import React from 'react'
import translate from './../../shared/language/Translate'
import Colors from './../Colors'
import moment from 'moment'

import { 
  Button, 
  Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core'

import {
  mergeNote,
  completeNote,
  deleteNote,
  showNoteDialog,
} from './../reducers/NotesLogic'

import IconAccept from '@material-ui/icons/Done'
import IconCancel from '@material-ui/icons/NotInterested'
import IconDelete from '@material-ui/icons/DeleteForever'
import IconUndelete from '@material-ui/icons/Restore'
import IconComplete from '@material-ui/icons/DoneAll'
import IconEdit from '@material-ui/icons/Edit'

const HOVER = '2px solid ' + Colors.CONTENT_HOVER

const COLOR_ASSISTANT = 'hsl(0,100%, 35%)'
const COLOR_LIGHTING = 'hsl(45,100%, 35%)'
const COLOR_VIDEO = 'hsl(90,100%, 35%)'
const COLOR_COSTUMES = 'hsl(135,100%, 35%)'
const COLOR_SCENERY = 'hsl(180,100%, 35%)'
const COLOR_SOUND = 'hsl(225,100%, 35%)'
const COLOR_MANAGEMENT = 'hsl(270,100%, 35%)'

const DATE_FORMAT = 'dddd, MM/DD hh:mm a'

const styles = theme => {
	return {
		row: {
			height: 26,
		},
		cell: {
			paddingLeft: 12,
      paddingRight: 4,
      border: 'none',
		},
		additional: {
			fontStyle: 'italic'
		}
	}
}

export const buttonStyle = {
  maxWidth: '48px', 
  maxHeight: '48px',
  minWidth: '48px',
  minHeight: '48px',
}

const TableCellSlim = withStyles(styles)(props => {
	return (
		<TableCell className={props.classes.cell} colSpan={props.colSpan || 1}>
			{props.children}
		</TableCell>
	)
})

const TableRowSlim = withStyles(styles)((props)=>{
	const { classes, children, ...rest } = props
	return (
		<TableRow className={classes.row} {...rest}>
			{children}
		</TableRow>
	)
})

class NoteRow extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      hover: false,
      note: { ...this.props.note },
      backup: { ...this.props.note },
    }

    //FIXME Map this to a setting for users
    const T = this.props.strings
    this.mappings = {}
    this.mappingsFont = {}

    this.mappings[T.get('assistant')] = COLOR_ASSISTANT
    this.mappings[T.get('lighting')] = COLOR_LIGHTING
    this.mappings[T.get('video')] = COLOR_VIDEO
    this.mappings[T.get('scenery')] = COLOR_SCENERY
    this.mappings[T.get('costumes')] = COLOR_COSTUMES
    this.mappings[T.get('sound')] = COLOR_SOUND
    this.mappings[T.get('management')] = COLOR_MANAGEMENT

    this.mappingsFont[T.get('assistant')] = 'black'
    this.mappingsFont[T.get('lighting')] = 'black'
    this.mappingsFont[T.get('video')] = 'black'
    this.mappingsFont[T.get('scenery')] = 'black'
    this.mappingsFont[T.get('costumes')] = 'black'
    this.mappingsFont[T.get('sound')] = 'black'
    this.mappingsFont[T.get('management')] = 'black'
  }

  componentDidUpdate() {
    if(this.state.editing && this.state.focus) {
      const el = this[this.state.focus]
      if(el) {
        el.focus()
      }
    }
  }
  
  componentWillReceiveProps(props) {
    const note = { ...props.note }
    this.setState({
      ...this.state,
      note: note,
    })
  }

  edit(bool) {
    this.setState( {...this.state, editing: bool} )
  }

  update(e, field) {
    //console.log('test ' + field + ': ' +  e.target.value)
    const copy = { ...this.state.note }

    if(e.target.value == copy[field]){
      //console.log('items are identical')
      return
    }

    copy[field] = e.target.value
    this.setState({
      ...this.state,
      note: copy,
    })
  }

  save(e) {
    this.edit(false)
    this.props.dispatch( mergeNote( this.state.note) )
  }

  cancel() {
    this.setState({
      ...this.state,
      editing: false,
      note: {
        ...this.state.backup,
      }
    })
  }

  keyHandler(e) {
    const key = e.which || e.keyCode
    if(key == 27){
      this.cancel(e)
    } else if (key == 13){
      this.save()
    }
  }

  onMouseEnter(e) {
    this.setState( {...this.state, hover: true} )
  }

  onMouseLeave(e) {
    this.setState( {...this.state, hover: false} )
  }

  select(e, field) {
    this.setState({
      ...this.state,
      editing: true,
      focus: field,
    })
    this.edit(true)
  }

  onFocus(e) {
    e.target.select()
    this.setState({
      ...this.state,
      autoFlag: false, //just a flag to make set state update
    })
  }

  render() {
    const T = this.props.strings
    const { index, dispatch } = this.props
    const { note } = this.state
    

    const containerStyle = {
      borderBottom: '1px solid black',
      verticalAlign: 'initial',
      // display: 'flex',
      // flexDirection: 'row',
      backgroundColor: index % 2 == 0 ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.5)',
    }
    const rowStyle = {
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      overflow: 'hidden',
    }

    if(this.state.hover) {
      containerStyle.borderLeft = HOVER
    } 
    if(this.state.editing) {
      containerStyle.border = '2px solid rgba(0, 117, 255, 0.5)'
      containerStyle.boxShadow = '2px 2px 5px #0075ff'
    }

    if(!this.state.hover && !this.state.editing) {
      containerStyle.borderLeft = '2px solid rgba(0,0,0,0.25)'
    }

    //red
    if(note.deleted) {
      containerStyle.backgroundColor = index % 2 == 0 ? 'rgba(255,0,0,0.7)' : 'rgba(255,0,0,0.5)'
      containerStyle.textDecoration = 'line-through'
    } else if (note.completed) {
      containerStyle.fontStyle = 'italic'
      containerStyle.color = 'rgba(0,0,0, 0.6)'
    }

    const rowStyles = {
      row: {
        display: 'flex',
        flexDirection: 'row',
      },
      status: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      category: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      department: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      priority: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      channels: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      cue: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
      },
      date: {
        ...rowStyle,
        minWidth: '96px',
        width: '96px',
        flex: 0,
      },
      description: {
        ...rowStyle,
        flex: 1,
      },
    }

    const cell = (val) => {
      return <TableCellSlim>{val}</TableCellSlim>
    }
    const display = (fontColor, backgroundColor)=> {
      const clear = 'rgba(0,0,0,0)'
      const highlight = backgroundColor
      let bgImage = `linear-gradient(to right, ${highlight}, ${clear}, ${clear}, ${clear}, ${clear})`
      if(this.state.hover) {
        bgImage = `linear-gradient(to right, ${highlight}, ${clear}, ${clear}, ${clear}, ${highlight})`
      }
      //backgroundImage: `linear-gradient(to right, ${paper}, ${paper}, ${paper}, ${paper}, ${highlight})`,
      const smallStyle = {
        fontSize: '0.8em',
        fontWeight: 'bold',
        marginRight: 12,
      }

      const smallLabel = (text, prefix = null) => {
        if(!text) {
          return null
        }

      return <label style={smallStyle}>{prefix} {text}</label>
      }


      const base = {
        ...containerStyle,
        backgroundImage: bgImage,
        color: note.completed ? containerStyle.color : fontColor,
      }
      const style = {
        ...base
      }

      const styleUnder = {
        ...base
      }

      delete style.borderBottom
      delete styleUnder.borderTop

      return (
        <React.Fragment>
          <TableRowSlim
            onMouseEnter={e => { this.onMouseEnter(e) }}
            onMouseLeave={e => { this.onMouseLeave(e) }}
            style={style}
            key='display'
          >
            { cell( T.soft(note.department) ) }
            { cell( T.soft(note.category) ) }
            <TableCellSlim colSpan={4}>
             {T.soft(note.description)}
            </TableCellSlim>
            <TableCellSlim style={{fontWeight: 'bold'}}>
              { note.relatedChannels.length ? 'Ch:' : ''}
              { note.relatedChannels.map(c => '(' + c + ')').join(', ') }
            </TableCellSlim>
            <TableCellSlim style={{fontWeight: 'bold'}}>
              { note.relatedCues.map(c => T.get('cue') + ' ' + c).join(', ') }
            </TableCellSlim>
            {/*Buttons */}
            <TableCellSlim>
              <div 
                style={{
                  minWidth: 144,
                  maxWidth: 144,
                  background: 'rgba(0,0,0,0.3)',
                }}
              >

                <Tooltip title={ !note.completed ? T.get('tipCompleteNote') : T.get('tipReopenNote') }>
                  <Button 
                    style={buttonStyle}
                    size='small'
                    onClick={ e  => { 
                      if(note.completed) {
                        this.edit(false)
                        const copy = {
                          ...note,
                          completed: false,
                          deleted: false,
                          status: 'open',
                        }
                        this.props.dispatch( mergeNote( copy , copy.uuid ) )
                      } else {
                        this.edit(false)
                        this.props.dispatch( completeNote(this.state.note) )
                      }

                    } }
                  >
                    { note.completed ? 
                      <React.Fragment>
                        <IconUndelete />
                      </React.Fragment>
                    : 
                      <React.Fragment>
                        <IconComplete />
                      </React.Fragment>
                    }
                  </Button>
                </Tooltip>

                <Tooltip title={ T.get('tipEditFields') }>
                  <Button 
                    style={buttonStyle}
                    size='small'
                    onClick={ e  => { 
                      this.props.dispatch(
                        showNoteDialog({
                          ...note
                        })
                      )
                      this.cancel()
                    } }
                  >
                    <IconEdit />
                  </Button>
                </Tooltip>

                <Tooltip title={ !note.deleted ? T.get('tipDeleteNote') : T.get('tipRestoreNote') }>
                  <Button 
                    style={buttonStyle}
                    size='small'
                    color='secondary' 
                    onClick={ e  => { 
                      if(note.deleted) {
                        const copy = {
                          ...note,
                        }
                        copy.deleted = false
                        copy.completed = false
                        copy.status = 'open'
                        dispatch( mergeNote( copy, copy.uuid ) )
                      } else {
                        dispatch( deleteNote( note ) )
                      }

                      this.edit(false)
                    } } 
                  >
                    { note.deleted ? 
                      <React.Fragment>
                        <IconUndelete />
                      </React.Fragment>
                      : 
                      <React.Fragment>
                        <IconDelete />
                      </React.Fragment>              
                    }
                  </Button>
                </Tooltip>
              </div>
            </TableCellSlim>
          </TableRowSlim>
          
          <TableRowSlim
            style={styleUnder}
            key='displayUnder'
          > 
            <TableCellSlim colSpan={1}>
              {/*INDENT*/}
            </TableCellSlim>

            <TableCellSlim colSpan={6}>
              { smallLabel( T.get(note.priority), T.get('priority') ) }
              { note.relatedChannels && smallLabel( note.relatedChannels.join(', '), T.get('relatedChannels')) }
              { note.relatedCues && smallLabel( note.relatedCues.join(', '), T.get('relatedCues')) }
              { smallLabel( moment(note.date).format(DATE_FORMAT), T.get('created') )}
            </TableCellSlim>

            <TableCellSlim colSpan={1}>
              {/*PADDING*/}
            </TableCellSlim>

            <TableCellSlim>
              { smallLabel( T.get('status_' + note.status) , T.get('status') + ':')}
            </TableCellSlim>
          </TableRowSlim>
        </React.Fragment>
      )
    }

    const edit = (
      <div 
        onMouseEnter={e => { this.onMouseEnter(e) }}
        onMouseLeave={e => { this.onMouseLeave(e) }}
        style={{
          ...containerStyle,
        }}
        key='display'
      >
        <div 
          style={rowStyles.status}
          onClick={ 
            e => { this.select(e, 'status') } 
          }
        >
          { T.get('status_' + note.status) }
        </div>

        <div 
          style={rowStyles.category}
          onClick={ 
            e => { this.select(e, 'category') } 
          }
        >
          { T.get(note.category) }
        </div>

        <div 
          style={rowStyles.department}
          onClick={ 
            e => { this.select(e, 'department') } 
          }
        >
          { note.department }
        </div>

        <div 
          style={rowStyles.priority}
          onClick={ 
            e => { this.select(e, 'priority') } 
          }
        >
          { T.get(note.priority) }
        </div>

        <div 
          style={rowStyles.channels}
          onClick={ 
            e => { this.select(e, 'department') } 
          }
        >
          { note.relatedChannels.join(', ') }
        </div>

        <div 
          style={rowStyles.cue}
          onClick={ 
            e => { this.select(e, 'cue') } 
          }
        >
          { note.relatedCues.join(', ') }
        </div>

        <div 
          style={rowStyles.date}
        >
          { moment(note.date).format(DATE_FORMAT) }
        </div>

        <div 
          style={rowStyles.description}
          onClick={ 
            e => { this.select(e, 'description') } 
          }
  >
          <TextField 
            onFocus={e => this.onFocus(e) }
            value={ note.description }
            onKeyDown={ e => this.keyHandler(e) }
            hintText={T.get('descriptionHint')}
            ref={x => this.description = x }
            multiLine={true}
            fullWidth={true}
            rows={1}
            style={{marginTop:'-12px'}}
            onChange={ e => this.update(e, 'description') } 
          />
        </div>

      </div>
    )
    if(this.state.editing) {
      return [
        edit,
        <div 
          key='control'
          style={{
            display: 'flex',
            paddingBottom: '4px',
            paddingTop: '2px',
          }}
        >
          
           {/*spacer */}
          <div style={{flex: 1}}></div>
           {/*spacer */}

          <Tooltip title={ T.get('tipSaveChanges') }>
            <Button 
              size='small'
              color='primary' 
              onClick={ e  => { 
                this.save()
              } }
            >
              <IconAccept />
            </Button>
          </Tooltip>

          <Tooltip title={ T.get('tipCancelChanges') }>
            <Button 
              size='small'
              onClick={ e  => { 
                this.cancel()
              } }
            >
              <IconCancel />
            </Button>
          </Tooltip>

          <Tooltip title={ T.get('tipEditFields') }>
            <Button 
              size='small'
              onClick={ e  => { 
                this.props.dispatch(
                  showNoteDialog({
                    ...note
                  })
                )
                this.cancel()
              } }
            >
              <IconEdit />
            </Button>
          </Tooltip>

          <Button 
            size='small'
            color='primary' 
            variant='contained'
            onClick={ e  => { 
              if(note.completed) {
                this.edit(false)
                const copy = {
                  ...note,
                  completed: false,
                  deleted: false,
                  status: 'open',
                }
                this.props.dispatch( mergeNote( copy , copy.uuid ) )
              } else {
                this.edit(false)
                this.props.dispatch( completeNote(this.state.note) )
              }

            } }
          >
            { note.completed ? 
              <React.Fragment>
                <IconUndelete />
                { T.get('reopen') }
              </React.Fragment>
            : 
              <React.Fragment>
                <IconComplete />
                { T.get('complete') }
              </React.Fragment>
            }
          </Button>

          <Button 
            size='small'
            color='secondary' 
            variant='contained'
            onClick={ e  => { 
              if(note.deleted) {
                const copy = {
                  ...note,
                }
                copy.deleted = false
                copy.completed = false
                copy.status = 'open'
                dispatch( mergeNote( copy, copy.uuid ) )
              } else {
                dispatch( deleteNote( note ) )
              }

              this.edit(false)
            } } 
          >
            { note.deleted ? 
              <React.Fragment>
                <IconUndelete />
                { T.get('restore') }
              </React.Fragment>
              : 
              <React.Fragment>
                <IconDelete />
                { T.get('delete') }
              </React.Fragment>              
            }
          </Button>
        </div>
      ]
    } else {
      const fontColor = this.mappingsFont[note.department]
      const backgroundColor = this.mappings[note.department]
      return display(fontColor, backgroundColor)
      return (
        <div style={{
          background: backgroundColor || 'white',
          color: fontColor || 'black',
        }}>
          {display}
        </div>
      )
    }
  }  
}

export default translate('Notes')(NoteRow)