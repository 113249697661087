import { Translator } from './Translate'

let language = 'en'

let Language = {
  key: language,
  navigation: Translator(language, 'Navigation'),
  cueSheet: Translator(language, 'CueSheet'),
  cueLogic: Translator(language, 'CueLogic'),
  homePage: Translator(language, 'HomePage'),
  notes: Translator(language, 'Notes'),
  footer: Translator(language, 'Footer'),
  instruments: Translator(language, 'Instruments'),
  instrumentLogic: Translator(language, 'InstrumentLogic'),
  instrumentMappings: Translator(language, 'InstrumentMappings'),
  log: Translator(language, 'Log'),
  show: Translator(language, 'Show'),
  Paperwork: Translator(language, 'Paperwork'),
  osc: Translator(language, 'OSC'),
  vectorworks: Translator(language, 'Vectorworks'),
  general: Translator(language, 'General'),
  reportEditor: Translator(language, 'ReportEditor'),
  positions: Translator(language, 'Positions')
}

export const changeLanguage = (key) => {
  language = key
  Language = {
    key: language,
    navigation: Translator(language, 'Navigation'),
    cueSheet: Translator(language, 'CueSheet'),
    cueLogic: Translator(language, 'CueLogic'),
    homePage: Translator(language, 'HomePage'),
    notes: Translator(language, 'Notes'),
    footer: Translator(language, 'Footer'),
    general: Translator(language, 'General'),    
    instruments: Translator(language, 'Instruments'),
    instrumentLogic: Translator(language, 'InstrumentLogic'),
    instrumentMappings: Translator(language, 'InstrumentMappings'),
    log: Translator(language, 'Log'),
    show: Translator(language, 'Show'),
    paperwork: Translator(language, 'Paperwork'),
    osc: Translator(language, 'OSC'),
    vectorworks: Translator(language, 'Vectorworks'),
    reportEditor: Translator(language, 'ReportEditor'),
    positions: Translator(language, 'Positions')
  }
}

//Initialize
changeLanguage(language)

export const navigation = Language.navigation
export const cueSheet = Language.cueSheet
export const cueLogic = Language.cueLogic
export const homePage = Language.homePage
export const notes = Language.notes
export const footer = Language.footer
export const instruments = Language.instruments
export const instrumentLogic = Language.instrumentLogic
export const instrumentMappings = Language.instrumentMappings
export const log = Language.log
export const show = Language.show
export const paperwork = Language.paperwork
export const osc = Language.osc
export const vectorworks = Language.vectorworks
export const general = Language.general
export const reportEditor = Language.reportEditor
export const positions = Language.positions

export const T = Language

export default Language