import deepEqual from 'deep-equal'
/**
 * 
 * @see https://stackoverflow.com/questions/610406/javascript-equivalent-to-printf-string-format
 */

 /**
  * @param {string} the string to format
  * @param {array || number || string} the arguments to format by 
  * ex: formatUnicorn('i am {0} {1}', 'the', 'walrus') -> 'i am the walrus'
  */
export const formatUnicorn = (str, ...rest) => {
    let _str = str + ''
    if (rest.length) {
        const t = typeof rest[0]
        let key
        const args = ('string' === t || 'number' === t) ?
            Array.prototype.slice.call(rest)
            : rest[0]

        for (key in args) {
          _str = _str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key])
        }
    }

    return _str
}

export const joinStrings = (...rest) => {
    if(!rest) {
        return ''
    }

    let out = ''
    for(let s of rest) {
        if(s) {
            out += s + ' '
        }
    }
    return out
}

/**
 * Returns B if A is null or undefined
 * @param {*} a anything possibly null
 * @param {*} defaultValue default value
 */
export const ifNullThen = (a, defaultValue) => {
    if(a === undefined || a === null) {
        return defaultValue
    } else {
        return a
    }
}

/**
 * If the string is null, undefined, or empty return true
 * @param {*} a 
 * @returns {boolean} true if empty, false otherwise
 */
export const isEmptyOrWhitespace = (a) => {
    if(a === undefined || a === null) {
        return true
    }

    const tmp = ('' + a).trim()
    if( tmp.length == 0) {
        return true
    }

    return false
}

export const trim = (str) => {
    if(!str) {
        return str
    }

    
}
/**
 * Reference to deepEqaual without the require statement
 */
export const equals = deepEqual