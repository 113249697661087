import React from 'react'
import translate from '../../shared/language/Translate'
import {notes} from '../../shared/language/'

import {
  detectShiftEnter,
} from './../Utility'
import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,

} from '@material-ui/core'

import {
  showNoteDialog,
} from '../reducers/NotesLogic'

import { 
  utilSmartLabelCue,
  noteCue, 
  dismissNote, 
  mergeCueData, 
  NOTE_TYPES 
} from '../reducers/CueLogic'

class CueMemoDialog extends React.Component {

  constructor(props){
    super(props)

    let note = props.note
    if(!note){
      note = {
        typeGeneral: true,
      }
    }

    if(!note.channels){
      note.channels = []
    }

    this.state = {
      ...props,
      note: note
    }
  } 

  componentWillReceiveProps(props){
    let note = props.note
    if(!note){
      note = {
        typeGeneral: true,
      }
    }

    if(!note.channels){
      note.channels = []
    }

    this.state = {
      ...props,
      note: note
    }
  }

  render(){
    const T = this.props.strings
    const { dispatch, open, cue, onCancel, note } = this.state || {};
  
    const renderCheck = (field) =>{
      return (
        <FormControlLabel
          control = {
            <Checkbox 
              checked={ note[field] }
              onCheck={ (e, on)=>{
                const update = {
                  ...this.state.note,
                }

                update[field] = !!on

                this.setState({
                  ...this.state,
                  note: update
                })
              }}
            />
          }
          label={ T.get(field) }
        />
        
      )
    }

    const smartLabel = utilSmartLabelCue(this.props.cue)
    return (
      <Dialog
        open={open || false}
        modal={true}
        autoScrollBodyContent={true}
        onRequestClose={e=>{
          dispatch( dismissNote() )
        }}
      >
        <DialogTitle>
          { T.get('notesDialog') }
        </DialogTitle>
        
        <DialogContent>
          <label> 
            <b>{T.get('cue')} { smartLabel }</b>
          </label>  

          <TextField 
            autoFocus
            fullWidth
            ref={ x => this.description = x }
            hintText={T.get('description')}
            label={T.get('description')}
            multiLine={true}
            onKeyPress={e => {
              if( detectShiftEnter(e) ){
                //Create a note object and clear the values
                const update = e.target.value
                const note = {
                  ...this.state.note,
                  description: update,
                }

                if(!cue.notes){
                  cue.notes = []
                }
                cue.notes.push(note)
                dispatch( 
                  mergeCueData(
                    cue, 
                    `Add memo: (${smartLabel})`,
                    `Remove memo: (${smartLabel})`,
                  ) 
                )

                dispatch( dismissNote() )
              }
            }}
            onBlur={e=>{
              const update = e.target.value
              this.setState({
                ...this.state,
                note: {
                  ...this.state.note,
                  description: update
                }
              })
            }}
          />

          <span
            dangerouslySetInnerHTML={{__html: T.get('notesMemoDescription')}}
          >
          </span>
        </DialogContent>

        <DialogActions>
          <Button 
            onClick={e=>{
              if(onCancel){
                onCancel()
              }
              dispatch( dismissNote() )
            } }
          >
            {T.get('cancel')}
          </Button>

          {/* IF THIS IS A NUMBERED CUE ALLOW NOTES TO BE MADE ON IT */}
          { cue && cue.number ? 
            <Tooltip title={T.get('convertToNoteTip')}>
              <Button
                color='primary'
                onClick={ e=>{
                  const data = {
                    cue: this.props.cue.number,
                    description: this.state.note.description || '',
                  }
                  dispatch( dismissNote() )
                  dispatch( showNoteDialog(data, false, notes.get('lighting')) )
                }}
              >
                { T.get('convertToNote') }
              </Button>
            </Tooltip>
            : null 
          }

          <Button  
            color='secondary'
            onClick={e=>{
              if(!cue.notes){
                cue.notes = []
              }
              cue.notes.push(note)
              dispatch( 
                mergeCueData(
                  cue, 
                  `Add memo: (${smartLabel})`,
                  `Remove memo: (${smartLabel})`,
                ) 
              )
              dispatch( dismissNote() )
              // console.log('---------------------DEBUG')
              // console.log(note)
            } }
          >
            { T.get('notesAddMemo') }     
          </Button> 
        </DialogActions>
      </Dialog>
    )
  }
}

export default translate('CueSheet')(CueMemoDialog)