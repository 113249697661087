import { appendCues } from './reducers/CueLogic'
import {
  showNoteDialog
} from './reducers/NotesLogic'
const KEY = '__LIGHT_ASSISTANT_KEYS'

const globalEventKeys = (e) => {
  if(!e) {
    return
  }

  //CTRL or CMD or ALT - N
  if(e.keyCode == 78) {
    if(e.ctrlKey || e.metaKey || e.altKey) {
      window.REDUX_STORE.dispatch( showNoteDialog() )
    }
  }

  //CTRL or CMD or ALT L
  if(e.keyCode == 76) {
    if(e.ctrlKey || e.metaKey || e.altKey) {
      //If we are in the cue sheet module
      let el = document.getElementById('scroll-end-spacer')
      if(el) {
        el.scrollIntoView()
        window.REDUX_STORE.dispatch( appendCues(1, true) )
      }
    }

  }
}

export default (w) => {
  //Prevent multiple bindings
  if(w[KEY] != KEY) {
    w[KEY] = KEY

    console.log('BINDING GLOBAL LISTENERS---------------')
    w.document.addEventListener('keydown', globalEventKeys, false)

    
  }
}