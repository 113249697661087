export default {
  addFailTitle: 'Error: Failed to Add Cue',
  addFailBody: 'You must specify a instrument to add, the object',
  addInstrumentUndo: `Add Instrument (id: '{_id}' uid: '{UID}' Deleted? {deleted})`,
  mergeFailTitle: 'Merge Failed',
  mergeFailDetail: `The application failed to merge the data. Please save your data.`,
  htmlDiffRow: `<div>({aCh}) {aKey}:{aValue} to ({bCh}) {bKey}:{bValue}</div>`,
  simpleBulkMergeDescription: `<div>({aId}) thru ({bId}) [{key}] to [{value}] </div>`,

  errorWorksheetExistsTitle: '{0} already exists!',
  errorWorksheetExistsParagraph: `
  <p>
  The name {0} is already in use. 
  Please choose another name for this worksheet.
  </p>
` ,

  //UNDO
  deleteUndoMessage: '(${first}) to $({last}) Deleted',
  restoreUndoMessage: '(${first}) to $({last}) Restored',
}