export default {
  completed: 'Completed',
  inProgress: 'In Progress',
  pending: 'Pending',
  cueSheet: 'Cue Sheet - Simple',
  cueSheetAll: 'Cue Sheet - Detailed',
  notes: 'Notes',
  instrumentSchedule: 'Instrument Schedule',
  channelHookup: 'Channel Hookup',
  addressHookup: 'Address Hookup',
  circuitHookup: 'Circuit Hookup',
  colorSchedule: 'Color Schedule',
  templateSchedule: 'Template Schedule',
  genericInstrumentSchedule: 'Instrument Schedule',
  genericChannelHookup: 'Channel Hookup',
  genericColorCutList: 'Color Cut List',
  //Settings
  settings: 'Settings',
  avery5160Labels: 'Avery 5160 Labels',
  genericColorSchedule: 'Color Schedule',
  notesByDeparment: 'Notes by Department',
  notesByCue: 'Notes by Cue',
  notesByPriority: 'Notes by Priority',
}