import React from 'react'
import { connect } from 'react-redux'
import translate, { TranslatorFromRawState } from './../../shared/language/Translate'
import { detectShiftEnter } from './../Utility'
import Colors from './../Colors'
import {
  ifNullThen
} from './../../shared/StringUtil'
import { 
  addNote, 
} from './../reducers/NotesLogic'
import FloatingOSCTracker from './../integration/FloatingOSCTracker'

import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core'


const SORTS = [
  'status',
  'priority',
  'date',
  'category',
  'department',
  'cue',
  'channel',
]

    
const cardStyle = {
  display: 'block',
  padding: 20,
  marginBottom: 20,
  borderRadius: 3,
}

const styles = theme => {
	return {
		row: {
			height: 26,
		},
		cell: {
			paddingLeft: 12,
			paddingRight: 4,
		},
		additional: {
			fontStyle: 'italic'
		}
	}
}

class TextFieldRef extends React.Component {
  render(){
    return <TextField {...this.props} />
  }
}

class NotesPageEditor extends React.Component {
  constructor(props){
    super(props)

    const T = props.strings

    this.departments = [
      T.get('private'),
      T.get('assistant'),
      T.get('lighting'),
      T.get('video'),
      T.get('scenery'),
      T.get('costumes'),
      T.get('sound'),
      T.get('management'),
    ]

    this.priorities = [
      'urgent',
      'high',
      'regular',
      'low',
      'minor',
    ]

    this.noteTypes = [
      'typeGeneral',
      'typeCue',
      'typeFocus',
      'typeWork',
      'typePrivate',
    ]

    this.sorts = [
      T.get('status'),
      T.get('category'),
      T.get('department'),
      T.get('priority'),
      T.get('channels'),
      T.get('cue'),
      T.get('date'),
    ]

    this.focusField = null
    const categories = this.departments.slice()
    categories.unshift( 'cue' )
    this.state = {
      addNote: {},
      channels:[],
      channelText: '',
      cues:[],
      cueText: '',
      department: props.strings.get('lighting'),
      priority: 'regular',
      noteType: 'typeGeneral',
      cascade: true,
      discardErrors: false,
      trackOsc: false,
      categories: [],
      inactiveCategories: categories,
      dirty: false,
    }

  }

  tryAddNote() {
    const note = {
      description: ifNullThen(this.state.description, '').trim(),
      department: this.state.department,
      priority: this.state.priority,
      category: this.state.noteType,
      relatedChannels: this.state.channels || [],
      relatedCues: this.state.cues || [],
      date: new Date(),
    }

    setTimeout( () => this.resetNote(), 100 )
    this.props.dispatch( addNote(note) )
  }


  resetNote() {
    const addCurrentOSC = this.props.oscOn && this.props.trackOsc


    this.setState({
      ...this.state,
      channels: [],
      channelText: '',
      cues: addCurrentOSC ? [this.props.oscCue] : [],
      cueText: '',
      description: '',
      priority: 'regular',
      dirty: false,
    })

    if(this.focusField && this.focusField.focus) {
      this.focusField.focus()
    }
  }

  setOscCue = (cue) => {
    if(cue) {
      const copy = {...this.state}
      copy.cues = [ cue ]
      //copy.cues.push(cue)
      copy.cueText = ''
      this.setState(copy)
    }
  }

  componentWillReceiveProps(props) {
    //if we are not dirty chance the cue to the current cue
    if(!this.state.dirty && props.oscOn && props.trackOsc) {
      this.setOscCue(props.oscCue)
    }
  }

  render() {
    const T = this.props.strings
    const textStyle = {
      flex: 1,
      marginRight: '4px'
    }

    const setChannel = () => {
      if(this.state.channelText) {
        const copy = {...this.state}
        copy.channels.push(this.state.channelText)
        copy.channelText = ''
        this.setState(copy)
      }
    }

    const removeChannel = (ch) => {
      const channels = this.state.channels.slice()
      const cut = channels.indexOf(ch)
      if(cut > -1) {
        channels.splice(cut, 1)
      }

      this.setState({
        ...this.state,
        channels: channels,
      })
    }

    const setCue = () => {
      if(this.state.cueText) {
        const copy = {...this.state}
        copy.cues.push(this.state.cueText)
        copy.cueText = ''
        this.setState(copy)
      }
    }

    const removeCue = (ch) => {
      const cues = this.state.cues.slice()
      const cut = cues.indexOf(ch)
      if(cut > -1) {
        cues.splice(cut, 1)
      }

      this.setState({
        ...this.state,
        cues: cues,
      })
    }

    return (
      <div style={{
        display: 'block',
        flex: 1,
      }}>
        <Paper  
          style={{
            ...cardStyle,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <div style={{display:'flex'}}>
              <TextFieldRef
                select 
                style={textStyle}
                label={T.get('department')}
                onChange={ event => {
                  this.setState({
                    department: event.target.value,
                  })
                } }
                value={this.state.department}
              >
                { this.departments.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { d }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>

              <TextFieldRef 
                select
                style={textStyle}
                label={T.get('priority')}
                onChange={ event => {
                  this.setState({
                    priority: event.target.value,
                  })
                } }
                value={this.state.priority}
              >
                { this.priorities.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { T.get(d) }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>
            </div>
            
            <div style={{display:'flex'}}>
              <TextFieldRef 
                style={textStyle}
                multiline
                label={T.get('shortDescription')}
                value={this.state.description}
                ref={ (x)=>{ this.focusField = x} }//callback for focus
                onKeyUp={ (e) => {
                  if( detectShiftEnter(e) ) {
                    setTimeout(()=>{this.tryAddNote()}, 50) //delay
                    e.preventDefault()
                  }
                } }
                onChange={ (e) => {
                  if( detectShiftEnter(e) ) {
                    e.preventDefault()
                    return
                  }
                  this.setState({
                    dirty: true,
                    description: e.target.value,
                  })
                } }
              />

              <TextFieldRef 
                select
                style={textStyle}
                label={T.get('category')}
                onChange={ event => {
                  this.setState({
                    ...this.state,
                    dirty: true,
                    noteType: event.target.value,
                  })
                } }
                value={this.state.noteType}
              >
                { this.noteTypes.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { T.get(d) }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>
            </div>

            <div style={{display:'flex'}}>
              <TextFieldRef
                style={textStyle}
                label={ T.get('relatedChannels') }
                onChange={ (e) => {
                  const val = e.target.value
                  this.setState({
                    channelText: val,
                  })
                } }
                onBlur={ (e) => {
                  setChannel()
                }}
                onKeyUp= { (e) => {
                  if(e.charCode === 13 || e.keyCode === 13) {
                    setChannel()
                  }
                }}
                value={this.state.channelText}
              />

              <TextFieldRef
                style={textStyle}
                label={ T.get('relatedCues') }
                onChange={ (e) => {
                  const val = e.target.value
                  this.setState({
                    cueText: val,
                  })
                } }
                onBlur={ (e) => {
                  setCue()
                }}
                onKeyUp= { (e) => {
                  if(e.charCode === 13 || e.keyCode === 13) {
                    setCue()
                  }
                }}
                value={this.state.cueText}
              />          
            </div>

            {/* HELP TEXT */ }
            <div>
              { this.state.noteType == 'typePrivate' ? 
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: T.get('privateNoteDetail') }}/>
                </React.Fragment> 
                : null
              }
            </div>

            <div 
              style={{
                display: 'flex',
              }}
            >
              { this.state.cues.map( (c,i) => {
                return (
                  <Chip 
                    key={c}
                    label={'Cue ' + c}
                    backgroundColor={ Colors.LA_LIGHT_BLUE }
                    onDelete={ () => { removeCue(c) }}
                  />
                )
              })}

              { this.state.channels.map( (c,i) => {
                return (
                  <Chip 
                    key={c}
                    label={'Ch. ' + c}
                    onDelete={ () => { removeChannel(c) }}
                  />
                )
              })}
            </div>
            
            <div 
              style={{
                display: 'flex',
                marginTop:'24px', 
                justifyContent: 'space-between',
              }}
            >
              <div>
                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.cascade }
                      />
                    }
                    label={T.get('cascadeChanges')}
                  />
                </FormGroup>
                
                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.discardErrors }
                      />
                    }
                    label={T.get('discardErrors')}
                  />
                </FormGroup>

                                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.trackOsc }
                      />
                    }
                    label={T.get('trackCueListOSC')}
                  />
                </FormGroup>
              </div>
              <div 
                style={{
                  display:'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div>
                  <Button 
                    variant='outlined'
                    onClick={() => {
                      this.resetNote()
                    }}
                  >
                   { T.get('clear') }
                  </Button>

                  <Button 
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.tryAddNote()
                    } }
                  >
                    { T.get('addNote') }
                  </Button>

                  { (this.props.trackOsc && this.props.oscOn) && (
                    <Button 
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setOscCue(this.props.oscCue)
                      } }
                    >
                      { T.get('readOscData') }
                    </Button>
                  )}
                  
                </div>
              </div>
            
            </div>          
          </div>

                      {/* CLEARING SOME OLD SILLY INSTRUCTIONS */}
          {/* <div style={{ display:'flex', marginLeft: '24px' }}>
              <div>
                <h6>DEBUG: Dirty <b>{this.state.dirty ? '!DIRTY!' : '!CLEAN!'}</b></h6>
                <h3>Hot Keys</h3>
                <p style={{color: 'red'}}>
                    SHIFT+ENTER in the Note Field will submit the note and refocus the Note Field for a new note.
                    <b>
                      If OSC is enabled it will automatically refresh the "Related Cue" field with the currently 
                      tracked cue after submitting the note.
                    </b>
                </p>
                
                <h3>Notes Information</h3>
                <p>
                  The notes module allows you to take notes "ad hoc" outside 
                  other modules. This is built for speed and with the idea you are in the middle of 
                  tech when taking these notes and you don't have time to sort through
                  endless lists. 
                </p>

                <p style={{color: this.state.cascade ? 'red' : 'inherit' }}>
                  <b>Cascade {this.state.cascade ? 'Enabled' : 'Disabled'}</b> You can attach related channels and related cues
                  to the note and it will cascade those notes to the other modules.
                  In the event the cue or channel can not be found Light Assistant will
                  alert you to the fact and create a general note before savings. 
                </p>

                <p style={{color: this.state.discardErrors ? 'red' : 'inherit' }}>
                  <b>Discard Errors {this.state.discardErrors ? 'Enabled' : 'Disabled'}</b> Warning messages are turned off and unmatched channels or cues are automatically turned into general notes. 
                </p>
                
                <p style={{color: this.state.trackOsc ? 'red' : 'inherit' }}>
                  <b>Osc Tracking {this.state.trackOsc ? 'Enabled' : 'Disabled'} </b> If you are connected via OSC and the <i>Related Cues</i> field 
                  is automatically populated with the last OSC go that matched 
                  a cue in the list. 
                </p>
              </div>
              
          </div> */}
        
        </Paper>
      </div>
    )
  }
}

export default connect(s => {
  return {
    //OSC Translations
    OSC: TranslatorFromRawState(s, 'OSC'),
    //OSC Tracking
    trackOsc: !!s.notes.trackOsc,
    oscCue: s.osc.cue,
    oscList: s.osc.list,
    oscOn: !!s.osc.on,
  }
})(translate('Notes')(NotesPageEditor) )