import history from './history';

export const redirect = (to)=>{
  return dispatch => {
    history.push( { pathname: to});
    dispatch ({ type: 'redirect', to : to});
  };
};

/**
 * Parses a list of files provided by a drag event
 * and sorts them by their type.
 * 
 * Returns an empty array for null 
 */
export const parseFiles = (dropList)=> {
  const results = [];
  if(!dropList || !dropList.length){
    return results;
  }
  for(let i = 0; i < dropList.length; ++i){
    const f = dropList[i];

    if(!f){
      continue;
    }

    //This is a folder
    if(!f.type && f.size % 4096 === 0){
      results.push({
        type: 'folder',
        name: f.name,
        size: f.size,
        file: f
      });
    } else {
      results.push({
        type: f.type,
        name: f.name,
        size: f.size,
        file: f
      });
    }
  }

  return results;
};

export const convertFileToImage = (fileUrl, onComplete)=>{
  
  let canvas = document.createElement('canvas');
  canvas.width = 400;
  canvas.height = 300;
  const ctx = canvas.getContext('2d');
  const reader = new FileReader();
  reader.onload = (e)=>{
    const image = new Image();
    image.onload = ()=>{
      //Here we scale this to fit inside the (arbitrary) 400x300 shape
      const w = image.naturalWidth;
      const h = image.naturalHeight;
      const wScale = canvas.width / w;
      const hScale = canvas.height / h;
      const scale = Math.min(wScale, hScale);
      const sW = w * scale;
      const sH = h * scale;
      const sX = (canvas.width - sW) / 2;
      const sY = (canvas.height - sH) / 2;

      ctx.drawImage(image, sX, sY, sW, sH);

      const dataUrl = canvas.toDataURL('image/png');
      onComplete(dataUrl);
    };
    image.src = e.target.result;
  };
  reader.readAsDataURL(fileUrl.file);
};

/**
 * Detects whether the user presses SHIFT+ENTER
 * @param {*} event 
 * @returns true if this keystroke happens.
 */
export const detectShiftEnter = (event) => {
  if(!event){
    return false
  }
  const code = event.charCode || event.keyCode 
  if(code === 13 && event.shiftKey) {
    return true
  }

  return false
}

export default redirect;