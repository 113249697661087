export default {
  cues: 'Cues',
  history: 'History',
  undo: 'Undo',
  redo: 'Redo',
  viewHistory: '{0} History',
  viewHistoryDetail: 'View a list of changes',
  redoTag: '[REDO] ',
  undoTag: '[UNDO] ',
  instruments: 'Instruments',
}