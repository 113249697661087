
import {
  isNum,
  num,
  incrementLetter,
} from './../Utility'
/**
 * 
 * @param {String} value
 * @return {Function(index<int>)<String>} a function that returns the value based on index
 */
export const handleThru = (value, lowercase, tokens) => {
  let index = -1
  let command = null
  for(let t of tokens) {
    const i = lowercase.indexOf(t)
    if(i > -1) {
      index = i 
      command = t
      break;
    }
  }

  //ERROR
  if(index < 0 || !command) {
    return {
      value: value,
      parser: (index)=>{
        return value
      },
      error: {
        message: `No valid thru tokens found. Try "=1>5" or "=1>"`
      }
    }
  }

  let first = lowercase.slice(1, index)
  let second = lowercase.slice(index + command.length, lowercase.length)

  const isNumeric = isNum(first)
  const isSecondNumeric = isNum(second) && (second > 0)


  console.log(`'${first}'::'${second}' (${lowercase}) numeric? ${isNumeric}, ${isSecondNumeric}`)
  if(isNumeric && isSecondNumeric) {
    return {
      value: value,
      parser: (index)=>{
        const a = Number(first)
        const b = Number(second)
        const mod = index % (b - a + 1)
        console.log(`MOD ${index}::${mod} ${b - a}`)
        console.log(`\t${a} + ${mod} = ${a + mod}`)
        return a + mod
      },
    }
  } else if (isNumeric) {
    return {
      value: value,
      parser: (index)=>{
        return Number(first) + index
      },
    }
  } else {
    return {
      value: value,
      parser: (index)=>{
        return incrementLetter(first)
      },
    }
  }
}

/**
 * This is the main parsing function that looks for 
 * 
 * @returns 
 *  Function(index<Int>)<String> a function that returns a string for a given index. 
 *      OR 
 *  a String (the value passed)
 */
export const parseInstrumentCommandLine = (value) => {

  if(value === null || value === undefined || value === '') {
    return value
  } 

  value += '' //convert to string

  if(value[0] !== '=') {
    return value
  }

  const lowercase = value.toLowerCase()
  //TOKENS
  const THRU = ['>', 'thru']

  let isThru = -1
  for(let token of THRU) {
   let index = lowercase.indexOf(token) 
   if(index > -1) {
     isThru = index
   }
   return handleThru(value, lowercase, THRU)
  }

  if(isThru > -1) {
    return (index) => {
      return value
    }
  }
  

  //DEFAULT
  return value
  
}

/**
 * 
 * @param {Result of ::parseInstrumentCommandLine} result 
 */
export const isCommand = (result) => {
  return !!result.parser
}

export const inError = (result) => {
  if(result.error) {
    return true
  }

  return false
}