import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import ReactJsonView from 'react-json-view'

class DebugInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true,
    }
  }

  _renderCollapsed() {
    return (
      <Button 
        color='secondary'
        style = {{
        }}
        onClick={()=>{
          this.setState({
            ...this.state,
            collapsed: false
          })
        }}
      >
        { `DEBUG ${this.props.label}` }
      </Button>
    )
    
  }

  _renderExpanded() {
    return (
      <div 
        style={ {
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          overflow: 'scroll',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          zIndex: 999,
        } }
      >
        <Button 
          style = {{
          }}
          color='secondary'
          onClick={()=>{
            this.setState({
              ...this.state,
              collapsed: true
            })
          }}
        >
         { `CLOSE DEBUG ${this.props.label}` }
        </Button>
        
        <ReactJsonView src={this.props.json || {debug: 'object was falsey'}}/>
      </div>
    )
  }

  render() {
    return this.state.collapsed ? this._renderCollapsed() : this._renderExpanded()
    
  }
}

DebugInfo.propTypes = {
  json: PropTypes.any,
  label: PropTypes.string,
}

export default DebugInfo
