import uuidv1 from 'uuid/v1'
import moment from 'moment'
import {
  isEmptyOrWhitespace
} from './../../shared/StringUtil'

import {
  alphaNumericCompare,
  broadwaySort,
  compareToNullSafe,
  isNull,
} from './../../shared/Utility'

import {
 getCurrentOscCue
} from './OscLogic'

import {
  cuesTrackOsc
} from './CueLogic'

import {
  showWarning
} from './InterfaceLogic'
import Language from './../../shared/language'

export const ADD_NOTE = 'NOTES_ADD_NOTE'
export const STORE_LAST_NOTE_STATE = 'NOTES_STORE_LAST_NOTE'
export const DELETE_NOTE = 'NOTES_DELETE_NOTE'
export const REPLACE_NOTE = 'NOTES_REPLACE_NOTE'
export const LOAD_NOTES = 'NOTES_LOAD_NOTES'
export const REMOVE_COMPLETED_NOTES = 'NOTES_REMOVE_COMPLETED'
export const REMOVE_DELETED_NOTES = 'NOTES_REMOVE_DELETED'
export const REMOVE_ALL_NOTES = 'NOTES_REMOVE_ALL_NOTES'

export const ANALYZE_CUES = 'NOTES_ANALYZE_CUES'
export const RESET = 'NOTES_RESET'
export const PUSH_SORT = 'NOTES_PUSH_SORT'
export const PUSH_FILTER = 'NOTES_PUSH_FILTER'
export const CLEAR_SORT = 'NOTES_CLEAR_SORT'
export const CLEAR_FILTER = 'NOTES_CLEAR_FILTER'
export const SHOW_DELETED = 'NOTES_SHOW_DELETED'
export const SHOW_COMPLETED = 'NOTES_SHOW_COMPLETED'
export const TRACK_OSC = 'NOTES_TRACK_OSC'

export const SET_CUE_NOTES_CACHE = 'NOTES_SET_CUE_NOTES_CACHE'
export const SET_INSTRUMENT_NOTES_CACHE = 'NOTES_SET_INSTRUMENT_NOTES_CACHE'
export const REPAIR_LEGACY = 'NOTES_REPAIR_LEGACY'

export const DISMISS_NOTE_DIALOG = 'NOTES_DISMISS_NOTE_DIALOG'
export const CLEAR_NOTE_DIALOG = 'NOTES_DISMISS_NOTE_DIALOG' //This wipes the data after a delay
export const SHOW_NOTE_DIALOG = 'NOTES_SHOW_NOTE_DIALOG'
export const CHANGE_MODE_DIALOG_DRAWER = 'NOTES_CHANGE_MODE_DIALOG_DRAWER'

//"Pure" Methods
export const NOTE_SORT_STATUS = 'status'
export const NOTE_SORT_CATEGORY = 'category'
export const NOTE_SORT_DEPARTMENT = 'department'
export const NOTE_SORT_PRIORITY = 'priority'
export const NOTE_SORT_CHANNEL = 'channels'
export const NOTE_SORT_CUE = 'cue'
export const NOTE_SORT_DATE = 'date'
export const SORT_PRIORITY = {
  'urgent': 1,
  'high': 2,
  'regular' : 3,
  'low': 4,
  'minor': 5,
}

export const NOTE_TYPES = [
  'typeGeneral',
  'typeCue',
  'typeFocus',
  'typeWork',
  'typePrivate',
]

/**
 * Formats a note with normal words rather than symbols
 * @param {Note} note 
 * @return { Object } with human readable terms and variables
 */
export const formatNoteForPaperwork = (note) => {
  const _time = moment(note.date)
  let out = {
    ...note,
    description: note.description,
    _department: note.department,
    _priority: note.priority,
    _category: note.category,
    _relatedChannels: note.relatedChannels,
    _relatedCues: note.relatedCues,
    _date: note.date,
    _status: note.status,
    uuid: note.uuid,
    LLLL: _time.format('LLLL'),
    YYYY: _time.format('YYYY'),
    YY: _time.format('YY'),
    Q: _time.format('Q'),
    M: _time.format('M'),
    MM: _time.format('MM'),
    MMM: _time.format('MMM'),
    MMMM: _time.format('MMMM'),
    month: _time.format('month'),
    D: _time.format('D'),
    DD: _time.format('DD'),
    Do: _time.format('Do'),
    DDD: _time.format('DDD'),
    X: _time.format('X'),
    day: _time.format('dddd'),
    dddd: _time.format('dddd'),
    H: _time.format('H'),
    HH: _time.format('HH'),
    h: _time.format('h'),
    hh: _time.format('hh'),
    a: _time.format('a'),
    am: _time.format('a'),
    mm: _time.format('mm'),
    minutes: _time.format('mm'),
    ss: _time.format('ss'),
    seconds: _time.format('ss'),
    ZZ: _time.format('ZZ'),
    date: _time.format('MMMM Do YYYY'),
    time: _time.format('hh:mm:ss a'),
    shortDate: _time.format('YYYY-MM-DD'),
  }


  out.relatedCues = (out._relatedCues || []).join(', ')
  out.relatedChannels = (out._relatedChannels || []).join(', ')

  //Map enums and types
  const T = Language.notes
  out.priority = T.soft( out._priority )
  out.category = T.soft( out._category )
  out.department = T.soft( out._department )
  out.status = T.soft( out._status )

  return out
}

const _privateNotes = {}
Object.keys(formatNoteForPaperwork({})).sort().forEach(k => _privateNotes[k] = k)
export const NOTES_VARIALBES = {..._privateNotes}

/**
 * Looks for the minimum value in the array and compares using that
 * @param {*} toCompare 
 * @param {*} arrayOfValues 
 */
export const sortValuesInArray = (toCompareArray, arrayOfValues) => {
  const aNull = isNull( toCompareArray ) || toCompareArray.length < 1
  const bNull = isNull( arrayOfValues ) || arrayOfValues.length < 1
  
  //We really need to check an array type here...
  if(aNull && bNull) {
    return 0
  }

  if(aNull) {
    return -1
  }

  if(bNull) {
    return 1
  }

  let aMin = toCompareArray[0]
  let bMin = arrayOfValues[0]

  for(let x of toCompareArray) {
    let comp = broadwaySort(aMin, x, true)
    if(comp < 0) {
      aMin = x
    } //we skip equal or greater values
  }

  for(let x of arrayOfValues) {
    let comp = broadwaySort(bMin, x, true)
    if(comp < 0) {
      bMin = x
    } //we skip equal or greater values
  }

  return broadwaySort(aMin, bMin, true)
}
/**
 * 
 * @param {*} notesObject Function< Array<Notes> >
 * @param {*} departments Array<String> eg. 'Lighting', provided by the translator, this is a string...
 * @param {*} sorts Array<String> e.g. NOTE_SORT_CUE 
 * @param {Boolean} completed Show completed notes in the result
 * @param {Boolean} deleted Show deleted notes in the result
 * @returns a sorted array of the notesObject
 */
export const createSort = (notesObject, departments = [], sorts = [], completed = false, deleted = false) => {
  
  const notes = {...notesObject }

  let sorted = []
  const sortedIndex = []

  if(departments && departments.length) {
    for(let key of Object.keys(notes) ) {
      
      const note = { ...notes[key] }
      for( let d of departments) {
        if(note.department == d) {
          sorted.push(note)
          break
        }
      }
    }
  } else {
    for (const note of Object.values(notes) ) {
      sorted.push( {...note} )
    }
  }

  //If we chose cues we only want to see cues
  if(departments && departments.indexOf('typeCue') > -1 ) {
    sorted = sorted.filter( n => n.category !== 'typeCue')
  }

  if(completed || deleted) {
    if(completed && !deleted) {
      sorted = sorted.filter(n => !n.deleted)
    } else if ( deleted && !completed) {
      sorted = sorted.filter(n => !n.completed)
    } //else do nothing, we want it all
  } else {
    sorted = sorted.filter(n => !n.completed && !n.deleted)
  }


  let makeSortFn = (_sorts) => {
    let sortFns = []
    for(let sort of _sorts) {
      if        (sort == NOTE_SORT_STATUS) {
        sortFns.push(
          (a, b)=>{
            let aS = a.status || 'open'
            let bS = b.status || 'open'

            return alphaNumericCompare(bS, aS)
          }
        )
      } else if (sort == NOTE_SORT_CATEGORY) {
        sortFns.push(
          (a, b)=>{
            return alphaNumericCompare(a.category, b.category)
          }
        )
      } else if (sort == NOTE_SORT_DEPARTMENT) {
        sortFns.push(
          (a, b)=>{
          return alphaNumericCompare(a.department, b.department)
          }
        )
      } else if (sort == NOTE_SORT_PRIORITY) {
        sortFns.push(
          (a, b)=>{
            const aP = SORT_PRIORITY[a.priority] || 5
            const bP = SORT_PRIORITY[b.priority] || 5
            return compareToNullSafe(aP, bP)
            // let out = compareToNullSafe(bP, aP)
            // console.log(`${a.priority} < ${b.priority} ? ${out}`)
            // return out
          }
        )
      } else if (sort == NOTE_SORT_CHANNEL) {
        sortFns.push( 
          (a,b) => {
            return sortValuesInArray(a.relatedChannels, b.relatedChannels)
          } 
        )
      } else if (sort == NOTE_SORT_CUE) {
        sortFns.push( 
          (a,b) => {
            return sortValuesInArray(a.relatedCues, b.relatedCues)
          } 
        )
      } else if (sort == NOTE_SORT_DATE) {
        sortFns.push(
          (a, b)=>{
            return alphaNumericCompare(b.date, a.date)
          }
        )
      }
    }

    const masterSort = (a, b) => {
      let sort = 0
  
      for(let fn of sortFns) {
        let result = fn(a, b)
        if(result != 0) {
          return result
        }
      }
  
      return 0 //boring natural sort...
    }

    return masterSort
  }

  const defaultSort = makeSortFn([NOTE_SORT_STATUS, NOTE_SORT_DEPARTMENT, NOTE_SORT_PRIORITY, NOTE_SORT_DATE])
  const userSort = makeSortFn(sorts)

  //DEFAULT SORT
  sorted = sorted.sort(defaultSort)
  sorted = sorted.sort(userSort)  

  return sorted
}

/**
 * @see createSort
 * @param {*} onComplete A callback that executes with the data from `createSort`
 *                   NOTE a second argument called on this will skip the sort. Super naughty
 * @param {*} departments --refer to createSort
 * @param {*} sorts --refer to createSort
 * @param {*} completed --refer to createSort
 * @param {*} deleted --refer to createSort
 */
export const dispatchCreateNotesSort = (onComplete, departments = [], sorts = [], completed = false, deleted = false) => {
  return (dispatch, getState) => {
    const state = getState()
    const notes = state.notes.notes
    const noteArray = []
    for(const k of Object.keys(notes) ) {
      noteArray.push({
        ...notes[k],
        _id: k,
      })
    }

    const output = createSort(noteArray, departments, sorts, completed, deleted)
    const processed = []
    output.forEach(n => processed.push( formatNoteForPaperwork(n) ) )
    const SKIP_SORTING = true
    onComplete(processed, SKIP_SORTING)
  }
}

/**
 * Takes an array of notes and returns only the active notes
 * @param {Array<Notes>} notes return a non-null array (possibly empty) of notes that are active
 */
export const utilNotesActive = (notes = []) => {
  const out = []
  for(let n of notes) {
    if(n) {
      if(n.status == 'open') {
        out.push(n)
      }
    }
  }
  return out
}

/**
 * Return the defaults for each department.
 * @param {*} state 
 * @param {*} department 
 */
export const getNoteDefaultsForDepartment = (state, department) => {

  if(!department) {
    department = Language.notes.get('lighting')
  }

  if(department == Language.notes.get('lighting')) {
    return {
      department: department,
      category: 'typeCue',
      priority: 'regular',
    }
  } else {
    return {
      department: department,
      category: 'typeGeneral',
      priority: 'regular',
    }
  }
}

/**
 * Get the default setup for a specific note by either
 * what was last in the editor
 */
export const utilGetLastSettingsOrDepartmentDefault = (state, department) => {
  const old = state && state.notes && state.notes.noteDialogLastData || {}
  const defaults = getNoteDefaultsForDepartment(department)
  //if no department or we are the same department use the old settings
  if(!department) {
    if(old.department) {
      return {
        category: old.category || defaults.category,
        //priority: old.priority || defaults.priority,
        department: old.department,
      } 
    } 

    return defaults
  
  } 
  //Else if we have a department and an old
  if(old.department == department) { //restore the last used settings
   return {
     category: old.category,
     //priority: old.priority,
     department: old.department,
   } 
  } 

  //Else defaults
  return defaults
}

/**
 * Push a note into the "old state" to be referenced when new notes 
 * are triggered. This only works if you don't switch department or 
 * specify overrides (like OSC or the cue list which are properly cue notes)
 * 
 * @param {Note} note a note
 */
export const storeLastNoteState = (note) => {
  return dispatch => {
    dispatch({
      type: STORE_LAST_NOTE_STATE,
      note: {...note},
    })
  }
}

//Arbitrary Note Dialog
export const dismissNoteDialog = ()=>{
	return dispatch => {
		dispatch({
			type: DISMISS_NOTE_DIALOG,
      clear: true,
    })
    // setTimeout(()=>{
    //   dispatch({
    //     type: CLEAR_NOTE_DIALOG
    //   })
    // }, 100)//100ms delay
	}
}

/**
 * 
 * @param {Object<Note>} defaults the default value of the cue to edit
 * @param {Boolean} useOSC if true: look at the cue and if it is missing a
 * cue number and OSC tracking is enabled, pull in relevant OSC data.
 * @param {String} department Optional, if set it checks the last used setting and
 * if that is the same uses that department. If unspecified it uses the last
 * known settings.
 */
export const showNoteDialog = (defaults, useOSC = true, department = null) => {
  return (dispatch, getState) => {
    const state = getState()

    if(!defaults) {
      defaults = utilGetLastSettingsOrDepartmentDefault(state, department)
      //defaults = {}
    }
    //Is this an existing cue? Ignore the OSC
    const useOscCheck = useOSC && !defaults.uuid && !defaults.activeCue

    if(!useOscCheck || defaults.uuid) { 
      dispatch({
        type: SHOW_NOTE_DIALOG,
        defaults: {
          ...defaults,
          status: isEmptyOrWhitespace(defaults.status) ? 'open' : defaults.status,
        },
      })
    } else {
      //Try OSC
      const s = getState()
      const cue = getCurrentOscCue(s)
      dispatch({
        type: SHOW_NOTE_DIALOG,
        defaults: {
          ...defaults,
          cue: cue, //if null we don't care
          status: isEmptyOrWhitespace(defaults.status) ? 'open' : defaults.status,
        },
      })
    }

  }
}

export const showNotesDialogForInstrument = (instrument) => {
  const ch = instrument && instrument.channel
  if(isEmptyOrWhitespace(ch)) {
    return dispatch => {
      dispatch( showWarning(Language.notes.get('warnNotesRequireChannels'), Language.notes.get('warnNotesRequireChannelsDetail')) )
    }
  } 

  return (dispatch, getState) => {
    const state = getState()

    let lights = getNoteDefaultsForDepartment(Language.notes.get('lighting'))
    
    dispatch( showNoteDialog({
      ...lights,
      category: 'typeWork',
      relatedChannels: [ch]
    }) )
  }
}

export const changeModeToDialog = () => {
  return dispatch => {
    dispatch({
      type: CHANGE_MODE_DIALOG_DRAWER,
      mode: true,
    })
  }
}

export const changeModeToDrawer = () => {
  return dispatch => {
    dispatch({
      type: CHANGE_MODE_DIALOG_DRAWER,
      mode: false,
    })
  }
}

/**
 * Build a cache of notes for the cues. This module shows completed notes
 */
export const buildCueNotesCache = () => {
  return (dispatch, getState) => {
    const state = getState()
    const notes = state.notes.notes || {}
    const cues = state.cues.cues || []

    //Map< CueNumber, Array<Notes> >
    const cueNotes = {}

    const keys = Object.keys(notes)
    for(let key of keys) {
      const note = notes[key]
      if(note.status == 'open') {
        let related = note.relatedCues || []
        for( let cue of related) {
          if( !isEmptyOrWhitespace(cue) ) {
            //Push an ID onto the stack
            let array = cueNotes[cue] || []
            array.push(key)
            cueNotes[cue] = array
          }
        }
      } //else SKIP closed/completed notes
    }

    dispatch({
      type: SET_CUE_NOTES_CACHE,
      cueNotes: cueNotes
    })
  }
}

/**
 * Build a cache of notes indexed by the channel for the instrument (we might want to do a UUID too)
 */
export const buildInstrumentNotesCacheByChannel = () => {
  return (dispatch, getState) => {

    const state = getState()
    const notes = state.notes.notes || {}
    const instruments = state.instruments.instruments || []

    //Map< CueNumber, Array<Notes> >
    const instrumentNotes = {}

    const keys = Object.keys(notes)
    for(let key of keys) {
      const note = notes[key]
      if(note.status == 'open') {
        let related = note.relatedChannels || []
        for( let channel of related) {
          if( !isEmptyOrWhitespace(channel) ) {
            //Push an ID onto the stack
            let array = instrumentNotes[channel] || []
            array.push(key)
            instrumentNotes[channel] = array
          }
        }
      }
    }

    dispatch({
      type: SET_INSTRUMENT_NOTES_CACHE,
      instrumentNotesByChannel: instrumentNotes
    })
  }
}

/**
 * Builds all the related caches for the notes lookups
 */
export const buildAllCaches = () => {
  return dispatch => {
    dispatch(buildCueNotesCache())
    dispatch(buildInstrumentNotesCacheByChannel())
  }
}

/**
 * Converts a note to a complete smart string 
 * representations
 * @param {*} note 
 */
export const utilSmartNoteDescription = (note) => {
  if(!note) {
    return 'Empty'
  }

  const join = (prefix, array, before = '', after = '') => {
    if(array && array.length > 0) {
      return prefix + ' ' + array.map(x => before + x + after).join(', ')
    }
  }

  let relatedChannels = join('Channels:', note.relatedChannels, '(', ')' )
  let relatedCues = join('Cues:', note.relatedCues)

  let label = ''
  const append = (data) => {
    if(data && data.trim().length > 0) {
      label += data.trim()
      label += ' '
    }
  }

  append(note.department)
  if(label.length > 0) {
    append('|')
  }
  append(note.description)
  append('|')
  append(relatedChannels)
  append(relatedCues)

  return label
}
/**
 * Return notes that apply to a cue. An empty cueNumber returns an
 * empty array
 * @param {String} cueNumber 
 * @param {The notes object} notes 
 */
export const getNotesForCue = (cueNumber, notes) => {
  if( isEmptyOrWhitespace(cueNumber) ) {
    return []
  }
  const results = []
  const allNotes = Object.keys(notes) 
  for(let id of allNotes) {
    let note = notes[id]
    let related = note.relatedCues || []
    for( let cue of related) {
      if( !isEmptyOrWhitespace(cue) ) {
        if(cue == cueNumber) {
          results.push( {...note } )
        }
      }
    }
  }

  return results
}

const _ensureStatus = (note) => {

  if( note.status == 'open'||
      note.status == 'completed' ||
      note.status == 'deleted'
    ) {
    return note
  } else {
    return {
      ...note,
      status: 'open',
    }
  }
}
//General Note Logic
export const addNote = (note) => {
  note = _ensureStatus(note)
  if(!note.status){
    note.status = 'open'
  }

  note.uuid = uuidv1()
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: ADD_NOTE,
      note: note,
    })
  }
}
/**
 * Returns a correctly formatted object if the notes
 * are found to be an array. Otherwise it just returns the notes object
 * @param {array or object} state 
 */
const _repairLegacy = (notes) => {

    if( Array.isArray(notes) ) {
      const repair = {}

      for(let n of notes) {
        n.uuid = uuidv1()
        repair[n.uuid] = n
      }

      return repair
    }

    return notes
}

export const repairLegacy = () => {
  return dispatch => dispatch({
    type: REPAIR_LEGACY
  })
}

/**
 * returns true if this is legacy, else false
 * @param {*} notes 
 */
export const checkLegacy = (notes) => {
  return Array.isArray(notes)
}
/**
 * Merge this note with the index given.
 */
export const mergeNote = (note) => {
  note = _ensureStatus(note)
  return dispatch => {
    dispatch({
      type: REPLACE_NOTE,
      note: note,
    })
  }
}

export const completeNote = (note) => {
  const update = {
    ...note,
    completed: true,
    status: 'completed',
    completedAt: new Date(),
  }


  if(!note.uuid) {
    alert('can not complete a note without uuid, aborting')
    return
  }

  return mergeNote(update)
}

export const deleteNote = (note) => {
  const update = {
    ...note,
    status: 'deleted',
    deleted: true,
  }

  if(!note.uuid) {
    alert('can not remove note without uuid, aborting')
    return
  }

  return mergeNote( update)
}

export const restoreNote = (note) => {
  const update = {
    ...note,
    status: 'open',
    deleted: false,
  }

  if(!note.uuid) {
    alert('can not restore note without uuid, aborting')
    return
  }

  return mergeNote( update)
}

export const openNote = (note) => {
  
  const update = {
    ...note,
    completed: false,
    status: 'open',
    completedAt: null,
  }

  if(!note.uuid) {
    alert('can not open a note without uuid, aborting')
    return
  }

  return mergeNote(update)
}

/**
 * Adds an advanced property to the note. These look like:
 * <tt>
 * note {
 *   ...
 *   advanced: {
 *     field: value,
 *   }
 * }
 * </tt> 
 * @param {*} note 
 * @param {*} field 
 * @param {*} value 
 */
export const linkNoteAdvancedProperty = (note, field, value) => {

  if(isEmptyOrWhitespace(field) || isEmptyOrWhitespace(value)) {
    return note
  }

  const copy = {...note}
  const advanced = {...copy.advanced}
  advanced[field] = value
  copy.advanced = advanced
  return copy

}

/**
 * Removes a property from the note advanced section
 * @param {*} note 
 * @param {*} field 
 */
export const deleteNoteAdvancedProperty = (note, field) => {
  if(!note || !note.advanced) {
    return note
  }

  const advanced = {...note.advanced}
  delete advanced[field]
  return {
    ...note,
    advanced: advanced,
  }
}

/**
 * Analyze the cue module and find all active notes 
 */
export const analyzeCues = ()=>{
  // //DEAD CODE
  //   return (dispatch, getState) => {
  //     const state = getState()

  //     //Tie into cues and check for loaded
  //     const processCues = (cues)=>{
  //       const notes = []
  //       if(cues){
  //         cues.forEach(q => {
  //           if(q.notes && q.notes.length > 0){
  //             q.notes.forEach(n => {
  //               notes.push({
  //                 note: n,
  //                 cue: {
  //                   number: q.number,
  //                   label: q.label,
  //                   description: q.description,
  //                 }
  //               })
  //             })
  //           }
  //         })
  //       }
        
  //       dispatch({
  //         type: ANALYZE_CUES,
  //         notes: notes
  //       })
  //     }

  //     if(!state.cues.loaded || !state.cues.cues){
  //       dispatch( loadCues(processCues) )
  //     } else {
  //       processCues(state.cues.cues)
  //     }
  //   }
}

export const pushSort = (key) => {
  return dispatch => dispatch({
    type: PUSH_SORT,
    key: key,
  })
}

export const pushFilter = (key) => {
  return dispatch => dispatch({
    type: PUSH_FILTER,
    key: key,
  })
}

export const clearSort = (key) => {
  return dispatch => dispatch({
    type: CLEAR_SORT,
    key: key,
  })
}

export const clearFilter = (key) => {
  return dispatch => dispatch({
    type: CLEAR_FILTER,
    key: key,
  })
}

export const showDeleted = (bool) => {
  return dispatch => dispatch({
    type: SHOW_DELETED,
    mode: bool,
  })
}

export const showCompleted = (bool) => {
  return dispatch => dispatch({
    type: SHOW_COMPLETED,
    mode: bool,
  })
}

export const trackOsc = (bool) => {
  return dispatch => dispatch( cuesTrackOsc(bool) )
  // return dispatch => dispatch({
  //   type: TRACK_OSC,
  //   mode: bool,
  // })
}
/** 
 * Reset the notes data
 */
export const noteReset = ()=>{
  return dispatch => {
    dispatch ( { type: RESET })
  }
}

/**
 * Remove all deleted notes from the file
 */
export const clearAllDeletedNotes = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_DELETED_NOTES,
    })
    dispatch( buildAllCaches() )
  }
}

/**
 * Remove all completed notes from the file
 */
export const clearAllCompletedNotes = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_COMPLETED_NOTES,
    })
    dispatch( buildAllCaches() )
  }
}

export const clearAllNotes = () => {
  return dispatch => {
    dispatch({
      type: REMOVE_ALL_NOTES
    })
    dispatch(buildAllCaches())
    
  }
}

export const INITIAL_STATE = {
   cue: {
     loaded: false,
     cueNotes: []
   },
   instruments: {
     loaded: false,
   },
   instrumentNotesByChannel: {
     loaded: false,
     instrumentNotesByChannel: []
   },
   notes: {},//map of UUID -> NOTE,
   filters: [],
   sorts: [NOTE_SORT_STATUS, NOTE_SORT_PRIORITY, NOTE_SORT_DEPARTMENT, NOTE_SORT_DATE],
   showDeleted: false,
   showCompleted: true,
   noteDialogOpen: false,
   noteDialogData: {},
   noteDialogLastData: {}, //used for loading what was last used
   noteDialogMode: false,

   //OSC Tracking:
   trackOsc: false,
 }

 export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    case RESET: {
      return INITIAL_STATE
    }
      
    case REPAIR_LEGACY: {
      const notes = state.notes
      const repair = _repairLegacy(notes)
      console.log('[WARNING]: repairing legacy state for notes')
      return {
        ...INITIAL_STATE,
        ...state,
        notes: repair,
      }
    }

    case SET_CUE_NOTES_CACHE: {
      const cueNotes = action.cueNotes || []
      return {
        ...state,
        cue: {
          loaded: true,
          cueNotes: cueNotes
        }
      }
    }

    case SET_INSTRUMENT_NOTES_CACHE: {
      const instrumentNotesByChannel = action.instrumentNotesByChannel || []
      return {
        ...state,
        instrumentNotesByChannel: {
          loaded: true,
          instruments: instrumentNotesByChannel
        }
      }
    }

    case ANALYZE_CUES: {
      return {
        ...state,
        cue: {
          ...state.cues,
          notes: action.notes,
          loaded: true,
        }
      }
    }

    case ADD_NOTE: {
      const copy = {
        ...state.notes
      }
      copy[action.note.uuid] = action.note
      
      return {
        ...state,
        notes: copy,
      }
    }

    case REPLACE_NOTE: {
      const { note } = action
      if(!note.uuid) {
        return alert(`unable to merge a note without a valid UUID NotesLogic::Actions::REPLACE_NOTE`)
      }
      const notes = {
        ...state.notes,
      }

      notes[note.uuid] = note

      if(state.sorts) {

      }
      return {
        ...state,
        notes: notes,
      }
    }

    case PUSH_SORT: {
      const { key } = action
      const sorts = (state.sorts || []).slice()
      const index = sorts.indexOf(key)
      if(index < 0) {
        sorts.push(key)
        return {
          ...state,
          sorts: sorts
        }
      } 
      
      return state
    }

    case CLEAR_SORT: {
      const { key } = action
      const sorts = (state.sorts || []).slice()
      const index = sorts.indexOf(key)
      if(index > -1) {
        sorts.splice(index, 1)
      }
      return {
        ...state,
        sorts: sorts,
      }
    }

    case PUSH_FILTER: {
      const { key } = action
      const filters = (state.filters || []).slice()
      const index = filters.indexOf(key)
      if(index < 0) {
        filters.push(key)
        return {
          ...state,
          filters: filters
        }
      } 
      
      return state
    }

    case CLEAR_FILTER: {
      const { key } = action
      const filters = (state.filters || []).slice()
      const index = filters.indexOf(key)
      if(index > -1) {
        filters.splice(index, 1)
      }
      return {
        ...state,
        filters: filters,
      }
    }

    case SHOW_COMPLETED: {
      return {
        ...state,
        showCompleted: action.mode,
      }
    }

    case SHOW_DELETED: {
      return {
        ...state,
        showDeleted: action.mode,
      }
    }

    case TRACK_OSC: {
      return {
        ...state,
        trackOsc: action.mode,
      }
    }

    /* 
    I broke this into two parts because 
    there is a weird flicker that happens when the
    dialog closes. This should minimize it.
    */
    case DISMISS_NOTE_DIALOG: {
      if(action.clear) {
        return {
          ...state,
          noteDialogOpen: false,
          noteDialogData: {},
        }
      }

      return {
        ...state,
        noteDialogOpen: false,
        //noteDialogData: null,
      }
      
    }

    case CLEAR_NOTE_DIALOG: {
      return {
        ...state,
        //noteDialogOpen: false,
        noteDialogData: null,
      }
    }

    case SHOW_NOTE_DIALOG: {
      const defaults = action.defaults
      
      return {
        ...state,
        noteDialogOpen: true,
        noteDialogData: defaults || {},
      }
    }

    case CHANGE_MODE_DIALOG_DRAWER: {
      const mode = action.mode || false
      return  {
        ...state,
        noteDialogMode: mode
      }
    }

    case REMOVE_COMPLETED_NOTES: {
      const notes = {...state.notes}
      const newNotes = {}
      for(let uuid of Object.keys(notes)) {
        const note = notes[uuid]
        if(note.status != 'completed') {
          newNotes[uuid] = {...note}
        }
      }

      return {
        ...state,
        notes: newNotes
      }
    }

    case REMOVE_DELETED_NOTES: {
      const notes = {...state.notes}
      const newNotes = {}
      for(let uuid of Object.keys(notes)) {
        const note = notes[uuid]
        if(note.status != 'deleted') {
          newNotes[uuid] = {...note}
        }
      }

      return {
        ...state,
        notes: newNotes
      }
    }

    case REMOVE_ALL_NOTES: {
      return {
        ...state,
        notes: INITIAL_STATE.notes,
      }
    }

    case STORE_LAST_NOTE_STATE: {
      if(!action.note) {
        return state
      }
      //Wipe the UUID
      const noUuid = {
        ...action.note
      }
      delete noUuid.uuid
      return {
        ...state,
        noteDialogLastData: noUuid
      }
    }
  }

  return state
}