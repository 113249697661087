export default {
  //Core
  settings: 'Instrument Settings',
  loading: 'Preparing Instruments',
  //Errors
  deleteInstrumentFailed: 'Unable to delete instrumemt',
  deleteInstrumentFailedMessageNull: `The system was unable to remove a null instrument`,
  restoreInstrumentFailed: 'Unable to restore insrument',
  restoreInstrumentFailedMessageNull: 'The system was unable to restore a null instrument',
  electronNotConnected: 'Electron is not connected',
  electronNotConnectedDetail: `Electron is not connected, you are likely running in a web-container. To use Vectorworks Data Exchange you need to download the app. http://www.lightassistant.com`,

  //Sync
  setupDataExchange: 'Setup Data Exchange',
  sync: 'Sync Now',

  //Paperwork - Used by reports
  channel: '(Ch)',
  address: 'Adr.',
  circuit: 'Cir.',
  __instTypeAccessories: 'Instrument Type & Accessories',
  wattage: 'Wattage',
  color: 'Color',
  colorTemplate: 'Color & Gobo',
  template: 'Template',
  purpose: 'Purpose',
  unit: '#',
  frameSize: 'Frame Size',
  instrumentType: 'Instrument Type',
  count: 'Count',

  //Alternative Paperwork
  position: 'Position',
  universe: 'Universe',
  circuitName: 'Circuit Name',
  circuitNumber: 'Circuit Number',

  //Worksheets
  addWorksheet: 'Add Worksheet',
  removeWorksheetTitle: 'Remove worksheet {name}?',
  removeWorksheetDetail: `
  <p>
    Are you sure you want to remove the worksheet 
    <b>{name}</b>? This action can not be undone.
  </p>
`, 
  removeWorksheet: 'Remove Workhseet',
  restoreWorksheets: 'Restore Worksheets',
  restoreWorksheetsMerge: 'Merge Default Worksheets',
  restoreWorksheetsTitle: 'Restore Default Worksheets?',
  restoreWorksheetsDetail: `
  <p>
    This will remove any custom worksheets in the document and 
    restore the default worksheets. This will create a standard
    set of paperwork to work from.
  </p>
  
  <p>
    Selecting <b>Merge Default Worksheets</b> will merge in 
    the defaults rather than overwriting your custom worksheets.
  </p>

  <p>
    <b>Defaults: </b> 
    <i>
    Instrument Schedule, Channel Hookup, 
    Address Hookup, Color Schedule, Unit Schedule
    </i>
  </p>
`,
  worksheetName: 'Worksheet Name',
  worksheetSettings: 'Worksheet Settings',
  createWorksheets: 'Create a Worksheet',
  otherActions: 'Other Actions',
  selectedWorksheet: 'Selected Worksheet',
  sortedBy: 'Sorted by',
  groupedBy: 'Grouped by',
  selectWorksheet: 'Select Worksheet',
  helpEditWorksheet: 'Selects a worksheet for editing',
  createWorksheetTitle: 'Create a New Worksheet',
  createWorksheetHelper: 'Enter a unique name for this worksheet',
  createWorksheetDescription: `
  <p>
    This creates a new worksheet that can be configured for use.
  </p>

  <p>
    Worksheets are editable views of your instruments 
    and function like editable paperwork. You should consider 
    creating a worksheet for any common sorting or grouping operation
    you need.
  </p>
`, 
  editWorksheetGroupingsTitle: 'Edit Grouping',
  editWorksheetGroupingsDetail: `
  <p>
    Worksheet groupings act <b>first as a sorting</b>, and secondly as a 
    container. If you want to see section breaks when data changes 
    then a grouping is probably the right answer. If you don't want
    groups you should look at <b>Sorting</b>.
  </p>
  <p>
    Two good examples are: <i>Instrument Schedule</i> (Position) or
    <i>Color Hookups</i> (Color and Frame Size).
  </p>
  <p>
    <tt>
      The sort is:
      (Group 1, ..., Group N, Sort 1, ... Sort N)
    </tt>
  </p>
`,
  editWorksheetSortTitle: 'Edit Sort',
  editWorksheetSortDetail: `
  <p>
    Worksheet sorting happens after grouping. This is the order you 
    want the worksheet to be displayed in.
  </p>
  <p>
    A good example of a simple sorted worksheet without grouping
     is the Channel Hookup or Address Hookup.
  </p>
  <p>
    <tt>
      The sort is:
      (Group 1, ..., Group N, Sort 1, ... Sort N)
    </tt>
  </p>
`,
cleanupDimmers: 'Copy Dimmers to Addresses',
cleanupDimmersDetail: `<p>
 This will transition all address information (dimmer or address) to use the
 Universe/Address format. Dimmer will take higher priority than address for 
 the algorithm.
 </p>
 <p>Example, dimmer 513 will now become Universe 2, address: 1, dimmer 2/1</p>
 <p>Cleaning up using asolute addresses will use consecutive numbers to represent the 
 universes</p>
 <p>Example, dimmer 513 will remain 513, Universe will be set to empty, and address will become 513
 `,
 cleanupAddressAndDimmersOK: 'Copy Dimmers to Addresses',
 showAllFields: 'Show All Fields',
 showAllFieldsHelp: `
  Show All Fields displays a list of all possible data imported 
  from Vectorworks, this can be useful if you're looking for 
  a custom field or you do not find what you are expecting.
 `,

 //**INSTRUMENT NOTES DIALOG */
 instrumentNotesDialogTitle: 'Notes for ({channel}) [{position} #{unitNumber}]',
 description: 'Description',
 controls: 'Controls',
 channels: 'Channels',
 cues: 'Cues',
}