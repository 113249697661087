
import CueSheet from './en/CueSheet'
import CueLogic from './en/CueLogic'
import EosAPI from './en/EosAPI'
import Errors from './en/Errors'
import HomePage from './en/HomePage'
import Notes from './en/Notes'
import Footer from './en/Footer'
import General from './en/General'
import Instruments from './en/Instruments'
import InstrumentLogic from './en/InstrumentLogic'
import InstrumentMappings from './en/InstrumentMappings'
import Log from './en/Log'
import Navigation from './en/Navigation'
import OSC from './en/OSC'
import Paperwork from './en/Paperwork'
import ReportEditor from './en/ReportEditor'
import Show from './en/Show'
import Undo from './en/Undo'
import Vectorworks from './en/Vectorworks'
import Positions from './en/Positions'

export default {
  Navigation: Navigation,
  CueSheet: CueSheet,
  CueLogic: CueLogic,
  EosAPI: EosAPI,
  Errors: Errors,
  General: General,
  HomePage: HomePage,
  Notes: Notes,
  Footer: Footer,
  Instruments: Instruments,
  InstrumentLogic: InstrumentLogic,
  InstrumentMappings: InstrumentMappings,
  Log: Log,
  Paperwork,
  Positions,
  Show: Show,
  OSC: OSC,
  Undo: Undo,
  Vectorworks: Vectorworks,
  ReportEditor,
  Sync: {
    outstanding: 'Outstanding Changes',
    outstandingTooltip: `You have {0} changes to instruments that have not been synchronized`
  },
  
}