import React from 'react'
import { connect } from 'react-redux'
import { 
  osc as OSC
} from './../../shared/language'
import SmartToggle from './../../components/Button/Toggle'
import AsHtml from './../../components/AsHtml'
import { 
  showDialog
} from './../reducers/InterfaceLogic'

import {
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
//Logic
import { 
  READ_PATCH_ENABLED,
  READ_PATCH_UNKNOWN,
  EOS_PATCH_ADD_PARTS_TO_CHANNEL,
  updateOscKey,
  connectToEos,
  OSC_UPDATE_KEY,
} from '../reducers/OscLogic'

import { 
  disconnectEos,
  eosSyncCueList, 
  eosOscHeartbeat,
  eosRequestPatch,
} from './../reducers/ElectronLogic'

import {
  Card, CardSeries
} from './../../components/Cards'

class EosConnection extends React.Component {

  componentDidMount() {
    if(this.props.osc  && this.props.osc.on) {
      this.tryEosConnect()
    } 
  }

  tryEosConnect() {
    console.log('trying to connect...')
    this.props.dispatch( connectToEos() )
  }
   
  tryEosDisconnect() {
    this.props.dispatch( disconnectEos() )
  }

  sendEosHeartbeat() {
    this.props.dispatch( eosOscHeartbeat() )
  }

  render() {
    const T = OSC
    let { osc, dispatch } = this.props

    if(!osc) {
      osc = {}
    }
    const readPatch = !!osc[READ_PATCH_ENABLED] 
    const readPatchUnknown = !!osc[READ_PATCH_UNKNOWN]
    const eosPatchAddPartToChannel = !!osc[EOS_PATCH_ADD_PARTS_TO_CHANNEL]
    return (
      <div>
        <CardSeries>
          <Card>
            <h2>{T.get('etcEosConnection')}</h2>
            <div>
              <TextField 
                style={{
                  width: 196,
                  marginRight: 8,
                }}
                disabled = { osc.on }
                InputLabelProps={{
                  shrink: true,
                }}
                value = { osc.eosHost }
                label = { T.get('eosIpAddress') }
                helperText = 'ex: 10.101.100.101 (Typical Ion)'
                onChange = { (e) => {
                  dispatch( updateOscKey('eosHost', e.target.value) )
                } }
              />
              <TextField
                style={{
                  width: 196,
                  marginRight: 8,
                }}
                select
                disabled = { osc.on }
                InputLabelProps={{
                  shrink: true,
                }}
                label = { T.get('eosOscVersion') }
                value = { osc.eosOscVersion }
                helperText = { osc.eosOscVersion == '1.0' ? 'Packet Length Headers (1.0)' : 'SLIP (1.1)'}
                onChange = { (e) => {
                  dispatch( updateOscKey('eosOscVersion', e.target.value) )
                } }
              >
                <MenuItem value={'1.0'}>
                  OSC 1.0
                </MenuItem>
                <MenuItem value={'1.1'}>
                  OSC 1.1
                </MenuItem>
              </TextField>

              <Button
                variant='contained' 
                color={ !osc.on ? 'primary' : 'secondary'}
                onClick = { e => {
                  if( osc.on ) {
                    this.tryEosDisconnect()
                  } else {
                    this.tryEosConnect()
                  }
                } }
              >
                { T.get(osc.on ? 'disconnect' : 'connect' ) }
              </Button>
            </div>
            <AsHtml 
              style={{
                maxWidth: 600,
                minWidth: 350,
              }}
              html={T.get('eosExplaination')} 
            />

          </Card>

          <Card
            style={{
              maxWidth: 350,
            }}
          >
            <h2>{T.get('dataFilters')}</h2>
            <SmartToggle 
              onChange = { booleanVal => {
                dispatch( updateOscKey(READ_PATCH_ENABLED, booleanVal) )
              }}
              label={ T.get('readPatchFromEos') }
              value={readPatch}
            />
            <Typography variant="caption" display="block" gutterBottom>
              { T.get('readPatchFromEosTip') }
            </Typography>
            
            <SmartToggle 
              onChange = { booleanVal => {
                dispatch( updateOscKey(READ_PATCH_UNKNOWN, booleanVal) )
              }}
              label={ T.get('includeUnknownFixtures') }
              value={readPatchUnknown}
            />
            <Typography variant="caption" display="block" gutterBottom>
              { T.get('includeUnknownFixturesTip') }
            </Typography>

            <SmartToggle 
              onChange = { booleanVal => {
                dispatch( updateOscKey(EOS_PATCH_ADD_PARTS_TO_CHANNEL, booleanVal) )
              }}
              label={ T.get('eosPatchAddPartsToChannel') }
              value={eosPatchAddPartToChannel}
            />
            <Typography variant="caption" display="block" gutterBottom>
              { T.get('eosPatchAddPartsToChannelTip') }
            </Typography>

            <h2>Commands</h2>
            
            <Button 
              variant='contained'
              color='secondary'
              onClick = { e => {
                if( confirm( T.get('eosSyncConfirm') )) {
                  this.props.dispatch( eosSyncCueList(osc.eosHost, 3032, '{page} | {label}') )
                }
              } }
            >
              { T.get('sendCueSheet') }
            </Button>

            <Button 
              variant='contained'
              color='primary'
              onClick = { e => {
                dispatch( eosRequestPatch() )
              } }
            >
              { T.get('requestPatchFromEos')}
            </Button>
          </Card>

        </CardSeries>
      </div>
    )
  }
}
export default connect(s => {
  const osc = s.osc
  return {
    osc: {
      ...osc,
      eosOscVersion: osc.eosOscVersion ? osc.eosOscVersion : 1.0,
    }
  }
})(EosConnection)