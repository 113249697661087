import Errors from './../Errors'
import Logger, { 
  createExplicitLogEvent,
  DEBUG, 
  ERROR, 
  WARN, 
  INFO 
} from './../../shared/WebLog'
export const LOG_ADD = 'LOG_ADD'
export const LOG_REFRESH = 'LOG_REFRESH'
export const LOG_CLEAR_ITEM = 'LOG_CLEAR_ITEM'
export const LOG_CLEAR = 'LOG_CLEAR'
export const LOG_LOCALSTORAGE_KEY = 'la_local_log'
export const LOG_LEVELS = {
  DEBUG,
  INFO,
  WARN,
  ERROR,
}
const _getLocalItems = () => {
  try {
    let history = JSON.parse(localStorage.getItem(LOG_LOCALSTORAGE_KEY))
    if (!history) {
      history = []
    }
    return history
  } catch (err) {
    console.error('[FATAL]error in log loading')
    console.error(localStorage.getItem(LOG_LOCALSTORAGE_KEY))
    console.error(err)
    return [] //reset the log
  }
}

const _setLocalItems = (arrayOfItems) => {
  localStorage.setItem(LOG_LOCALSTORAGE_KEY, JSON.stringify(arrayOfItems || []))
}

export const addEvent = (level, module, message) => {
	return (dispatch, getState) => {

    const log = createExplicitLogEvent(
      level,
      module,
      message
    )

    let history = _getLocalItems()
    //Fixme add validation here
    history.unshift(log)
    _setLocalItems(history)

    dispatch({
      type: LOG_ADD,
      log: log
    })
  }
}

export const refreshLog = () => {
  let history = _getLocalItems()

	return dispatch => dispatch({
		type: LOG_REFRESH,
    events: history
	})
}

export const clearEvent = (event) => {
  throw new Error('NOT IMPLEMENTED')
}
export const clearLog = (log) => {
  _setLocalItems([])
  return dispatch => dispatch({
    type: LOG_CLEAR
  })
}

export const INITIAL_STATE = {
  events: [],
	loaded: false,
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {

	case LOG_CLEAR:
      return {
        ...INITIAL_STATE,
        loaded: true,
      }

  case LOG_REFRESH: {
    return {
      ...INITIAL_STATE,
      events: action.events || [],
      loaded: true
    }
  }

	case LOG_ADD: {
    const { level, time, module, message } = action.log
    const events = state.events.slice()
    events.unshift({
      level: level,
      time: time,
      module: module,
      message: message
    })

    return {
      ...state,
      events: events
    }
	}

	default:
		return state
	}
}