import { 
  PAPER_US_LETTER_PORTRAIT,
} from './../../../shared/printing/GenericReport'

export default {
  ...PAPER_US_LETTER_PORTRAIT,
  'layout': 'portrait',
  'margins': {
    'top': 36,
    'left': 18,
    'right': 18,
    'bottom': 36
  },
  'paperSize': {
    'width': 612,
    'height': 792
  },
  'name': 'My New Notes',
  'data': 'notes',
  'group': ['priority'],
  'sort': ['priority', 'status', 'department', 'cue'],
  'filters': [
    {
      name: 'Only Open',
      description: 'Only show active notes',
      type: 'equalTo',
      field: '_status',
      value: 'open',
    },
    {
      name: 'Not Private',
      description: 'Do not print private notes',
      type: 'notEqualTo',
      field: 'department',
      value: 'Private',
    },
  ],
  'headerEmpty': 'No Priority',
  'header': [{
    'logo': 'show',
    'posX': 0,
    'posY': 0,
    'width': 300,
    'height': 50
  }, {
    'text': '{show}',
    'posX': 0,
    'posY': 0,
    'fontSize': 16,
    'font': 'Helvetica-Bold',
    'align': 'center',
    'wrap': false
  }, {
    'text': 'My New Report',
    'posX': 0,
    'posY': 18,
    'fontSize': 16,
    'font': 'Helvetica-Bold',
    'align': 'center',
    'wrap': false
  }],
  'heading': {
    'text': '{priority}',
    'font': 'Helvetica-Bold',
    'fontSize': 14,
    'posX': 0,
    'posY': 0,
    'height': 20
  },
  'formatting': {
    'seperator': 'none',
    'start': 'none',
    'end': 'none',
    'vertical': 'none',
    'rowLineColor': '#181818'
  },
  'subheading': {
    'font': 'Helvetica',
    'fontSize': 10,
    'wrap': 'true'
  },
  'people': {
    'disable': false,
    'align': 'left',
    'font': 'Helvetica-Bold',
    'posX': 400,
    'posY': 0
  },
  'footer': [],
  'columns': [{
    'key': '',
    'width': 8,
    'fontSize': 10,
    'align': 'left',
    'label': '',
    'font': 'Helvetica'
  }, {
    'key': 'department',
    'width': 64,
    'label': 'Department'
  }, {
    'key': 'status',
    'width': 52,
    'label': 'Status'
  }, {
    'key': 'date',
    'width': 64,
    'label': 'Day',
    'format': '{day}'
  }, {
    'key': 'date',
    'width': 64,
    'label': 'Date',
    'format': '{shortDate}'
  }, {
    'key': 'description',
    'width': 322,
    'label': 'Description'
  }, {
    'key': '',
    'width': 64
  }],
  'columnsOptional': [{
    'key': '',
    'width': 248
  }, {
    'key': 'relatedCues',
    'width': 150,
    'format': 'Cue {relatedCues}',
    'font': 'Helvetica-Bold'
  }, {
    'key': '',
    'width': 24,
    'align': 'left'
  }, {
    'key': 'relatedChannels',
    'width': 150,
    'format': 'Channels {relatedChannels}',
    'font': 'Helvetica-Bold',
    'align': 'right'
  }]
}