import { 
  call,
  select,
  delay,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import {
  showConfirm
} from './../reducers/InterfaceLogic'

const SAGA_TEST_USE = 'SAGA_TEST_USE'
const SAGA_OUTCOME_SUCCESS = 'SAGA_OUTCOME_SUCCESS'
const SAGA_OUTCOME_FAILURE = 'SAGA_OUTCOME_FAILURE'

function* testAction(args) {
  let random = Math.floor(Math.random()*10)

  yield delay(2000)

  if(random < 5) {
    yield put({
      type: SAGA_OUTCOME_SUCCESS
    })
    console.log(`saga-success ${random}`)
  } else {
    yield put({
      type: SAGA_OUTCOME_FAILURE
    })
    console.log(`saga-FAILURE ${random}`)
  }
}

//DO NOT ALLOW CONCURENT SYNCS
function* testSaga() {
  yield takeLatest(SAGA_TEST_USE, testAction)
}


export const SAGA_SYNC_START = 'SAGA_SYNC_START'

class SyncArguments {
    fileAsString = null
    fileName = null
    onComplete = null
    silent = false
}

const selectSetupVariables = (state) => {
  const sync = new SyncArguments()
  //defaults

  sync.fileAsString = ''
  sync.fileName = state.vectorworks
  sync.onComplete = ()=>{alert('We need to kill the onComplete')}
  sync.silent = false
  sync.error = null

  //Pull from state
  //syncModule = state.instruments.sync
  //vectorworks = state.vectorworks

  return sync

}
function* syncWithVectorworks(action) {
  console.log('action here...')
  const syncArguments = yield select( selectSetupVariables )
  if(syncArguments.error) {
    yield put({

    })
  }

  /*
Steps are -> 
  
1)
  InstrumentLogic::syncWithVectorworks = (fileAsString, fileName, onComplete, silent = false) =>{
    ...this basically just formats the file, mappings, etc... and passes it to
2)  
    shared/vectorworks/index.js::processVectorworksXmlFile -> Promise(processed Object)
    ...this makes the "processed" object that needs documentation or a class or something
    we then call 
3) --probably needs to refactor into some Saga based logic for clarity.
  InstrumentLogic::processVectorworksSynchronization
    ... this basically just takes the "parts" from the processed object and 
    sends it to another method. This is where the bulk of the work happens
    
  */
  //
  //calls -> 
  yield delay(2000)//FIXME -> Remove delays before we do it
  console.log(JSON.stringify(syncArguments))
  yield put( showConfirm('Thunk Test', `Hi I'm a test from a thunk in a redux-saga-put`) )
}

function* syncWithVectorworksSaga() {
  yield takeLatest(SAGA_SYNC_START, syncWithVectorworks)
}

export default syncWithVectorworksSaga