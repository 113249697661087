export default {
  //Loading
  loading: 'Preparing Cue Sheet...',
  //New Show
  newShow: 'New Show',
  close: 'Close',
  //Headings
  number: 'Cue',
  page: 'Page',
  label: 'Label',
  description: 'Description',
  time: 'Time',
  hCue: 'Cue',
  hPage: 'Page',
  hLabel: 'Label',
  hDescription: 'Description',
  actions: 'Actions',

  //Actions
  dismiss: 'Dismiss',
  done: 'Done',
  addCue: 'Add Cue',
  addCues: 'Add {0} Cues',
  add: 'Add',
  settings: 'Settings',

  //Settings
  editing: 'Editor Settings',
  export: 'Export',
  utilities: 'Utilities',
  options: 'Options',

  //Utilities
  deletePrompt: 'Prompt for Delete',
  autoSave: 'Automatically Save',
  dragEnabled: 'Drag and Drop Enabled',
  showDeleted: 'Show Deleted',
  purgeDeleted: 'Purge Deleted Cues',
  purgeDeletedTitle: 'Purge the following cues?',
  purgeDeletedBody: '<p>This will permanently delete the following {COUNT} cues:</p><b>{CUES}</b>.',

  //Cue Row
  '#': '#',
  pg: '.pg',
  sceneBreak: '<Default Scene Break>',

  sceneHelp: 'scene name',
  cueHint: 'cue',
  pageHint: 'page',
  labelHint: 'label',
  descriptionHint: 'description',

  //Notes
  general: 'Memo',
  cue: 'Cue',
  focus: 'Focus',
  work: 'Work',
  private: 'Private',
  takeNote: 'Take a Note',
  note: 'Note',
  complete: 'Complete',
  notesDialog: 'Add a Memo',
  notesAddMemo: 'ADD MEMO',
  convertToNote: 'Convert to Note',
  noteMarkedCompleted: 'Memo for {label} dismissed',
  noteMarkedRestored: 'Memo for {label} restored',
  convertToNoteTip: `Convert this to a public note`,
  notesMemoDescription: `
  <p>
    Press <code>SHIFT+ENTER</code> to submit.
  </p>
  <p>
    Memorandums will not print on the cue sheets, or appear in the notes module.
  </p>
  `,
  //Spots
  spot: 'Spot ',
  spot_pickup: 'Pickup',
  spot_size: 'Size',
  spot_frame: 'Frame',
  spot_location: 'Location',
  spotpickupHint: 'pickup',
  spotsizeHint: 'size',
  spotframeHint: 'frame',
  spotlocationHint: 'location',

  spotSettings: 'Spotlight Settings',
  spotCount: 'Number of Spotlights',
  spotCountPicker: '{0} Spot(s)',
  spotFrameDefaults: 'Default Frames/Colors',
  spotSizeDefaults: 'Default Sizes',
  spotLocationDefaults: 'Default Locations',

  spotSizeFullBody: 'Full Body',
  spotSizeWaist: 'Waist',
  spotSizeShoulder: 'Shoulder',
  spotSizeHead: 'Head',

  addSpotPlaceholder_spotLightColors: 'Add Color/Frame',
  addSpotPlaceholder_spotLightSizes: 'Add Size',
  addSpotPlaceholder_spotLightLocations: 'Add Location',

  spotDefault: 'Open',
  spotDefault1: 'Frame 1',
  spotDefault2: 'Frame 2',
  spotDefault3: 'Frame 3',
  spotDefault4: 'Frame 4',
  spotDefault5: 'Frame 5',
  spotDefault6: 'Frame 6',

  spotLocationDSL: 'DSL',
  spotLocationDSR: 'DSR',
  spotLocationCSL: 'CSL',
  spotLocationCSR: 'CSR',
  spotLocationUSL: 'USL',
  spotLocationUSR: 'USR',
  spotLocationUSC: 'USC',
  spotLocationAudience: 'Audience',

  //Cue Sheet Columns
  cueSheetSettings: 'Cue Sheet Settings',
  cueSheetColumns: 'Columns',
  cueSheetEditor: 'Editor Settings',

  //Headers
  downloadTsv: 'Download TSV',
  downloadUSITT: 'Download USITT',
  downloadSimplePDF: 'Simple Cue Sheet',
  downloadDetailed: 'Detailed Cue Sheet',

  //Delete Dialog
  dialogDeleteTitle: 'Delete cue ({number})?',
  dialogDeleteBody: '<p>This will remove <b>cue ({number})</b> from the cue stack. You can recover this in the deleted cues section.</p> <p><i>(To turn this dialog off turn off "prompt for delete" above)</i></p>',
  confirm: 'Confirm',
  delete: 'Delete',
  cancel: 'Cancel',

  //Check Sequencing
  checkSequence: 'Check Sequencing',
  cueSheetStatus: 'Cue Sheet Sequencing',
  totalCues: 'Total Cues',
  sequenceProblems: 'Sequencing Problems',
  sequenceProblemsNone: 'None',
      //NOTE! All cue parts are part of the argument object
  sequenceProblemsStart: '<div>The cue ({previous}) is before ({problem}).</div> <b>({problem})</b> pg. {page} | "{label}" is likely out of order',

  //Tooltips
  tipAddCue: 'Add a New Cue',
  tipAddHeader: 'Add a Scene Break',
  tipRemoveHeader: 'Remove Scene Break',
  tipCancel: 'Cancel Changes',
  tipDelete: 'Delete Cue',
  tipSave: 'Save',
  tipUndelete: 'Restore Deleted Cue',
  tipAddNote: 'Add a Note for this Cue',
  tipAddImage: 'Add an Image to this Cue',
  tipHideSpots: 'Hide Spotlights for this Cue (Does not delete)',
  tipShowSpots: 'Show Spotlights for this Cue',

  //OSC Messages
  oscTrackingEnable: 'Enable OSC Tracking',
  oscTrackingDisable: 'Disable OSC Tracking',
  oscNotEnableTitle: 'OSC Not Connected',
  oscGoToCurrentCue: 'GOTO Current OSC Cue',
  oscNotEnabledMessage: `
  <p>
    There is no connection to a valid console
    Please connect via the OSC Integration Window and try again.
  </p>

  <p>
    Currently we support cue tracking for:
    <ul>
      <li>Eos Family Consoles (2.9 tested)</li>
    </ul>

    We currently support cue list transfer to:
    <ul>
      <li>Eos Family Consoles</li>
      <li>Figure 53 QLab</li>
      <li>Any console that reads USITT Text files</li>
    </ul>
  </p>

  <p>
    If there is a console you wish to support please contact Daniel B. Chapman at
    chapman@danielbchapman.com.
  </p>
  `,
  trackOsc: 'Track Live OSC',
  skipMemos: 'Skip Memos'
}