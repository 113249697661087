import React from 'react'
import { connect } from 'react-redux'
import { makeStyles, withStyles } from '@material-ui/styles'
import moment from 'moment'
import {
  Button,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableFooter,
  Tooltip,
} from '@material-ui/core'

import {
  mergeNote,
  completeNote,
  deleteNote,
  showNoteDialog,
  showDeleted,
  showCompleted,
} from './../reducers/NotesLogic'

import {
  getNotesTableVisibilitySettings
} from './../reducers/SettingsLogic'

import IconAccept from '@material-ui/icons/Done'
import IconCancel from '@material-ui/icons/NotInterested'
import IconCompleted from '@material-ui/icons/Check'
import IconDeleted from '@material-ui/icons/DeleteForever'
import IconDelete from '@material-ui/icons/DeleteForever'
import IconUndelete from '@material-ui/icons/Restore'
import IconComplete from '@material-ui/icons/DoneAll'
import IconEdit from '@material-ui/icons/Edit'

import { notes as Strings } from './../../shared/language/'

const COLOR_ASSISTANT = 'hsl(0, 50%, 35%)'
const COLOR_LIGHTING = 'hsl(45, 50%, 35%)'
const COLOR_VIDEO = 'hsl(90, 50%, 35%)'
const COLOR_COSTUMES = 'hsl(135, 50%, 35%)'
const COLOR_SCENERY = 'hsl(180, 50%, 35%)'
const COLOR_SOUND = 'hsl(225, 50%, 35%)'
const COLOR_MANAGEMENT = 'hsl(270, 50%, 35%)'

export const buttonStyle = {
  maxWidth: '48px', 
  maxHeight: '48px',
  minWidth: '48px',
  minHeight: '48px',
}

const StripedRow = withStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: window.theme.palette.action.hover,
    },
  }
})(TableRow)

const DarkTableHead = withStyles({
  root: {
    'border': '2px solid white'
  }
})(TableHead)

const DarkTableCell = withStyles({
  root: {
    'border-top': `1px solid ${window.theme.palette.common.white}`,
    'border-bottom': `1px solid ${window.theme.palette.common.white}`,
    'color': 'white',
    'background-color': window.theme.la_blue, 
    'font-weight': 'bold',
    'margin-top': 0,
    'margin-bottom': 0,
    'padding-top': 4,
    'padding-bottom': 0,
  },
  left: {
    'border-left': `1px solid ${window.theme.palette.common.white}`
  },
  right: {
    'border-right': `1px solid ${window.theme.palette.common.white}`
  }
})(props=>{
  const { left, right, classes} = props
  // if(props.left) {
  //   return (<TableCell className={props.classes.left}>{props.children}</TableCell>)
  // }

  // if(props.right) {
  //   return (<TableCell className={props.classes.right}>{props.children}</TableCell>)
  // }
  if(left) {
    return (<TableCell className={`${classes.root} ${classes.left}`}>{props.children}</TableCell>)
  }

  if(right) {
    return (<TableCell className={`${classes.root} ${classes.right}`}>{props.children}</TableCell>)
  }

  return (<TableCell className={classes.root}>{props.children}</TableCell>)
})

class NotePageTable extends React.Component {
  constructor(props) {
    super(props)

    this.mappings = {}
    this.mappingsFont = {}
    const T = Strings

    this.mappings[T.get('assistant')] = COLOR_ASSISTANT
    this.mappings[T.get('lighting')] = COLOR_LIGHTING
    this.mappings[T.get('video')] = COLOR_VIDEO
    this.mappings[T.get('scenery')] = COLOR_SCENERY
    this.mappings[T.get('costumes')] = COLOR_COSTUMES
    this.mappings[T.get('sound')] = COLOR_SOUND
    this.mappings[T.get('management')] = COLOR_MANAGEMENT

    this.mappingsFont[T.get('assistant')] = 'black'
    this.mappingsFont[T.get('lighting')] = 'black'
    this.mappingsFont[T.get('video')] = 'black'
    this.mappingsFont[T.get('scenery')] = 'black'
    this.mappingsFont[T.get('costumes')] = 'black'
    this.mappingsFont[T.get('sound')] = 'black'
    this.mappingsFont[T.get('management')] = 'black'
  }

  /**
   * Displays some complex style information
   * @param {*} index 
   * @param {*} note 
   * @param {*} fontColor 
   * @param {*} backgroundColor 
   */
  departmentStyle = (index, note, fontColor, backgroundColor) => {
    const containerStyle = {
      borderLeft: `5px solid ${backgroundColor}`,
      // display: 'flex',
      // flexDirection: 'row',
      // backgroundColor: index % 2 == 0 ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,1.0)',
    }

    return containerStyle

    const clear = 'rgba(0,0,0,0)'
    const highlight = backgroundColor
    let bgImage = `linear-gradient(to right, ${highlight}, ${clear}, ${clear}, ${clear}, ${clear})`
    // if(this.state.hover) {
    //   bgImage = `linear-gradient(to right, ${highlight}, ${clear}, ${clear}, ${clear}, ${highlight})`
    // }

    const base = {
      ...containerStyle,
      backgroundImage: bgImage,
      color: note.completed ? containerStyle.color : fontColor,
    }
    const style = {
      ...base
    }

    const styleUnder = {
      ...base
    }

    return base
  }
  render() {
    const T = Strings
    const { dispatch } = this.props
    const STYLE_CLOSED = {
      color: window.theme.palette.text.disabled,
      textDecoration: 'line-through',
    }

    const STYLE_DELETED = {
      color: window.theme.palette.error.main,
      textDecoration: 'line-through',
    }
    const notes = this.props.notes || []
    let lastDepartment = -2 //not null

    const join = (list, prefix = '', suffix = '') => {
      if(!list || list.length < 1) {
        return ''
      }

      return list.map(x => prefix + x + suffix).join(', ')
    }

    const headerCell = (key, first = false, last = false) => {
      if(first) {
        return (
          <DarkTableCell left>
            { Strings.soft(key) }
          </DarkTableCell>
        )
      }

      if(last) {
        return (
          <DarkTableCell right>
            { Strings.soft(key) }
          </DarkTableCell>
        )
      }

      return (
        <DarkTableCell>
          { Strings.soft(key) }
        </DarkTableCell>
      )
    }

    const staticColumns = this.props.notesTableVisibility

    const firstColumn = staticColumns[0]
    const bodyColumns = staticColumns.slice(1)

    return (
      <Table 
        stickyHeader={true}
      >
        <DarkTableHead>
          <TableRow>
            { headerCell(firstColumn.name, true, false)}
            { 
              bodyColumns.map(x => {
                if(x.hidden) {
                  return null
                }
                //FIXME More functionality later
                return headerCell(x.name)
              })
            }
            { headerCell('', false, true)}
          </TableRow>
        </DarkTableHead>
        <tbody>
          { notes.map((x,i)=>{
            const result = []
            if(lastDepartment !== x.department) {
              lastDepartment = x.department
            }

            const fontColor = this.mappingsFont[x.department]
            const backgroundColor = this.mappings[x.department]
            const colorCode = this.departmentStyle(i, x, fontColor, backgroundColor)
            const style = x.status == 'open' ? {} : x.status == 'completed' ? STYLE_CLOSED : STYLE_DELETED 
            const renderData = (key) => {
              if(key == 'relatedChannels' ) {
                return join(x.relatedChannels, '(', ')')
              } else if (key == 'relatedCues') {
                return join(x.relatedCues, 'Q')
              } else if (key == 'time') {
                if(!x.date) {
                  return '-'
                } else {
                  return moment(x.date).format('h:mmA')
                }
              } else if (key == 'day') {
                if(!x.date) {
                  return '-'
                } else {
                  return moment(x.date).format('dddd')
                }
              } else if (key == 'date') {
                if(!x.date) {
                  return '-'
                } else {
                  return moment(x.date).format('YYYY-DD-MM HH:mm:ss')
                }
              } else {
                return Strings.soft(x[key]) || '-'
              }
            }
            result.push(
              <StripedRow 
                style={style}
                key={x.uuid}
              >
                <TableCell
                  style={colorCode}
                >
                  { renderData(firstColumn.name) }
                </TableCell>
                { bodyColumns.map(spec => {
                  if(spec.hidden) {
                    return null
                  }

                  return (
                    <TableCell>
                      { renderData(spec.name) }
                    </TableCell>
                  )  
                } ) }
                <TableCell>
                  <div
                    style={{ //I'm leaving padding for the speed-dials
                      minWidth: 144+48,
                      maxWidth: 144+48,
                    }}
                  >
                    <Tooltip title={ !x.completed ? T.get('tipCompleteNote') : T.get('tipReopenNote') }>
                      <Button 
                        style={buttonStyle}
                        size='small'
                        onClick={ e  => { 
                          if(x.completed) {
                            const copy = {
                              ...x,
                              completed: false,
                              deleted: false,
                              status: 'open',
                            }
                            this.props.dispatch( mergeNote( copy , copy.uuid ) )
                          } else {
                            this.props.dispatch( completeNote(x) )
                          }

                        } }
                      >
                        { x.completed ? 
                          <React.Fragment>
                            <IconUndelete />
                          </React.Fragment>
                        : 
                          <React.Fragment>
                            <IconComplete />
                          </React.Fragment>
                        }
                      </Button>
                    </Tooltip>

                    <Tooltip title={ T.get('tipEditFields') }>
                      <Button 
                        style={buttonStyle}
                        size='small'
                        onClick={ e  => { 
                          this.props.dispatch(
                            showNoteDialog({
                              ...x
                            })
                          )
                        } }
                      >
                        <IconEdit />
                      </Button>
                    </Tooltip>

                    <Tooltip title={ !x.deleted ? T.get('tipDeleteNote') : T.get('tipRestoreNote') }>
                      <Button 
                        style={buttonStyle}
                        size='small'
                        color='secondary' 
                        onClick={ e  => { 
                          if(x.deleted) {
                            const copy = {
                              ...x,
                            }
                            copy.deleted = false
                            copy.completed = false
                            copy.status = 'open'
                            dispatch( mergeNote( copy, copy.uuid ) )
                          } else {
                            dispatch( deleteNote( x ) )
                          }
                        } } 
                      >
                        { x.deleted ? 
                          <React.Fragment>
                            <IconUndelete />
                          </React.Fragment>
                          : 
                          <React.Fragment>
                            <IconDelete />
                          </React.Fragment>              
                        }
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>
              </StripedRow>
            )
            // result.push(
            //   <tr>
            //     <td colSpan={4}>
            //       {JSON.stringify(x)}
            //     </td>
            //   </tr>
            // )

            return result
          })}
        </tbody>
      </Table>
    )
  }
}

export default connect(s => {
  return {
    notesTableVisibility: getNotesTableVisibilitySettings(s),
    showDeleted: s.notes.showDeleted,
    showCompleted: s.notes.showCompleted,
  }
})(NotePageTable)