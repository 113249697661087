import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import CueRow, { pageStyle } from './CueRow'
import translate from '../../shared/language/Translate'
import notes from '../../shared/language'
import Toggle from './../../components/Button/Toggle'
import Colors from './../Colors'
import {
  Button,
  Chip,
  Drawer,
  Fab,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
  TextField,
} from '@material-ui/core'

import TextFieldRef from './../../components/TextFieldRef'

import ContentAdd from '@material-ui/icons/Add'
import ContentInsert from '@material-ui/icons/PlaylistAdd'
import IconSettings from '@material-ui/icons/Settings'
import IconClose from '@material-ui/icons/Close'
import IconPlay from '@material-ui/icons/PlayArrow'
import IconNote from '@material-ui/icons/Flag'

import { 
  addCue, 
  appendCues,
  mergeCueData,
  updateUi, 
  noteCue, 
  dismissNote, 
  downloadCusTsv, 
  downloadCuesUSITT,
  checkSequential,
  cuesTrackOsc,
  purgeDeleted,
} from '../reducers/CueLogic'

import {
  updateSetting,
} from '../reducers/SettingsLogic'

import {
  showNoteDialog,
  dismissNoteDialog,
  buildCueNotesCache,
} from '../reducers/NotesLogic'

import { printSimpleCueSheet } from './../reducers/ElectronLogic'

import {
  ifNullThen,
  isEmptyOrWhitespace,
} from './../../shared/StringUtil'

import {
  num
} from './../../shared/Utility'

import CueMemoDialog from './CueMemoDialog'

import Spot from './Spot'

import {
  showDialog, showSnack
} from './../reducers/InterfaceLogic'

const spotCounts = [1,2,3,4,5,6,7,8]
const mappings = [
  {
    key: 'number',
    shortKey: '#',
    width: 100,
    style: {
      fontWeight: 'bold',
    },
    formatter: (val) => {
      if( isEmptyOrWhitespace(val) ) {
        return ''
      } else {
        return `(${val})`
      }
    }
  }, {
    key: 'page',
    shortKey: 'pg.',
    width: 100,
  }, {
    key: 'time',
    width: 100,
  }, {
    key: 'label',
    width: 250,
  }, {
    key: 'description',
    width: 300,
    multiline: true,
  },
]

const settingsCard = {
  display: 'flex',
  flexDirection: 'column',
  minWidth: '256px',
  padding: '16px'
}

const chipBox = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
}

class CueSheet extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      drawer: false,

      //OSC Tracking
      oscCue: null,
      oscList: null,
      oscConsumed:  false,
    }

    this.temp = {}
    this.cueRefs = {}


  }

  //key={q._id + q._rev}
  scrollToCue(num) {
    if(num !== undefined && num !== null && num !== '' && this.props.cues) {
      //Find the cue
      let index = this.props.cues.findIndex(x => x.number == num)
      if(index > -1) {
        const cue = this.props.cues[index]
        const lookupId = cue._id + '::' + cue._rev
        const cueRef = this.cueRefs[lookupId]

        const node = ReactDOM.findDOMNode(cueRef)
        if(!node) {
          this.props.dispatch( showSnack(`Unable to locate cue ${num}@${index}`) )
        }
        const parentNode = ReactDOM.findDOMNode(this)

        node.scrollIntoViewIfNeeded({
          behavior: 'smooth',
          block: 'center',
        })


        const HIGHLIGHT_CLASS = 'overlay-highlight'
        node.classList.add(HIGHLIGHT_CLASS)
        setTimeout(() => {
          node.classList.remove(HIGHLIGHT_CLASS)
        }, 8000)
        //alert(`found cue ${index} index for ${num}`)
        
      } else {
        //alert(`unable to locate ${num}`)
      }
    } else {
      //alert(`cues are empty... ${num}`)
    }
  }

  openDrawer() {
    this.setState({
      ...this.state,
      drawer: true
    })
  }

  closeDrawer() {
    this.setState({
      ...this.state,
      drawer: false
    })
  }

  _updateSpotSetting(setting, value) {
    if(setting) {
      this.props.dispatch(
        updateSetting(setting, value)
      )
    }

  }

  //FIXME Outdated, this need to be migrated to a new hook location...
  componentWillReceiveProps(props) {
    //Kill the editor, we have a conflict
    console.log('INCOMING PROPS--CueSheet ', props + this.props.flag)

    //Live Cue Tracking Method
    const oscCue = props.oscCue
    const oscList = props.oscList
    let oscConsumed = this.state.oscConsumed  && this.state.oscCue == props.oscCue //we already tracked this

    //Dispatch scroll event
    if(props.trackOsc && !oscConsumed) {
      setTimeout(() => {
        this.scrollToCue(oscCue)
      }, 250) //250ms delay
    }
    this.setState({
      ...this.state,
      //OSC Tracking
      oscCue: oscCue,
      oscList: oscList,
      oscConsumed:  true,
    })
  }

  renderHeaders() {
    const T = this.props.strings
    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          lineHeight: '48px',
          fontWeight: 'bold',
        }}
      >
        <div
          style={{
            width: 64
          }}
        >
          {/* ADD CUE */}
        </div>

        { mappings.map( (mapping) => {
          return (
            <div
              key={ mapping.key }
              style={{
                width: mapping.width 
              }}
            >
              { T.get( mapping.key )}
            </div>
          )
        })}

        <div
          style={{
            width: 192 //4 buttons
          }}
        >
          { T.get('actions') }
        </div>
      </div>
    )
  }

  _spotOptionsEditor(key, defaults) {
    const refKey = key + 'Ref'
    const T = this.props.strings
    const dataSet = defaults[key] || []
    return (
      <React.Fragment>
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <TextFieldRef 
          //value={this.temp['addColor'] || ''}
          ref={x => this.temp[refKey] = x}
          label={T.get('addSpotPlaceholder_' + key)}
          onChange={e => {
            let newVal = e.target.value
            this.temp[key] = newVal
          }}
        />
        <Button
          color='primary'
          size='small'
          onClick={e => {
            const newString= this.temp[key]

            const strings = defaults[key].slice()
            strings.push(newString)

            this._updateSpotSetting(key, strings)
            const field = this.temp[refKey]
            field.clear()
          }}
        >
            <ContentAdd /> { T.get('add') }
        </Button>
      </div>
      <div style={{height: 12}}>{/*SPACER*/}</div>
      <div style={chipBox}>
        { dataSet.map((c, i)=>{
          return (
            <Chip 
              key={i} 
              label={c} 
              onDelete={ e => {
                let copy = defaults[key].slice()
                copy.splice(i, 1)
                this._updateSpotSetting(key, copy)
              } }
            />
          )
        } ) }
      </div>
      </React.Fragment>
    )
  }

  _spotDefaults(props) {
    const T = props.strings

    return {
      spotCount: props.spotCount || 4,
      spotWidth: props.spotWidth || 120,
      spotLightColors: props.spotLightColors || [ 
        T.get('spotDefault'),
        T.get('spotDefault1'),
        T.get('spotDefault2'),
        T.get('spotDefault3'),
        T.get('spotDefault4'),
        T.get('spotDefault5'),
        T.get('spotDefault6'),
      ],
      spotLightSizes: props.spotLightSizes || [ 
        T.get('spotSizeShoulder'),
        T.get('spotSizeWaist'),
        T.get('spotSizeFullBody'),
        T.get('spotSizeHead'),
      ],
      spotLightLocations: props.spotLightLocations || [
        T.get('spotLocationDSL'),
        T.get('spotLocationDSR'),
        T.get('spotLocationCSL'),
        T.get('spotLocationCSR'),
        T.get('spotLocationUSL'),
        T.get('spotLocationUSR'),
        T.get('spotLocationUSC'),
        T.get('spotLocationAudience'),
      ],
    }
  }

  render(){
    //reusable toggles
    const OSC_ENABLED = this.props.trackOsc && this.props.oscOn

    const headers = () => {
      return this.renderHeaders()
    }
    const headerButtonStyle = {
      color: '#fff',
      marginLeft: '-15px'
    }
    const spacer = () => {
      return <div style={{width:12, height: 12}}></div>
    }
    const T = this.props.strings
    
    const { 
      dispatch, 
      cues, 
      flag, 
      autoSave, 
      deletePrompt, 
      dragEnabled,
      showDeleted, 
      trackOsc,
      oscOn,
      settings
    } = this.props

    const spotLightDefaults = this._spotDefaults(this.props)
    const { 
      spotCount,
      spotWidth,
      spotLightColors,
      spotLightSizes,
      spotLightLocations,
    } = spotLightDefaults

    const toolbarButton = (key, action) => {
      return (
        <Button
          variant='contained'
          className={'no-print'}
          color='primary'
          style={{ marginTop: '15px' }}
          onClick={e => {
            if(action){
              action(e)
            } }
          }
        >
          { T.get(key) }
        </Button>
      )
    }

    const title = (key) => {
      return (
        <Typography variant='title'>
          { T.get( key) }
        </Typography>
      )
    }

    const subheading = (key) => {
      return (
        <Typography 
          variant='subheading' 
          color='primary' 
          style={{ 
            marginTop: '12px',
          }}
        >
          { T.get( key) }
        </Typography>
      )
    }

    let top = 64
    const next = () => {
      let now = top
      top += 48
      return now
    }
    return (
      <div 
        style={{
          marginLeft: '24px',
          marginRight: '24px',
        }}
        className='container cue-sheet'
      >
        <Tooltip title={T.get('editing')} placement='left'>
          <Fab
            style={{
              position: 'absolute',
              zIndex: 50,
              top: next(),
              right: 48,
            }}
            color='primary'
            size="small"
            onClick={ () => this.openDrawer() }
          >
            <IconSettings /> 
          </Fab>
        </Tooltip>

        <Tooltip 
          placement='left'
          title={T.get('takeNote')}
        >
          <Fab
            style={{
              position: 'absolute',
              zIndex: 50,
              top: next(),
              right: 48,
            }}
            size="small"
            color='primary'
            onClick={ () => {
              dispatch (
                showNoteDialog({}, OSC_ENABLED ) 
              )
            } }
          >
            <IconNote /> {this.props.oscCue || ''}
          </Fab>
        </Tooltip>

        <Tooltip 
          title={this.props.trackOsc ? T.get('oscTrackingDisable') : T.get('oscTrackingEnable') } 
          placement='left'
        >
          <Fab
            style={{
              position: 'absolute',
              zIndex: 50,
              top: next(),
              right: 48,
            }}
            size="small"
            color={ this.props.trackOsc ? 'secondary' : 'default'}
            onClick={ () => {
              if(!this.props.oscOn) {
                dispatch( 
                  showDialog(
                    T.get('oscNotEnableTitle'),
                    T.get('oscNotEnabledMessage'),
                  )
                )

                return
              }
              if(this.props.trackOsc) {
                dispatch( cuesTrackOsc(false) )
              } else {
                dispatch( cuesTrackOsc(true) )
              }
            } }
          >
            { !this.props.trackOsc ? <span style={{textDecoration: 'line-through'}}>OSC</span> : <span>OSC</span> }
          </Fab>
        </Tooltip>

        <Tooltip
          placement='left'
          title={T.get('oscGoToCurrentCue')}
        >
          <Fab
            style={{
              position: 'absolute',
              zIndex: 50,
              top: next(),
              right: 48,
            }}
            disabled={!OSC_ENABLED}
            size="small"
            color='secondary'
            onClick={ () => this.scrollToCue(this.props.oscCue) }
          >
            <IconPlay /> 
          </Fab>
        </Tooltip>

        <Drawer
          anchor='top'
          open={this.state.drawer}
          onClose={ () => this.closeDrawer() }
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div style={{
            flex: 0,
            display: 'flex',
            flexDirection: 'row',
            minHeight: 48,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 4,
            backgroundColor: window.theme.la_blue,
            boxShadow: Colors.SHADOW_BLACK,
            borderBottom: '1px solid black',
            zIndex: 99,
          }}>
            <div 
              style={{
                flex: 1, 
                display: 'flex', 
                alignItems: 'center', 
                color: window.theme.palette.common.white,
              }}
            >
              <h2 style={{ margin: 0,}}>Cue Sheet Settings</h2>
            </div>
            <div style={{flex: 0, paddingTop: 6,}}>
              <Button
                style={{
                  color: window.theme.palette.common.white,
                }}
                onClick={e => {
                  this.closeDrawer()
                }}
              >
                <IconClose /> { T.get('close') }
              </Button>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              overflowY: 'auto',
              overflowX: 'auto',
            }}
          >
          
            <Paper style={ settingsCard }>
              { title('utilities') }

              { toolbarButton(
                'checkSequence', ()=>{ 
                  dispatch( checkSequential() ) 
                } )
              }

              { toolbarButton(
                'purgeDeleted', ()=>{ 
                  dispatch( purgeDeleted() ) 
                } )
              }
            </Paper>

            <Paper style={ settingsCard }>
              { title('export') }
              
              { toolbarButton(
                'downloadTsv', () => { 
                  dispatch( downloadCusTsv() ) 
                } )
              }

              { toolbarButton(
                'downloadUSITT', () => { 
                  dispatch( downloadCuesUSITT() ) 
                } )
              }
  
            </Paper>

            <Paper style={ settingsCard }>
              { title('spotSettings') }

              { subheading('spotCount') }
              <TextField 
                  select
                  value={spotCount}
                  onChange={ e => {
                    dispatch(
                      this._updateSpotSetting('spotCount', e.target.value || 2)
                     )
                  }}
                >
                  { spotCounts.map((v) => {
                    return (
                      <MenuItem key={v} value={v}>
                        { T.get('spotCountPicker', v) }
                      </MenuItem>
                    )
                  } ) }
                </TextField>

              { subheading('spotFrameDefaults') }
              { this._spotOptionsEditor('spotLightColors', spotLightDefaults) }
              
              { subheading('spotSizeDefaults') }
              { this._spotOptionsEditor('spotLightSizes', spotLightDefaults) }

              { subheading('spotLocationDefaults') }
              { this._spotOptionsEditor('spotLightLocations', spotLightDefaults) }

                {/* <p style={{color: 'red'}}>
                <b>WARNING:</b> There is currently a problem with the 
                Component API for "Editable Selects". You need to 
                chose to use a text field for positions and sizes or you
                can use the selects. If you want to type in non-standard
                information you should uncheck "Enable Suggestions".
                </p> */}
            </Paper>

            <Paper style={ settingsCard }>
              { title('cueSheetSettings')}
              { subheading('cueSheetColumns') }

              { mappings.map( (col, i) => {
                return (
                  <label key={i}>
                    key: <tt>{col.key}</tt> width: <tt>{col.width}</tt>
                  </label>
                )
              })}

              { subheading('cueSheetEditor') }

              <Toggle
                label={T.get('deletePrompt')}
                color='primary'
                value={deletePrompt}
                onChange={x => {
                  dispatch( updateUi( { deletePrompt: x } ))
                }}
              />

              <Toggle
                label={T.get('autoSave')}
                color='primary'
                value={autoSave}
                onChange={x => {
                  dispatch( updateUi( { autoSave: x } ))
                }}
              />

              <Toggle
                label={T.get('dragEnabled')}
                color='primary'
                value={dragEnabled}
                onChange={x => {
                  dispatch( updateUi( { dragEnabled: x } ))
                }}
              />

              <Toggle
                label={T.get('showDeleted')}
                color='secondary'
                value={showDeleted}
                onChange={x => {
                  dispatch( updateUi( { showDeleted: x } ))
                }}
              />  

              <Toggle
                label={T.get('trackOsc')}
                color='secondary'
                value={trackOsc}
                onChange={x => {
                  dispatch( updateUi( { trackOsc: x } ))
                }}
              />

              <Toggle
                label={T.get('skipMemos')}
                color='secondary'
                value={this.props.skipMemos}
                onChange={x => {
                  dispatch( updateUi( { skipMemos: x } ))
                }}
              />        
            </Paper>
          </div>
        </Drawer>

        <div className='row cue-row'
          style={{
            lineHeight: '48px',
          }}
        >
            <div 
              className='scene-top first'
              style={{
                display: 'flex',
                color: window.theme.palette.text.primary,
                alignItems: 'center',
              }}
            >
              <Typography 
                variant='title' 
                style={{
                  color: window.theme.palette.common.white,
                  lineHeight: '48px',
                }}
              >
                {this.props.show.show.currentShow || T.get('newShow') }
              </Typography>

              <div style={{flex: 1}}>{/*SPACER*/}</div>

              <Button 
                style={{
                  color: window.theme.palette.common.white,
                  marginRight: '48px',
                }}
                onClick={ e => { 
                  this.openDrawer()
                } }
              >
                { T.get('settings') }
              </Button>
            </div>
        </div>

        <div>
          { this.renderHeaders() }
        </div>

        { cues && cues.length ? cues.map((q, index) => {
          const lookupId = q._id + '::' + q._rev
          const notesCount = (this.props.cueNotes[q.number] || []).length
          const compositeKey = lookupId + '::' + notesCount
          return (
            <CueRow  
              key={compositeKey} 
              oscCue = { this.props.oscCue }
              oscList = { this.props.oscList}
              ref={ r => { 
                this.cueRefs[lookupId] = r
                return lookupId
              } }
              cueSheet= { this }
              mappings={ mappings }
              renderHeaders = { headers }
              showDeleted={showDeleted}
              autoSave={autoSave}
              deletePrompt={deletePrompt}
              dragEnabled={dragEnabled}
              dispatch={dispatch}
              flag={flag}
              cue={q}
              lastCue={cues[index -1]}
              nextCue={cues[index + 1]}
              alt={index % 2 === 1 ? index : null} 

              
              spotCount={spotCount} //we should refactor this out
              spotWidth={spotWidth}
              spotLightColors={spotLightColors}
              spotLightSizes={spotLightSizes}
              spotLightLocations={spotLightLocations}
              characterNames={this.props.characterNames}

              //NOTES
              notes={this.props.notes}
              cueNotes={this.props.cueNotes}
            />
          )
        }) 
        : 
          <Paper
            style={{
              padding: 24,
              maxWidth: 450,
            }}
          >
            <h2>The cue list is currently empty.</h2>
            <p>
              When you add a cue using the <span style={{color: Colors.MATERIAL_SECONDARY}}><ContentAdd /> ADD CUE</span> button it
              will append the cue to the bottom of the list. The new cue 
              includes an <ContentInsert style={{width:18, height: 18}} color='secondary'/> button to insert a cue after it.
            </p>

            <p>
              You can also drag and drop cues, add images, and adjust the 
              way the cue sheet behaves in the <IconSettings style={{width:18, height: 18}} color='secondary'/> Settings .
            </p>
          </Paper>
        }

        <Button
          className={'no-print'}
          style={ { 
            marginTop: '15px',
            marginRight: 15,
          } }
          variant='contained'
          color='primary'
          onClick={ e => {
            dispatch(addCue({
              label : ''
            }))
          } }
        >
          <ContentAdd /> { T.get('addCue') }
        </Button>

        <Button
          className={'no-print'}
          style={ { 
            marginTop: '15px',
            marginRight: 15,
          } }
          variant='contained'
          color='primary'
          onClick={ e => {
            dispatch( appendCues(5) )
          } }
        >
          <ContentAdd /> { T.get('addCues', 5) }
        </Button>

        <Button
          className={'no-print'}
          style={ { 
            marginTop: '15px',
            marginRight: 15,
          } }
          variant='contained'
          color='primary'
          onClick={ e => {
            dispatch( appendCues(10) )
          } }
        >
          <ContentAdd /> { T.get('addCues', 10) }
        </Button>    

        <div
          id='scroll-end-spacer' 
          style={{
            height: 256, //4 rows
            width: 512,
          }}
        >
          <p>NEW: To add a new cue press CTRL or CMD or ALT + L (on the web ALT-L)</p>
          <button 
            id='select-last-cue'
            style={{display:'none'}}
            onClick={e => {
              const lastCue = this.props.cues[this.props.cues.length - 1]
              if(lastCue) {
                let el = document.getElementById(`${lastCue._id}_page`)
                if(el) {
                  el.click()
                }
              }
            }}
          >
            
          </button>
          {/*BOTTOM SPACER*/}
        </div>

        <CueMemoDialog 
          {...this.props.note}
          dispatch={dispatch} 
        />
      </div>
    )
  }
}

export default connect(s => {
  const cues = s.cues.cues || []
  const cueNotes = s.notes && s.notes.cue && s.notes.cue.cueNotes || {}
  const notes = s.notes.notes || {}

  return {
    //Data
    cues : cues.slice(),
    cueNotes: {...cueNotes},
    notes: notes,
    note: s.cues.note,
    settings : s.cues,
    loaded: s.cues.loaded,
    show: s.show,
    characterNames: s.cues.characters || [],

    //Persistent Settings
    spotLightLocations: s.settings.spotLightLocations,
    spotLightSizes: s.settings.spotLightSizes,
    spotLightColors: s.settings.spotLightColors,
    spotCount: s.settings.spotCount,
    spotWidth: s.settings.spotWidth,

    //Temporary Settings
    autoSave: !!s.cues.autoSave,
    deletePrompt: !!s.cues.deletePrompt,
    dragEnabled: !!s.cues.dragEnabled,
    showDeleted: !!s.cues.showDeleted,

    //OSC Tracking
    trackOsc: s.cues.trackOsc,
    oscCue: s.osc.cue,
    oscList: s.osc.list,
    oscOn: !!s.osc.on,
    skipMemos: !!s.cues.skipMemos
  }
})(translate('CueSheet')(CueSheet))