import React from 'react'
import {
  SvgIcon
} from '@material-ui/core'


export const SpotlightIcon = (props) => {
  return (
    <SvgIcon 
      viewBox='0 0 48 48'
    >
      <path d="M18 42c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-2H18v2zm6-38c-7.73 0-14 6.27-14 14 0 4.76 2.38 8.95 6 11.48V34c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-4.52c3.62-2.53 6-6.72 6-11.48 0-7.73-6.27-14-14-14zm5.71 22.2L28 27.39V32h-8v-4.6l-1.71-1.19C15.6 24.33 14 21.27 14 18.01c0-5.51 4.49-10 10-10s10 4.49 10 10c0 3.25-1.6 6.31-4.29 8.19z"/>
    </SvgIcon>
  )
}

export const LabelOff = (props) => {
  return (
    <SvgIcon 
      viewBox='0 0 24 24'
    >
      <path d="M16,7l3.55,5l-1.63,2.29l1.43,1.43L22,12l-4.37-6.16C17.27,5.33,16.67,5,16,5L8.63,5.01L10.63,7H16z"/>
      <path d="M2,4.03l1.58,1.58C3.22,5.96,3,6.46,3,7v10c0,1.1,0.9,1.99,2,1.99L16,19c0.28,0,0.55-0.07,0.79-0.18L18.97,21l1.41-1.41
        L3.41,2.62L2,4.03z M14.97,17H5V7.03L14.97,17z"/>
    </SvgIcon>
  )
}

export default {
  SpotlightIcon: SpotlightIcon,
  LabelOff: LabelOff,
}