import cues from './reducers/CueLogic'
import instruments from './reducers/InstrumentLogic'
import notes from './reducers/NotesLogic'
import language from './reducers/LanguagesLogic'
import user from './reducers/UserLogic'
import show from './reducers/ShowLogic'
import ui from './reducers/InterfaceLogic'
import routing from './reducers/Routing'
import osc from './reducers/OscLogic'
//import electron from './reducers/ElectronLogic'
import log from './reducers/LogLogic'
import reports from './reducers/ReportsLogic'
import settings from './reducers/SettingsLogic'
import undo from './reducers/UndoLogic'
import vectorworks from './reducers/VectorworksCache'
import worksheets from './reducers/WorksheetsLogic'
import errors from './reducers/ErrorLogic'

export default {
  cues: cues,
  //electron: electron,
  instruments: instruments,
  vectorworks: vectorworks,
  notes: notes,
  language: language,
  user: user,
  show: show,
  ui: ui,
  osc: osc,
  routing: routing,
  log: log,
  undo,
  settings,
  worksheets,
  reports,
  errors: errors,
}

