import React from 'react'
import { 
  FormGroup,
  FormControlLabel,
  Switch 
} from '@material-ui/core'

class SmartToggle extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      value: this.props.value || false,
      onChange: this.props.onChange
    }
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle(){
    console.log('toggled...' + !this.state.value);

    if(this.state.onChange){
      this.state.onChange(!this.state.value);
    }

    this.setState(Object.assign({}, this.state, {
      value: !this.state.value
    }))
  }

  componentWillReceiveProps(props) {
    this.setState({
      value: props.value || false,
      onChange: props.onChange
    })
  }

  render() {
    const { value, toggled, onChange, label, ...rest } = this.props

    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              {...rest}
              checked={ this.state.value } 
              onClick={ e=>{
                this.handleToggle(e)
              }}
            />
          }
          label={ label }
        />
      </FormGroup>
    )
  }
}

export default SmartToggle