import React from 'react'
import { connect } from 'react-redux'
import translate from './../../shared/language/Translate'
import { general, navigation } from './../../shared/language'
import { saveState } from './../../src/reducers/ShowLogic'
import Link from '../Link'
import Colors from './../../src/Colors'

import {
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@material-ui/core'

import { 
  Menu,
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons'

import HomeIcon from '@material-ui/icons/Home'
import NotesIcon from '@material-ui/icons/Rowing'
import PrintIcon from '@material-ui/icons/LocalPrintShop'
import OscIcon from '@material-ui/icons/SettingsEthernet'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpIcon from '@material-ui/icons/Help'
import DevIcon from '@material-ui/icons/BugReport'
import CueIcon from '@material-ui/icons/ViewList'
import LogIcon from '@material-ui/icons/EventNote'

import {
  pushRoute
} from './../../src/history'

import { withRouter } from 'react-router-dom'

const mainRoutes = [
  {
    to: '/',
    key: 'home',
    icon: <HomeIcon />
  }, {
    divider: true
  }, {
    to: '/cues',
    key: 'cues',
    icon: <CueIcon />
  }, {
    to: '/instruments',
    key: 'instruments',
    icon: <CueIcon />        
  }, {
    to: '/paperwork',
    key: 'paperwork',
    icon: <PrintIcon />
  }, {
    to: '/notes',
    key: 'notes',
    icon: <NotesIcon />
  }, {
    to: '/integration',
    key: 'integration',
    icon: <OscIcon />
  }, {
    to: '/dev',
    key: 'dev',
    icon: <DevIcon />,

  }, {
    to: '/show',
    key: 'show',
    icon: <SettingsIcon />
  },
]
const additionalRoutes = [
  {
    to: '/log',
    key: 'log',
    icon: <LogIcon />
  }, {
    to: '/about',
    key: 'about',
    icon: <HelpIcon />
  }
]

export const LinkButton = withRouter( ({ laKey, history, to}) => (
  <Button
    style={{
      color: window.theme.palette.common.white,    
    }}
    onClick={_=>{
      history.push(to)
    }}
  >
    { navigation.get( laKey ) }
  </Button>
) )


class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
    }
  }

  closeMenu() {
    this.setState({
      ...this.state,
      open: false,
    })
  }

  openMenu() {
    this.setState({
      ...this.state,
      open: true,
    })
  }

  render() {

    const { dispatch } = this.props
    const showName = this.props.show.currentShow || 'New Show'
    const language = this.props.language.current || 'en'
    const { pathname } = this.props

    const iconColor = window.theme.palette.type === 'light' ? window.theme.palette.action.active : window.theme.palette.common.white 
    const link = (to, key) => {
      return (
        <LinkButton to={to} laKey={key} key={key} />
      // return (
      //   <Button
      //     key={key}
      //     style={{
      //       color: Colors.WHITE,    
      //     }}
      //     onClick={_=>{
      //       pushRoute( to )
      //     }}
      //   >
      //     { navigation.get( key ) }
      //   </Button>
      )
      
    }

    return (
        <React.Fragment>
        <nav 
          className='no_print'
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: window.theme.navBar,
            color: window.theme.palette.common.white,
            boxShadow: '0 3px 3px rgba(0,0,0,0.4)',
          }}
        >
          <Button
            style={{
              minWidth: '48px',
              minHeight: '48px',
              zIndex: 901,
              color: window.theme.palette.common.white,
            }}
            onClick = { e => {
              this.setState({
                ...this.state,
                open: !this.state.open
              })
            } }
          >
            <Menu />
          </Button>
          <Typography variant="h6" color="inherit" noWrap>
              Light Assistant
          </Typography>

          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContenxt: 'flex-end',
          }}>
            <div
              style={{
                flex: 1,
              }}
            >
            </div>

            { mainRoutes.map((r, index) => {
              if(!r.to || r.hideMain) {
                return null
              }
              return link(r.to, r.key)
            })}

            <div
              style={{
                width: '24px'
              }}
            >
            </div>
          </div>
        </nav>

        <Drawer
          modal={true}
          //variant="persistent"
          anchor={ 'left' }
          open={ this.state.open }
          onClose={ () => {
            this.setState({
              ...this.state,
              open: false,
            })
          } }
          //  classes={{
          //    paper: classes.drawerPaper,
          //  }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <Typography 
              variant='h6'
              style={{
                marginLeft: '8px',
                fontVariant: 'all-small-caps',
              }}
              color='secondary'
              noWrap
            >
              { showName }
            </Typography>
            
            <IconButton
              style= {{    
                color: iconColor,
                marginLeft: 'auto',
                marginRight: '12px',
              }}
              onClick={e => {
                this.closeMenu()
              }}
            >
              <ChevronLeft />
            </IconButton>

          </div>
          
          <Divider />

          <List>
            <ListSubheader>{ navigation.get('navigation') }</ListSubheader>
            { mainRoutes.map( (route, index) => {
              const label = navigation.get(route.key)
              
              if(route.divider) {
                return <Divider key={'divider-' + index} />
              }

              return (
                <ListItem 
                  key={route}
                  button
                  onClick={ () => {
                    this.closeMenu()
                    setTimeout(() => {
                      //Delay for animation
                      pushRoute( route.to )  
                    }, 180)
                    
                  } }
                >
                  { route.icon ? 
                    <ListItemIcon
                      style={{
                        color: iconColor,
                      }}
                    >
                      { route.icon } 
                    </ListItemIcon>
                    : null }
                  <ListItemText primary={label} />
                </ListItem>
              )
            } )}

{/* QUICK ACTIONS */}
            
            <Divider />

             { additionalRoutes.map( (route, index) => {
              const label = navigation.get(route.key)
              
              if(route.divider) {
                return <Divider key={'divider-' + index} />
              }

              return (
                <ListItem 
                  key={route}
                  button
                  onClick={ () => {
                    this.closeMenu()
                    setTimeout(() => {
                      //Delay for animation
                      pushRoute( route.to )  
                    }, 180)
                    
                  } }
                >
                  { route.icon ? 
                    <ListItemIcon
                      style={{
                        color: iconColor,
                      }}
                    >
                      { route.icon } 
                    </ListItemIcon>
                    : null }
                  <ListItemText primary={label} />
                </ListItem>
              )
            } )}

            <Divider />

            <ListSubheader>{ navigation.get('fileMenu') }</ListSubheader>
            <Button
              color='secondary'
              style={{
                flex: 1,
                width: '100%',
              }}
              onClick={ e => {
                this.props.dispatch( saveState() )
              }}
            >
              { general.get('save') }
            </Button>
            <Divider/>

          </List>
        </Drawer>        
        </React.Fragment>
                
    )
  }
}

export default connect(s => { 
  return { 
    user : s.user,
    language: s.language,
    pathname: s.routing.pathname,
    show: ( (s.show || {}).show) || {},
  }
})(NavBar)