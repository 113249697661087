import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Standard from '../../components/Layouts/Standard'
import translate from '../../shared/language/Translate'
import {
  notes as NotesStrings
} from '../../shared/language'
import moment from 'moment'
import { 
  estimateFileSize,
  loadShow, 
  loadShowSettings, 
  loadSystemSettings,
  mergeSettings, 
  mergeShowSettings,
  newShow, 
  purgeNonCriticalData,
  saveState,
  estimateFileSizeFromDispatch, 
} from '../reducers/ShowLogic'

import { 
  showConfirm,
  showWarning,
} from '../reducers/InterfaceLogic'

import { 
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'

import IconSettings from '@material-ui/icons/Settings'
import IconShowSettings from '@material-ui/icons/FileCopy'
import IconLightDark from '@material-ui/icons/Brightness6'
import IconPortrait from '@material-ui/icons/Portrait'
import IconVisible from '@material-ui/icons/Visibility'
import IconVisibleOff from '@material-ui/icons/VisibilityOff'
import IconUp from '@material-ui/icons/ArrowUpward'
import IconDown from '@material-ui/icons/ArrowDownward'
import IconNotes from '@material-ui/icons/Note'
import IconExtension from '@material-ui/icons/Extension'
import {
  convertFileToImage,
  parseFiles,
} from './../Utility'

import {
  num,
} from './../../shared/Utility'

import {
  isEmptyOrWhitespace,
} from './../../shared/StringUtil'

import {
  changeThemeDark,
  changeThemeLight,
  changeThemeDarkAmber,
  getNotesTableVisibilitySettings,
  moveNotesIndexDown,
  moveNotesIndexUp,
  setNoteIndexHidden,
  updateSetting,
} from './../reducers/SettingsLogic'

import ColorPicker from 'rc-color-picker'

import {
  changeModeToDialog,
  changeModeToDrawer,
  clearAllCompletedNotes,
  clearAllDeletedNotes,
  clearAllNotes,
} from './../reducers/NotesLogic'

import InAppPurchase from './../iap/InAppPurchase'
const buttonStyle = {
  marginTop: '5px',
}

const paperStyle = {
  padding: '16px',
  margin: '20px 0 0 20px',
  minWidth: 300,
  flexDirection: 'column',
  display: 'flex',
}

const CardSeries = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      { props.children }
    </div>
  )
}

const Card = props => {
  const {children, style, ...rest} = props
  return (
    <Paper
      style={{
        ...paperStyle,
        ...style,
      }}
    >
      { children }
    </Paper>
  )
}
  
const AsHtml = props => {
  return <div dangerouslySetInnerHTML={{__html: props.html}} {...props} />
}

class ShowPage extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      ...props,
      selectedItem: 0,
    }

    const show = props.show || {}
    this.showColorCache = show.color || '#0d47a1'
    this.estimateTimer = null
    this.runEstimate()
  }

  runEstimate() {
    clearTimeout(this.estimateTimer)

    this.setState({
      fileSize: null,
    })
    this.estimateTimer = setTimeout(() => {
      this.props.dispatch( estimateFileSizeFromDispatch( obj => {

        if(navigator.storage.estimate) {
          navigator.storage.estimate()
          .then( db => {
            this.setState({
              fileSize: {
                ...obj,
                dbtotal: db.quota,
                dbusage: db.usage,
                usageDetails: {...db.usageDetails},
              }
            })
          })
          // .reject(err=>{
          //   console.error(err)
          //   this.setState({
          //     fileSize: {
          //       ...obj,
          //       ...err,
          //     }
          //   })
          // })
        } else {
          //No database functionality
          this.setState({
            fileSize: {
              ...obj,
              dbtotal: 50000000,
              dbusage: 0,
              error: true,
            }
          })
        }
        

      }) )
    }, 800)
  }

  renderFileSize() {
    const T = this.props.strings

    const bar = (title, current = 0, max = 5000000) => {
      const mb = (val) => {
        return num(val / 1000000, 0).toFixed(2) + 'MB'
      }
      const _p = Math.round((current / max) * 100)
      const _grad = `linear-gradient(to right, red 0%, orange ${_p}%, green ${_p}%, green)`
      return (
        <div 
            style={{
              display: 'block',
              borderRadius: 5,
              border: '1px solid white',
              overflow: 'hidden',
              color: 'white',
              padding: 0,
              background: _grad,
              textAlign: 'center',
              marginTop: 4,
            }}
          >
              {T.get('titleMBOfMB', title, mb(current), mb(max))}
          </div>
      )
    }
    if(!this.state.fileSize) {
      return (
        <React.Fragment>
          <h3>{T.get('estimateFileSize')}</h3>
          <CircularProgress />
        </React.Fragment>
      )
    }
    const fileSize = this.state.fileSize || {}
    const max = fileSize.available
    return (
      <React.Fragment>
        <h2>{T.get('estimateFileSize')}</h2>
        
        <b>All Data</b>
        { bar('', fileSize.all, max) }

        <b>By Module</b>
        { bar('Cues', fileSize.cues, max) }
        { bar('Data Exchange', fileSize.vectorworks, max) }
        { bar('Instruments', fileSize.instruments, max) }
        { bar('Notes', fileSize.notes, max) }

        <h3>Data Bases (50MB)</h3>
        { fileSize.error ? 
          <div style={{maxWidth: 250}}>
            Unable to query databases, not all browsers support this.
          </div>
        : 
        <React.Fragment>
          <b>System Quota</b>
          { bar('', fileSize.dbusage, fileSize.dbtotal) }

          <b>Databases</b>
          { Object.keys(fileSize.usageDetails).sort().map(key => {
            return bar(key, fileSize.usageDetails[key], 50000000)
          })}
        </React.Fragment>}
        {/* <div>
          <code style={{maxWidth:250}}>
            { JSON.stringify(this.state.fileSize, null, 2) }
          </code>
        </div> */}
        <AsHtml style={{maxWidth: 250}} html={T.get('estimateFileSizeInfo')} />
        <Button 
          variant='outlined'
          style={{
            ...buttonStyle
          }}
          onClick={ e => { 
            const onConfirm = () => {
              this.props.dispatch( purgeNonCriticalData() ) 
              this.runEstimate()
            }

            this.props.dispatch( showConfirm(T.get('purgeNonCritical'), T.get('purgeNonCriticalInfo'), onConfirm) )

          } }
        >
          { T.get('purgeNonCritical') }
        </Button>   
      </React.Fragment>
    )
  }
  componentWillReceiveProps(props){
    const show = props.show || {}
    this.setState({
      ...this.state, 
      ...props
    })
    this.showColorCache = show.color || '#0d47a1'

    this.runEstimate()
  }

  addShowImage(fileObject){
		const first = parseFiles(fileObject)[0]

		try {
			convertFileToImage(first, (data)=>{
				console.log(data)
				console.log('image uploaded')
				this.props.dispatch( mergeShowSettings({
					logo: data,
				}))
			})
		} catch (err) {
			this.props.dispatch( showWarning('Unable to process image', 'The image file is likely invalid.'))
		}
  }

  updateShow(e, key){
    if(key){
      const update = {}
      update[key] = e.target.value
      const push = { 
        show: {
          ...this.state.show,
          ...update
        }
      }

      this.setState(Object.assign({}, this.state, push))
      console.log(push)
    }
  }

  mergeShow(e, key){
    if(key){
      const obj = {}
      obj[key] = e.target.value
      this.props.dispatch( mergeShowSettings(obj) )
    }
  }

  renderFileMenu() {
    const T = this.props.strings
    const { show } = this.state    
    const { dispatch } = this.props
    return (
      <CardSeries>
        <Card>
          <h2>{ T.get('importExport')}</h2>
          <Button
            color='secondary'
            variant='contained'
            style={{
              ...buttonStyle
            }}
            onClick={ e => {
              dispatch( 
                showConfirm( 
                  T.get('newShowWarning'), 
                  T.get('newShowDetail'), 
                  ()=> { 
                    dispatch( newShow())
                  })
                )
            }}
          >
            { T.get('newShow') }
          </Button>
              
          <div style={{padding:'20px'}}></div>    

          <Button 
            variant='outlined'
            color='secondary'
            style={{
              ...buttonStyle
            }}
            onClick={e=>{ 
              dispatch(
                saveState(this.props.show.currentShow || 'unknown')
              )
            } }
          >
            { T.get('saveShow') }
          </Button>
              
          <input 
            hidden
            type='file' 
            accept='.la,.txt,.json'       
            ref={x => this.fileUpload = x }         
            onChange={e=>{ 
              if(e.target.files[0]){
                const reader = new FileReader()
                
                reader.onload = e =>{
                  const text = reader.result
                  const json = JSON.parse(text)
                  dispatch(loadShow(json))
                }

                reader.readAsText(e.target.files[0])

              } else {
                //alert('BAD FILE')
                //This appears when you cancel....
              }
            } } 
          />

          <Button 
            variant='outlined'
            color='primary'
            style={{
              ...buttonStyle
            }}
            onClick={ e => { this.fileUpload.click() }}
          >
            { T.get('loadShow') }
          </Button>  
        </Card>

        <Card>
          {this.renderFileSize()}  
        </Card>
      </CardSeries>
    )
  }

  renderThemes() {
    const T = this.props.strings
    const { dispatch } = this.props
    const style = {
      marginBottom: 8,
    }
    return (
      <CardSeries>
        <Card>
          <h2>Themes</h2>
          <Button
            style={style}
            variant='outlined'
            onClick={e => {
              dispatch( changeThemeDark() )
            }}
          >
            Dark Theme (Default)
          </Button>

          <Button
            style={style}
            variant='outlined'
            onClick={e => {
              dispatch( changeThemeLight() )
            }}
          >
            Light Theme
          </Button>

          <Button
            style={style}
            variant='outlined'
            onClick={e => {
              dispatch( changeThemeDarkAmber() )
            }}
          >
            Dark Amber Theme (Low Light)
          </Button>
        </Card>
      </CardSeries>
    )
  }

  renderVariables() {
    const T = this.props.strings
    const { show } = this.state

    const text = (key, helper)=>{
      return (
        <TextField 
          style={{
            marginBottom: 8,
            marginRight: 8,
          }}
          InputLabelProps={{ 
            shrink: !isEmptyOrWhitespace(show[key])
          }} 
          label={T.get(key)}
          onChange={ e=>this.updateShow(e, key) }
          onBlur={ e => this.mergeShow(e, key) }
          value={ show[key] }
          helperText={helper || null}
        />
      )
    }

    return (
      <CardSeries>
        <input 
          type='file' 
          accept='images' 
          ref={x=> this.imageUpload = x}
          hidden
          onChange={e => {
            if(e.target.files && e.target.files.length > 0) {
              this.addShowImage(e.target.files)
            }
          } } 
        />

        <Card>
          <h2>Show Details</h2>
          <FormGroup row>
            { text('currentShow') }
          </FormGroup>
          
          <FormGroup row>
            { text('designerTitle') }
            { text('designer') }
          </FormGroup>

          <FormGroup row>
            { text('assistant1Title') }
            { text('assistant1') }
          </FormGroup>

          <FormGroup row>
          { text('assistant2Title') }
          { text('assistant2') }
          </FormGroup>

          <FormGroup row>
            { text('assistant3Title') }
            { text('assistant3') }
          </FormGroup>
          
          { text('paperworkDate', T.get('paperworkDateHelp')) }
        </Card>

        <Card>
          <h2>Paperwork</h2>
          <FormGroup>
          <FormControlLabel
            label={`Default Report Color ${this.showColorCache}`}
            control={
              <div>
                <div
                  style={{
                    padding: 8,
                  }}
                >
                  <ColorPicker
                    animation="slide-up"
                    color={this.showColorCache}
                    onChange={colors => {
                      this.showColorCache = colors.color
                    }}
                    onClose={_ => {
                      const e = {
                        target: {
                          value: this.showColorCache
                        }
                      }
                      this.updateShow(e, 'color')
                      this.mergeShow(e, 'color')

                      
                      //this.updateRootProp('headerColor', this.headerCacheColor)
                    }}
                  />
                </div>
              </div>
            }
          />
        </FormGroup>
        <FormGroup>
          <img src={show.logo} />
          
          <Button
            variant='outlined'
            onClick={e => {
              console.log('Loading ' + this.imageUpload)
              this.imageUpload.click()
            }}
          >
            Upload Logo
          </Button>

        </FormGroup>
        <Button
          variant='outlined'
          onClick={_ => {
            const e = {
              target: {
                value: '#0d47a1'
              }
            }
            this.updateShow(e, 'color')
            this.mergeShow(e, 'color')
            this.showColorCache = e.target.value
          }}
        >
          Reset Colors to Default
        </Button>
              
        </Card>
      </CardSeries>
    )
  }

  renderPreferences() {
    const {dispatch, noteDialogMode} = this.props
    return (
      <CardSeries>
        <Card>
          <h2>User Interface Preferences</h2>
          <div>
            Notes Appear as Dialogs
            <Switch 
              checked={noteDialogMode || false}
              onChange={() => {
                if(noteDialogMode) {
                  dispatch( changeModeToDrawer() )
                } else {
                  dispatch( changeModeToDialog() )
                }
              }}
            />
          </div>
        </Card>
      </CardSeries>
    )
  }

  renderNotesPreferences() {
    const { dispatch, noteDialogMode, settings } = this.props
    const fake = {
      settings: settings
    }
    const tableViz = getNotesTableVisibilitySettings(fake)

    const up = (index) => {
      dispatch( moveNotesIndexUp(index) )
    }

    const down = (index) => {
      dispatch( moveNotesIndexDown(index) )
    }

    const hide = (index) => {
      if(tableViz[index].hidden) {
        dispatch( setNoteIndexHidden(index, false) )
      } else {
        dispatch( setNoteIndexHidden(index, true) )
      }
    }
    const thinPadStyle = {
      padding: 0,
      paddingTop: 2,
      paddingBottom: 3,
      textAlign: 'center',
    }

    const thinPadLeft = {
      padding: 0,
      paddingTop: 2,
      paddingBottom: 3,
      textAlign: 'left',
    }
    const renderRow = (index) => {
      const el = tableViz[index]
      //Cheating using moment (dates)
      const formatter = moment().year(2000).month(1).date(index + 1)
      return (
        <TableRow key={index}>
          
          <TableCell style={thinPadStyle}>
            { formatter.format('Do') }
          </TableCell>

          <TableCell style={thinPadStyle}>
            <IconButton
              size='small'
              color='primary'
              onClick={()=>{
                up(index)
              }}
              disabled={index === 0}
            >
              <IconUp />
            </IconButton>

            <IconButton
            size='small'
              color='secondary'
              onClick={()=>{
                down(index)
              }}
              disabled={index === tableViz.length - 1}
            >
              <IconDown />
            </IconButton>
          </TableCell>
          
          <TableCell style={thinPadLeft}>
            { NotesStrings.soft(tableViz[index].name) }
          </TableCell>

          <TableCell 
            style={thinPadStyle}
          >
            <IconButton
              size='small'
              onClick={()=>{
                hide(index)
              }}
            >
              { el.hidden ? 
                <IconVisibleOff style={{
                  color: window.theme.palette.text.disabled,
                }}/> 
                : 
                <IconVisible />
              }
            </IconButton>

          </TableCell>
          
        </TableRow>
      )
    }
    return (
      <CardSeries>
        <Card>
          <h2>Notes Table Preferences</h2>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Move</TableCell>
                <TableCell>Field</TableCell>
                <TableCell>Visibility</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { tableViz.map((x,i)=>{
                  return renderRow(i)
                }) 
              }
            </TableBody>
          </Table>
          
          <Button
            variant='outlined'
            onClick={()=>{
              dispatch( updateSetting('notesTableVisibility', null) )
            } }
          >
            RESTORE DEFAULTS
          </Button>
        </Card>

        <Card>
          <h2>Data Management</h2>
          <h4>{NotesStrings.get('tipRemoveCompleted')}</h4>
          <Button
            variant='outlined'
            style={{
              marginBottom: 8,
            }}
            onClick={
              () => {
                this.props.dispatch( 
                  (d, getState) => {
                    const state = getState()
                    const notes = state.notes.notes
        
                    const countCompleted = Object.values(notes).filter(x=>x.status == 'completed').length
                    const countDeleted = Object.values(notes).filter(x=>x.status == 'deleted').length
                    d( 
                      showConfirm(
                        NotesStrings.get('removeAllCompletedTitle'),
                        NotesStrings.get('removeAllCompletedBody', {completed: countCompleted, deleted: countDeleted }),
                        ()=>{
                          this.props.dispatch( clearAllCompletedNotes() )
                        },
                        null,
                        NotesStrings.get('delete')
                      ) 
                    )
                  }
                )
              }
            }
          >
            {NotesStrings.get('settingsClearCompletedNotes')}
          </Button>

          <Button
            variant='outlined'
            color='secondary'
            style={{
              marginBottom: 8,
            }}
            onClick={
              () => {
                this.props.dispatch( 
                  (d, getState) => {
                    const state = getState()
                    const notes = state.notes.notes
        
                    const countCompleted = Object.values(notes).filter(x=>x.status == 'completed').length
                    const countDeleted = Object.values(notes).filter(x=>x.status == 'deleted').length
                    d( 
                      showConfirm(
                        NotesStrings.get('removeAllDeletedTitle'),
                        NotesStrings.get('removeAllDeletedBody', {completed: countCompleted, deleted: countDeleted }),
                        ()=>{
                          this.props.dispatch( clearAllDeletedNotes() )
                        },
                        null,
                        NotesStrings.get('delete')
                      ) 
                    )
                  }
                )
              }
            }
          >
            {NotesStrings.get('settingsClearDeletedNotes')}
          </Button>

          <Button
            variant='outlined'
            color='secondary'
            style={{
              marginBottom: 8,
            }}
            onClick={
              () => {
                this.props.dispatch( 
                  (d, getState) => {
                    const state = getState()
                    const notes = state.notes.notes
        
                    const countCompleted = Object.values(notes).filter(x=>x.status == 'completed').length
                    const countDeleted = Object.values(notes).filter(x=>x.status == 'deleted').length
                    d( 
                      showConfirm(
                        NotesStrings.get('removeAllNotes'),
                        NotesStrings.get('removeAllNotesBody', {completed: countCompleted, deleted: countDeleted }),
                        ()=>{
                          this.props.dispatch( clearAllNotes() )
                        },
                        null,
                        NotesStrings.get('settingsClearAll')
                      ) 
                    )
                  }
                )
              }
            }
          >
            {NotesStrings.get('settingsClearAll')}
          </Button>
        </Card>

      </CardSeries>
    )
  }
  setMenu(item) {
    this.setState({
      selectedItem: item,
    })
  }

  render() {
    const T = this.props.strings
    const { dispatch, language, user } = this.props
    const fakeShows = []
    const { show } = this.state

    const SETTINGS_ITEMS = [
      {
        key: 'fileMenu',
        fn: () => this.renderFileMenu(),
        icon: <IconSettings />
      }, {
        key: 'showSettings',
        fn: () => this.renderVariables(),
        icon: <IconShowSettings />
      }, {
          key: 'themes',
          fn: () => this.renderThemes(),
          icon: <IconLightDark />
      }, {
        key: 'notesPreferences',
        fn: ()  => this.renderNotesPreferences(),
        icon: <IconNotes />
      }, {
        key: 'userPreferences',
        fn: ()  => this.renderPreferences(),
        icon: <IconPortrait />
      },  {
        key: 'inAppPurchases',
        fn: () => {
          return <InAppPurchase />
        },
        icon: <IconExtension />
      }
    ]

    if(!this.props.show || !this.props.show.loaded){
      dispatch( loadShowSettings() )
    }

    // if(!this.props.system || !this.props.system.loaded){
    //   dispatch( loadSystemSettings() );
    // }

    const renderListItem = (settings, index) => {
      return (
        <ListItem
          key={settings.key}
          button
          selected={ this.state.selectedItem == index }
          onClick={e => {
            this.setMenu( index )
          }}
        >
          <ListItemIcon>
            { settings.icon }
          </ListItemIcon>
          <ListItemText 
            primary={ T.get( settings.key) }
          />
        </ListItem>
      )
    }

    const renderComponent = (index) => {
      const base = SETTINGS_ITEMS[index]
      if(!base || !base.fn) {
        return (
          <React.Fragment>
            NOT FOUND {this.state.selectedItem} base->[{!!base}] 
          </React.Fragment>
        )
      }

      return base.fn()
    }

    return (
      <Standard key={language.current}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            maxHeight: '100%',
            flexDirection: 'row',
          }}
        >
          <Paper 
            style={{
              flex: 0,
              maxHeight: '100%',
              minWidth: 200,
              overflowY: 'auto'
            }}
          >
            <List 
              style={{
                maxWidth: 400,
                overflowX: 'none',
              }}
            >
              <ListSubheader>
                { T.get('settings') }
              </ListSubheader>

              <Divider />

              { SETTINGS_ITEMS.map( (el, i) => {
                return renderListItem(el, i)
              })}

              <Divider />
              
              <ListSubheader>
                { T.get('actions') }
              </ListSubheader>

              <div 
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  margin: 0,
                  paddingLeft: 8,
                  paddingRight: 8,
                  alignContent: 'center',
                  alignSelf: 'center',

                }}
              >
                <Button 
                  variant='outlined'
                  color={'secondary'}
                  style={{
                    flex: 1,
                  }}
                  onClick={ () => {
                    this.props.dispatch(
                      saveState(this.props.show.currentShow || 'unknown')
                    )
                  } }
                >
                  { T.get('saveShow') }
                </Button>
                
              </div>
              
            </List>
          </Paper>

          <div
            style={{
              flex: 1,
              display: 'flex',
              overflowY: 'auto',
              paddingBottom: 20,
              paddingLeft: 8,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              { renderComponent(this.state.selectedItem) }
            </div>
          </div>
        </div>
      </Standard>
    )
  }
}

export default connect(s=>{
  const out = {
    show: s.show.show,
    system: s.show.system,
    language: s.language,
    user: s.user,
    noteDialogMode: s.notes.noteDialogMode || false,
    settings: s.settings,
  }

  if(!out.show.paperworkDate) {
    out.show.paperworkDate = '{day}, {date} {time}'
  }
  return out
})(translate('Show')(ShowPage))