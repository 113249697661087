import pouch from '../pouch'
import uuidv1 from 'uuid/v1'
import Errors from '../Errors'
import Logger from '../../shared/WebLog'

import { Translator, TranslatorFromState } from './../../shared/language/Translate'
import { instrumentLogic as Strings, positions } from './../../shared/language'
import { redirect } from '../Utility'
import { showDialog } from './InterfaceLogic'
import {
  pushUndo,
  pushUndoSet,
  makeUndo,
} from './UndoLogic'

import {
  formatUnicorn,
  isEmptyOrWhitespace,
  ifNullThen
} from '../../shared/StringUtil'

import {
  pushSyncChangeBulk,
  pushImportState,
} from './VectorworksCache'
import {
  DEFAULT_VECTORWORKS_MAPPINGS,
  readAccessories,
  readXmlFileToJson,
  processVectorworksXmlFile,
  Actions,
  SyncResults,
} from './../../shared/vectorworks'
import {
  num,
  joinStrings,
  broadwaySortFieldsFn,
  splitDmx,
  toNumericDmxAddress,
  toUniverseSlashAddress,
  reformatAsUniverseSlashAddress,
  isNum,
  toAbsoluteAddress,
  toUniverseAndAddress,
} from '../../shared/Utility'

export const UNDO_KEY = 'instruments'
export const TYPE_KEY = 'instrument'
export const SYNC_HISTORY_KEY = 'SYNC_HISTORY_KEY'
export const SYNC_SETUP = 'VW_SYNC_SETUP'

export const ADD_UNIT = 'INST_ADD_UNIT'
export const DELETE_UNITS = 'INST_DELETE_UNITS'
export const BULK_ADD_UNIT = 'INST_BULK_ADD_UNIT'
export const BULK_DELETE_UNIT = 'INST_BULK_DELETE_UNIT'
export const REPLACE_UNIT = 'INST_REPLACE_UNIT'
export const BULK_REPLACE_UNIT = 'INST_BULK_REPLACE_UNIT'
export const LOAD_INST = 'INST_LOAD'
export const RESET = 'INST_RESET'
export const SYNC_UPDATE = 'INST_SYNC_UPDATE'
export const SET_SYNC_FILE = 'INST_SET_SYNC_FILE'
export const BACKEND_SET_INSTRUMENTS_DIRECTLY = 'INST_BACKEND_SET_INSTRUMENTS_DIRECTLY'

export const UNLOAD = 'INST_UNLOAD'

export const REPLACE_POSITIONS = 'INST_REPLACE_POSITIONS'

//Local Storage
export const LOCAL_STORAGE_SYNC_KEY = 'la_inst_sync'

const log = Logger('en', 'InstrumentLogic')

const _getSync = () => {
  const syncDefaults = {
    red: 'herring'
  }
  try {
    let sync = JSON.parse(localStorage.getItem(LOCAL_STORAGE_SYNC_KEY))
    if(!sync) {
      return Object.assign({}, syncDefaults)
    }
    return Object.assign({}, syncDefaults, sync)
  } catch (error) {
    return Object.assign({}, syncDefaults)
  }
}

const _setSync = (sync) => {
  localStorage.setItem(LOCAL_STORAGE_SYNC_KEY,
    JSON.stringify( Object.assign({}, sync) ) )
}

export const SYNC_STATE = {
  NOT_STARTED: 'NOT_STARTED',
  LA_HAS_READ: 'LA_HAS_READ',
  RECONCILE: 'RECONCILE',
}

export const LA_FIELDS = { //WE NEED TO DEPRECIATE THIS
  UNIT: 'unit',
  POSITION: 'position',
  ADDRESS: 'address',
  UNIVERSE: 'universe',
  COLOR: 'color',
  FROST: 'frost',
  CHANNEL: 'channel',
  TEMPLATE: 'template',
  TEMPLATE2: 'template2',
  PURPOSE: 'purpose',
  CIRCUIT_NAME: 'circuitName',
  CIRCUIT_NUMBER: 'circuitNumber',
  INSTRUMENT_TYPE: 'instrumentType',
  DEVICE_TYPE: 'deviceType',
  USER_FIELD_1: 'userField1',
  FRAME_SIZE: 'frameSize',
}

const _vw_sync_inverted = {}

Object.keys(DEFAULT_VECTORWORKS_MAPPINGS).forEach(key => {
  const el = DEFAULT_VECTORWORKS_MAPPINGS[key]
  _vw_sync_inverted[el.key] = {
    name: el.name,
    key: key
  }
})

export const VW_SYNC_MAPPINGS = _vw_sync_inverted

export const SYNC_APP_STAMP_VW = 'Vectorworks'
export const SYNC_APP_STAMP_LIGHTWRIGHT = 'Lightwright' //FIXME maybe we can use LA
export const SYNC_APP_STAMP_LIGHT_ASSISTANT = 'LightAssistant'

export const numericSort = (a, b) => {
  const aNum = Number(a)
  const bNum = Number(b)

  const aCh = a
  const bCh = b

  if(a == b) {
    return 0
  }

  if(isNaN(aNum) && isNaN(bNum)) { //Not Numeric
    let aEmpty = isEmptyOrWhitespace( aNum )
    let bEmpty = isEmptyOrWhitespace( bNum )

    if( aEmpty && bEmpty ) {
      return 0
    }

    if(aEmpty) {
      return 1
    }

    if(bEmpty) {
      return -1
    }
    return aCh < bCh ? -1 : 1

  } else {
    //NUMERIC
    if( isNaN(aNum) ) {
      return 1
    }

    if( isNaN(bNum) ) {
      return -1
    }

    return aNum < bNum ? -1 : 1
  }
}

const _presortParentAccessories = (instruments) => {
  const uidLookup = {}
  const positionMap = {}
  const accessories = []
  const staticAccessories = []

  for(let inst of instruments) { //Map non-accessories
    const type = inst.deviceType
    if( type == 'Accessory') {
      accessories.push(inst)
    } else if (type == 'Static Accessory') {
      staticAccessories.push(inst) //FIXME we should probably throw an error here, this isn't matched to a unit
    } else {
      const key = inst.position + ':' + inst.unit
      uidLookup[ inst.uid ] = inst
      positionMap[ key ] = inst
    }
  }

  for(let a of accessories) { //Remap accessories to parents
    const key = a.position + ':' + a.unit
    let inst = positionMap[ key ]

    //map to a one way relationship. Maybe we wnat to store these in the parent on import?
    if(inst) {
      a.parent = inst
      a.parentUid = inst.uid
    } else {
      log.info('::_presortParentAccessories The accessory does not have a parent uid:{uid} position:{position} #: {unitNumber}', a)
    }
  }

  return instruments
}

//This is also mapped in WorksheetsLogic.js
const _DEVICE_TYPE_SORT = { //Default to 0
  'Light': 1,
  'Moving Light': 2,
  'Accessory': 3,
  'Static Accessory': 4,
  'Device': 5,
  'Practical': 6,
  'SFX' : 7,
  'Power': 8,
  'Other': 9,
}

/**
 * ***IMPORTANT***
 *
 * Sort by device type, this will never return 0. It returns
 * the natural order when identical.
 *
 * @param {*} a
 * @param {*} b
 */
const _sortDeviceType = (a, b) => {
  const aDevice = _DEVICE_TYPE_SORT[ a.deviceType ] || 0
  const bDevice = _DEVICE_TYPE_SORT[ b.deviceType ] || 0

  const out = numericSort(aDevice, bDevice)
  if(out === 0) {
    return 1 //Natural Order
  }

  return out
}

/**
 * Creates a sorter based on the user specified positions
 * @param {*} state
 */
export const createInstrumentSortOverrides = (state) => {
  //POSITIONS
  const positions = state && state.instruments && state.instruments.positions || []

  const map = {}
  for(let i = 0; i < positions.length; i++) {
    map[positions[i].name] = i
  }

  const positionSort = (a, b) => {
    const aIndex = map[a]
    const bIndex = map[b]

    if(isNum(aIndex) && isNum(bIndex)) {
      return numericSort(aIndex, bIndex)
    } else {
      return numericSort(a, b)
    }
  }

  return {
    'position': positionSort,
  }
}

const _sortInstrumentSchedule = (a, b) => {
  const position = numericSort(a.position, b.position)
  if(position === 0) {
    let out = numericSort(a.unit, b.unit)
    if(out === 0) {
      return _sortDeviceType(a, b)
    }
    return out
  } else {
    return position
  }
}

const _sortChannel = (a, b) => {

  let aChan = a.channel
  let bChan = b.channel
  let aDim = a.dimmer
  let bDim = b.dimmer

  let aAddress = a.address
  let bAddress = b.address

  if(a.deviceType == 'Accessory' || b.deviceType == 'Accessory') {

    //check for accessory
    if( a.deviceType == 'Accessory') {

      //Reassign values for A
      if(a.parent) {
        log.info('::_sortChannel attempting to parent accessory')
        if( isEmptyOrWhitespace(a.channel) ) {
          log.info('\tassigning parent channel {channel}', a.parent)
          aChan = a.parent.channel
        }
      }
    }

    if(b.deviceType == 'Accessory') {
      if(b.parent) {
        log.info('::_sortChannel attempting to parent accessory')
        if( isEmptyOrWhitespace(b.channel) ) {
          log.info('\tassigning parent address {channel}', b.parent)
          bChan = b.parent.channel
        }
      }
    }

    let out = numericSort(aChan, bChan)

    if(out !== 0) {
      log.debug(`_channel sorting by channel ${out} for ${aChan}->${bChan} for ${a.deviceType}->${b.deviceType}`)
      return out
    }

    if(a.deviceType == 'Accessory' && b.deviceType == 'Accessory') {
      return _sortInstrumentSchedule(a, b)
    } else {
      return _sortDeviceType(a, b)
    }
  } else { //Traditional sort
    let out = numericSort(aChan, bChan)

    if(out !== 0) {
      return out
    }
    //Try another sort
    out = numericSort(aDim, bDim)
    if(out != 0) {
      return out
    } else {
      return _sortInstrumentSchedule(a, b)
    }
  }


}

const _sortDimmer = (a, b) => {
  //This needs to be smarter using universes probably
  const aD = a.dimmer || a.address
  const bD = b.dimmer || b.address
  return numericSort(aD, bD)
}

const _sortCircuit = (a, b) => {
  const aName = a.circuitName
  const bName = b.circuitName

  const names = numericSort(a.circuitName, b.circuitName)
  if(names !== 0) {
    return names
  }

  return numericSort(a.circuitNumber, b.circuitNumber)
}

const _sortColorThenFrame = ( a, b ) => {
  const aColors = joinStrings( a.color, a.frost )
  const bColors = joinStrings( b.color, b.frost )

  return numericSort(aColors, bColors)
}

const _sortColorSchedule = ( a, b ) => {

  const out = _sortColorThenFrame(a, b)
  if(out !== 0) {
    return out
  }

  return _sortInstrumentSchedule(a, b)
}

export const Sorts = {
  INSTRUMENT_SCHEDULE: 'INSTRUMENT_SCHEDULE',
  COLOR_SCHEDULE: 'COLOR_SCHEDULE',
  CHANNEL_HOOKUP: 'CHANNEL_HOOKUP',
  ADDRESS_HOOKUP: 'ADDRESS_HOOKUP',
  CIRCUIT_HOOKUP: 'CIRCUIT_HOOKUP',
  NATURAL: 'NATURAL_ORDER',
  COLOR_THEN_FRAME: 'COLOR_THEN_FRAME',

  instrumentSchedule : _sortInstrumentSchedule,
  channel: _sortChannel,
  dimmer: _sortDimmer,
  circuit: _sortCircuit,
  colorThenFrame: _sortColorThenFrame,
  colorSchedule: _sortColorSchedule,
}

const translator = (getState) => {
  if (getState) {
    const lang = getState().language || {}

    return Translator(lang.current || 'en', 'InstrumentLogic')
  }
  return Translator('en', 'Instruments')
}

const Log = Logger('en', 'InstrumentLogic')

/**
 * Produces a set of keys that are different between a and b on the top level
 * @param {Instrument} a
 * @param {Instrument} b
 */
export const diff = (a, b) => {
  const allKeys = {}
  const diffKeys = []

  for(let x of Object.keys(a)) {
    allKeys[x] = true
  }

  for(let x of Object.keys(b)) {
    allKeys[x] = true
  }

  //Merge
  for(let key of Object.keys(allKeys) ) {
    if(a[key] != b[key]) {
      if(key !== '_rev' && key !== '_id') { //ignored
        diffKeys.push(key)
      }
    }
  }

  return diffKeys
}

/**
 * Produces a set of keys that are different between a and b on the top level and
 * displays it in a simple HTML format.
 * @param {Instrument} a instrument A
 * @param {Instrument} b instrument B
 * @param {String} template using aCh, bCh, aKey, bKey, aValue, bValue to format the string
 */
export const htmlDiff = (a, b, template) => {
  if(!template) {
    template = `<div>({aCh}) {aKey}:{aValue}' to ({bCh}) {bKey}:{bValue}</div>`
  }

  const keys = diff(a, b)
  let output = '<div>'
  if(keys.length < 1) {
    return '<div>No Changes</div>'
  }

  for(let key of keys) {
    output += formatUnicorn(template, {
      aCh: a.channel || '-',
      aKey: key,
      aValue: a[key],
      bCh: b.channel || '-',
      bKey: key,
      bValue: b[key],
    })
  }

  return output + '</div>'
}

/**
 * Returns a Promise that returns an array of instruments from the pouchdb data.
 */
export function legacyLoadPouchDbInstruments(whenDone) {
  return dispatch => {
		pouch.db()
			.find({
				selector: {
					type: TYPE_KEY
				}
			}).then(result=>{
				dispatch({
					type: LOAD_INST,
          instruments: result.docs,
          sort: Sorts.INSTRUMENT_SCHEDULE,
          whenDone: whenDone,
        })
			}).catch(error => {
				dispatch(Errors.reactError('Unable to load instruments', error))
			})
	}
}

export const replacePositions = (newArray) => {
  return dispatch => {
    dispatch({
      type: REPLACE_POSITIONS,
      positions: (newArray || []).slice()
    })
  }
}

export const updateKnownPositions = (state) => {
  const current = (state.instruments.positions || []).slice()
  const process = (instruments) => {
    const positionMap = {}

    for(let inst of instruments) {
      const position = inst.position || 'unknown'
      const plusPlus = ( num( positionMap[position], 0) ) + 1

      positionMap[position] = plusPlus
    }

    const remap = current.slice()
    const result = []
    for(const el of remap) {
      result.push({
        ...el,
        count: num(positionMap[el.name], 0)
      })

      delete positionMap[el.name]
    }

    const keys = Object.keys(positionMap)
    keys.sort()

    for(const key of keys) {
      result.push({
        name: key,
        count: num(positionMap[key], 0),
      })
    }

    return result
  }

  return process(state.instruments.instruments || [])
}

export const loadInstruments = (sort, whenDone)=>{

  return (dispatch, getState) => {
    const state = getState()
    const positions = updateKnownPositions(state)

    dispatch({
      type: REPLACE_POSITIONS,
      positions: positions || [],
    })

    dispatch({
      type: LOAD_INST,
      instruments: getState().instruments.instruments || [],
      sort: sort,
      whenDone: whenDone,
    })
  }
}

/**
 * **WARNING this mutates the dataset**
 *
 * Merges the data into the existing instrument if found. If not found it
 * returns false
 * @param {} inst  the instrument to merge
 * @param {*} dataset the instrument array
 * @returns true if success, false if not merged, error on an error
 */
const _internalMergeInst = (inst, dataset) => {
  const index = dataset.findIndex(x => x._id == inst._id)
  let add = index > -1

  const merge = {
    ...dataset[index],
    ...inst,
  }

  if(add) {
    dataset[index] = merge
  } else {
    dataset.push(merge)
  }

  return true
}

/**
 * Bulk operation that merges instruments. This then dispatches an event to update the UI. There
 * is no undo history for this as it is used by the synchronization operation.
 *
 * @param {Array of instruments} instruments
 */
export const _bulkMergeInstrumentsNoUndo = (instruments) => {
  return (dispatch, getState) => {

    const errors = []
    const db = getState().instruments.instruments || []


    const updateSet = [] //filtered

    for(let inst of instruments) {
      _internalMergeInst(inst, db)
    }

    dispatch({
      type: BULK_REPLACE_UNIT,
      instruments: db
    })
    return

    //CODE FOR REFERENCE POST REFACTER TESTING DELETE
    pouch.db().upsertBulk(updateSet).then(results => {
      //Update the revs
      for(let i = 0; i < instruments.length; i++) {

        let rev = results[i]
        instruments[i]._rev = rev.rev

        if(instruments[i]._id != rev.id) {
          throw new Error('::_bulkMergeInstrumentsNoUndo -=CRITICAL=- UNMATCHED ID, this indicates the database is not performing as expected. Switch to a HashMap instead of an Array. Please contact support.')
        }
      }


    }).catch(error => {
      log.error('::_bulkMergeInstrumentsNoUndo Bulk Merge Failed', error)
      return dispatch(Errors.reactError('Bulk Merge Failed', error))
    })
  }
}

export const formatPartial = (inst) => {
  const channel = inst.channel
  const part = inst.part

  const check = num(part, 1)
  const noParts = (ifNullThen(channel, '') + '').split(' P')[0]

  if(check > 1) {
    return noParts + ' P' + check
  } else {
    return channel
  }
}
/**
* This is a bulk operation for the merge instrument method. It updates the Redux state
 * when all merges are complete rather than per unit. It is less efficient than the bulk merge
 * operation but is easier to use.
 *
 * The function used matches first by `_id` where possible and then by `channel` if it can't match by `_id`. It will
 * skip anything unmatched that does not have a universe or address
 *
 * @param {Array<Instrments>} toMerge a list of instruments to merge
 * @param {String} undoDescription String (formatter)
 * @param {Function<Array<Instruments>, Instrument>} transformSkipped if provided the skipped items will be
 * transformed and added. They will get an `_id` if not provided by the transform
 * @param {Boolean} addPartsToChannel
 * @param {Boolean} createUndo
 */
export const mergeInstrumentSet = (toMergeBase, undoDescription, transformSkippedFn = null, addPartsToChannel = false, createUndo = true) => {

  return (dispatch, getState) => {
    if(!toMergeBase || toMergeBase.length < 1) {
      return
    }

    //remove duplicate data
    const __eosDuplicateFilter = {}
    //We're going to match on channel/part and use the "latest" received as the match
    for(let el of toMergeBase) {
      __eosDuplicateFilter[formatPartial(el)] = el
    }

    const toMerge = Object.values(__eosDuplicateFilter)

    const state = getState()
    const instruments = state.instruments.instruments || []

    const merged = []
    const toRestore = []
    const skipped = []
    const toAdd = []
    const toUndelete = []

    //returns -2 for filter this out
    const matchFunction = (instruments, inst) => {
      let index = instruments.findIndex(x => x._id == inst._id)
      if(index > -1) {
        return index
      }

      index = instruments.findIndex(x => {
        const chA = formatPartial(x)
        const chB = formatPartial(inst)
        const chEq = chA == chB

        if(chEq) {
          return index
        }
      } )

      if(index > -1) {
        return index
      }

      const address = num(inst.address, -2)
      return address === -2 ? -2 : -1
    }

    for(const inst of toMerge) {
      const index = matchFunction(instruments, inst)

      if(index < -1) {
        continue //skip this entirely
      }

      if(index < 0) {
        skipped.push({
          ...inst,
          deleted: false,
        })
      } else {
        const old = {...instruments[index]}
        toRestore.push({...old})
        const formatted = addPartsToChannel ? formatPartial(inst) : inst.channel

        const update = {
          ...old,
          ...inst,
          channel: formatted,
        }

        merged.push(update)
        toUndelete.push({
          ...update,
        })
      }
    }

    if(transformSkippedFn) {
      for(const inst of skipped) {
        let copy = {
          ...inst
        }
        copy = transformSkippedFn(copy)
        if(!copy._id) {
          copy._id = uuidv1()
        }
        const part = num(copy.part, 1)

        if( part > 1 ) {
          copy.position = 'EOS PARTIAL FIXTURES'
        }
        //Always override the channel
        copy.channel = addPartsToChannel ? formatPartial(copy) : copy.channel

        toAdd.push(copy)
        toRestore.push({
          ...copy,
          deleted: true,
        })
        toUndelete.push({
          ...copy,
          deleted: false,
        })
      }
    }

     //FIXME - We might want a more advanced undo operation, currently the "from eos" position will not be removed on undo.
    const onUndo = () => {
      dispatch( mergeInstrumentSet(toRestore, undoDescription, null, addPartsToChannel, false))
    }

    const onRedo = () => {
      dispatch( mergeInstrumentSet(toUndelete, undoDescription, null, addPartsToChannel, false))
    }

    console.log('Initial Data: pushing')
    console.log(merged)
    console.log('skipped')
    console.log(skipped)
    console.log('of (toMerge)')
    console.log(toMerge)
    dispatch({
      type: BULK_REPLACE_UNIT,
      instruments: merged,
      toAdd: toAdd,
    })

    dispatch( pushSyncChangeBulk( null, merged ) )

    if(createUndo) {
      const undo = makeUndo(onUndo, onRedo, undoDescription, undoDescription)
      dispatch( pushUndo(UNDO_KEY, undo) )
    }
  }
}
/**
 * Merges the new instrument into the existing instrument and creates some undos
 * @param {*} inst
 * @param {*} undoDescription
 * @param {*} createUndo
 */
export const mergeInstrument = (inst, undoDescription, createUndo = true) => {
  return (dispatch, getState) => {
    const state = getState()
    const instruments = state.instruments.instruments || []
    const index = instruments.findIndex(x => x._id == inst._id)

    if(index < 0) {
      console.log('InstrumentLogic::mergeInstrument -> Unable to merge instrument, aborting.')
      return
    }

    const old = {...instruments[index]}
    const merged = {
      ...old,
      ...inst,
    }

    const onUndo = () => {
      dispatch( mergeInstrument(old, undoDescription, false))
    }

    const onRedo = () => {
      dispatch( mergeInstrument(merged, undoDescription, false))
    }

    dispatch({
      type: BULK_REPLACE_UNIT,
      instruments: [merged]
    })

    dispatch( pushSyncChangeBulk( null, [merged] ) )
    if(createUndo) {
      const undo = makeUndo(onUndo, onRedo, undoDescription, undoDescription)
      dispatch( pushUndo(UNDO_KEY, undo) )
    }
  }
}

/**
 * # This is an expensive operation, make sure to call it only as needed
 *
 * Bulk operation that merges instruments. These arrays should be the same size.
 *
 *
 * @param {String} key the key to be merged, this operation can only change one field at a time
 * @param {Array of instruments} oldInstruments A collection of the previous values we're trying to
 * replace.
 * @param {Array of instruments} instruments
 * @param {callback<String, Instrument[]>} an optional handler that will be called with the key passed and an array of units updated.
 * called instead of dispatch if you want to manage the state manually.
 * @param {String} undoDescription the description for the undo event
 * @param {Bool} createUndo defaults true, whether or not to push an udo event
 * @param {Bool} flagAllKeys defaults false, when specified all changes will be pushed based on the key to Vectorworks rather than "just changes"
 */
export const bulkMergeInstruments = (key, oldInstruments, instruments, handler, undoDescription, createUndo = true, flagAllKeys = false) => {
  //Clear PouchDB silently unless there's an error
  return (dispatch, getState) => {

    const T = TranslatorFromState(getState, 'InstrumentLogic')
    if(createUndo && !oldInstruments) {
      throw new Error('NOT IMPLEMENTED, the bulk merge operation currently requires the value before updates')
    }

    if(!undoDescription && createUndo) {
      //Generate an undo description
      let joined = '<div>'
      const first = instruments[0]
      const last = instruments[ instruments.length - 1 ]

      const description = T.get('simpleBulkMergeDescription', {
        aId: first.channel || '??',
        bId: last.channel || '???',
        key: key,
        value: first[key],
      })
      joined += description
      joined += '</div>'

      undoDescription = joined
    }

    const old = []
    const copy = []
    const toUpdate = []

    //IMPORTANT - In this method we update things that need to be updated in tandem
    for(let inst of instruments) {
      const processed =
      copy.push( inst ) //create a copy of the action
      toUpdate.push( _processInstrument(key, inst) )
    }

    for(let inst of oldInstruments) {
      old.push({...inst})
    }

    const onRedo = () => {
      dispatch( bulkMergeInstruments(key, old, copy, handler, null, false))
    }

    const onUndo = () => {
      dispatch( bulkMergeInstruments(key, copy, old, handler, null, false))
    }

    if (handler) {
      handler(key, copy)

    } else {
      dispatch({
        type: BULK_REPLACE_UNIT,
        instruments: toUpdate
      })
    }
    const syncChangeKey = flagAllKeys ? null : _vectorworksSyncKey(key)
    dispatch( pushSyncChangeBulk( syncChangeKey, toUpdate ) )
    if(createUndo) {
      const undo = makeUndo(onUndo, onRedo, undoDescription, undoDescription)
      dispatch( pushUndo(UNDO_KEY, undo) )
    }
  }
}

/**
 *
 * @param {Instrument} inst The instrument to add to the end of the list
 */
export const addInstrument = (inst, undoDescription = null) => {

  return (dispatch, getState) => {
    const T = TranslatorFromState(getState, 'InstrumentLogic')
    if(!inst){
      return dispatch (
        Errors.reactError(
          T.get('addFailTitle'),
          T.get('addFailBody')
        )
      )
    }

    inst.type = TYPE_KEY

    if(!inst._id) {
      inst._id = uuidv1()
    }

    if(!inst.position){
      inst.position = 'unknown'
    }

    const description = undoDescription || T.get('addInstrumentUndo', inst)

    const onRedo = () => {
      //bulkMergeInstruments(key, old, copy, handler, null, false)
      const old = [{...inst}]
      const update = [{...inst, deleted: false} ]
      dispatch( bulkMergeInstruments('deleted', old, update, null, null, false) )

      //{ ...inst, deleted: false} ) )
    }

    const onUndo = () => {
      const old = [{...inst}]
      const update = [{...inst, deleted: true} ]
      dispatch( bulkMergeInstruments('deleted', old, update, null, null, false) )
    }

    const undo = makeUndo(onUndo, onRedo, description, description)

    dispatch({
      type: ADD_UNIT,
      instrument: inst
    })
    dispatch( pushSyncChangeBulk( null, [inst] ) )

    dispatch( pushUndo(UNDO_KEY, undo))
    return
    //OLD CODE LEFT FOR REFERENCE
    pouch.db()
      .put(inst)
      .then(res => {
        if(res.ok) {
          inst._id = res.id
          inst._rev = res.rev

          const _id = inst._id
          const description = T.get('addInstrumentUndo', inst)

          const onRedo = () => {
            //bulkMergeInstruments(key, old, copy, handler, null, false)
            const old = [{...inst}]
            const update = [{...inst, deleted: false} ]
            dispatch( bulkMergeInstruments('deleted', old, update, null, null, false) )

            //{ ...inst, deleted: false} ) )
          }

          const onUndo = () => {
            const old = [{...inst}]
            const update = [{...inst, deleted: true} ]
            dispatch( bulkMergeInstruments('deleted', old, update, null, null, false) )
          }

          const undo = makeUndo(onUndo, onRedo, description, description)

          dispatch({
            type: ADD_UNIT,
            instrument: inst
          })
          dispatch( pushSyncChangeBulk( null, [inst] ) )

          dispatch( pushUndo(UNDO_KEY, undo))
        } else {
					dispatch(Errors.reactError('CRITICAL ERROR', JSON.stringify(res, null, 2)))
				}
      }).catch(err => {
        dispatch(Errors.reactError('Unable to save instrument', err))
      })
  }
}

/**
 * Bulkd replace this set of instruments.
 * @param {Array of instruments} instruments
 * @param {callback[array of instruments]} an optional handler that will be
 * called instead of dispatch if you want to manage the state manually.
 */
// export const bulkReplaceInstrument = (instruments, handler) => {
//     //Clear PouchDB silently unless there's an error
//     return (dispatch, getState) => {
//       pouch.db()
//       .bulkDocs(instruments)
//       .then( result => {
//           for(let i = 0; i < result.length; i++) {
//             instruments[i]._rev = result[i].rev
//           }
//           if (handler) {
//             handler(instruments)
//           } else {
//             dispatch({
//               type: BULK_REPLACE_UNIT,
//               instruments: instruments
//             })
//           }
//         }
//       ).catch(error => {
//         return dispatch(Errors.reactError('Bulk Replacement Failed', error))
//       })
//     }
// }

export const undeleteInstruments = (instruments) => {
  if(!instruments || instruments.length < 1){
    return (dispatch, getState) => {

        const T = translator(getState)
        return dispatch(
          Errors.reactError(
            T.get('restoreInstrumentFailed'),
            T.get('restoreInstrumentFailedMessageNull')
          ) )
      }
  }

  return (dispatch, getState) => {
    let first = instruments[0]
    let last = instruments[instruments.length - 1]

    const copy = []
    instruments.forEach(inst => {
      copy.push({
        ...inst,
        deleted: !!inst.deleted,
      })
      inst.deleted = false
    })

    const undoDescription = translator(getState).get('restoreUndoMessage', {
      first: first.channel || first._id,
      last: last.channel || last._id,
    })

    dispatch( bulkMergeInstruments('deleted', copy, instruments, null, undoDescription, true, true) )
  }
}
/**
 * Mark the instruments in this list as deleted from the database
 * @param {Array of Instruments} instruments
 */
export const deleteInstruments = (instruments) => {
  if(!instruments || instruments.length < 1){
    return (dispatch, getState) => {

        const T = translator(getState)
        return dispatch(
          Errors.reactError(
            T.get('deleteInstrumentFailed'),
            T.get('deleteInstrumentFailedMessageNull')
          ) )
      }
  }

  return (dispatch, getState) => {
    let first = instruments[0]
    let last = instruments[instruments.length - 1]

    const copy = []
    instruments.forEach(inst => {
      copy.push({
        ...inst,
        deleted: !!inst.deleted,
      })
      inst.deleted = true
    })

    const undoDescription = translator(getState).get('deleteUndoMessage', {
      first: first.channel || first._id,
      last: last.channel || last._id,
    })

    dispatch( bulkMergeInstruments('deleted', copy, instruments, null, undoDescription) )
  }
}

export const instrumentReset = ()=>{
	return dispatch => {
    dispatch({ type: RESET })
  }
}
/**
 * A simple check that if we are moving an address or universe
 * then we need to update the absolute address
 * @param {*} key
 * @returns
 */
export const _vectorworksSyncKey = (key) => {
  if(key == 'address' || key == 'universe') {
    return 'absoluteAddress'
  }

  return key
}
/**
 * Process the instrument for linked records. If it isn't linked
 * then the process will return the reference to the insrument passed.
 * @param {*} key
 * @param {*} oldInt
 * @param {*} newInst
 * @returns a copy of the instrument with updated fields.
 */
export const _processInstrument = (key, instrument) => {
  //CONSTANTS
  if(key == 'address') {
    let absolute = toAbsoluteAddress(instrument.universe, instrument.address)
    console.log(`_processInstrument('address', instrument) ${instrument.universe}/${instrument.address} -> ${absolute}`)
    return {
      ...instrument,
      absoluteAddress: absolute
    }
  }

  if(key == 'universe') {
    let absolute = toAbsoluteAddress(instrument.universe, instrument.address)
    console.log(`_processInstrument('universe', instrument) ${instrument.universe}/${instrument.address} -> ${absolute}`)
    return {
      ...instrument,
      absoluteAddress: absolute
    }
  }

  if(key == 'absoluteAddress') {
    let pair = toUniverseAndAddress(instrument.absoluteAddress) || {
			address: 0,
			universe: 1,
		}
    console.log(`_processInstrument('absoluteAddress', instrument) ${instrument.universe}/${instrument.address} -> ${instrument.absoluteAddress}`)
    return {
      ...instrument,
      address: pair.address,
      universe: pair.universe,
    }
  }

  return {...instrument} //no action needed
}

/**
 * Process the incoming information from the synchonization operation
 * and dispatch the contents to the user interface.
 * //FIXME finish this documentation
 * @param {*} updatedObj
 * @param {*} deletedObj
 * @param {*} conflictedObj
 * @param {*} appStamp
 * @param {*} xmlArchive
 * @param {*} xmlResponse
 * @param {*} fileName the name of the file we synchronized
 * @param {*} onComplete a callback called with the xml data to save or use
 * @param {Bool} silent default false, if silent don't display a popup on completion
 */
export const processVectorworksSynchronization =
  ( updatedObj,
    deletedObj,
    conflictedObj,
    appStamp,
    xmlArchive,
    xmlResponse,
    fileName,
    exportFieldList,
    onComplete,
    silent = false ) => {

  if(!updatedObj) {
    updatedObj = {}
  }

  if(!deletedObj) {
    deletedObj = {}
  }

  let updated = []
  let deleted = []

  return (dispatch, getState) => {
     const db = getState().instruments.instruments.slice()

    //Synchronize with DB
    const uidLookup = {}
    const laIdLookup = {}
    const errors = []

    db.forEach(inst => {
      laIdLookup[inst._id] = inst
      if(inst.uid) {
        if(uidLookup[inst.uid] !== undefined){
          //NOTE -> this might be a revision conflict, nothing more. If it is we can just use the "last" inserted
          const lookup = uidLookup[inst.uid]
          if(inst.uid != inst._id) {
            errors.push(inst)
          } else if (lookup.uid != lookup.id) {
            errors.push(lookup)
          } else {
            //alert(`[CRITICAL ERROR] Multiple UIDs mappped in database import document ${inst.uid}:${inst.deviceType} you likely have duplicate instruments in your list.`)
            uidLookup[inst.uid] = inst
          }
          //throw new Error('[CRITICAL ERROR] Multiple UIDs in import document')
        } else {
          uidLookup[inst.uid] = inst
        }
      }
    })

    if(errors.length > 0) {
      let html = ''
      html += `<h3>[CRITICAL ERROR] Multiple UIDs mappped in database import document, these units are likely duplicates.</h3><ul>`
      for(let e of errors) {
        html += `<li><b>[${e.uid}] ${e[LA_FIELDS.POSITION]} ${e[LA_FIELDS.UNIT]}, (${e[LA_FIELDS.CHANNEL]}) ${e[LA_FIELDS.INSTRUMENT_TYPE]} </b></li>`
      }

      html += '</ul>'

      dispatch(Errors.reactError(`Synchronization Error`, html))
    }

    const prepareRecord = (incoming) => {
      if( !incoming.uid ) {
        throw new Error('[CRITICAL] The incoming unit does not have a UID (required).')
      }

      let inst = uidLookup[incoming.uid]

      if (!inst) {
        inst = {
          ...incoming,
          _id: incoming.uid,
          synced: true,
        }
      } else {
        inst = {
          ...inst,
          ...incoming,
        }
      }

      //We merge the instrument here. This is where preprocessing happens

      return _processInstrument('absoluteAddress', inst)
    }

    for(let key of Object.keys(updatedObj)) {
      updated.push( prepareRecord( updatedObj[key] ) )
    }

    for(let key of Object.keys(deletedObj)) {
      deleted.push( prepareRecord( deletedObj[key] ) )
    }

    //Operate on each
    if(log) {
      console.log('=====================================')
      console.log(uidLookup)
      console.log(laIdLookup)
      console.log('=====================================')
      console.log('= TO UPDATE')
      console.log('=====================================')
      console.log(updated)
      console.log('')
      console.log('=====================================')
      console.log('= TO DELETE')
      console.log('=====================================')
      console.log(deleted)
    }

    const bulkArray = []


    updated.forEach( inst => {
      inst.deleted = false //Undelete if we have a collision
      inst.type = TYPE_KEY

      bulkArray.push(inst)
    })

    deleted.forEach( inst => {
      inst.deleted = true
      inst.type = TYPE_KEY

      bulkArray.push(inst)
    })

    dispatch( _bulkMergeInstrumentsNoUndo( bulkArray ) )
    //Push the sync state to the database
    dispatch(
      pushImportState(
        appStamp,
        updated,
        deleted,
        conflictedObj,
        xmlResponse,
        fileName,
        undefined, //byUser
        exportFieldList,
        onComplete) )

/**
 * NOTE- FIXME we need to read deeper into this and see if it is needed or just
part of my orignial implementation */
//Save synchronization state to the db for comparison
pouch.db()
  .get(SYNC_HISTORY_KEY)
  .then(resp => {
    const archiveDoc = Object.assign(resp, {
        xml: xmlArchive,
        xmlResponse: xmlResponse,
        meta: {
          appStamp: appStamp,
          date: new Date(),
          user: 'FIXME_USER',//FIXME, add a real user here
        }
      })

      //FIXME do we need an event for this now? It seems like the
      //::pushImportState deals with this nicely.
      pouch.db().put(archiveDoc).then(r=>{
        dispatch({
          type: SYNC_UPDATE,
          status: SYNC_STATE.LA_HAS_READ,
          xml: xmlResponse,
          outstanding: 0
        })
        //We don't need to do this anymore
        Log.debug('InstrumentLogic::processVectorworksSynchronization', 'Data Archived')

      }).catch(err=> {
        //An actual error...
        dispatch(Errors.reactError('Error updating sync-document \n\t::SYNC_UPDATE ', err))
      })
    }).catch(err=> {

      const doc = {
        _id: SYNC_HISTORY_KEY,
        xml: xmlArchive,
        xmlResponse: xmlResponse,
        meta: {
          appStamp: appStamp,
          date: new Date(),
          user: 'FIXME_USER',//FIXME, add a real user here
        }
      }

      pouch.db().put(doc).then(r=>{
        dispatch({
          type: SYNC_UPDATE,
          status: SYNC_STATE.LA_HAS_READ,
          xml: xmlResponse,
          outstanding: 0
        })
      }).catch(err=> {
        //An actual error...
        dispatch(Errors.reactError('Error creating sync-document', err))
      })
    })
  }
}

/**
 * Process the incoming information from the synchonization operation
 * and dispatch the contents to the user interface.
 * //FIXME finish this documentation
 * @param {*} updatedObj
 * @param {*} deletedObj
 * @param {*} conflictedObj
 * @param {*} appStamp
 * @param {*} xmlArchive
 * @param {*} xmlResponse
 * @param {*} fileName the name of the file we synchronized
 * @param {*} onComplete a callback called with the xml data to save or use
 * @param {Bool} silent default false, if silent don't display a popup on completion
 */
export const processVectorworksSynchronizationOLD =
  ( updatedObj,
    deletedObj,
    conflictedObj,
    appStamp,
    xmlArchive,
    xmlResponse,
    fileName,
    exportFieldList,
    onComplete,
    silent = false ) => {

  if(!updatedObj) {
    updatedObj = {}
  }

  if(!deletedObj) {
    deletedObj = {}
  }

  let updated = []
  let deleted = []

  return (dispatch, getState) => {

  //Synchronize with DB
  pouch.db()
  .find({
    selector: {
      type: TYPE_KEY
    }
  })
  .then(response => {
    const uidLookup = {}
    const laIdLookup = {}
    const errors = []

    response.docs.forEach(inst => {
      const respCopy = response
      laIdLookup[inst._id] = inst
      if(inst.uid) {
        if(uidLookup[inst.uid] !== undefined){
          //NOTE -> this might be a revision conflict, nothing more. If it is we can just use the "last" inserted
          const lookup = uidLookup[inst.uid]
          if(inst.uid != inst._id) {
            errors.push(inst)
          } else if (lookup.uid != lookup.id) {
            errors.push(lookup)
          } else {
            //alert(`[CRITICAL ERROR] Multiple UIDs mappped in database import document ${inst.uid}:${inst.deviceType} you likely have duplicate instruments in your list.`)
            uidLookup[inst.uid] = inst
          }
          //throw new Error('[CRITICAL ERROR] Multiple UIDs in import document')
        } else {
          uidLookup[inst.uid] = inst
        }
      }
    })

    if(errors.length > 0) {
      let html = ''
      html += `<h3>[CRITICAL ERROR] Multiple UIDs mappped in database import document, these units are likely duplicates.</h3><ul>`
      for(let e of errors) {
        html += `<li><b>[${e.uid}] ${e[LA_FIELDS.POSITION]} ${e[LA_FIELDS.UNIT]}, (${e[LA_FIELDS.CHANNEL]}) ${e[LA_FIELDS.INSTRUMENT_TYPE]} </b></li>`
      }

      html += '</ul>'

      dispatch(Errors.reactError(`Synchronization Error`, html))
    }

    const prepareRecord = (incoming) => {
      if( !incoming.uid ) {
        throw new Error('[CRITICAL] The incoming unit does not have a UID (required).')
      }

      let inst = uidLookup[incoming.uid]

      if (!inst) {
        inst = {
          ...incoming,
          _id: incoming.uid,
          synced: true,
        }
      } else {
        inst = {
          ...inst,
          ...incoming,
        }
      }
//FIXME actually merge the data here. We're just adding right now
      return inst
    }

    for(let key of Object.keys(updatedObj)) {
      updated.push( prepareRecord( updatedObj[key] ) )
    }

    for(let key of Object.keys(deletedObj)) {
      deleted.push( prepareRecord( deletedObj[key] ) )
    }

    //Operate on each
    if(log) {
      console.log('=====================================')
      console.log(uidLookup)
      console.log(laIdLookup)
      console.log('=====================================')
      console.log('= TO UPDATE')
      console.log('=====================================')
      console.log(updated)
      console.log('')
      console.log('=====================================')
      console.log('= TO DELETE')
      console.log('=====================================')
      console.log(deleted)
    }

    const bulkArray = []


    updated.forEach( inst => {
      inst.deleted = false //Undelete if we have a collision
      inst.type = TYPE_KEY

      bulkArray.push(inst)
    })

    deleted.forEach( inst => {
      inst.deleted = true
      inst.type = TYPE_KEY

      bulkArray.push(inst)
    })

    dispatch( _bulkMergeInstrumentsNoUndo( bulkArray ) )
    //Push the sync state to the database
    dispatch(
      pushImportState(
        appStamp,
        updated,
        deleted,
        conflictedObj,
        xmlResponse,
        fileName,
        undefined, //byUser
        exportFieldList,
        onComplete) )


  }).catch(error=>{
    dispatch( Errors.reactError('CRITICAL ERROR. Unable to synchronize data \nInstrumentLogic::processVectorworksSynchronization::\ndb.find', error) )
  })

//Save synchronization state to the db for comparison
pouch.db()
  .get(SYNC_HISTORY_KEY)
  .then(resp => {
    const archiveDoc = Object.assign(resp, {
        xml: xmlArchive,
        xmlResponse: xmlResponse,
        meta: {
          appStamp: appStamp,
          date: new Date(),
          user: 'FIXME_USER',//FIXME, add a real user here
        }
      })

      //FIXME do we need an event for this now? It seems like the
      //::pushImportState deals with this nicely.
      pouch.db().put(archiveDoc).then(r=>{
        dispatch({
          type: SYNC_UPDATE,
          status: SYNC_STATE.LA_HAS_READ,
          xml: xmlResponse,
          outstanding: 0
        })
        if(!silent) {
          dispatch (
            showDialog('Data Synchronized!',
  `<p>The file was imported successfully! Don't forget to overwrite your DataExchange file
  with the new file.

  You can find this on the <a href="/show">show</a> page</p>`
          ) )
        }


      }).catch(err=> {
        //An actual error...
        dispatch(Errors.reactError('Error updating sync-document \n\t::SYNC_UPDATE ', err))
      })
    }).catch(err=> {

      const doc = {
        _id: SYNC_HISTORY_KEY,
        xml: xmlArchive,
        xmlResponse: xmlResponse,
        meta: {
          appStamp: appStamp,
          date: new Date(),
          user: 'FIXME_USER',//FIXME, add a real user here
        }
      }

      pouch.db().put(doc).then(r=>{
        dispatch({
          type: SYNC_UPDATE,
          status: SYNC_STATE.LA_HAS_READ,
          xml: xmlResponse,
          outstanding: 0
        })
      }).catch(err=> {
        //An actual error...
        dispatch(Errors.reactError('Error creating sync-document', err))
      })
    })
  }
}

/**
 * THIS IS THE UNIVERSAL STARTING POINT (web or electron) for the sync process.
 *
 * The oncomplete parameter is needed because we have two branches that
 * save the file, web or desktop. Basically it is fired to save the file or
 * generate the download URL.  I would like to eliminate it in the long run.
 *
 * @param {*} fileAsString the initial data as XML (utf8)
 * @param {*} fileName the filename uploaded (or part of file name)
 * @param {*} onComplete a callback called with the processed data as XML (utf8)
 * @param {Bool} silent default false, if true do not show a popup confirming this process.
 */
export const syncWithVectorworks = (fileAsString, fileName, onComplete, silent = false) =>{
  console.log('    ------------------------NEW METHOD DEBUG')
  return (dispatch, getState) => {
    //Setup Variables
    const state = getState()
    const vectorworks = state.vectorworks || {}
    const mappings = vectorworks.syncMappings || {}
    const changeMap = vectorworks.changeMap || {}

    //FIXME do we need to load this prior to firing this method?
    const instruments = state.instruments.instruments || []
    const instrumentMap = {}

    for(let i = 0; i < instruments.length; i++) {
      const instrument = {
        ...instruments[i]
      }
      //uid or _id if not merged yet. (The values will be the same)
      instrumentMap[instrument.uid || instrument._id] = instrument
    }

    const _MAPPINGS = {
      ...DEFAULT_VECTORWORKS_MAPPINGS,
      ...mappings,
    }

    const jsonData = readXmlFileToJson(fileAsString).then(data => {
      processVectorworksXmlFile(data, _MAPPINGS, instrumentMap, changeMap, true)
        .then(result => {
          console.log('NEW METHOD RESULT')
          console.log(result)
          console.log('END METHOD RESULT')
          const { updated, deleted, conflicted, appStamp, replacementXml } = result
          const SLData = result.xmlObject.SLData || {}
          const exportFieldList = SLData.ExportFieldList || {}

          //Remove invalid
          delete exportFieldList.Accessories
          delete exportFieldList.TimeStamp

          dispatch (
            processVectorworksSynchronization(
              updated,
              deleted,
              conflicted,
              appStamp,
              fileAsString,
              replacementXml,
              fileName,
              SLData.ExportFieldList || {},
              onComplete,
              silent,
            )
          )
        }).catch(error => {
          dispatch(Errors.reactError('Error processing Vectorworks Data ::syncXmlFile', error))
          console.log(error)
        })
    }).catch(err => {
      dispatch(Errors.reactError('Error processing Vectorworks Data ::processXmlFile', err))
      console.log(err)
    })
  }
}

///FIXME ->
/* Today we want to take
this function and move it to the "shared" function in electron. I think
we need both to work properly. I'd also like to clean this up so that is
very, clean. This should basically dispatch a redux action after calling
the right methods on the shared library.

This should also add logging details
*/
export const syncWithVectorworksDOM_METHOD_OLD = (fileAsString, mappings)=>{

  const log = true
  let toUpdateOrAdd = []
  let toDelete = []
  return dispatch => {
    //try {
      let xml
      if(window.DOMParser) {
        xml = new DOMParser().parseFromString(fileAsString, 'application/xml')
      } else {
        xml = new ActiveXObject('Microsoft.XMLDOM')
        xml.async='false'
        xml.load(fileAsString)
      }
      const fields = xml.getElementsByTagName('ExportFieldList')[0]
      const vwFields = xml.getElementsByTagName('VWFieldList')[0]
      const instData = xml.getElementsByTagName('InstrumentData')[0]
      const universeSettings = xml.getElementsByTagName('UniverseSettings')[0]

      const fieldMap = Object.assign({}, DEFAULT_VECTORWORKS_MAPPINGS)
      const vwMap = Object.assign({}, VW_SYNC_MAPPINGS)

      if(log) {
        console.log('VECTORWORKS SYNC--------------------------')
        console.log('----xml')
        console.log(xml)
        console.log('----fields')
        console.log(fields)
        console.log('----vw fields')
        console.log(vwFields)
        console.log('----universe')
        console.log(universeSettings)
        console.log('----data')
        console.log(instData)
      }
      const toImport = []
      let action, appStamp, vwVesion, vwBuild, autoRot2D
      const nodes = instData.childNodes

      const text = (node) => {
        if(node.nodeType !== 1){
          return undefined
        }

        if(node.nodeType === 1){
          const child = node.childNodes[0]
          return child ? child.nodeValue : ''
        }
        return undefined
      }

      const mapInst = (node) => {
        if(node.nodeType !== 1){
          return undefined
        }
        let inst = {}
        const mapElement = (n)=>{
          if(n.nodeType === 1){
            if(n.nodeName === 'Accessories'){
              console.log('FIXME (InstrumentLogic): BYPASSING ACCESSORIES')
              return
            } else {
              const map = vwMap[n.nodeName]
              if(!map){
                console.log('[INFO] no mapping for ' + n.nodeName)
                return
              } else {
                inst[map.key] = text(n)
              }
            }
          }
        }
        node.childNodes.forEach(mapElement)
        toImport.push(inst)
      }

      for(let i = 0; i < nodes.length; i++){
        const n = nodes[i]
        if(!n || n.nodeType !== 1) {
          continue
        }
        //Instrument
        if(n.nodeName.indexOf('UID') > -1){
          mapInst(n)
        } else if(n.nodeName === 'Action'){
          action = text(n)
        } else if(n.nodeName === 'AppStamp'){
          appStamp = text(n)
        } else if(n.nodeName === 'VWVersion'){
          vwVesion = text(n)
        } else if(n.nodeName === 'VWBuild'){
          vwBuild = text(n)
        } else if(n.nodeName === 'AutoRot2D'){
          autoRot2D = text(n)
        }
      }

      //Synchronize with DB
      pouch.db()
        .find({
          selector: {
            type: TYPE_KEY
          }
        }).then(response => {
          const uidLookup = {}
          const laIdLookup = {}

          response.docs.forEach(inst => {
            laIdLookup[inst._id] = inst
            if(inst.uid) {
              if(uidLookup[inst.uid] !== undefined){
                alert('[CRITICAL ERROR] Multiple UIDs in import document')
                throw new Error('[CRITICAL ERROR] Multiple UIDs in import document')
              }
              uidLookup[inst.uid] = inst
            }
          })

          toImport.forEach(incoming => {

            if( !incoming.uid ) {
              throw new Error('[CRITICAL] The incoming unit does not have a UID (required).')
            }

            //Add or Update in VW language
            if(incoming.action === 'Update') {

              let inst = uidLookup[incoming.uid]
              //FIXME, we might want some smarter logic here (like looking up potential matches)
              if(!inst) { //ADD
                inst = Object.assign({}, incoming, {
                  _id : incoming.uid,
                  synced: true
                })

                toUpdateOrAdd.push(inst)
              } else { //UPDATE
                inst = Object.assign({}, inst, incoming, {
                  synced: true
                })
                toUpdateOrAdd.push(inst)
              }

            } else if (incoming.action === 'Delete') {
              //Remove this unit--FIXME is Delete the keyword? Might be Remove...
              let inst = uidLookup[incoming.uid]
              if(inst) {
                toDelete.push(inst)
              } else {
                alert(`//FIXME we didn't find this light by UID '{incoming.uid}' so it doesn't exist... do we delete it?`)
              }
            }
          })

          //Operate on each
          if(log) {
            console.log('=====================================')
            console.log(uidLookup)
            console.log(laIdLookup)
            console.log('=====================================')
            console.log('= TO UPDATE')
            console.log('=====================================')
            toUpdateOrAdd.forEach(console.log)
            console.log('')
            console.log('=====================================')
            console.log('= TO DELETE')
            console.log('=====================================')
            toDelete.forEach(console.log)
          }

          toUpdateOrAdd.forEach(inst => {
            inst.deleted = false //Undelete if we have a collision
            inst.type = TYPE_KEY
            pouch.db()
              .put(inst)
              .then(x => {
                inst._id = x.id
                inst._Rev = x.rev
                //Maybe update a progress bar here?
              }).catch(err => {
                console.log(err)
                dispatch(Errors.reactError('Unable to save instrument', err))
              })
          })

          toDelete.forEach(inst => {
            inst.deleted = true
            pouch.db()
              .put(inst)
              .then(x => {
                inst._id = x.id
                inst._Rev = x.rev
                //Maybe update a progress bar here?
              }).catch(err => {
                console.log(err)
                dispatch(Errors.reactError('Unable to delete instrument', err))
              })
          })
        }).catch(error=>{
          dispatch(Errors.reactError('Unable to synchronize data', error))
        })

      const xmlOut = '<not implemented></not implemented>'

      //Save synchronization state to the db for comparison
      pouch.db()
        .get(SYNC_HISTORY_KEY)
        .then(resp => {
          const doc = Object.assign(resp, {
                xml: xmlOut,
                meta: {
                  vwVesion: vwVesion,
                  vwBuild: vwBuild,
                  autoRot2D: autoRot2D,
                  appStamp: appStamp,
                  action: action
                }
              })

          pouch.db().put(doc).then(r=>{
            console.log('dispatching SYNC_UPDATE here...')
            dispatch({
              type: SYNC_UPDATE,
              status: SYNC_STATE.LA_HAS_READ,
              xml: '<not implemented></not implemented>',
              outstanding: 0
            })
            dispatch (showDialog('Data Synchronized!',
            `<p>The file was imported successfully! Don't forget to overwrite your DataExchange file
            with the new file.

            You can find this on the <a href="/show">show</a> page</p>`) )

            dispatch( pushImportState(appStamp, toUpdateOrAdd, toDelete) )
          }).catch(err=> {
            //An actual error...
            dispatch(Errors.reactError('Error updating sync-document', err))
          })
        }).catch(err=> {
          //It didn't exist, create it
          const doc = {
            _id: SYNC_HISTORY_KEY,
            xml: xmlOut,
            meta: {
              vwVesion: vwVesion,
              vwBuild: vwBuild,
              autoRot2D: autoRot2D,
              appStamp: appStamp,
              action: action
            }
          }
          pouch.db().put(doc).then(r=>{
            console.log('dispatching SYNC_UPDATE ON FAIL...')
            dispatch({
              type: SYNC_UPDATE,
              status: SYNC_STATE.LA_HAS_READ,
              xml: '<not implemented></not implemented>',
              outstanding: 0
            })

            dispatch (showDialog('Data Synchronized!',
            `<p>The file was imported successfully! Don't forget to overwrite your DataExchange file
            with the new file.

            You can find this on the <a href="/show">show</a> page</p>`) )
          }).catch(err=> {
            //An actual error...
            dispatch(Errors.reactError('Error creating sync-document', err))
          })
        })
  }
}

/**
 * # This alters the main dataset. Call this only for non-mutating methods
 *
 * A utility method to set the instruments directly if
 * needed by another module (worksheets).
 *
 * THIS DOES NOT WORK WITH VECTORWORKS SYNCHRONIZATION
 */
export const backendInstrumentsDirectly = (instruments) => {
  if(true) {
    console.log('SETTING BACKEND')
    console.log(instruments)
  }
  return dispatch => dispatch({
    type: BACKEND_SET_INSTRUMENTS_DIRECTLY,
    instruments: instruments,
  })
}

/**
 * An acion that splits the universe to the universe column
 * and copies the address to the address column
 */
export const copyDimmerToAbsoluteAddress = () => {
  return (dispatch, getState) => {
      alert(
`WARNING: Vectorworks 2020 and later translates "dimmer"
to an absolute address. This means your universe will effectively
be lost if the dimmer is not stored as an absolute address. Light Assistant
stores addresses as absolute addresses and simply displays the other formats.

This function copies the dimmer to absolute address.
`)
    const state = getState()
    const instruments = (state.instruments.instruments || []).slice()
    const updates = []

    const process = (source) => {
      let inst = { ...source }


      let dimAddress = toNumericDmxAddress(inst.dimmer)

      if(dimAddress >= 0) {
        inst.absoluteAddress = dimAddress
        inst =_processInstrument('absoluteAddress', inst)
      }

      return inst
    }

    //Main loop, instruments then accessories
    for(let _inst of instruments) {
      let inst = process(_inst)
      updates.push(inst)
    }

    //We directly set the backend here
    dispatch( backendInstrumentsDirectly(updates) )
    //Then we update dimmer, address, universe...this feels hacky
    //FIXME THIS IS HAAAACKKKYYYY
    dispatch( pushSyncChangeBulk('absoluteAddress', updates) )
  }
}

/**
 * Populates the universe/address field then
 * migrates that address to the dimmer column as a
 * set value.
 */
export const cloneAddressToDimmers = (useUniverseFormat = true) => {
  return (dispatch, getState) => {

  }
}

export const moveToUniverseSlashAddress = () => {
  return (dispatch, getState) => {

  }
}

export const INITIAL_STATE = {
  instruments: [],
  sort: Sorts.CHANNEL_HOOKUP,
  positions: [],
  mappings: [
    {
      property: LA_FIELDS.POSITION,
      width: 100,
    }, {
      property: LA_FIELDS.UNIT,
      width: 50,
    }, {
      property: LA_FIELDS.DEVICE_TYPE,
      width: 100,
      locked: true,
    }, {
      property: LA_FIELDS.INSTRUMENT_TYPE,
      width: 150,
      editor: 'select'
    },  {
      property: LA_FIELDS.FRAME_SIZE,
      width: 75,
    }, {
      property: LA_FIELDS.CHANNEL,
      width: 100,
    }, {
      property: LA_FIELDS.UNIVERSE,
      width: 100,
    },  {
      property: LA_FIELDS.ADDRESS,
      width: 100,
    },  {
      property: LA_FIELDS.CIRCUIT_NAME,
      width: 50,
    },  {
      property: LA_FIELDS.CIRCUIT_NUMBER,
      width: 50,
    },  {
      property: LA_FIELDS.COLOR,
      width: 100,
    },  {
      property: LA_FIELDS.TEMPLATE,
      width: 100,
    },  {
      property: LA_FIELDS.TEMPLATE2,
      width: 100,
    },  {
      property: LA_FIELDS.PURPOSE,
      width: 100,
    },  {
      property: LA_FIELDS.USER_FIELD_1,
      width: 100,
    }
  ],
  sync: {
    status: SYNC_STATE.NOT_STARTED,
    fileUrl: null,
    mappings: null,
    lastSync: null,
    xml: null,
    outstanding: -1
  }
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    case RESET: {
      return INITIAL_STATE
    }

    case SYNC_UPDATE: {
      console.log('SYNC_UPDATE_CALLED')
      return {
        ...state,
        xml: action.xml,
        outstanding: action.outstanding,
        status: action.status
      }
      //OLD METHOD, forced a refresh...
      // return Object.assign({}, INITIAL_STATE, {
      //   xml: action.xml,
      //   outstanding: action.outstanding,
      //   status: action.status
      // });
    }

    case ADD_UNIT: {
      const update = Object.assign({}, state)
      const out = update.instruments.slice()
      out.push(action.instrument)
      update.instruments = out
      return update
    }

    case LOAD_INST: {
      console.log('LOADING_INSTRUMENTS for sort ' + action.sort || state.sort)
      //Update sync counts
      let count = 0
      action.instruments.forEach(x => {
        if(!x.synced){
          count++
        }
      })

      const sync = Object.assign({}, _getSync(), {
        outstanding: count
      })

      let sortFn = null

      //select sort fn
      const sort = action.sort || state.sort
      switch(sort) {
        case Sorts.INSTRUMENT_SCHEDULE:
          sortFn = Sorts.instrumentSchedule
          break

        case Sorts.CHANNEL_HOOKUP:
          sortFn = Sorts.channel
          break

        case Sorts.ADDRESS_HOOKUP:
          sortFn = Sorts.dimmer
          break
        case Sorts.CIRCUIT_HOOKUP:
          sortFn = Sorts.circuit
          break

        case Sorts.COLOR_SCHEDULE:
          sortFn = Sorts.colorSchedule
          break

        case Sorts.COLOR_THEN_FRAME:
          sortFn = Sorts.colorThenFrame
          break

        default:
          sortFn = null
      }

      //I really don't like that we're doing an extra operation here
      let sorted = _presortParentAccessories( action.instruments )

      if(sortFn) {
        sorted.sort(sortFn)
      }

      const loaded = {
        instruments: sorted,
        sort: sort,
        sync: sync
      }

      if(action.whenDone) {
        action.whenDone(loaded.instruments) //apply to sorted set
      }
      return Object.assign({}, state, loaded)
    }

    case DELETE_UNITS: {
      const toRemove = action.instruments
      if (!toRemove || toRemove.length === 0) {
        Log.warn('"DELETE UNITS" was passed an array of no length or was null')
        return state
      }
      let copy = state.instruments.slice()

      for(let inst of toRemove) {
        const index = copy.findIndex(x => x._id === inst._id)
        if(index > -1) {
          Log.debug(`DELETE_UNITS::removing {_id} {channel} {position} {unit}`, inst)
          copy.splice(index, 1) //remove
        } else {
          Log.debug(`DELETE_UNITS::removing FAILED TO REMOVE!!{_id} {channel} {position} {unit}`, inst)
        }
      }
      //Old backwards logic (remove from end by splicing backwards as we go.)
      // for(let i = copy.length; i--; i > -1) {
      //   const exists = toRemove.findIndex(x => x._id === copy[i]._id)

      //   if (exists > -1) {
      //     Log.debug(`DELETE_UNITS::removing {_id} {channel} {position} {unit}`, copy[i])
      //     copy.splice(i, 1) //remove
      //   }
      // }

      return Object.assign({}, state, {
        instruments: copy
      })
    }

    case BULK_REPLACE_UNIT: {
      const instruments = action.instruments || []
      const toAdd = action.toAdd || []
//DEBUG OUTPUT
//console.log(JSON.stringify(action, null, 2))
      if(instruments.length < 1 && toAdd.length < 1) {
        return state
      }

      //This merges only incoming changes
      let copy = state.instruments.slice()

      if(instruments.length > 0) {
        for(let inst of instruments) {
          const index = copy.findIndex(x => x._id == inst._id)
          if(index > -1) {
            copy[index] = inst
          } else {
            console.log(`[InstrumentLogic::BULK_REPLACE_UNIT Data lost: unable to match key for ${JSON.stringify(inst)}`)
          }
        }
      }
      //FIXME OLD WEIRD LOGIC this is weird logic, shouldn't we just accept both?
      // if(instruments.length > 0) {
      //   for(let i = 0; i < copy.length; i++){
      //     const unitInStack = instruments.findIndex(x => x._id == copy[i]._id)

      //     if(unitInStack > -1) {
      //       //replace...do we want to merge?
      //       copy[i] = instruments[unitInStack]
      //     } else {
      //       //FIXME high priority error
      //       console.log(`[InstrumentLogic::BULK_REPLACE_UNIT Data lost: unable to match key for ${JSON.stringify(copy[i])}`)
      //     }
      //   }
      // }

      //FIXME this feels like a hack.
      if(toAdd && toAdd.length) {
        for(const inst of toAdd) {
          const _c = {
            ...inst
          }
          if(!_c._id) {
            _c._id = uuidv1()
          }

          copy.push(_c)
        }
      }

      return {
        ...state,
        instruments: copy,
      }
    }

    case REPLACE_UNIT: {
      const inst = action.instrument
      if(inst){
        const index = state.instruments.findIndex(x => x._id === inst._id)
        if(index < 0){
          return state
        }

        const instruments = state.instruments.slice()
        instruments[index] = inst
        return Object.assign({}, state, {
          instruments : instruments
        })
      } else {
        return state
      }
    }

    case SYNC_SETUP: {
      const { fileUrl, mappings } = action
      return {
        ...state,
        fileUrl: fileUrl,
        mappings: mappings,
      }
    }

    case SET_SYNC_FILE: {
      let update = Object.assign({}, state.sync, {
        fileUrl: action.fileUrl
      })
      _setSync(update)
      return {
        ...state,
        sync: update
      }
    }

    case BACKEND_SET_INSTRUMENTS_DIRECTLY: {
      let { instruments } = action
      return {
        ...state,
        instruments: instruments
      }
    }

    case REPLACE_POSITIONS: {
      let { positions } = action
      return {
        ...state,
        positions: (positions || []).slice(),
      }
    }

    default:
      return state
  }
}
