import React from 'react'
import {
  Paper
} from '@material-ui/core'

const paperStyle = {
  padding: '16px',
  margin: '20px 0 0 20px',
  minWidth: 300,
  flexDirection: 'column',
  display: 'flex',
}

export const CardSeries = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      { props.children }
    </div>
  )
}

export const Card = props => {
  const {children, style, ...rest} = props
  return (
    <Paper
      style={{
        ...paperStyle,
        ...style,
      }}
    >
      { children }
    </Paper>
  )
}