//import PDFDocument from 'pdfkit'
import { joinStrings, formatUnicorn } from './../StringUtil'
import { num } from './../Utility'
import { Translator} from './../language/Translate'
import { createPositionList } from '../ReportHelpers'
import {
  readAccessories
} from './../vectorworks'

import moment from 'moment'

export const DEFAULT_FONT = 'Helvetica'
export const DEFAULT_BOLD = 'Helvetica-Bold'
export const DEFAULT_FONT_SIZE = 10

/**
 * Converts inches to pts
 * @param {number} _inches 
 */
export const inches = (_inches) => {
  return Math.floor( num(_inches, 0) * 72 )
}

export const cm = (_cm) => {
  return Math.floor( num(_cm, 0) * 28.3465 )
}

export const ELEMENT_TYPES = {
  TEXT: 'text',
  RECTANGLE: 'rect',
  TRIANGLE: 'triangle',
  CIRCLE: 'circle',
  LINE: 'line',
}
export const ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',

  V_TOP: 'top',
  V_MIDDLE: 'middle',
  V_BOTTOM: 'bottom',
}

export const SHAPE = {
  LINE_CAP: 'lineCap',
  LINE_WIDTH: 'lineWidth',
  STROKE: 'stroke',
  FILL: 'fill',
  X: 'x',
  Y: 'y',
  WIDTH: 'width',
  HEIGHT: 'height',
}

export const TEXT_OPTIONS = {
  wrap: 'wrap',
}
/**
 * A helper function to create text for the PDF.
 * Required Parameters: (string, x, y)
 * Everything else is optional.
 */
export const text = (string, x, y, width, height, wrap, size, fontName, alignment, verticalAlignment, color) => {
  return {
    type: ELEMENT_TYPES.TEXT,
    text: string,
    x: x,
    y: y,
    width: width,
    height: height,
    wrap: wrap,
    size: size,
    font: fontName,
    alignment: alignment,
    verticalAlignment: verticalAlignment,
    color: color
  }
}

/**
 * Creates a rectangle.
 */
export const rect = (x, y, w, h, stroke = 'black', fill, radius = 0, lineWidth = 2) => {
  return {
    type: ELEMENT_TYPES.RECTANGLE,
    x: x,
    y: y,
    width: w,
    height: h,
    stroke: stroke,
    fill: fill,
    radius: radius,
    lineWidth: lineWidth,
  }
}

//A Help method for a line
export const line = (x, y, x2, y2, stroke = 'black', lineWidth = 1, lineCap = 'butt') => {
  const out = {
    type: ELEMENT_TYPES.LINE,
  }

  out[SHAPE.X] = x
  out[SHAPE.Y] = y
  out[SHAPE.WIDTH] = x2 - x
  out[SHAPE.HEIGHT] = y2 - y
  out[SHAPE.STROKE] = stroke
  out[SHAPE.LINE_CAP] = lineWidth
  out[SHAPE.LINE_CAP] = lineCap

  return out
}

export const PAPER_US_LETTER_PORTRAIT = {
  layout: 'portrait',
  margins: {
    top: inches(1/2),
    left: inches(1/4),
    right: inches(1/4),
    bottom: inches(1/2),
  },
  paperSize: {
    width: inches(8.5),
    height: inches(11),
  }
}

export const PAPER_US_LETTER_LANDSCAPE = {
  layout: 'landscape',
  margins: {
    top: inches(1/2),
    right: inches(1/4),
    bottom: inches(1/2),
    left: inches(1/4),
  },
  paperSize: {
    width: inches(8.5),
    height: inches(11),
	},
	grid: {
    rows: 10,
    columns: 3,
    paddingRow: 0,
    paddingColumn: inches(1/8),
  }
}

export const PAPER_AVERY_5160_FBA_LABEL_1x2n5_8 = {
  ...PAPER_US_LETTER_PORTRAIT,  
  margins: {
    top: inches(1/2),
    right: inches(3/16),
    bottom: inches(1/2),
    left: inches(3/16),
  },
  printArea: {
    width: inches(2 + 5/8),
    height: inches(1),
  },
  grid: {
    rows: 10,
    columns: 3,
    paddingRow: 0,
    paddingColumn: inches(1/8),
  },
}

export const PAPER_US_LETTER_BOOKLET = {
  ...PAPER_US_LETTER_LANDSCAPE,  
  printArea: {
    width: inches(5),
    height: inches(4),
  },
  grid: {
    rows: 2,
    columns: 2,
    paddingRow: inches(1/2),
    paddingColumn: inches(1/2),
  },
}

/**
 * Return a VARIABLES object that has a lot of utility methods
 * for time/show/data/etc...
 * @param {ShowData} show 
 */
export const createVariables = (show) => {
  const _time = moment()
  const VARIABLES = {
    show: show.currentShow || 'Unknow Show',
    YYYY: _time.format('YYYY'),
    YY: _time.format('YY'),
    Q: _time.format('Q'),
    M: _time.format('M'),
    MM: _time.format('MM'),
    MMM: _time.format('MMMM'),
    MMMM: _time.format('MMMM'),
    month: _time.format('MMMM'),
    D: _time.format('D'),
    DD: _time.format('DD'),
    Do: _time.format('Do'),
    DDD: _time.format('DDD'),
    X: _time.format('X'),
    day: _time.format('dddd'),
    dddd: _time.format('dddd'),
    H: _time.format('H'),
    HH: _time.format('HH'),
    h: _time.format('h'),
    hh: _time.format('hh'),
    a: _time.format('a'),
    am: _time.format('a'),
    mm: _time.format('mm'),
    minutes: _time.format('mm'),
    ss: _time.format('ss'),
    seconds: _time.format('ss'),
    ZZ: _time.format('ZZ'),
    date: _time.format('MMMM Do YYYY'),
    time: _time.format('hh:mm:ss a'),
    shortDate: _time.format('YYYY-MM-DD'),
  }

  return VARIABLES
}

/**
 * Warning, this function mutates the document object 
 * and could, potentially, cause drawing failures.
 * 
 * @param {*} text 
 * @param {*} width 
 * @param {*} size 
 * @param {*} font 
 */
const _calcTextSize = (doc, availableSpace, text, width, size, font) => {
  doc.fontSize(size || DEFAULT_FONT_SIZE)
  doc.font(font || DEFAULT_FONT)
  const out = doc.heightOfString(text, { width: width })
  return out
}

const _calculateLineHeight = (doc, ptSize) => {
  return doc._font.ascender / 1000 * ptSize
}
/**
 * !!Warning!!, you need to set the font in the document first
 */
const _calculateVerticalCenter = (doc, y, height, ptSize) => {
  const size = doc._font.ascender / 1000 * ptSize
  return y + (height - size) / 2 
}

/**
 * !!Warning!!, you need to set the font in the document first.
 * 
 * I'd also recommend you pad by -2 or more
 */
const _calculateVerticalBottom = (doc, y, height, ptSize) => {
  const size = doc._font.ascender / 1000 * ptSize
  return y + (height - size)
}

const _drawText = (doc, drawableSpace, text, data) => {
  const size = text.size || DEFAULT_FONT_SIZE
  const width = text.width || drawableSpace.width - num(text.x)
  const font = text.font || DEFAULT_FONT
  const ellipsis = !text.wrap
  const align = text.alignment || ALIGNMENT.LEFT
  const vAlign = text.verticalAlignment || ALIGNMENT.V_TOP
  const color = text.color || 'black'

  let x = text.x
  let y = text.y
  let height = text.height
  //Calculate the size of text and deal with it
  let formattedText = formatUnicorn(text.text || '', data || {}).trim()
  let textHeight = 0
  
  if(!text.wrap) {
    textHeight = _calcTextSize(doc, width, 'gpZ', width, size, font)
  } else {
    textHeight = _calcTextSize(doc, text.width || drawableSpace.width, formattedText, width, size, font)
  }

  if(height < textHeight) {
    height = textHeight
  }

  if(vAlign === ALIGNMENT.V_BOTTOM) { 
    y = _calculateVerticalBottom(doc, y, height, size)
  } 
  
  if (vAlign === ALIGNMENT.V_MIDDLE || vAlign === ALIGNMENT.CENTER) {
    y = _calculateVerticalCenter(doc, y, height, size)
  }
  
  doc.save()
  // doc
  //   .rect(text.x, text.y, width, height)
  //   .fill('yellow')

  doc.fontSize(text.size || DEFAULT_FONT_SIZE)
  doc.font(text.font || DEFAULT_FONT)
  doc.fill(color)
  doc.text(formattedText || '', x, y, {
    width: width,
    height: textHeight,
    lineBreak: !!text.wrap,
    ellipsis: ellipsis,
    align: align,
  })
  doc.restore()
}

export const _drawRect = (doc, el) => {
  const { x, y, width, height, radius, fill, stroke, lineWidth } = el 
  doc.save()
  
  if(fill && stroke) {
    doc
      .stroke(stroke)
      .fill(fill)
      .lineWidth(lineWidth)
      .roundedRect(x, y, width, height, radius || 0)      
      .fillAndStroke()
  } else if (fill) {
    doc
      .roundedRect(x, y, width, height, radius || 0)      
      .fill(fill)
      
  } else if (stroke) {
    doc
      .lineWidth(lineWidth)
      .roundedRect(x, y, width, height, radius || 0)
      .stroke(stroke)
  }

  doc.restore()
}

export const _drawLine = (doc, el) => {
  const { x, y, width, height, stroke = 'black', lineWidth = 1, cap = 'butt'} = el
  doc.save()

  doc
    .lineCap(cap)
    .lineWidth(lineWidth)
    .moveTo(x, y)
    .lineTo(x + width, y + height)
    .stroke(stroke)

  doc.restore()
  
}

export const generateReport = (report, stream, dataArray, show) => {
  const paper = {
    ...PAPER_US_LETTER_PORTRAIT, 
    ...report.paper,
  }

  return new Promise( 
    (resolve, reject) => {
      try {
        //DETERMINE PAPER SIZE
        let grid = {
          rows: 1,
          columns: 1,
          paddingRow: 0,
          paddingColumn: 0,
          ...paper.grid,
        }
        const pageElements = grid.rows * grid.columns

        let printArea = {}

        if(paper.printArea) {
          printArea = {
            ...paper.printArea,
          }
        } else {
          printArea.width = Math.floor(paper.paperSize.width - paper.margins.left - paper.margins.right)
          printArea.height = Math.floor(paper.paperSize.height - paper.margins.top - paper.margins.bottom)
        }

        console.log('PAPER SIZE', report.paperSize)
        console.log('PRINT AREA', printArea)
        console.log('MARGINS', report.margins)

        const pageSettings = {
          layout: paper.layout || 'portrait',
          size: [paper.paperSize.width || 612, paper.paperSize.height || 792], //8.5x11 default
          margins: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }
        }

        let doc = new PDFDocument({
          bufferPages: true,
          ...pageSettings,
        })

        doc.info = {
          ...doc.info,
          Title: report.title || 'Untitled',
          Autor: report.username || 'Light Assistant',
          Subject: 'Light Assistant Report',
        }

        doc.fontSize(12)
        doc.font(DEFAULT_FONT)

        //console.log(stream)
        let output = doc.pipe(stream)



        //for each page, we need to do a "new page" function
        //doc.rect(0, 0, paper.margins.left, paper.margins.top).fill('blue')
        doc.translate(paper.margins.left, paper.margins.top)
        doc.save()
//---------START RENDER        

        let _COLUMN = 0
        let _ROW = 0
        let page = 0

        // doc.save()
        //   doc
        //     .roundedRect(0, 0, printArea.width, printArea.height, 5)
        //     .stroke('red')
        // doc.restore()
         
        
        for(let i = 0; i < dataArray.length; i++) {
          const columnOffset = Math.floor(_COLUMN * printArea.width + _COLUMN * paper.grid.paddingColumn)
          const rowOffset = Math.floor(_ROW * printArea.height + _ROW * paper.grid.paddingRow)          
          //MAIN LOOP SAVE

          doc.save()
          doc.translate(columnOffset, rowOffset) 

          let data = dataArray[i]

          //---------- RENDER LOOP
          for(let el of report.elements || []) {
            doc.save()
            switch (el.type) {
              case ELEMENT_TYPES.TEXT: {
                _drawText(doc, printArea, el, data)
                break
              }

              case ELEMENT_TYPES.RECTANGLE: {
                _drawRect(doc, el)
                break
              }

              case ELEMENT_TYPES.LINE: {
                _drawLine(doc, el)
                break
              }

              default: {
                //FIXME WE need to raise errors here to the user.
                console.warn('UKNOWN ELEMENT TYPE')
              }
                
            }

            doc.restore()
          }

//---------- MAIN LOOP RESTORE
          doc.restore()


          //----------LOOP MAINTENANCE
          _ROW++
          if(i + 1 >= dataArray.length) {
            //we're done
            break
					}

          if(_ROW >= paper.grid.rows) {
            _COLUMN++
            _ROW = 0
          }

          if(_COLUMN >= paper.grid.columns) {
            //NEW PAGE
            _COLUMN = 0
            _ROW = 0

            doc.addPage()
            doc.translate(paper.margins.left, paper.margins.top)
            doc.save()
            page++
          }
        }

        doc.flushPages()
        doc.end()
        resolve(output)

      } catch (error) {
        reject(error)
      }

    })
}