import {
  num,
  joinStrings,
} from './Utility'

import {
  ifNullThen,
} from './StringUtil'

import {
  readAccessories
} from './vectorworks'

export const formatPartial = (inst) => {
  const channel = inst.channel
  const part = inst.part

  const check = num(part, 1)
  const noParts = (ifNullThen(channel, '') + '').split(' P')[0]

  if(check > 1) {
    return noParts + ' P' + check
  } else {
    return channel
  }
}

export const REPORT_VARIABLES = {
  'checkbox': () => {
    return '\u2610'//doesn't work on base fonts...
  },
  'circuit': (inst) => {
    const name = inst.circuitName || ''
    const num = inst.circuitNumber || ''
    return name + num
  },
  'instrumentTypeAndAccessories': (inst) => {
    const type = inst.instrumentType || ''
    const accessories = readAccessories(inst) || ''
    return type + ' ' + accessories
  },
  'colorFrost': (inst) => {
    const color = inst.color
    const frost = inst.frost
    return joinStrings(color, frost)
  },
  'templates': (inst) => {
    const template = inst.template
    const template2 = inst.template2

    return joinStrings(template, template2)
  },
  'colorAndTemplates': (inst) => {
    const color = inst.color
    const frost = inst.frost
    const template = inst.template ? 'T:'+inst.template : ''
    const template2 = inst.template2 ? 'T:'+inst.template2 : ''

    return joinStrings(color, frost, template, template2)
  }
}

export const applyVariables = (inst) => {
  const copy = {
    ...inst
  }
  for(const key of Object.keys(REPORT_VARIABLES) ) {
    copy[key] = REPORT_VARIABLES[key](inst)
  }

  return copy
}