import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Standard from '../../components/Layouts/Standard'
import SortIcon, { SortStatus } from './SortIcon'
import Errors from './../Errors'
import { showSnack, showConfirm, showThreeAction, showDialog, showWarning } from '../reducers/InterfaceLogic'
import { ElectronMethods } from '../reducers/ElectronLogic'
import translate from '../../shared/language/Translate'
import { instruments as Strings, general, notes } from '../../shared/language'
import Draggable from 'react-draggable'
import loremIpsum from 'lorem-ipsum'
import Colors from './../Colors'
import SyncModule from './SyncModule'
import UtilityMenu from './UtilityMenu'

import { chunk, jsonToTable, isNull } from '../../shared/Utility'
import {
  parseInstrumentCommandLine,
  isCommand,
  inError,
} from './../../shared/parser/CommandLine'

import {
  updateSetting
} from './../reducers/SettingsLogic'

import {
  Grid,
  MultiGrid,
  List,
  Table,
  Column,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache, 
} from 'react-virtualized'

import { 
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Drawer,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core'

import IconCheck from '@material-ui/icons/Check'
import IconCancel from '@material-ui/icons/Cancel'
import IconSettings from '@material-ui/icons/Settings'
import IconClose from '@material-ui/icons/Close'
import IconUp from '@material-ui/icons/ArrowUpward'
import IconDown from '@material-ui/icons/ArrowDownward'
import IconAdd from '@material-ui/icons/Add'
import IconEdit from '@material-ui/icons/Edit'
//import IconDrag from '@material-ui/icons/DragHandle'
import IconDrag from '@material-ui/icons/DragIndicator'
import IconNote from '@material-ui/icons/Flag'

import Undo from './../history/Undo'

import InstrumentLogic, { 
  addInstrument, 
  bulkMergeInstruments, 
  dataOnly_flagInstrumentAsAllChanged,
  diff, 
  deleteInstruments,
  undeleteInstruments,
  loadInstruments, 
  UNDO_KEY, 
} from './../reducers/InstrumentLogic'

import {
  createDefaultWorksheets,
  createWorksheet,
  mergeMissingMappings,
  moveProperty,
  removeWorksheet,
  selectWorksheet,
  toggleProperty,
  updateWorksheet,
} from './../reducers/WorksheetsLogic'

import InstrumentRow from './InstrumentRow'
import Logger from './../../shared/WebLog'
import DebugInfo from '../../components/DebugInfo'
import TextFieldRef from './../../components/TextFieldRef'
import AddWorksheetDialog from './AddWorksheetDialog'
import EditWorksheetGroupings from './EditWorksheetGroupings'
import EditWorksheetSort from './EditWorksheetSort'
import InstrumentNotesDialog from './InstrumentNotesDialog'
import LoadingCentered from '../../components/LoadingCentered'
import { 
  buildInstrumentNotesCacheByChannel,
  showNoteDialog,
  showNotesDialogForInstrument,
  utilNotesActive,
} from '../reducers/NotesLogic'

let log = Logger('en', 'InstrumentSheet')

export const MINIMUM_COLUMN_WIDTH = 50

const Spacer = (props) => {
  return (
    <div style={{flex: 1}} {...props}></div>
  )
}

const Container = (props) => {
  let style = null
  if(props.override) {
    style = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...props.override,
    }
  } else {
    style = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    }
  }

  return (
    <div
      style={style}  
      {...props}
    />
  )
}

class WrappedIconButton extends React.Component {
  render() {
    const { children, ...rest} = this.props
    return (
      <span>
        <IconButton {...rest}>
          {children}
        </IconButton>
      </span>
    )
  }
}
class WrappedTextArea extends React.Component {
  render() {
    return <textarea {...this.props} />
  }
}

class WrappedTextField extends React.Component {
  render() {
    return <TextField {...this.props} />
  }
}

class WorksheetField extends React.Component {
  render() {
    const { index, field } = this.props
    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            width: 30,
          }}
        >
          {index}
        </span>

        <IconButton
          color='primary'
          disabled={index === 0}
          style={{
            width: 24,
            height: 24,
          }}
          onClick={e => {
            this.props.onMoveUp()
          }}
        >
          <IconUp 
            style={{
              width: 18,
              height: 18,
            }}
          />
        </IconButton>

        <IconButton
          color='secondary'
          style={{
            width: 24,
            height: 24,
            marginRight: 10,
          }}
          onClick={e => {
            this.props.onMoveDown()
          }}
        >
          <IconDown 
            style={{
              width: 18,
              height: 18,
            }}
          />
        </IconButton>

        <FormGroup 
          style={{
            width: 200,
            minWidth: 200,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox 
                checked={ !field.hidden }
                color='default'
                onChange={ () => {
                  this.props.onChange()
                }}
              />
            }
            label={field.property}
          />
          <FormHelperText 
            style={{
              marginTop: -12,
              marginLeft: 34,
              color: Colors.LA_BLUE,
            }}
          >
            {field.disabled ? general.get('locked') : ''}
          </FormHelperText>
        </FormGroup>
        
      </div>
    )
  }
}



class InstrumentPage extends React.Component {

  constructor(props) {
    super(props)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)

    const T = this.props.strings
    
    this.onSelect = this.onSelect.bind(this)
    this.deselect = this.deselect.bind(this)
    this.cancelEdit = this.cancelEdit.bind(this)
    this.updateEdit = this.updateEdit.bind(this)
    this.commitEdit = this.commitEdit.bind(this)
    
    this._getColumnName = this._getColumnName.bind(this)
    this._getColumnWidth = this._getColumnWidth.bind(this)
    this._cellRenderer = this._cellRenderer.bind(this)
    this._hoverIn = this._hoverIn.bind(this)
    this._handleCellOnClick = this._handleCellOnClick.bind(this)

    const settings = props.settings || {}
    const columnMappings = this.props.mappings.map( m => {
      return {
        key: m.property,
        width: m.width,
        name: T.get(m.property),
        locked: m.disabled,
        editor: m.editor || 'textarea',
      }
    })

    const columns = columnMappings

    this.selectStart = -1
    this.selectEnd = -1
    this.selectKey = null

    this.selected = {}
    this.lastSelection = -1
    this.editingRow = -1
    this.backup = null
    this.editing = null
    this.message = 'no message'

    this.state = {
      message:'',
      columns: columns,
      settingsWorksheet: null,
      __delayLoad: true,
      __firstLoad: true,
      //Notes Dialog for multiple notes
      _notesDialogOpen: false,
      _notesDialogInstrument: {},
      _notesDialogNotes: [],
    }

    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 40,
    })
  }

  componentDidMount() {
    //Load up the worksheet
    console.log('MOUNTED...')
    this.props.dispatch( buildInstrumentNotesCacheByChannel() )
  }

  componentWillReceiveProps(props) {
    const T = props.strings

    // We probably want to send this out to dispatch, but we don't 
    // want to redraw the component constantly. Maybe just check against
    // the active sheet and cache?

    //FIXME Optimize this so we don't run through dispatch constantly

    if(props.worksheets && props.worksheets[props.worksheet]) {
      const worksheet = props.worksheets[props.worksheet]
      const columnMappings = worksheet.fields.
        filter( m => !m.hidden)
        .map( m => {
          return {
            key: m.property,
            width: m.width,
            name: T.get(m.property),
            locked: m.disabled,
            editor: m.editor || 'textarea',
          }
        })
  
      this.setState({
        columns: columnMappings
      })

      if(this.grid) {
        setTimeout(()=> {
          if(this.grid) {
            this.grid.forceUpdateGrids()
            this.grid.recomputeGridSize({})
          }
          // this.grid.scrollToColumn(0)
          // this.grid.scrollToRow(0)
        }, 250)
      }
    } 

  }

  debounce() {
    this.setState({
      ...this.state,
      debounce: true,
    })
  }

  clearDebounce() {
    this.setState({
      ...this.state,
      debounce: false,
    })
  }

  openSettings() {
    this.setState({
      ...this.state,
      settingsOpen: true,
    })
  }

  closeSettings() {
    this.setState({
      ...this.state,
      settingsOpen: false,
    })
  }
  /**
   * The function called to select an item in the spreadsheet
   * @param {Integer} index the row index
   * @param {String} key the key to lookup (column)
   * @param {Boolean} shift is the shift key down
   * @param {Boolean} ctrl is the ctrl key down?
   * @memberof Dev
   */ 
  onSelect(index, key, shift, ctrl) {
    // log.info(`::onSelect index:${index} key:${key} shift:${shift} ctrl:${ctrl}`)
    // console.log('BEFORE', this)
    
    if(!ctrl && this.selected[index] && key === this.selectKey) {
      return// we're already selected, just allow it to focus
    }
    let selectionCopy = {
      ...this.selected
    }

    const deselectRow = (index) => {
      selectionCopy[index] = false
    }

    const selectRow = (index) => {
      selectionCopy[index] = true
    }

    const selectOrDeselectRow = (index) => {
      if(this.selected[index]) {
        selectionCopy[index] = false
        return false
      } else {
        selectionCopy[index] = true
        return true
      }
    }

    if(this.lastSelection < 0) {
      this.lastSelection = index
    }

    if(this.selectKey == key) {
      if(shift) { //range select
        if(index > this.lastSelection) {
          for(let i = this.lastSelection + 1; i < index; i++) {
            selectOrDeselectRow(i)
          }
        } else {
          for(let i = this.lastSelection -1; i > index; i--){
            selectOrDeselectRow(i)
          }
        }

        selectRow(index)
        this.editingRow = index

      } else if (ctrl) {
        if( selectOrDeselectRow(index) ) { //if we add to the selection move the row here
          this.editingRow = index
        }

      } else { //default
        selectionCopy = {}
        selectRow(index)
        this.selectStart = index
        this.selectKey = key
        this.editingRow = index
      }

    } else { //not the previous column
      selectionCopy = {}
      selectRow(index)
      this.selectStart = index
      this.selectKey = key
      this.editingRow = index
    } 
    //Track the editor
    
    this.selected = selectionCopy
    this.lastSelection = index

    const selections = '[' + Object.keys(this.selected).map(s=> s +', ') + ']'
    this.setState({
      ...this.state,
      editValue: this.props.instruments[index][key],
      editBackup: this.props.instruments[index][key],
      //DEBUG
      message: `Selected ${index} ${key} ${shift} | ${this.selectStart}-> ${this.selectEnd}: ${this.selectKey} editing: ${this.editingRow} selections:${selections}`,
    })

    // console.log('AFTER', this)
    // console.log('-----------------------------')

    if(this.grid) {
      this.grid.forceUpdateGrids()//update the UI
      this.refs.inputMaster.focus()
    }
  }

  deselect() {
    this.selected = {}
    this.lastSelection = -1
    this.editingRow = -1
    //OLD
    
    this.selectEnd = -1
    this.selectStart = -1

    this.setState({
      ...this.state,
      editValue: '',
      editCommand: null,

    })

    setTimeout(x => {
      if(this.grid) {
        this.grid.forceUpdateGrids()
      }
    }, 150)
  }

  cancelEdit() {

    this.setState({
      ...this.state,
      editValue: null,
      editBackup: null,
    })
  }

  updateEdit(value) {
    console.log('new value = ' + value)
    if(this.grid) {
      this.grid.forceUpdateGrids({})
    }
    const command = parseInstrumentCommandLine( value )
    this.setState({
      ...this.state,
      editValue: value,
      editCommand: command,
      message: `EDITING ${this.editingRow} ${this.selectKey} | ${value}`,
    })
  }

  commitEdit(event) {
    console.log('commit edit called...')
    //alert(`PLACEHOLDER ${this.editingRow}:${this.selectKey} => '${this.state.editValue}'`)
    
    //MOCK LOGIC, in fact we need to edit _ALL_ the selected rows
    if(this.editingRow < 0 || this.selectKey < 0) {
      return //if we don't have a key or row abort
    }
    if( this.state.editBackup != this.state.editValue ) { //If changed
      //Is command? 
      let command = null
      if( isCommand(this.state.editCommand) ) {
        if(inError(this.state.editCommand)) {
          if(event) {
            event.preventDefault()
          }
          //alert('Error in formula ' + this.state.editCommand.error.message || '')
          return
        }

        command = this.state.editCommand
      }

      const previousInstruments = []
      const updatedInstruments = []

      const active = []
      for(let key of Object.keys(this.selected)) {
        if(this.selected[key]) {
          active.push(key)
        }
      }

      let i = 0
      for(let index of active ) {
        const ref = this.props.instruments[index] 
        previousInstruments.push( { ...ref } )        
        let update = { ...ref }

        if(command) {
          update[this.selectKey] = command.parser( i++ )
        } else {
          update[this.selectKey] = this.state.editValue
        }
        
        updatedInstruments.push( update )        
      }

      this.props.dispatch( bulkMergeInstruments(this.selectKey, previousInstruments, updatedInstruments) )

      this.props.dispatch( dispatch => {
        //console.log('void action test')
        //this.list.recomputeRowHeights(this.editingRow)
        if(this.grid) {
          this.grid.forceUpdateGrids()
        }
        return dispatch({
          type: 'VOID_REFRESH',
          note: 'InstrumentPage::commitEdit force refresh'
        })
      })

      this.deselect()
      //OPTIMISTIC UPDATE HRE
      //FIXME -> we probably want to optimistically update all these cells
      setTimeout(x => {
        if(this.grid) {
          this.grid.forceUpdateGrids()
        }
      }, 150)
      //this.grid.forceUpdateGrids()
    } 

    //We don't do anything if there isn't a change--leave the editor open
//    this.grid[this.editingRow][this.selectKey] = this.state.editValue
    
  }

  _handleDataChange(oldObjects, newObjects, handler) {
    this.log.debug(`UPDATING -> ${oldObjects}, ${newObjects}`)
    const keyMap = {}
    for(let i = 0; i < oldObjects.length; i++) {
      const keys = diff(oldObjects[i], newObjects[i])
      for(let key of keys) {
        keyMap[key] = true
      }
    }
    const key = Object.keys(keyMap)[0]
    if(key !== null && key !== undefined) {
      this.props.dispatch( bulkMergeInstruments(key, oldObjects, newObjects, handler) )
    } else {
      this.log.info('[INSTRUMENTS-UI] There were no keys found that changed.')
    }
  }

  /**
   * Handles selection and editing
   * @param {*} e ClickEvent
   * @param {*} column the column to manipulate
   * @param {*} row the row (data) to manipulate
   */
  _handleCellOnClick(e, column, row) {
    const shift = e.shiftKey
    const ctrl = e.ctrlKey

    //----INTERNAL CACHE
    // const selected = { ...this.state.selected }
    // selected[column.key] = true

    //---DEBOUNCE
    // this.setState({
    //   ...this.state,
    //   debounce: false,
    // })

    this.onSelect(row, column.key, shift, ctrl)
  }  

  /**
   * Sort the column specified by the direction specified
   * 'asc', 'dsc', 'none'
   * @param {*} key the column key
   * @param {*} direction the direction to update
   * @memberof Dev
   */
  _sortColumn(key, direction) {
    alert(`NOT IMPLEMENTED ${key}->${direction}`)
  }

  _showDialogDetails(rowIndex) {
    const T = this.props.strings
    const data = this.props.instruments[rowIndex]
    const html = jsonToTable(data || null)
    const channel = data.channel ? `(${data.channel}) ` : ''
    const position = data.position || ''
    const unit = data.unit ? `${position} #${data.unit}` : position

    const isDeleted = data.deleted
    const doDelete = () => {
      this.props.dispatch( 
        showConfirm(
          'Delete Fixture',
          'Are you sure you want to delete this fixture?',
          () => {
            this.props.dispatch(
              deleteInstruments([ {...data} ])
            )
          },
          null,
          general.get('delete')
        ) 
      )
    }

    const doRestore = () => {
      this.props.dispatch( 
        showConfirm(
          'Restore Fixture',
          'Are you sure you want to restore this fixture? Vectorworks will likely place it at the origin.',
          () => {
            this.props.dispatch(
              undeleteInstruments( [ 
                {...data}
              ] )
            )
          },
          null,
          general.get('restore')
        ) 
      )
    }

    const ok = ()=>{}
    const note = () =>{
      this.props.dispatch( showNotesDialogForInstrument(data))
    }
    const deleted = isDeleted ? doRestore : doDelete
    this.props.dispatch(
      showThreeAction(
        `${unit} ${channel} Details`, 
        'Worksheet Row ' + rowIndex + html,
        note,
        deleted,
        ok,
        notes.get('addNote'),
        isDeleted ? general.get('restore') : general.get('delete'),
        general.get('ok')
      )
    )

    // this.props.dispatch( 
    //   showConfirm(
    //     `${unit} ${channel} Details`, 
    //     'Worksheet Row ' + rowIndex + html,
    //     isDeleted ? doRestore : doDelete,
    //     ()=>{},//ok
    //     isDeleted ? general.get('restore') : general.get('delete'),//titles
    //     general.get('ok')
    //   )
    // )
  }
  /**
   * Reset the cache for the table 
   * @memberof Dev
   */
  _resetCache() {
    this.grid.recomputeGridSize()
    this.cache.clearAll()
    // this.list.recomputeRowHeights(this.editingRow)
    // console.log('::_resetCache', this.lastRenderedStart, this.lastRenderedEnd)
    // //this.list.scrollToRow (this.lastRenderedStart) //Force visibility to the first row
    // this.list.scrollToRow (this.lastRenderedStart) //Force visibility to the first row
  }

  /**
   * Update the column with the new properties at the index. This does 
   * not overwrite all properties, only those specified.
   *
   * @param {Object} column
   * @param {int} index
   * @memberof Dev
   */
  _updateColumn(update, index) {
    const columns = this.state.columns.slice()
    const col = columns[index]
    columns[index] = {
      ...col,
      ...update,
    }

    console.log('COLUMN', `UPDATING COL ${index} ${col.key} to width ${col.width}`)
    this.setState({
      ...this.state,
      columns: columns,
    })

    this.grid.recomputeGridSize({})
    this.lastUpdate = index

  }

  _submitUpdatedColumns() {
    const index = this.props.worksheet
    const worksheet = this.props.worksheets[index]
    const updatedFields = worksheet.fields.slice()
    
    //Update widths
    const fieldsLookup = {}
    for(let i = 0; i < worksheet.fields.length; i++) {
      fieldsLookup[ worksheet.fields[i].property ] = i
    }
    for(let column of this.state.columns) {
      const fieldIndex = fieldsLookup[ column.key ]
      if(fieldIndex > -1) {
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          width: column.width
        }
      }
    }

    console.log('submitting widths')
    this.props.dispatch( 
      updateWorksheet(this.props.worksheet, {
        ...worksheet, 
        fields: updatedFields,
      }) 
    )
  }

  _getColumnWidth( {index} ) {
    if(index < 1) {
      return 30
    }
    const colIndex = index - 1
    const column = this.state.columns[index - 1 ]
    if(!column) {
      return 24 //padding at the end
    } else {
      return column.width
    }
  }

  _hoverIn(rowIndex) {
    this.setState({
      ...this.state,
      hover: rowIndex,
    })
    if(this.grid) {
      this.grid.forceUpdateGrids()
    }
  }

  /**
   * Render a text editor for this column
   * @param {*} column 
   */
  _renderTextArea(column) {
    return (
      <WrappedTextArea 
        autoFocus
        ref={x => this.textEditor = x}
        style={{
          resize: 'none',
          flex: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          border: `2px solid ${window.theme.editorBorder}`,
          background: window.theme.editor,
          outline: 'none',
          color: window.theme.palette.text.primary
        }}
        onFocus={ e => {
          e.target.select()
        }}
        value={ this.state.editValue }
        // onKeyPress={(e)=>{
        //   this._handleKeyPress(e)
        // }}
        onChange={(e)=>{
          this.updateEdit( e.target.value )
        }}
        onBlur={ e => {
          setTimeout(()=>{
            var doc = document.activeElement
            var master = ReactDOM.findDOMNode(this.refs.inputMaster)
            if(doc === master) {
              console.log('IGNORING BLUR, TRANSFERRING FOCUS')
            } else {

              this.commitEdit(e)
            }
          }, 1)
        } }
        onKeyDown = { e => {
          console.log(`::textarea::keyUp ${e.keyCode} ${e.which}` )
          if ( e.keyCode === 13 || e.which === 13) {
            e.preventDefault()
            this.commitEdit()
          }

          if ( e.keyCode === 27 || e.which === 27) {
            this.deselect()
          }
        } }
      />
    )
  }

  _renderCellRowLabel( key, rowIndex, style, hover, hoverIndex, selected ) {
    const PRIMARY = window.theme.palette.primary.main
    const SECONDARY = window.theme.palette.secondary.main
    const PAPER = window.theme.brightPaper

    if(rowIndex === 0) {
      return (
        <div 
          key={key}
          style={style} 
        >
          <div 
            style={{
              width: 30,
              minWidth: 30,
              height: 30,
              minHeight: 30,
              display: 'flex',
              borderRight: `1px dotted ${window.theme.tableDashed}`,
              borderBottom: `2px solid ${window.theme.tableDashed}`,
              padding: '2px', 
            }}
            onMouseEnter={ e => this._hoverIn(-1) }
          >
          </div>
        </div>
      )
    }

    const border1px = `1px solid ${window.theme.palette.primary.main}`

    if(hover) {
      return (
        <div
          key={key}
          style={{
            ...style,
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            color: hover ? SECONDARY : PRIMARY,
            backgroundColor: PAPER,
            borderRight: selected ? `3px solid ${SECONDARY}`: `1px solid ${window.theme.tableDashed}`,
            borderBottom: selected ? `1px solid ${SECONDARY}`: border1px,
            padding: '2px', 
            fontWeight: 'bold',
            alignItems: 'center',
          }}
          onClick={e=>{
            this._showDialogDetails(rowIndex -1)
          }}
          onMouseEnter={ e => {
            this._hoverIn(hoverIndex)
          }}
        >
          <label
            className='pointer'
            style={ selected ? {
              flex: 1,
              textAlign: 'center',
              color: Colors.SECONDARY,
            } : {
              flex: 1,
              textAlign: 'center',
            } }
          >
            { rowIndex === 0 ? '' : rowIndex - 1 }
          </label>
        </div>
      )
    } else {
      return (
        <div
          key={key}
          style={{
            ...style,
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            color: hover ? SECONDARY : PRIMARY,            
            backgroundColor: PAPER,
            borderRight: selected ? `3px solid ${SECONDARY}`: `1px solid ${window.theme.tableDashed}`,
            borderBottom: selected ? `3px solid ${SECONDARY}`: `1px solid ${window.theme.tableDashed}`,            
            padding: '2px', 
            fontWeight: 'bold',
            alignItems: 'center',
          }}
          onMouseEnter={ e => {
            this._hoverIn(hoverIndex)
          }}
        >
          <label
            className='pointer'
            style={ selected ? {
              flex: 1,
              textAlign: 'center',
              color: SECONDARY,
            } : {
              flex: 1,
              textAlign: 'center',
            } }
          >
            { rowIndex === 0 ? '' : rowIndex - 1 }
          </label>
        </div>
      )
    }
  }

  _cellRenderer( { columnIndex, key, rowIndex, style} ) {
    /*
      selectKey={this.selectKey}
      editingRow={this.editingRow}
      selected={ this.selected[index] }
    */
    const dataIndex = rowIndex - 1 //headers
    const colIndex = columnIndex - 1 //row numbers
    const column = this.state.columns[ colIndex ] || {}
    const selected = this.selectKey && column.key == this.selectKey && this.selected[ dataIndex ]
    const editing = selected && this.editingRow === dataIndex
    const hover = rowIndex == this.state.hover
    const activeEditor = false
    const hoverIndex = rowIndex > 0 ? rowIndex : -1

    const borderBottom = `1px solid ${Colors.MATERIAL_PRIMARY}`
    const borderLeft = `1px solid ${Colors.TABLE_BORDER}`
    const boxShadow = `0px 2px 5px ${Colors.LA_LIGHT_BLUE_ALPHA_25}`
    const borderClear = `1px solid ${Colors.CLEAR}`
    const borderHover = `1px solid ${Colors.LA_LIGHT_BLUE}`

    let color = rowIndex % 2 === 1 ? Colors.TABLE_CLEAR : Colors.TABLE_ROW_ALT
    let _baseStyle = {
      ...style,
      display: 'flex',
      backgroundColor: color,
      borderRight: borderClear,
      borderTop: borderClear,
      borderBottom: borderBottom,
      //borderLeft: borderLeft,
      boxShadow: 'none',
      color: window.theme.palette.text.primary,
    }

    if(hover) {
      _baseStyle.borderBottom = borderHover
      //_baseStyle.borderRight = borderHover
      _baseStyle.borderTop = borderHover
      _baseStyle.boxShadow = boxShadow
    } 

    //This is the row number
    if(columnIndex === 0) {
      return this._renderCellRowLabel( key, rowIndex, style, hover || this.selected[ dataIndex ], hoverIndex, selected)
    }

    //HEADERS
    if(rowIndex === 0) {
      const col = this.state.columns[colIndex]
      return this._renderColumnHeader( col, key, colIndex, style)
    }

    //This is the row display
    const data = this.props.instruments[dataIndex]

    let bgFunc = (key) => {

      if(selected) {
        if (editing) {
          return window.theme.editor
        } else {
          return window.theme.highlight
        }
      }
      
      return color
    }

    if(editing && !column.locked && !data.deleted) {
      return (
        <div 
          key={key} 
          style={{
            ..._baseStyle,
            borderRight: `1px dotted ${window.theme.tableDashed}`,
            backgroundColor: bgFunc(column.key), 
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            display: 'flex',
          }}
          onMouseEnter={ e => {
            this._hoverIn(hoverIndex)
          }}
          // onClick= { e => { 
          //   this._handleCellOnClick(e, column, dataIndex)
          // } } 
        >
          { this._renderTextArea(column) }
        </div>
      )
    }

    if(data.deleted) {//check for deleted flag
      _baseStyle.textDecoration = 'line-through'
      _baseStyle.color = 'red'
    }

    let noteButton = null
    if(column.key == 'channel') {
      const notesArray = this.props.instrumentNotesByChannel[data.channel] || []

      if(notesArray.length > 0) {
        if(notesArray.length == 1) {
          const note = this.props.notes[ notesArray[0] ]//lookup
          if(note && note.uuid) {
            noteButton = (
              <Tooltip
                key={note.uuid}
                title={note.description}
              >
                <IconButton
                  color='secondary'
                  size='small'
                  variant='contained'
                  onClick={(e)=>{
                    e.preventDefault()
                    this.props.dispatch(
                      showNoteDialog({...note}, false)
                    )
                  }}
                >
                  <IconNote 
                    style={{
                      width: 16,
                      height: 16,
                    }}
                  />
                </IconButton>
              </Tooltip>
              )
          }
        } else {
          //Dispatch multi-select dialog
          noteButton = (
            <Tooltip
              key={data.uuid + '-notes'}
              title={'View Multiple notes'}
            >
              <IconButton
                color='secondary'
                size='small'
                variant='contained'
                onClick={(e)=>{
                  e.preventDefault()
                  this._openMultiNotesDialog(data, notesArray)
                }}
              >
                <IconNote 
                  style={{
                    width: 16,
                    height: 16,
                  }}
                >
                </IconNote>
                <sup
                  style={{
                    fontWeight: 'bold',
                    fontSize: 10,
                  }}
                >
                  {notesArray.length}
                </sup>
              </IconButton>
            </Tooltip>
          )
        }
      }
    }

    return (
      <div 
        key={key} 
        style={{
          ..._baseStyle,
          borderRight: `1px dotted ${window.theme.tableDashed}`,
          backgroundColor: bgFunc(column.key), 
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          display: 'flex',
        }}
        onMouseEnter={ e => {
          this._hoverIn(hoverIndex)
        }}
        onClick= { e => {   
          if(!column.locked && !data.deleted && !e.defaultPrevented) {
            this._handleCellOnClick(e, column, dataIndex)
          }
          //NOTE: else this is locked, do nothing...
        } } 
      >
        <label 
          style={{
            flex: 1,
            padding: '2px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: (column.locked || data.deleted) ? window.theme.palette.text.disabled : window.theme.palette.text.primary,
            fontStyle: column.locked ? 'italic' : 'inherit',
            alignSelf: 'center',
          }}
        >
          { data[ column.key ] }
        </label>
        {noteButton}
      </div>
    )
  }

  _getColumnName( column, width ) {
    return column
  }

  _renderColumnHeader( column, reactKey, index, style, ) {
      return (
        <div 
          key={reactKey}
          style={style} 
        >
          <div 
            style={{
              width: column.width,
              minWidth: column.minWidth,
              display: 'flex',
              borderRight: `1px dotted ${window.theme.tableDashed}`,
              borderBottom: `2px solid ${Colors.BLACK}`,
              padding: '2px', 
            }}
            title={ column.key }
            onMouseEnter={ e => this._hoverIn(-1) }
          >
            <label
              style={{
                paddingLeft: '5px', 
                flex: 1,
                textAlign: column.textAlign || 'left',
                overflow: 'hidden',
                //textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: column.width,
                minWidth: 20,
              }}
            >
              { Strings.get( column.key ) }
            </label>
            
            {/* OLD SORT ICON, NOT USED
            { column.width > 40 ? 
              <SortIcon 
                style = {{
                  flex: 0,
                  marginRight: '12px',
                  width: '20px',
                  height: '24px',
                  cursor: 'pointer'
                }}
                iconStyle = {{
                    width: '16px',
                    height: '24px',
                    marginTop: '-3px',
                }}
                status={ column.sort }
                onClick={e => {
                  let newSort = SortStatus.ASCENDING
                  if (column.sort == SortStatus.ASCENDING) {
                    newSort = SortStatus.DESCENDING
                  } else if (column.sort == SortStatus.DESCENDING) {
                    newSort = SortStatus.NONE
                  } 
                  this._sortColumn(column.key, newSort)
                }}
              />
              : null
            } 
            END OLD SORT ICON--NOT USED*/}
            
          </div>
          
          
          <Draggable
            axis = 'x'
            position = {{
              x: -10,
            }}
            zIndex = {999}
            onDrag={(event, { deltaX }) => {
              const activeState = this.state.columns[index]
              let width = activeState.width + deltaX
              if(width < MINIMUM_COLUMN_WIDTH) {
                width = MINIMUM_COLUMN_WIDTH
              }
              if(this.DRAGGING) {
                //console.log('DRAG', `DRAGGING ${width} ${index} ${this.DRAGGING}`)
                this._updateColumn({ width: width }, index)
              }
              
            }}
            onStart = { e => { 
              this.DRAGGING = true
            }}
            onStop={e=>{
              this.DRAGGING = false 
              this._submitUpdatedColumns()                     
              this._resetCache()
            }}
          >
            <IconDrag 
              style={{
                color: Colors.CONTROL_INACTIVE,
                width: 18,
                minWidth: 18,
                height: 18,
                minHeight: 18,
                position: 'absolute',
                top: 2,
                right: -2,
                //marginTop: '-4px',
              }}
            />
              {/* <span
                style={{
                  width: '16px',
                  height: '16px',
                  color: 'blue',
                  marginLeft: '-16px',
                }}
              >
              *
              </span> */}
          </Draggable>
        </div>
      )
  }

  _renderSettings() {
    const T = this.props.strings
    const { 
      dispatch,
    } = this.props

    const worksheets = this.props.worksheets || []
    const worksheet = this.state.settingsWorksheet || 0
    let current = worksheets[worksheet]
    let fields = []
    if(current) {
      current = mergeMissingMappings( this.props.vectorworks, current, this.state.showAllFields )
      fields = current.fields
    }
    const chunkSize = 10
    const settingGroups = chunk(fields, 10)
    const settingsCard = {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 370,
      maxWidth: 370,
      padding: '16px'
    }

    const paragraph = {
      minWidth: 256,
      maxWidth: 300
    }

    //Helpers
    const toolbarButton = (key, action) => {
      return (
        <Button
          variant='contained'
          className={'no-print'}
          color='primary'
          style={{ marginTop: '15px' }}
          onClick={e => {
            if(action){
              action(e)
            } }
          }
        >
          <ContentAdd /> { T.get(key) }
        </Button>
      )
    }

    const title = (key) => {
      return (
        <Typography variant='h6'>
          { T.get( key) }
        </Typography>
      )
    }

    const title2 = (str) => {
      return (
        <Typography variant='h6'>
          { str }
        </Typography>
      )
    }

    const subheading = (key) => {
      return (
        <Typography 
          variant='subheading' 
          color='primary' 
          style={{ 
            marginTop: '12px',
          }}
        >
          { T.get( key) }
        </Typography>
      )
    }

    return (
      <Drawer
        anchor='top'
        open={this.state.settingsOpen}
        onClose={ () => this.closeSettings() }

      >
{/* EDITORS */}
        <AddWorksheetDialog 
          isOpen={this.state.addWorksheetOpen}
          onClose={()=>{
            this.setState({
              addWorksheetOpen: false,
            })
          }}
          onCreateCallback={ (index) => {
            this.setState({
              settingsWorksheet: index, 
            })
          }}
        />

        <EditWorksheetGroupings 
          isOpen = { this.state.editGroupings}
          onClose = { ()=>{
            this.setState({
              editGroupings: false
            })
          }}
          worksheet={ current }
          worksheetIndex={ worksheet }
        />

        <EditWorksheetSort 
          isOpen = { this.state.editSorting}
          onClose = { ()=>{
            this.setState({
              editSorting: false
            })
          }}
          worksheet={ current }
          worksheetIndex={ worksheet }
        />

{/* START RENDER */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* TITLEBAR */}
          <div style={{
            flex: 0,
            display: 'flex',
            flexDirection: 'row',
            minHeight: 48,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 4,
            boxShadow: Colors.SHADOW_BLACK,
            borderBottom: '1px solid black',
            zIndex: 99,
            backgroundColor: window.theme.la_blue,
            color: window.theme.palette.common.white,
          }}>
            <div 
              style={{
                flex: 1, 
                display: 'flex', 
                alignItems: 'center', 
              }}
            >
              <h2 style={{ margin: 0,}}> { Strings.get('settings') } </h2>
            </div>
            <div style={{flex: 0, paddingTop: 6,}}>
              <Button
                style={{
                  color: window.theme.palette.common.white,
                }}
                onClick={e => {
                  this.closeSettings()
                }}
              >
                <IconClose /> { general.get('close') }
              </Button>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              overflowY: 'auto',
              overflowX: 'auto',
            }}
          >
            <Paper style={ settingsCard }>
              
              <Container
                override={{
                  alignItems: 'flex-start',
                }}
              >
                { title('worksheetSettings') }
                <Spacer />
                <Tooltip title={Strings.get('createWorksheetTitle')}>
                  <Button 
                    variant='fab'
                    color='secondary'
                    onClick={e => {
                      this.setState({
                        addWorksheetOpen: true,
                      })
                    }}
                    style={{
                      width: 36,
                      height: 36,
                    }}
                  >
                    <IconAdd />
                  </Button>
                </Tooltip>
              </Container>
              
              <Container>
                <TextField
                  select
                  style={{
                    flex: 1,
                    minWidth: 200,
                    width: 200,
                  }}
                  label={ Strings.get('selectedWorksheet') }
                  value={worksheet}
                  onChange={e => {
                    this.setState( {
                      settingsWorksheet: e.target.value
                    } )
                  } }
                  helperText={ Strings.get('helpEditWorksheet') }
                >
                  { 
                    worksheets.map( 
                      (w, i) => <MenuItem key={i} value={i}>{w.name}</MenuItem>
                    )
                  }

                </TextField>
                 <Button
                  color='secondary'
                  onClick={e => {
                    const onDelete = () => {
                      dispatch( removeWorksheet(current.name) )
                    }
                    dispatch( 
                      showConfirm(
                        Strings.get('removeWorksheetTitle', current),
                        Strings.get('removeWorksheetDetail', current),
                        onDelete,
                        null,
                        Strings.get('removeWorksheet')
                      ) 
                    )
                  }}
                >
                  { general.get('remove') }
                </Button>
              </Container>
              
              <div>
                { current ? 
                  <React.Fragment>
                    <div>
                      <Container>
                        <Tooltip title={Strings.get('editGroupingsHelp')}>
                          <Button 
                            color='primary'
                            onClick={e => {
                              this.setState({
                                editGroupings: true,
                              })
                            }}
                            style={{
                              marginLeft: -16
                            }}
                          >
                            { Strings.get('groupedBy') } <IconEdit /> 
                          </Button>
                        </Tooltip>
                      </Container>
                      
                      <Container>
                        { current.grouping.map( (s, i) => (
                          <Chip 
                            key={s.key}
                            color='primary'
                            avatar={<Avatar>{i + 1}</Avatar>}
                            label={s.key}
                          />
                          ) 
                        ) }
                      </Container>

                      <Container>
                        <Tooltip title={Strings.get('editGroupingsHelp')}>
                          <Button 
                            color='secondary'
                            onClick={e => {
                              this.setState({
                                editSorting: true,
                              })
                            }}
                            style={{
                              marginLeft: -16
                            }}
                          >
                            { Strings.get('sortedBy') } <IconEdit /> 
                          </Button>
                        </Tooltip>
                      </Container>

                      <Container>
                        { current.sort.map( (s, i) => (
                          <Chip 
                            key={s.key}
                            color='secondary'
                            avatar={<Avatar>{i + 1}</Avatar>}
                            label={s.key}
                          />
                          ) 
                        ) }
                      </Container>
                      
                    </div>
                    <div>

                    </div>
                    
                  </React.Fragment>
                  : null
                }
                
              </div>

              <Spacer />

              { subheading('otherActions')}
              <div
                style={{ 
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Spacer />
                <FormGroup 
                  style={{
                    width: 150,
                    minWidth: 150,
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={ this.state.showAllFields }
                        color='secondary'
                        onChange={ (e) => {
                          this.setState({
                            showAllFields: !this.state.showAllFields
                          })
                        }}
                      />
                    }
                    label={ Strings.get('showAllFields') }
                  />
                </FormGroup>
                <Button
                  color='secondary'
                  onClick={e => {
                    const onRestore = () => {
                      dispatch( createDefaultWorksheets() )
                    }
                    const onMerge = () => {
                      dispatch( createDefaultWorksheets(true) )
                    }
                    dispatch( 
                      showThreeAction(
                        Strings.get('restoreWorksheetsTitle'),
                        Strings.get('restoreWorksheetsDetail'),
                        onRestore,
                        onMerge,
                        null,
                        Strings.get('restoreWorksheets'),
                        Strings.get('restoreWorksheetsMerge')
                      )
                    )
                  }}
                >
                  { general.get('restoreDefaults') }
                </Button>
              </div>
              <p >
                { Strings.get('showAllFieldsHelp')}
              </p>
            </Paper>

            <div style={ settingsCard }>            
              { title2( current ? current.name : '---' ) }
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                { settingGroups.map( (group, groupIndex ) => {
                  return (
                    <div 
                      key={'settings' + groupIndex}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 250,
                      }}
                    >
                      { group.map( (item, index) => {
                        const realIndex = index + groupIndex * chunkSize
                        return (
                          <WorksheetField 
                            field={ item }
                            key={item.property}
                            index={realIndex}
                            onChange={ e => {
                              const worksheetCopy = toggleProperty( item.property, !item.hidden, current )
                              if(worksheetCopy !== current) {
                                dispatch( updateWorksheet(worksheet, worksheetCopy) )
                              }
                            } }
                            onMoveUp={ e => {
                              const worksheetCopy = moveProperty(realIndex, true, current)
                              if(worksheetCopy !== current) {
                                dispatch( updateWorksheet(worksheet, worksheetCopy) )
                              }
                            } }
                            onMoveDown={ e => {
                              const worksheetCopy = moveProperty(realIndex, false, current)
                              if(worksheetCopy !== current) {
                                dispatch( updateWorksheet(worksheet, worksheetCopy) )
                              }
                            } }
                          />
                        )
                      } ) }
                    </div>
                  ) } )
                } 

              </div>
            </div>
            
          </div>

        </div>
      </Drawer>
    )
  }

  _renderControls() {
    const dispatch = this.props.dispatch
    const worksheets = this.props.worksheets || []
    let worksheet = this.props.worksheet
    if(worksheet === null || worksheet === undefined) {
      worksheet = -1
    }
    const current = worksheets[worksheet] || { grouping: [], sort:[]}
    const sortText = Strings.get('sortedBy') + current.grouping.map(x => ', ' + x.key) + current.sort.map(x => ', ' + x.key)

    let inputMasterStyle = {
      flex: 0,
      minWidth: 250,
      height: 30,
      marginTop: 18,
    }

    let hasCommandLineError = false
    if(this.state.editCommand && isCommand(this.state.editCommand) ) {
      inputMasterStyle.background = 'rgba(255,0,0,0.25)'
      inputMasterStyle.borderColor = 'rgba(255,0,0, 1.0)'
      inputMasterStyle.border = '1px solid red'

      hasCommandLineError = inError(this.state.editCommand)
    }

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            minWidth: 32,
          }}
        >
          {/* spacer */}
        </div>
        <TextField
          select
          label={Strings.get('selectedWorksheet')}
          helperText={sortText}
          value={worksheet}
          onChange={ e => {
            const _new = e.target.value
            this.deselect()
            if(_new != worksheet) {
              dispatch( selectWorksheet( _new ) )   
            }
          } }
          InputLabelProps={{
            shrink: true,
          }}
          style={{
            minWidth: 200,
            width: 200,
          }}
        >
          { worksheets.map( (w, i) => { 
            return (
              <MenuItem key={i} value={i}>
                <b>{ w.name }</b>
              </MenuItem>
            )
          } ) }
        </TextField>

        <div
          style={{ 
            display: 'flex',
            flexDirection: 'column',
            marginLeft: 12,
          }}
        >
          <input
            style={ inputMasterStyle }
            type='text' 
            ref='inputMaster'
            key={ 'master' + this.editingRow }
            disabled={this.editingRow < 0}
            value={this.state.editValue}
            onChange={(e)=>{
              this.updateEdit( e.target.value )
            }}
            onFocus={ e => {
              e.target.select()
            }}
            //-----We probably don't need a blur event up top.
            // onBlur={ e => {
            //   setTimeout(()=>{
            //     const doc = document.activeElement
            //     const editor = ReactDOM.findDOMNode(this.textEditor)
            //     if(doc === editor) {
            //       console.log('IGNORING BLUR, TRANSFERRING FOCUS TO EDITOR')
            //     } else {
            //       this.commitEdit()
            //     }
            //   }, 1)
            // } }
            onKeyDown = { e => {
              console.log(`::master::keyUp ${e.keyCode} ${e.which}` )
              if ( e.keyCode === 13 || e.which === 13) {
                e.preventDefault()
                this.commitEdit()
              }
    
              if ( e.keyCode === 27 || e.which === 27) {
                this.deselect()
              }
            } }
          />

          { hasCommandLineError ? 
            <div
              style={{
                position: 'absolute',
                width: 320,
                minHeight: 48,
                marginTop: 54,
                marginLeft: 16,
                padding: 8,
                zIndex: 999,
                border: '1px solid rgba(128,0,0,1)',
                borderRadius: '5px',
                background: 'rgba(64,0,0,0.8)',
                color: Colors.WHITE,
                WebkitTransition: 'opacity 1s ease-in-out'
              }}
            >
              <span style={{display: 'block', fontWeight: 'bold'}}>{ Strings.get('badCommand') }</span>
              <span style={{display: 'block', fontFamily: 'monospaced'}}>
                { this.state.editCommand.error.message || 'Unknown Error' }
              </span>
            </div>

          : null}

          <label
            style={{
              flex: 0,
              minWidth: 120,
              fontSize: '0.75rem',
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              color: Colors.DISABLED,
            }}
          >
            { this.editingRow < 0 ? 
                this.props.strings.get('not editing') 
              :
                this.props.strings.get('editingRow', {
                  key: this.selectKey,
                  start: this.selectStart < 0 ? '' : this.selectStart,
                  end: this.selectEnd,
                  original: this.state.back
                })
            }
          </label>
        </div>
        
        
        <Tooltip title={general.get('commitEdits')}>
          <WrappedIconButton
            ref={x => this.refs.inputCommit}
            disabled={this.editingRow < 0}
            style={{
              color: this.editingRow < 0 ? Colors.DISABLED : Colors.GREEN,
              marginTop: 8,
            }}
            onClick={ e => {
              this.commitEdit() 
            } }
          >
            <IconCheck />
          </WrappedIconButton>
        </Tooltip>

        <Tooltip title={general.get('cancelEdits')}>
          <WrappedIconButton
            ref={x => this.refs.inputCancel}
            disabled={this.editingRow < 0}
            color='secondary'
            style={{
              marginTop: 8,
            }}
            onClick={e => {
              this.cancelEdit()
              this.deselect()
            } }
          >
            <IconCancel />
          </WrappedIconButton>
        </Tooltip>

        <Spacer />
        
        <SyncModule />
        <UtilityMenu />
        <Button
          color='primary'
          onClick={e => this.openSettings() }
        >
          <IconSettings /> { general.get('settings') } 
        </Button>
      </div>
    )
  }
  _openMultiNotesDialog(instrument, notes) {
    this.setState({
      _notesDialogOpen: true,
      _notesDialogInstrument: {...instrument},
      _notesDialogNotes: notes.slice(),
    })
  }

  _renderNotesDialog() {
    // _notesDialogOpen: false,
    // _notesDialogInstrument: {},
    // _notesDialogNotes: [],
    const onClose = ()=>{
      this.setState({
        _notesDialogOpen: false,
        _notesDialogInstrument: {},
        _notesDialogNotes: [],
      })
    }

    return (
      <InstrumentNotesDialog 
        onClose={onClose}
        open={this.state._notesDialogOpen}
        instrument={this.state._notesDialogInstrument}
        notes={this.state._notesDialogNotes}
      />
    )
  }

  render() {
    const T = this.props.strings    
    const { dispatch, show, sort, settings, mappings, worksheets, worksheet } = this.props

    const instruments = this.props.instruments || []

    const { columns } = this.state

    const columnPadding = 50 //30 left + 20 scrollbar right
    const columnWidth = columns.reduce((a, c) => c.width + a, 50) //pad by 30 for the first column

    if(this.state.__firstLoad) {
      dispatch( {type: 'SAGA_REFRESH_WORKSHEETS', onComplete: ()=>{
        this.setState({__delayLoad: false})
      }} )
      this.setState({
        __firstLoad: false,
      })
    }

    let totalWidth = 0

    for(let i = 0; i < columns.length; i++) {
      totalWidth += columns[i].width || 20 //20 is the min
    }
    const renderNoData = () => {
      return (
        <div
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
        }}
        >
          <h3>No instrument data</h3>
        </div>
      )
    }

    const renderTableOrHelp = () => {

      if(instruments.length < 1) {
        return renderNoData()
      }

      return (
      <div
        style={{
          width: '100%',
          height: '100%',
          flex: 1,
        }}
      >
        <AutoSizer>
          { ( { width, height } ) => (
            <MultiGrid 
              ref={r => this.grid = r}
              cellRenderer={ this._cellRenderer }
              fixedColumnCount={1}
              fixedRowCount={1}
              height={height}
              columnWidth={ this._getColumnWidth }
              columnCount={ columns.length + 1 }
              rowHeight={ 30 }
              rowCount={ instruments.length + 1 }
              width={width}
              //enableFixedColumnScroll
              //enableFixedRowScroll
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar

            />
          ) }
        </AutoSizer>
      </div>
      )
    }

    const outsideBorder = `1px solid ${Colors.TABLE_BORDER}`
    return (
      <Standard undo={UNDO_KEY}>
        { this._renderSettings() }
        {/*DEBUG INFORMATION */}
{/* 
        <div 
          style={{
            display: 'flex',
            alignContent: 'space-between',
          }}
        >
          <DebugInfo label='instruments' json={this.props.instruments } />
          <DebugInfo label='mappings' json={this.props.mappings } />
          <DebugInfo label='history' json={this.props.vectorworks.history } />
          <DebugInfo label='sync' json={this.props.vectorworks.changeMap } />
        </div>
        <Button
          onClick={e => {
            alert('Electron Connected? ' + ElectronMethods.isConnected())
            alert(ElectronMethods.heartbeat())
            ElectronMethods.heartbeatAsync()
          } }
        >        
          PING ELECTRON
        </Button> 
        <h1>Development Module | Virtualized Instrument List {this.state.columns[0].width} : {this.state.columns[1].width} {this.lastUpdate} {this.state.hover}</h1>        
        <h4 style={{color: 'red'}}>{this.state.message}</h4>        
*/}
        {/* END DEBUG */}
        {/* <h2>LABS: Virtualized Instrument Module</h2> */}
        <div 
          style={{
            marginTop: 12,
          }}
        >
          { this._renderControls() }
        </div>
        
        <div 
          style={{
            width: '100%',
            userSelect: 'none',
            borderTop: outsideBorder,
            borderRight: outsideBorder,
            //paddingLeft: '24px',
            //paddingRight: '24px',
            flex: 1,
            //overflowX: 'scroll',
          }}
        >
          { this.state.__delayLoad ? 
            <LoadingCentered label={T.get('loading')}/>
          :
            renderTableOrHelp()
          }            
        </div> 

        { this._renderNotesDialog() }
      </Standard>      
    )
  }
}

export default connect(s =>{
  const includeDeleted = true
  let toFilter  = s.instruments.instruments || []

  if(!includeDeleted) {
    toFilter = toFilter.filter( x => !x.deleted )
  }

  const instrumentNotesByChannel = s.notes.instrumentNotesByChannel || {}
  const notes = s.notes.notes || {}
  const mapped = {
    language: s.language,
    instruments: toFilter,
    mappings: s.instruments.mappings,
    fileUrl: s.instruments.sync.fileUrl,
    sort: s.instruments.sort,
    show: s.show,
    settings: s.settings || {},
    vectorworks: s.vectorworks,
    worksheets: s.worksheets.worksheets,
    worksheet: s.worksheets.worksheet,
    syncMappings: s.vectorworks.syncMappings || {},
    instrumentNotesByChannel: instrumentNotesByChannel.instruments || {},
    notes: notes,
  }
  // console.log('state------------------------');
  // console.log(s);
  // console.log('-----------------------------');
  
  // console.log('mapped-----------------------');
  // console.log(mapped);
  // console.log('-----------------------------');
  return mapped
}) (translate('Instruments')(InstrumentPage))