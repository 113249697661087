export const ROUTE_CHANGED = 'ROUTE_CHANGED'

const INITIAL_STATE = {
  pathname: '/'
}

export const goTo = (pathname) => {
  return dispatch => {
    window.location = pathname
  }
  
  // return dispatch => dispatch({
  //   type: ROUTE_CHANGED,
  //   pathname: pathname,
  // })
}
export default (state = INITIAL_STATE, action)=>{
  switch(action.type){
    case ROUTE_CHANGED: {
      if(action.onComplete){
        setTimeout(action.onComplete, 10)
      }
      return {
        ...state,
        pathname: action.pathname
      }
    }
    default: 
      return state
  }
};