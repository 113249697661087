import React from 'react'
import { connect } from 'react-redux'
import NavBar from './NavBar'
import Footer from './../Footer/Footer'
import Undo from './../../src/history/Undo'

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
} from '@material-ui/core'

import {
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@material-ui/icons'
import UiDialogs from './UiDialogs'

import {
  saveState,
  newShow,
} from './../../src/reducers/ShowLogic'

import {
  reportError
} from './../../src/reducers/ErrorLogic'

import ErrorPage from './ErrorPage'
import NoteDialog from './../../src/notes/NoteDialog'

import { closeDialog, changePrivacy, showConfirm } from '../../src/reducers/InterfaceLogic'
import { redirect } from '../../src/Utility'

import {
  downloadError,
  mailError,
  tryReportError
} from './../../shared/ErrorReporter'

import {
  takeScreenShot
} from './../../src/reducers/ElectronLogic'

class Standard extends React.Component {

  constructor(props) {
    super(props) 

    this.state = {
      error: false,
      info: {},
    }
  }

  handleDialogClose(dispatch, action) {
    dispatch(closeDialog())
    if(action){
      dispatch( action )
    }
  }

  componentDidCatch(error, info) {

    // this.setState({
    //   error: error,
    //   info: info,
    // })
  }

  static getDerivedStateFromError(error) {
    if(error && window.IPC) {
      //Timeout for page capture
      setTimeout(()=>{ 
        console.log('break')
      }, 1500)
      let ss = takeScreenShot()
      window.ERROR_SCREEN_SHOT = ss.screenShot
      console.log('SCREEN SHOT')
      console.log(ss)
    }

    window.REDUX_STORE.dispatch(
      reportError(error, 'unknown') 
    )
    return {
      error: error,
      info: 'Unknown'
    }
  }

  reportError() {
    this.props.dispatch( 
      mailError(this.state.error, this.state.info)
    )
  }

  downloadError() {
    this.props.dispatch( 
      downloadError( this.state.error, this.state.info ) 
    )
  }

  reportErrorOld() {
    const error = this.state.error
    const info = this.state.info

    //OLD METHODS
    if (error) {
      let stack = error.stack || 'No stack trace'
      let mailer = document.createElement('a')
      const subject = 'Lightassistant | StackTrace'
      const body = 
          '\r\nUser --------------\r\n' 
          + 'Please delete this information if you do not wish me to know it. This is simply so I can respond when it is fixed.\r\n'
          + 'The system is currently in a beta test and does not store information server-side.\r\n'
          + this.props.user.name + ' ' + this.props.user.email
          + '\r\nError --------------\r\n' 
          + error
          + '\r\nStack --------------\r\n'
          + stack
          + '\r\nMessage-------------\r\n' 
          + info.componentStack || ''
          + '\r\n'
          + JSON.stringify(info, null, 2)
          + '\r\nStacktrace-----------\r\n' 
          + stack
      const msg = 'mailto:chapman@danielbchapman.com?subject=' + encodeURI(subject)+ '&body=' + encodeURI(body)
      mailer.href = msg
      mailer.click()
      alert( 'Thank you for reporting the error.' )
    } 
  }

  render() {

    const { user, ui, dispatch, current, undo } = this.props
    const actions = []

    if(ui.buttonCancel){
      actions.push(
        <Button
          key='cancel'
          onClick={ () => {
            this.handleDialogClose(dispatch, ui.onCancel)
          } }
        >
         { ui.buttonCancel }
        </Button>
      )
    }

    if(ui.buttonOption) {
      actions.push(
        <Button
          key='cancel'
          color='secondary'
          onClick={ () => {
            this.handleDialogClose(dispatch, ui.onOption)
          } }
        >
         { ui.buttonOption || 'Option' }
        </Button>
      )
    }
    if(ui.buttonOk){
      actions.push(
        <Button
          key='ok'
          color='secondary'
          onClick={ () => {
            this.handleDialogClose(dispatch, ui.onConfirm)
          } }
        >
         { ui.buttonOk || 'OK' }
        </Button>
      )
    }
    let style = { ...this.props.style }

    if(this.state.error || this.props.errors.error) {
      style.overflowY = 'auto'
      style.overflowX = 'auto'
      style.overflow = 'auto'
    }
    const theme = window.theme || {}
    const palette = theme.palette || {}
    const themeWhite = palette.type === 'light' ? palette.common.white : palette.text.primary

    const dynamicStyles = 
`
    body {
      color: ${palette.text.primary};
    }

    footer {
      color: ${themeWhite}
    }

    tt {
      white-space: pre
    }

    code {
      white-space: pre
    }

    .warning {
      color: ${palette.error.main}
    }

    textarea::selection {
      background: ${theme.highlight};
    }    
    .row-hover::hover {
    }

    .dragHover {
      border: 1px dashed ${theme.dragHoverColor} !important;
      border-bottom: 4px solid ${theme.dragHoverColor} !important;
    }

    .parens::before {
      content: "("
    }

    .parens::after {
      content: ")"
    }

    .empty {
      font-style: italic;
      color: ${palette.text.disabled};
    }

    .cue-row.hover {
      //background: #bbd9fd;
      border: 2px solid ${theme.hover};
    }

    .cue-row.selected {
      background: initial;
      box-shadow: 2px 2px 5px ${theme.la_blue};
      border: 2px solid ${theme.la_blue_half};
    }

    .cue-row {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      font-family: Roboto, sans serif;
      font-size: 16px;
      line-height : 48px;
      vertical-align: middle;
      border: 2px solid rgba(255,255,255,0);
      border-bottom: 2px solid ${theme.seperator};
      background: ${palette.background.paper};
    }

    .cue-row.alt {
      background: ${theme.paperAlt};
    }
    
    .cue-row label {
      cursor: pointer;
    }

    .cue-row .scene-top {
      border: 1px solid #eee;
      font-weight: bold;
      background: ${theme.la_blue};
      color: ${themeWhite};
      padding-left: 20px;
      box-shadow: ${theme.boxShadow};
    }

    .cue-row .scene-top-editing {
      border: 1px solid ${theme.seperator};
      font-weight: bold;
      padding-left: 20px;
    }

    .note {    
      background: ${theme.brightPaper};
      margin: 3px 0 0 0;
      padding: 0;
      border-radius: 10px;
      border: 1px solid ${theme.la_blue_half};
      box-shadow: 3px 3px 3px ${theme.la_blue_half};
    }

    .pointer {
      cursor: pointer;
    }

    @keyframes greenFlash {
      0%{
        background-color: rgba(0, 128, 0, 0.25)
      }
      100%{
        background-color: rgba(0, 128, 0, 1)
      }
    }

    @keyframes oscStatusBlink {
      0%{
        background-color: rgba(0, 128, 0, 0.25)
      }
      100%{
        background-color: rgba(0, 128, 0, 0.75)
      }
    }

    @keyframes gotoHighlight {
      0%{
        background: url(img/highlight-arrow-dark.png) no-repeat;
        background-size: contain;
        background-size: 96px;
        background-position: left;
        background-color: rgba(0, 128, 0, 0.25)
      }
      100%{
        background: url(img/highlight-arrow.png) no-repeat;
        background-size: contain;
        background-size: 96px;
        background-position: left;
        background-color: rgba(0, 128, 0, 1)
      }
    }
    
    .overlay-highlight {
      animation: gotoHighlight 1s infinite;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
    }

    .osc-status-text:not(:empty) {
      opacity: 1;
      transition:opacity 1s;
    }

    .osc-status-text:empty {
      opacity: 0;
      transition:opacity 1s;
    }

    .oscFlash {
      animation: oscStatusBlink 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }

    .flash {
      animation: greenFlash 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 3;
    }
    
    .cue-row.osc-active {
      background: url(img/highlight-arrow.png) no-repeat;
      background-size: contain;
      background-size: 96px;
      background-position: left;
    }

    button.footer {
      background: rgba(0,0,0,0);
      border: none;
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: ${themeWhite}
    }

    button.disabled { 
      color: ${palette.text.disabled}
    }

    button.footer:hover {
      background: rgba(255,255,255,0.3);
      border: none;
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    button.disabled:hover {
      background: rgba(0,0,0,0);
      border: none; 
      vertical-align: middle;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: default;
    }

    em {
      font-family: monospaced;
      font-weight: bold;
      font-style: normal;
      font-size: larger;
    }
`
    let getErrorMessage = () => {
      let stack = this.state.error.stack || 'No stack trace'
      const body = 
          '\r\nUser --------------\r\n' 
          + 'Please delete this information if you do not wish me to know it. This is simply so I can respond when it is fixed.\r\n'
          + 'The system is currently in a beta test and does not store information server-side.\r\n'
          + this.props.user.name + ' ' + this.props.user.email
          + '\r\nError --------------\r\n' 
          + this.state.error
          + '\r\nStack --------------\r\n'
          + stack
          + '\r\nMessage-------------\r\n' 
          + this.state.info.componentStack || ''
          + '\r\n'
          + JSON.stringify(this.state.info, null, 2)
          + '\r\nStacktrace-----------\r\n' 
          + stack

      return body
    }
      
    if(this.props.errors.error) {
      return (
        <div className='la-main'>
          <style dangerouslySetInnerHTML={{ __html: dynamicStyles }} />
          <NavBar />
          <div className='la-alignment' style={style}>

          <ErrorPage />
          
          </div>
          <Footer 
            undo={undo}
          />
        </div>
      )
      
    }
    if(this.state.error) {
      return (
        <div className='la-main'>
          <style dangerouslySetInnerHTML={{ __html: dynamicStyles }} />
          <NavBar />
          <div className='la-alignment' style={style}>

            <Paper
              style={{
                minWidth: 384,
                maxWidth: 800,
                padding: 20,
                margin: '0 auto',
              }}
            >

              <h1>Unexpected Application Error</h1>
              <p>
                Whoops! Looks like something went wrong. Don't panic! Light Assistant stores all data in a recoverable state that
                can be refreshed. If the application crashed it is very unlikely that something corrupted the state.
              </p>

              <p>
                If something did manage to corrupt the state it is very likely the file can be easily recovered. 
                Saving a backup and then loading the file. If the applciation is unrecoverable please save a backup 
                and then use the "New Show" option to clear your data. 
              </p>

              <LinearProgress color='primary' />
              <Button
                color='primary'
                variant='outlined'
                onClick={e => {
                  window.location.reload(true) //force a reload
                }}
              >
                Force Reload
              </Button>
            </Paper>
          </div>

        <Footer 
          undo={undo}
        />

        </div>
      )
    }
    return (
      <div className='la-main'>
        <style dangerouslySetInnerHTML={{ __html: dynamicStyles }} />
        <NavBar />
        <div className='la-alignment' style={style}>
          { this.props.children }
        </div>
        <Footer 
          undo={this.props.undo} 
        />
        <UiDialogs />
        <Dialog
          open={ ui.show }
          onRequestClose = { _ => {
            this.handleDialogClose(dispatch, ui.onCancel)
          } }
        >
          <DialogTitle>
            {ui.title} 
          </DialogTitle>

          <DialogContent>
            <div dangerouslySetInnerHTML={{ __html: ui.html }}></div>  
          </DialogContent>
          
          <DialogActions>
            { actions }
          </DialogActions>
        </Dialog>

        {/* Notes Dialog or Drawer (Preference) */}
        <NoteDialog />
        
      </div>
    )
  }
}
export default connect(s => {
  return {
    user: s.user,
    ui: s.ui,
    language : s.language.current,
    privacy : s.ui.privacy,
    errors: s.errors || {},
  }
}) (Standard)