//Do some platform checks or a copy or something...
import React from 'react'
import Apple from './Apple'
//We are doing apple right now
const setup = () => {
  //return <Apple />
  console.log('Loading platform specific in app purchases')
  const Apple = require('./Apple').default
  return <Apple />
}
export default setup