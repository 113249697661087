var xml2js = require('xml2js')
import moment from 'moment'
import Logger from './../WebLog'
import { joinStrings, mergeObjectsIgnoreEmpty } from '../Utility'
import { isEmptyOrWhitespace } from './../../shared/StringUtil'

export const ACCESSORIES_KEY_VW = 'Accessories'
export const ACCESSORIES_KEY_LA = 'accessories'
export const APP_STAMP = 'Lightwright'
export const UNIQUE_ID = 'Lightwright_ID'
/**
 * The static mappings overrive any custom mapping as they are the "Required"
 * parts of the data exchange. Basically anything else is fair game.
 */
export const STATIC_MAPPINGS = {
   //Required, can not be changed
  //  vwlayer: {
  //   name: 'Layer',
  //   key: 'Layer',
  //   required: true,
  //  },
  //  vwClass: {
  //   name: 'Class',
  //   key: 'Class',
  //   required: true,
  //  },
  uid: {
    name: 'UID',
    key: 'UID',
    disabled: true,
    required: true,
  },
  action: {
    name: 'Action',
    key: 'Action',
    disabled: true,
  },
  time: {
    name: 'Time',
    key: 'Time',
    disabled: true,
  },
  timeStamp: {
    name: 'TimeStamp',
    key: 'TimeStamp',
    disabled: true,
  },
  appStamp: {
    name: 'AppStamp',
    key: 'AppStamp',
    disabled: true,
  },
  _id: {
    name: 'Lightwright ID',
    key: 'Lightwright_ID',
    disabled: true,
  },
  accessories: {
    name: 'Accessories',
    key: 'Accessories',
    disabled: true,
    required: true,
  },
  instrumentType: {
    name: 'Instrument Type',
    key: 'Inst_Type',
    required: true,
  },
  deviceType: {
    name: 'Device Type',
    key: 'Device_Type',
    disabled: true,
    required: true,
  }, 
  symbolName: {
    name: 'symbolName',
    key: 'Symbol_Name',
    required: true,
  },
  absoluteAddress: {
    "name": "Absolute Address",
    "key": "Absolute_Address",
    //required: true,
  },
}

/** Ignored by user  */
const _static_keys = Object.keys(STATIC_MAPPINGS)
export const USER_REQUIRED = _static_keys
export const USER_IGNORES = [
  'action',
  'time',
  'mark',
  'timeStamp',
  '_id',
  'accessories',
  'instrumentType',
  'deviceType',
  'symbolName',
  'appStamp',
]

export const USER_WORKSHEET_IGNORES = [
  'action',
  'time',
  'mark',
  'timeStamp',
  'accessories',
  'appStamp',
]

let _userDisabled = []
for(let key of USER_REQUIRED) {
  _userDisabled.push(key)
}

for(let key of USER_IGNORES) {
  _userDisabled.push(key)
}

/**
 * A list of fields the user can not add or delete
 */
export const USER_CAN_NOT_REMOVE = _userDisabled

//Processed by the playground/generateVWMap.js (automated)
export const PROCESSED_MAPPINGS = {
//"key" <-- added 2022
  address: {
    "name": "DISABLE_DMX Address",
    "key": "DISABLE_DMX_Address",
    disabled: true,
  },
  angleToFace: {
    "name": "Angle To Face Plane",
    "key": "Angle_To_Face_Plane"
  },
  beamAngle: {
    "name": "Beam Angle",
    "key": "Beam_Angle"
  },
  beamAngle2: {
    "name": "Beam Angle 2",
    "key": "Beam_Angle_2"
  },
  "breakerID": {
    "name": "Breaker ID",
    "key": "Breaker_ID"
  },
  channel: {
    "name": "Channel",
    "key": "Channel",
    required: true,
  },
  circuitName: {
    "name": "Circuit Name",
    "key": "Circuit_Name"
  },
  circuitNumber: {
    "name": "Circuit Number",
    "key": "Circuit_Number"
  },
  color: {
    "name": "Color",
    "key": "Color"
  },
  cost: {
    "name": "Cost",
    "key": "Cost"
  },
  dimmer: {
    "name": "Dimmer",
    "key": "Dimmer"
  },
  enableZRot: {
    "name": "Custom plan rotation",
    "key": "EnableZRot"
  },
  fieldAngle: {
    "name": "Field Angle",
    "key": "Field_Angle"
  },
  fieldAngle2: {
    "name": "Field Angle 2",
    "key": "Field_Angle_2"
  },
  fixtureMode: {
    "name": "Fixture Mode",
    "key": "Fixture_Mode"
  },
  "flip3DLeftRight": {
    "name": "Flip left and right 3D legend",
    "key": "Flip_3D_Left_Right"
  },
  "flip3DTopBottom": {
    "name": "Flip top and bottom 3D legend",
    "key": "Flip_3D_Top_Bottom"
  },
  "flipFrontBack": {
    "name": "Flip front and back 2D legend",
    "key": "Flip_Front_Back"
  },
  "flipLeftRight": {
    "name": "Flip left and right 2D legend",
    "key": "Flip_Left_Right"
  },
  focus: {
    "name": "Focus",
    "key": "Focus"
  },
  "focusAngleHorizontal": {
    "name": "Horizontal Focus Angle",
    "key": "Focus_Angle_Horizontal"
  },
  "focusAngleVertical": {
    "name": "Vertical Focus Angle",
    "key": "Focus_Angle_Vertical"
  },
  frameSize: {
    "name": "Frame Size",
    "key": "Frame_Size"
  },
  "gdtfFixtureMode": {
    "name": "GDTF Fixture Mode",
    "key": "GDTF_Fixture_Mode"
  },
  "gdtfFixtureName": {
    "name": "GDTF Fixture",
    "key": "GDTF_Fixture_Name"
  },
  lampRotationAngle: {
    "name": "Lamp Rotation Angle",
    "key": "Lamp_Rotation_Angle"
  },
  "legendView3D": {
    "name": "3D Legend View",
    "key": "Legend_View_3D"
  },
  mark: {
    "name": "Mark",
    "key": "Mark"
  },
  "numChannels": {
    "name": "DMX Footprint",
    "key": "Num_Channels"
  },
  offAxisAngle: {
    "name": "Off Axis Angle",
    "key": "Off_Axis_Angle"
  },
  "pan": {
    "name": "Pan",
    "key": "Pan"
  },
  position: {
    "name": "Position",
    "key": "Position",
    required: true,
  },
  purpose: {
    "name": "Purpose",
    "key": "Purpose"
  },
  system: {
    "name": "System",
    "key": "System"
  },
  "template": {
    "name": "Gobo 1",
    "key": "Template"
  },
  "template2": {
    "name": "Gobo 2",
    "key": "Template2"
  },
  "templateRot1": {
    "name": "Gobo 1 Rotation",
    "key": "TemplateRot1"
  },
  "templateRot2": {
    "name": "Gobo 2 Rotation",
    "key": "TemplateRot2"
  },
  throwDistance: {
    "name": "Throw Distance",
    "key": "Throw_Distance"
  },
  "tilt": {
    "name": "Tilt",
    "key": "Tilt"
  },
  "time": {
    "name": "Time",
    "key": "Time"
  },
  unitNumber: {
    "name": "Unit Number",
    "key": "Unit_Number"
  },
  universe: {
    "name": "DISABLE_Universe",
    "key": "DISABLE_Universe",
    disabled: true,
  },
  "universeAddress": {
    "name": "Universe/Address",
    "key": "UniverseAddress",
    disabled: true,
  },
  "useGDTFGeometry": {
    "name": "Use GDTF Geometry",
    "key": "UseGDTFGeometry"
  },
  useVerticalBeam: {
    "name": "Use Vertical Beam",
    "key": "Use_Vertical_Beam"
  },
  userField1: {
    "name": "User Field 1",
    "key": "User_Field_1"
  },
  userField2: {
    "name": "User Field 2",
    "key": "User_Field_2"
  },
  userField3: {
    "name": "User Field 3",
    "key": "User_Field_3"
  },
  userField4: {
    "name": "User Field 4",
    "key": "User_Field_4"
  },
  userField5: {
    "name": "User Field 5",
    "key": "User_Field_5"
  },
  userField6: {
    "name": "User Field 6",
    "key": "User_Field_6"
  },
  voltage: {
    "name": "Voltage",
    "key": "Voltage"
  },
  wattage: {
    "name": "Wattage",
    "key": "Wattage"
  },
  weight: {
    "name": "Weight",
    "key": "Weight"
  },
}
export const DEFAULT_VECTORWORKS_MAPPINGS = {
  ...STATIC_MAPPINGS,
  ...PROCESSED_MAPPINGS,
  frost: {
    name: 'Frost',
    key: 'Frost',
  },
  color: {
    name: 'Color',
    key: 'Color'
  },
  purpose: {
    name: 'Purpose',
    key: 'Purpose'
  },
  position: {
    name: 'Position',
    key: 'Position',
    required: true,
  },
  unit: {
    name: 'Unit Number',
    key: 'Unit_Number'
  },
  address: {
    name: "DMX Address",
    key: "DMX_Address"
  },
  dimmer: {
    name: 'Dimmer',
    key: 'Dimmer'
  },
  universe: {
    name: 'Universe',
    key: 'Universe'
  },
  channel: {
    name: 'Channel',
    key: 'Channel'
  },
  circuitName: {
    name: 'Circuit Name',
    key: 'Circuit_Name'
  },
  circuitNumber: {
    name: 'Circuit Number',
    key: 'Circuit_Number'
  },
  template: {
    "name": "Template",
    "key": "Template"
  },
  template2: {
    "name": "Template2",
    "key": "Template2"
  },
}

let _exportFieldListDefault = {}
for(let key of Object.keys(DEFAULT_VECTORWORKS_MAPPINGS)) {
  const map = DEFAULT_VECTORWORKS_MAPPINGS[ key ]

  if(map && !map.disabled && map.key) {
    //We support multi-mapping so duplicates can happen
    _exportFieldListDefault[map.key] = map.name
  }
}

export const DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST = { ..._exportFieldListDefault }


export const STATIC_ACCESSORY_MAPPINGS = [
  {
    laKey: 'deviceType',
    vwKey: 'Device_Type',
  }, {
    laKey: 'instrumentType',
    vwKey: 'Inst_Type',
  }, {
    laKey: 'symbolName',
    vwKey: 'Symbol_Name',
  }, {
    laKey: 'uid',
    vwKey: 'UID',
  },
]

export const STATIC_MAPPING_KEYS = Object.keys(STATIC_MAPPINGS)
export const XML2JS_OPTS = {
  trim: true,
  explicitArray: false,
}
const LOG = Logger('en', 'DATA_EXCHANGE')

export const Actions = {
  DELETE: 'Delete',
  UPDATE: 'Update',
  ADD: 'Add',
  ENTIRE_PLOT: 'Entire Plot'
}

/** A list of the Vectorworks device types */
export const DeviceTypes = {
  LIGHT: 'Light',
  MOVING_LIGHT: 'Moving Light',
  ACCESSORY: 'Accessory',
  STATIC_ACCESSORY: 'Static Accessory',
  POWER: 'Power',
  PRACTICAL: 'Practical',
  SFX: 'SFX',
  DEVICE: 'Device',
  OTHER: 'Other',
}

/**
 * Template object for SyncResults
 */
export const SyncResults = {
  Action: 'string',
  AppStamp: 'string',
  VWVersion: 'vwVersion',
  VWBuild: '0.0.0',
  AutoRot2D: 'false',
}

const VW_SYNC_FORMAT = 'YYYYMMDDHHmmss'
const GHETTO_SYNC_FORMAT = 'YYMMDDHHmmss'

/**
 * 
 * @param {*} inst the instrument to process
 * @returns a string that is a list of all the accessories, empty if none
 */
export const readAccessories = (inst) => {
  if(!inst) {
    return ''
  }

  if(!inst.accessories) {
    return ''
  }

  
  if(inst.accessories.length) {
    let outArray = []
    for(let a of inst.accessories) {
      let instType = a.instrumentType
      let altName = a.symbolName + ''
      let altLower = altName.toLowerCase()
      if(!instType) {
        if(altLower.startsWith('light acc')) {
          altName = altName.slice(9)
          outArray.push(altName)
        }
      }
      if(a.instrumentType) {
        outArray.push(a.instrumentType)
      }
    }

    return joinStrings(...outArray)
  }
}
 /**
 * @returns the TimeStamp in Vectorworks format
 */
export const vwTimestamp = () => {
  return moment.utc().format( VW_SYNC_FORMAT )
}

export const ghettoLightwrightTimestamp = () => {
  return moment.utc().format( VW_SYNC_FORMAT )
  //return moment.utc().format( GHETTO_SYNC_FORMAT )
}

 /**
 * Hillbillifies the timestamp to match the WTF format in 
 * Lightwright synchronization.
 *
 * @param {*} stamp
 * @returns a time stamp, not y2.1K compliant
 */
export const hillbillifyTimestamp = (stamp) => {
  return stamp.slice(2)
}

/**
 * Reads and XML file into JSON and returns it
 * 
 * @param {*} fileName 
 * @param {*} encoding 
 */
export const readXmlFileToJson = (data) => {
  console.log(`----------processing data`)
  return new Promise((resolve, reject) => {  

    let json = xml2js.parseString(data, XML2JS_OPTS, (error, result) => {
      console.log(`----------processing data \n\terror:${error}\n\t result:${result}`)
      if (error) {
        console.log('----------error processing data')
        console.log(error)
        return reject(error)
      } else {
        console.log('----------success processing data')
        console.log(result)
        return resolve(result)
      }
    })
  })
} 

/**
 * Resolve the ExportFieldList from the raw data (not the JSON)
 * @param {*} data text file 
 */
export const readExportFieldList = (data) => {
  return new Promise( (resolve, reject) => {
    readXmlFileToJson(data)
      .then(json => {
        const data = json || {}
        const slData = json.SLData || {}
        const ExportFieldList = slData.ExportFieldList || []
        resolve(ExportFieldList)
      })
      .catch(e => reject(e) )
  })
}

/**
 * Sync the contents of this file to the current laUuidObject and return a new
 * object representing the changes and the result of the sync.
 * 
 * @param {JSON SLData Object} contents the XML file to import 
 * @param {Map<UUID, object>} laUuidObject a Map<UUID, object> of the current light assistant instruments
 * @param {Map<String, String>} mappings a set of mappings to respect
 * @param {Map<String, String>} instrumentMap a Map<UUID, object> of the updates that need to be sent
 * @param {Map<String, String>} changeMap a Map<UUID, object> of the updates that need to be sent
 * @param {Bool} verbose whether or not to log verbosely
 * 
 * @return a Promise<SyncResults, Error>
 */
export const processVectorworksXmlFile = (json, mappings, instrumentMap, changeMap, verbose) => {
  return new Promise( (resolve, reject) => {

    const _MAPPINGS = {
      ...mappings,
      ...STATIC_MAPPINGS, //overwrite with required mappings
    }
    const _MAPPINGS_KEYS = Object.keys(_MAPPINGS)
    const _MAPPING_LA_TO_VW = {}
    const _MAPPING_VW_TO_LA = {}
    
    for(let _key of _MAPPINGS_KEYS) {
      const vwKey = _MAPPINGS[_key].key
      const laKey = _key

      _MAPPING_LA_TO_VW[ laKey ] = vwKey
      _MAPPING_VW_TO_LA[ vwKey ] = laKey
    }

    //Fix this later, this is for debug output
    let fs = null
    if (verbose) {
      try {
        fs = require('fs')
        if (!fs.existsSync('tmp')) {
          fs.mkdirSync('tmp')
        }
  
        fs.writeFileSync('tmp/fromVw.json', JSON.stringify(json || {no: 'data'}, null, 2))
        fs.writeFileSync('tmp/mappings.json', JSON.stringify(mappings || {no: 'data'}, null, 2))        
      } catch (error) {
        console.log('Not running in Node.js for FS supporting verbosity')
        console.log('Json=========================')
        console.log(json)
        console.log('MAPPINGS=========================')
        console.log(mappings)
        console.log('END Sync Verbose=========================')
      }

      for( let key in json.SLData) {
        console.log(key)
      }
    } //end verbose debug

    let slData = json.SLData
    let instrumentData = slData.InstrumentData
    let exportFieldList = slData.ExportFieldList
    let vwFieldList = slData.VWFieldList
    let universeSettings = slData.UniverseSettings
    let processed = {
      /** Added by Light Assistant */
      added: {},
      /**
       * Items to be updated into the database
       */
      updated: {},
      /**
       * Items to be removed (marked deleted) in the database
       */
      deleted: {},
      /** 
       * Items that were already in the sync file and need to be persisted to 
       * that file. 
       */
      unsynchronized: {},
      /**
       * A list of changes that happened inside a synchronization 
       * action where the AppStamp is not Vectorworks. The ChangeMap
       * and unsynchronized are pushed into the next sync
       */
      conflicted: {},
      /** 
       * Informational warnings about data being changes/stomped.
       */
      warnings: {},
    }
    
    const fileTimeStamp = exportFieldList.TimeStamp
    
    const _parsed = moment(fileTimeStamp, 'YYYYMMddHHmmss')

    if(verbose) {
      console.log('Last Timestamp -> ' + fileTimeStamp)
      console.log('Time -> ' + _parsed.format())
      /** There are some special cases like entire plot  */
      console.log('Action -> ' + instrumentData.Action)
      console.log('App Stamp -> ' + instrumentData.AppStamp)
      console.log('VWVersion -> ' + instrumentData.VWVersion)
      console.log('VWBuild -> ' + instrumentData.VWBuild)
      console.log('AutoRot2D -> ' + instrumentData.AutoRot2D)
    }

    const entirePlot = instrumentData.Action == Actions.ENTIRE_PLOT

    processed.timeStamp = fileTimeStamp
    processed.time = _parsed.format()
    /** There are some special cases like entire plot  */
    processed.action = instrumentData.Action
    processed.appStamp = instrumentData.AppStamp
    processed.vwVersion = instrumentData.VWVersion
    processed.vwBuild = instrumentData.VWBuild
    processed.autoRot2D = instrumentData.AutoRot2D

    //Remap the instrument based on the mappings / field list
    const mapInstrument = (inst) => {
      const _appStamp = inst.AppStamp
      let output = {}
      const uid = inst.UID || inst.uid

      for(let _key of _MAPPINGS_KEYS) {
        const vwKey = _MAPPINGS[_key].key
        const laKey = _key

        if (vwKey == ACCESSORIES_KEY_VW && inst[ACCESSORIES_KEY_VW]) {
          let _items = inst[ACCESSORIES_KEY_VW]

          if(!output[ACCESSORIES_KEY_LA]) { //create array
            output[ACCESSORIES_KEY_LA] = []
          }

          for( let accessory of Object.keys(_items) ) {
            let _mapped = mapInstrument( _items[accessory] )
            output[ACCESSORIES_KEY_LA].push(_mapped)
          }

          //special processing
        } else {
          const val = inst[vwKey]
          if(val !== null && val !== undefined) { //skip undefined values
            output[laKey] = inst[vwKey]
          }
         }
      }

      //============HANDLE CONFLICTS
      //Is there a pending change that Vectorworks doesn't have?
      const conflicted = changeMap[ uid ]

      //Changes were made to this after the vectorworks synchronization
      const EXCLUDED = [
        'timeStamp',
        'action',
        'lastUpdatedBy',
      ]

      if (conflicted && (conflicted.timeStamp > inst.TimeStamp) ) {
        LOG.info(`\tResolving conflict, using Light Assistant ${uid}`)
        processed.conflicted[ uid ] = { ...conflicted }
        const updatedValues = {}
        for( let _key of Object.keys(conflicted) ) {
          let excluded = false

          for( let _e of EXCLUDED) {
            if (_key == _e) {
              excluded = true  
            }
          }

          if(!excluded) {
            updatedValues[_key] = conflicted[_key]
            output[_key] = conflicted[_key]
          }
        }

        processed.warnings[uid] = {
          overwriteBy: 'Light Assistant',
          ...updatedValues
        }
      } else if (conflicted) {
        LOG.info(`\tResolving conflict, using Vectorworks ${uid}`)
        //Generate a "warning" object. This means data was potentially lost
        processed.warnings[ uid ] = { 
          overwriteBy: 'Vectorworks',
          ...conflicted,
        }
      }

      if(conflicted) {
        conflicted._processed = true
      }

      //============MERGE DATABASE KEYS/REV DATA
      const laInst = instrumentMap[ uid ]

      if(!output._id) {
        output._id = uid //Lightwright ends with :Vecto, but I don't see a need for it
      }

      //========STATIC ACCESSORY PROCESSING
      //
      // Basically we skip the static accessory on the root object and maintain it 
      // on the main object.
      //

      let isChildAccessory = false
      if(output.deviceType == DeviceTypes.STATIC_ACCESSORY) {
        isChildAccessory = true
      }
      if(laInst) {
        if(entirePlot) { //overwrite with data in LA as priority
          const out = mergeObjectsIgnoreEmpty(laInst, output)
          
          if(laInst.deleted) {
            out.action = Actions.DELETE
          } else {
            out.deleted = false
          }
          
          if(!isChildAccessory) {
            processed.conflicted[ uid ] = out
          }
          
          return out
        } else {
          const out = {
            ...laInst,
            ...output,
          }

          if(out.deleted) {
            out.action = Actions.DELETE
          }
          if(!isChildAccessory) {
            processed.conflicted[ uid ] = out
          }
          return out
        }
      } else {
        if(entirePlot) {
          if(!isChildAccessory) { 
            processed.conflicted[ uid ] = output
          }
        }
        return output
      }
    }

    for(let key in instrumentData) {
      let item = instrumentData[key]
      const uid = item.UID || item.uid //use Vectorworks ID
      if ( !key.startsWith('UID_') ){

        if(verbose) {
          LOG.info('ignoring key ' + key)
        }
        
        continue
      }
      
      if(item.AppStamp == 'Vectorworks') {
      //VECTORWORKS IMPORT
        if (Actions.DELETE == item.Action) {
          const exists = instrumentMap[ uid ]
          if(!exists) {
            if(verbose) {
              LOG.info('skipping delete, item is not in Light Assistant -> ' + key + ' type: ' + item.Device_Type  + ' action: ' + item.Action)
            }          
            continue
          }
          if(verbose) {
            LOG.info('deleting -> ' + key + ' type: ' + item.Device_Type  + ' action: ' + item.Action)
          }
          
          processed.deleted[ uid ] = mapInstrument(item)
        } else if (Actions.UPDATE == item.Action) {
          if(verbose) {
            LOG.info('importing -> ' + key + ' type: ' + item.Device_Type  + ' action: ' + item.Action)
          }
          
          processed.updated[ uid ] = mapInstrument(item)
        } else {
          //FIME do a log
          LOG.error(`Unknown Action [${item.Action}] UUID:${item.UUID} _ID: ${item._id}`)
        }
      } else {
      //LIGHT ASSISTANT MERGE
        if(verbose) {
          LOG.info('Unsynchronized Data -> ' + key + ' type: ' + item.Device_Type  + ' action: ' + item.Action)
        }
        const inst = mapInstrument(item)
        processed.unsynchronized[ uid ] =  { ...inst } //Track this
      }
    }

    //PROCESS changeMap/merge Changes
    //NOTE: we need to delete the "_processed" attribute otherwise it can persist
    
    for(let _uid of Object.keys(changeMap)) {
      const changes = changeMap[_uid]

      if(changes._processed) {
        delete changes._processed
        continue //we already handled this in ()=>::mapInstrument
      }

      delete changes._processed
      //This is weird, but we might need other processing here
      if(changes.action == Actions.ADD) { 
        //FIXME I don't think "ADD" actually exists, I only ever see UPDATE
        processed.added[ _uid ] = changes //uses _id 
      } else if (changes.action == Actions.DELETE) {
        processed.conflicted[ _uid ] = changes
      } else if (changes.action == Actions.UPDATE) {
        processed.conflicted[ _uid ] = changes
      } else {
        LOG.warn('UNKNOWN STATE ' + _uid)
        LOG.warn('\t' + JSON.stringify(changes, 2, null) )
      }
    }

    //=================MERGE THE UNSYNCHRONIZED (already in the XML)
    for(let _uid of Object.keys(processed.unsynchronized) ) {
      const conflicted = processed.conflicted[ _uid ]
      const fromXml = processed.unsynchronized[ _uid ]

      if(!fromXml) { //if this is null or empty
        continue
      }

      if (conflicted) { //Merge with a priority of the conflicted data (current changes)
        processed.conflicted[ _uid ] = {
          ...fromXml,
          ...conflicted,
        }
      } else {
        processed.conflicted[ _uid ] = fromXml
      }
    }
    
    //write the updated JSON file
    let _slData = {
      ...slData
    }

    _slData.InstrumentData = {
      AppStamp: APP_STAMP, //FIXME LOW I'd like this to be Light Assistant
      VWVersion: instrumentData.VWVersion,
      VWBuild: instrumentData.VWBuild,
      AutoRot2D: instrumentData.AutoRot2D,
    }

    const createSillyUID = (uid) => {
      return 'UID_' + uid.replace(/\./g, '_')
    }

    //================= REMAP the conflicted data into the xmlObject
    if(processed.conflicted && processed.conflicted) {
      for(let _uid of Object.keys(processed.conflicted) ) {
        //We need to transform all this back.
        const remapped = {}
        const inst = processed.conflicted[ _uid ]
        const tag = createSillyUID(_uid)
        
        for(let _key of Object.keys( processed.conflicted[_uid] ) ) {
          if( _key == '_processed' || _key == 'lastUpdatedBy' ) { //skip
            continue
          }

          //Accessories logic...
          if(_key == 'accessories' && inst.accessories) {
            //REMAP ACCESSORIES
            let remappedAccessories = {}

            for(let _accessory of inst.accessories) {
              const sillyUID = createSillyUID( inst.uid )
              let remapped_a = {}
              for( let _aKey of Object.keys(_accessory) ) {
                remapped_a[ _MAPPING_LA_TO_VW[_aKey] ] = _accessory[_aKey]
              }
              remappedAccessories[ sillyUID ] = remapped_a
            }

            remapped[ _MAPPING_LA_TO_VW.accessories ] = remappedAccessories
          } else {
            remapped[ _MAPPING_LA_TO_VW[_key] ] = inst[_key]
          }
        }

        delete remapped.undefined //clear any unmapped keys

        remapped.TimeStamp = ghettoLightwrightTimestamp()

        remapped.AppStamp = APP_STAMP
        remapped.UID = _uid
        remapped.Lightwright_ID = inst._id
        _slData.InstrumentData[ tag ] = remapped//
      }
    }
    //clear the conflicted data (we have processed it)
    //This can be used to map back to the changeMap object.
    processed.conflicted = {}

    //return the new XML file to write
    let builder = new xml2js.Builder(XML2JS_OPTS)
    let xmlString = builder.buildObject( {
      SLData: {
        ..._slData,
      }
    } )
    processed.replacementXml = xmlString
    processed.xmlObject = {
      SLData: {
        ..._slData
      }
    }

    if (fs && verbose) {      
      let builder = new xml2js.Builder(XML2JS_OPTS)
      let xml = builder.buildObject(processed.replacementXml)
      fs.writeFileSync('tmp/toVw.xml', xmlString)
    }

    resolve( processed )
  })
}

/**
 * Converst the Sync JSON into XML for Vectorworks. 
 * @param {JSON} xmlObj 
 */
export const convertDownloadXmlObjToXml = (xmlObj) => {
  let builder = new xml2js.Builder(XML2JS_OPTS)
  let xml = builder.buildObject(xmlObj)
  return xml
}

/**
 * Regenerates the downloadXML from the current changes. All changes should
 * be pruned by the last syncrhonization so it is safe to say Light Assistant
 * is in control. Synchonization should be completed as a "whole" but due to
 * the web interface this is a decent workaround.
 * 
 * @param {*} vectorworksState The state (vectorworks) object
 * @returns a Promise containing the xmlData as a string. Rejects with error messages
 */
export const regenerateDownloadXml = (vectorworksState) => {
  const { vectorworks, syncMappings } = vectorworksState

  const _MAPPINGS = {
    ...syncMappings,
    ...STATIC_MAPPINGS, //overwrite with required mappings
  }
  const _MAPPINGS_KEYS = Object.keys(_MAPPINGS)
  const _MAPPING_LA_TO_VW = {}
  const _MAPPING_VW_TO_LA = {}
  
  for(let _key of _MAPPINGS_KEYS) {
    const vwKey = _MAPPINGS[_key].key
    const laKey = _key

    _MAPPING_LA_TO_VW[ laKey ] = vwKey
    _MAPPING_VW_TO_LA[ vwKey ] = laKey
  }

  return new Promise((resolve, reject) => {  
    //FIXME add better error handling here.
    if(!vectorworks || !vectorworks.downloadXml) {
      reject('[shared/vectorworks::regenerateDownloadXml] Can not regenerate a file that is not synchronized. Please synchronize first.')
    }

    let changeMap = vectorworks || {}    

    if(!changeMap) {
      resolve( vectorworks.downloadXml )
    }

    //FIXME -> ACTUALLY IMPLEMENT THIS
    return resolve( vectorworks.downloadXml )

    //Rebuild the InstrumentData node
    const Instruments = {}
    const InstrumentData = {
      AppStamp: APP_STAMP, //I'd like this to be Light Assistant
      VWVersion: instrumentData.VWVersion,
      VWBuild: instrumentData.VWBuild,
      AutoRot2D: instrumentData.AutoRot2D,
    }
  
    const createSillyUID = (uid) => {
      return 'UID_' + uid.replace(/\./g, '_')
    }
  
    //Remap the conflicted into the file
    for(let _uid of Object.keys( changeMap ) ) {
      //We need to transform all this back.
      const remapped = {}
      const inst = changeMap[ _uid ]
      const tag = createSillyUID(_uid)
      
      for(let _key of Object.keys( changeMap[_uid] ) ) {
        if( _key == '_processed' || _key == 'lastUpdatedBy' ) { //skip
          continue
        }

        remapped[_key] = inst[_key]
      }

      remapped['red'] = 'herring'//DELETEME
      newXmlFile.InstrumentData[ tag ] = remapped//
    }
  
    //return the new XML file to write
    let builder = new xml2js.Builder(XML2JS_OPTS)
    let xmlString = builder.buildObject( newXmlFile )
    processed.replacementXml = xmlString
  
    if (fs && verbose) {      
      let builder = new xml2js.Builder(XML2JS_OPTS)
      let xml = builder.buildObject(processed.replacementXml)
      fs.writeFileSync('tmp/toVw.xml', xmlString)
    }
  
    resolve( processed )

    //Read the XML, reassign the InstrumentData Node and return
    let json = xml2js.parseString(data, XML2JS_OPTS, (error, result) => {
      console.log(`----------processing data \n\terror:${error}\n\t result:${result}`)
      if (error) {
        console.log('----------error processing data')
        console.log(error)
        return reject(error)
      } else {
        console.log('----------success processing data')
        console.log(result)
        return resolve(result)
      }
    })
  })
}