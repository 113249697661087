import React from 'react'
import { connect } from 'react-redux'

import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core'

import IconSettings from '@material-ui/icons/Settings'
import IconBuild from '@material-ui/icons/Build'

import { showConfirm } from '../reducers/InterfaceLogic'
import { 
  copyDimmerToAbsoluteAddress
} from '../reducers/InstrumentLogic'
import { pushRoute } from './../history'

import { positions, general, instruments as Strings, instruments } from '../../shared/language'
class UtilityMenu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      menu: false,
    }
  }
  openMenu() {
    this.setState({
      ...this.state,
      menu: true,
    })
  }

  closeMenu() {
    this.setState({
      ...this.state,
      menu: false,
    })
  }

  renderMenu(open) {
    const { dispatch } = this.props
    return (
      <Menu
        open={Boolean(this.state.menu)}
        onClose={ _ => this.closeMenu() }
        anchorEl={ this.menuAnchor }
      >
        <MenuItem
          onClick={ () => {
            this.closeMenu()
            setTimeout(()=>{
              pushRoute('/positions')
            }, 180) //animation delay
          }}
        >
          <ListItemText>
            {positions.get('managePositions')}
          </ListItemText>
        </MenuItem>
          <MenuItem 
            onClick={e => {
              this.closeMenu()
              const doIt = () => {
                dispatch( copyDimmerToAbsoluteAddress() )
              }

              dispatch( 
                showConfirm(
                  Strings.get('cleanupDimmers'), 
                  Strings.get('cleanupDimmersDetail'), 
                  doIt, 
                  null,  
                  Strings.get('cleanupAddressAndDimmersOK'),
                  null
                )
              )
            }}
          >
            <ListItemText>
              {Strings.get('cleanupDimmers')}
            </ListItemText>
          </MenuItem>
      </Menu>
    )
    
  }
  render(){
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
          <Button
            buttonRef={x => this.menuAnchor = x}
            onClick={ e => {
              // if( !isConnected() ) {
              //   alert(Strings.get('appRequired'))
              //   return
              // }
              this.openMenu()
            }}
          >
            <IconBuild /> { general.get('utilities') }
          </Button>

          { this.renderMenu() }
      </div>
    )
  }
}

export default connect(s => {
  return {}
})(UtilityMenu)