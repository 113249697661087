export default {
  currentShow: 'Show Name',
  designerField: 'Designer',
  assistantField: 'Assistant',
  producer: 'Producer',
  paperworkDate: 'Paperwork Date',
  paperworkDateHelp: 'Text or a formatter, ex:"{day}, {date} {time}"',

  fileMenu: 'File',
  showSettings: 'Show Settings',
  settings: 'Settings',
  actions: 'Actions',
  themes: 'Themes',
  importExport: 'Import/Export',
  newShow: 'Create a New Show',
  saveShow: 'Save',
  loadShow: 'Load',

  designerTitle: 'Designer',
  designer: 'Designer',
  assistant1Title: 'Assistant Title',
  assistant2Title: 'Assistant Title',
  assistant3Title: '2nd Assistant Title',

  assistant1: 'Associate/Assistant',
  assistant2: 'Assistant',
  assistant3: '2nd Assistant',

  //Application Close Confirmation
  quit: 'Exit Light Assistant',
  quitMessage: 'Are you sure you want to quit?',
  yes: 'Yes',
  no: 'No',

  newShowWarning: 'Are you sure you want to create a new show?',
  newShowDetail: `
  <p>

    This will <b>delete</b> all your current data, 
    please make sure you have saved 
    your show first.
  </p>
  `,

  showSaved: 'Show Successfully Saved',
  showSavedMessage: `
  <p>The file has been saved to:</p>
  <code>{fileName}</code>
  `,
  estimateFileSize: 'Estimated File Size',
  estimateFileSizeInfo: `
  <p>
  Files over 5MB are expected to fail when using the Web App. 
  For larger files the native application (App Store/Windows Store) 
  does not have this constraint.
  </p>
  <p>
  Web storage is different on every system and file size errors
  occur when you are low on hard drive space.
  </p>
  `,
  purgeNonCritical: 'Purge Non Critical Data',
  purgeNonCriticalInfo: `
  <p>
  Puring non-critical data will remove the history of Vectorworks 
  data-exchange, undo history, and any other data that is not current.
  This is intended to be used on larger files when you are working in the
  web app. A typical workflow will not require using this unless there are 
  a lot of images in your show file.
  </p>

  <p>
  You should save your file before purging.
  </p>
`,
  titleMBOfMB: '{0} {1} of {2}',

  notesPreferences: 'Notes',
  userPreferences: 'User Settings',

  //In App Purchase Stuff
  inAppPurchases: 'Subscription',
}