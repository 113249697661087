//Import Logic
import { 
  STATIC_MAPPINGS, //required
  DEFAULT_VECTORWORKS_MAPPINGS,
  USER_IGNORES,
  USER_REQUIRED,
  DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST,
} from './../../shared/vectorworks'

import React from 'react'
import { connect } from 'react-redux'
import translate from '../../shared/language/Translate'
import { withStyles } from '@material-ui/core/styles'
import Colors from './../Colors'
import {
  genericStringSort
} from './../../shared/Utility'

import {
  Button,
  Input,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  IconButton
} from '@material-ui/core'

import IconEdit from '@material-ui/icons/Edit'
import IconCancel from '@material-ui/icons/Clear'
import IconSave from '@material-ui/icons/Done'
import IconBlock from '@material-ui/icons/Block'
import IconDelete from '@material-ui/icons/DeleteForever'
import IconAdd from '@material-ui/icons/Add'
import IconHelp from '@material-ui/icons/Help'
import IconRemove from '@material-ui/icons/Delete'
import IconError from '@material-ui/icons/Error'
import IconWarning from '@material-ui/icons/Warning'
import TextFieldRef from './../../components/TextFieldRef'

import { 
  updateMapping, 
  deleteMapping, 
  addMapping 
} from './../reducers/VectorworksCache'

import {
  instrumentMappings
} from './../../shared/language'

import {
  showDialog,
  showConfirm,
} from './../reducers/InterfaceLogic'

import AddMappingDialog from './AddMappingDialog'

const cellStyles = {
  
}

const MappingTableCell = withStyles( theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 14,
  },
  body: {
    fontSize: 14,
  },
}) )(TableCell)

const tableStyles = theme => ({
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  rowEdit: {
    backgroundColor: Colors.LA_LIGHT_BLUE_ALPHA_25,
  }
})

class Mappings extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      editing: null,
      current: null,
      backup: null,
      addName: '',
    }

    this.dialogRef = null
  }

  componentWillReceiveProps(props) {
    this.setState({
      editing: null,
      current: null,
      backup: null,
      addName: '',
    })
  }

  editRow(mapping) {
    let backup = 
    this.setState({
      ...this.state,
      editing: mapping.property,
      current: { ...mapping },
      backup: { ...mapping },
    })
  }

  cancelEdits() {
    this.setState({
      ...this.state,
      editing: null,
      current: null,
      backup: null,
    })
  }

  removeMapping(mapping) {
    let copy = {
      ...this.state.current,
      vectorworks: null,
      vectorworksDisplay: null,
    }

    this.setState({
      ...this.state,
      current: copy,
    })
  }

  _deleteField(mapping) {
    const T = this.props.strings
    if(mapping.required) {
      return
    }

    const onConfrim = () => {
      this.setState({
        ...this.state,
        editing: null,
        current: null,
        backup: null,
      })

      this.props.dispatch( deleteMapping( mapping.property ) )
    }

    this.props.dispatch(
      showConfirm( 
        T.get('deleteConfirmTitle', mapping.property), 
        T.get('deleteConfirmCopy', mapping.property), 
        onConfrim, ()=>{}, 
        T.get('deleteField')
        , null
      )
    )
  }

  setField(key, value) {
    let copy = {
      ...this.state.current
    }
    copy[key] = value
    this.setState({
      ...this.state,
      current: copy,
    })
  }

  setMapping(mapping) {
    let copy = {
      ...this.state.current
    }
    copy.vectorworks = mapping.key
    copy.vectorworksDisplay = mapping.name
    this.setState({
      ...this.state,
      current: copy,
    })
  }

  setAddFieldName(name) {
    this.setState({
      ...this.state,
      addName: name
    })
  }

  submitName() {
    if(this.state.addName) {
      const name = this.state.addName

      this.props.dispatch( addMapping( name ) )

      this.setState({
        ...this.state,
        addName: null,
      })

    }
  }

  saveChanges() {
    const { property, vectorworks, vectorworksDisplay, long, medium, short } = this.state.current
    
    this.props.dispatch( 
      updateMapping( property, vectorworks, vectorworksDisplay, long, medium, short)
    )
    this.cancelEdits()
  }

  render() {
    const T = this.props.strings
    const { 
      dispatch, 
      mappings, 
      mappingsReverseLookup,
      classes, 
      exportFieldList, 
      exportFieldListSorted,
    } = this.props

    const { editing, current } = this.state
    const cellWidths = {
      required: { width: '128px', maxWidth: '128px' },
      la: { width: '196px', maxWidth: '196px' },
      vectorworks: { width: '300px', maxWidth: '300px' },
      display: { width: '156px', maxWidth: '156px' },
      long: { width: '170px', maxWidth: '170px' },
      medium: { width: '170px', maxWidth: '170px' },
      short: { width: '150px', maxWidth: '150px' },
      controls: { width: '196px', maxWidth: '196px' },
    }

    const blank = ()=>{
      return (
        <span 
          style={{
            color: Colors.DISABLED,
            fontStyle: 'italic',
          }}
        >
          ---
        </span>
      )
    }
    return (
      <Paper
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <AddMappingDialog 
          ref={r => this.dialogRef = r} 
          dispatch={this.props.dispatch} 
        />
        
        <Table className={classes.table} >
          <TableHead>
            <TableRow>
              
              <MappingTableCell style={cellWidths.required}>
                { T.get('required') }
              </MappingTableCell>

              <MappingTableCell style={cellWidths.la}>
              { T.get('laKey') }
              </MappingTableCell>

              <MappingTableCell style={cellWidths.vectorworks}>
              { T.get('vwKey') }
              <Button
                onClick={ e => {
                  dispatch( showDialog(T.get('vwHelp'), T.get('vwHelpMessage') ) )
                }}
              >
                <IconHelp style={{color: 'white'}}/>
              </Button>
              </MappingTableCell>

              <MappingTableCell style={cellWidths.display}>
                { T.get('displayLong') }
              </MappingTableCell>

              <MappingTableCell  style={cellWidths.medium}>
              { T.get('displayMedium') }
              </MappingTableCell>

              <MappingTableCell style={cellWidths.short}>
              { T.get('displayShort') }
              </MappingTableCell>

              <MappingTableCell style={cellWidths.controls}>
              </MappingTableCell>
            </TableRow>
          </TableHead>
        </Table>
        <div
          style={{
            overflowY: 'scroll',
            height: '100%',
          }}
        >
          <Table className={classes.table} >       
            <TableBody
              style={{
                overflowY: 'scroll',
              }}
            >
              { mappings.map( (mapping, index) => { 
                const { property } = mapping
                const isEditing = editing == property
                //if null or in the list we're ok
                const inactiveMapping = (exportFieldList[mapping.vectorworks] || {}).default
                const validMapping = !!mappingsReverseLookup[mapping.vectorworks] || mapping.vectorworks === null || mapping.vectorworks === undefined//if null, invalid
                const baseStyle = validMapping ? {} : { color: 'red'}
                if(mapping.disabled) {
                  return null //We skip "disabled" mappings the user can't see
                }

                return (
                  <React.Fragment>
                  <TableRow className={isEditing ? classes.rowEdit : classes.row } key={property}>

                    <MappingTableCell style={cellWidths.required}>
                      { !mapping.required ? T.get('optional') : T.get('required') }
                    </MappingTableCell>

                    <MappingTableCell 
                      style={ {
                        ...cellWidths.la, 
                        ...baseStyle 
                      }}
                    >
                      { mapping.property }
                    </MappingTableCell>

                    <MappingTableCell 
                      style={ {
                        ...cellWidths.vectorworks, 
                        ...baseStyle 
                      }}
                    >
                      { isEditing ?
                          !mapping.required ?
                            <React.Fragment> 
                              <TextField 
                                select
                                value={current.vectorworksDisplay}
                                onChange={ e => {
                                  const mappingValue = e.target.value
                                  const mapping = exportFieldListSorted.filter( item => item.name == mappingValue)[0]
                                  if(mapping) {
                                    this.setMapping(mapping)
                                  } else {
                                    alert('unable to map ' + e.target.value)
                                  }
                                  
                                }}
                              >
                                { exportFieldListSorted.map( (item, index) => {
                                  const primary = item.name
                                  const secondary = item.default ? null : 'active'
                                  return (
                                    <MenuItem
                                      key={item.key} 
                                      value={item.name}
                                    >
                                      <ListItemText 
                                        primary={primary} 
                                        secondary={secondary} 
                                        color='primary' 
                                      />
                                    </MenuItem>
                                  )
                                })}
                              </TextField>
                              { mapping.required ? 
                                null 
                                :
                                <Tooltip title={T.get('deleteMappingTip')}>
                                  <IconButton
                                    onClick={e => {
                                      this.removeMapping(mapping)
                                    }}
                                  >
                                    <IconRemove />
                                  </IconButton>
                                </Tooltip>
                              }
                              
                              { !validMapping ? 
                                <span>
                                  <Tooltip title={T.get('errorMappedButNotValid', mapping)}>
                                    <IconError />
                                  </Tooltip>
                                </span>
                                : 
                                null}
                            </React.Fragment>
                          :
                          <Tooltip title={T.get('blockTip')}>
                            <span>
                                <IconBlock /> {mapping.vectorworksDisplay} 
                            </span>
                          </Tooltip>
                        : 
                          <React.Fragment>
                            {mapping.vectorworksDisplay}
                            { !validMapping ? 
                              <span>
                                <Tooltip 
                                  title={instrumentMappings.get('errorMappedButNotValid', mapping)}
                                >
                                  <IconError 
                                    onClick={e => {
                                      dispatch( 
                                        showDialog(instrumentMappings.get('errorMappedButNotValidTitle', mapping), instrumentMappings.get('errorMappedButNotValidHelp', mapping) )
                                      )
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            : null }

                            { !mapping.required && inactiveMapping ? 
                              <span>
                                <Tooltip 
                                  title={instrumentMappings.get('errorMappedButNotValid', mapping)}
                                >
                                  <IconWarning 
                                    onClick={e => {
                                      dispatch( 
                                        showDialog(instrumentMappings.get('errorMappedButNotValidTitle', mapping), instrumentMappings.get('errorMappedButNotValidHelp', mapping) )
                                      )
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            : null }
                          </React.Fragment>
                      }
                      
                    </MappingTableCell>

                    <MappingTableCell style={cellWidths.display}>
                      { isEditing ?                     
                        <Input 
                          value={current.long}
                          style={{
                            width: '150px'
                          }}
                          onChange={ e => {
                            this.setField('long', e.target.value)
                          }}
                        />
                      : mapping.long || blank()
                      }
                    </MappingTableCell>

                    <MappingTableCell style={cellWidths.medium}>
                      { isEditing ?                     
                        <Input 
                          value={current.medium}
                          style={{
                            width: '150px'
                          }}
                          onChange={ e => {
                            this.setField('medium', e.target.value)
                          }}
                        />
                      : mapping.medium || blank()
                      }
                    </MappingTableCell>

                    <MappingTableCell style={cellWidths.short}>
                      { isEditing ?                     
                        <Input 
                          value={current.short}
                          style={{
                            width: '150px'
                          }}
                          onChange={ e => {
                            this.setField('short', e.target.value)
                          }}
                        />
                      : mapping.short || blank()
                      }
                    </MappingTableCell>

                    <MappingTableCell style={cellWidths.controls}>
                      { isEditing ? 
                        <React.Fragment>
                          <Tooltip title={T.get('saveTip')}>
                            <IconButton
                              onClick={ e => this.saveChanges() }
                            >
                              <IconSave />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={T.get('cancelTip')}>
                            <IconButton
                              onClick={ e => this.cancelEdits() }
                            >
                              <IconCancel />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title={T.get('deleteFieldTip')}>
                            <IconButton
                              onClick={ e => this._deleteField(mapping) }
                            >
                              <IconDelete />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                        : 
                          <Tooltip 
                            title={T.get('editTip')}
                          >
                            <IconButton
                              onClick = { 
                                e => this.editRow(mapping) 
                              }
                            >
                              <IconEdit />
                            </IconButton>
                          </Tooltip>
                        }
                      
                    </MappingTableCell>
                  </TableRow>
                  {/* <tr style={{color:'blue'}}><td colSpan="6">{ JSON.stringify(mapping) }</td></tr>
                  { isEditing ? <tr style={{color:'red'}}><td colSpan="6">{ JSON.stringify(current) }</td></tr> : null } */}
                  </React.Fragment>
                )
              } ) } 
              <TableRow>
                <MappingTableCell
                  colSpan={6}
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={e => {
                      this.dialogRef.open()
                    }}
                  >
                    <IconAdd /> { T.get('addMapping') }
                  </Button>
                </MappingTableCell>
              </TableRow>

            </TableBody>
          </Table>
        </div>
      </Paper>
    )
  }
}

export default connect(
  (state)=>{
    const syncMappings = state.vectorworks.syncMappings || {}
    const ExportFieldList = state.vectorworks.ExportFieldList || {}

    const mappings = []
    const exportFieldListSorted = []

    //A Mapping of VW Key -> LA KEY
    const mappingsReverseLookup = {}
    const activeReverseLookup = {}

    //A map of <key, <default, key, name> >
    const exportFieldList = {}

    //Build the export field list
    for(let key of Object.keys(DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST) ) {
      exportFieldList[key] = {
        default: true,
        key: key,
        name: DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST[key],
      }
    }

    for(let key of Object.keys( ExportFieldList )) {
      if(key) {
        exportFieldList[key] = { 
          default: false,
          key: key,
          name: ExportFieldList[key]
        }
      }
    }

    const badMaps = {}

    for(let key of USER_REQUIRED) {
      if(syncMappings[key]) {
        syncMappings[key].required = true
      }
    }

    for(let key of USER_IGNORES) {
      if(syncMappings[key]) {
        syncMappings[key].disabled = true
      }
    }

    for(let key of Object.keys(syncMappings)) {
      const map = syncMappings[key]

      mappings.push({
        property: key,
        vectorworks: map.key,
        vectorworksDisplay: map.name,
        disabled: !!map.disabled,
        required: !!map.required,
        long: map.display,
        medium: map.displayMedium,
        short: map.displayShort,
      })

      if(map.key) {
        mappingsReverseLookup[ map.key ] = key
      }
    }

    
    for( let key of Object.keys(exportFieldList) ) {
      exportFieldListSorted.push( exportFieldList[key] )  
    }

    mappings.sort( (a,b) => genericStringSort(a,b, 'required', 'property') )
    exportFieldListSorted.sort( (a,b) => genericStringSort(a,b, 'key') )

    return {
      mappings: mappings,
      mappingsReverseLookup: mappingsReverseLookup,
      exportFieldList: exportFieldList,
      exportFieldListSorted: exportFieldListSorted,
    }
  }) ( translate('InstrumentMappings') (withStyles(tableStyles)(Mappings) ) )