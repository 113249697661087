import React, {useState} from 'react'
import {
  makeStyles,
  withStyles
} from '@material-ui/styles'

import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@material-ui/lab'

import {
  Backdrop
} from '@material-ui/core'

import IconPin from '@material-ui/icons/PinDrop'
import IconClose from '@material-ui/icons/Close'

const speedStyles = makeStyles({
  'noWrap': {
    'white-space': 'nowrap'
  },
  'secondary': {
    'color': window.theme.palette.common.white,
    'background-color': window.theme.palette.secondary.main,
    'margin': '8px',
  },
  'primary': {
    'color': window.theme.palette.common.white,
    'background-color': window.theme.palette.primary.main,
    'margin': '8px',
  }
})


export default (props) => {
  const classes = speedStyles()
  const [open, setOpen] = React.useState(false)
  const [pinned, setPinned] = React.useState(false)

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const isOpen = pinned || open || false
  const actions = props.actions || []
  const pinnedIcon = pinned ? <IconPin /> : null

  let pinAction
  if(pinned) {
    pinAction = <SpeedDialAction
      tooltipOpen
      FabProps={{
        className: classes.primary,
      }}
      TooltipClasses={{
        className: classes.noWrap
      }}
      icon={<IconClose />}
      tooltipTitle={`Close Menu`}
      onClick={ ()=>{
        if(pinned) {
          setPinned(false)
          setOpen(false)
        } else {
          setPinned(true)
          setOpen(true)
        }
      } }
    />
  } else {
    pinAction = <SpeedDialAction
      tooltipOpen
      icon={<IconPin />}
      tooltipTitle={`Pin Menu`}
      TooltipClasses={{
        className: classes.noWrap
      }}
      onClick={ ()=>{
        if(pinned) {
          setPinned(false)
          setOpen(false)
        } else {
          setPinned(true)
          setOpen(true)
        }
      } }
    />
  }
  return (
    <React.Fragment>
      <Backdrop 
        style={{
          zIndex: window.theme.zIndex.drawer + 1
        }}
        open={open} 
      >
      </Backdrop>
      <SpeedDial
        style={{
          position: 'absolute',
          top: 50,
          right: 12,
          //width: 350,
          whiteSpace: 'nowrap',
          zIndex:  window.theme.zIndex.drawer + 2
        }}
        ariaLabel = 'Notes Speed Dial'
        icon={<SpeedDialIcon  openIcon={pinnedIcon} />}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        direction = {'down'}
      >
        { actions.map((x,i) => {
          const runFn = () => {
              x.fn()
          }
          const key = x.key || 'key-' + i
          if(isOpen && x.color == 'primary') {
            return (
              <SpeedDialAction 
                tooltipOpen
                key={key}
                icon={x.icon}
                FabProps={{
                  className: classes.primary,
                }}
                tooltipTitle={x.tooltip || 'Unknown'}
                onClick={runFn}
              />
            )
          } else if (isOpen && x.color == 'secondary') {
            return (
              <SpeedDialAction 
                tooltipOpen
                key={key}
                icon={x.icon}
                FabProps={{
                  className: classes.secondary,
                }}
                tooltipTitle={x.tooltip || 'Unknown'}
                onClick={runFn}
              />
            )
          } else {
            return (
              <SpeedDialAction 
                tooltipOpen
                key={key}
                icon={x.icon}
                tooltipTitle={x.tooltip || 'Unknown'}
                onClick={runFn}
              />
            )
          }
          
        })}

        {/* {pinAction} */}
      </SpeedDial>
    </React.Fragment>
  )
}