export default {
  palette: {
    type: 'light',
    primary: {
			main: '#3f51b5',
    },
    secondary: {
      main:'#f50057',
    }
    // primary: '#00bcd4', //Original Teal //Darker Blue : #3f51b5
    // //secondary: '#ff4081', //Original Magenta //Darker "new" Magenta #f50057
  },
  green: 'rgb(0,128,0)',
  navBar: 'rgb(13, 71, 161)',
  la_blue: 'rgb(13, 71, 161)',
  la_light_blue: '#0075ff',
  la_blue_half: 'rgba(13, 71, 161, 0.5)',
  la_light_blue_alpha_25: 'rgba(0, 117, 255, 0.25)',
  dragHoverColor: 'green',
  hover: 'rgb(0, 117,255)',
  paperAlt: 'rgba(240, 240, 240, 0.4)',
  brightPaper: 'rgb(255,255,255)',
  boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px',
  boxShadowPrimary:  'rgba(0, 117, 255, 0.5) 0px 1px 6px, rgba(0, 117, 255, 0.5) 0px 1px 4px',
  contentHighlight: 'FFFF80',
  highlight: 'rgba(255, 255, 0, 0.5)',
  editor: 'rgba(105, 251, 89, 0.25)',
  editorBorder: 'rgba(105, 251, 89, 1.0)',
  seperator: 'rgba(238, 238, 238, 1)',
  oscOnline: 'rgba(200, 100, 0, 1)',
  tableBorder: 'rgba(0,0,0,1.0)',
  tableDashed: 'rgba(64,64,64, 0.3)',
}