import React from 'react'
import { connect } from 'react-redux'
import SmartToggle from './../../components/Button/Toggle'
import { 
  osc as OSC
} from './../../shared/language'

import { 
  showDialog
} from './../reducers/InterfaceLogic'

import {
  Button,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'

import {
  Card, CardSeries
} from './../../components/Cards'

import { 
  READ_PATCH_ENABLED,
  updateOscKey,
  connectToEos,
  OSC_UPDATE_KEY,
} from '../reducers/OscLogic'

import { 
  qlabDumpCueList, 
} from './../reducers/ElectronLogic'

class QLabConnection extends React.Component {
  render() {
    const T = OSC
    let { osc, dispatch } = this.props

    if(!osc) {
      osc = {}
    }
    const readPatch = !!osc[READ_PATCH_ENABLED] 

    return (
      <CardSeries>
        <Card>
        <h3 style={{marginBottom: '8px'}}> Figure 53 QLab OSC Connection </h3>
              <TextField 
                style  = {{ width: '144px' }}
                label = { T.get('workspace') }
                hintText = 'my-show-file'
                ref = { x => this.qlabWorkspace = x }
                value = { osc.qlabWorkspace }
                onChange = { (e) => {
                  dispatch( updateOscKey('qlabWorkspace', e.target.value) )
                } }
              />

              <TextField 
                style  = {{ width: '196px' }}
                label = { T.get('qlabHost') }
                hintText = '127.0.0.1:53000'
                ref = { x => this.qlabServer = x}
                value = { osc.qlabServer }
                onChange = { (e) => {
                  dispatch( updateOscKey('qlabServer', e.target.value) )
                } }
              />

              <Button 
                variant='contained'
                color='secondary'
                onClick = { e => {
                  if( confirm( T.get('qlabSyncConfirm') )) {
                    const workspace = osc.qlabWorkspace
                    let parts = (osc.qlabServer || ':').split(':')

                    if(!workspace) {
                      alert('You must specify a workspace')
                      return
                    }

                    if(!parts || !parts.length == 2) {
                      alert('You must specify a server and port. This looks like 127.0.0.1:53000 (localhost:53000)')
                      return
                    }

                    if(!parts[0]) {
                      alert('You must specify a server (look at QLab\'s OSC Settings for the address')
                      return
                    }

                    if(!parts[1]) {
                      alert('You must specify a port (QLab is typically set to 53000)')
                      return
                    }
                    this.props.dispatch( qlabDumpCueList(workspace, parts[0], parts[1], '{page} | {label}') )
                  }
                } }
              >
                { T.get('dumpCueSheet') }
              </Button>

              <span className='status'> 
                { osc.status }
              </span>      

              <Button
                variant='contained'
                onClick= {e => {
                  this.props.dispatch ( showDialog( T.get('qlabIdHelp'), T.get('qlabIdHelpData'), ()=>{}, T.get('ok') ) )
                }}
              >
                { T.get('help') }
              </Button>

        </Card>
      </CardSeries>
    )
  }
}

export default connect(s => {
  const osc = s.osc
  return {
    osc: {
      ...osc,
      eosOscVersion: osc.eosOscVersion ? osc.eosOscVersion : 1.0,
    }
  }
})(QLabConnection)