import {
  READ_PATCH_ENABLED,
  READ_PATCH_UNKNOWN,
  EOS_PATCH_ADD_PARTS_TO_CHANNEL,
  DISCONNECT,
  ERROR,
  OSC_MESSAGE_RECEIVED,
  OSC_MESSAGE_SENT,
  updateStatus,
  updateOscKey,
  liveCueFired,
	liveCueSetFired,
  patchValueRead,
  patchBulkInstruments,
} from './OscLogic'

import {
  loadCues,
  filterOnlyActive
} from './CueLogic'

import {
  showNoteDialog
} from './NotesLogic'

import constants from './../../shared/constants'

import {
  regenerateDownloadXml
} from './../../shared/vectorworks'

import {
  syncWithVectorworks,
} from './InstrumentLogic'

import {
  newShow,
  loadShow,
  saveShow,
} from './ShowLogic'

import { addEvent } from './LogLogic'
import WebLog, {
   createExplicitLogEvent,
   LEVELS
} from './../../shared/WebLog'

import Errors from './../Errors'

import {
  TranslatorFromState
} from './../../shared/language/Translate'

import {
 showDialog,
} from './InterfaceLogic'

/**
 * We have a global IPC passed to the react application to skip require.
 * If NULL then this API isn't available.
 */
const IPC = window.IPC
const log = WebLog('en', 'ElectronLogic::IPC')
//Register Asynchronous Methods
if (IPC) {
  console.log('REGISTERING IPC ON CLIENT')

  //React Redux Listeners
  IPC.on(constants.REDUX_DISPATCH, (event, args) => {
    window.REDUX_STORE.dispatch(args)
    window.REDUX_STORE.dispatch( addEvent( LEVELS.DEBUG, 'ElectronLogic::IPC', 'dispatching event via global {0} {1} ...', args ) )
  })

  IPC.on(constants.REDUX_ERROR, (event, args) => {
    window.REDUX_STORE.dispatch(Errors.reactError(args.title || 'unknownError', args.error))
    window.REDUX_STORE.dispatch( addEvent( LEVELS.ERROR, 'ElectronLogic::IPC', 'dispatching error via global {title} {error} ', args ) )
  })

  IPC.on(constants.REDUX_INFO, (event, args) => {
    let combined = (args.title + ' ' + args.message)
    if (args.level == LEVELS.INFO) {
      window.REDUX_STORE.dispatch(Errors.reactInfo(combined))
      window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'ElectronLogic::IPC', 'dispatching info [' + combined + ']') )
    } else if (args.level == LEVELS.ERROR ){
      window.REDUX_STORE.dispatch(Errors.reactError(combined))
      window.REDUX_STORE.dispatch( addEvent( LEVELS.ERROR, 'ElectronLogic::IPC', 'dispatching info [' + combined + ']') )
    } else if (args.level == LEVELS.WARN) {
      window.REDUX_STORE.dispatch(Errors.reactWarning(combined))
      window.REDUX_STORE.dispatch( addEvent( LEVELS.WARN, 'ElectronLogic::IPC', 'dispatching info [' + combined + ']') )
    } else if (args.level == LEVELS.DEBUG) {
      window.REDUX_STORE.dispatch( addEvent( LEVELS.DEBUG, 'ElectronLogic::IPC', 'dispatching info [' + combined + ']') )
    }
  })

  IPC.on(constants.REDUX_LOG, (event, args) => {
    if (args) {
      if (args.level == LEVELS.ERROR ){
        window.REDUX_STORE.dispatch(Errors.reactError(args.message, args.module))
      } else if (args.level == LEVELS.WARN) {
        window.REDUX_STORE.dispatch(Errors.reactWarning(args.message, args.module))
      }

      window.REDUX_STORE.dispatch( addEvent( args.level, args.module , args.message) )
    }
  })

  IPC.on(constants.REDUX_INFO_DIALOG, (event, args) => {
    window.REDUX_STORE.dispatch(
      showDialog(args.title || 'unknown', args.message || 'unknown')
    )
  })

  //Heartbeat
  IPC.on(constants.ELECTRON_HEARTBEAT, (event, args) => {
    alert('[Asynchronous] Electron Heartbeat ' + args)
    window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'ElectronLogic::IPC', 'Hearbeat -> ' + args) )
  })

  //Data Exchange Listeners
  IPC.on(constants.VW_CHECK_READ_FROM_VECTORWORKS, (event, args)=>{
    window.REDUX_STORE.dispatch(
      createExplicitLogEvent(LEVELS.INFO,
        'ElectronLogic::IPC',
        constants.VW_CHECK_READ_FROM_VECTORWORKS)
    )
  })

  IPC.on(constants.ELECTRON_VW_SYNC_START_EXCHANGE, (event, args) => {

    //Expects (file, mappings, data) or error
    const { file, data, mappings, mode, error} = args
    if( error ) {
      window.REDUX_STORE.dispatch(
        Errors.reactError('[SYNC FAILED] ' + error)
      )
      return
    }


    if(data) {
      const onComplete = (xml) => {
        log.info(`[SYNC-PROCESSED] file:${file} mode:${mode}`)
        const state = window.REDUX_STORE.getState()
        IPC.send(constants.ELECTRON_VW_SYNC_SAVE_DATA, {
          file: file,
          mappings: mappings,
          mode: mode,
          xml: xml,
        })
      }

      window.REDUX_STORE.dispatch( syncWithVectorworks( data, file, onComplete) )
    }

    //Just dispatch updating the new values.
    console('[VW-TEST]', args)
  })

  IPC.on(constants.ELECTRON_VW_SYNC_PROCESS_DATA, (event, args) => {
    console.log('processing data')
    const { data, file, mappings, mode } = args
    log.info(`[SYNC-PROCESS-DATA-CLIENT] data:${!!data}} file: ${file}`)
    const onComplete = (xml) => {
      IPC.send(constants.ELECTRON_VW_SYNC_SAVE_DATA, {
        file: file,
        mappings: mappings,
        mode: mode,
        xml: xml,
      })
    }
    window.REDUX_STORE.dispatch(
      syncWithVectorworks(data, file, onComplete, true/*silent*/ )
    )
  })

  //EOS
  IPC.on(constants.EOS_HEARTBEAT, (event, args) => {
    if(!args.status) {
      //Warn user EOS is disconnected
      window.REDUX_STORE.dispatch( Errors.reactWarning('Lost connection to EOS', 'The connection to EOS has been lost, reconnect in the OSC Integration Module.') )
      window.REDUX_STORE.dispatch( updateOscKey('on', false) )
    } else { //Quietly log
      window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'EOS::Heartbeat', args.status ? 'Eos connected' : 'Eos disconnected') )
      window.REDUX_STORE.dispatch( updateOscKey('on', true) )
    }
  })

  IPC.on(constants.EOS_CONNECT, (event, args) => {
    if(!args.status) {
      //Warn user EOS is disconnected
      window.REDUX_STORE.dispatch( updateOscKey('on', false) )
    } else { //Quietly log
      window.REDUX_STORE.dispatch( updateOscKey('on', true) )
      window.REDUX_STORE.dispatch( updateStatus('Connected to Eos') )
      window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'EOS::Heartbeat', args.status ? 'Eos connected' : 'Eos disconnected') )
    }
  })

  IPC.on(constants.EOS_DISCONNECT, (event, args) => {
    window.REDUX_STORE.dispatch( updateOscKey('on', false) )
    window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'EOS::Heartbeat', 'Eos disconnected') )
  })

  IPC.on(constants.EOS_CUE_FIRED, (event, args) => {
    console.log(`EOS_CUE ${args.cue} L${args.list}`)
    window.REDUX_STORE.dispatch( liveCueFired(args.list, args.cue) )
  })

	IPC.on(constants.EOS_CUE_SET_FIRED, (event, args) => { //args is array
		console.log(`EOS_CUE_SET ${JSON.stringify(args.data)}`)
		window.REDUX_STORE.dispatch( liveCueSetFired(args) )
	})

  IPC.on(constants.EOS_PATCH_CHANGED, (event, args) => {
    const {
      channel,
      address,
      universe,
      label,
      part,
      model,
      manufacturer,
    } = args

    console.log('PATCHING -> {channel}@{universe}/{address}', args)
    window.REDUX_STORE.dispatch(
      patchValueRead(channel, part, universe, address, label, model, manufacturer)
    )
  })

  IPC.on(constants.EOS_PATCH_CHANGED_BULK, (event, args) => {
    if(args && args.patch && args.patch.length > 0) {
      const state = window.REDUX_STORE.getState()
      const addMissing = state && state.osc && !!state.osc[READ_PATCH_UNKNOWN]
      const addPartsToChannel = state && state.osc && !!state.osc[EOS_PATCH_ADD_PARTS_TO_CHANNEL]
      let missingTransformer = null
      if(addMissing) {
        missingTransformer = (inst) => {
          return {
            ...inst,
            position: 'Added From Eos',
            purpose: inst.label,
            color: inst.gel,
            instrumentType: `${inst.manufacturer} ${inst.model}`,
          }
        }
      }
      window.REDUX_STORE.dispatch(
        patchBulkInstruments(args.patch, missingTransformer, addPartsToChannel)
      )
    } else {
      console.log(`EMPTY PATCH ${JSON.stringify(args)}`)
    }
  })

  //MENUES

  IPC.on(constants.ELECTRON_MENU_SAVE, (event, args) => {
    window.REDUX_STORE.dispatch( saveShow() )
    //window.REDUX_STORE.dispatch( saveShow() )
  })

  IPC.on(constants.ELECTRON_MENU_SAVE_AS, (event, args) => {
    alert('NOT IMPLEMENTED--use the hamburger')
    //window.REDUX_STORE.dispatch( saveShow() )
  })

  IPC.on(constants.ELECTRON_MENU_LOAD, (event, args) => {
    alert('NOT IMPLEMENTED--use the hamburger')
    //window.REDUX_STORE.dispatch( loadShow() )
  })

  IPC.on(constants.ELECTRON_MENU_NEW, (event, args) => {
    alert('NOT IMPLEMENTED--use the hamburger')
    //window.REDUX_STORE.dispatch( newShow() )
  })

  IPC.on(constants.ELECTRON_CAPTURE_SCREEN, (event, args) => {
    console.log('SCREEN SHOT RETURNED')
    const url = event.returnValue
  })

  IPC.on(constants.NOTES_NEW_NOTE_FROM_ELECTRON, (evt, args)=>{
    window.REDUX_STORE.dispatch( showNoteDialog() )
  })
}

export const takeScreenShot = () => {
  if(IPC) {
    return IPC.sendSync(constants.ELECTRON_CAPTURE_SCREEN)
  }

  return null
}

export const storeScreenShot = () => {
  const output = takeScreenShot()
  if(output) {
    window.ERROR_SCREEN_SHOT = output
  }
}

export const isConnected = () => {
  return !!(process && IPC)
}

export const electronSave = (data, fileName) => {
  return dispatch => {
    //FIXME Handle Errors here
    IPC.send(constants.ELECTRON_SAVE_DATA, {
      name: fileName,
      data: data
    })
  }
}

export const changeLanguageCode = (code) => {
  if(IPC) {
    IPC.send('LANGUAGE_CODE', {
      code: code
    })
  }
}

//================ New Vectorworks Data Exchange Methods
/**
 * Start an exchange with Vectorworks.
 * @param {*} file the file to use, null prompts a dialog
 * @param {*} mappings the mappings to use
 * @param {*} mode the mode do use for this operation
 * @param {*} exportFieldList the current ExportFieldList
 * @param {*} reselect whether or not to retarget the exchange file. This can be useful for renamed files or merges.
 */
export const startElectronVectorworksExchange = (
  file,
  mappings,
  mode,
  exportFieldList,
  reselect = false
) => {
  return dispatch => {
    IPC.send( constants.ELECTRON_VW_SYNC_START_EXCHANGE, {
      file: file,
      mappings: mappings,
      mode: mode,
      exportFieldList: exportFieldList,
      reselect: reselect,
    })
  }
}

//=================EOS CONNECTION
/**
 * Sends an asynchronous heartbeat, this chimes back
 */
export const eosOscHeartbeat = () => {
  return dispatch => {
    IPC.send(constants.EOS_HEARTBEAT)
    dispatch( {
      type: 'NO-OP',
    } )
  }
}

export const reconnectToEos = (ipAddress, port = 3032, oscMode = '1.0') => {
  return (dispatch, getState) => {
    dispatch(
      updateStatus('connecting to [' + (ipAddress || 'localhost' ) + ']')
    )

    IPC.send(constants.EOS_CONNECT, {
      address: ipAddress,
      port: port,
      mode: oscMode,
      dispatch: dispatch,
    })
  }
}

export const disconnectEos = () => {
  return (dispatch, getState) => {
    dispatch( {
      type: DISCONNECT,
      status: 'Disconnecting Eos',
    })

    IPC.send(constants.EOS_DISCONNECT, {
      dispatch: dispatch,
    })
  }
}

export const rawCommand = (command, server) => {
  return (dispatch, getState) => {
    dispatch({
      type: OSC_MESSAGE_SENT,
      command: command,
      server: server
    })
  }
}

export const eosSyncCueList = (address, port, labelFormat) => {
  return (dispatch, getState) => {
    if(!IPC) {
      return dispatch(Errors.reactError('unableToSync', 'electronNotConnected'))
    }

    const doSync = (cues) => {
      log.info('eosCueSynchronizationStarted')
      let _filtered = filterOnlyActive(cues)
      IPC.send(constants.EOS_SYNC_CUES, {
        cues: _filtered,
        address: address,
        port: port,
        labelFormat: labelFormat
      })
    }
    const cueStore = getState().cues
    if (!cueStore || !cueStore.cues || cueStore.cues.length < 1){
      dispatch ( loadCues( data => {
        console.log('cues -> ' + data)
        doSync( data)
      }) )
    } else {
      doSync(cueStore.cues)
    }
  }
}

export const eosRequestPatch = () => {
  return (dispatch, getState) => {
    if(!IPC) {
      return dispatch(Errors.reactError('unableToSync', 'electronNotConnected'))
    } else {
      log.info(constants.EOS_REQUEST_PATCH)
      IPC.send(constants.EOS_REQUEST_PATCH, {})
    }
  }
}

export const qlabDumpCueList = (workspace, address, port, labelFormat) => {
  return (dispatch, getState) => {
    if(!IPC) {
      return dispatch(Errors.reactError('unableToSync', 'electronNotConnected'))
    }

    const doSync = (cues) => {
      log.info('qlabDumpCueList')
      IPC.send(constants.QLAB_WRITE_GROUPS, {
        cues: cues,
        workspace: workspace,
        address: address,
        port: port,
        labelFormat: labelFormat
      })
    }
    const cueStore = getState().cues
    if (!cueStore || !cueStore.cues || cueStore.cues.length < 1){
      dispatch (loadCues( data => {
        console.log('cues -> ' + data)
        doSync( data)
      }) )
    } else {
      doSync(cueStore.cues)
    }
  }
}
//----PDF Printing
export const printSimpleCueSheet = () => {
  return (dispatch, getState) => {
    if (!IPC) {
      return dispatch(Errors.reactError('requiresElectron', 'electronNotConnected'))
    }
    IPC.send(constants.CUES_PRINT_SIMPLE_PDF, {
      cues: getState().cues.cues
    })
  }
}

//=================QLab Connection

export const ElectronMethods = {
  isConnected: ()=>{
    return !!(process && IPC)
  },
  heartbeat: ()=>{
    const out = IPC.sendSync(constants.ELECTRON_HEARTBEAT_SYNC, new Date().toString())
    window.REDUX_STORE.dispatch( addEvent( LEVELS.INFO, 'ElectronLogic::IPC', 'Hearbeat -> ' + out) )
    return out
  }, heartbeatAsync: ()=>{
    IPC.send(constants.ELECTRON_HEARTBEAT, new Date().toString())
  }
}

const INITIAL_STATE = {
  loaded: false,
  vwPath: null
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type){

    default:
      return state
  }
}
