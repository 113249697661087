export const UPDATE_SETTING = 'SETTINGS_UPDATE_SETTING'

const INITIAL_STATE = {
}

export const updateSetting = (key, value) => {
  if(!key) {
    throw new Error(`[SettingsLogic::updateSeting]inavliid key value pair [{key} : {value}]`)
  }

  return dispatch => {
    dispatch({
      type: UPDATE_SETTING,
      key: key,
      value: value,
    })
  }
}

export const getSheet = (key, settings, defaultSettings) => {
  if(!settings || !settings[key]) {
    return defaultSettings
  }

  return settings[key]
}

export const updateSheet = (key, sheet) => {
  return (dispatch, getState) => {

  }
}

export const updateSheetColumn = (key, column, index) => {

}

export const changeThemeLight = () => {
  return dispatch => dispatch({
    type: 'CHANGE_THEME',
    theme: 'light',
  })
}

export const changeThemeDark = () => {
  return dispatch => dispatch({
    type: 'CHANGE_THEME',
    theme: 'dark',
  })
}

export const changeThemeDarkAmber = () => {
  return dispatch => dispatch({
    type: 'CHANGE_THEME',
    theme: 'darkAmber',
  })
}

/**
 * return the notes tables visibility from the settings or the
 * default values.
 * @param {ApplicationState} state the state
 */
export const getNotesTableVisibilitySettings = (state) => {
  const settings = state.settings || {}
  const notesTableVisibility = settings.notesTableVisibility || []

  if(!notesTableVisibility.length || notesTableVisibility.length < 1) {
    return [
      {
        name: 'department',
        width: 0,
        hidden: false,
      },
      {
        name: 'category',
        width: 0,
        hidden: false,
      },
      {
        name: 'status',
        width: 0,
        hidden: false,
      },
      {
        name: 'priority',
        width: 0,
        hidden: false,
      },
      {
        name: 'day',
        width: 0,
        hidden: false,
      },
      {
        name: 'time',
        width: 0,
        hidden: false,
      },
      {
        name: 'description',
        width: 0,
        hidden: false,
      },
      {
        name: 'relatedChannels',
        width: 0,
        hidden: false,
      },
      {
        name: 'relatedCues',
        width: 0,
        hidden: false,
      }
    ]
  }

  return settings.notesTableVisibility
}

/**
 * Move sawp the note index to a new location
 * @param {*} index 
 * @param {*} up 
 */
const _notesSwapIndex = (index, newIndex) => {

  if(  index == newIndex || //same
       index < 0 || //bad index
       newIndex < 0 //bad target
    ) {
    console.log(`SettingsLogic::_notesSwapIndex index: ${index} == ${newIndex} aborting as no-op`)
    return   
  }

  return (dispatch, getState) => {
    const state = getState()
    const notes = getNotesTableVisibilitySettings(state)

    const last = notes.length - 1
    if( newIndex > last || index > last) {
      console.log(`SettingsLogic::_notesSwapIndex index: ${index} == ${newIndex} for array of length ${notes.length} aborting as no-op`)
      return 
    }

    const copy = notes.slice()
    const a = {
      ...copy[index]
    }
    const b = {
      ...copy[newIndex]
    }
    copy[index] = b
    copy[newIndex] = a

    dispatch( updateSetting('notesTableVisibility', copy) )
  }
}

/** Move the note index up by one */
export const moveNotesIndexUp = (index) => {
  return dispatch => {
    dispatch( _notesSwapIndex(index, index - 1) )
  }
}

/** Move the note index down by one */
export const moveNotesIndexDown = (index) => {
  return dispatch => {
    dispatch( _notesSwapIndex(index, index + 1) )
  }
}

/**
 * Set whether the not index should be hidden or not
 * @param {*} index the index of the field to hide
 * @param {*} hidden true, hidden, false, not hidden
 */
export const setNoteIndexHidden = (index, hidden) => {
  return (dispatch, getState) => {
    const state = getState()
    const notes = getNotesTableVisibilitySettings(state)

    const last = notes.length - 1
    if(index < 0) {
      return 
    }

    if(index > notes.length - 1) {
      return
    }

    const copy = {...notes[index]}
    copy.hidden = !!hidden //cast to bool

    const arrayCopy = notes.slice()
    arrayCopy[index] = copy

    dispatch( updateSetting('notesTableVisibility', arrayCopy) )
  }
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SETTING: {
      const out = {
        ...state,
      }
      out[action.key] = action.value
      return out
    }
    
    default: {
      return state
    }
  }
}