import React from 'react';
import { connect } from 'react-redux';
import translate from './../../shared/language/Translate'
import { general } from './../../shared/language'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Slide,
} from '@material-ui/core'

import { 
  dismissError, 
  dismissSnack,
  reportErrorMessage,
} from './../../src/reducers/InterfaceLogic'

class UiDialogs extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      open: false,
    }
  }

  handleClose(){
    this.props.dispatch( dismissError() );
  }

  render(){
    const T = this.props.strings
    const that = this
    let actions = [
      <Button
        key='close'
        autoFocus
        color='primary'
        onClick={ e => this.handleClose(e) }
      >
        { general.get('close') }
      </Button>
    ]

    const { dispatch } = this.props
    const { title, open, message, error, type } = this.props.error
    const snack = this.props.snack || {}
    let stack = null

    if (error && error.stack) {
      stack = error.stack
      actions = [
      <Button
        key='report'
        color='primary'
        onClick={ e=> {
          this.handleClose(e)
          dispatch(
            reportErrorMessage( this.props.error.error, title, this.props.history )
          )
        } }
      >
        { general.get('report') }
      </Button>
      ,
        <Button
          key='close'
          autoFocus  
          color='primary'
          onClick = { e => this.handleClose(e) }
        >
          { general.get('close') }
        </Button>
      ]
    }
    return (
      <div>
        <Dialog
          mo
          model={false}
          open={ open || false}
          onRequestClose={ e=> this.handleClose(e) }
        >
          <DialogTitle> { title || '' } </DialogTitle>
          <DialogContent>
            <div className='error'>
              <div dangerouslySetInnerHTML={ {
                __html: message || ''
              } }>
              </div>
              
              { error ? 
                <pre className='stacktrace'>
                  {stack}
                </pre> 
                : null 
              }
            </div>
          </DialogContent>

          <DialogActions>
            { actions }
          </DialogActions>
        </Dialog>

        { snack.open && snack.action ?
          <Snackbar 
            open={ snack.open || false }
            transition={ <Slide direction="right" /> }
            message={ snack.message || T.get('unknown') }
            autoHideDuration={ snack.time || 4000 }
            action={ [
              <Button 
                color='secondary'
                onClick={()=>{
                  snack.action()
                  dispatch( dismissSnack() ) 
                }}
              >
                {snack.actionName || '????'}
              </Button>
            ]
            }
            onRequestClose={ () => {
              dispatch( dismissSnack() )
            } }
          />
          :
          <Snackbar 
            open={ snack.open || false }
            message={ snack.message || T.get('unknown') }
            autoHideDuration={ snack.time || 6000 }
            onRequestClose={ e=> {
              dispatch( dismissSnack() )
            } }
          />
        }

      </div>
    );
  }
}

export default connect(s=>{
  return {
    error: s.ui.error || {},
    snack: s.ui.snack || {},
    user: s.user
  }
})(translate('Errors')(UiDialogs))