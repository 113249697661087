import blobStream from 'blob-stream'
import  { 
  broadwaySortFieldsFnWithOverrides,
} from './../../shared/Utility'
import {
  applyVariables 
} from './../../shared/InstrumentsShared'
import { 
  reportGenericInstrument 
} from './../../shared/printing/GenericInstrumentReport'
import {
  reportGenericMultiline
} from './../../shared/printing/GenericMultilineReport'
import {
  loadInstruments,
  createInstrumentSortOverrides,
} from './../reducers/InstrumentLogic'
import {
  showError
} from './../reducers/InterfaceLogic'

import {
  dispatchCreateNotesSort
} from './../reducers/NotesLogic'

import { 
  FilterConditions 
} from './../reducers/ReportsLogic'
/**
 * Renders the report as a PDF
 * @param {*} report the report to render
 * @param {Array<Object>} dataset the dataset unfiltered or sorted (though iti can be filtered)
 * @param {Show} show the show for variables used by the report
 * @param {doNotSort} ignore the sorting if you have processed the report with custom logic (notes)
 * @returns a Promise when this is completed the result for "then" is a `blob` which should be application/pdf
 */
export const createPdfBlob = (report, dataset, show = {}, doNotSort = false) => {
  return new Promise( (resolve, reject) => {
    const run = (unfiltered, sortFunction, filterFunction) => {
      const stream = blobStream()
      
      let dataSource = unfiltered.filter(x => !x.deleted) //eslint-disable-line
      
      if(filterFunction) {
        dataSource.filter( filterFunction)
      }
      if(!doNotSort) {
        if(sortFunction) {
          dataSource.sort( sortFunction )
        }
      }

      // reportGenericInstrument
      reportGenericMultiline(dataSource, stream, report, show).then(
        output => {
          //console.log('output pdf->')
          //console.log(output)
          output.on('finish', ()=>{
            resolve(output.toBlob('application/pdf'))
          })
        }).catch(error => {
          console.log(error)
          reject(error)
        })
    }
  
    if(report.data === 'instruments' || report.data === 'notes') {
      const allSorts = []
      for(const s of report.group) {
        allSorts.push(s)
      }
  
      for (const s of report.sort) {
        allSorts.push(s)
      }
  
      //const sortFn = broadwaySortFieldsFn(...allSorts)
      window.REDUX_STORE.dispatch( (_, getState) => {
        const state = getState()

        const instrumentSorts = createInstrumentSortOverrides(state)
        const sortFn = broadwaySortFieldsFnWithOverrides(false, instrumentSorts, ...allSorts)
  
        const filterFn = ()=> true
        const runSorted = (data) => {
    
          for(let i = 0; i < data.length; i++) {
            data[i] = applyVariables(data[i])
          }
          run(data, sortFn, filterFn)
        }

        runSorted(dataset)

      })
    } else if (report.data === 'cues') {
      run( dataset )
    } else {
      reject('Data Type ${report.data} is not recognized')
    }
  })
  
}

/**
 * A simple enum defining a filter.
 */
export const FilterProps  = Object.freeze({
  NAME: 'name',
  DESCRIPTION: 'description',
  TYPE: 'type',
  VALUE: 'value',
  FIELD: 'field',
  FUNCTION: 'function', //Custom logic
})

export const makeFilterFunction = (report) => {
  if(!report.filters || report.filters.length < 1) {
    return ()=>true
  } 

  //We probably want to sanitize the filters...  
  const functions = []
  for(let filter of report.filters) {
    //Sanitize
    if(  (!filter.function) && (!filter.type || !filter.field || !filter.value) ) {
      console.log(`[FILTERS] Filter ${filter.name} invalid parameters.`)
      console.log(JSON.stringify(filter, null, 2))
      throw new Error(`Filter ${filter.name} invalid parameters.`)
    }

    if(filter.function) {
      functions.push( filter.function )
      continue
    } 

    switch(filter.type) {
      case FilterConditions.EQUAL_TO: {
        console.log(`adding equals filter ${filter.name}`)
        functions.push( (x) => {
          const val = x[filter.field]
          const target = filter.value

          return val == target //soft equals
        })
        break
      }

      case FilterConditions.NOT_EQUAL_TO: {
        console.log(`adding not equal filter ${filter.name}`)
        functions.push( (x) => {
          let val = x[filter.field]
          let target = filter.value

          console.log(`[FILTERS::NOT_EQUAL] ('${val}' != '${target}') ${val != target} => `)
          return val != target //soft not-equals
        })
        break
      }

      case FilterConditions.INCLUDES: {
        console.log(`adding includes filter ${filter.name}`)
        functions.push( (x) => {
          let val = (x[filter.field] || '') + ''
          let target = filter.value + ''

          if( val.indexOf(val) > -1 ) {
            return true
          }
          return false
        })
        break
      }

      case FilterConditions.EXCLUDES: {
        console.log(`adding excludes filter ${filter.name}`)
        functions.push( (x) => {
          const val = (x[filter.field] || '') + ''
          const target = filter.value + ''
          if( val.indexOf(val) > -1 ) {
            return false
          }
          return true
        })
        break
      }
    }
  }


  const master = (x) => {
    for(const fn of functions) {
      const valid = fn(x)
      if(!valid) {
        return false
      }
    }
    return true
  }

  return master
}

export const dispatchCreatePdfBlob = (report, onComplete) => {

  return (dispatch, getState) => {
    const state = getState()
    const show = { ...state.show.show }

    //create filters

    const filterFn = makeFilterFunction(report)

    const runIt = (dataset, skipSorting = false) => {
      const filtered = dataset.filter(filterFn)
      createPdfBlob(report, filtered, show, skipSorting).then(blob => {
        onComplete(blob)

      }).catch(error => {
        console.log(error)
        dispatch( showError('Unable to render Report', 'Failed to render', error) )
      })
    }
    switch(report.data) {
      case 'instruments': {
        dispatch( loadInstruments(null, runIt) )
        break
      }

      case 'cues' : {
        runIt(state.cues.cues.slice())
        break
      }

      case 'notes': {
        dispatch( dispatchCreateNotesSort( runIt, [], report.sort, true, true ) )
        break
      }

      default: {
        alert('dataset ' + report.data + ' is not recognized')
      }
    }
  }
}