import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

class TranslationWrapper extends React.Component {

  render() {
    return (
      <div className='translation'>
        { this.props.children }
      </div>
    )
  }

  getChildContext() {
    const lang = this.props.language || {}
    return {
      currentLanguage: lang.current || 'en'
    }
  }
}

TranslationWrapper.childContextTypes = {
  currentLanguage: PropTypes.string
};

const select = (state)=> {
  return { currentLanguage: state.language.current };
};

export default connect(s => {
  return {
    language : s.language
  };
})(TranslationWrapper);

