import React from 'react'
import {
	CircularProgress
} from '@material-ui/core'

export default (props) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<CircularProgress 
				size={100}
				style={{
					marginBottom: 10,
				}}
			/>
			<h3>{props.label || 'Loading'}</h3>
		</div>
	)
	
}