import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import s from './styles.css'
import { 
  showError, 
  showDialog, 
  showConfirm 
} from './../reducers/InterfaceLogic'
import Standard from './../../components/Layouts/Standard'
import translate from './../../shared/language/Translate'
import Colors from './../Colors'
import { detectShiftEnter } from './../Utility'
import NoteRow from './NoteRow'
import { genericSortFn, alphaNumericCompare } from './../../shared/Utility'

import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Drawer,
  Fab,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
  TextField,
  Tooltip,
  withStyles,
  IconButton,
} from '@material-ui/core'

import {
  makeStyles,
} from '@material-ui/styles'

import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@material-ui/lab'

import LASpeedDial from './../../components/LASpeedDial'

import { 

  analyzeCues, 
  addNote,
  checkLegacy,
  clearAllCompletedNotes,
  clearAllDeletedNotes,
  clearFilter,
  clearSort,
  createSort,
  pushFilter,
  pushSort,
  repairLegacy,
  showDeleted,
  showCompleted,
  showNoteDialog,
  trackOsc,
} from './../reducers/NotesLogic'

import Toggle from './../../components/Button/Toggle'

import NotesPageEditor from './NotesPageEditor'
import NotesPageTable from './NotesPageTable'

import ContentAdd from '@material-ui/icons/Add'
import ContentInsert from '@material-ui/icons/PlaylistAdd'
import IconSettings from '@material-ui/icons/Settings'
import IconClose from '@material-ui/icons/Close'
import IconPlay from '@material-ui/icons/PlayArrow'
import IconNote from '@material-ui/icons/Flag'
import IconPin from '@material-ui/icons/PinDrop'

import IconCompleted from '@material-ui/icons/Check'
import IconDeleted from '@material-ui/icons/DeleteForever'
import IconDeleteCompleted from '@material-ui/icons/RemoveCircle'
import IconVisible from '@material-ui/icons/Visibility'
import IconNotVisible from '@material-ui/icons/VisibilityOff'
import IconSearch from '@material-ui/icons/Search'
import { isEmptyOrWhitespace } from '../../shared/StringUtil'

const NOTE_INDEX = '__INDEX'
const SORTS = [
  'status',
  'priority',
  'date',
  'category',
  'department',
  'cue',
  'channel',
]

    
const cardStyle = {
  display: 'block',
  padding: 20,
  marginBottom: 20,
  borderRadius: 3,
}

const styles = theme => {
	return {
		row: {
			height: 26,
		},
		cell: {
			paddingLeft: 12,
			paddingRight: 4,
		},
		additional: {
			fontStyle: 'italic'
		}
	}
}

const TableCellSlim = withStyles(styles)(props => {
	return (
		<TableCell className={props.classes.cell} colSpan={props.colSpan || 1}>
			{props.children}
		</TableCell>
	)
})

const TableRowSlim = withStyles(styles)((props)=>{
	const { classes, children, ...rest } = props
	return (
		<TableRow className={classes.row} {...rest}>
			{children}
		</TableRow>
	)
})

class TextFieldRef extends React.Component {
  render(){
    return <TextField {...this.props} />
  }
}

class NotesPage extends React.Component {

  constructor(props) {
    super(props)

    const T = props.strings

    this.departments = [
      T.get('assistant'),
      T.get('lighting'),
      T.get('video'),
      T.get('scenery'),
      T.get('costumes'),
      T.get('sound'),
      T.get('management'),
    ]

    this.priorities = [
      'urgent',
      'high',
      'regular',
      'low',
      'minor',
    ]

    this.noteTypes = [
      'typeGeneral',
      'typeCue',
      'typeFocus',
      'typeWork',
      'typePrivate',
    ]

    this.sorts = [
      T.get('status'),
      T.get('category'),
      T.get('department'),
      T.get('priority'),
      T.get('channels'),
      T.get('cue'),
      T.get('date'),
    ]

    this.focusField = null
    const categories = this.departments.slice()
    categories.unshift( 'typeCue' )
    this.state = {
      addNote: {},
      channels:[],
      channelText: '',
      cues:[],
      cueText: '',
      department: props.strings.get('lighting'),
      priority: 'regular',
      noteType: 'typeGeneral',
      cascade: true,
      discardErrors: false,
      trackOsc: false,
      categories: [],
      inactiveCategories: categories,

      //Other stuff
      search: '',
    }

    //this.runSorts()
  }

  search(notes) {
    if(this.state.search && !isEmptyOrWhitespace(this.state.search)){
      let terms = this.state.search
      if(!terms) {
        return notes
      }
      terms = terms.toLowerCase()

      const filterFunc = (note) => {
        if(note) {
          const description = (note.description || '').toLowerCase()
          const department = (note.department || '').toLowerCase()
          const channels = (note.relatedChannels || []).join(' ').toLowerCase()
          const cues = (note.relatedCues || []).join(' ').toLowerCase()
          
          if(description.indexOf(terms) > -1) {
            return true
          }
          if(department.indexOf(terms) > -1) {
            return true
          }
          if(channels.indexOf(terms) > -1) {
            return true
          }
          if(cues.indexOf(terms) > -1) {
            return true
          }
        }
    
        return false
      }
      return notes.slice().filter(filterFunc)
    }

    return notes
  }
  // componentWillReceiveProps(props) {
  //   console.log('NotesPage::componentWillReceiveProps')
  //   const notes = props.notes
  //   this.runSorts()
  // }

  runSorts() {
    console.log('firing run sorts...')
    const notes = this.props.notesObject || {}
    const departments = this.props.filters
    const sorts = this.props.sorts
    const completed = this.props.showCompleted
    const deleted = this.props.showDeleted
    
    const sorted = createSort(notes, departments, sorts, completed, deleted )
    this.setState({
      notes: sorted,
    })
  }

  runSortsOLD(notes){
    const T = this.props.strings
    notes = (notes || []).slice()//create a copy
    for(let i = 0; i < notes.length; i++) {
      notes[i][NOTE_INDEX] = i //volitile index 
    }

    let sorted = []
    const sortedIndex = []
    const filters = []

    //FILTER
    if(filters && filters.length > 0) {
      for(let n of notes) {
        for(let d of filters) {
          if(n.department == d) {
            sorted.push(n)
          }
        }
      }
    } else {
      sorted = notes.slice() //include all
    } 
    
    let sortFns = []
    for(let sort of this.sorts) {
      if (sort == T.get('status')) {
        sortFns.push(
          (a, b)=>{
            return alphaNumericCompare(b.status, a.status)
          }
        )
      } else if (sort == T.get('category')) {
        sortFns.push(
          (a, b)=>{
            return alphaNumericCompare(a.category, b.category)
          }
        )
      } else if (sort == T.get('department')) {
        sortFns.push(
          (a, b)=>{
          return alphaNumericCompare(a.department, b.department)
          }
        )
      } else if (sort == T.get('priority')) {
        sortFns.push(
          (a, b)=>{
          return alphaNumericCompare(b.priority, a.priority)
          }
        )
      } else if (sort == T.get('channels')) {
        //ignored
      } else if (sort == T.get('cue')) {
        //ignored
      } else if (sort == T.get('date')) {
        sortFns.push(
          (a, b)=>{
            return alphaNumericCompare(b.date, a.date)
          }
        )
      }
    }

    if(sortFns.length < 1) { //GENERIC SORT
      sortFns.push( (a, b) => { 
        return alphaNumericCompare(b.status, a.status) //reverse
      } )

      //PRIORITY - FIXME, store a number here
      sortFns.push( (a, b) => {
        return alphaNumericCompare(b.priority, a.priority) //reverse so urgent is top
      } )

      //Department
      sortFns.push( (a, b) => {
        return alphaNumericCompare(a.department, b.department) //reverse so urgent is top
      } )

      //DATE
      sortFns.push( (a, b) => {
        return alphaNumericCompare(b.date, a.date) //reverse so urgent is top
      } )
    }

    const masterSort = (a, b) => {
      let sort = 0

      for(let fn of sortFns) {
        let result = fn(a, b)
        if(result != 0) {
          return result
        }
      }

      return alphaNumericCompare(a[NOTE_INDEX], b[NOTE_INDEX]) //sort by index
    }

    sorted.sort(masterSort)

    //TRACK ONLY INDECIES
    for(let s of sorted) {
      sortedIndex.push(s[NOTE_INDEX])
    }

    this.setState({
      ...this.state,
      sorted: sortedIndex,
      notes: notes,
    })

    const departments = []
    const sorts = []
    const handler = (notes) => {
      // this.setState({

      // })
    }

    //createSort( handler ) )
  }

  tryRemoveCategory(c) {
    this.props.dispatch( clearFilter(c) )
    const categories = this.state.categories.slice()
    const index = categories.indexOf(c)
    if(index > -1) {
      categories.splice(index, 1)
      const inactiveCategories = this.state.inactiveCategories.slice()
      inactiveCategories.push(c)
      this.setState({
        ...this.state,
        categories: categories,
        inactiveCategories: inactiveCategories,
      })
    }
  }

  tryAddCategory(c) {
    this.props.dispatch( pushFilter(c) )

    const categories = this.state.categories.slice()
    const index = categories.indexOf(c)
    if(index < 0) {
        categories.push(c)
        const inactive = this.state.inactiveCategories.slice()
        const inactiveIndex = inactive.indexOf(c)
        if(inactiveIndex > -1) {
          inactive.splice(inactiveIndex, 1)
        }
        this.setState({
          ...this.state,
          categories: categories,
          inactiveCategories: inactive,
        })
    }
  }

  tryRemoveSort(s) {
    this.props.dispatch( clearSort(s) )
    // //Do we need the rest here?
    // const sorts = this.state.sorts.slice()
    // const index = sorts.indexOf(s)
    // if(index > -1) {
    //   sorts.splice(index, 1)
    //   const inactiveSorts = this.state.inactiveSorts.slice()
    //   inactiveSorts.push(s)

    //   this.setState({
    //     ...this.state,
    //     sorts: sorts,
    //     inactiveSorts: inactiveSorts,
    //   })
    // }
  }

  tryAddSort(s) {
    this.props.dispatch( pushSort(s) )
  }

  resetNote() {
    this.setState({
      ...this.state,
      channels: [],
      channelText: '',
      cues: [],
      cueText: '',
      description: '',
      priority: 'regular',
    })

    if(this.focusField && this.focusField.focus) {
      this.focusField.focus()
    }
  }

  handleDepartmentChange(event, index, value) {

  }

  renderCurrentDepartmentText() {
    const T = this.props.strings
    return (
      <React.Fragment>
        { this.props.filters.length < 1 ? <label>{T.get('showingAllCategories')}</label> : <label>{T.get('showingCategories')}</label>}
        { this.props.filters.map( (c, i) => {
          return (
            <Chip
              key={'active'+c}
              label={ T.soft(c) }
              onDelete={()=>{
                this.tryRemoveCategory(c)
              }}
            />
          )
        })}
      </React.Fragment>
    )
  }

  renderCurrentFilterText() {
    const T = this.props.strings
    return (
      <React.Fragment>
        { this.props.sorts.length < 1 ? <label>{T.get('showingDefaultSort')}</label> : <label>{T.get('showingSort')}</label>}
        { this.props.sorts.map( (s, i) => {
          const val = i === 0 ? T.get(s) : (T.get('thenBy') + ': ' + T.get(s))
          return (
            <Chip
              key={'active-sort-'+s}
              label= { val }
              onDelete={()=>{
                this.tryRemoveSort(s)
              }}
            />
          )
        })}
      </React.Fragment>
    )
  }
  renderCueNote(pair, key){
    const { note, cue} = pair
    return (
    <div key={key}>
      
      <div>
        ({ cue.number || '???' })
      </div>

      <div>
        { note.description }
      </div>

      <div>
        { note.completed }
      </div>
    </div>
    )
  }
  
  renderEditor() { 
    const T = this.props.strings
    const textStyle = {
      flex: 1,
      marginRight: '4px'
    }

    const setChannel = () => {
      if(this.state.channelText) {
        const copy = {...this.state}
        copy.channels.push(this.state.channelText)
        copy.channelText = ''
        this.setState(copy)
      }
    }

    const removeChannel = (ch) => {
      const channels = this.state.channels.slice()
      const cut = channels.indexOf(ch)
      if(cut > -1) {
        channels.splice(cut, 1)
      }

      this.setState({
        ...this.state,
        channels: channels,
      })
    }

    const setCue = () => {
      if(this.state.cueText) {
        const copy = {...this.state}
        copy.cues.push(this.state.cueText)
        copy.cueText = ''
        this.setState(copy)
      }
    }

    const removeCue = (ch) => {
      const cues = this.state.cues.slice()
      const cut = cues.indexOf(ch)
      if(cut > -1) {
        cues.splice(cut, 1)
      }

      this.setState({
        ...this.state,
        cues: cues,
      })
    }

    return (
      <div style={{
        display: 'block',
        flex: 1,
      }}>
        <Paper  
          style={{
            ...cardStyle,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <div style={{display:'flex'}}>
              <TextFieldRef
                select 
                style={textStyle}
                label={T.get('department')}
                onChange={ event => {
                  this.setState({
                    department: event.target.value,
                  })
                } }
                value={this.state.department}
              >
                { this.departments.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { d }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>

              <TextFieldRef 
                select
                style={textStyle}
                label={T.get('priority')}
                onChange={ event => {
                  this.setState({
                    priority: event.target.value,
                  })
                } }
                value={this.state.priority}
              >
                { this.priorities.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { T.get(d) }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>
            </div>
            
            <div style={{display:'flex'}}>
              <TextFieldRef 
                style={textStyle}
                multiline
                label={T.get('shortDescription')}
                value={this.state.description}
                ref={ (x)=>{ this.focusField = x} }//callback for focus
                onKeyUp={ (e) => {
                  if( detectShiftEnter(e) ) {
                    setTimeout(()=>{this.tryAddNote()}, 50) //delay
                    e.preventDefault()
                  }
                } }
                onChange={ (e) => {
                  if( detectShiftEnter(e) ) {
                    e.preventDefault()
                    return
                  }
                  this.setState({
                    description: e.target.value,
                  })
                } }
              />

              <TextFieldRef 
                select
                style={textStyle}
                label={T.get('noteType')}
                onChange={ event => {
                  this.setState({
                    ...this.state,
                    noteType: event.target.value,
                  })
                } }
                value={this.state.noteType}
              >
                { this.noteTypes.map( d => {
                  return (
                    <MenuItem value={d} key={d}>
                      { T.get(d) }
                    </MenuItem>
                  )
                })}

              </TextFieldRef>
            </div>

            <div style={{display:'flex'}}>
              <TextFieldRef
                style={textStyle}
                label={ T.get('relatedChannels') }
                onChange={ (e) => {
                  const val = e.target.value
                  this.setState({
                    channelText: val,
                  })
                } }
                onBlur={ (e) => {
                  setChannel()
                }}
                onKeyUp= { (e) => {
                  if(e.charCode === 13 || e.keyCode === 13) {
                    setChannel()
                  }
                }}
                value={this.state.channelText}
              />

              <TextFieldRef
                style={textStyle}
                label={ T.get('relatedCues') }
                onChange={ (e) => {
                  const val = e.target.value
                  this.setState({
                    cueText: val,
                  })
                } }
                onBlur={ (e) => {
                  setCue()
                }}
                onKeyUp= { (e) => {
                  if(e.charCode === 13 || e.keyCode === 13) {
                    setCue()
                  }
                }}
                value={this.state.cueText}
              />          
            </div>

            <div>

            </div>

            <div 
              style={{
                display: 'flex',
              }}
            >
              { this.state.cues.map( (c,i) => {
                return (
                  <Chip 
                    key={c}
                    label={'Cue ' + c}
                    backgroundColor={ Colors.LA_LIGHT_BLUE }
                    onDelete={ () => { removeCue(c) }}
                  />
                )
              })}

              { this.state.channels.map( (c,i) => {
                return (
                  <Chip 
                    key={c}
                    label={'Ch. ' + c}
                    onDelete={ () => { removeChannel(c) }}
                  />
                )
              })}
            </div>
            
            <div 
              style={{
                display: 'flex',
                marginTop:'24px', 
                justifyContent: 'space-between',
              }}
            >
              <div>
                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.cascade }
                      />
                    }
                    label={T.get('cascadeChanges')}
                  />
                </FormGroup>
                
                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.discardErrors }
                      />
                    }
                    label={T.get('discardErrors')}
                  />
                </FormGroup>

                                <FormGroup row>
                  <FormControlLabel 
                    control={
                      <Checkbox 
                        checked={ this.state.trackOsc }
                      />
                    }
                    label={T.get('trackCueListOSC')}
                  />
                </FormGroup>
              </div>
              <div 
                style={{
                  display:'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <div>
                  <Button 
                    variant='outlined'
                    onClick={() => {
                      this.resetNote()
                    }}
                  >
                   { T.get('clear') }
                  </Button>

                  <Button 
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      this.tryAddNote()
                    } }
                  >
                    { T.get('addNote') }
                  </Button>
                </div>
              </div>
            
            </div>          
          </div>

          <div style={{ display:'flex', marginLeft: '24px' }}>
              <div>
                <h3>Hot Keys</h3>
                <p style={{color: 'red'}}>
                    SHIFT+ENTER in the Note Field will submit the note and refocus the Note Field for a new note.
                </p>
                <p>
                  The notes module allows you to take notes "ad hoc" outside 
                  other modules. This is built for speed and with the idea you are in the middle of 
                  tech when taking these notes and you don't have time to sort through
                  endless lists. 
                </p>

                <p style={{color: this.state.cascade ? 'red' : 'inherit' }}>
                  <b>Cascade {this.state.cascade ? 'Enabled' : 'Disabled'}</b> You can attach related channels and related cues
                  to the note and it will cascade those notes to the other modules.
                  In the event the cue or channel can not be found Light Assistant will
                  alert you to the fact and create a general note before savings. 
                </p>

                <p style={{color: this.state.discardErrors ? 'red' : 'inherit' }}>
                  <b>Discard Errors {this.state.discardErrors ? 'Enabled' : 'Disabled'}</b> Warning messages are turned off and unmatched channels or cues are automatically turned into general notes. 
                </p>
                
                <p style={{color: this.state.trackOsc ? 'red' : 'inherit' }}>
                  <b>Osc Tracking {this.state.trackOsc ? 'Enabled' : 'Disabled'} </b> If you are connected via OSC and the <i>Related Cues</i> field 
                  is automatically populated with the last OSC go that matched 
                  a cue in the list. 
                </p>
              </div>
              
          </div>
        
        </Paper>
      </div>
    )
    
  }

  renderFilters() {
    const T = this.props.strings
    let inactiveSorts = SORTS.slice()
    let inactiveFilters = this.departments.slice()
    inactiveFilters.unshift('cue')

    for(let f of this.props.filters) {
      let index = inactiveFilters.indexOf(f)
      if(index > -1) {
        inactiveFilters.splice(index, 1)
      }
    }

    for(let s of this.props.sorts) {
      let index = inactiveSorts.indexOf(s)
      if(index > -1) {
        inactiveSorts.splice(index, 1)
      }
    }

    return (
      <Drawer
        anchor='top'
        open={this.state.showFilterDialog}
        onClose={()=>{
          this.setState({
            showFilterDialog: false,
          })
        }}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{
          flex: 0,
          display: 'flex',
          flexDirection: 'row',
          minHeight: 48,
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 4,
          backgroundColor: window.theme.la_blue,
          boxShadow: Colors.SHADOW_BLACK,
          borderBottom: '1px solid black',
          zIndex: 99,
        }}>
          <div 
            style={{
              flex: 1, 
              display: 'flex', 
              alignItems: 'center', 
              color: window.theme.palette.common.white,
            }}
          >
            <h2 style={{ margin: 0,}}>{T.get('configureSorting')}</h2>
          </div>
          <div style={{flex: 0, paddingTop: 6,}}>
            <Button
              style={{
                color: window.theme.palette.common.white,
              }}
              onClick={e => {
                this.setState({
                  showFilterDialog: false,
                })
              }}
            >
              <IconClose /> { T.get('close') }
            </Button>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            overflowY: 'auto',
            overflowX: 'auto',
          }}
        >
          <Paper style={{
            ...cardStyle,
            marginBottom: 0,
          }}>

            <h3>Filter By</h3>
            <div>
              <div 
                style={{
                  display: 'flex', 
                  flexWrap: 'wrap',
                }}
              >
                { inactiveFilters.map( (c, i) => {
                  return (
                    <Chip
                      key={'inactive'+c}
                      label={ T.soft( c ) }
                      onClick={ (e) => {
                        this.tryAddCategory(c)
                      }}
                    />
                  )
                })}
              </div>
              <hr />
              <div 
                style={{
                  display: 'flex', 
                  flexWrap: 'wrap',
                }}
              >
                { this.renderCurrentDepartmentText() }
              </div>            

              <h3>Sort By</h3>
              <div 
                style={{
                  display: 'flex', 
                  flexWrap: 'wrap',
                }}
              >
                { inactiveSorts.map( (c, i) => {
                  return (
                    <Chip
                      key={'inactive-sort-'+c}
                      label={ T.get(c) }
                      onClick={ (e) => {
                        this.tryAddSort(c)
                      }}
                    />
                  )
                })}
              </div>
              <hr />
              
              <div 
                style={{
                  display: 'flex', 
                  flexWrap: 'wrap',
                }}
              >
                { this.renderCurrentFilterText() }
              </div>          
            </div>
          </Paper>
        </div>
      </Drawer>
    )
  }

  renderRow(note, index) {
    return (
      <NoteRow 
        note={note} 
        key={note.uuid + note.status} 
        index={index} 
        dispatch={this.props.dispatch} 
      />
    )
  }

  renderHeaders() {
    const T = this.props.strings
    const headerStyle = {
      border: '1px solid red',
      backgroundColor: 'orange',
    }
    const rowStyles = {
      row: {
        display: 'flex',
        flexDirection: 'row',
        fontWeight: 'bold',
      },
      status: {
        ...headerStyle,
        minWidth: '96px',
      },
      category: {
        ...headerStyle,
        minWidth: '96px',
      },
      department: {
        ...headerStyle,
        minWidth: '96px'
      },
      priority: {
        ...headerStyle,
        minWidth: '96px'
      },
      channels: {
        ...headerStyle,
        minWidth: '96px'
      },
      cue: {
        ...headerStyle,
        minWidth: '96px',
      },
      date: {
        ...headerStyle,
        minWidth: '96px'
      },
      description: {
        ...headerStyle,
        flex: 1,
      },
    }

    const header = (key, colSpan = 1) => {
      return <TableCellSlim style={rowStyles[key]} colSpan={colSpan}>{ T.get(key) }</TableCellSlim>
    }
    return (
      <TableHead>
        <TableRow>
          { header('department') }
          { header('category') }
          { header('description', 4) }
          { header('channels') }
          { header('cue') }
          
          <TableCellSlim></TableCellSlim>
          <TableCellSlim></TableCellSlim>
          <TableCellSlim></TableCellSlim>
        </TableRow>
      </TableHead>
    )
    return (
      <div style={rowStyles.row}>
        <div style={rowStyles.status}>
          <label >{ T.get('status') }</label>
        </div>

        <div style={rowStyles.category}>
          <label >{ T.get('category') }</label>
        </div>
        <div style={rowStyles.department}>
          <label >{ T.get('department') }</label>
        </div>
        <div style={rowStyles.priority}>
          <label >{ T.get('priority') }</label>
        </div>
        <div style={rowStyles.channels}>
          <label >{ T.get('channels') }</label>
        </div>
        <div style={rowStyles.cue}>
          <label >{ T.get('cue') }</label>
        </div>
        <div style={rowStyles.date}>
          <label >{ T.get('date') }</label>
        </div>
        <div style={rowStyles.description}>
          <label >{ T.get('description') }</label>
        </div>
      </div>
    )
  }

  getQuickActions() {
    const T = this.props.strings
    const props = this.props
    const { dispatch } = this.props
    
    const osc = {
      key:'osc',
      tooltip: T.get('tipOSC'),
      color: props.oscOn && props.trackOsc ? 'secondary' : 'default',
      icon: 'OSC',//<IconPlay />,
      fn: ()=>{
        if(!props.oscOn) {
          dispatch( 
            showDialog(
              T.get('oscNotEnableTitle'),
              T.get('oscNotEnabledMessage'),
            )
          )

          return
        } else {
          dispatch( trackOsc( !props.trackOsc ))
        }
      }
    }

    // const deleted = {
    //   key:'deleted',
    //   fn: ()=>{
    //     props.dispatch( showDeleted( !props.showDeleted ))
    //   },
    //   color: this.props.showDeleted ? 'primary' : 'default',
    //   icon: <IconDeleted />,
    //   tooltip: T.get('tipShowDeleted'),
    // }

    let completedAndDeleted = {
      key: 'completed',
      tooltip: T.get('tipShowCompleted'),
      color: props.showCompleted ? 'primary' : 'default' ,
      icon: props.showCompleted ? <IconVisible /> : <IconNotVisible />,
      fn: () => {
        props.dispatch( 
          showCompleted( !props.showCompleted )
        )
        props.dispatch(
          showDeleted( !props.showDeleted )
        )
      }
    }

    const takeNote = {
      key: 'takeNote',
      tooltip: T.get('takeNote'),
      color: 'secondary',
      icon: <IconNote />,
      fn: () => {
        props.dispatch( showNoteDialog({}) )
      }
    }

    // const removeCompleted = {
    //   key: 'removeCompleted',
    //   tooltip: T.get('tipRemoveCompleted'),
    //   color: 'secondary',
    //   icon: <IconDeleteCompleted />,
    //   fn: () => {

    //     props.dispatch( 
    //       (d, getState) => {
    //         const state = getState()
    //         const notes = state.notes.notes

    //         const countCompleted = Object.values(notes).filter(x=>x.status == 'completed').length
    //         const countDeleted = Object.values(notes).filter(x=>x.status == 'deleted').length
    //         d( 
    //           showConfirm(
    //             T.get('removeAllDeletedTitle'),
    //             T.get('removeAllCompletedBody', {completed: countCompleted, deleted: countDeleted }),
    //             ()=>{
    //               props.dispatch( clearAllCompletedNotes() )
    //               props.dispatch( clearAllDeletedNotes() )
    //             },
    //             null,
    //             T.get('delete')
    //           ) 
    //         )
    //       }
    //     )
    //   }
    // }

    const actions = [
      takeNote,
      osc,
      completedAndDeleted,
      //removeCompleted,
    ]

    return actions
  }

  renderSpeedDial() {
    
    const actions = this.getQuickActions()

    return (
      <LASpeedDial
        actions={actions}
      >
      </LASpeedDial>
    )
  }

  renderTopControls() {
    const actions = this.getQuickActions()

    // fn: ()=>{
    //   props.dispatch( showDeleted( !props.showDeleted ))
    // },
    // color: this.props.showDeleted ? 'primary' : 'default',
    // icon: <IconDeleted />,
    // tooltip: T.get('tipShowDeleted'),
    const renderAction = (action)=>{
      return (
        <Tooltip
          title={action.tooltip}
        >
          <Fab
            size='small'
            style={{
              marginLeft: 8,
            }}
            color={action.color || 'default'}
            onClick={action.fn}
          >
            {action.icon}
          </Fab>
        </Tooltip>
      )
    }
    const T = this.props.strings
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <div
            style={{
              flex: 1,
              marginRight: 8,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Tooltip
            title={T.get('takeNote')}
            >
              <Button 
                size='small'
                variant='outlined'
                onClick={()=>{
                  this.props.dispatch( showNoteDialog() )
                }}

              >
                <IconNote /> {T.get('newNote')}
              </Button>
            </Tooltip>

            {actions.map((a,i) => {
              if(i == 0) {
                return null //skip 'take a note'
              }
              return renderAction(a)
              } 
            )}
          </div>
          <div
            style={{
              flex: 0,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextField
              size='small'
              style={{
                minWidth: 150,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    { this.state.search ? (
                        <IconButton
                          size='small'
                          onClick={e => {
                            this.setState({
                              search: '',
                            })
                          }}
                        >
                          <IconClose />
                        </IconButton>
                      ) 
                      : <IconSearch />
                    }
                  </InputAdornment> 
                )
              }}
              label='Search Notes'
              value={this.state.search}
              onChange={e => {
                const newVal = e.target.value
                this.setState({
                  search: newVal
                })
              }}
            />

            <Button
              variant='outlined'
              style={{marginLeft:8}}
              onClick={()=>{
                this.setState({
                  showFilterDialog: true
                })
              }}
            >
              { T.get('filters') }
              <IconSettings />
            </Button>
          </div>
          
        </div>
        
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            marginTop: 8,
          }}
        >

        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          {/* <IconButton
            size='small'
          >
            <IconCompleted />
          </IconButton>   */}
          
  
        </div>

        {/* CONTENT ON THE RIGHT */}
        
        <div 
          style={{
            marginLeft: 8,
            flex: 1,
            display: 'flex',
            //whiteSpace: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          { this.renderCurrentDepartmentText() }
          &nbsp;
          { this.renderCurrentFilterText() }
        </div>

        
      </div>
    )
  }

  render() {
    const T = this.props.strings

    if(this.props.repair) {

      this.props.dispatch( repairLegacy() )
      return (
        <Standard key={this.props.language}>
          <CircularProgress size='large' />
          <label>{T.get('repairingLegacyFormat')}</label>
        </Standard>
      )
    }

    const { dispatch, cue, notes }  = this.props

    const head = ['a','b','c','d','e','f','g','h','i']
    const tables = [1,2,3,4,5]
    const body = []
    
    //OSC AND MENU
    //reusable toggles
    const OSC_ENABLED = this.props.trackOsc && this.props.oscOn
    let __offset = 0 //base 64
    const offset = (override) => {
      if(override) {
        __offset = 4
      }
      __offset += 48
      return __offset
    }
    const fabs = () => {
      return (
        <React.Fragment>
          <Tooltip title={T.get('tipSettings')}>
            <Fab
              style={{
                position: 'absolute',
                top: offset('start'),
                right: 48,
                zIndex: 51,
              }}
              size='small'
              color='primary'
              onClick={ () => alert('NOT IMPLEMENTED') }
            >
              <IconSettings /> 
            </Fab>
          </Tooltip>
          

          <Tooltip title={T.get('tipOSC')}>
            <Fab
              style={{
                position: 'absolute',
                top: offset(),
                right: 48,
                zIndex: 51,
              }}
              size='small'
              color={ this.props.oscOn && this.props.trackOsc ? 'secondary' : 'default'}
              onClick={ () => {
                if(!this.props.oscOn) {
                  dispatch( 
                    showDialog(
                      T.get('oscNotEnableTitle'),
                      T.get('oscNotEnabledMessage'),
                    )
                  )

                  return
                } else {
                  dispatch( trackOsc( !this.props.trackOsc ))
                }

                

              } }
            >
              { !this.props.trackOsc ? <span style={{textDecoration: 'line-through'}}>OSC</span> : <span>OSC</span> }
            </Fab>
          </Tooltip>
          
          <Tooltip title={T.get('tipShowCompleted')}>
            <Fab
              style={{
                position: 'absolute',
                top: offset('start'),
                right: 4,
                zIndex: 51,
              }}
              size='small'
              color={ this.props.showCompleted ? 'secondary' : 'default'}
              onClick={ () => {
                this.props.dispatch( showCompleted( !this.props.showCompleted ))
              } }
            >
              <IconCompleted />
            </Fab>
          </Tooltip>
          
          <Tooltip title={T.get('tipShowDeleted')}>
            <Fab
              style={{
                position: 'absolute',
                top: offset(),
                right: 4,
                zIndex: 51,
              }}
              size='small'
              color={ this.props.showDeleted ? 'secondary' : 'default'}
              onClick={ () => {
                this.props.dispatch( showDeleted( !this.props.showDeleted ))
              } }
            >
              <IconDeleted />
            </Fab>
          </Tooltip>
        </React.Fragment>
      )
    }

    let lastDepartment = 'startup-lightassistant'
    const notesFiltered = this.search(notes)
    return (
      <Standard key={this.props.language}>
        {/* { fabs() } */}
        { this.renderSpeedDial() }
        { this.renderFilters() }

        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          flex: 1,
          height: '100%',
        }}
        >
          {/* <div
            style={{
              flex: 0,
              minWidth: '400px',
              width: '25%',
            }}
          >
            <NotesPageEditor />

            { this.renderFilters() }
          </div> */}

          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              minWidth: '400px',//maybe more?
              height: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 8,
                marginRight: 64,
              }}
            >
              { this.renderTopControls() }
            </div>
            {/* Render new Table */}
            <div
              style={{
                flex: 2,
                overflow: 'scroll',
              }}
            >
              <NotesPageTable notes={notesFiltered} />
            </div>
          </div>
        </div>
      </Standard>
    )
  }
}

export default connect(s=>{

  //LEGACY REPAIR CHECK
  if( checkLegacy(s.notes.notes) ){
    return {
      repair: true,
    }
  }

  console.log('NotesPage::connect fired')
  const sorted = createSort(
      s.notes.notes, 
      s.notes.filters, 
      s.notes.sorts, 
      s.notes.showCompleted, 
      s.notes.showDeleted
  )

  return {
    cue: s.notes.cue,
    notes: sorted || [],
    language: s.language.current,
    sorts: s.notes.sorts,
    filters: s.notes.filters,
    showDeleted: s.notes.showDeleted,
    showCompleted: s.notes.showCompleted,

    //OSC TRACKING
    trackOsc: !!s.cues.trackOsc,
    oscCue: s.osc.cue,
    oscList: s.osc.list,
    oscOn: !!s.osc.on,
  }
})(translate('Notes')(NotesPage))