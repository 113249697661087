//import PDFDocument from 'pdfkit'

import { createPositionList } from '../ReportHelpers'
//import blobStream from 'blob-stream'
/**
 * Print a simplified cue sheet
 * @param {Array of Cue Objects} cues the cues to write
 * @param {Stream} stream the stream to write to
 * @param {Object} _settings an object of settings, if null a default setting is used
 * {
 *   columns: [
 *    {
 *      key: propertyName, //the key to write
 *      width: 96, //number in points
 *      wrap: true | false | default = true
 *    }
 *    , ...
 *   ],
 * }

 * @returns a Promise that resolves in a PDF document
 */
export const spotOverview = (cues, stream, _settings, show) => {
  const CUE_INFO = 'cue'
  const CUE_IMAGE_SET = 'images'
  const NOTE = 'note'
  const SPOTS = 'spot'
  const DEFAULT_FONT = 'Helvetica'
  const DEFAULT_BOLD = 'Helvetica-Bold'
  const DEFAULT_ITALIC = 'Helvetica-Oblique'
  const DEFAULT_BLUE = show.color || '#0D47A1'
  const DEFAULT_FONT_SIZE = 12
  const positions = createPositionList(show)
  
  return new Promise( (resolve, reject) => {
    try {
      let doc = new PDFDocument({
        bufferPages: true
      })
      doc.fontSize(12)
      doc.font(DEFAULT_FONT)
      //console.log(stream)
      let output = doc.pipe(stream)
  
      let page = 1
      let cue = 0
      let numRows = 20

      //468 on normal margins
      const spotWidth = 128
      const spotHeight = 63
      const settings = _settings == null ? {
        header: 40,
        footer: 20,
        show: {
          title: show.currentShow || 'Untitled'
        },
        positions: positions,
        columns: [{
            key: 'number',
            width: 32,
            fontSize: 12,
            font: 'Helvetica-Bold',
            align: 'left',
            formatter: (num) => {
              if(num === undefined || num === null || num.length < 1) {
                return ''
              }
              
              return '(' + num + ')'
            },
          }, {
            key: 'page',
            width: 64,
            fontSize: 12,
            font: 'Helvetica',
            align: 'left',
            formatter: (pg) => {
              if(!pg) {
                return ''
              } else {
                return pg
              }
            }
          }
          ,{
            key: 'label',
            width: 144,
          }, {
            key: 'description',
            width: 300,
          }
        ]
      } : _settings

      const columns = settings.columns
      const margins = { //18 is the minimum standard we can expect a printer to use
        top: 18,
        left: 18,
        right: 18,
        bottom: 18.
      }
      const pageSize = { width: doc.page.width, height: doc.page.height }
      const position = {x: doc.x, y: doc.y}
      const availableSize = { 
        width: pageSize.width - margins.left - margins.right, 
        height: pageSize.height - margins.top - margins.bottom
      }

      const bodySize = {
        width: availableSize.width,
        height: availableSize.height - settings.header - settings.footer,
        start: settings.header || 0
      }

      const lineSpacing = 2
      //console.log(doc)
      console.log(`margins -> ${JSON.stringify(margins)}`)
      console.log(`size -> ${JSON.stringify(pageSize)}`)
      console.log(`position -> ${JSON.stringify(position)}`)
      console.log(`available -> ${JSON.stringify(availableSize)}`)

      let sections = []
      let section = null

      const newSection = (title) => {
        section = {
          height: 20 + lineSpacing, //minimum
          header: null,
          title: title,
          rows: [],
        }
        sections.push(section)
      }

      const addRow = (type, data, height) => {
        //Layout guides
        section.rows.push({
          type: type,
          data: data,
          height: height
        })

        section.height += height + lineSpacing
      }

      newSection('showNameTbd')

      /**
       * Warning, this function mutates the document object 
       * and could, potentially, cause drawing failures.
       * 
       * @param {*} text 
       * @param {*} width 
       * @param {*} size 
       * @param {*} font 
       */
      const calcTextSize = (text, width, size, font) => {
        doc.fontSize(size || DEFAULT_FONT_SIZE)
        doc.font(font || DEFAULT_FONT)
        const out = doc.heightOfString(text, { width: width })
        return out
      }

      const defaultFormatter = x =>{ 
        if (x === undefined || x === null) {
          return '-'
        } else {
          return x
        }
      }

      for (let cueIndex = 0; cueIndex < cues.length; cueIndex++) {
        const cue = cues[cueIndex]
        let cueHeight = 0 //maximum vertical space for this cue

        //FIRST CUE
        if(cueIndex === 0) {
          section.title = cue.headerText //use this cue's title
        } else {
          if(cue.header) { //start a new section
            newSection(cue.headerText)
          }
        }

        //Header Portion
          //Not implemented
          //Images
        let fakeCue = ''
        //Basic Cue Information
        for(let i = 0; i < columns.length; i++) {
          const col = columns[i]
          let formatter = col.formatter || defaultFormatter
          let key = col.key
          let val = formatter(cue[key])
          fakeCue += ' ' + val
          const textHeight = calcTextSize(val, columns[i].width, col.fontSize, col.font)
          cueHeight = Math.max(cueHeight, textHeight)
        }

        fakeCue += ' | height -> ' + cueHeight 
        //FIXME add the other parts as needed
        addRow(CUE_INFO, cue, cueHeight)
        console.log(fakeCue)

        //Run spots
        if(cue.showSpots) {
          //console.log('adding spots for ' + cue.number)
          addRow(SPOTS, cue, spotHeight)
        }
        //Notes portion
          //Not implemented
        //Add pagination logic
        //console.log('Cue -> ' + cues[cue])
      }
      
      console.log(`Sections ${sections.length}`)
      for(let i = 0; i < sections.length; i++) {
        console.log(`\tSection: ${sections[i].title} Rows: ${sections[i].rows.length} Height: ${sections[i].height} Pages: ${sections[i].height / bodySize.height}`)
      }

      //----------------------------------
      // WRITE THE PDF
      //----------------------------------
      const writeSectionHeader = (text, x, y, width, height) => {
        doc
          .font(DEFAULT_BOLD)
          .fontSize(14)

        const titleHeight = doc.heightOfString('_Demo', { 
            width: 400
          })

        const titleOffsetY = (height - titleHeight) / 2 + 3
        console.log(`titleOffsetY: ${titleOffsetY}`)
        doc
          .rect(x, y, width, height)
          .fill(show.color || '#0D47A1')

        doc
          .fillColor('white')
          .font(DEFAULT_BOLD)
          .fontSize(14)
          .text(text || 'No Title', x + 5, y + titleOffsetY, {
            width: width,
            ellipsis: true,
            align: 'left',
          })
      }

      //Write pages
      let dY = 0
      doc.translate(margins.left, margins.top + (settings.header || 0)) //respect the margins
      doc.margins = margins
      doc.save()
      //Write the page number, header, etc... 
      for(let s = 0; s < sections.length; s++) {
        let section = sections[s]
        //Write header

        const measurement = section.height + dY + 20 //section padding
        if(s > 0 && measurement > bodySize.height) {
          dY = 0
          doc.restore()
          doc.addPage(margins)
          doc.translate(margins.left, margins.top + (settings.header || 0))
          doc.margins = margins
          doc.save()
        }

        writeSectionHeader(section.title, 0, dY, bodySize.width, 20)

        dY += 20 + lineSpacing
        
        for(let i = 0; i < section.rows.length; i++) {
          let row = section.rows[i]
          
          if (row.type == CUE_INFO) { //Rendering Cues
            let dX = 0
            
            const nextHeight = dY + row.height + lineSpacing
            if(nextHeight > bodySize.height) {
              console.log(`
              Breaking here for the following
              dY: ${dY}
              bodySize: ${JSON.stringify(bodySize)}
              nextHeight: ${nextHeight}
              `)
              doc.restore()
              doc.addPage()
              doc.translate(margins.left, margins.top + (settings.header || 0)) 
              doc.margins = margins
              doc.save()
              writeSectionHeader(section.title + '(continued...)', 0, 0, bodySize.width, 20)
              dY = 20 + lineSpacing
            }
  
            for(let c = 0; c < columns.length; c++) {
              let col = columns[c]
              let formatter = col.formatter || defaultFormatter
              const text = formatter( row.data[col.key] )
              doc
                .fillColor(col.color || 'black')
                .font(col.font || DEFAULT_FONT)
                .fontSize(col.fontSize || DEFAULT_FONT_SIZE)
                .text( text, dX, dY, {
                  width: col.width,
                  height: row.height + 0.2,
                  color: col.color || 'black',
                  align: col.align || 'left',
                } )
              
                console.log(`WRITING ROW ${c} [${col.width},${row.height}] | ${text}`)
              dX += col.width
            }
  
            //Line after cue data
            if(!row.data.showSpots) {
              const lineY = dY + row.height - 1
              doc
                .moveTo(0, lineY)
                .lineTo(bodySize.width, lineY)
                .stroke('#ddd')
            }
            
            dY += row.height
            dY += lineSpacing
            //console.log(`${row.data.number} | Moving to points [${0}, ${dY}]`)
          } else if (row.type == SPOTS) {
            console.log('spot cue here...')
            // dY = row.height + lineSpacing
            doc
              .moveTo(0, dY)

//RENDER LABELS
              doc
                .fillColor(DEFAULT_BLUE)
                .font(DEFAULT_ITALIC)
                .fontSize(10)
                .text( 'SPOT', 12, dY, {
                  width: 48,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
              doc
                .fillColor(DEFAULT_BLUE)
                .font(DEFAULT_ITALIC)
                .fontSize(10)
                .text( 'PICKUP', 12, dY + 12, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
              doc
                .fillColor(DEFAULT_BLUE)
                .font(DEFAULT_ITALIC)
                .fontSize(10)
                .text( 'COLOR', 12, dY + 24, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )                
              doc
                .fillColor(DEFAULT_BLUE)
                .font(DEFAULT_ITALIC)
                .fontSize(10)
                .text( 'SIZE', 12, dY + 36, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )                
              doc
                .fillColor(DEFAULT_BLUE)
                .font(DEFAULT_ITALIC)
                .fontSize(10)
                .text( 'LOCATION', 12, dY + 48, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )   
//RENDER SPOTS

const spotOffset = 80
            for(let i = 0; i < row.data.spots.length && i < 4; i++) {
              doc
                .fillColor('black')
                .font(DEFAULT_BOLD)
                .fontSize(10)
                .text( `Spot ${i + 1}`, spotOffset + spotWidth * i, dY, {
                  width: 48,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
              doc
                .fillColor('black')
                .font(DEFAULT_FONT)
                .fontSize(10)
                .text( row.data.spots[i].pickup, spotOffset + spotWidth * i, dY + 12, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
              doc
                .fillColor('black')
                .font(DEFAULT_FONT)
                .fontSize(10)
                .text( row.data.spots[i].frame, spotOffset + spotWidth * i, dY + 24, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )                
              doc
                .fillColor('black')
                .font(DEFAULT_FONT)
                .fontSize(10)
                .text( row.data.spots[i].size, spotOffset + spotWidth * i, dY + 36, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )                
              doc
                .fillColor('black')
                .font(DEFAULT_FONT)
                .fontSize(10)
                .text( row.data.spots[i].location, spotOffset + spotWidth * i, dY + 48, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )                
            }

            const lineY = dY + row.height - 1
            doc
              .moveTo(0, lineY)
              .lineTo(bodySize.width, lineY)
              .stroke('#ddd')

            dY += spotHeight
            dY += lineSpacing
          }
        } 
      }

      //Add Page Numbers
      let range = doc.bufferedPageRange()
      doc.restore()
      for(let i = range.start; i < range.count; i++) {
        doc.switchToPage(i)
        doc.translate(0, -settings.header) //This feels weird, but seems to work.
        //Write Header
        doc
          .fillColor('black')
          .font(DEFAULT_BOLD)
          .fontSize(24)
          .text( `Spot Cues ${settings.show.title}`, 0, 0, {
            width: bodySize.width,
            height: settings.header,
            color: 'black',
            align: 'left',
          } )

        if (settings.positions && settings.positions.length) {
          for(let p = 0; p < settings.positions.length; p++) {
            doc
            .fillColor('black')
            .font(DEFAULT_FONT)
            .fontSize(DEFAULT_FONT_SIZE)
            .text( 
              settings.positions[p], 
              bodySize.width - 200, p * 15, {
              width: 200,
              height: settings.header || 40,
              color: 'black',
              align: 'right',
            } )
          }
        }
        
        
        //Write Footer
        doc
          .fillColor('black')
          .font(DEFAULT_FONT)
          .fontSize(DEFAULT_FONT_SIZE)
          .text( `Page ${i + 1} of ${range.count}`, bodySize.width - 128, availableSize.height - settings.footer, {
            width: 128,
            height: 20,
            color: 'black',
            align: 'right',
          } )
      }

      doc.flushPages()
      doc.end()
      resolve(output)
    } catch (error) {
      reject(error)
    }
  })
}