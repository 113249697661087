import dark from './dark'

export default {
  ...dark,
  palette: {
    type: 'dark',
    common: {
      white: 'rgba(255, 117, 0)',
      black: '#000',
    },
		primary: {
      main: 'rgb(255, 117, 0)',
		},
		secondary: {
      main: 'rgb(255, 30, 0)',
		},
		text: {
			primary: 'rgba(255, 117, 0, 0.87)',
			secondary: 'rgba(255, 117, 0, 0.60)',
			disabled: 'rgba(255, 117, 0, 0.4)',
      hint: 'rgba(255, 117, 0, 0.4)',
      icon: 'rgba(255, 117, 0, 0.4)',
    },
  },
  navBar: 'rgb(93,34,0)',
  la_blue: 'rgb(93, 34, 0)',
  la_blue_half: 'rgba(93, 34, 0, 0.5)',
  boxShadowPrimary:  'rgba(93, 34, 0, 0.5) 0px 1px 6px, rgba(93, 34, 0, 0.5) 0px 1px 4px',
  hover: 'rgb(255, 117, 0)',
  highlight: 'rgba(256, 0, 0, 0.25)',
  editor: 'rgb(105, 64, 0)',
  editorBorder: 'rgba(196, 100, 0, 1.0)',
}