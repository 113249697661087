import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  TextField,
} from '@material-ui/core'

class SelfContainedText extends React.Component {
  constructor(props) {
    super(props) 
    this.state = {
      value: props.value
    }

    this.getValue = this.getValue.bind(this)
  }

  getValue(){
    return this.state.value
  }

  clear() {
    this.setState({
      ...this.state,
      value: ''
    })

    this.value = ''
  }

  render() {
    const { onChange, ...rest} = this.props
    return (
      <TextField 
        {...this.props}
        value = {this.state.value}
        onChange = { e => {
          if(onChange) {
            onChange(e)
          }

          this.setState({
            ...this.state,
            value: e.target.value
          })

          this.value = e.target.value //expose to other components
        } }
      />
    )
  }
}

SelfContainedText.propTypes = {
  clear: PropTypes.func,
  getValue: PropTypes.func,
}

export default SelfContainedText