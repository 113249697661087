import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography ,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Colors from './../src/Colors'

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  flexTitle: {
    flex: 1,
    paddingLeft: '24px',
  }
}

class FullScreenDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open || false,
    }
  }

  handleOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    })
  }

  handleClose = () => {
    if(this.props.onClose) {
      this.props.onClose()
    }
    this.setState({
      ...this.state,
      open: false,
    })
  }

  render() {
    const classes = this.props
    return (
      <Dialog
        fullScreen
        open = { this.state.open }
        onClose = { this.handleClose }
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 0,
            minHeight: 48,
            backgroundColor: this.props.color || Colors.LA_BLUE,
            alignItems: 'center',
            color: Colors.WHITE,
            boxShadow: Colors.SHADOW_HEADER,
          }}
        >
            { this.props.closeText ? 
              <Button 
                color='inherit' 
                onClick={this.handleClose} 
                aria-label='Close'
              >
                <CloseIcon />
                { this.props.closeText }
              </Button>
             : 
              <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
             }
            
            <Typography variant="title" color="inherit" className={classes.flexTitle}>
                { this.props.title || 'NO TITLE' }
            </Typography>
            <div
              style={{flex: 1}}
            >
            {/* SPACER */}
            </div>
            { this.props.topActions }
        </div>
        <Paper
          style={{
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
          }}
        >
          { this.props.children }
        </Paper>
      </Dialog>
    )
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeText: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  topActions: PropTypes.node,
  onClose: PropTypes.func,
}

export default withStyles(styles)(FullScreenDialog)
