export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

const INITIAL_STATE = {
  current: 'en'
};

export const changeLanguage = (code)=>{
  return dispatch => {
    dispatch({ 
      type: CHANGE_LANGUAGE,
      code: code
    });
  };
};

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    case CHANGE_LANGUAGE:
      console.log('CHANGING TO ' + action.code);
      return Object.assign({}, state, { current : action.code});
   }

  return state;
};