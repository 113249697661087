  //EOS
  export default {
    eosAlreadyConnected: 'OSC Connected',
    eosAlreadyConnectedMessage: '<p>A connection to the EOS server is already active. If you are experiencing errors, please disconnect and reconnect.</p>',
    eosConnected: 'Successfully Connected to EOS',
    eosConnectedMessage: `
    <p>
      Light Assistant has successfully connected to EOS at {host} 
      version {oscVersion}. Light Assistant will make every effort
      to maintain this connection, but you can reconnect in the Integration
      module at any point.
    </p>
  
    <p>
      Modules that interface with OSC will have the features turned on by default
      but you can toggle these options on and off using the OSC button located
      in the upper right hand corner. 
    </p>
    `,
  }
  