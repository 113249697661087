import React from 'react'
import { connect } from 'react-redux'
import { general, vectorworks as Strings } from '../../shared/language'
import Colors from './../Colors'
import constants from './../../shared/constants'
import { syncWithVectorworks,  } from '../reducers/InstrumentLogic'
import { purgeInstruments } from '../reducers/ShowLogic' 
import { 
  regenerateDownloadXmlAndDownload,
  setExportFieldList,
} from './../reducers/VectorworksCache'
import { 
  readExportFieldList 
} from '../../shared/vectorworks'
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core'
import IconSync from '@material-ui/icons/Sync'
import IconHelp from '@material-ui/icons/Help'
import IconSettings from '@material-ui/icons/Settings'

import { showDialog, showConfirm, showError } from '../reducers/InterfaceLogic'
import {
  isConnected,
  startElectronVectorworksExchange,

} from '../reducers/ElectronLogic'

import MappingsFullScreen from './../modules/MappingsFullScreen'

class SyncModule extends React.Component {

  constructor(props) {
    super(props)

    this.renderWeb = this.renderWeb.bind(this)
    this.renderElectron = this.renderElectron.bind(this)
    this.renderMenu = this.renderMenu.bind(this)

    this.state = {
      menu: false,
      mappings: false,
    }
  }

  openMenu() {
    this.setState({
      ...this.state,
      menu: true,
      mappings: false,
    })
  }

  closeMenu() {
    this.setState({
      ...this.state,
      menu: false,
      mappings: false,
    })
  }

  openMappings(onCloseFunc) {
    this.setState({
      ...this.state,
      menu: false,
      mappings: true,
      mappingsCloseFunc: onCloseFunc,
    })
  }
  
  renderMenu( isElectron = false ) {
    const T = this.props.strings
    const { dispatch, sync, vectorworks } = this.props
    const mappings = {}

    return (
      <Menu
        open={Boolean(this.state.menu)}
        onClose={ _ => this.closeMenu() }
        anchorEl={ this.menuAnchor }
      >
        { isElectron ? 
          <MenuItem 
            onClick={e => {
              this.closeMenu()
              const onOk = () => {
                dispatch( 
                  startElectronVectorworksExchange(
                    vectorworks.fileName, 
                    mappings, 
                    constants.SYNC_MODES.CHANGES, 
                    vectorworks.ExportFieldList || {},
                    true) )
              }
              dispatch( showConfirm(Strings.get('syncExplainNew'), Strings.get('syncExplainNewBody'), onOk, null, Strings.get('syncConfrimNew') ) )
            }}
          >
            <ListItemText>
              { Strings.get('syncExplainNew') }
            </ListItemText>
          </MenuItem>
          : null 
        }

        <MenuItem 
          onClick={e => {
            this.closeMenu()
          }}
        >
          <ListItemText>
            { Strings.get('syncExplainExportAll') }
          </ListItemText>
        </MenuItem>

        <MenuItem 
          onClick={e => {
            this.closeMenu()
            
            dispatch( showDialog() )
          }}
        >
          <ListItemText>
            { Strings.get('syncExplainSyncWithVectorworks') }
          </ListItemText>
        </MenuItem>

        <MenuItem 
          onClick={e => {
            this.closeMenu()

            const onOk = () => {
              dispatch( purgeInstruments() )
            }

            dispatch( showConfirm(Strings.get('syncExplainClear'), Strings.get('syncExplainClearBody'), onOk, null, Strings.get('syncExplainClearConfirm') ) )                
            
          }}
        >
          <ListItemText>
            { Strings.get('syncExplainClear') }
          </ListItemText>
        </MenuItem>

        <MenuItem 
          onClick={e => {
            this.openMappings()

            // const onOk = () => {
            //   dispatch( purgeInstruments() )
            // }

            // dispatch( showConfirm(Strings.get('syncExplainClear'), Strings.get('syncExplainClearBody'), onOk, null, Strings.get('syncExplainClearConfirm') ) )                
            
          }}
        >
          <ListItemText>
            { Strings.get('syncChangeMappings') }
          </ListItemText>
        </MenuItem>
      </Menu>
    )
  }

  renderWeb() {
    const { dispatch, sync, vectorworks } = this.props
    const mappings = {}
    const { outstanding } = sync
    const that = this

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          { vectorworks.fileName ? //we have started the exchange
              <React.Fragment>
                <Button 
                  color='secondary'
                  variant='contained'
                  //icon={<IconSync />}
                  onClick={
                    e => {
                      that.devMode = true
                      //alert(`This will prompt an upload immediately followed by a download.`)
                      this.fileUpload.value = ''
                      this.fileUpload.click()
                    }
                  }
                >
                  { Strings.get('syncDataExchange') }
                </Button>
                <Button 
                  color='secondary'
                  onClick={ e=> {
                    dispatch ( regenerateDownloadXmlAndDownload() )
                  }}
                >
                  { Strings.get('redownloadXml') }
                </Button>
              </React.Fragment>
            : 
              <React.Fragment>
                <Button 
                  color='secondary'
                  variant='contained'
                  onClick={
                    e => {
                      dispatch( 
                        showConfirm( 
                          Strings.get('setupDataExchange'), 
                          Strings.get('syncDataExchangeHelp'), 
                          () => {
                            that.devMode = true
                            this.fileUpload.value = ''
                            this.fileUpload.click()
                          },
                          () => {/*cancel*/},
                          Strings.get('start'),
                          Strings.get('cancel')
                        ) 
                      )
                    }
                  }
                >
                  <IconSync /> { Strings.get('setupDataExchange') }
                </Button>
              </React.Fragment>
            
          }
          
          <Button
            color='secondary'
            buttonRef={x => this.menuAnchor = x}
            onClick={ e => {
              // if( !isConnected() ) {
              //   alert(Strings.get('appRequired'))
              //   return
              // }
              this.openMenu()
            }}
          >
            <IconSync /> { general.get('advanced') }
          </Button>
          
          { this.renderMenu() }
          <Button
            color='secondary'
            onClick={ e => {
              dispatch( showDialog( Strings.get('syncDataExchangeTitle'), Strings.get('syncDataExchangeHelp') ) )
            } }
          >
            <IconHelp />
          </Button>

          <input 
            hidden
            type='file' 
            accept='.xml'                
            ref={x=> this.fileUpload = x } 
            onChange={e=>{ 
              console.log('fileUpload::onChange')
              if(e.target.files[0]){
                const reader = new FileReader();
                
                let file = e.target.files[0]
                let fileName = file.name

                reader.onload = e => {
                  const text = reader.result
                  const onComplete = () => {
                    const onOk = () => {
                      dispatch( regenerateDownloadXmlAndDownload() )
                    }
                    dispatch( 
                      showDialog(
                        Strings.get('webImportDone'), 
                        Strings.get('webImportDoneDetail'), 
                        onOk, 
                        Strings.get('download') 
                      ) 
                    )
                  }

                  if( Object.keys(this.props.vectorworks.ExportFieldList).length < 1){
                    const justSync = () => {
                      dispatch( syncWithVectorworks(text, fileName, onComplete) )
                    }

                    const showMappings = () => {
                      readExportFieldList(text)
                        .then(result => {
                          dispatch( setExportFieldList(result) )
                          this.openMappings( ()=> { 
                            setTimeout(justSync, 250)
                          } )
                        })
                        .catch(e => {
                          dispatch( showError(
                            Strings.get('errorReadingXml'), 
                            Strings.get('errorReadingXmlDetail', fileName), 
                            e)
                          )
                        })
                    }

                    dispatch( 
                      showConfirm(
                        Strings.get('setupMappingsTitle'),
                        Strings.get('setupMappingsDetail'),
                        justSync,
                        showMappings,
                        Strings.get('justSync'),
                        Strings.get('setupMappings'),
                      ) 
                    )
                  } else {
                    dispatch( syncWithVectorworks(text, fileName, onComplete) )
                  }
                  
                }
                
                reader.readAsText(file)

              } else {
                alert('No file selected: Canceled')
              }
            } }
            />
        </div>
        { vectorworks.fileName ? 
            <label
              style={{
                fontFamily: 'monospace',
                color: Colors.MATERIAL_SECONDARY,
              }}
            >
              { vectorworks.fileName }
            </label>
          : null
        }

        { this.state.mappings ? this.renderMappings() : null }
      </div>
      
    )
  }

  renderElectron() {
    const { dispatch, sync, vectorworks } = this.props
    const mappings = {}
    const { outstanding } = sync
    const that = this

    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        > 
          <Button
            color='primary'
            variant='contained'
            onClick={ e => {
              if( !isConnected() ) {
                alert(T.get('appRequired'))
                return
              }
              dispatch( 
                startElectronVectorworksExchange(
                  vectorworks.fileName,  
                  /*FIXME MAPPINGS */ {},  
                  constants.SYNC_MODES.CHANGES,
                  vectorworks.ExportFieldList || {},
                  false /* reselect */,
                ) )
            }}
          >
            <IconSync /> 
            { !vectorworks.fileName ? 
                Strings.get('setupDataExchange') 
              : 
                Strings.get('syncDataExchange') 
            }
          </Button>


          <Button
            color='primary'
            buttonRef={x => this.menuAnchor = x}
            onClick={ e => {
              this.openMenu()
            }}
          >
            <IconSync /> { general.get('advanced') }
          </Button>
          
          { this.renderMenu(true) }
          
          <Button
            color='primary'
            onClick={ e => {
              dispatch( showDialog( Strings.get('syncDataExchangeAppTitle'), Strings.get('syncDataExchangeAppHelp') ) )
            } }
          >
            <IconHelp />
          </Button>
        </div>
            <label
              style={{
                fontFamily: 'monospace',
                color: Colors.MATERIAL_SECONDARY,
              }}
            >
              { vectorworks.fileName }
            </label>

        { this.state.mappings ? this.renderMappings() : null }
      </div>
      
    )    
  }

  onMappingClose() {
    
    if(this.state.mappingsCloseFunc) {
      this.state.mappingsCloseFunc()
    }

    this.setState({
      ...this.state,
      menu: false,
      mappings: false,
      mappingsCloseFunc: null,
    })
  }

  renderMappings() {
    return (
      <MappingsFullScreen 
        onClose={ x => this.onMappingClose() }
        open={ this.state.mappings }
      /> 
    )
  }

  render() {
    const isElectron = isConnected()
    if(isElectron) {
      return this.renderElectron()
    } else {
      return this.renderWeb()
    }
  }
}

export default connect(s => {
  return {
    sync : s.instruments.sync,
    vectorworks: s.vectorworks,
  }
})(SyncModule)