import React from 'react'
import PropTypes from 'prop-types'
class AsHtml extends React.Component {
  render(){
    return (
      <div 
        style={{
          maxWidth: this.props.width || 350,
          ...this.props.style
        }}
        dangerouslySetInnerHTML={{__html: this.props.html}}
      />
    )
  }
}

AsHtml.propTypes = {
  html: PropTypes.string,
}

export default AsHtml
