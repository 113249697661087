export default {
    //Mappings Editor
    editFieldsAndMappings: 'Edit Fields and Mappings',
    required: 'Required',
    optional: 'Optional',
    laKey: 'Light Assistant Key',
    vwKey: 'Vectorworks Key',
    displayLong: 'Display Name',
    displayMedium: 'Medium (< 100)',
    displayShort: 'Short (< 50)',
  
    //Create and Delete Fields
    addMapping: 'Add New Mapping',
    newFieldName: 'New Field Name',
    addMappingTitle: 'Add New Mapping',
    addMappingDescription: `
    Adding a new field to Light Assistant is allows you to store
    the data in a way that makes sense to you. You may need to define
    new sorts and customize your paperwork to make sense of it.
`,  
    //RESET
    resetToDefault: 'Reset Default Mappings',
    resetToDefaultConfirm: 'Restore Defaults',
    resetDefaultMessage: `
    <p>
    This will reset all mappings to the default mapping (a 1:1 map of 
    <b>ALL</b> the fields from the <b>default</b> Spotlight record). Spotlight
    may or may not be exporting all these fields which will then be assigned
    once the file import starts (removing older mappings).
    </p>

    <p>
    This operation has no permanent affects. This only changes the flow
    of data between Light Assistant and Vectorworks, not the data in either
    file.
    </p>
`,
    resetDeafultHelp: `
    <p>
    Resetting the mappings to default can help correct synchronization 
    errors if you are not paying close attention to the mappings. In general
    the default settings will allow you to do almost anything you need to do.
    </p>

    <p>
    If you are experiencing a problem with Data Exchange this is a good first 
    step towards fixing the problem.
    </p>
`,
    //Delete Fields
    deleteField: 'Delete',
    deleteFieldTip: 'Delete this field and mapping',
    deleteMappingTip: 'Removes this mapping',
    deleteConfirmTitle: 'Remove {0}?',
    deleteConfirmCopy: `
  <p>
  Are you sure you want to remove <b>{0}</b>? Light Assistant will
  not remove the data from the structure but it will no longer be available
  to you in paperwork or worksheets. 
  </p>
  
  <p>
  You can manually restore this at any time.
  </p>
  `,
    clearMappingTip: 'Remove this Vectorworks mapping (Data Exchange)',
    editTip: 'Edit this Field and Mapping',
    saveTip: 'Save Changes',
    cancelTip: 'Cancel Changes',
    blockTip: 'This field required can not be changed',
    deleteTip: 'Remove this field and mapping',

    //ERRORS
    errorTitle: 'Error',
    errorKeyRequired: 'The key was invalid (whitespace or empty).',
    errorKeyExists: 'The key {0} already exists, check the mappings.',
    errorKeyInvalid: 'The key {0} is invalid. It is one of the reserved field names (and already exists).',
    vwHelp: 'Data Exchange Help',
    vwHelpMessage: `
<p>
    Vectorworks exports a list of fields when Data Exchange is 
    enabled in the document. These fields are controlled in the "Export Field List" 
    which can be configured by the user. These fields are the only data exchanged with 
    Vectorworks.
</p>

<p>
    Light Assistant recommends exporting <b>ALL FIELDS</b> so that any changes
    you make in Light Assistant are reflected in Vectorworks. If you want to 
    minimize these fields you can remove the mapping here. Light Assistant will
    still read the data but it will not write the data back to Vectorworks.
</p>

<p>
    For more information (or if you have a use case you need) please contact support.
</p>
`,
    errorMappedButNotValid: 'The field "{property}" is mapped in Light Assistant but not Exported as "{vectorworksDisplay}" in Vectorworks',
    errorMappedButNotValidTitle: '"{property}" Mapping Invalid.',
    errorMappedButNotValidHelp: `
<p>
    Light Assistant maps all possible fields by default, this is not neccessarily 
    an error. It merely indicates that the data in Vectorworks is not synchronized
    with the data in Light Assistant for a particular field.
</p>

<p>
    In this case the field <b>{property}</b> is mapped to 
    <b>{vectorworksDisplay}</b> but <b>{vectorworksDisplay}</b> is not exported.
</p>
<p>
    There are many use cases (such as only importing patch or address data) 
    where a single field might be active in Vectorworks. For more information 
    please contact support.
</p>
`,
}