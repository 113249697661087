export const ReportCommon = Object.freeze({
  FONT: 'font',
  FONT_SIZE: 'fontSize',
  COLOR: 'color',
  X: 'posX',
  Y: 'posY',
  WIDTH: 'width',
  HEIGHT: 'height',
  WRAP: 'wrap',
  TEXT: 'text',
  LOGO: 'logo',
  IMAGE: 'image',
  ALIGN: 'align',
  LABEL: 'label',
  KEY: 'key',
  FORMAT: 'format',
  FORMATTER_RESTRICTED: 'formatter',
  COLUMNS: 'columns',
  COLUMNS_OPTIONAL: 'columnsOptional',
  CONTAINER: 'container',
  FILL: 'fill',
})

export const Align = Object.freeze({
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
})

export const Seperator = Object.freeze({
  DASH: 'dash',
  SOLID: 'solid',
  NONE: 'none',
})

export const ReportContainers = Object.freeze({
  ROUNDED_RECTANGLE: 'roundedRectangle',
  RECTANGLE: 'rectangle',
})

export const ReportFormatting = Object.freeze({
  SEPERATOR: 'seperator',
  BORDER_TYPE_START: 'start',
  BORDER_TYPE_END: 'end',
  VERTICAL: 'vertical',
  ROW_LINE_COLOR: 'rowLineColor',
  SEPERATOR_COLOR: 'seperatorColor',
})

export const ReportTextNode = Object.freeze({
  TEXT: ReportCommon.TEXT,
  FONT: ReportCommon.FONT,
  FONT_SIZE: ReportCommon.FONT_SIZE,
  COLOR: ReportCommon.COLOR,


})
export const ReportHeading = Object.freeze({
  ...ReportTextNode,
  TEXT: ReportCommon.TEXT,
})

export const ReportSubheading = Object.freeze({
  FONT: ReportCommon.FONT,
  FONT_SIZE: ReportCommon.FONT_SIZE,
  COLOR: ReportCommon.COLOR,
  FILL_COLOR: 'fill',
})