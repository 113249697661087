import {
  dismissError
} from './InterfaceLogic'
const ERRORS_REPORT_ERROR = 'ERRORS_REPORT_ERROR'
const ERRORS_CLEAR_ERROR = 'ERRORS_CLEAR_ERROR'

export const reportError = (error, hint) => {
  return dispatch => {
    dispatch({
      type: ERRORS_REPORT_ERROR,
      error: error,
      hint: hint,
    })

    dispatch({
      type: 'ROUTE_CHANGED',
      pathname: '/feedback'
    })
  }
}

export const clearError = () => {
  return dispatch => {
    dispatch( dismissError() )
    dispatch({
      type: ERRORS_CLEAR_ERROR,
    })
  }
}

export const sendErrorToServer = (formatted) => {
  console.log('simulating upload')
  return dispatch => {
    setTimeout(()=>{
      dispatch( clearError() )
    }, 1000)
  }
}

const INITIAL_STATE = {
  error: null,
  hint: null,
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case ERRORS_CLEAR_ERROR: {
      return {...INITIAL_STATE}
    }

    case ERRORS_REPORT_ERROR: {
      return {
        ...state,
        error: action.error,
        hint: action.hint,
      }
    }
  }

  return state
}