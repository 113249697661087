import { Translator } from './language/Translate'
import { formatUnicorn } from './StringUtil'
import moment from 'moment'

export const DEBUG = 0
export const INFO = 1
export const WARN = 2
export const ERROR = 3

export const LEVELS = {
  DEBUG: DEBUG,
  INFO: INFO,
  WARN: WARN,
  ERROR: ERROR
}

let LOG_LEVEL = DEBUG

/**
 * Returns the current log level
 * @param {*} setLevel if this value it provided it sets the current log
 * level to this value.
 */
export const level = (setLevel) => {
  if(setLevel === undefined || setLevel === null) {
    return LOG_LEVEL
  } else {
    //FIXME do some type of strict typing here.
    LOG_LEVEL = setLevel
  }
}

export const logTime = () => {
  return moment().format('YYYYMMDD HH:mm:ss')
}

export const textForPrefix = (level) => {
  switch (level) {
    case ERROR: 
      return 'ERROR'
    case WARN: 
    return 'WARNING'
    case INFO: 
      return 'INFO'
    default:
      return 'DEBUG'
  }
}

/**
 * Create a log object without all the translation features baked in. Use
 * this if you already have a translation framework set up.
 * @param {*} level 
 * @param {*} module 
 * @param {*} message 
 */
export const createExplicitLogEvent = (_level, _module, _message) => {
  return {
    time: logTime(),
    module: _module,
    prefix: textForPrefix(_level),
    message: _message,
    level: _level
  }
}
/**
 * Create a log event based on a dictionary key
 * @param {*} lang 
 * @param {*} module 
 * @param {*} level 
 * @param {*} key 
 * @param {*} rest 
 */
export const createLogEvent = (lang, module, level, key, ...rest) => {
  const message = Translator(lang, module).get(key, ...rest)
  const time = logTime()

  const prefix = textForPrefix(level)

  console.log(`REMOVEME->[${time}] [${module}] [${prefix}] ${message}`)
  return {
    time: time,
    module: module,
    prefix: prefix,
    message: message,
    level: level
  }
}
/**
 * /**
 * A simple logging implementation that can be expanded by the user of a 
 * listener object with matching functions passed to it.
 * 
 * It uses the translation service by default and expects keys for the messages 
 * though a full message will appear as `message**` and can be added later.
 * 
 * @param {string} an additional label for this message
 * @param {function} listenerFn (_level, time, module, formattedMessage)
 * @param {string} language key for the formatter 'en' is default
 */
export const Logger = (language, module, listenerFn) => {
    /**
     * A log function that is for internal use. It may be of interest
     * to the listener function
     * @param {*} prefix 
     * @param {*} module 
     * @param {*} key 
     * @param {*} rest 
     */
    const log = (_level, module, key, ...rest) => {
      const T = Translator(language || 'en', module)

      const message = T.get(key, ...rest)
      const time = logTime()
      let prefix = textForPrefix(_level)
      
      console.log(`[${time}] [${module}] [${prefix}] ${message}`)

      if (listenerFn) {
        listenerFn(_level, time, module, message)
      }
    }

    /**
     * Log at the information level. This is for end users 
     * who are looking for details.
     * 
     * @param {*} key the translation key for this message
     * @param {*} rest arguments to fill the key
     */
    const info = (key, ...rest) => {
      if (LOG_LEVEL <= INFO) {
        log(INFO, module, key, ...rest)
      }
    }

    /**
     * Log anything and everything you see useful. With any luck
     * we can compile these messages out with a preprocessor before
     * build.
     * 
     * @param {*} key the translation key for this message
     * @param {*} rest arguments to fill the key
     */
    const debug = (key, ...rest) => {
      if (LOG_LEVEL <= DEBUG) {
        log(DEBUG, module, key, ...rest)
      }
    }
    /**
     * A log warning something may or may not be right. This is for
     * users who are looking for more detailed information but are not
     * looking for all the information. A good example might be a missed 
     * command or bad input that wouldn't generate an error. 
     * 
     * In general this should be for cases you're not expecting
     * 
     * @param {*} key the translation key for this message
     * @param {*} rest arguments to fill the key
     */
    const warn = (key, ...rest) => {
      if (LOG_LEVEL <= WARN) {
        log(WARN, module, key, ...rest)
      }
    }
    /**
     * Log at the error level. This is for production use and 
     * should indicate something went wrong.
     * 
     * @param {*} key the translation key for this message
     * @param {*} rest arguments to fill the key
     */
    const error = (key, ...rest) => {
      if (LOG_LEVEL <= ERROR) {
        log(ERROR, module, key, ...rest)
      }
    }

    /**
     * !!!DOES NOT WRITE TO THE LOG!!!
     * 
     * This is a convienence method for creating a log object through an 
     * already created logger to pass to another framework (like the front end logger).
     * 
     * @param {*} level the level of this message
     * @param {*} key the key for translation
     * @param {*} rest arguments to be folded into the key
     * 
     * @return a log object
     */
    const explicit = (level, key, ...rest) => {
      return createExplicitLogEvent(level, module, formatUnicorn(key, ...rest))
    }

    return {
      log: log,
      info: info,
      error: error,
      warn: warn,
      debug, debug,
      explicit: explicit,
      level: level,
    }

}

export default Logger
