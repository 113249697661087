import {
  SYNC_APP_STAMP_LIGHTWRIGHT,
  SYNC_APP_STAMP_VW,
  SYNC_APP_STAMP_LIGHT_ASSISTANT,
} from './InstrumentLogic'

import {
  STATIC_MAPPINGS,
  DEFAULT_VECTORWORKS_MAPPINGS,
  USER_CAN_NOT_REMOVE,
  regenerateDownloadXml,
  vwTimestamp,
  DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST,
} from './../../shared/vectorworks'

import {
  showDialog
} from './InterfaceLogic'

import Language from './../../shared/language'

import Errors from './../Errors'
import { mergeObjectsIgnoreEmpty } from './../../shared/Utility'

const PUSH_LA_CHANGE_BULK = 'VECTORWORKS_CACHE_PUSH_LA_CHANGE_BULK'
const DATA_STORE_IMPORT = 'VECTORWORKS_CACHE_STORE_IMPORT'
const PULL_VW_CHANGE = 'VECTORWORKS_CACHE_PULL_VW_CHANGE'
const VW_CACHE_RESET = 'VECTORWORKS_CACHE_RESET'
const REGENERATE_DOWNLOAD_XML = 'VECTORWORKS_REGENERATE_DOWNLOAD_XML'
const UPDATE_MAPPING = 'VECTORWORKS_UPDATE_MAPPING'
const ADD_MAPPING = 'VECTORWORKS_ADD_MAPPING'
const REMOVE_MAPPING = 'VECTORWORKS_REMOVE_MAPPING'
const RESET_DEFAULT_MAPPINGS = 'VECTORWORKS_RESET_DEFAULT_MAPPINGS'
const RESET_MAPPINGS_VECTORWORKS_2019 = 'VECTORWORKS_RESET_DEFAULT_MAPPINGS'
const SET_EXPORT_FIELD_LIST = 'VECTORWORKS_SET_EXPORT_FIELD_LIST'

const PURGE_VECTORWORKS = 'VECTORWORKS_PURGE'
export const AppStamps = {
  Vectorworks: 'Vectorworks',
  LightAssistant: 'LightAssistant',
  Lightwright: 'Lightwight'
}


const INITIAL_STATE = {
  xml: {},
  /**
   * This is the timestamp incoming from Vectorworks. 
   */
  lastVectorworksImport: null,
  xmlObject: null,
  ExportFieldList: {},
  VWFieldList: {},
  UniverseSettings: {},
  InstrumentData: {},
  downloadXml: null, //The XML file to download, created by ::regenerateDownloadXmlXml
  lastSync: null,
  history: {},
  changeMap: {}, //a map of _id to Records (Which can then be VW_UID)
  syncMappings: { 
    ...DEFAULT_VECTORWORKS_MAPPINGS 
  },
  vwFieldList: {},
}

/**
 * The record type for this VW Transation
 */
export const RecordType = {
  UPDATE: 'Update', //this was undefined--why?
  ADD: 'Add',
  DELETE: 'Delete',
}

/**
 * Pushes a record onto the stack to be changed. Generally we know what it is 
 * @param {String} key, the key that was changed for this update. If this is null all keys will be synced
 * @param {Array<SyncRecord>} records an array of all the records to push into the sync data.
 */
export const pushSyncChangeBulk = (key, records) => {
  return (dispatch, getState) => {
    dispatch({
      type: PUSH_LA_CHANGE_BULK,
      key: key,
      records: records,
      byUser: 'Light Assistant',
    })
  }
}

export const setExportFieldList = (map) => {
  return dispatch => dispatch({
    type: SET_EXPORT_FIELD_LIST,
    exportFieldList: map,
  })
}

export const regenerateDownloadXmlAndDownload = () => {
  return (dispatch, getState) => {
    const state = getState()
    const { vectorworks } = state
    const fileName = vectorworks.fileName || 'unknown.xml'
    regenerateDownloadXml( state )
    .then(xml => {

      const str = 'data:text/xml;charset=utf-8,' + encodeURIComponent( xml )
      const a = document.createElement('a')
      const blobData = new Blob([xml], { type: 'text/xml' })
      const blobUrl = URL.createObjectURL(blobData)
      a.href = blobUrl
      a.download = fileName
      a.innerHTML = 'TEMPORARY_LINK'

      
      const container = document.body.appendChild(a)
      a.click() //Fire Download
      document.body.removeChild(a)
      dispatch({
        type: REGENERATE_DOWNLOAD_XML,
        downloadXml: xml
      })
    }).catch( err => {
      dispatch( Errors.reactError(`Error generating XML`, err, err) )
    })
  }
}

/**
 * Pushes the history and the new "pending" changes to the UI so that synchronization
 * can happen in the future. 
 * @param {*} appStamp the app stamp of this import/export
 * @param {*} updated an array of updated objects
 * @param {*} deleted an array of deleted objects
 * @param {*} conflictedObj the new "conflicted" state (conflicted means Light Assistant has data Vectorworks doesn't)
 * @param {*} downloadXml the XML file to send back to Vectorworks. 
 * @param {*} byUser the user that did this, Vectorworks, Light Asssistant, etc...
 * @param {*} exportFieldList the list of fields exported by Vectorworks
 * 
 * @returns a dispatch/thunk
 */
export const pushImportState = 
  (appStamp, updated, deleted, conflictedObj, downloadXml, fileName, byUser, exportFieldList, onComplete) => {
  return dispatch => {
    dispatch({
      type: DATA_STORE_IMPORT,
      appStamp: appStamp,
      updated: updated || [],
      deleted: deleted || [],
      conflicted: conflictedObj || [],
      downloadXml: downloadXml,
      byUser: byUser,
      fileName: fileName,
      exportFieldList: exportFieldList,
    })

    if(onComplete) {
      onComplete(downloadXml)
    } else {
      //FIXME else what? 
    }
  }
}

export const vectorworksCacheReset = () => {
  return dispatch => {
    dispatch({
      type: VW_CACHE_RESET
    })
  }
}

const _requiredFields = {}
for(let k of USER_CAN_NOT_REMOVE) {
  _requiredFields[k] = true
}

/**
 * Returns true if this field is required, else false
 * @param {String} key 
 */
export const isRequiredField = (key) => {
  return !!_requiredFields[key]
}

/**
 * Update a sync mapping and associated names
 * @param {*} key the light assistant key
 * @param {*} vectorworks the vectorworks key
 * @param {*} vectorworksName the vectorworks name
 * @param {*} long The full field name
 * @param {*} medium A medium field name
 * @param {*} short A short field name
 */
export const updateMapping = (key, vectorworks, vectorworksName, long, medium, short ) => {

  return dispatch => dispatch({
    type: UPDATE_MAPPING,
    key: key,
    vectorworks: vectorworks,
    vectorworksName: vectorworksName,
    long: long,
    medium: medium,
    short: short,
  })
}

/**
 * Remove a mapping from the list (if not required)
 * @param {*} key 
 */
export const deleteMapping = (key) => {
  if( isRequiredField(key) ) {
    return
  }

  return dispatch => dispatch({
    type: REMOVE_MAPPING,
    key: key,
  })
}

/**
 * Add a mapping to the list if not a duplicate
 * @param {*} key 
 */
export const addMapping = (key) => {
  if( isRequiredField(key) ) {
    return dispatch => dispatch( 
      Errors.reactWarning( 
        Language.instrumentMappings.get('errorTitle'), 
        Language.instrumentMappings.get('errorKeyInvalid', key), 
      ) )
  }
  key = key.trim()

  return (dispatch, getState) => {
    const { vectorworks } = getState()
    const mappings = vectorworks.syncMappings
    if(!key) {
      return dispatch( 
        Errors.reactWarning( 
          Language.instrumentMappings.get('errorTitle'), 
          Language.instrumentMappings.get('errorKeyRequired'), 
        ) )
    }
  
    const keys = Object.keys(mappings)
    const index = keys.indexOf(key.trim())
  
    if(index > 0) {
      return dispatch( 
        Errors.reactWarning( 
          Language.instrumentMappings.get('errorTitle'), 
          Language.instrumentMappings.get('errorKeyExists', key), 
        ) )
    }
  
    dispatch({
      type: ADD_MAPPING,
      key: key,
    })
  }
}

export const createOrResetDefaultMappings = () => {
  return dispatch => dispatch({
    type: RESET_DEFAULT_MAPPINGS
  })
}

export const purgeVectorworksNotActive = () => {
  return dispatch => dispatch({
    type: PURGE_VECTORWORKS,
  })
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case VW_CACHE_RESET: {
      return { ...INITIAL_STATE }
    }

    case PUSH_LA_CHANGE_BULK: {
      const { records, key, byUser } = action
      let changeMap = {
        ...state.changeMap
      }
      const time = vwTimestamp()
      for(let record of records) {
        let current = { 
          ...changeMap[record._id],
          timeStamp: time,
        }

        if(!key) {
          const keys = Object.keys(record)
          for(let k of keys) {
            current[k] = record[k]
          }
        } else {
          current[key] = record[key]
        }
        
        current['lastUpdatedBy'] = byUser
        if(record.deleted) {
          if(record.uid) {
            current.action = RecordType.DELETE
            changeMap[record._id] = current
          } else { //Not synced, abort adding this at all
            delete changeMap[record._id] //clear from the map if it exists
          }
        } else if (!record.uid) { //No VW ID, add, if we need to gen these, we do it on writing the file
          current.action = RecordType.ADD
          changeMap[record._id] = current
        } else {
          current.action = RecordType.UPDATE
          changeMap[record._id] = current
        }
      }
      
      return {
        ...state,
        changeMap: changeMap,
      }
    }

    case DATA_STORE_IMPORT: {
      const time = vwTimestamp()
      const { 
        updated, 
        deleted, 
        conflicted, 
        appStamp, 
        byUser, 
        downloadXml, 
        fileName,
        exportFieldList,
      } = action

      const history = {
        ...state.history
      }

      if (!history.first && appStamp == AppStamps.LightAssistant) {
        return state //don't do anything, there's no "Sync" happening as it isn't set up
      }

      let key = time

      history[key] = {
        time: time,
        updated: updated,
        conflicted: { ...conflicted }, //"changeMap, but let's see what happens"
        deleted: deleted,
        byUser: byUser,
      }

      return {
        ...state,
        //We keep the changes in here until they are explicitly overwritten by Vectorworks
        changeMap: { ...conflicted }, 
        downloadXml: downloadXml,
        fileName: fileName,
        history: history,
        ExportFieldList: exportFieldList,
      }
    }
    case PULL_VW_CHANGE: {
      throw new Error('[VectorworksCache::PUSH_VW_CHANGE] NOT IMPLEMENTED')
    }

    case REGENERATE_DOWNLOAD_XML: {
      const { downloadXml } = action

      return {
        ...state,
        downloadXml: downloadXml
      }
    }

    case UPDATE_MAPPING: {
      const { key, vectorworks, vectorworksName, long, medium, short  } = action
      const syncCopy = { ...state.syncMappings }

      const record = { 
        ...syncCopy[key],
        key: vectorworks,
        name: vectorworksName,
        display: long,
        displayMedium: medium,
        displayShort: short,
      }
      
      syncCopy[key] = record

      return {
        ...state,
        syncMappings: syncCopy
      }
    }

    case ADD_MAPPING: {
      const copyMappings = { ...state.syncMappings }
      let { key } = action
      key = key.trim()
      if(!key) {
        return state
      }

      const keys = Object.keys(copyMappings)
      const index = keys.indexOf(key.trim())

      if(index > 0) {
        return state //we already have this mapping
      }

      copyMappings[key] = {}

      return {
        ...state,
        syncMappings: copyMappings,
      }
    }

    case REMOVE_MAPPING: {
      const copyMappings = { ...state.syncMappings }
      let { key } = action
      key = key.trim()

      if(!key) {
        return state
      }

      const data = copyMappings[key]
      if(data === null || data === undefined) {
        return state
      }

      delete copyMappings[key]

      return {
        ...state,
        syncMappings: copyMappings,
      }
    }

    case RESET_DEFAULT_MAPPINGS: {
      const syncMappings = { ...DEFAULT_VECTORWORKS_MAPPINGS }

      return {
        ...state,
        syncMappings: syncMappings,
      }
    }

    case SET_EXPORT_FIELD_LIST: {
      const { exportFieldList } = action
      if(!exportFieldList) {
        return state
      }

      return {
        ...state,
        ExportFieldList: exportFieldList,
      }
    }

    case PURGE_VECTORWORKS: {
      const copy = {
        ...state,
        history: INITIAL_STATE.history
      }
      return copy
    }

    default: {
      return state
    }
  }
}