import pouch from '../pouch'
import Errors from '../Errors'
import Translate from './../../shared/language/Translate'
import { redirect } from '../Utility'
import { showDialog } from './InterfaceLogic'
import {
  addEvent,
  LOG_LEVELS,
} from './LogLogic'
import {
  reconnectToEos,
  isConnected,
  heartbeat
} from './ElectronLogic'

import {
  addInstrument,
  mergeInstrumentSet,
  mergeInstrument,
} from './InstrumentLogic'

import {
  toAbsoluteAddress
} from './../../shared/Utility'

export const CHANGE_ADDRESS = 'OSC_CHANGE_ADDRESS'
export const RECONNECT = 'OSC_RECONNECT'
export const DISCONNECT = 'OSC_DISCONNECT'
export const ERROR = 'OSC_ERROR'
export const OSC_MESSAGE_RECEIVED = 'OSC_MESSAGE_RECEIVED'
export const OSC_MESSAGE_SENT = 'OSC_MESSAGE_SENT'
export const OSC_UPDATE_KEY = 'OSC_UPDATE_KEY'
export const OSC_UPDATE_KEY_SET = 'OSC_UPDATE_KEY_SET'
export const OSC_STATUS_UPDATE = 'OSC_STATUS_UPDATE'

export const STATUS_DISCONNECTED = 'DISCONNECTED'
export const STATUS_CONNECTING = 'CONNECTING'
export const STATUS_CONNECTED = 'CONNECTED'

export const READ_PATCH_ENABLED = 'READ_PATCH_ENABLED'
export const READ_PATCH_UNKNOWN = 'READ_PATCH_UNKNOWN'
export const EOS_PATCH_ADD_PARTS_TO_CHANNEL = 'EOS_PATCH_ADD_PARTS_TO_CHANNEL'

export let oscEos = null
export let oscQlab = null

/**
 * Return the current cue tracked in OSC from the state.
 * @param {ApplicationState} state
 * @param {Boolean} force - if true, ignore the on/off toggle in notes.
 */
export const getCurrentOscCue = (state, force=false) => {
  if(!state || !state.notes || !state.osc) {
    return null
  }

  if(state.osc.on) {
    if(force) {
      return state.osc.cue
    } else if(state.cues.trackOsc) {
      return state.osc.cue
    } else {
      return null
    }
  }

  return null
}

export const connectToEos = () => {
  return (dispatch, getState) => {
    const { osc } = getState()

    if (!isConnected()) {
      dispatch(
        Errors.reactError(
          'App Not Found',
          `Light Assistant is not running the appand OSC will not function.
          Eos and QLab require OSC over TCP/IP connections to function properly. As a result this means OSC connections can not be maintained from a web-browser.
          Download the Light Assistant App to use this functionality.
          `
        ))
      return
    }

    dispatch( reconnectToEos(osc.eosHost, osc.eosPort || 3032, osc.eosOscVersion || '1.0') )
  }
}

export const updateOscKey = (key, val) => {
  return dispatch => {
    dispatch({
      type: OSC_UPDATE_KEY,
      key: key,
      value: val,
    })
  }
}

export const updateOscKeySet = (keyObject) => {
	return dispatch => {
		dispatch({
			type: OSC_UPDATE_KEY_SET,
			updates: keyObject
		})
	}
}

let _timeout = null
export const updateStatus = (message = '') => {
  return dispatch => {
    clearTimeout(_timeout)
    dispatch( updateOscKey('status', message)   )
    const statusElement = document.getElementById('OSC_STATUS_LINE')
    if(statusElement){
      statusElement.classList.add('oscFlash')
    }
    _timeout = setTimeout(()=>{
      const statusElement = document.getElementById('OSC_STATUS_LINE')
      dispatch( updateOscKey('status', ''))//clear the message...
    }, 5000)
  }
}

export const liveCueFired = (list, cue) => {
  return dispatch => {
    dispatch( updateOscKey('list', list) )
    dispatch( updateOscKey('cue', cue) )
  }
}

export const liveCueSetFired = (dataArray) => {
	return (dispatch, getState) => {
		const cues = getState().cues.cues.slice()

		let last = null
		for(let i = dataArray.length -1; i > -1; i--){
			let cue = cues.find(q => q.number == dataArray[i].cue)
			if(cue) {
				last = dataArray[i]
				break
			}
		}

		//And here is where we need to trigger ALL the cues
		if(last) {
			dispatch( updateOscKeySet({
				list: last.list,
				cue: last.cue
			}))
		} else {
			dispatch({type:'no-op-live-cues-fired'})
		}
	}
}

/**
 * Bulk Merges patch values to avoid heavy UI updates when a lot of changes are coming in
 * It is a multiple object version of the patchValueRead event.
 * @param {*} instruments an array of instruments to merge
 * @param {*} addUnmatchedTransformer a transformer that, if provided, will add data to the instruments and add them.
 */
export const patchBulkInstruments = (instruments, addUnmatchedTransformer = null, displayPartsInChannel = false) => {
  return dispatch => {
    if(!instruments || instruments.length < 1) {
      return () => {
        console.log('WARNING: Patch Bulk Instruments called with invalid array')
      }
    }

    //Add the preprocessing for this
    for(let x of instruments) {
      x.absoluteAddress = toAbsoluteAddress(x.universe, x.address)
    }
    dispatch( updateStatus(`Merging ${instruments.length} units`) )
    dispatch( mergeInstrumentSet(instruments, 'Patch from Eos', addUnmatchedTransformer, displayPartsInChannel) )
  }
}
/**
 * This method attempts to update an instrument based on the
 * channel or creates a new instrument for the missing channels
 *
 * @param {*} channel
 * @param {*} part
 * @param {*} universe
 * @param {*} address
 * @param {*} label
 * @param {*} model
 * @param {*} manufacturer
 */
export const patchValueRead = (channel, part, universe, address, label, model, manufacturer) => {

  // return dispatch => {
  //   console.log(`${channel}, ${part}, ${universe}, ${address}, ${label}`)
  // }
  return (dispatch, getState) => {
    const doReadPatch = (getState().osc || {})[READ_PATCH_ENABLED]
    if(doReadPatch) {
      const state = getState()
      const instruments = (state.instruments.instruments || []).slice()
      const index = instruments.findIndex(x => x.channel == channel)
      console.log('DEV=>' + instruments.length)
      if(index > -1) {
        const inst = {...instruments[index]}
        //CHEATING
        inst.address = address
        inst.universe = universe
        inst.absoluteAddress = toAbsoluteAddress(universe, address)

        dispatch( updateStatus(`PATCH (${inst.channel}) @ ${universe}/${address}`) )
        //dispatch( addEvent(LOG_LEVELS.INFO, 'OSC::EOS::PATCH', `Merging channel (${inst.channel}) @ ${universe}/${address}`) )
        dispatch( mergeInstrument(inst, `EOS PATCH (${channel})@${universe}/${address}`) )
        // bulkMergeInstruments('address', [instruments[index]], [inst], null, 'Merged from EOS')
      } else { //ADD
        if (!address) {
          dispatch( addEvent(LOG_LEVELS.INFO, 'OSC::EOS::PATCH', `Skipping Channel (${channel}) No address information (${universe}/${address})`) )
        } else {
          dispatch( updateStatus(`ADD (${channel}) @ ${universe}/${address}`) )
          //dispatch( addEvent(LOG_LEVELS.INFO, 'OSC::EOS::PATCH', `Adding Channel (${channel}) @ ${universe}/${address}`) )
          dispatch(
            addInstrument({
              position: 'From EOS',
              instrumentType: manufacturer + ' ' + model,
              channel: channel,
              universe: universe || null,
              address: address,
            })
          )
        }
      }

      console.log(`${channel} part: ${part} ${universe}/${address} ${label}`)
    } else {
      console.log(`IGNORE ${channel} part: ${part} ${universe}/${address} ${label}`)
    }
  }
}

export const INITIAL_STATE = {
  mode: 'tcp',
  port: '3032',
  eosName: 'Eos Default',
  eosHost: '',
  qlabWorkspace: '',
  qlabServer: '',
  on: false,
  status: '',
  messages: ['OSC Subsystem Placeholder', 'OSC state: INITIAL_STATE'],
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    case CHANGE_ADDRESS:
      return state

    case ERROR: {
      return {
        ...state,
        status: action.status
      }
    }

    case OSC_MESSAGE_RECEIVED: {
      return state
    }

    case OSC_MESSAGE_SENT: {
      return state
    }

    case OSC_UPDATE_KEY: {
      const { key, value } = action
      if(!key) {
        return state
      }

      //FIXME do we persist the state?
      let newState = { ...state }
      newState[key] = value || ''
      return newState
    }

		case OSC_UPDATE_KEY_SET: {
			const { updates } = action
			let keys = Object.keys(updates)
			if(keys.length < 1) {
				return state
			}

			let newState = {...state}
			for(let key of keys) {
				newState[key] = updates[key] || ''
			}

			return newState
		}

    default:
      return state
  }
}
