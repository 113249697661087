import React from 'react'
import Card from './Card'
import CardSeries from './CardSeries'

import {
  Button,
  Tooltip,
} from '@material-ui/core'

class AppleInAppPurchase extends React.Component {
  render() {
    return (
      <CardSeries>
        <Card>
          <h2>App Store</h2>
        
          <p>You do not currently have a subscription.</p>
          <Tooltip title='Attempt to restore purchases from the App Store.'>
            <Button
              variant='contained'
            >
              Purchase Monthly Plan (AutoRenew @ $9.99/month)
            </Button>
          </Tooltip>


          <p>You are currently subscribed at AMOUNT from START to END.</p>
          <Tooltip title='Attempt to restore purchases from the App Store.'>
            <Button
              variant='contained'
            >
              Cancel Monthly Plan (AutoRenews @ $9.99/month)
            </Button>
          </Tooltip>
          <p></p>

          <Tooltip title='Attempt to restore purchases from the App Store.'>
            <Button
              variant='contained'
            >
              Restore Purchases
            </Button>
          </Tooltip>
        </Card>
      </CardSeries>
    )
  }
}

export default AppleInAppPurchase