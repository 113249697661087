import { isEmptyOrWhitespace } from './StringUtil'
import numeral from 'numeral'
import storage from 'redux-persist/lib/storage'

/**
 * A simple check for null or undefined
 * @param {Any} a
 */
export const isNull = (a) => {
  if(a === null || a === undefined) {
    return true
  } else {
    return false
  }
}

/**
 * A wrapper for isNaN that checks for spaces...
 * I can't believe I'm writing this. Spaces are NOT A NUMBER
 * @param {Any} a
 * @returns boolean true/false
 */
export const safeIsNaN = (a) => {
  if(a === ' ') {
    return true
  }

  return isNaN(Number(a))
}

/**
 * Tests whether this value can be interpreted as numeric.
 * @param {Any} val
 */
export const isNum = (val) => {
  const num = Number(val)
  return !isNaN(num)
}

/**
 * A simple comparator with nulls before values
 * @param {*} a
 * @param {*} b
 * @param {bool} invertNulls should the nulls be forced to the bottom? Default false (nulls on top)
 */
export const compareToNullSafe = (a, b, invertNulls = false) => {
  const aNull = isNull(a)
  const bNull = isNull(b)

  if(aNull && bNull) {
    return 0
  }

  if(!aNull && bNull) {
    return invertNulls ? -1 : 1
  }

  if(aNull && !bNull) {
    return invertNulls ? 1 : -1
  }

  if(a == b) {
    return 0
  }

  return a < b ? -1 : 1
}

export const joinStrings = (...values) => {
  if(!values || values.length < 1) {
    return ''
  }

  let out = ''
  for(let i = 0; i < values.length; i++) {
    let v = values[i]
    if( isNull(v) ) {
      continue //skip empty or null
    }
    if(v && i !== 0) {
      out += v
    } else if (v) {
      out += ' ' + v
    }
  }

  return out
}

export const incrementLetter = (str, amount = 1) => {
  const letters = 'abcdefghijklmnopqrstuvwxyz'
  let lower = str.toLowerCase()
  let lastChar = lower[lower.length - 1]
  let lastCharRaw = str[lower.length - 1]
  let index = letters.indexOf(lastChar)

  let uppercase = true
  if(lastCharRaw == lastChar) {
    uppercase = false
  }

  if(index < 0 || (index > letters.length) ) {
    if(uppercase) {
      return str + 'A'
    } else {
      return str + 'a'
    }
  }
  else if (index === 25) { //z
    if(uppercase) {
      return str.slice(0, str.length - 1) + 'AA'
    } else {
      return str.slice(0, str.length - 1) + 'aa'
    }
  } else {
    let next = letters[index + 1]
    if(uppercase) {
      return str.slice(0, str.length -1) + next.toUpperCase()
    } else {
      return str.slice(0, str.length -1) + next
    }
  }
}



/**
 * Returns a number if possible, else the default provided
 * @param {Any} val the value to test
 * @param {Number} def the default value (0)
 */
export const num = (val, def = 0) => {
  if(val === null || val === undefined) {
    return def
  }

  const numeric = Number(val)
  if(isNaN(numeric)) {
    return def
  }

  return numeric
}

/**
 * Fix this to use some internationalization at some point
 */
export const currency = (number, defaultValue = 0, symbol = '$') => {
	let val = num(number, defaultValue)

	let format = symbol+'0,0'
	return numeral(val).format(format)
}

/**
 * Returns an array of tokens with
 * the format:
 * <code>[{
 *   type: 'number' || 'string', <String>
 *   value: number || string <Object>
 * }, ...]</code>
 * }
 * A null or empty object returns an empty array
 * @param {Array<String>} str
 * @param {Char} numericSeperator the seperator for decimal places
 */
export const tokenizeString = (str, numericSeperator = '.') => {
  if( isNull(str) ) {
    return []
  }
  const tokens = []
  let mode = null //0 = string 1 == number
  let current = ''

  for(let i = 0; i < str.length; i++) {
    let c = str[i]
    let isNum = !safeIsNaN(c)

    if(c === numericSeperator && mode === 'number') {
      isNum = true//else we treat it as a string
    }
    //console.log(c + ' is number? ' + isNum)

    if( isNum ) {//it is a number

      if(mode === 'string') {
        tokens.push({
          type: 'string',
          value: current
        })

        current = c
      } else {
        current += c
      }
      mode = 'number'
    } else { //it is a character
      if(mode === 'number') {
        tokens.push({
          type: 'number',
          value: Number(current)
        })
        current = c
      } else {
        current += c
      }
      mode = 'string'
    }
    //console.log(str[i])
    if(i === (str.length - 1) ) {
      tokens.push({
        type: mode,
        value: mode === 'string' ? current : Number(current),
      })
    }
  }

  return tokens
}

/**
 * Sorts in a human readable fashion. Nulls are forced to the bottom
 * @param {*} a string
 * @param {*} b string
 * @param {bool} invertNulls should the nulls be forced to the bottom? (true, default false)
 */
export const broadwaySort = (a, b, invertNulls = false) => {
  const aTokens = tokenizeString( isNull(a) ? null : String(a) )
  const bTokens = tokenizeString( isNull(b) ? null : String(b) )

  const maxIndex = Math.max(aTokens.length, bTokens.length)
  let compare = 0
  for(let i = 0; i < maxIndex; i++) {

    if(compare !== 0) {
      return compare
    }

    let aT = aTokens[i]
    let bT = bTokens[i]

    let debug = (message, result) => {
      return
      let __a = aT || {}
      let __b = bT || {}
      console.log(`${message} \tRESULT:${result}`)
      console.log(`\t${a}\t${i}:${__a.type}->${__a.value}`)
      console.log(`\t${b}\t${i}:${__b.type}->${__b.value}`)
    }
    const aNull = isNull(aT)
    const bNull = isNull(bT)

    if( aNull && bNull ) {
      //We're done, they're equal
      debug('Both Null')
      continue
    }

    if( aNull && !bNull) { //B exists, so A is less
      debug('A not null')
      return invertNulls ? 1 : -1
    }

    if( !aNull && bNull) { //A Exists so B is less
      debug('B not null')
      return invertNulls ? -1 : 1
    }

    const aIsString = aT.type === 'string'
    const bIsString = bT.type === 'string'

    if(aIsString && bIsString) {
      compare = compareToNullSafe(aT.value, bT.value, invertNulls)
      debug('Both Strings', compare)
      continue
    }

    if(aIsString && !bIsString) {
      debug('A String')
      return -1 //numbers first
    }

    if(!aIsString && bIsString) { //numbers first
      debug('B String')
      return 1
    }

    //we're numbers
    compare = compareToNullSafe(aT.value, bT.value, invertNulls)
    debug('Both Numbers', compare) //this is our problem
    continue
  }

  return compare
}

/**
 * returns a broadway sort function that uses the field for the sort
 * @param {*} a object
 * @param {*} b object
 * @param {*} field key
 */
export const broadwaySortField = (field) => {
	return (a,b) => broadwaySort(a && a[field], b && b[field])
}

/**
 * Sorts the numbers with the priority of
 * A...Z0-9 but breaks the sets into logical units of letters
 * and numbers.
 * example: null, 1, 1A, 1B, 1.1, 2, 2A, 3, ... 11, A
 *
 * This is the default sort operation for almost all units.
 *
 * Nulls return -1 (come before)
 * @param {*} a
 * @param {*} b
 */
export const broadwaySort_OLD = (a, b) => {
  //Are we null or undefined
  if(a === null || a === undefined) {
    if(b === null || b === undefined) {
      return 0
    } else {
      return -1
    }
  }

  if(b === null || b === undefined) {
    if(a === null || a === undefined) {
      return 0
    } else {
      return 1
    }
  }

  //Cast to strings
  a = '' + a
  b = '' + b

  //Are we numbers?
  let aNum = Number(a)
  let bNum = Number(b)

  if( !isNaN(aNum) && !isNaN(bNum) ) {
    const out = aNum == bNum ? 0 : a < bNum ? -1 : 1
    //console.log(`Straight Numeric sort: ${aNum} ${bNum} -> ${out}`)
    return out
  }

  //Do we start with numbers?
  const matchRegex = /^([0-9\.]+)/g

  const aStart = (a.match( matchRegex ) || [])[0]
  const bStart = (b.match( matchRegex ) || [])[0]

  //console.log(`\tDEBUG -> [${aStart} ${bStart}] | [${a} ${b}]`)
  if(aStart === undefined || bStart === undefined || aStart === null || bStart === null) {
    //
    if(aStart == bStart) { //we're both not numbers
      return a == b ? 0 : a < b ? -1 : 1
    } else if (aStart === undefined || aStart === null) {
      //A isn't a number so it is "less"
      //console.log(`b is number returning -1`)
      return -1

    } else { //a is a number so a is greater
      //console.log(`a is number returning 1`)
      return 1
    }
  } else {
    //sort via numbers, then by predicate
    aNum = Number(aStart)
    bNum = Number(bStart)
    let out = aNum == bNum ? 0 : aNum < bNum ? -1 : 1

    if(out != 0) {
      //console.log(`numeric sort, ${aStart}, ${bStart} -> ${out}`)
      return out
    }

    let aEndArray = a.split(matchRegex)
    let bEndArray = b.split(matchRegex)
    let aEnd = aEndArray[aEndArray.length - 1]
    let bEnd = bEndArray[bEndArray.length - 1]

    out = aEnd == bEnd ? 0 : aEnd < bEnd ? -1 : 1
    //console.log(`numeric sort predicate, { |${aEndArray}| |${bEndArray}| } | [${aEnd}, ${bEnd}] -> ${out}`)
    return out
  }
}

/**
 * Returns a sort function that returns the first
 * time a sort in the chain returns something
 * other than '0'
 *
 * @returns a sort F(a,b) function that runs the chain of other sort functions
 */

export const chainSorts = (...sortFunctions) => {
  return (a, b) => {
    if(!sortFunctions) {
      return -1 //natural order
    }

    let out = -1 //natural order

    for(let fn of sortFunctions) {
      out = fn(a, b)
      if(out !== 0){
        return out
      }
    }

    return out
  }
}

/**
 * This one is a doozey...basically it takes the fields, but you can override
 * a field with the map function to use your own custom logic.
 *
 * Essentially this is to allow you inject custom sorts into the logic without
 * breaking the reference sorts.
 *
 * returns a compare function (a,b)=>{-1,0,1} for the fields provided
 * @param invertNulls should the nulls be at the bottom? Defaults false
 * @param overrides Map<String, CompareFn<A,A>:1|0|-1> that overrides a specific field name
 * @param  {...any} fields the fields to sort by
 */
export const invertedBroadwaySortFieldFn = (invertNulls = false, overrides = {}, ...fields) => {
  return (a, b) => {
    if(!fields) {
      return 0
    }

    if(!a || !b) {
      if(!a && !b){
        return 0
      }

      if(!b) {
        return -1
      } else {
        return 1
      }
    }

    for(let field of fields) {
      const override = overrides[field]
      let out = null

      let valA = a[field] + ''
      let valB = b[field] + ''

      if(override) {
        out = override(valA, valB, invertNulls)
      } else {
        out = broadwaySort(valA, valB, invertNulls)
      }

      if(out !== 0) {
        console.log(`DEBUG SORT: `)
        return out
      }
    }

    return 0
  }
}

/**
 * Creates a sort using the default override functions from the state
 */
export const broadwaySortFieldsFnWithOverrides = (invertNulls = false, overrides = {}, ...fields) => {
  return invertedBroadwaySortFieldFn(invertNulls, overrides, ...fields)
}

/**
 * Creates a sort function without overriding from user settings....we probably
 * want to depriciate this.
 * @param  {...any} fields
 */
export const broadwaySortFieldsFn = (...fields) => {
  return invertedBroadwaySortFieldFn(false, {}, ...fields)
}

export const alphaNumericCompare = (a, b) => {
  if(!a || !b) {
    if(!a && !b){
      return 0
    }

    if(!b) {
      return -1
    } else {
      return 1
    }
  }

  if(a != b) {
    return a < b ? -1 : 1
  }

  return 0
}

export const genericStringSort = (a, b, ...fields) => {
  if(!fields) {
    return 0
  }

  if(!a || !b) {
    if(!a && !b){
      return 0
    }

    if(!b) {
      return -1
    } else {
      return 1
    }
  }

  for(let field of fields) {
    let valA = a[field] + ''
    let valB = b[field] + ''

    if(!valA || !valB) {
      if(!valA && !valB) {
        return 0
      }
      if(!valB) {
        return -1
      } else {
        return 1
      }
    }

    valA = valA.toLowerCase().trim()
    valB = valB.toLowerCase().trim()

    if(valA != valB) {
      return valA < valB ? -1 : 1
    }
  }

  return 0
}

export const genericSortFn = (a, b, ...fields) => {
  if(!fields) {
    return 0
  }

  if(!a || !b) {
    if(!a && !b){
      return 0
    }

    if(!b) {
      return -1
    } else {
      return 1
    }
  }

  for(let field of fields) {
    let valA = a[field]
    let valB = b[field]

    if(!valA || !valB) {
      if(!valA && !valB) {
        return 0
      }
      if(!valB) {
        return -1
      } else {
        return 1
      }
    }

    if(valA != valB) {
      return valA < valB ? -1 : 1
    }
  }

  return 0
}

/**
 * Merge two objects with a less than b ignoring null keys.
 * @param {*} obj
 */
export const mergeObjectsIgnoreEmpty = (a, b) => {
  if(!a && !b) {
    return {}
  }

  if(!a){
    return { ...b }
  }

  if(!b){
    return { ...a }
  }

  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  const out = {}
  for(let k of aKeys){
    const aExists = isEmptyOrWhitespace( a[k] )
    const bExists = isEmptyOrWhitespace( b[k] )

    if(!aExists && !bExists) {
      continue
    }

    if(!aExists) {
      out[k] = b[k]
    }

    out[k] = a[k]
  }

  for(let k of bKeys){
    const aExists = !isEmptyOrWhitespace( a[k] )
    const bExists = !isEmptyOrWhitespace( b[k] )

    if(!aExists && !bExists) {
      continue
    }

    if(!aExists) {
      out[k] = b[k]
    }

    out[k] = a[k]
  }

  return out
}

/**
 * Breaks an array into chunks.
 * @param {*} array
 * @param {*} size
 */
export const chunk = (array, size = 1) => {
  let groups = array.map((x,i) => {
    return i % size === 0 ? array.slice(i, i + size) : null
  }).filter(x => x)

  return groups
}

/**
 * Split a DMX representation into a universe/address format.
 *
 * @param {*} str the string
 * @return null if the split doesn't work
 *
 * Example patters are
 *
 *  null null
 * '1.123' [1, 123]
 * 'A.123' [A, 123]
 * Number(123) [123]
 * '1 / 123' [1, 123]
 *
 */
export const splitDmx = (str) => {
	if(!str) {
		return null
	}
	str = String(str)
	str = str.replace(/\s+/g, '')
	return str.split(/[/|.|\s+]/g)
}

/**
 * This will take either a numeric address or a 1/X address and
 * return a number. Returns -1 on error
 * @param {Address} str
 */
export const toNumericDmxAddress = (str) => {
	const _patch = splitDmx(str)
	if(!_patch) {
		return -1
	}
	const first = num(_patch[0], -1)
	const second = num(_patch[1], -1)

	if(_patch.length === 1 && first > -1) {
		return num(first, -1)
	}

	if(_patch.length === 2 && second > -1 && first > 0) {
		let _u = first - 1
		return (_u * 512) + second
	}

	//console.log(`DEBUG: ${first} ${second} | ${str}`)
	return -1 //we have extra data or a formatting error
}

/**
 *
 * @param {*} address the numeric address
 * @return null on error, else a string
 */
export const toUniverseSlashAddress = (address, seperator = '/') => {
	const _number = num(address, -1)

	if (_number < 0) {
		return null
	}

	const _address = _number % 512
	const _universe = Math.floor(_number / 512) + 1

	return `${_universe}${seperator}${_address}`
}

/**
 * A simple conversion to an absolute address
 * @param {*} universe number the universe. 0 or missing counts as 1 (0 + address)
 * @param {*} address number the address. 0 or missing counts as 1 (0 + address)
 * @returns number the absolute address (1) as starting point
 */
export const toAbsoluteAddress = (universe, address) => {
  if(isEmptyOrWhitespace(universe)) {
    universe = 1
  }

  if(isEmptyOrWhitespace(address)) {
    address = 1
  }

  return (num(universe, 1) - 1) * 512 + num(address, 1)
}

/**
 * Returns an object with the universe (1 is the start) slash
 * the address.
 * @param {Any Address Format} address returns null for an invalid address
 */
export const toUniverseAndAddress = (address) => {
  if( isEmptyOrWhitespace(address) ) {
    return address
  }

  const _number = toNumericDmxAddress( address )

	if (_number < 0) {
		return null
	}

	const _address = _number % 512
	const _universe = Math.floor(_number / 512) + 1

	return {
    universe: _universe,
    address: _address,
  }
}


export const reformatAsUniverseSlashAddress = (address, seperator = '/') => {
	const number = toNumericDmxAddress(address)
	if(number < 1) {
		return null
	}

	return toUniverseSlashAddress(address, seperator)
}

const LOWER_CASE_ALPHABET = [...'abcdefghijklmnopqrstuvwxyz']
const UPPER_CASE_ALPHABET = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
/**
 * Appends a letter to the end of this string, it starts with the
 * last character and cycles through.
 *
 * @param {String} str
 */
export const appendLetter = (str) => {
	if( isNull(str) ){
		return 'A'
	}

	const val = String(str)
	if(val.length < 1) {
		return 'A'
	}

	let array = val.split('')
	const last = array[array.length - 1]
	let index = UPPER_CASE_ALPHABET.indexOf(last)
	if(index < 0) {
		index = LOWER_CASE_ALPHABET.indexOf(last)
	}

	//We always use upper case

	if(index < 0) {
		return str + 'A'
	} else if (index === 25) {//Z
		array.splice(array.length -1, 1, 'AA')
		return array.join('')
	}

	array.splice(str.length - 1, 1, UPPER_CASE_ALPHABET[index + 1])
	return array.join('')
}

/**
 *
 * @param {Callback} onComplete(int)
 *
 * @returns the size in bytes remaining for the local storage on the computer.
 */
export const calculateLocalStorageSize = (onComplete, key = 'persist:root') => {

  return onComplete(5000000)
  const getLeftStorageSize = () =>  {
    var itemBackup = localStorage.getItem(key)
    var increase = true
    var data = "1"
    var totalData = ""
    var trytotalData = ""
    let i = 0
    while (true) {
      try {
        i++
        if(i % 1000 === 0) {
          console.log('storage-calculating...')
        }
        trytotalData = totalData + data
        localStorage.setItem(key, trytotalData)
        totalData = trytotalData
        if (increase) data += data
      } catch (e) {
        if (data.length < 2) {
          //var storageSize = localStorage.getItem("").length
          //console.log("LIMIT REACHED: ", totalData.length);
          //console.log(e);
          break;
        }
        increase = false
        data = data.substr(data.length / 2)
      }
    }
    try {
      localStorage.setItem(key, itemBackup)
    } catch(e) {
      console.error(e)
      return 0
    }
    return totalData.length
  }

  var storageLeft = getLeftStorageSize()
  onComplete(storageLeft)
}
/**
 * A simple version comparison function
 * @param {} a
 * @param {*} b
 */
export const versionCompare = (a, b) => {
	const vA = a.split('.')
	const vB = b.split('.')

	//These are actually error states...
	if(vA.length < vB.length) {
		return -1
	} else if (vB.length < vA.length) {
		return 1
  }

	const base = (n1, n2) => {
		const x1 = isNum(n1)
		const x2 = isNum(n2)
		if(x1 && x2) {

			return compareToNullSafe(num(n1), num(n2))
		}

		if(x1) {
			return 1
		}

		if(x2) {
			return -1
		}

		return 0
	}

	let out = base(vA[0], vB[0])
	if(out !== 0)  {
		return out
	}

	out = base(vA[1], vB[1])
	if(out !== 0)  {
		return out
	}

	return base(vA[2], vB[2])
}


export const meter = (fnStartEnd, count, setSize, delay) => {
  const sets = Math.floor(count / setSize)
  const additional = count % setSize > 0 ? 1 : 0
  let iterations = sets + additional
  console.log('-------------------------------------')
  console.log(`ITERATIONS -> ${iterations} ${sets} ${additional} of ${count}`)
  for(let i = 0; i < iterations; i++) {
    const start = i * setSize
    const baseEnd = (i + 1) * setSize - 1
    let end = Math.min( baseEnd, count )
    if(baseEnd == count - 1) {
      end = count
    }

    if(delay === 0) {
      fnStartEnd(start, end)
    } else {
      setTimeout( ()=>{
          console.log(`\t${i}| ${start}=>${end}`)
          fnStartEnd(start, end)
        }, delay * (i + 1)
      )
    }
  }
}

export const jsonToTable = (any, deep = true) => {
  if(!any) {
    return '<table><thead><thead><tbody><tr><td>No Data...</td></tr><tbody></table>'
  }

  const keys = Object.keys(any).sort()
  let output = `
<table class="json-table">
  <thead>
    <tr>
      <th>Key</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
`
  for(let key of keys) {
    let val = any[key]

    if(typeof val === 'object' && val !== null && deep) {
      val = jsonToTable(val, false) //prevent infinite nests
    } else { //Just stringify it and strip the quotes
      val = JSON.stringify(any[key], null, 2)

      if(val !== undefined && val !== null) {
        if(val[0] === '"') {
          val = val.substr(1, val.length - 1)
        }

        if(val[val.length - 1] == '"') {
          val = val.substr(0, val.length - 1)
        }
      }
    }

    output += `
<tr>
  <td>${key}</td>
  <td>${val}</td>
</tr>
`
  }
  output += `</tbody></table>`
  return output
}
