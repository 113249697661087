
let Colors = {
  CLEAR: 'rgba(128,128,128,0.0)',
  WHITE: 'rgba(255,255,255,1.0)',
  BLACK: 'rgba(0,0,0,1.0)',
  GREEN: 'rgba(6,214,20,1.0)',
  PURPLE: 'rgba(204, 0, 153, 1.0)',
  ORANGE_CONSOLE: 'rgba(204, 102, 0, 1.0)',
  RED: 'rgba(214,20,6,1.0)',
  DISABLED: 'rgba(0,0,0, 0.5)',
  SHADOW_BLACK: '0px 2px rgba(0,0,0,0.2)',
  SHADOW_HEADER: '0px 3px 3px rgba(0, 0, 0, 0.4) ',
  LA_BLUE: '#0d47a1',
  LA_LIGHT_BLUE: '#0075ff',
  LA_LIGHT_BLUE_ALPHA_25: 'rgba(0, 117, 255, 0.25)',
  BACKGROUND: '#ffffff',
  ROW_ALT: 'rgba(247, 247, 247, 0.4)',
  MATERIAL_HIGHLIGHT: 'rgb(0, 188, 212)',
  CONTENT_HOVER: '#bbd9fd',
  MATERIAL_PRIMARY: '#3f51b5',
  MATERIAL_SECONDARY: 'rgb(255, 64, 129)',
  DELETED_BACKGROUND: 'rgba(255, 0, 0, 0.2)',
  DELETED_TEXT: 'rgb(255,0,0)',
  
  //A SUBTLE HIGHLIGHT
  SELECTED_BACKGROUND_OVERLAY: 'rgba(0,117,255, 0.03)',

  //DYNAMIC THEME COLROS
  CONTROL_LIGHT: '#0075ff',
  CONTROL_INACTIVE: 'rgba(128,128,128,0.5)',

  //TABLES
  TABLE_SELECTED: 'rgba(255, 255, 0, 0.5)',
  TABLE_FONT: 'rgba(0,0,0,1.0)',
  TABLE_HEADER: 'rgba(0,0,0,1.0)',
  TABLE_SEPERATOR: 'rgba(0,117, 255, 1.0)',
  TABLE_BORDER: 'rgba(0,0,0,1.0)',
  TABLE_CLEAR: 'rgba(0,0,0,0.0)',
  TABLE_ROW_ALT: 'rgba(128,128,128,0.15)',
  TABLE_EDITOR_BG: 'rgba(105, 251, 89, 0.25)',
  TABLE_EDITOR_BORDER: 'rgba(105, 251, 89, 1.0)',
  TABLE_FONT_LOCKED: 'rgba(128, 0, 128, 1.0)',

  //UNDO MODULE
  BUTTON_UNDO: '#0075ff',
  BUTTON_REDO: 'rgb(255, 64, 129)',
  UNDO_LABEL: 'orange',

  //OSC COLORS
  EOS_SEND: 'orange',
  EOS_RECEIVE: 'rgb(0, 132, 255)',
  EOS_INFO: 'rgb(255, 237, 204)',
  QLAB_SEND: 'rgb(128, 0, 128)',
  QLAB_RECEIVE: 'rgb(0, 128, 0)',
  QLAB_INFO: 'rgb(255, 204, 255)',

}

export default Colors