import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { 
  showDialog
} from './../reducers/InterfaceLogic'
import Standard from '../../components/Layouts/Standard'

import translate from '../../shared/language/Translate'

import {
  Button,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'

import SmartToggle from './../../components/Button/Toggle'

//Logic
import { 
  READ_PATCH_ENABLED,
  updateOscKey,
  connectToEos,
  OSC_UPDATE_KEY,
} from '../reducers/OscLogic'

import { 
  disconnectEos,
  eosSyncCueList, 
  qlabDumpCueList, 
  eosOscHeartbeat,
  eosRequestPatch,
} from './../reducers/ElectronLogic'

import OscConsole from './OscConsole'
import EosConnection from './EosConnetion'
import QLabConnection from './QLabConnection'
import {
  Card, CardSeries
} from './../../components/Cards'

const EosIcon = props => {
  return (
    <Icon>
      <img 
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
        src='/img/eos-logo-transparent.png' 
        alt='ETC' 
      />
    </Icon>
  )
}

const QLabIcon = props => {
  return (
    <Icon>
      <img 
        style={{
          width: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
        }}
        src='/img/qlab-icon.png' 
        alt='ETC' 
      />
    </Icon>
  )
}

class IntegrationPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItem: 0,
    }
  }

  setMenu(index) {
    this.setState({
      selectedItem: index,
    })
  }

  tryDumpCueSheet() {
    //Add logic to make sure we're connected first.
    // console.log('trying to connect...')
    // this.props.dispatch(tryEosConnection())
  }

  renderLogs() {
    return (
      <React.Fragment>
        <h1>Not Implemented</h1>
        <code>
          { JSON.stringify(this.props.osc, null, 2) }
        </code>
      </React.Fragment>
      
    )
  }

  render() {
    const T = this.props.strings
    let { osc, dispatch } = this.props
    
    const OSC_LIST = [
      {
        key: 'eos',
        fn: () => <EosConnection />,
        icon: <EosIcon />
      }, {
        key: 'qLab',
        fn: () => <QLabConnection />,
        icon: <QLabIcon />,
      }, 
      // {
      //     key: 'logs',
      //     fn: () => this.renderLogs(),
      //     icon: null,
      // }
    ]

    const renderListItem = (settings, index) => {
      return (
        <ListItem
          key={settings.key}
          button
          selected={ this.state.selectedItem == index }
          onClick={e => {
            this.setMenu( index )
          }}
        >
          <ListItemIcon>
            { settings.icon }
          </ListItemIcon>
          <ListItemText 
            primary={ T.get( settings.key) }
          />
        </ListItem>
      )
    }

    const renderComponent = (index) => {
      const base = OSC_LIST[index]
      if(!base || !base.fn) {
        return (
          <React.Fragment>
            NOT FOUND {this.state.selectedItem} base->[{!!base}] 
          </React.Fragment>
        )
      }

      return base.fn()
    }

    return (
      <Standard>
        <div
          style={{
            display: 'flex',
            flex: 1,
            maxHeight: '100%',
            flexDirection: 'row',
          }}
        >
          <Paper 
            style={{
              flex: 0,
              maxHeight: '100%',
              minWidth: 250,
              overflowY: 'auto'
            }}
          >
            <List 
              style={{
                maxWidth: 400,
                overflowX: 'none',
              }}
            >
              <ListSubheader>
                { T.get('connections') }
              </ListSubheader>

              <Divider />

              { OSC_LIST.map( (el, i) => {
                return renderListItem(el, i)
              })}

              <Divider />
              
              {/* <ListSubheader>
                { T.get('actions') }
              </ListSubheader>

              <div 
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  margin: 0,
                  paddingLeft: 8,
                  paddingRight: 8,
                  alignContent: 'center',
                  alignSelf: 'center',

                }}
              >
                <Button 
                  variant='outlined'
                  color={'secondary'}
                  style={{
                    flex: 1,
                  }}
                  onClick={ () => {
                    this.props.dispatch(
                      saveState(this.props.show.currentShow || 'unknown')
                    )
                  } }
                >
                  { T.get('reconnect') }
                </Button>
                
              </div> */}
              
            </List>
          </Paper>

          <div
            style={{
              flex: 1,
              display: 'flex',
              overflowY: 'auto',
              paddingBottom: 20,
              paddingLeft: 8,
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              { renderComponent(this.state.selectedItem) }
            </div>
          </div>
        </div>
      </Standard>
    )

    if(!osc) {
      osc = {}
    }
    const readPatch = !!osc[READ_PATCH_ENABLED] 

    const packets = [
      {time: new Date(), type: 'info', app: 'eos', message: '/path/here', args: ['1', 2, 3]},
      {time: new Date(), type: 'info', app: 'qlab', message: '/path/here', args: ['1', 2, 3]},
      {time: new Date(), type: 'receive', app: 'eos', message: '/path/here', args: ['1', 2, 3]},
      {time: new Date(), type: 'receive', app: 'qlab', message: '/path/here', args: ['1', 2, 3]},
      {time: new Date(), type: 'send', app: 'eos',message: '/path/here', args: ['1', 2, 3]},
      {time: new Date(), type: 'send', app: 'qlab',message: '/path/here', args: ['1', 2, 3]},
    ]    

    const lines = `packet from...\r\npacket w from...\r\nupdating osc k...`
    const cardStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      minWidth: 440,
      flexGrow: 'unset',
      padding: 16,
      marginLeft: 16,
    }
    return (
        <Standard>
          <h1>OSC Connections</h1>  
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Paper
              style={cardStyle}
            >
              <EosConnection />
            </Paper>

            <Paper
              style={cardStyle}
            >
              <h3 style={{marginBottom: '8px'}}> 
                ETC Eos OSC Connection
              </h3>
              <h4 style={{marginBottom: '8px'}}> 
                (Port 3032 only TCP/IP)
              </h4>

              <h4>
                { osc.on ? 'Connected' : 'Not Connected' }
              </h4>
            {/* <TextField 
              style  = {{ width: '144px' }}
              floatingLabelText = { T.get('eosName') }
              hintText = 'ETC Ion'
              label= 'Console Name'
              value = { osc.eosName }
              onChange = { (e) => {
                dispatch( updateOscKey('eosName', e.target.value) )
              } }                
            /> */}
              <div>
                <TextField 
                  style={{
                    width: 196,
                    marginRight: 8,
                  }}
                  disabled = { osc.on }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value = { osc.eosHost }
                  label = { T.get('eosIpAddress') }
                  helperText = 'ex: 10.101.100.101 (Typical Ion)'
                  onChange = { (e) => {
                    dispatch( updateOscKey('eosHost', e.target.value) )
                  } }
                />
                <TextField
                  style={{
                    width: 196,
                    marginRight: 8,
                  }}
                  select
                  disabled = { osc.on }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label = { T.get('eosOscVersion') }
                  value = { osc.eosOscVersion }
                  helperText = { osc.eosOscVersion == '1.0' ? 'Packet Length Headers (1.0)' : 'SLIP (1.1)'}
                  onChange = { (e) => {
                    dispatch( updateOscKey('eosOscVersion', e.target.value) )
                  } }
                >
                  <MenuItem value={'1.0'}>
                    OSC 1.0
                  </MenuItem>
                  <MenuItem value={'1.1'}>
                    OSC 1.1
                  </MenuItem>
                </TextField>

                <Button
                  variant='contained' 
                  color={ !osc.on ? 'primary' : 'secondary'}
                  onClick = { e => {
                    if( osc.on ) {
                      this.tryEosDisconnect()
                    } else {
                      this.tryEosConnect()
                    }
                  } }
                >
                  { T.get(osc.on ? 'disconnect' : 'connect' ) }
                </Button>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  variant='contained' 
                  color='secondary'
                  onClick = { e => this.sendEosHeartbeat() }
                >
                  { 'Send Heartbeat' }
                </Button>

                <Button 
                  variant='contained'
                  color='secondary'
                  onClick = { e => {
                    if( confirm( T.get('eosSyncConfirm') )) {
                      this.props.dispatch( eosSyncCueList(osc.eosHost, 3032, '{page} | {label}') )
                    }
                  } }
                >
                  { T.get('syncCueSheet') }
                </Button>

                
                <SmartToggle 
                  onChange = { booleanVal => {
                    dispatch( updateOscKey(READ_PATCH_ENABLED, booleanVal) )
                  }}
                  label={ T.get('readPatchFromEos') }
                  value={readPatch}
                />

                <Button 
                  variant='contained'
                  color='primary'
                  onClick = { e => {
                    dispatch( eosRequestPatch() )
                  } }
                >
                  { T.get('requestPatchFromEos')}
                </Button>
                
              </div>
            </Paper>

            <Paper style={cardStyle}>
              <h3 style={{marginBottom: '8px'}}> Figure 53 QLab OSC Connection </h3>
              <TextField 
                style  = {{ width: '144px' }}
                label = { T.get('workspace') }
                hintText = 'my-show-file'
                ref = { x => this.qlabWorkspace = x }
                value = { osc.qlabWorkspace }
                onChange = { (e) => {
                  dispatch( updateOscKey('qlabWorkspace', e.target.value) )
                } }
              />

              <TextField 
                style  = {{ width: '196px' }}
                label = { T.get('qlabHost') }
                hintText = '127.0.0.1:53000'
                ref = { x => this.qlabServer = x}
                value = { osc.qlabServer }
                onChange = { (e) => {
                  dispatch( updateOscKey('qlabServer', e.target.value) )
                } }
              />

              <Button 
                color={ osc.on ? 'secondary' : 'primary' }
                onClick = { e => this.tryEosConnect() }
                disabled = { true }
              >
                { T.get(osc.on ? 'disconnect' : 'connect' ) }
              </Button>

              <Button 
                variant='contained'
                color='secondary'
                onClick = { e => {
                  if( confirm( T.get('qlabSyncConfirm') )) {
                    const workspace = osc.qlabWorkspace
                    let parts = (osc.qlabServer || ':').split(':')

                    if(!workspace) {
                      alert('You must specify a workspace')
                      return
                    }

                    if(!parts || !parts.length == 2) {
                      alert('You must specify a server and port. This looks like 127.0.0.1:53000 (localhost:53000)')
                      return
                    }

                    if(!parts[0]) {
                      alert('You must specify a server (look at QLab\'s OSC Settings for the address')
                      return
                    }

                    if(!parts[1]) {
                      alert('You must specify a port (QLab is typically set to 53000)')
                      return
                    }
                    this.props.dispatch( qlabDumpCueList(workspace, parts[0], parts[1], '{page} | {label}') )
                  }
                } }
              >
                { T.get('dumpCueSheet') }
              </Button>

              <span className='status'> 
                { osc.status }
              </span>      

              <Button
                variant='contained'
                onClick= {e => {
                  this.props.dispatch ( showDialog( T.get('qlabIdHelp'), T.get('qlabIdHelpData'), ()=>{}, T.get('ok') ) )
                }}
              >
                { T.get('help') }
              </Button>
              
            </Paper>  

            <Paper>
              <h3>Tracking</h3>
              <ul>
                <li>Cue List {this.props.osc.list}</li>
                <li>Cue {this.props.osc.cue}</li>
              </ul>
            </Paper>
          </div>
          
          <div style={{height:24}}></div>
          <OscConsole 
            sendText={ T.get('sendEos') }
            lines={packets}
            enabled={ osc.on }
            onSent={()=>{alert('not enabled')}}
          />
        </Standard>
    )
  }
}

// export default connect(s => {
//   return {
//     cues : s.cues.cues,
//     note: s.cues.note,
//     autoSave: s.cues.autoSave,
//     deletePrompt: s.cues.deletePrompt,
//     dragEnabled: s.cues.dragEnabled,
//     loaded: s.cues.loaded,
//     show: s.show
//   };
// })(translate('CueSheet')(CueSheet));

export default connect(s => {
  const osc = s.osc
  return {
    osc: {
      ...osc,
      eosOscVersion: osc.eosOscVersion ? osc.eosOscVersion : 1.0,
    }
  }
})(translate('OSC')(IntegrationPage))