export default {
  totalMessages: 'There are a total of {0} messages in the current log.',
  time: 'Time',
  level: 'Level',
  module: 'Module',
  message: 'Message',
  log: 'Event Log',
  clearLog: 'Clear Log',
  INFO: '[INFO]',
  DEBUG: '[DEBUG]',
  ERROR: '[ERROR]',
  WARN: '[WARNING]',
}