import pouch from './../pouch';
import Errors from './../Errors';
import { cueReset, loadCuesOLD } from './CueLogic';
import { 
	instrumentReset,
	legacyLoadPouchDbInstruments
 } from './InstrumentLogic'

import { 
	vectorworksCacheReset, 
	purgeVectorworksNotActive,
} from './VectorworksCache'

import { noteReset } from './NotesLogic';
import { showDialog } from './InterfaceLogic';
import { redirect } from '../Utility';
import { electronSave, isConnected } from './ElectronLogic'

export const SETTINGS_RECORD_KEY = 'LA_SETTINGS';
export const SHOW_RECORD_KEY = 'SHOW_SETTINGS';
export const LOAD_SHOWS = 'SHOW_LOAD_SHOW';
export const SAVE_SHOW = 'SHOW_SAVE_SHOW';
export const SHOW_SETTINGS_CHANGED = 'SHOW_SETTINGS_CHANGED';
export const SETTINGS_CHANGED = 'SYSTEM_SETTINGS_CHANGED';

import PACKAGE_JSON from './../../package.json'
import {
	Translator 
} from './../../shared/language/Translate'

import {
	showConfirm
} from './InterfaceLogic'
import { 
	compareToNullSafe, 
	isNum, 
	versionCompare,
	calculateLocalStorageSize,
} from '../../shared/Utility'

const OLD_SHOW_TRIGGER = '0.0.0-ALPHA'
const _loadSetting = (dispatch, key, action, error, debug)=>{
	pouch.db().get(key)
		.then(val=>{

			dispatch({
				type: action,
				settings: val || {}
			} );

		}).catch(err=>{

			const defaults = {
				_id: key,
				...debug
			};

			pouch.db().put(defaults).then(resp=>{
				defaults._rev = resp._rev;
				dispatch({
					type: action,
					settings: defaults  || {}
				} );
			}).catch(errorBig=>{
				Errors.reactError(error, errorBig);
			});
		});
};

export const loadShowSettings = ()=>{
	
	return dispatch => {
		_loadSetting(
			dispatch, 
			SHOW_RECORD_KEY, 
			SHOW_SETTINGS_CHANGED, 
			'Unable to load show settings', 
			{ 
				red_herring: 'fish'
			} 
		);
	};
};

export const loadSystemSettings = ()=>{
	return dispatch => {
		_loadSetting(
			dispatch, 
			SETTINGS_RECORD_KEY, 
			SETTINGS_CHANGED, 
			'Unable to load show settings', 
			{ 
				tuna: 'fish'
			} 
		);
	};	
};

const _mergeSettings = (key, type, settings) => {
	return (dispatch, getState) => {
		if(!settings._id) {
			settings._id = key;
		}

		const merge = (record)=>{
			if(!record){
				settings._id = key;
			} else {
				const { _id, _rev, ...data } = settings;
				settings = {...record, ...data };
			}

			pouch.db().put(settings).then(resp=>{
				dispatch({
					type: type,
					settings: {
						...settings, 
						_id: resp._id, 
						_rev: resp._rev 
					}
				});
			}).catch(error=>{
				return dispatch(Errors.reactError('Settings failed to merge', error));
			});
		};
		
		//Update the pointer
		pouch.db().get(key)
			.then(docs=>{
				merge(docs);
			}).catch( error=>{
				merge();
			});
	};
};

/**
 * Merge the show settings object with the settings passed to this element.
 */
export const mergeShowSettings = (settings)=>{
	return _mergeSettings(SHOW_RECORD_KEY, SHOW_SETTINGS_CHANGED, settings);
};

/**
 * Merge the system settings object with the settings passed to this element.
 * (This is persistent between shows)
 */
export const mergeSettings = (settings)=>{
	return _mergeSettings(SETTINGS_RECORD_KEY, SETTINGS_CHANGED, settings);
};

export const saveShow = () => {
	return (dispatch, getState) => {
		const state = getState()
		const lang = state && state.language && state.language.current || 'en'
		const T = Translator(lang, 'show')
		let showName = (state.show || {}).currentShow || 'unknown'
		dispatch(
			saveState(showName)
		)
	}
}

export const saveState = (name)=> {
	return (dispatch, getState) =>{

		//Need to load a bunch of data here...
		const database = pouch.db().allDocs({
			include_docs: true,
			attachments: true
		}).then(result=>{
			const state = getState()
			if(!name) {
				name = state.show.show.currentShow || 'New Show'
			}
			let show = {
				meta: {
					saved: new Date(),
					savedBy: state.user.name,
					application: 'Light Assistant',
					url: 'http://www.lightassistant.com',
					version: PACKAGE_JSON.version,
					database: pouch.current()
				},
				...state,
				offset: result.offset,
				total_rows: result.total_rows,
				rows: result.rows,
			}

			const cues = show.cues.cues
			delete show._persist
			//delete show.instruments
			//delete show.cues
			delete show.log
			delete show.user
			delete show.ui
			delete show.routing

			if (isConnected()) { //Save via electron---works on larger files
				return dispatch (
					electronSave ( JSON.stringify(show, null, 2), name + '.la')
				)
			} else { //Fallback to Web method--fails on large documents (over 2MB--so anything with images)
				//FIXME we appear to be limited to 2MB here.
				console.log('------------------START SHOW FILE SAVE')
				console.log(`
				This log message is a work-around for the 2MB limit in Chrome/Safari/Others 
				for generated Javascript. If we include images then it is easy to break 2MB 
				and cause a timeout on the download (unrecoverable). The app will bypass this
				bug because it uses a native system call to save the file rather than the front
				end but until we can "chunk" this or use another library to fix it this will allow
				show data to be recovered by the end user--with difficulty.
				
				Copy and paste the output between START/END which is the data-structure. You can then
				clean this up into JSON which can be read by Light Assistant.`)
				console.log('------------------START SHOW FILE SAVE')
				console.log(show) //a fallback method to save
				console.log('------------------END SHOW FILE SAVE')
				const a = document.createElement('a');
				const blobData = new Blob([JSON.stringify(show, null, 2)], { type: 'text/json' })
				const blobUrl = URL.createObjectURL(blobData)
				a.href = blobUrl;
				a.download = name + '.la';
				a.innerHTML = 'download TEMP';

				const container = document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				dispatch({
					type: SAVE_SHOW
				});
			}

		}).catch(err => 
			dispatch(Errors.reactError('Unable to save show', err))
		);
	};
};

export const createShowStateThen = (callback) => {
	if(!callback) {
		console('ShowLogic::createShowStateThen called with no callback, aborting')
		return
	}
	return (dispatch, getState) =>{

		//Need to load a bunch of data here...
		const database = pouch.db().allDocs({
			include_docs: true,
			attachments: true
		}).then(result=>{
			const state = getState()

			let show = {
				meta: {
					saved: new Date(),
					savedBy: state.user.name,
					application: 'Light Assistant',
					url: 'http://www.lightassistant.com',
					version: '0.0.0-ALPHA',
					database: pouch.current()
				},
				...state,
				offset: result.offset,
				total_rows: result.total_rows,
				rows: result.rows,
			}

			delete show._persist
			delete show.instruments
			delete show.cues
			delete show.log
			delete show.user
			delete show.ui
			delete show.routing
			
			callback(show)

		}).catch(err => 
			dispatch(Errors.reactError('Unable to save show', err))
		)
	}
}

export const loadShow = (data, onComplete) => {
	return (dispatch, getState) =>{
		if(!data || !data.rows){
			dispatch(showDialog('ERROR', 'Failed to load, no data rows'));
			return;
		}
		
		pouch.change('no_user', data.meta.database)
			.then(_ => {
				const rows = data.rows.map(r => {
					const out = r.doc;
					delete out._rev;
					return out;
				});
				pouch.db().bulkDocs(rows).then(r => {
					console.log('----------------NEW ROWS')
					console.log(r)
					console.log('----------------END LOAD')
					dispatch( cueReset() )
					dispatch( noteReset() )
					dispatch( instrumentReset() )

					let override = { ...data }

					//Pouch DB and Show File stuff
					delete override.meta
					delete override.offset
					delete override.total_rows
					delete override.rows

					//Load a new state, then do the conditional loads...
					dispatch( {
						type: 'OVERRIDE_STATE',
						override: override,
					})

					//loadCues from old LA
					const soOldItsAnAlpha = versionCompare('0.6.0', data.meta.version) > 0
					//Load instruments from pouchDB
					const oldInstruments = versionCompare('0.8.4', data.meta.version) > 0

					if(soOldItsAnAlpha) {
						dispatch( loadCuesOLD((documents) => {
							alert(`Cues imported from version ${data.meta.version}. Minor data inconsistencies may be preset.`)
							console.log(documents)
							override.cues = {
								cues: documents,
							}
						}) )
					}

					if(oldInstruments) {
						dispatch( legacyLoadPouchDbInstruments(()=>{
							alert(`Instruments imported from version ${data.meta.version}. Minor data inconsistencies may be preset.`)
						}) )
					}

					//console.log(override)

					if(onComplete) {
						onComplete()
						return
					} else {
						dispatch( 
							showDialog(
								'Success!', 
								'The show file was successfully loaded!', 
								redirect('/'), 
								'GO') )

						dispatch({
							type:LOAD_SHOWS,
							title: data.meta.saved,
							message: 'Saved by: ' + data.meta.savedBy
						})
					}
				}).catch(err => {
					dispatch(showDialog('ERROR', err.message || err));
					console.log(err);
				}); 

			}).catch(err=> 
				Errors.reactError('[CRITICAL] Error changing show at bulk insert.')
			);
	};
};

/**
 *
 * Purge all instrument data from the show file, but don't adjust the 
 * route. Just force a refresh. If you provide a filter, that filter will be used
 * on the Object.doc rather than returning false.
 * 
 * As such, it will only work on type='instrument'
 * 
 * @param {Function<Document>} filter 
 */
export const purgeInstruments = (filter) => {
	return dispatch => {
		dispatch( createShowStateThen( show => {
			if(show.rows) {
				show.rows = show.rows.filter( obj => {
					if(obj.doc) {
						if(obj.doc.type == 'instrument') {
							if(filter) {
								return filter(obj.doc)
							} else {
								return false
							}
						}
					}
					return true
				})
			}

			//FIXME, the "location.reload" is pretty hacky, maybe we have a better way to do it?
			dispatch( loadShow(show, ()=>{} ) ) //()=>{ location.reload() }) )
		} ) )
	}
}

export const newShow = (reload)=>{
	return (dispatch, getState) => {
		pouch.change('no_user')
			.then(resp => {
				dispatch( noteReset() )
				dispatch( cueReset() )
				dispatch( instrumentReset() )
				dispatch( vectorworksCacheReset() )
				dispatch({
					type:LOAD_SHOWS,
					title: 'New Show',
				})
			})
			.then(()=>{
				setTimeout(()=>{
					if(reload) {
						window.location.reload(true)
					} else {
						dispatch( 
							showDialog(
								'Success!', 
								'Show data was cleared', 
								redirect('/'), 
								'GO') )
					}
				}, 500)
			})
			.catch(error => {
					Errors.reactError('[CRITICAL] Error changing show | clean DB');
			});
	};
};

export const purgeNonCriticalData = () => {
	return dispatch => {
		dispatch( purgeVectorworksNotActive() )
	}
}

export const estimateFileSize = (state) => {

	const bytes = obj => {
		return new TextEncoder().encode( JSON.stringify(obj) ).length
	}
	const total = bytes(state)
	const vw = bytes(state.vectorworks)
	const cues = bytes(state.cues)
	const instruments = bytes(state.instruments)
	const notes = bytes(state.notes)

	const estimates = {
		all: total,
		vectorworks: vw,
		cues: cues,
		instruments: instruments,
	}
	return new Promise( (resolve, reject) => {
		//resolve(estimates)
		//HARD CODED TO RETURN 5 MB
		calculateLocalStorageSize( size => {
			estimates.remaining = size
			estimates.available = size
			resolve(estimates)
		})
		
	})
}

export const estimateFileSizeFromDispatch = (callback) => {
	return (dispatch, getState) => {
		const state = getState()
		estimateFileSize(state)
			.then(r => callback(r))
	}
}

const INITIAL_STATE = {
	show: {},
	system: {},
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
		case SAVE_SHOW: {
			return state;
		}

		case LOAD_SHOWS: {
			return INITIAL_STATE;
		}

		case SHOW_SETTINGS_CHANGED: {
			return {
				...state,
				show: {
					...state.show,
					...action.settings,
					loaded: true
				},
			};
		}

		case SETTINGS_CHANGED: {
			return {
				...state,
				system: {
					...state.system,
					...action.settings,
					loaded: true
				},
			};
		}

		default: {
			return state;
		}
  }
};
