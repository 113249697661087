import 'whatwg-fetch'

import React from 'react'
import ReactDOM from 'react-dom'
import FastClick from 'fastclick'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import { connect, Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import reduceReducers from 'reduce-reducers'
import thunk from 'redux-thunk'

import reducers from './reducers'
import router from './router'
import history from './history'
import TranslationWrapper from './../shared/language/TranslationWrapper'

//Material Design
//import injectTapEventPlugin from 'react-tap-event-plugin'
import { Router, Switch, Route, Link } from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

//Redux Persist
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import localForage from 'localforage'
import HomePage from '../shared/language/en/HomePage'
import Cookies from 'js-cookie'

import {
  showConfirm,
  showThreeAction,
} from './reducers/InterfaceLogic'

import {
  estimateFileSize,
  saveState,
  purgeNonCriticalData
} from './reducers/ShowLogic'

import {
  reconnectToEos,
} from './reducers/ElectronLogic'

import {
  updateOscKey,
  updateStatus,
} from './reducers/OscLogic'

import {
  formatUnicorn,
} from './../shared/StringUtil'

import {
  num
} from './../shared/Utility'

import {
  applyStorageListener
} from './persist/Storage'

//SAGAS
import SagaSync from './sagas/SagaSync'
import SagaRefreshWorksheet from './sagas/SagaRefreshWorksheet'
import GlobalEvents from './GlobalEvents'

//Add fonts.icons etc...
// import 'material-design-icons/iconfont/material-icons.css'
// import 'typeface-roboto/index.css'

//App starts
//let routes = require('./routes.json').default // Loaded with utils/routes-loader.js
let routesJSON = require('./routes.json')
const container = document.getElementById('react-container')

const logger = store => next => action => {
  //LOTS OF LOGGING
  //console.log('[DISPATCHING] %s', action.type, JSON.stringify(action) )
  //SOME LOGGING
  console.log('[DISPATCHING] %s', action.type)
  const result = next(action)
  console.log('[NEXT STATE]', store.getState())
  return result
}

const crashReporter = store => next => action => {
  try {
    return next(action);
  } catch (err) {
    console.error('Caught an exception!', err)
    // Raven.captureException(err, {
    //   extra: {
    //     action,
    //     state: store.getState()
    //   }
    // });
    throw err
  }
}

const combined = combineReducers(Object.assign({}, {
    ...reducers,
  }
))

import light from './themes/light'
import dark from './themes/dark'
import amber from './themes/darkAmber'

const themeLight = createMuiTheme(light)
const themeDark = createMuiTheme(dark)
const themeDarkAmber = createMuiTheme(amber)

window.theme = themeLight
GlobalEvents(window)

const changeTheme = (type, reload = true) => {
	type = type || 'dark'
	Cookies.set('theme', type || 'light')
	if(type === 'dark') {
		window.theme = themeDark
		window.themeType = 'dark'

	} else if(type == 'darkAmber') {
		window.theme = themeDarkAmber
		window.themeType = 'darkAmber'

	} else {
		window.theme = themeLight
		window.themeType = 'light'
	}

	if(reload) {
		location.reload()
	}
}


const reducer = reduceReducers(combined, 
  (state, action) => {
    if('BUG_REPLACE_STATE' === action.type) {
      return action.replacement
    }

    if('persist/REHYDRATE' === action.type) {
      setTimeout(()=>{
        console.log('----------------HACK FIRED POST REHYDRATE')
        window.REDUX_STORE.dispatch( 
          (dispatch, getState) => {
            const _state = getState()
            if(window.IPC) {
              if(_state.osc && _state.osc.on) {
                dispatch( reconnectToEos() )
              } else {
                dispatch( updateStatus('[OSC Startup: DISCONNECTED]', ))  
              }
              console.log('\t------------ELECTRON')
            } else {
              //Shut off OSC
              dispatch( updateOscKey('on', false) )
              dispatch( updateStatus('[WebApp, OSC disabled]', ))
              console.log('\t------------WEB')
            }
          } 
        )
      }, 1000)
    }

    if('override/STARTUP' === action.type) {
      let copy = {
        ...state
      }
      if(window.IPC) {
      }
    }

    if('OVERRIDE_STATE' === action.type) {
      const override = action.override || {}
      let newState = {
        ...state,
        ...override,
      }

      return newState
    }

    if('CHANGE_THEME' === action.type) {
			setTimeout(()=>{
				changeTheme(action.theme)
			}, 200)

			return {
				...state
			}
    }

    return state
  } 
)

let storageErrorTime = -1
const dialogTimeout = 10000
const noStorageError = `
<p>
You are out of storage space. Light Assistant saves every action that you 
perform to the disk in the event something goes wrong (or you reload in the web-app). 
Most web-browsers (and systems) enforce a quota based on your available remaining space. Though
Light Assistant only needs a fraction of this, the browser protects your hard drive by only
allowing a percentage of that space to be used.
</p>

<h3>Save a copy of your show!</h3>

<p>
You can use the purge option in the <em>Show</em> screen to clear old data and 
less important data. You may also consider eliminating images from your file. This is the
last resort. 
</p>

<p>You can also use the native applciation which has a much higher quota and is less susceptible to 
these errors.</p>

<p><b>Ignoring this error will result in data-loss.</b> As such it will repeat after 10 seconds until the problem is fixed.</p>

<b>Most browsers prevent storage over 5MB, we use IndexDB so the theoritical maximum is 50MB</b>
<ul>
  <li>Total: {all}</li>
  <li>Vectorworks Sync:{vectorworks}</li>
  <li>Instruments: {instruments}</li>
  <li>Cues: {cues}</li>
  <li>Notes: {notes}</li>
</ul>
`


const persistConfig = {
  key: 'root',
  storage: localForage,
  blacklist: [ //modules to exclude
    'log',
    'routing',
    'undo',
    'errors',
  ],
  debug: true,
  writeFailHandler: (err) => {
    console.error('[MAIN] Error storing data', err)
    const time = Date.now()
    if( (time - storageErrorTime) > dialogTimeout) {
      if(window.REDUX_STORE) {
        let state = window.REDUX_STORE.getState()
        const mb = (size, total = 1000000) => {
          return num(size / total, 0).toFixed(2) + 'MB'
        }
        const sendData = (size) => {
          window.REDUX_STORE.dispatch(
            (dispatch, getState) => {
            
              dispatch( 
                showThreeAction(
                  'No Storage Avaialble!',
                  formatUnicorn(noStorageError, {
                    all: mb(size.all),
                    vectorworks: mb(size.vectorworks),
                    cues: mb(size.cues),
                    instruments: mb(size.instruments),
                    notes: mb(size.notes),
                  }),
                  ()=> {window.REDUX_STORE.dispatch( saveState('OUT-OF-SPACE')) },
                  ()=> {window.REDUX_STORE.dispatch( purgeNonCriticalData()) },
                  ()=>{
                    alert('WARNING: you may lose data if you continue, save your show')
                  },
                  'Save Show',
                  'Purge Non Critical Data',
                  'Ignore',
                )
              )
            }
          )
        }

        //Dispatch a warning
        estimateFileSize(state)
          .then(size => {
            sendData(size)
          })
          .catch(err=>{
            sendData({
              all: 'Unknown',
              vectorworks: '???',
              cues: '???',
              instruments: '???',
              notes: '???',
            })
          })

        
      }
    }
    storageErrorTime = time
    
  }
}

const _persisted = persistReducer(persistConfig, reducer)
const sagaMiddleware = createSagaMiddleware()
const store = createStore( _persisted, 
                applyMiddleware( 
                  thunk,
                  sagaMiddleware,
                  logger, 
                  crashReporter 
                )
              )
const persistor = persistStore(store)
window.REDUX_STORE = store //Globabl redux for easy access
window.PERSISTOR = persistor

//APPLY SAGAS
sagaMiddleware.run(SagaSync)
sagaMiddleware.run(SagaRefreshWorksheet)


//applyStorageListener(store)
//Load the theme
changeTheme(Cookies.get('theme'), false)

//FIXME change this to a map so we can hot reload properly
let Home = require('./home').default
let About = require('./about').default
let Labs = require('./dev').default
let ErrorPage = require('./error').default
let ErrorPage404 = require('./error').default
let Cues = require('./cues').default
let Instruments = require('./instruments').default
let PostiionsPage = require('./instruments/PositionsPage').default
let OSC = require('./integration').default
let Log = require('./log').default
let Notes = require('./notes').default
let Paperwork = require('./paperwork').default
let Show = require('./show').default
let Privacy = require('./privacy').default

//NEW RENDER
const rerender = () => {
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={window.theme}>
        <CssBaseline />
        <TranslationWrapper>
          <Router history={history}>
            <Switch>
              <Route path='/about' component={About} />
              <Route path='/positions' component={ PostiionsPage } />
              <Route path='/cues' component={Cues} />
              <Route path='/instruments' component={Instruments} />
              <Route path='/dev' component={Labs} />
              <Route path='/paperwork' component={Paperwork} />
              <Route path='/notes' component={Notes} />
              <Route path='/show' component={Show} />
              <Route path='/integration' component={OSC} />
              <Route path='/log' component={Log} />
              <Route path='/error' component={ErrorPage} />
              <Route path='/privacy' component={Privacy} />
              <Route exact path='/' component={Home} />
              <Route path='/home' component={Home} />
              <Route component={ErrorPage404} />
            </Switch>
          </Router>
        </TranslationWrapper>
      </MuiThemeProvider>
    </Provider>, 
    container)
}

rerender()

// setTimeout(() => {
//   console.log('updating theme from window.')
//   window.theme = themeDark
//   window.theme = store.getState().theme || {} //Dispatch this to the ui.
// }, 15000)

function renderComponent(component) {
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={window.theme}>
        <CssBaseline />
        <TranslationWrapper>
          {component}
        </TranslationWrapper>
      </MuiThemeProvider>
    </Provider>, 
    container)
}

// Find and render a web page matching the current URL path,
// if such page is not found then render an error page (see routes.json, core/router.js)
function render(location) {
  
  store.dispatch({
    type: 'ROUTE_CHANGED',
    pathname: location.pathname
  })

  // router.resolve(routes, location)
  //   .then(renderComponent)
  //   .catch(error => router.resolve(routes, { ...location, error }).then(renderComponent));
}

// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/ReactJSTraining/history/tree/master/docs#readme

history.listen(render)
//render(history.location)

// Eliminates the 300ms delay between a physical tap
// and the firing of a click event on mobile browsers
// https://github.com/ftlabs/fastclick
FastClick.attach(document.body)

const loadModules = () => {
  Home = require('./home').default
  About = require('./about').default
  Labs = require('./dev').default
  ErrorPage = require('./error').default
  ErrorPage404 = require('./error').default
  Cues = require('./cues').default
  Instruments = require('./instruments').default
  OSC = require('./integration').default
  Log = require('./log').default
  Notes = require('./notes').default
  Paperwork = require('./paperwork').default
  Show = require('./show').default
  Privacy = require('./privacy').default
  PostiionsPage = require('./instruments/PositionsPage').default
}
// Enable Hot Module Replacement (HMR)
if (module.hot) {
  console.log('MODULE', module)
  const refresh = () => {
    const path = history.location.pathname || '/'
    history.replace({
      ...history.location,
      pathname: path
    })
  }

  module.hot.accept('./about/index.js', ()=>{
    loadModules()
    console.log(history)
    rerender()
  } )

  module.hot.accept('./paperwork/index.js', ()=>{
    loadModules()
    console.log(history)
    rerender()
  } )

  module.hot.accept('./main.js', () => {
    //loadModules()
    //rerender()
  })
  // module.hot.accept('./routes.json', () => {
  //   routesJSON = require('./routes.json')
  //   routes = require('./routes.json').default // eslint-disable-line global-require
  //   render(history.location)
  // })
}

