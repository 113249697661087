import light from './light'

export default {
  ...light,
  palette: {
		type: 'dark',
		primary: {
			main: '#0075ff',
		},
  },
  paperAlt: 'rgba(64, 64, 64, 0.4)',
  brightPaper: 'rgba(0,0,0,0.5)',
  seperator: 'rgba(96, 96, 96, 1)',
  highlight: 'rgba(255, 255, 0, 0.3)',
  editor: 'rgba(105, 251, 89, 0.20)',
  editorBorder: 'rgba(105, 251, 89, 0.5)',
  tableDashed: 'rgba(192,192,192, 0.3)',
}