import React from 'react'
import PropTypes from 'prop-types'

import en from './en'
import fr from './fr'
import sp from './sp'

import { formatUnicorn } from './../StringUtil'

export const languages = {
  en,
  fr,
  sp
}

export const Backend = (strings)=>{
  const _get = (key, ...args) => {
    const out = strings[key]

    if(out !== null && out !== undefined) {
      if(args){
        return formatUnicorn(out, ...args)
      } else {
        return out
      }
    } else {
      //console.log('[MISSING_KEY] `' + key + '`');
      if (args) {
        const flat = args.map(a => { return ', ' + a})
        return formatUnicorn('**' + key, ...args) + flat
      } else {
        return '**' + key
      }
    }
  }

  const _soft = (key, ...args) => {
    const out = strings[key]

    if(out !== null && out !== undefined) {
      if(args){
        return formatUnicorn(out, ...args)
      } else {
        return out
      }
    } else {
      //console.log('[MISSING_KEY] `' + key + '`');
      if (args) {
        const flat = args.map(a => { return ', ' + a})
        return formatUnicorn(key, ...args) + flat
      } else {
        return key
      }
    }
  }
  
  return {
    get: _get,
    soft: _soft,
    debug: () => {
      console.log(JSON.stringify(strings))
    }
  }
}

export const Translator = (code, section) => {
    const pack = languages[code] || {}
    const strings = pack[section] || {}
    return Backend(strings)
}

export const TranslatorFromRawState = (state, section) => {
  const lang = state.language || {}
  const pack = languages[lang.current || 'en'] || {}
  return Backend(pack[section] || {})
}

export const TranslatorFromState = (getState, section) => {
  const lang = getState().language || {}
  const pack = languages[lang.current || 'en'] || {}
  return Backend(pack[section] || {})
}

export default function translate(key) {
  return Component => {
    class TranslationComponent extends React.Component {
      render() { 
        //console.log('current language: ', this.context.currentLanguage);
        const strings = languages[this.context.currentLanguage][key] || {}
        return React.createElement(Component, {
          ...this.props,
          ...this.state,
          strings: Backend(strings),
          currentLanguage: this.context.currentLanguage,
        })
        //return <Component {...this.props} {...this.state} strings={ Backend(strings) } currentLanguage={this.context.currentLanguage} />
      }
    }

    TranslationComponent.contextTypes =  {
      currentLanguage: PropTypes.string
    }

    return TranslationComponent
  }
}

