export default {
  ok: 'OK',
  etcEosConnection: 'ETC Eos Family OSC',
  eosName: 'ETC Eos Console',
  eosIpAddress: 'IP Address',
  sendCueSheet: 'Send Cue Sheet',
  eosSyncConfirm: 
`Are you sure you want to send the cue sheet to the console?`,
  cueLabelFormat: 'Cue Label Format',
  cueFormatDetailed:
`The cue format substitutes property names as variables so you can 
customize your labels. Any property on the cue sheet is a valid property.
example: "p.{page} | {label}"`,
  workspace: 'QLab Workspace ID',
  qlabHost: 'IP Address',
  dumpCueSheet: 'Dump Cue Sheet',
  qlabSyncConfirm: 'This is a one-way operation. Are you sure you want to send the cue sheet?',
  eosOscVersion: 'OSC Version',
  eosOscAndVersion: 'host {eosHost}:3032 | osc {eosOscVersion}',
  eosExplaination: `
  <p>
    Connections to Eos Family systems is typically on TCP/IP <tt>port 3032</tt>. 
    Older version of Eos will only connect on this port.
  </p>
  <p>
    You can select your OSC version, 1.1 is preferred but older application (OSC Remote) require 1.0
    connections so this is provided for compatibility.
  </p>
  <img src="/help/osc/eos-osc.png" style="width:100%; border:1px solid;" />
  `,
  dataFilters: 'Data Filters',
  readPatchFromEos: 'Read Patch Info',
  readPatchFromEosTip: 'Allows Light Assistant to read patch information over OSC. Disabled to prevent changes.',
  includeUnknownFixtures: 'Import Unknown Fixtures',
  includeUnknownFixturesTip: 'When enabled this will import channels not found in Light Assistant as "From Eos"',
  eosPatchAddPartsToChannel: 'Add Part Number to Channels',
  eosPatchAddPartsToChannelTip: 'When enabled adds a part number ex: (1 P2) to the channel field.',
  requestPatchFromEos: 'Request Patch from Eos',
  offline: 'OSC off-line',
  online: 'OSC Connected',
  //Console
  commandLine: 'Command Line',
  sendRawOsc: 'Send OSC Command',
  send: 'Send',
  cue: 'Cue',
  logs: 'Logs',
  helo: 'Help',
  connect: 'Connect',

  //QLAB
  qLab: 'Figure 53 QLab',
  qlabIdHelp: 'QLab Workspace ID Help',
  qlabIdHelpData: `
  <p>
    To connect to QLab you need to specify the Workspace ID in order to access the OSC API. This 
    is found in the <b><code>Window -> Workspace Status</code></b>. Under 'Info' you will see the Workspace ID which tells
    QLab where to put the cues we are dumping.
  </p>

  <p>Once you have entered the Workspace ID you need to find the IP Address which is available under <b><code>Settings -> OSC Controls</code></b></p>
  <img 
    src='help/osc/qlab-workspace-status.png' 
    style={{
      width: '65%'
    }}
  />
  <img 
    src='help/osc/qlab-workspace-status-window.png' 
    style={{
      width: '100%'
    }}
  />`,

  //WEB
  web: 'WebApp',
  requiresApp: 'Native App Required',

  //EOS
  connections: 'Connections',
  eos: 'ETC Eos Family',


}