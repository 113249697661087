export default {
  "abbrev@1.0.9": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/abbrev-js",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\abbrev\\LICENSE"
  },
  "acorn-jsx@3.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/RReverser/acorn-jsx",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\acorn-jsx\\LICENSE"
  },
  "acorn@1.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/marijnh/acorn",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\falafel\\node_modules\\acorn\\LICENSE"
  },
  "acorn@3.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ternjs/acorn",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\acorn-jsx\\node_modules\\acorn\\LICENSE"
  },
  "acorn@4.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/ternjs/acorn",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\acorn\\LICENSE"
  },
  "after@0.8.1": {
    "licenses": "MIT*",
    "repository": "https://github.com/Raynos/after",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\after\\LICENCE"
  },
  "ajv-keywords@1.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/epoberezkin/ajv-keywords",
    "publisher": "Evgeny Poberezkin",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ajv-keywords\\LICENSE"
  },
  "ajv@4.10.4": {
    "licenses": "MIT",
    "repository": "https://github.com/epoberezkin/ajv",
    "publisher": "Evgeny Poberezkin",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ajv\\LICENSE"
  },
  "amdefine@1.0.1": {
    "licenses": "BSD-3-Clause OR MIT",
    "repository": "https://github.com/jrburke/amdefine",
    "publisher": "James Burke",
    "email": "jrburke@gmail.com",
    "url": "http://github.com/jrburke",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\amdefine\\LICENSE"
  },
  "ansi-escapes@1.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/ansi-escapes",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ansi-escapes\\license"
  },
  "ansi-regex@2.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/ansi-regex",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ansi-regex\\license"
  },
  "ansi-styles@2.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/ansi-styles",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ansi-styles\\license"
  },
  "ansi@0.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/TooTallNate/ansi.js",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://tootallnate.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ansi\\LICENSE"
  },
  "app@0.0.0": {
    "licenses": "MIT*",
    "licenseFile": "D:\\Development\\LA\\Node\\LICENSE.txt"
  },
  "aproba@1.0.4": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/aproba",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\aproba\\LICENSE"
  },
  "are-we-there-yet@1.1.2": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/are-we-there-yet",
    "publisher": "Rebecca Turner",
    "url": "http://re-becca.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\are-we-there-yet\\LICENSE"
  },
  "argparse@1.0.9": {
    "licenses": "MIT",
    "repository": "https://github.com/nodeca/argparse",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\argparse\\LICENSE"
  },
  "argsarray@0.0.1": {
    "licenses": "WTFPL",
    "repository": "https://github.com/calvinmetcalf/argsarray",
    "publisher": "Calvin Metcalf",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\argsarray\\license.md"
  },
  "array-index@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/TooTallNate/array-index",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://tootallnate.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\array-index\\LICENSE"
  },
  "array-union@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/array-union",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\array-union\\license"
  },
  "array-uniq@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/array-uniq",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\array-uniq\\license"
  },
  "arrify@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/arrify",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\arrify\\license"
  },
  "asap@2.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/kriskowal/asap",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\asap\\LICENSE.md"
  },
  "asn1@0.2.3": {
    "licenses": "MIT",
    "repository": "https://github.com/mcavage/node-asn1",
    "publisher": "Mark Cavage",
    "email": "mcavage@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\asn1\\LICENSE"
  },
  "assert-plus@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mcavage/node-assert-plus",
    "publisher": "Mark Cavage",
    "email": "mcavage@gmail.com"
  },
  "assert-plus@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mcavage/node-assert-plus",
    "publisher": "Mark Cavage",
    "email": "mcavage@gmail.com"
  },
  "ast-types@0.8.15": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/ast-types",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ast-types\\LICENSE"
  },
  "ast-types@0.9.4": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/ast-types",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\node_modules\\ast-types\\LICENSE"
  },
  "async@1.5.2": {
    "licenses": "MIT",
    "repository": "https://github.com/caolan/async",
    "publisher": "Caolan McMahon",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\prebuild\\node_modules\\async\\LICENSE"
  },
  "asynckit@0.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/alexindigo/asynckit",
    "publisher": "Alex Indigo",
    "email": "iam@alexindigo.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\asynckit\\LICENSE"
  },
  "aws-sign2@0.6.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/aws-sign",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\aws-sign2\\LICENSE"
  },
  "aws4@1.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mhart/aws4",
    "publisher": "Michael Hart",
    "email": "michael.hart.au@gmail.com",
    "url": "http://github.com/mhart",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\aws4\\LICENSE"
  },
  "babel-code-frame@6.22.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-code-frame",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babel-eslint@6.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel-eslint",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-esnext\\node_modules\\babel-eslint\\LICENSE"
  },
  "babel-messages@6.22.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-messages",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babel-polyfill@6.22.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-polyfill",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babel-runtime@6.22.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-runtime",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babel-traverse@6.22.1": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-traverse",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babel-types@6.22.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babel/tree/master/packages/babel-types",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com"
  },
  "babylon@6.15.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/babylon",
    "publisher": "Sebastian McKenzie",
    "email": "sebmck@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\babylon\\LICENSE"
  },
  "balanced-match@0.4.2": {
    "licenses": "MIT",
    "repository": "https://github.com/juliangruber/balanced-match",
    "publisher": "Julian Gruber",
    "email": "mail@juliangruber.com",
    "url": "http://juliangruber.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\brace-expansion\\node_modules\\balanced-match\\LICENSE.md"
  },
  "base62@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/andrew/base62.js",
    "publisher": "Andrew Nesbitt",
    "email": "andrewnez@gmail.com",
    "url": "http://nesbitt.io",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\base62\\LICENSE"
  },
  "bcrypt-pbkdf@1.0.0": {
    "licenses": "BSD-4-Clause"
  },
  "bindings@1.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/TooTallNate/node-bindings",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://tootallnate.net"
  },
  "bl@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/bl",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsonist\\node_modules\\bl\\LICENSE.md"
  },
  "bl@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/bl",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\bl\\LICENSE.md"
  },
  "block-stream@0.0.9": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/block-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\block-stream\\LICENSE"
  },
  "boom@2.10.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/hapijs/boom",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\boom\\LICENSE"
  },
  "bootstrap@3.3.7": {
    "licenses": "MIT",
    "repository": "https://github.com/twbs/bootstrap",
    "publisher": "Twitter, Inc.",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\bootstrap\\LICENSE"
  },
  "bowser@1.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/ded/bowser",
    "publisher": "Dustin Diaz",
    "email": "dustin@dustindiaz.com",
    "url": "http://dustindiaz.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\bowser\\LICENSE"
  },
  "brace-expansion@1.1.6": {
    "licenses": "MIT",
    "repository": "https://github.com/juliangruber/brace-expansion",
    "publisher": "Julian Gruber",
    "email": "mail@juliangruber.com",
    "url": "http://juliangruber.com"
  },
  "buffer-from@0.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/linusu/buffer-from"
  },
  "buffer-shims@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/buffer-shims",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\buffer-shims\\license.md"
  },
  "builtin-modules@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/builtin-modules",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\builtin-modules\\license"
  },
  "caller-path@0.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/caller-path",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "callsites@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/callsites",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "caseless@0.11.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/caseless",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\caseless\\LICENSE"
  },
  "chalk@1.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/chalk",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\chalk\\license"
  },
  "change-emitter@0.1.2": {
    "licenses": "MIT",
    "publisher": "Andrew Clark",
    "email": "acdlite@me.com"
  },
  "circular-json@0.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/WebReflection/circular-json",
    "publisher": "Andrea Giammarchi",
    "url": "http://webreflection.blogspot.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\circular-json\\LICENSE.txt"
  },
  "clamp@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/hughsk/clamp",
    "publisher": "Hugh Kennedy",
    "email": "hughskennedy@gmail.com",
    "url": "http://github.com/hughsk",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\clamp\\LICENSE.md"
  },
  "classnames@2.2.5": {
    "licenses": "MIT",
    "repository": "https://github.com/JedWatson/classnames",
    "publisher": "Jed Watson",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\classnames\\LICENSE"
  },
  "cli-cursor@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/cli-cursor",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\cli-cursor\\license"
  },
  "cli-width@2.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/knownasilya/cli-width",
    "publisher": "Ilya Radchenko",
    "email": "ilya@burstcreations.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\cli-width\\LICENSE"
  },
  "clone-buffer@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/gulpjs/clone-buffer",
    "publisher": "Gulp Team",
    "email": "team@gulpjs.com",
    "url": "http://gulpjs.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\clone-buffer\\LICENSE"
  },
  "co@4.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tj/co",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\co\\LICENSE"
  },
  "code-point-at@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/code-point-at",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\code-point-at\\license"
  },
  "combined-stream@1.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/felixge/node-combined-stream",
    "publisher": "Felix Geisend├╢rfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\combined-stream\\License"
  },
  "commander@2.9.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tj/commander.js",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commander\\LICENSE"
  },
  "commoner@0.10.8": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/commoner",
    "publisher": "Ben Newman",
    "email": "ben@benjamn.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\LICENSE"
  },
  "concat-map@0.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-concat-map",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\concat-map\\LICENSE"
  },
  "concat-stream@1.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/maxogden/concat-stream",
    "publisher": "Max Ogden",
    "email": "max@maxogden.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\concat-stream\\LICENSE"
  },
  "console-control-strings@1.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/console-control-strings",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "url": "http://re-becca.org/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\console-control-strings\\LICENSE"
  },
  "contains-path@0.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jonschlinkert/contains-path",
    "publisher": "Jon Schlinkert",
    "url": "https://github.com/jonschlinkert",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\contains-path\\LICENSE"
  },
  "core-js@1.2.7": {
    "licenses": "MIT",
    "repository": "https://github.com/zloirock/core-js",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fbjs\\node_modules\\core-js\\LICENSE"
  },
  "core-js@2.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/zloirock/core-js",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\core-js\\LICENSE"
  },
  "core-util-is@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/core-util-is",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\core-util-is\\LICENSE"
  },
  "cryptiles@2.0.5": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/hapijs/cryptiles",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\cryptiles\\LICENSE"
  },
  "d@0.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/d",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\d\\LICENCE"
  },
  "dashdash@1.14.1": {
    "licenses": "MIT",
    "repository": "https://github.com/trentm/node-dashdash",
    "publisher": "Trent Mick",
    "email": "trentm@gmail.com",
    "url": "http://trentm.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\dashdash\\LICENSE.txt"
  },
  "debug@2.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/visionmedia/debug",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-node\\node_modules\\debug\\LICENSE"
  },
  "deep-extend@0.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/unclechu/node-deep-extend",
    "publisher": "Viacheslav Lotsmanov",
    "email": "lotsmanov89@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\deep-extend\\LICENSE"
  },
  "deep-is@0.1.3": {
    "licenses": "MIT",
    "repository": "https://github.com/thlorenz/deep-is",
    "publisher": "Thorsten Lorenz",
    "email": "thlorenz@gmx.de",
    "url": "http://thlorenz.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\deep-is\\LICENSE"
  },
  "deferred-leveldown@1.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/Level/deferred-leveldown",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\deferred-leveldown\\LICENSE.md"
  },
  "defined@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/defined",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\defined\\LICENSE"
  },
  "del@2.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/del",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\del\\license"
  },
  "delayed-stream@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/felixge/node-delayed-stream",
    "publisher": "Felix Geisend├╢rfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\delayed-stream\\License"
  },
  "delegates@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/visionmedia/node-delegates",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\delegates\\License"
  },
  "detective@4.3.2": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-detective",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\detective\\LICENSE"
  },
  "doctrine@1.2.3": {
    "licenses": "BSD",
    "repository": "https://github.com/eslint/doctrine",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-esnext\\node_modules\\doctrine\\LICENSE.esprima"
  },
  "doctrine@1.5.0": {
    "licenses": "BSD",
    "repository": "https://github.com/eslint/doctrine",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\doctrine\\LICENSE.esprima"
  },
  "double-ended-queue@2.1.0-0": {
    "licenses": "MIT",
    "repository": "https://github.com/petkaantonov/deque",
    "publisher": "Petka Antonov",
    "email": "petka_antonov@hotmail.com",
    "url": "http://github.com/petkaantonov/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\double-ended-queue\\LICENSE"
  },
  "duplexer2@0.0.2": {
    "licenses": "BSD",
    "repository": "https://github.com/deoxxa/duplexer2",
    "publisher": "Conrad Pankoff",
    "email": "deoxxa@fknsrs.biz",
    "url": "http://www.fknsrs.biz/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\duplexer2\\LICENSE.md"
  },
  "ecc-jsbn@0.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/quartzjer/ecc-jsbn",
    "publisher": "Jeremie Miller",
    "email": "jeremie@jabber.org",
    "url": "http://jeremie.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ecc-jsbn\\LICENSE"
  },
  "encoding@0.1.12": {
    "licenses": "MIT",
    "repository": "https://github.com/andris9/encoding",
    "publisher": "Andris Reinman",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\encoding\\LICENSE"
  },
  "end-of-stream@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/end-of-stream",
    "publisher": "Mathias Buus",
    "email": "mathiasbuus@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\end-of-stream\\LICENSE"
  },
  "end-stream@0.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/Raynos/end-stream",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\end-stream\\LICENCE"
  },
  "errno@0.1.4": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/node-errno"
  },
  "es3ify@0.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/spicyj/es3ify",
    "publisher": "Ben Alpert",
    "email": "ben@benalpert.com",
    "url": "http://benalpert.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es3ify\\LICENSE"
  },
  "es5-ext@0.10.12": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es5-ext",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es5-ext\\LICENSE"
  },
  "es6-iterator@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es6-iterator",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es6-iterator\\LICENSE"
  },
  "es6-map@0.1.4": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es6-map",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es6-map\\LICENSE"
  },
  "es6-set@0.1.4": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es6-set",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es6-set\\LICENSE"
  },
  "es6-symbol@3.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es6-symbol",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es6-symbol\\LICENSE"
  },
  "es6-weak-map@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/es6-weak-map",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\es6-weak-map\\LICENSE"
  },
  "escape-string-regexp@1.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/escape-string-regexp",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\escape-string-regexp\\license"
  },
  "escope@3.6.0": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/escope",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\escope\\LICENSE.BSD"
  },
  "eslint-config-esnext@1.6.0": {
    "licenses": "ISC",
    "repository": "https://github.com/kunalgolani/eslint-config",
    "publisher": "kunalgolani@gmail.com"
  },
  "eslint-config-node@1.6.0": {
    "licenses": "ISC",
    "repository": "https://github.com/kunalgolani/eslint-config",
    "publisher": "kunalgolani@gmail.com"
  },
  "eslint-import-resolver-node@0.2.3": {
    "licenses": "MIT",
    "repository": "https://github.com/benmosher/eslint-plugin-import",
    "publisher": "Ben Mosher",
    "url": "me@benmosher.com"
  },
  "eslint-plugin-babel@3.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/babel/eslint-plugin-babel",
    "publisher": "Jason Quense @monasticpanic",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-plugin-babel\\LICENSE"
  },
  "eslint-plugin-import@1.14.0": {
    "licenses": "MIT",
    "repository": "https://github.com/benmosher/eslint-plugin-import",
    "publisher": "Ben Mosher",
    "email": "me@benmosher.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-esnext\\node_modules\\eslint-plugin-import\\LICENSE"
  },
  "eslint@3.13.1": {
    "licenses": "MIT",
    "repository": "https://github.com/eslint/eslint",
    "publisher": "Nicholas C. Zakas",
    "email": "nicholas+npm@nczconsulting.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint\\LICENSE"
  },
  "eslint@3.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/eslint/eslint",
    "publisher": "Nicholas C. Zakas",
    "email": "nicholas+npm@nczconsulting.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-node\\node_modules\\eslint\\LICENSE"
  },
  "esmangle-evaluator@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/zertosh/esmangle-evaluator",
    "publisher": "Andres Suarez",
    "email": "zertosh@gmail.com"
  },
  "espree@3.3.2": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/eslint/espree",
    "publisher": "Nicholas C. Zakas",
    "email": "nicholas+npm@nczconsulting.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\espree\\LICENSE"
  },
  "esprima-fb@15001.1.0-dev-harmony-fb": {
    "licenses": "BSD",
    "repository": "https://github.com/facebook/esprima",
    "publisher": "Ariya Hidayat",
    "email": "ariya.hidayat@gmail.com"
  },
  "esprima-fb@15001.1001.0-dev-harmony-fb": {
    "licenses": "BSD",
    "repository": "https://github.com/facebook/esprima",
    "publisher": "Ariya Hidayat",
    "email": "ariya.hidayat@gmail.com"
  },
  "esprima@2.7.3": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/jquery/esprima",
    "publisher": "Ariya Hidayat",
    "email": "ariya.hidayat@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\esprima\\LICENSE.BSD"
  },
  "esprima@3.1.3": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/jquery/esprima",
    "publisher": "Ariya Hidayat",
    "email": "ariya.hidayat@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\node_modules\\esprima\\LICENSE.BSD"
  },
  "esrecurse@4.1.0": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/esrecurse"
  },
  "estraverse@4.1.1": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/estraverse",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\esrecurse\\node_modules\\estraverse\\LICENSE.BSD"
  },
  "estraverse@4.2.0": {
    "licenses": "BSD-2-Clause",
    "repository": "https://github.com/estools/estraverse",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\estraverse\\LICENSE.BSD"
  },
  "esutils@2.0.2": {
    "licenses": "BSD",
    "repository": "https://github.com/estools/esutils",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\esutils\\LICENSE.BSD"
  },
  "event-emitter@0.3.4": {
    "licenses": "MIT",
    "repository": "https://github.com/medikoo/event-emitter",
    "publisher": "Mariusz Nowak",
    "email": "medyk@medikoo.com",
    "url": "http://www.medikoo.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\event-emitter\\LICENSE"
  },
  "execspawn@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/AndreasMadsen/execspawn",
    "publisher": "Andreas Madsen",
    "email": "amwebdk@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\execspawn\\LICENSE.md"
  },
  "exit-hook@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/exit-hook",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "expand-template@1.0.3": {
    "licenses": "WTFPL",
    "repository": "https://github.com/ralphtheninja/expand-template",
    "publisher": "LM",
    "email": "ralphtheninja@riseup.net"
  },
  "extend@3.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/justmoon/node-extend",
    "publisher": "Stefan Thomas",
    "email": "justmoon@members.fsf.org",
    "url": "http://www.justmoon.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\extend\\LICENSE"
  },
  "extsprintf@1.0.2": {
    "licenses": "MIT*",
    "repository": "https://github.com/davepacheco/node-extsprintf",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\extsprintf\\LICENSE"
  },
  "falafel@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-falafel",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net"
  },
  "fast-future@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/kesla/fast-future",
    "publisher": "David Bj├╢rklund",
    "email": "david.bjorklund@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fast-future\\LICENCE"
  },
  "fast-levenshtein@2.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/hiddentao/fast-levenshtein",
    "publisher": "Ramesh Nair",
    "email": "ram@hiddentao.com",
    "url": "http://www.hiddentao.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fast-levenshtein\\LICENSE.md"
  },
  "fastclick@1.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/ftlabs/fastclick",
    "publisher": "Rowan Beentje",
    "email": "rowan.beentje@ft.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fastclick\\LICENSE"
  },
  "fbjs@0.8.8": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/fbjs",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fbjs\\LICENSE"
  },
  "figures@1.7.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/figures",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\figures\\license"
  },
  "file-entry-cache@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/royriojas/file-entry-cache",
    "publisher": "Roy Riojas",
    "url": "http://royriojas.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\file-entry-cache\\LICENSE"
  },
  "find-up@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/find-up",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\find-up\\license"
  },
  "flat-cache@1.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/royriojas/flat-cache",
    "publisher": "Roy Riojas",
    "url": "http://royriojas.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\flat-cache\\LICENSE"
  },
  "foreach@2.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/manuelstofer/foreach",
    "publisher": "Manuel Stofer",
    "email": "manuel@takimata.ch",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\foreach\\LICENSE"
  },
  "forever-agent@0.6.1": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/forever-agent",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\forever-agent\\LICENSE"
  },
  "form-data@2.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/form-data/form-data",
    "publisher": "Felix Geisend├╢rfer",
    "email": "felix@debuggable.com",
    "url": "http://debuggable.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\form-data\\License"
  },
  "fs.realpath@1.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/fs.realpath",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fs.realpath\\LICENSE"
  },
  "fstream@1.0.10": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/fstream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\fstream\\LICENSE"
  },
  "gauge@1.2.7": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/gauge",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\gauge\\LICENSE"
  },
  "gauge@2.7.2": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/gauge",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\node-gyp\\node_modules\\gauge\\LICENSE"
  },
  "generate-function@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/generate-function",
    "publisher": "Mathias Buus"
  },
  "generate-object-property@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/generate-object-property",
    "publisher": "Mathias Buus",
    "url": "@mafintosh",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\generate-object-property\\LICENSE"
  },
  "getpass@0.1.6": {
    "licenses": "MIT",
    "repository": "https://github.com/arekinath/node-getpass",
    "publisher": "Alex Wilson",
    "email": "alex.wilson@joyent.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\getpass\\LICENSE"
  },
  "ghreleases@1.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/ralphtheninja/ghreleases",
    "publisher": "Magnus Skog",
    "email": "ralphtheninja@riseup.net"
  },
  "ghrepos@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/ghrepos",
    "publisher": "Rod Vagg",
    "email": "r@va.gg",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ghrepos\\LICENSE.md"
  },
  "ghutils@3.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/ghutils",
    "publisher": "Rod",
    "email": "rod@vagg.org",
    "url": "http://r.va.gg/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ghutils\\LICENSE.md"
  },
  "github-from-package@0.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/github-from-package",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\github-from-package\\LICENSE"
  },
  "glob@5.0.15": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/node-glob",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\node_modules\\glob\\LICENSE"
  },
  "glob@7.1.1": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/node-glob",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\del\\node_modules\\glob\\LICENSE"
  },
  "globals@9.14.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/globals",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\globals\\license"
  },
  "globby@5.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/globby",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\del\\node_modules\\globby\\license"
  },
  "graceful-fs@4.1.11": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/node-graceful-fs",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\flat-cache\\node_modules\\graceful-fs\\LICENSE"
  },
  "graceful-readlink@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/zhiyelee/graceful-readlink",
    "publisher": "zhiyelee",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\graceful-readlink\\LICENSE"
  },
  "har-validator@2.0.6": {
    "licenses": "ISC",
    "repository": "https://github.com/ahmadnassri/har-validator",
    "publisher": "Ahmad Nassri",
    "email": "ahmad@ahmadnassri.com",
    "url": "https://www.ahmadnassri.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\har-validator\\LICENSE"
  },
  "has-ansi@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/has-ansi",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\has-ansi\\license"
  },
  "has-symbol-support-x@1.0.11": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/has-symbol-support-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\has-symbol-support-x\\LICENSE"
  },
  "has-to-string-tag-x@1.0.10": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/has-to-string-tag-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\has-to-string-tag-x\\LICENSE"
  },
  "has-unicode@2.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/has-unicode",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\has-unicode\\LICENSE"
  },
  "hawk@3.1.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/hueniverse/hawk",
    "publisher": "Eran Hammer",
    "email": "eran@hammer.io",
    "url": "http://hueniverse.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hawk\\LICENSE"
  },
  "history@4.5.1": {
    "licenses": "MIT",
    "repository": "https://github.com/mjackson/history",
    "publisher": "Michael Jackson",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\history\\LICENSE.md"
  },
  "hoek@2.16.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/hapijs/hoek",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hoek\\LICENSE"
  },
  "hoist-non-react-statics@1.2.0": {
    "licenses": "BSD",
    "repository": "https://github.com/mridgway/hoist-non-react-statics",
    "publisher": "Michael Ridgway",
    "email": "mridgway@yahoo-inc.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hoist-non-react-statics\\LICENSE.md"
  },
  "http-signature@1.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/joyent/node-http-signature",
    "publisher": "Joyent, Inc",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\http-signature\\LICENSE"
  },
  "hyperquest@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/hyperquest",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hyperquest\\LICENSE"
  },
  "hyphenate-style-name@1.0.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/rexxars/hyphenate-style-name",
    "publisher": "Espen Hovlandsdal",
    "email": "espen@hovlandsdal.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hyphenate-style-name\\LICENSE"
  },
  "iconv-lite@0.4.15": {
    "licenses": "MIT",
    "repository": "https://github.com/ashtuchkin/iconv-lite",
    "publisher": "Alexander Shtuchkin",
    "email": "ashtuchkin@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\iconv-lite\\LICENSE"
  },
  "ignore@3.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/kaelzhang/node-ignore",
    "publisher": "kael"
  },
  "immediate@3.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/immediate",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\immediate\\LICENSE.txt"
  },
  "immutable@3.8.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/immutable-js",
    "publisher": "Lee Byron",
    "url": "https://github.com/leebyron",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\immutable\\LICENSE"
  },
  "imurmurhash@0.1.4": {
    "licenses": "MIT",
    "repository": "https://github.com/jensyt/imurmurhash-js",
    "publisher": "Jens Taylor",
    "email": "jensyt@gmail.com",
    "url": "https://github.com/homebrewing"
  },
  "inflight@1.0.6": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/inflight",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\inflight\\LICENSE"
  },
  "inherits@2.0.3": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/inherits",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\inherits\\LICENSE"
  },
  "ini@1.3.4": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/ini",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ini\\LICENSE"
  },
  "inline-process-browser@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/inline-process-browser",
    "publisher": "Calvin W. Metcalf",
    "email": "calvin.metcalf@gmail.com"
  },
  "inline-style-prefixer@2.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/rofrischmann/inline-style-prefixer",
    "publisher": "Robin Frischmann",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\inline-style-prefixer\\LICENSE"
  },
  "inquirer@0.12.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sboudrias/Inquirer.js",
    "publisher": "Simon Boudrias",
    "email": "admin@simonboudrias.com"
  },
  "interpret@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/tkellen/node-interpret",
    "publisher": "Tyler Kellen",
    "url": "http://goingslowly.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\interpret\\LICENSE"
  },
  "invariant@2.2.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/zertosh/invariant",
    "publisher": "Andres Suarez",
    "email": "zertosh@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\invariant\\LICENSE"
  },
  "is-array-buffer-x@1.0.13": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/is-array-buffer-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-array-buffer-x\\LICENSE"
  },
  "is-array@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/retrofox/is-array"
  },
  "is-fullwidth-code-point@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-fullwidth-code-point",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\readline2\\node_modules\\is-fullwidth-code-point\\license"
  },
  "is-fullwidth-code-point@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-fullwidth-code-point",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-fullwidth-code-point\\license"
  },
  "is-function-x@1.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/is-function-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-function-x\\LICENSE"
  },
  "is-my-json-valid@2.15.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/is-my-json-valid",
    "publisher": "Mathias Buus",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-my-json-valid\\LICENSE"
  },
  "is-object-like-x@1.0.11": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/is-object-like-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-object-like-x\\LICENSE"
  },
  "is-path-cwd@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-path-cwd",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "is-path-in-cwd@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-path-in-cwd",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "is-path-inside@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-path-inside",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "is-primitive@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jonschlinkert/is-primitive",
    "publisher": "Jon Schlinkert",
    "url": "https://github.com/jonschlinkert",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-primitive\\LICENSE"
  },
  "is-property@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/mikolalysenko/is-property",
    "publisher": "Mikola Lysenko",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-property\\LICENSE"
  },
  "is-resolvable@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/shinnn/is-resolvable",
    "publisher": "Shinnosuke Watanabe",
    "url": "https://github.com/shinnn",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-resolvable\\LICENSE"
  },
  "is-stream@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/is-stream",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-stream\\license"
  },
  "is-typedarray@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/hughsk/is-typedarray",
    "publisher": "Hugh Kennedy",
    "email": "hughskennedy@gmail.com",
    "url": "http://hughsk.io/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\is-typedarray\\LICENSE.md"
  },
  "isarray@0.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/juliangruber/isarray",
    "publisher": "Julian Gruber",
    "email": "mail@juliangruber.com",
    "url": "http://juliangruber.com"
  },
  "isarray@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/juliangruber/isarray",
    "publisher": "Julian Gruber",
    "email": "mail@juliangruber.com",
    "url": "http://juliangruber.com"
  },
  "isexe@1.1.2": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/isexe",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\isexe\\LICENSE"
  },
  "isomorphic-fetch@2.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/matthew-andrews/isomorphic-fetch",
    "publisher": "Matt Andrews",
    "email": "matt@mattandre.ws",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\isomorphic-fetch\\LICENSE"
  },
  "isstream@0.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/isstream",
    "publisher": "Rod Vagg",
    "email": "rod@vagg.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\isstream\\LICENSE.md"
  },
  "jodid25519@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/meganz/jodid25519",
    "publisher": "Michele Bini, Ron Garret, Guy K. Kloss",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jodid25519\\LICENSE"
  },
  "jquery@3.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jquery/jquery",
    "publisher": "jQuery Foundation and other contributors",
    "url": "https://github.com/jquery/jquery/blob/3.1.1/AUTHORS.txt",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jquery\\LICENSE.txt"
  },
  "js-tokens@3.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lydell/js-tokens",
    "publisher": "Simon Lydell",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\js-tokens\\LICENSE"
  },
  "js-yaml@3.7.0": {
    "licenses": "MIT",
    "repository": "https://github.com/nodeca/js-yaml",
    "publisher": "Vladimir Zapparov",
    "email": "dervus.grim@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\js-yaml\\LICENSE"
  },
  "jsbn@0.1.0": {
    "licenses": "BSD",
    "repository": "https://github.com/andyperlitch/jsbn",
    "publisher": "Tom Wu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsbn\\LICENSE"
  },
  "json-schema@0.2.3": {
    "licenses": [
      "AFLv2.1",
      "BSD"
    ],
    "repository": "https://github.com/kriszyp/json-schema",
    "publisher": "Kris Zyp"
  },
  "json-stable-stringify@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/json-stable-stringify",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\json-stable-stringify\\LICENSE"
  },
  "json-stringify-safe@5.0.1": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/json-stringify-safe",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\json-stringify-safe\\LICENSE"
  },
  "jsonify@0.0.0": {
    "licenses": "Public Domain",
    "repository": "https://github.com/substack/jsonify",
    "publisher": "Douglas Crockford",
    "url": "http://crockford.com/"
  },
  "jsonist@1.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/jsonist",
    "publisher": "Rod Vagg",
    "email": "r@va.gg",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsonist\\LICENSE"
  },
  "jsonpointer@4.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/janl/node-jsonpointer",
    "publisher": "Jan Lehnardt",
    "email": "jan@apache.org",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsonpointer\\LICENSE.md"
  },
  "jsprim@1.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/davepacheco/node-jsprim",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsprim\\LICENSE"
  },
  "jstransform@11.0.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/jstransform",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jstransform\\LICENSE"
  },
  "keycode@2.1.8": {
    "licenses": "MIT",
    "repository": "https://github.com/timoxley/keycode",
    "publisher": "Tim Oxley",
    "email": "secoif@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\keycode\\LICENSE"
  },
  "level-codec@6.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/level/codec",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\levelup\\node_modules\\level-codec\\LICENSE.md"
  },
  "level-codec@6.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/level/codec",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\level-codec\\LICENSE.md"
  },
  "level-errors@1.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/level/errors",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\level-errors\\LICENSE.md"
  },
  "level-iterator-stream@1.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/level/iterator-stream",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\level-iterator-stream\\LICENSE.md"
  },
  "level-write-stream@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/Raynos/level-write-stream",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\level-write-stream\\LICENCE"
  },
  "levn@0.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/levn",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\levn\\LICENSE"
  },
  "lie@3.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/lie",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb-promise\\node_modules\\lie\\license.md"
  },
  "lie@3.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/lie",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lie\\license.md"
  },
  "lodash-es@4.17.4": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash-es\\LICENSE"
  },
  "lodash.assign@4.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-esnext\\node_modules\\lodash.assign\\LICENSE"
  },
  "lodash.cond@4.5.2": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.cond\\LICENSE"
  },
  "lodash.endswith@4.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.endswith\\LICENSE"
  },
  "lodash.find@4.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.find\\LICENSE"
  },
  "lodash.findindex@4.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.findindex\\LICENSE"
  },
  "lodash.isequal@4.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.isequal\\LICENSE"
  },
  "lodash.isnull@3.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.isnull\\LICENSE.txt"
  },
  "lodash.merge@4.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\material-ui\\node_modules\\lodash.merge\\LICENSE"
  },
  "lodash.pad@4.5.1": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.pad\\LICENSE"
  },
  "lodash.padend@4.6.1": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.padend\\LICENSE"
  },
  "lodash.padstart@4.6.1": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.padstart\\LICENSE"
  },
  "lodash.pickby@4.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.pickby\\LICENSE"
  },
  "lodash.throttle@4.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash.throttle\\LICENSE"
  },
  "lodash@4.17.4": {
    "licenses": "MIT",
    "repository": "https://github.com/lodash/lodash",
    "publisher": "John-David Dalton",
    "email": "john.david.dalton@gmail.com",
    "url": "http://allyoucanleet.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\lodash\\LICENSE"
  },
  "loose-envify@1.3.1": {
    "licenses": "MIT",
    "repository": "https://github.com/zertosh/loose-envify",
    "publisher": "Andres Suarez",
    "email": "zertosh@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\loose-envify\\LICENSE"
  },
  "ltgt@2.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/dominictarr/ltgt",
    "publisher": "Dominic Tarr",
    "email": "dominic.tarr@gmail.com",
    "url": "http://dominictarr.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\ltgt\\LICENSE"
  },
  "material-ui@0.16.7": {
    "licenses": "MIT",
    "repository": "https://github.com/callemall/material-ui",
    "publisher": "Call-em-all Engineering Team",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\material-ui\\LICENSE"
  },
  "mime-db@1.26.0": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/mime-db",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\mime-db\\LICENSE"
  },
  "mime-types@2.1.14": {
    "licenses": "MIT",
    "repository": "https://github.com/jshttp/mime-types",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\mime-types\\LICENSE"
  },
  "minimatch@3.0.3": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/minimatch",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\minimatch\\LICENSE"
  },
  "minimist@0.0.8": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/minimist",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\minimist\\LICENSE"
  },
  "minimist@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/minimist",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\prebuild\\node_modules\\minimist\\LICENSE"
  },
  "mkdirp@0.5.1": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-mkdirp",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\mkdirp\\LICENSE"
  },
  "ms@0.7.2": {
    "licenses": "MIT",
    "repository": "https://github.com/zeit/ms",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-node\\node_modules\\ms\\LICENSE.md"
  },
  "mute-stream@0.0.5": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/mute-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\mute-stream\\LICENSE"
  },
  "nan@2.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/nodejs/nan",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\nan\\LICENSE.md"
  },
  "natural-compare@1.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/litejs/natural-compare-lite",
    "publisher": "Lauri Rooden",
    "url": "https://github.com/litejs/natural-compare-lite"
  },
  "node-fetch@1.6.3": {
    "licenses": "MIT",
    "repository": "https://github.com/bitinn/node-fetch",
    "publisher": "David Frank",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\node-fetch\\LICENSE.md"
  },
  "node-gyp@3.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/nodejs/node-gyp",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://tootallnate.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\node-gyp\\LICENSE"
  },
  "node-ninja@1.0.2": {
    "licenses": "MPL-2.0",
    "repository": "https://github.com/codejockey/node-ninja",
    "publisher": "Pieter Hintjens",
    "email": "pieterh@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\node-ninja\\LICENSE"
  },
  "noop-logger@0.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/segmentio/noop-logger"
  },
  "nopt@3.0.6": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/nopt",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\nopt\\LICENSE"
  },
  "normalize.css@5.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/necolas/normalize.css",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\normalize.css\\LICENSE.md"
  },
  "npmlog@2.0.4": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/npmlog",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\npmlog\\LICENSE"
  },
  "npmlog@4.0.2": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/npmlog",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\node-gyp\\node_modules\\npmlog\\LICENSE"
  },
  "number-is-nan@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/number-is-nan",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\number-is-nan\\license"
  },
  "oauth-sign@0.8.2": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/oauth-sign",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\oauth-sign\\LICENSE"
  },
  "object-assign@2.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/object-assign",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jstransform\\node_modules\\object-assign\\license"
  },
  "object-assign@4.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/object-assign",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\object-assign\\license"
  },
  "object-keys@1.0.11": {
    "licenses": "MIT",
    "repository": "https://github.com/ljharb/object-keys",
    "publisher": "Jordan Harband",
    "email": "ljharb@gmail.com",
    "url": "http://ljharb.codes",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\object-keys\\LICENSE"
  },
  "once@1.3.3": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/once",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\end-of-stream\\node_modules\\once\\LICENSE"
  },
  "once@1.4.0": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/once",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\once\\LICENSE"
  },
  "onetime@1.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/onetime",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\onetime\\license"
  },
  "optionator@0.8.2": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/optionator",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\optionator\\LICENSE"
  },
  "os-homedir@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/os-homedir",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\os-homedir\\license"
  },
  "os-tmpdir@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/os-tmpdir",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\os-tmpdir\\license"
  },
  "osenv@0.1.4": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/osenv",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\osenv\\LICENSE"
  },
  "path-array@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/TooTallNate/node-path-array",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://n8.io/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\path-array\\LICENSE"
  },
  "path-exists@2.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/path-exists",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\path-exists\\license"
  },
  "path-is-absolute@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/path-is-absolute",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\path-is-absolute\\license"
  },
  "path-is-inside@1.0.2": {
    "licenses": "(WTFPL OR MIT)",
    "repository": "https://github.com/domenic/path-is-inside",
    "publisher": "Domenic Denicola",
    "email": "d@domenic.me",
    "url": "https://domenic.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\path-is-inside\\LICENSE.txt"
  },
  "pify@2.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/pify",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pify\\license"
  },
  "pinkie-promise@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/floatdrop/pinkie-promise",
    "publisher": "Vsevolod Strukchinsky",
    "email": "floatdrop@gmail.com",
    "url": "github.com/floatdrop",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pinkie-promise\\license"
  },
  "pinkie@2.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/floatdrop/pinkie",
    "publisher": "Vsevolod Strukchinsky",
    "email": "floatdrop@gmail.com",
    "url": "github.com/floatdrop",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pinkie\\license"
  },
  "pkg-dir@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/pkg-dir",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pkg-dir\\license"
  },
  "pkg-up@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/pkg-up",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pkg-up\\license"
  },
  "pluralize@1.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/blakeembrey/pluralize",
    "publisher": "Blake Embrey",
    "email": "hello@blakeembrey.com",
    "url": "http://blakeembrey.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pluralize\\LICENSE"
  },
  "pouchdb-collate@1.2.0": {
    "licenses": "Apache",
    "repository": "https://github.com/pouchdb/collate"
  },
  "pouchdb-extend@0.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/pouchdb/pouchdb-extend",
    "publisher": "Nolan Lawson",
    "email": "nolan.lawson@gmail.com"
  },
  "pouchdb-find@0.10.5": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/nolanlawson/pouchdb-find",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb-find\\LICENSE"
  },
  "pouchdb-promise@5.4.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/pouchdb/pouchdb",
    "publisher": "Dale Harvey",
    "email": "dale@arandomurl.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb-promise\\LICENSE"
  },
  "pouchdb-promise@5.4.5": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/pouchdb/pouchdb",
    "publisher": "Dale Harvey",
    "email": "dale@arandomurl.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb-upsert\\node_modules\\pouchdb-promise\\LICENSE"
  },
  "pouchdb-upsert@2.0.2": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/nolanlawson/pouchdb-upsert",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb-upsert\\LICENSE"
  },
  "pouchdb@6.1.1": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/pouchdb/pouchdb",
    "publisher": "Dale Harvey",
    "email": "dale@arandomurl.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb\\LICENSE"
  },
  "prebuild@4.5.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/prebuild",
    "publisher": "Mathias Buus",
    "url": "@mafintosh",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\prebuild\\LICENSE"
  },
  "prelude-ls@1.1.2": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/prelude-ls",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\prelude-ls\\LICENSE"
  },
  "private@0.1.6": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/private",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\private\\LICENSE"
  },
  "process-nextick-args@1.0.7": {
    "licenses": "MIT",
    "repository": "https://github.com/calvinmetcalf/process-nextick-args",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\process-nextick-args\\license.md"
  },
  "progress@1.1.8": {
    "licenses": "MIT*",
    "repository": "https://github.com/visionmedia/node-progress",
    "publisher": "TJ Holowaychuk",
    "email": "tj@vision-media.ca",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\progress\\LICENSE"
  },
  "promise@7.1.1": {
    "licenses": "MIT",
    "repository": "https://github.com/then/promise",
    "publisher": "ForbesLindesay",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\promise\\LICENSE"
  },
  "prr@0.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/prr",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\errno\\node_modules\\prr\\LICENSE"
  },
  "prr@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/prr",
    "publisher": "Rod Vagg",
    "email": "rod@vagg.org",
    "url": "https://github.com/rvagg",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\prr\\LICENSE.md"
  },
  "pump@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/pump",
    "publisher": "Mathias Buus Madsen",
    "email": "mathiasbuus@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pump\\LICENSE"
  },
  "punycode@1.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/bestiejs/punycode.js",
    "publisher": "Mathias Bynens",
    "url": "https://mathiasbynens.be/"
  },
  "q@1.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/kriskowal/q",
    "publisher": "Kris Kowal",
    "email": "kris@cixar.com",
    "url": "https://github.com/kriskowal",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\q\\LICENSE"
  },
  "qs@6.3.0": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/ljharb/qs",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\request\\node_modules\\qs\\LICENSE"
  },
  "rc@1.1.6": {
    "licenses": "(BSD-2-Clause OR MIT OR Apache-2.0)",
    "repository": "https://github.com/dominictarr/rc",
    "publisher": "Dominic Tarr",
    "email": "dominic.tarr@gmail.com",
    "url": "dominictarr.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\rc\\LICENSE.MIT"
  },
  "react-addons-create-fragment@15.4.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/react",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-addons-create-fragment\\LICENSE"
  },
  "react-addons-shallow-compare@15.4.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/react",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-addons-shallow-compare\\LICENSE"
  },
  "react-addons-transition-group@15.4.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/react",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-addons-transition-group\\LICENSE"
  },
  "react-dom@15.4.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/react",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-dom\\LICENSE"
  },
  "react-event-listener@0.4.1": {
    "licenses": "MIT",
    "repository": "https://github.com/oliviertassinari/react-event-listener",
    "publisher": "olivier.tassinari@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-event-listener\\LICENSE"
  },
  "react-mdl-extra@1.4.2": {
    "licenses": "MIT",
    "repository": "https://github.com/HriBB/react-mdl-extra",
    "publisher": "Bojan Hribernik",
    "email": "bojan.hribernik@gmail.com",
    "url": "http://climbuddy.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-mdl-extra\\LICENSE.md"
  },
  "react-mdl@1.9.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tleunen/react-mdl",
    "publisher": "Tommy Leunen",
    "email": "tommy.leunen@gmail.com",
    "url": "http://tommyleunen.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-mdl\\LICENSE.md"
  },
  "react-portal@3.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/tajo/react-portal",
    "publisher": "Vojtech Miksu",
    "email": "vojtech@miksu.cz",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-portal\\LICENSE"
  },
  "react-redux@5.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/reactjs/react-redux",
    "publisher": "Dan Abramov",
    "email": "dan.abramov@me.com",
    "url": "http://github.com/gaearon",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-redux\\LICENSE.md"
  },
  "react-router-redux@4.0.7": {
    "licenses": "MIT",
    "repository": "https://github.com/reactjs/react-router-redux",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-router-redux\\LICENSE"
  },
  "react-tap-event-plugin@2.0.1": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/zilverline/react-tap-event-plugin",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react-tap-event-plugin\\LICENSE"
  },
  "react@15.4.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/facebook/react",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\react\\LICENSE"
  },
  "readable-stream@0.0.4": {
    "licenses": "BSD",
    "repository": "https://github.com/isaacs/readable-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\write-stream\\node_modules\\readable-stream\\LICENSE"
  },
  "readable-stream@1.0.33": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/readable-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\readable-stream\\LICENSE"
  },
  "readable-stream@1.0.34": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/readable-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hyperquest\\node_modules\\readable-stream\\LICENSE"
  },
  "readable-stream@1.1.14": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/readable-stream",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\duplexer2\\node_modules\\readable-stream\\LICENSE"
  },
  "readable-stream@2.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/nodejs/readable-stream",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\jsonist\\node_modules\\readable-stream\\LICENSE"
  },
  "readable-stream@2.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/nodejs/readable-stream",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\concat-stream\\node_modules\\readable-stream\\LICENSE"
  },
  "readline2@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sboudrias/readline2",
    "publisher": "Simon Boudrias",
    "email": "admin@simonboudrias.com"
  },
  "recast@0.10.43": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/recast",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\recast\\LICENSE"
  },
  "recast@0.11.20": {
    "licenses": "MIT",
    "repository": "https://github.com/benjamn/recast",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\node_modules\\recast\\LICENSE"
  },
  "rechoir@0.6.2": {
    "licenses": "MIT",
    "repository": "https://github.com/tkellen/node-rechoir",
    "publisher": "Tyler Kellen",
    "url": "http://goingslowly.com/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\rechoir\\LICENSE"
  },
  "recompose@0.21.2": {
    "licenses": "MIT",
    "repository": "https://github.com/acdlite/recompose",
    "publisher": "Andrew Clark",
    "email": "acdlite@me.com"
  },
  "redux-thunk@2.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/gaearon/redux-thunk",
    "publisher": "Dan Abramov",
    "email": "dan.abramov@me.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\redux-thunk\\LICENSE.md"
  },
  "redux@3.6.0": {
    "licenses": "MIT",
    "repository": "https://github.com/reactjs/redux",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\redux\\LICENSE.md"
  },
  "regenerator-runtime@0.10.1": {
    "licenses": "MIT",
    "repository": "https://github.com/facebook/regenerator/tree/master/packages/regenerator-runtime",
    "publisher": "Ben Newman",
    "email": "bn@cs.stanford.edu"
  },
  "request@2.79.0": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/request/request",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\request\\LICENSE"
  },
  "require-uncached@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/require-uncached",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\require-uncached\\license"
  },
  "resolve-from@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/resolve-from",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\require-uncached\\node_modules\\resolve-from\\license"
  },
  "resolve-pathname@2.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/mjackson/resolve-pathname",
    "publisher": "Michael Jackson"
  },
  "resolve@1.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-resolve",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\resolve\\LICENSE"
  },
  "restore-cursor@1.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/restore-cursor",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\restore-cursor\\license"
  },
  "rimraf@2.5.4": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/rimraf",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\rimraf\\LICENSE"
  },
  "run-async@0.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/SBoudrias/run-async",
    "publisher": "Simon Boudrias",
    "email": "admin@simonboudrias.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\run-async\\LICENSE"
  },
  "rx-lite@3.1.2": {
    "licenses": "Apache License, Version 2.0",
    "repository": "https://github.com/Reactive-Extensions/RxJS",
    "publisher": "Cloud Programmability Team",
    "url": "https://github.com/Reactive-Extensions/RxJS/blob/master/authors.txt"
  },
  "scope-eval@0.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/alexdavid/scope-eval",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\scope-eval\\LICENSE"
  },
  "semver@5.1.1": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/node-semver",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\levelup\\node_modules\\semver\\LICENSE"
  },
  "semver@5.3.0": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/node-semver",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\semver\\LICENSE"
  },
  "set-blocking@2.0.0": {
    "licenses": "ISC",
    "repository": "https://github.com/yargs/set-blocking",
    "publisher": "Ben Coe",
    "email": "ben@npmjs.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\set-blocking\\LICENSE.txt"
  },
  "setimmediate@1.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/yuzujs/setImmediate",
    "publisher": "YuzuJS",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\setimmediate\\LICENSE.txt"
  },
  "shelljs@0.6.1": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/shelljs/shelljs",
    "publisher": "Artur Adib",
    "email": "arturadib@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-node\\node_modules\\shelljs\\LICENSE"
  },
  "shelljs@0.7.6": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/shelljs/shelljs",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\shelljs\\LICENSE"
  },
  "signal-exit@3.0.2": {
    "licenses": "ISC",
    "repository": "https://github.com/tapjs/signal-exit",
    "publisher": "Ben Coe",
    "email": "ben@npmjs.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\signal-exit\\LICENSE.txt"
  },
  "simple-assign@0.1.0": {
    "licenses": "MIT",
    "repository": "https://github.com/newoga/simple-assign",
    "publisher": "Neil Gabbadon",
    "email": "neil.gabbadon@gmail.com",
    "url": "http://github.com/newoga"
  },
  "simple-get@1.4.3": {
    "licenses": "MIT",
    "repository": "https://github.com/feross/simple-get",
    "publisher": "Feross Aboukhadijeh",
    "email": "feross@feross.org",
    "url": "http://feross.org/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\simple-get\\LICENSE"
  },
  "simple-mime@0.1.0": {
    "licenses": "MIT*",
    "repository": "https://github.com/creationix/simple-mime",
    "publisher": "Tim Caswell",
    "email": "tim@creationix.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\simple-mime\\LICENSE"
  },
  "slice-ansi@0.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/slice-ansi",
    "publisher": "David Caccavella",
    "email": "threedeecee@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\slice-ansi\\license"
  },
  "sntp@1.0.9": {
    "licenses": "BSD",
    "repository": "https://github.com/hueniverse/sntp",
    "publisher": "Eran Hammer",
    "email": "eran@hammer.io",
    "url": "http://hueniverse.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\sntp\\LICENSE"
  },
  "source-map@0.4.4": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/mozilla/source-map",
    "publisher": "Nick Fitzgerald",
    "email": "nfitzgerald@mozilla.com"
  },
  "source-map@0.5.6": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/mozilla/source-map",
    "publisher": "Nick Fitzgerald",
    "email": "nfitzgerald@mozilla.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\commoner\\node_modules\\source-map\\LICENSE"
  },
  "spark-md5@2.0.2": {
    "licenses": "WTFPL",
    "repository": "https://github.com/satazor/js-spark-md5",
    "publisher": "Andr├⌐ Cruz",
    "email": "andremiguelcruz@msn.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\spark-md5\\LICENSE"
  },
  "spark-md5@3.0.0": {
    "licenses": "WTFPL",
    "repository": "https://github.com/satazor/js-spark-md5",
    "publisher": "Andr├⌐ Cruz",
    "email": "andremiguelcruz@msn.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\pouchdb\\node_modules\\spark-md5\\LICENSE"
  },
  "sprintf-js@1.0.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/alexei/sprintf.js",
    "publisher": "Alexandru Marasteanu",
    "email": "hello@alexei.ro",
    "url": "http://alexei.ro/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\sprintf-js\\LICENSE"
  },
  "sshpk@1.10.2": {
    "licenses": "MIT",
    "repository": "https://github.com/arekinath/node-sshpk",
    "publisher": "Joyent, Inc",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\sshpk\\LICENSE"
  },
  "string-width@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/string-width",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\inquirer\\node_modules\\string-width\\license"
  },
  "string-width@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/string-width",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\string-width\\license"
  },
  "string_decoder@0.10.31": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/string_decoder",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\string_decoder\\LICENSE"
  },
  "stringstream@0.0.5": {
    "licenses": "MIT",
    "repository": "https://github.com/mhart/StringStream",
    "publisher": "Michael Hart",
    "email": "michael.hart.au@gmail.com",
    "url": "http://github.com/mhart",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\stringstream\\LICENSE.txt"
  },
  "strip-ansi@3.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/strip-ansi",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\strip-ansi\\license"
  },
  "strip-bom@3.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/strip-bom",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\strip-bom\\license"
  },
  "strip-json-comments@1.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/strip-json-comments",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\eslint-config-node\\node_modules\\strip-json-comments\\license"
  },
  "strip-json-comments@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/strip-json-comments",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\strip-json-comments\\license"
  },
  "supports-color@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/supports-color",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "http://sindresorhus.com"
  },
  "supports-color@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/chalk/supports-color",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\chalk\\node_modules\\supports-color\\license"
  },
  "symbol-observable@1.0.4": {
    "licenses": "MIT",
    "repository": "https://github.com/blesh/symbol-observable",
    "publisher": "Ben Lesh",
    "email": "ben@benlesh.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\symbol-observable\\license"
  },
  "table@3.8.3": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/gajus/table",
    "publisher": "Gajus Kuizinas",
    "email": "gajus@gajus.com",
    "url": "http://gajus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\table\\LICENSE"
  },
  "tar-fs@1.15.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/tar-fs",
    "publisher": "Mathias Buus",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tar-fs\\LICENSE"
  },
  "tar-stream@1.2.2": {
    "licenses": "MIT",
    "repository": "https://github.com/mafintosh/tar-stream",
    "publisher": "Mathias Buus",
    "email": "mathiasbuus@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tar-stream\\LICENSE"
  },
  "tar@2.2.1": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/node-tar",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tar\\LICENSE"
  },
  "tether@1.4.0": {
    "licenses": "MIT",
    "repository": "https://github.com/HubSpot/tether",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tether\\LICENSE"
  },
  "text-table@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/text-table",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\text-table\\LICENSE"
  },
  "through2@0.6.5": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/through2",
    "publisher": "Rod Vagg",
    "email": "r@va.gg",
    "url": "https://github.com/rvagg",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\hyperquest\\node_modules\\through2\\LICENSE"
  },
  "through2@2.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/rvagg/through2",
    "publisher": "Rod Vagg",
    "email": "r@va.gg",
    "url": "https://github.com/rvagg",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\through2\\LICENSE"
  },
  "through@2.3.8": {
    "licenses": "MIT",
    "repository": "https://github.com/dominictarr/through",
    "publisher": "Dominic Tarr",
    "email": "dominic.tarr@gmail.com",
    "url": "dominictarr.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\through\\LICENSE.MIT"
  },
  "to-fast-properties@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/to-fast-properties",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\to-fast-properties\\license"
  },
  "to-string-tag-x@1.0.11": {
    "licenses": "MIT",
    "repository": "https://github.com/Xotic750/to-string-tag-x",
    "publisher": "Graham Fairweather",
    "email": "xotic750@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\to-string-tag-x\\LICENSE"
  },
  "tough-cookie@2.3.2": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/salesforce/tough-cookie",
    "publisher": "Jeremy Stashewsky",
    "email": "jstashewsky@salesforce.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tough-cookie\\LICENSE"
  },
  "tryit@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/HenrikJoreteg/tryit",
    "publisher": "Henrik Joreteg",
    "email": "henrik@andyet.net"
  },
  "tunnel-agent@0.4.3": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/mikeal/tunnel-agent",
    "publisher": "Mikeal Rogers",
    "email": "mikeal.rogers@gmail.com",
    "url": "http://www.futurealoof.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tunnel-agent\\LICENSE"
  },
  "tweetnacl@0.14.5": {
    "licenses": "Unlicense",
    "repository": "https://github.com/dchest/tweetnacl-js",
    "publisher": "TweetNaCl-js contributors",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\tweetnacl\\LICENSE"
  },
  "type-check@0.3.2": {
    "licenses": "MIT",
    "repository": "https://github.com/gkz/type-check",
    "publisher": "George Zahariev",
    "email": "z@georgezahariev.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\type-check\\LICENSE"
  },
  "typedarray@0.0.6": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/typedarray",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\typedarray\\LICENSE"
  },
  "ua-parser-js@0.7.12": {
    "licenses": "(GPL-2.0 OR MIT)",
    "repository": "https://github.com/faisalman/ua-parser-js",
    "publisher": "Faisal Salman",
    "email": "fyzlman@gmail.com",
    "url": "http://faisalman.com"
  },
  "unreachable-branch-transform@0.3.0": {
    "licenses": "MIT",
    "repository": "https://github.com/zertosh/unreachable-branch-transform",
    "publisher": "Andres Suarez",
    "email": "zertosh@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\unreachable-branch-transform\\LICENSE"
  },
  "unzip-response@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/unzip-response",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\unzip-response\\license"
  },
  "url-template@2.0.8": {
    "licenses": "BSD",
    "repository": "https://github.com/bramstein/url-template",
    "publisher": "Bram Stein",
    "email": "b.l.stein@gmail.com",
    "url": "http://www.bramstein.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\url-template\\LICENSE"
  },
  "user-home@2.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/sindresorhus/user-home",
    "publisher": "Sindre Sorhus",
    "email": "sindresorhus@gmail.com",
    "url": "sindresorhus.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\user-home\\license"
  },
  "util-deprecate@1.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/TooTallNate/util-deprecate",
    "publisher": "Nathan Rajlich",
    "email": "nathan@tootallnate.net",
    "url": "http://n8.io/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\util-deprecate\\LICENSE"
  },
  "util-extend@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/isaacs/util-extend",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\util-extend\\LICENSE"
  },
  "uuid@3.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/kelektiv/node-uuid",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\uuid\\LICENSE.md"
  },
  "validate.io-undefined@1.0.3": {
    "licenses": "MIT",
    "repository": "https://github.com/validate-io/undefined",
    "publisher": "Athan Reines",
    "email": "kgryte@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\validate.io-undefined\\LICENSE"
  },
  "value-equal@0.2.0": {
    "licenses": "MIT",
    "repository": "https://github.com/mjackson/value-equal",
    "publisher": "Michael Jackson"
  },
  "verror@1.3.6": {
    "licenses": "MIT*",
    "repository": "https://github.com/davepacheco/node-verror",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\verror\\LICENSE"
  },
  "vuvuzela@1.0.3": {
    "licenses": "Apache-2.0",
    "repository": "https://github.com/nolanlawson/vuvuzela",
    "publisher": "Nolan Lawson",
    "email": "nolan.lawson@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\vuvuzela\\LICENSE"
  },
  "warning@3.0.0": {
    "licenses": "BSD-3-Clause",
    "repository": "https://github.com/BerkeleyTrue/warning",
    "publisher": "Berkeley Martinez",
    "email": "berkeley@r3dm.com",
    "url": "http://www.freecodecamp.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\warning\\LICENSE.md"
  },
  "whatwg-fetch@2.0.2": {
    "licenses": "MIT",
    "repository": "https://github.com/github/fetch",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\whatwg-fetch\\LICENSE"
  },
  "which@1.2.12": {
    "licenses": "ISC",
    "repository": "https://github.com/isaacs/node-which",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\which\\LICENSE"
  },
  "wide-align@1.1.0": {
    "licenses": "ISC",
    "repository": "https://github.com/iarna/wide-align",
    "publisher": "Rebecca Turner",
    "email": "me@re-becca.org",
    "url": "http://re-becca.org/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\wide-align\\LICENSE"
  },
  "wordwrap@1.0.0": {
    "licenses": "MIT",
    "repository": "https://github.com/substack/node-wordwrap",
    "publisher": "James Halliday",
    "email": "mail@substack.net",
    "url": "http://substack.net",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\wordwrap\\LICENSE"
  },
  "wrappy@1.0.2": {
    "licenses": "ISC",
    "repository": "https://github.com/npm/wrappy",
    "publisher": "Isaac Z. Schlueter",
    "email": "i@izs.me",
    "url": "http://blog.izs.me/",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\wrappy\\LICENSE"
  },
  "write-stream@0.4.3": {
    "licenses": "MIT",
    "repository": "https://github.com/Raynos/write-stream",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\write-stream\\LICENCE"
  },
  "write@0.2.1": {
    "licenses": "MIT",
    "repository": "https://github.com/jonschlinkert/write",
    "publisher": "Jon Schlinkert",
    "url": "https://github.com/jonschlinkert",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\write\\LICENSE"
  },
  "xtend@4.0.1": {
    "licenses": "MIT",
    "repository": "https://github.com/Raynos/xtend",
    "publisher": "Raynos",
    "email": "raynos2@gmail.com",
    "licenseFile": "D:\\Development\\LA\\Node\\node_modules\\xtend\\LICENCE"
  }
};