import createHistory from 'history/createBrowserHistory'

const _history = createHistory()

export const pushRoute = (to) => {
  if(to) {
    _history.push(to)
  } 
  // else {
  //   history.push({
  //     pathname: event.currentTarget.pathname,
  //     search: event.currentTarget.search,
  //   });
  // }
}

export default _history
