import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Standard from '../../components/Layouts/Standard'

import translate from '../../shared/language/Translate'

import { 
  Collapse,
  Divider,
  List, 
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  TextField, 
} from '@material-ui/core'

import { 
  joinStrings,
  isEmptyOrWhitespace,
} from './../../shared/StringUtil'

import {
  broadwaySortFieldsFn,
  genericSortFn, 
  num, 
} from './../../shared/Utility'

import {
  readAccessories,
} from './../../shared/vectorworks'

import {
  applyVariables
} from './../../shared/InstrumentsShared'

import BuildIcon from '@material-ui/icons/Build'
import DevIcon from '@material-ui/icons/BugReport'
import PDFIcon from '@material-ui/icons/PictureAsPdf'
import SpotIcon from '@material-ui/icons/Announcement'
import SettingsIcon from '@material-ui/icons/Settings'
import NotDoneIcon from '@material-ui/icons/NotInterested'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'


import { simpleCueSheet } from './../../shared/printing/ReportCues'
import { reportInstrumentSchedule } from './../../shared/printing/ReportInstruments'
import { reportGenericInstrument } from './../../shared/printing/GenericInstrumentReport'
import { spotOverview } from './../../shared/printing/SpotOverview'
import {
  ALIGNMENT,
  DEFAULT_BOLD,
  DEFAULT_FONT,
  PAPER_AVERY_5160_FBA_LABEL_1x2n5_8,
  PAPER_US_LETTER_PORTRAIT,
  PAPER_US_LETTER_BOOKLET,
  TEXT_OPTIONS,
  generateReport,
  rect,
  text,
  line,
} from './../../shared/printing/GenericReport'
import blobStream from 'blob-stream'

import {
  processCues,
  utilCopyCue,
} from './../reducers/CueLogic'
import { 
  loadInstruments, 
  Sorts 
} from '../reducers/InstrumentLogic'
import { 
  showDialog,
  showError,
} from '../reducers/InterfaceLogic'

import {
  createPdfBlob, dispatchCreatePdfBlob
} from './PaperworkUtil'

import DefaultNotes from './reports/sample-notes'
import DefaultNotesByPriority from './reports/sample-notes-by-priority'

const defaultHeader = (name = 'UNKNOWN') => {
  return [
    {
      'logo': 'show',
      'posX': 0,
      'posY': 0,
      'width': 300,
      'height': 50
    }, {
      'text': '{show}',
      'posX': 0,
      'posY': 0,
      'fontSize': 16,
      'font': 'Helvetica-Bold',
      'align': 'center',
      'wrap': false
    }, {
      'text': name,
      'posX': 0,
      'posY': 18,
      'fontSize': 16,
      'font': 'Helvetica-Bold',
      'align': 'center',
      'wrap': false
    }
  ]
}

const defaultFooter = (report) => {
  return [
    //LEFT
    {
      text: '{paperworkDate}',
      posX: 0,
      posY: 14,
      align: 'left',
      wrap: false,
    },
    //CENTER
    {
      text: 'Created with Light Assistant',
      font: 'Helvetica', //Use a better default...
      fontSize: 10,
      posX: 0,
      posY: 14,
      align: 'center',
      wrap: false,
    },
    {
      text: 'http://lightassistant.com',
      font: 'Courier-Oblique', //Use a better default...
      fontSize: 8,      
      posX: 0,
      posY: 24,
      align: 'center',
      wrap: false,
    },
    //RIGHT
    {
      text: 'Page {page} of {pages}',
      posX: 0,
      posY: 14,
      align: 'right',
      wrap: false,
    },
  ]
}
//Import the default Reports
const updateDefaultReports = () => {
  const makeReport = (report) => {
    const copy = {...report}
    copy.header = defaultHeader(copy.name)
    copy.footer = defaultFooter(copy)
    return copy
  }

  return [
    makeReport( require('./defaults/instrument-schedule.json') ),
  ]
}
class PaperworkPage extends React.Component {

  constructor(props) {
    super(props)
    this.defaultReports = updateDefaultReports()
    this.state = {
      pdf: null,
      excel: null,
      open: {
        standard: true,
        custom: true,
        excel: false,
        pending: false,
        test: false,
        
      }
    }
  }

  componentDidMount() {
    this.renderCueSheet()
  }

  renderOutput(pdf = null, excel = null) {
    this.setState({
      ...this.state,
      pdf: pdf,
      excel: excel
    })
  }

  toggleList(name) {
    const open = { 
      ...this.state.open 
    }

    open[name] = !open[name]
    this.setState({
      open
    })
  } 

  displaySettings() {
    this.setState({
      ...this.state,
      settings: true,
    })
  }

  hideSettings() {
    this.setState({
      ...this.state,
      settings: false,
    })
  }

  renderCueSheet() {
    const { show } = this.props
    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()
      const run = (_cues) => {
        let stream = blobStream()
        
        const cues = []
        const spotCount = this.props.settings.spotCount || 2
        for(let q of _cues) {
          if(q.deleted) {
            continue
          }
          if(q.spots && q.spots.length > 0) {
            const copy = utilCopyCue(q)
            copy.spots = copy.spots.slice(0, spotCount)

            for(let i = 0; i < spotCount; i++) {
              if(!copy.spots[i]) {
                copy.spots[i] = {}
              }
            }
            
            cues.push(copy)
          } else {
            cues.push(q)
          }
        }

        simpleCueSheet(cues, stream, null, show).then(
          output => {
          console.log('output pdf->')
          console.log(output)
          output.on('finish', ()=>{
            console.log('stream finished...')
            const blob = output.toBlob('application/pdf')
            let url = URL.createObjectURL(blob)

            if(this.pdfFrame){
              this.pdfFrame.src = '/web/viewer.html?file=' + url  
            }
            
            this.renderOutput(url, null)
          })
        }).catch(error => {
          console.log(error)
        })
      }

      dispatch( processCues(run) )
    })
  }

  renderSpotOverview() {
    const { show } = this.props
    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()
      const run = (_cues) => {

        const cues = []
        const spotCount = this.props.settings.spotCount || 2
        for(let q of _cues) {
          if(q.spots && q.spots.length > 0) {
            const copy = utilCopyCue(q)
            copy.spots = copy.spots.slice(0, spotCount)
            for(let i = 0; i < spotCount; i++) {
              if(!copy.spots[i]) {
                copy.spots[i] = {}
              }
            }
            cues.push(copy)
          } else {
            cues.push(q)
          }
        }

        const onlySpots = cues.filter(cue => {
          return cue.showSpots && cue.spots.length > 0
        })
        let stream = blobStream()
        
        spotOverview(onlySpots, stream, null, show).then(
          output => {
          console.log('output pdf->')
          console.log(output)
          output.on('finish', ()=>{
            console.log('stream finished...')
            const blob = output.toBlob('application/pdf')
            let url = URL.createObjectURL(blob)

            if(this.pdfFrame){
              this.pdfFrame.src = '/web/viewer.html?file=' + url  
            }
            
            this.renderOutput(url, null)
          })
        }).catch(error => {
          console.log(error)
        })
      }

      dispatch( processCues(run) )
    })
  }

  renderColorCutList() {
    const { show } = this.props

    const colorCutList = {
      header: defaultHeader('Color Cut List'),
      heading: {
        text: 'Color Cut List',
        font: 'Helvetica-Bold',
        fontSize: 14,
        posX: 0,
        posY: 0,
        height: 20,
      },
      people: {
        disable: false,
        align: 'left',
        font: 'Helvetica-Bold',
        posX: 400,
        posY: 0,
      },
      footer: {

      },

      //IMPORTED
      columns: [ 
        {
          key: 'count',
          width: 64,
          fontSize: 12,
          align: 'center',  
          formatter: (x) => {
            return `(${x})`
          }        
        }, {
          key: 'color',
          width: 128,
          fontSize: 12,
          align: 'left',
          formatter: (_, inst) => {
            const color = inst.color
            const userField1 = inst.userField1 || ''
            const frost = inst.frost
            const template = inst.template ? 'T:'+inst.template : ''
            const template2 = inst.template2 ? 'T:'+inst.template2 : ''

            return joinStrings(color, frost, userField1, template, template2)
          }
        }, {
          key: 'frameSize',
          width: 128,
          fontSize: 10,
          align: 'left',   

        }, {
          key: 'instrumentType',
          width: 128,
          fontSize: 10,
          align: 'left',           
        },
      ],
      labels: {
        channel: 'Color',
        address: 'Frame Size',
        circuit: 'Count',
      }      
    }

    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()
      let frameErrors = false
      const run = (unfiltered) => {
        let stream = blobStream()

        let instruments = unfiltered.filter(x => !x.deleted)
        
        const colors = {}
        for(let inst of instruments) {
          if(inst.deviceType == 'Light' || inst.deviceType == 'Moving Light') {
            let frameSize = inst.frameSize || inst.instrumentType //if we don't have a size, print the unit.

            let _color = joinStrings( inst.color, inst.frost, inst.userField1 )
            let set = colors[_color]

            if( isEmptyOrWhitespace(_color) ) {
              continue
            }

            if(!inst.frameSize) {
              frameErrors = true
            }
            
            if(!set) {
              set = {
                frameSizes:{}
              }
              colors[_color] = set
            } 

            if(!set.frameSizes[frameSize]) {
              set.frameSizes[frameSize] = []
            }

            set.frameSizes[frameSize].push(inst)
          }
        }

        //Flatten data
        let colorData = []
        let colorKeys = Object.keys(colors)
        colorKeys.sort()

        for(let c of colorKeys ) {
          if(!colors[c]) {
            continue
          }
          let color = colors[c]
          let frames = Object.keys(color.frameSizes)
          frames.sort()

          for(let frame of frames) {
            let _insts = color.frameSizes[frame]
            colorData.push({
              color: c,
              frameSize: frame,
              count: _insts.length || 'none',
              instrumentType: (_insts[0] || {}).instrumentType,
            })
          }
        }

        const colorSort = broadwaySortFieldsFn('color', 'frameSize')
        colorData.sort( colorSort )

        if(frameErrors) {
          setTimeout( () => {
            this.props.dispatch( showDialog('Warning: Frame Size Missing', 
            `
            <p>One or more instruments did not have a frame size. In this event Light Assistant prints the <b><tt>Instrument Type</tt></b> as the frame size.</p>
            <p>If you are exporting from Vectorworks select the "Frame Size" option and resyncrhonize.</p>
            `
            ) )
          }, 200)
        }
        
        reportGenericInstrument(colorData, stream, colorCutList, show).then(
          output => {
            console.log('output pdf->')
            console.log(output)
            output.on('finish', ()=>{
              console.log('stream finished...')
              const blob = output.toBlob('application/pdf')
              let url = URL.createObjectURL(blob)
  
              if(this.pdfFrame){
                this.pdfFrame.src = '/web/viewer.html?file=' + url  
              }
              
              this.renderOutput(url, null)
            })
          }).catch(error => {
            console.log(error)
          })
      }

      dispatch( loadInstruments(null, run) )
    })    
  }

  renderColorSchedule() {
    const colorSchedule = {
      sort: Sorts.COLOR_SCHEDULE,
      headerEmpty: 'N/C',
      header: [
        {
          logo: 'show',
          posX: 0,
          posY: 0,
          width: 300,
          height: 50,//we only use the height
        },
        {
          text: '{show}',
          posX: 0,
          posY: 0,
          width: 468,
          fontSize: 16,
          font: 'Helvetica-Bold',
          align: 'center',
          wrap: false,

        }, {
          text: 'Color Schedule',
          posX: 0,
          posY: 18,
          width: 468,
//          color: '#ffaa00',
          fontSize: 16,
          font: 'Helvetica-Bold',
          align: 'center',
          wrap: false,
        }
      ],
      heading: {
        text: '{color}',
        font: 'Helvetica-Bold',
        fontSize: 14,
        posX: 0,
        posY: 0,
        height: 20,
      },
      people: {
        disable: false,
        align: 'left',
        font: 'Helvetica-Bold',
        posX: 400,
        posY: 0,
      },
      footer: [
        {
          text: '{shortDate} {time}',
          posX: 0,
          posY: 0,
          width: 468,
          font: 'Helvetica-Bold',
          align: 'left',
          wrap: false,
        }
      ],

      //IMPORTED
      columns: [ 
        {          
          key: 'position',
          width: 64,
          fontSize: 10,
          align: 'left'
        }, {          
          key: 'unit',
          width: 24,
          fontSize: 10,
          align: 'right'
        }, {
          key: 'channel',
          width: 32,
          fontSize: 10,
          align: 'center',
          formatter: (num) => {
            if(num === undefined || num === null || num.length < 1) {
              return ''
            }
            
            return '(' + num + ')'
          },
        }, {
          key: 'address',
          width: 32,
          fontSize: 10,
          align: 'left',
          formatter: (_, inst) => {
            const u = inst.universe
            const dim = inst.dimmer
            const address = inst.address
            let base =''

            if(u) {
              base = ''+u
              base.trim()
              base += '/'
            }

            if(!address && !dim) {
              return base
            }

            if(address) {
              return base + address
            }

            if(dim) {
              return base + dim
            }
            
            return base + '-'
          },
        }, {
          key: 'circuit',
          width: 32,
          fontSize: 10,
          align: 'left',
          formatter: (_, inst) => {
            const name = inst.circuitName || ''
            const num = inst.circuitNumber || ''
            return name + num
          }   
        }, {
          key: '__instTypeAccessories',
          width: 180,
          fontSize: 10,
          align: 'left',  
          formatter: (_, inst) => {
            const type = inst.instrumentType || ''
            const accessories = readAccessories(inst) || ''
            return type + ' ' + accessories
          }   
        }, {
          key: 'wattage',
          width: 56,
          fontSize: 10,
          align: 'left',  
        }, {
          key: 'colorTemplate',
          width: 76,
          fontSize: 10,
          font: 'Helvetica',
          align: 'left',
          formatter: (_, inst) => {
            const color = inst.color
            const userField1 = inst.userField1 || ''
            const frost = inst.frost
            const template = inst.template ? 'T:'+inst.template : ''
            const template2 = inst.template2 ? 'T:'+inst.template2 : ''

            return joinStrings(color, frost, userField1, template, template2)
          }
        }, {
          key: 'purpose',
          width: 80,
          fontSize: 10,
          font: 'Helvetica',
          align: 'left',
        },
      ],
      labels: {
        position: 'Position',
        unitNumber: '#',
        channel: '(Ch)',
        address: 'Dim',
        circuit: 'Cir.',
        __instTypeAccessories: 'Instrument Type & Accessories',
        wattage: 'Wattage',
        colorTemplate: 'Color & Template',
        purpose: 'Purpose',
      }      
    }

    this.renderGeneric(colorSchedule)
  }

  renderDefaultNotes() {
    const T = this.props.strings
    let copy = { ...DefaultNotes }
    copy.header = defaultHeader(T.get('notesByDeparment'))
    copy.footer = defaultFooter()

    this.props.dispatch( dispatchCreatePdfBlob(copy, (blob) => {
      const url = URL.createObjectURL(blob)

      if(this.pdfFrame){
        this.pdfFrame.src = '/web/viewer.html?file=' + url  
      }
      
      this.renderOutput(url, null)
    }))
  }

  renderNotesByCue() {
    const T = this.props.strings
    let copy = { ...DefaultNotes }
    copy.sort = ['department', 'status', 'cue']
    copy.header = defaultHeader(T.get('notesByCue'))
    copy.footer = defaultFooter()

    this.props.dispatch( dispatchCreatePdfBlob(copy, (blob) => {
      const url = URL.createObjectURL(blob)

      if(this.pdfFrame){
        this.pdfFrame.src = '/web/viewer.html?file=' + url  
      }
      
      this.renderOutput(url, null)
    }))
  }

  renderNotesPriority() {
    const T = this.props.strings
    let copy = { ...DefaultNotesByPriority }
    copy.header = defaultHeader(T.get('notesByPriority'))
    copy.footer = defaultFooter()

    this.props.dispatch( dispatchCreatePdfBlob(copy, (blob) => {
      const url = URL.createObjectURL(blob)

      if(this.pdfFrame){
        this.pdfFrame.src = '/web/viewer.html?file=' + url  
      }
      
      this.renderOutput(url, null)
    }))
  }


  renderAvery5160() {
    const { show } = this.props

    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()

      const run = (unfiltered) => {

        let stream = blobStream()
        let instruments = unfiltered.filter(x => !x.deleted).map(i => {
          let address = i.address || i.dimmer
          let universe = i.universe || ''
          let addressNum = num(address)
          if(universe) {
            address = universe + '/' + address
          } else if (addressNum > 512) {
            let universe = (addressNum % 512) + 1
            address = universe + '/' + address
          } else {
            address = '1/' + address
          }

          let colors = joinStrings(i.color, i.frost)

          //FORMATTING HERE
          return {
            frost: '',
            mode: '',
            ...i,
            address: address,
          }
        })

        const paper = { ...PAPER_AVERY_5160_FBA_LABEL_1x2n5_8 }
        const left = paper.printArea.width - 42
        const head = 20
        const report = {
          paper: paper,
          title: 'Test Report',
          elements: [
            
            //..background fill
            rect(paper.printArea.width - 42, 2, 40, paper.printArea.height - 4, '#ccc', '#ccc', 3, 1),            
            //Outline
            rect(2, 2, paper.printArea.width - 4, paper.printArea.height - 4, 'black', null, 5, 2),
            //Unit Number
            rect(paper.printArea.width - 42, 2, 40, head, 'black', 'black', 3, 1),            

            //Position & unit
            text('{position}', 0, 8, paper.printArea.width - 44, null, false, 13, DEFAULT_BOLD, ALIGNMENT.RIGHT),
            text('#{unit}', paper.printArea.width - 46, 6, null, null, true, 14, DEFAULT_BOLD, ALIGNMENT.CENTER, null, 'white'),

            line(2, head + 2, paper.printArea.width - 4, head + 2),

            text('Circuit', paper.printArea.width - 46, 24, null, null, true, 8, null, ALIGNMENT.CENTER, null, 'black'),
            text('{circuitName}-{circuitNumber}', paper.printArea.width - 46, 32, null, null, true, 14, DEFAULT_BOLD, ALIGNMENT.CENTER, null, 'black'),

            text('Channel', paper.printArea.width - 46, 45, null, null, true, 8, null, ALIGNMENT.CENTER, null, 'black'),
            text('({channel})', paper.printArea.width - 46, 55, null, null, true, 14, DEFAULT_BOLD, ALIGNMENT.CENTER, null, 'black'),

            //Instrument Info Number
            text('{instrumentType} {accessories}', 4, 25, left, null, false, 12),
            text('{color} {frost} {template} {template2}', 4, 37, left, null, false, 10),
            text('Adr:{address} {mode}', 4, 55, left, null, false, 12),

            //text('XXX', 5, 5, 45, 45, true, 20, null, ALIGNMENT.CENTER, ALIGNMENT.V_MIDDLE, 'green'),
            //text('XXX', 55, 5, 45, 45, true, 20, null, ALIGNMENT.CENTER, ALIGNMENT.V_BOTTOM, 'green'),
          ]
        }

        generateReport( report, stream, instruments, show).then(
          output => {
            console.log('output pdf->')
            console.log(output)
            output.on('finish', ()=>{
              console.log('stream finished...')
              const blob = output.toBlob('application/pdf')
              let url = URL.createObjectURL(blob)
  
              if(this.pdfFrame){
                this.pdfFrame.src = '/web/viewer.html?file=' + url  
              }
              
              this.renderOutput(url, null)
            })
          }).catch(error => {
            console.log(error)
          })

      }

      dispatch( loadInstruments(Sorts.INSTRUMENT_SCHEDULE, run) )
    })
  }

  renderGenericReport() {
    const { show } = this.props

    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()

      let stream = blobStream()
      let array = []
      for (let i = 0; i < 60; i++) {
        array[i] = {
          test: i,
          label: 'test row label',
        }
      }

      const report = {
        paper: PAPER_US_LETTER_BOOKLET,
        title: 'Test Report',
        elements: [
          //rect(5, 5, 50, 50, 'red', 'blue', 5, 3),
          text('Test Element Long Text', 5, 5, 100, null, false, 14, null, ALIGNMENT.RIGHT),
          text('Line 2 long text', 5, 20, 50, null, false, 14, null, ALIGNMENT.RIGHT),
          text('Line 3 long text', 5, 40, 25, null, false, 14, null, ALIGNMENT.RIGHT),
          //text('XXX', 5, 5, 45, 45, true, 20, null, ALIGNMENT.CENTER, ALIGNMENT.V_MIDDLE, 'green'),
          //text('XXX', 55, 5, 45, 45, true, 20, null, ALIGNMENT.CENTER, ALIGNMENT.V_BOTTOM, 'green'),
        ]
      }

      generateReport( report, stream, array, show).then(
        output => {
          console.log('output pdf->')
          console.log(output)
          output.on('finish', ()=>{
            console.log('stream finished...')
            const blob = output.toBlob('application/pdf')
            let url = URL.createObjectURL(blob)

            if(this.pdfFrame){
              this.pdfFrame.src = '/web/viewer.html?file=' + url  
            }
            
            this.renderOutput(url, null)
          })
        }).catch(error => {
          console.log(error)
        })
    })
  }

  renderModular(report) {
    const { show } = this.props
    this.props.dispatch( (dispatch, getState)=>{
      this.hideSettings()
    } )
  }

  renderGeneric(report) {

    const { show } = this.props
    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()

      const run = (unfiltered) => {
        let stream = blobStream()
        let instruments = unfiltered.filter(x => !x.deleted)
        reportGenericInstrument(instruments, stream, report, show).then(
          output => {
            console.log('output pdf->')
            console.log(output)
            output.on('finish', ()=>{
              console.log('stream finished...')
              const blob = output.toBlob('application/pdf')
              let url = URL.createObjectURL(blob)
  
              if(this.pdfFrame){
                this.pdfFrame.src = '/web/viewer.html?file=' + url  
              }
              
              this.renderOutput(url, null)
            })
          }).catch(error => {
            console.log(error)
          })
      }

      dispatch( loadInstruments(report.sort, run) )
    })
  }

  renderGenericInstrumentSchedule() {
    const schedule = {
      sort: Sorts.INSTRUMENT_SCHEDULE,
      header: defaultHeader('Instrument Schedule'),
      heading: {
        text: '{position}',
        font: 'Helvetica-Bold',
        fontSize: 14,
        posX: 0,
        posY: 0,
        height: 20,
        color: 'red',
        background: 'yellow',        
      },
      group:  ['position'],
      people: {
        disable: false,
        align: 'left',
        font: 'Helvetica-Bold',
        posX: 400,
        posY: 0,
      },
      footer: {

      },
    }

    this.renderGeneric(schedule)
  }

  renderGenericChannelHookup() {
    
    const channelHookup = {
      sort: Sorts.CHANNEL_HOOKUP,
      header: defaultHeader('Channel Hookup'),
      heading: {
        text: 'Channels ({channel}) to ({last.channel})',
        font: 'Helvetica-Bold',
        fontSize: 14,
        posX: 0,
        posY: 0,
        height: 20,
        color: 'red',
        background: 'yellow',        
      },
      people: {
        disable: false,
        align: 'left',
        font: 'Helvetica-Bold',
        posX: 400,
        posY: 0,
      },
      footer: {

      },

      //IMPORTED
      columns: [ 
        {
          key: 'channel',
          width: 32,
          fontSize: 10,
          align: 'center',
          format: '({channel})',
          Xformatter: (num) => {
            if(num === undefined || num === null || num.length < 1) {
              return ''
            }
            
            return '(' + num + ')'
          },
        }, {
          key: 'address',
          width: 32,
          fontSize: 10,
          align: 'left',
          formatter: (_, inst) => {
            const u = inst.universe
            const dim = inst.dimmer
            const address = inst.address
            let base =''

            if(u) {
              base = ''+u
              base.trim()
              base += '/'
            }

            if(!address && !dim) {
              return base
            }

            if(address) {
              return base + address
            }

            if(dim) {
              return base + dim
            }
            
            return base + '-'
          },
        }, {
          key: 'circuit',
          width: 32,
          fontSize: 10,
          align: 'left',
          formatter: (_, inst) => {
            const name = inst.circuitName || ''
            const num = inst.circuitNumber || ''
            return name + num
          }   
        }, {
          key: '__instTypeAccessories',
          width: 180,
          fontSize: 10,
          align: 'left',  
          formatter: (_, inst) => {
            const type = inst.instrumentType || ''
            const accessories = readAccessories(inst) || ''
            return type + ' ' + accessories
          }   
        }, {
          key: 'wattage',
          width: 56,
          fontSize: 10,
          align: 'left',  
        }, {
          key: 'colorTemplate',
          width: 76,
          fontSize: 10,
          font: 'Helvetica',
          align: 'left',
          formatter: (_, inst) => {
            const color = inst.color
            const userField1 = inst.userField1 || ''
            const frost = inst.frost
            const template = inst.template ? 'T:'+inst.template : ''
            const template2 = inst.template2 ? 'T:'+inst.template2 : ''

            return joinStrings(color, frost, userField1, template, template2)
          }
        }, {
          key: 'purpose',
          width: 80,
          fontSize: 10,
          font: 'Helvetica',
          align: 'left',
        }, {
          key: 'position',
          width: 64,
          fontSize: 10,
          font: 'Helvetica',
          align: 'left',          
        }, {          
          key: 'unit',
          width: 24,
          fontSize: 10,
          align: 'center'
        }, 
      ],
      labels: {
        channel: '(Ch)',
        address: 'Dim',
        circuit: 'Cir.',
        __instTypeAccessories: 'Instrument Type & Accessories',
        wattage: 'Wattage',
        colorTemplate: 'Color & Template',
        purpose: 'Purpose',
        position: 'Position',
        unitNumber: '#'
      }      
    }

    this.renderGeneric(channelHookup)
  }

  renderExcelMasterInstrumentData() {
    this.renderOutput(null, 'test')
  }

  renderPdfPreview() {
    return (
      <iframe 
        style={{
          flex: 1,
          width: '100%',
          //minHeight: '800px'
        }}
        ref={x => this.pdfFrame = x} 
      />  
    )
  }

  renderInstructions() {
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        <h1>Paperwork</h1>
        <p>Select a report on the left to view either a PDF or Excel for export.</p>
        <p>In the future you will be able to edit these reports, but in the meantime please feel free to reach out with your specific needs.</p>
      </div>
    )
  }

  renderCustomPdfReport(index) {
    const pdf = JSON.parse(JSON.stringify(this.props.pdf[index]))

    if(!pdf) {
      alert('Unable to render report ' + index)
      return
    }

    this.props.dispatch( dispatchCreatePdfBlob(pdf, (blob) => {
      const url = URL.createObjectURL(blob)

      if(this.pdfFrame){
        this.pdfFrame.src = '/web/viewer.html?file=' + url  
      }
      
      this.renderOutput(url, null)
    }))
  }

  renderCustomPdfReportBASE(index) {
    const pdf = JSON.parse(JSON.stringify(this.props.pdf[index]))

    if(!pdf) {
      alert('Unable to render report ' + index)
      return
    }

    this.props.dispatch( (dispatch, getState) => {
      const state = getState()
      const show = { ...state.show.show }

      const runIt = (dataset) => {
        createPdfBlob(pdf, dataset, show).then(blob => {
          const url = URL.createObjectURL(blob)

          if(this.pdfFrame){
            this.pdfFrame.src = '/web/viewer.html?file=' + url  
          }
          
          this.renderOutput(url, null)
        }).catch(error => {
          console.log(error)
          dispatch( showError('Unable to render Report', 'Failed to render', error) )
        })
      }
      switch(pdf.data) {
        case 'instruments': {
          dispatch( loadInstruments(null, runIt) )
          break
        }

        case 'cues' : {
          runIt(state.cues.cues.slice())
          break
        }

        case 'notes': {
          alert('notes note defined')
          break
        }

        default: {
          alert('dataset ' + pdf.data + ' is not recognized')
        }
      }
    })
    
  }
  renderCustomPdfReportOLD(index) {
    //Deep clone
    const pdf = JSON.parse(JSON.stringify(this.props.pdf[index]))

    if(!pdf) {
      alert('Unable to render report ' + index)
      return
    }

    const { show } = this.props
    this.props.dispatch( (dispatch, getState) => {
      this.hideSettings()
      const state = getState()

      const run = (unfiltered, sortFunction, filterFunction) => {
        let stream = blobStream()
        //FIXME apply filters here
        let dataSource = unfiltered.filter(x => !x.deleted) //remove deleted
        
        if(filterFunction) {
          dataSource.filter( filterFunction)
        }

        if(sortFunction) {
          dataSource.sort( sortFunction )
        }

        reportGenericInstrument(dataSource, stream, pdf, show).then(
          output => {
            console.log('output pdf->')
            console.log(output)
            output.on('finish', ()=>{
              console.log('stream finished...')
              const blob = output.toBlob('application/pdf')
              let url = URL.createObjectURL(blob)
  
              if(this.pdfFrame){
                this.pdfFrame.src = '/web/viewer.html?file=' + url  
              }
              
              this.renderOutput(url, null)
            })
          }).catch(error => {
            console.log(error)
          })
      }

      if(pdf.data === 'instruments') {
        const allSorts = []
        for(let s of pdf.group) {
          allSorts.push(s)
        }

        for (let s of pdf.sort) {
          allSorts.push(s)
        }

        const sortFn = broadwaySortFieldsFn(...allSorts)

        const filterFn = ()=> true
        const runSorted = (data) => {

          for(let i = 0; i < data.length; i++) {
            data[i] = applyVariables(data[i])
          }
          run(data, sortFn, filterFn)
        }
        dispatch( loadInstruments(null, runSorted) )
      } else if (pdf.data === 'cues') {
        run( (state.cues.cues || []).slice() )
      } else if (pdf.data === 'notes') {
        alert('Notes not implemented yet...')
      }

    })
  }

  renderExcel() {
    return (
      <div style={{flex:1}}>
        <button onClick={x=>alert('nope...')}>EXPORT EXCEL</button>
        <table id='download_excel'>
          <thead>
            <tr>
              <th>Test Excel here</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Test Data here...</td>
              <td>Test Data here...</td>
            </tr>
            <tr>
              <td>Test Data here...</td>
              <td>Test Data here...</td>
            </tr>
            <tr>
              <td>Test Data here...</td>
              <td>Test Data here...</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const T = this.props.strings
    const { show } = this.props
    const { open } = this.state
    const notImplemented = ()=>{
      alert('not currently implemented')
    }
    const fakeData = []
    for(let i = 0; i < 141; i++){
      fakeData.push({
        id: i,
        data: 'some random data to test printing on'
      })
    }

    const standard = [
      {
        text: T.get('cueSheet'),
        fn: ()=>{
          this.renderCueSheet()
        },
        icon: <PDFIcon />,
      },{
        text: 'Spot Overview' ,
        icon: <PDFIcon />,
        fn: () => {
          this.renderSpotOverview()
        }                        
      },{
        header: 'In Development'
      },{
        text: T.get('avery5160Labels') ,
        fn: ()=>{
          this.renderAvery5160()
        },
        icon: <PDFIcon />,        
      },{
        text: T.get('genericInstrumentSchedule') ,
        fn: ()=>{
          this.renderGenericInstrumentSchedule()
        },
        icon: <PDFIcon />,
      },{
        text: T.get('genericChannelHookup') ,
        fn: ()=>{
          this.renderGenericChannelHookup()
        },
        icon: <PDFIcon />,
      },{
        text: T.get('genericColorCutList') ,
        fn: ()=>{
          this.renderColorCutList()
        },
        icon: <PDFIcon />,    
      },{
        text: T.get('genericColorSchedule') ,
        fn: ()=>{
          this.renderColorSchedule()
        },
        icon: <PDFIcon />,            
      }, {
        text: T.get('notesByDeparment') ,
        fn: ()=>{
          this.renderDefaultNotes()
        },
        icon: <PDFIcon />,            
      }, {
        text: T.get('notesByCue') ,
        fn: ()=>{
          this.renderNotesByCue()
        },
        icon: <PDFIcon />,            
      }, {
        text: T.get('notesByPriority') ,
        fn: ()=>{
          this.renderNotesPriority()
        },
        icon: <PDFIcon />,            
      },
    ]

    const pending = [
      {
        text: 'Dimmer/Address Hookup' ,
        icon: <NotDoneIcon />,
      },{
        text: 'Circuit Hookup' ,
        icon: <NotDoneIcon />,
      },{
        text: 'Cue Sheet with Images' ,
        icon: <NotDoneIcon />,
      },{
        text: 'Work and Focus Notes' ,
        icon: <NotDoneIcon />,
      },{
        text: 'Cue Notes' ,
        icon: <NotDoneIcon />,
      },{
        text: 'Individual Spot Cue Sheet' ,
        icon: <NotDoneIcon />,
      },{
        text: 'And any other requests!' ,
        icon: <DevIcon />,        
        fn: () => {
          const a = document.createElement('a')
          a.href = 'mailto:chapman@danielbchapman.com?subject=Feature Request'
          a.innerHTML = 'mailme'
          const container = document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      }
    ]
    const excel = [
      {
        text: 'Master Instrument Data' ,
        icon: <DevIcon />,
        fn: ()=>{
          this.renderExcelMasterInstrumentData()
        }
      }
    ]
    const test = [
      {
        text: 'Generic Engine Test',
        fn: ()=>{
          this.renderGenericReport()
        }
      },
      {
        text: 'Send me a request!' ,
        icon: <DevIcon />,        
        fn: () => {
          const a = document.createElement('a')
          a.href = 'mailto:chapman@danielbchapman.com?subject=Feature Request'
          a.innerHTML = 'mailme'
          const container = document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      }
    ]

    const reports = [ 
      {
        header: T.get('completed')
      },{
        text: T.get('Generic Report'),
        fn: ()=>{
          this.renderGenericReport()
        },
        icon: <DevIcon />,
      } ,{
        header: 'Not Completed'
      },{
        header: 'Excel Export'
      },{
        text: 'Master Instrument Data' ,
        icon: <DevIcon />,
        fn: ()=>{
          this.renderExcelMasterInstrumentData()
        }
      },
    ]

    const item = (el) => {
      const { divider, text, fn, icon } = el
      if(el.header) {
        return null
      }
      if(divider) {
        return <Divider />
      }

      return (
        <ListItem
          key={text}
          button
          onClick={ () => {
            if(fn) {
              fn()
            }
          } }
        >
          { icon ? 
            <ListItemIcon>
              { icon }
            </ListItemIcon>
            : null
          }
          <ListItemText primary={ text } />
        </ListItem>
      )
    }

    const listStyle = {
      maxWidth: 300,
    }
    return (
        <Standard>
          <div
            style={{
              display: 'flex',
              flex: 1,
              maxHeight: '100%',
              flexDirection: 'row',
            }}
          >
            <Paper 
              style={{
                flex: 0,
                maxHeight: '100%',
                minWidth: 380,
                overflowY: 'auto'
              }}
            >
              <List 
                style={{
                  minWidth: 350,
                  maxWidth: 400,
                  overflowX: 'none',
                }}
              >
                <ListItem 
                  button 
                  onClick={e => {
                    this.toggleList('standard')
                  }}
                >
                  <ListItemText primary="Standard Reports" />
                  { open.standard ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={open.standard} timeout="auto">
                  <List 
                    style={listStyle}
                    component="div" 
                    disablePadding
                  >
                    { standard.map( item ) }
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={e => this.toggleList('custom') }
                >
                  <ListItemText primary='Custom Reports'></ListItemText>
                  { open.custom ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={open.custom} timeout="auto">
                  <List 
                    style={listStyle}
                    component="div" 
                    disablePadding
                  >
                    { this.props.pdf.map( (pdf, i) => {
                      const name = pdf.name || 'New Report'
                      return (
                        <ListItem
                          button  
                          key={i + '-' + name}
                          onClick={ () => {
                            this.renderCustomPdfReport(i)
                          } }
                        >
                          <ListItemIcon>
                            <PDFIcon />
                          </ListItemIcon>
                          <ListItemText 
                            primary={ name } 
                            secondary={ pdf.data }
                          />
                        </ListItem>
                      )
                    } ) }
                  </List>
                </Collapse>

                <ListItem 
                  button 
                  onClick={e => {
                    this.toggleList('excel')
                  }}
                >
                  <ListItemText primary="Excel Export" />
                  { open.standard ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={open.excel} timeout="auto">
                  <List 
                    style={listStyle}
                    component="div" 
                    disablePadding
                  >
                    { excel.map( item ) }
                  </List>
                </Collapse>
                
                <ListItem 
                  button 
                  onClick={e => {
                    this.toggleList('pending')
                  }}
                >
                  <ListItemText primary="Planned Reports" />
                  { open.pending ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={open.pending} timeout="auto">
                  <List 
                    style={listStyle}
                    component="div" 
                    disablePadding
                  >
                    { pending.map( item ) }
                  </List>
                </Collapse>

                <ListItem 
                  button 
                  onClick={e => {
                    this.toggleList('test')
                  }}
                >
                  <ListItemText primary="Experimental" />
                  { open.pending ? <ExpandLess /> : <ExpandMore /> }
                </ListItem>

                <Collapse in={open.test} timeout="auto">
                  <List 
                    style={listStyle}
                    component="div" 
                    disablePadding
                  >
                    { test.map( item ) }
                  </List>
                </Collapse>

              </List>
            </Paper>

            <div
              style={{
                flex: 1,
                display: 'flex',
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: this.state.pdf ? 'flex' : 'none'
                }}
              >
                { this.renderPdfPreview() }
              </div>
              <div
                style={{
                  flex: 1,
                  display: this.state.excel ? 'flex' : 'none'
                }}
              >
                { this.renderExcel() }
              </div>
              { !this.state.pdf && !this.state.excel  ? this.renderInstructions() : null }
              
            </div>
          </div>
          
        </Standard>
    )
  }
}

export default connect( s => {
  return {
    paperwork: s.paperwork,
    settings: s.settings,
    show: s.show.show,
    pdf: s.reports.pdf || [],
    excel: s.reports.excel || [],
  }
})(translate('Paperwork')(PaperworkPage))