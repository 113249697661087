import React from 'react'
import PropTypes from 'prop-types'
import translate from '../../shared/language/Translate'

class Spot extends React.Component {

  render(){
    const T = this.props.strings
    const { editing, spot } = this.props
    
    const labelEmpty = (field, empty, bold) => {
      const classes = []
      if(bold){
        classes.push('bold')
      }
      if(field) {
        return <label className={classes.join(' ')}>{field}</label>
      } else {
        return <label className='empty'>{empty}</label>
      }
    }

    if(editing){
      return (
        <div className='spot'>
          { labelEmpty(spot.pickup, T.get('spot_pickup'), true) }
          { labelEmpty(spot.size, T.get('spot_size')) }
          { labelEmpty(spot.frame, T.get('spot_frame')) }
        </div>
      );
    } else {
      return (
        <div className='spot'>
          { labelEmpty(spot.pickup, T.get('spot_pickup'), true) }
          { labelEmpty(spot.size, T.get('spot_size')) }
          { labelEmpty(spot.frame, T.get('spot_frame')) }
        </div>
      );
    }
  }
}
export default translate('CueSheet')(Spot);