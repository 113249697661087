import React from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import {
  instruments as Instruments
} from './../../shared/language'
const InstrumentNotesDialog = (props) => {
  const { onClose, notes, dispatch, open, instrument } = props
  return (
    <Dialog
      open={open}
      onClose={()=>{
        if(onClose) {
          onClose()//required
        }
      }}
    >
      <DialogTitle>{Instruments.get('instrumentNotesDialogTitle', instrument)}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                { Instruments.get('cues') }
              </TableCell>
              <TableCell>
                { Instruments.get('channels') }
              </TableCell>
              <TableCell>
                { Instruments.get('description') }
              </TableCell>
              <TableCell>
                { Instruments.get('controls')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            { notes.map(n => {
              const note = props.notesState[n]
              const relatedCues = (note.relatedCues || []).join(', ')
              const relatedChannels = (note.relatedChannels || []).map(c => {
                return '(' + c + ')'
              }).join(', ')
              return (
                <TableRow>
                  <TableCell>
                    {relatedCues}
                  </TableCell>
                  <TableCell>
                    {relatedChannels}
                  </TableCell>
                  <TableCell>
                    {note.description}
                  </TableCell>
                  <TableCell>
                    {/* Buttons */}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          onClick={()=>{
            if(onClose){
              onClose()
            }
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InstrumentNotesDialog.PropTypes = {
  instrument: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  notes: PropTypes.arrayOf(PropTypes.object)
}

export default connect(s => {
  return {
    notesState: s.notes.notes || {} 
  }
})(InstrumentNotesDialog)