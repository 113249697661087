export default {
  Navigation: {
    lightassistant: `Light Assistant`,
    home : `d'accueil`,
    cues : 'Coup de lumière',
    paperwork : '*Paperwork',
    instruments : 'lumières',
    show: '*Show',
    about: '*About'
  }
};