import React from 'react'
import { connect } from 'react-redux'
import {
  clearError,
} from './../../src/reducers/ErrorLogic'

import {
  saveState,
} from './../../src/reducers/ShowLogic'

import {
  tryReportError,
  downloadError,
  sendErrorToServer
} from './../../shared/ErrorReporter'

import {
  goTo
} from './../../src/reducers/Routing'

import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Paper,
  TextField,
} from '@material-ui/core'

import translate from './../../shared/language/Translate'
const OptInLabel = (props) => {
  const checked = props.checked || false
  const T = props.T
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox 
            checked={checked} 
            onChange={ props.handler(props.prop) } 
            value={ 'checked' + props.prop }
          />
        }
        label={ props.title }
      />
    </FormGroup>
  )
}

class ErrorPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      processed: null,
      optout: {},
    }
  }

  clear() {
    this.props.dispatch( clearError() )
    setTimeout(()=>{
      window.location.reload(true)
    }, 10)
  }

  componentDidMount() {
    tryReportError(this.props.error, this.props.hint, (result) => {
      console.log('result - > ' + result)
      this.setState({processed: result})
    })
  }

  renderGoHome() {
    const { dispatch } = this.props
    return (
      <React.Fragment>
        <h1>Nothing to report!</h1>
        <Button 
          onClick={e => {
            dispatch( clearError() )
            dispatch( goTo('/') )
          }}
        >
          Go Home
        </Button>
      </React.Fragment>
    )
  }

  optInOrOut(prop) {
    const copy = { ...this.state.optout }
    copy[prop] = !copy[prop]
    this.setState({
      ...this.state,
      optout: copy
    })
  }

  renderRequiredProperty(title, collapse) {
    return (
      <div>
        <h3>{title}</h3>
        { collapse && 
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: 400,
            }}
          >
            { collapse }
          </div>
        }
        
      </div>
    )
  }

  renderProperty(title, prop, collapse) {
    const checked = !this.state.optout[prop] //inverse map
    //FIXME we need to render this...

    return (
      <div>
        <h3>{title}</h3>
        <OptInLabel 
          checked={checked}
          T={this.props.strings}
          prop={prop} 
          title={this.props.strings.get('includeOptionalData')} 
          handler={ (x,y)=>{
            console.log(`handler ${x} ${y}`)
          }} 
        />
        { collapse && 
          <div
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: 400,
            }}
          >
            { collapse }
          </div>
        }
        
      </div>
    )

  }
  render() {
    const { dispatch, error } = this.props
    const T = this.props.strings
    const processed = this.state.processed || {}

    const processedProps = ['test', 'test2']
    return (
      <Paper 
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 800,
          minWidth: 600,
          padding: 24,
          margin: '0 auto',
        }}
      >

        <h1>Unexpected Application Error</h1>
        <p>
          Whoops! Looks like something went wrong. Don't panic! Light Assistant stores all data in a recoverable state that
          can be refreshed. If the application crashed it is very unlikely that something corrupted the state.
        </p>

        <p>
          If something did manage to corrupt the state it is very likely the file can be easily recovered. 
          Saving a backup and then loading the file. If the applciation is unrecoverable please save a backup 
          and then use the "New Show" option to clear your data. 
        </p>

        <Button 
            style={{
              margin: 4
            }}
            color='primary'
            variant='contained'
            onClick={e => {
              this.clear()
            }}
          >
            { T.get('refresh') }
          </Button>

        {/* <p>{JSON.stringify(error, null, 2)}</p>
        processed:
        <p>{JSON.stringify(this.state.processed, null, 2)}</p> */}
        { this.state.processed ? 
          <React.Fragment>
            { this.renderRequiredProperty('Error Name', processed.name) }
            { this.renderRequiredProperty('Version', processed.version) }
            { this.renderRequiredProperty('Message', processed.message) }
            { this.renderRequiredProperty('Error Hint', processed.hint) }
            { this.renderRequiredProperty('Location', processed.location) }

            { this.renderRequiredProperty('Stack Trace',
              <div
                style={{
                  display: 'block',
                  fontFamily: 'monospace',
                  whiteSpace: 'pre',
                  color: 'red',
                  backgroundColor: 'black',
                }}
              >
                {processed.stack}
              </div> )
            } 

            { this.renderProperty('Application Screen Shot', 'screenShot', 
              <img src={processed.screenShot} style={{maxWidth: 400, minWidth: 400, minHeight:300}}/>) }
            { this.renderProperty('Application User Data', 'state',
              <div
                style={{
                  display: 'block',
                  fontFamily: 'monospace',
                  whiteSpace: 'pre',
                  color: 'orange',
                  backgroundColor: 'black',
                }}
              >
                {JSON.stringify(processed.state, null, 2)}
              </div> )}

            <h3>Additional Information</h3>
            
            <TextField 
              InputLabelProps={{
                shrink: true,
              }}
              label="Email Address (Optional)"
              placeholder="user@foo.bar"
              helperText="We will only use your email address to contact you with a resolution. It will not be stored or sold."
            />
            <div style={{height: 20,}}></div>
            <TextField 
              multiline
              InputLabelProps={{
                shrink: true,
              }}
              label='Additional Information (Optional)'
              placeholder='Example: "It crashed when I tried to sync with EOS"'
              helperText="Any steps to reproduce the error are appreciated."
            />
          
          </React.Fragment>
        : null}
        

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >

          <Button 
            style={{
              margin: 4
            }}
            variant='contained'
            onClick={e => {
              this.clear()
            }}
          >
            { T.get('refresh') }
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button 
            style={{
              margin: 4
            }}
            color='primary'
            variant='contained'
            onClick={e => {
              dispatch( downloadError(this.props.error, this.props.hint) )
              this.clear()
            }}
          >
            { T.get('download')}
          </Button>

          <Button 
            style={{
              margin: 4
            }}
            color='secondary'
            variant='contained'
            onClick={e => {
              alert('The server currently is in beta, please download the report and mail it to chapman@danielbchapman.com.')
            }}
          >
            { T.get('submit')}
          </Button>

          <Button 
            style={{
              margin: 4
            }}
            color='secondary'
            variant='contained'
            onClick={e => {
              this.props.dispatch( saveState('backup') )
            }}
          >
            { T.get('saveBackup') }
          </Button>
        </div>
        
        {/* <tt>
        { JSON.stringify(processed, null, 2) }
        </tt> */}
      </Paper>
    )

  }
}

export default connect(s => {
  const errors = s.errors || {}
  return {
    error: errors.error,
    hint: errors.hint
  }
})( translate('Errors')(ErrorPage) )