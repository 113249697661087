export const ADD_CUE = 'CUE_ADD_CUE';
export const DELETE_CUE = 'CUE_DELETE_CUE';
export const REPLACE_CUE = 'CUE_REPLACE_CUE';
export const LOAD_CUES = 'CUE_LOAD_CUES';

const INITIAL_STATE = {
    id: '-1',
    name: '<Unknown User>',
    email: '<unknown@lightassistant.com>',
    plan: 'free'
};

 export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    case ADD_CUE:
      console.log(ADD_CUE);
      return state;
    case DELETE_CUE:
      console.log(DELETE_CUE);
      return state;
    case REPLACE_CUE:
     console.log(REPLACE_CUE);
      return state;
    case LOAD_CUES:
      console.log(LOAD_CUES);
      return state;

   }

  return state;
}