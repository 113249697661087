import { 
  call,
  cancel,
  select,
  delay,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

import {
  selectWorksheet,
  createDefaultWorksheets,
} from './../reducers/WorksheetsLogic'
import { FormLabel } from '@material-ui/core'
export const SAGA_REFRESH_WORKSHEETS = 'SAGA_REFRESH_WORKSHEETS'
const getWorksheets = (state)=> {
  const worksheets = state.worksheets || {}
  const _persist = state._persist || {}
  return {
    worksheets: worksheets.worksheets,
    worksheet: worksheets.worksheet,
    rehydrated: _persist.rehydrated,
  }
}

const _checkBad = (s) => {
  return !s.rehydrated || s.worksheets === null || s.worksheets === undefined || Object.keys(s.worksheets).length < 1
}

function* sagaSelectWorksheet(action) {
  //are we rehyrated? 
  for(let i = 0; i < 5; i++) {
    const status = yield select( getWorksheets )

    if(_checkBad(status)) {
      console.log('SAGA_WORKSHEET_REFRESH, invalid status')
      yield delay(500)
    } else {
      //We're good
      console.log(`WORKSHEET STATUS OK, SELECTING`)
      yield put( selectWorksheet(status.worksheet || 0) )
      if(action.onComplete) {
        try {
          action.onComplete()
        } catch (e) {
          console.log(e)
        }
      }
      return //we're done
    }
  }

  console.log('WORKSHEET TIMEOUT: RESETTING TO DEFAULT AND SELECTING 0')
  yield put( createDefaultWorksheets() )
  yield put( selectWorksheet(0) )
  alert('RESETTING TO DEFAULT, WORKSHEETS DID NOT REFRESH')
}

function* worksheetSaga() {
  yield takeLatest(SAGA_REFRESH_WORKSHEETS, sagaSelectWorksheet)
}

export default worksheetSaga