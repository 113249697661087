import React from 'react'
import Paper from '@material-ui/core/Paper'
const paperStyle = {
  padding: '16px',
  margin: '20px 0 0 20px',
  minWidth: 300,
  flexDirection: 'column',
  display: 'flex',
}

const Card = props => {
  const {children, style, ...rest} = props
  return (
    <Paper
      style={{
        ...paperStyle,
        ...style,
      }}
    >
      { children }
    </Paper>
  )
}

export default Card