import {
  storeScreenShot
} from './ElectronLogic'

import {
  reportError,
} from './ErrorLogic'

// this also works with react-router-native

export const SHOW_DIALOG = 'CORE_SHOW_DIALOG'
export const SHOW_THREE_OPTIONS = 'CORE_SHOW_THREE_OPTIONS'
export const SHOW_ERROR = 'CORE_SHOW_ERROR'

export const SHOW_SNACK = 'CORE_SHOW_SNACK'
export const DISMISS_SNACK = 'CORE_DISMISS_SNACK'
export const DISMISS_ERROR = 'CORE_DISMISS_ERROR'
export const CLOSE_DIALOG = 'CORE_CLOSE_DIALOG'
export const PRIVACY_CHANGE = 'PRIVACY_CHANGE'

export const showDialog = (title, html, onOk, okText)=>{
  return dispatch => {
    dispatch({ 
      type: SHOW_DIALOG, 
      title: title,
      html: html,
      confirm: okText || 'OK',
      confirmAction: onOk,
      cancel: null,
      cancelText: null
    })
  }
}

export const showConfirm = (title, html, ok, cancel, okText, cancelText)=>{
  return dispatch => {
    dispatch({ 
      type: SHOW_DIALOG, 
      title: title,
      html: html,
      confirm: okText || 'OK',
      cancel: cancelText || 'Cancel',
      confirmAction: ok,
      cancelAction: cancel,
    })
  }
}

export const showThreeAction = (title, html, ok, option, cancel, okText, optionText, cancelText, colorOk, colorOption, colorCancel) => {
  return dispatch => {
    dispatch({ 
      type: SHOW_DIALOG, 
      title: title,
      html: html,
      confirm: okText || 'OK',
      cancel: cancelText || 'Cancel',
      confirmAction: ok,
      cancelAction: cancel,
      option: option,
      optionText: optionText || 'Option',
      colorOk,
      colorOption,
      colorCancel,
    })
  }
}

export const reportErrorMessage = (error, hint) => {
  storeScreenShot()
  return dispatch => dispatch( reportError(error, hint) )
}

export const changePrivacy = (val)=>{
  return dispatch => {
    dispatch({ 
      type: PRIVACY_CHANGE,
      value: !!val
    })
  }
}

export const closeDialog = ()=>{
  return dispatch => {
    dispatch({ type: CLOSE_DIALOG })
  }
}

export const showWarning = (title, message)=>{
  return dispatch => { 
    dispatch({
      type: SHOW_ERROR,
      title: title || 'Unknown',
      message: message || ''
    })
  }
}

export const showError = (title, message, err)=>{
  return dispatch => { 
    dispatch({
      type: SHOW_ERROR,
      error: err || new Error(message),
      title: title || 'Unknown',
      message: message || ''
    })
  }
}

export const dismissError = ()=>{
  return dispatch => {
    dispatch( {
      type: DISMISS_ERROR
    } )
  }
}

export const showQuickSnack = (message, time)=>{
  return dispatch => { 
    dispatch({
      type: SHOW_SNACK,
      message: message,
      time: time || 1500
    })
  }  
}

export const showSnack = (message, actionName, action)=>{
  console.log(`SHOW_SNACK: ${message}`)
  return dispatch => { 
    dispatch({
      type: SHOW_SNACK,
      message: message,
      action: action,
      actionName: actionName
    })
  }
}

export const dismissSnack = ()=>{
  return dispatch => {
    dispatch( {
      type: DISMISS_SNACK
    } )
  }
}

const INITIAL_STATE = {
    show: false,
    title: '',//NO DEBUG NEEDED'_no_title',
    html: '', //NO DEBUG NEEDED'<p><b>Bold</b> HTML TEST</p>',
    buttonOk : 'OK',
    buttonCancel: null,
    buttonOption: null,
    onConfirm : null,
    onCancel: null,
    onOption: null,
    privacy: false,
    error: null,
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
  case 'TEST_ERROR': {
    throw new Error(action.message || 'No Test Message')
  }
  case SHOW_DIALOG: {
    
    const { 
      title, 
      html, 
      confirm, 
      confirmAction, 
      cancel, 
      cancelAction, 
      optionText, 
      option,

    } = action
    return Object.assign({}, state, {
      show: true,
      title: title || INITIAL_STATE.title,
      html : html || INITIAL_STATE.html,
      buttonOk: confirm,
      buttonCancel: cancel,
      buttonOption: optionText,
      onConfirm: confirmAction,
      onCancel: cancelAction,
      onOption: option,
    })
  }

  case PRIVACY_CHANGE: {
    return Object.assign({}, state, {
      privacy: action.value
    })
  }

  case SHOW_ERROR: {
    const {title, message, error } = action
    const ui = {
      title: title || 'Unknown',
      message: message || 'Unknown',
      error: error,
      open: true
    }
    return Object.assign({}, state, {
      error: ui 
    })
  }

  case SHOW_SNACK: {
    const { message, type, actionName, time } = action

    return Object.assign({}, state, {
      snack: {
        open: true,
        type: type,
        time: time, 
        message: message,
        action: action.action,
        actionName: actionName
      }
    })
  }

  case DISMISS_SNACK: {
    return { 
      ...state, 
      snack: null
    }
  }

  case DISMISS_ERROR: {
    return Object.assign( {}, state, {
      error: null
    } )
  }

  case CLOSE_DIALOG:
    return {...INITIAL_STATE}

  default: 
    return state
  }
}