import pouch from './../pouch'
import uuidv1 from 'uuid/v1'
import Errors from './../Errors'
import { 
	showConfirm,
	showDialog
} from './InterfaceLogic'

import Logger from './../../shared/WebLog'

import { 
	makeUndo,
	pushUndoSet,
	pushUndo,
	diffKeys,
} from './UndoLogic'

import { formatUnicorn, ifNullThen, equals } from './../../shared/StringUtil'

import { TranslatorFromState } from './../../shared/language/Translate'
import {
	notes as NOTES,
	log as LOG,
	cueSheet as CUE_SHEET,
} from './../../shared/language/'
import {
	showNoteDialog
} from './NotesLogic'
import { isNull, num } from '../../shared/Utility'

export const ADD_CUE = 'CUE_ADD_CUE'
export const ADD_CUES = 'CUES_ADD_CUES'
export const DELETE_CUE = 'CUE_DELETE_CUE'
export const PURGE_DELETED = 'CUE_PURGE_DELETED'
export const REPLACE_CUE = 'CUE_REPLACE_CUE'
export const LOAD_CUES = 'CUE_LOAD_CUES'
export const RESET = 'CUE_RESET'
export const MOVE_TO = 'CUE_MOVE_TO'
export const UPDATE_UI = 'CUE_UPDATE_UI'
export const FIND_CHARACTER_NAMES = 'CUE_FIND_CHARACTER_NAMES'

//We need to clean this up and remove it T:1
export const NOTE_CUE = 'CUE_NOTE_CUE'
export const NOTE_DISMISS = 'CUE_NOTE_DISMISS'

export const NOTE_TYPES = {
				GENERAL: 'typeGeneral',
        CUE: 'typeCue',
        FOCUS: 'typeFocus',
        WORK: 'typeWork',
        PRIVATE: 'typePrivate',
}
//END T1

export const MERGE_BLACK_LIST = ['_id', '_rev', 'next']
export const MERGE_PLACEMENT_WHITE_LIST = ['next']
export const CUES_UNDO = 'cues'
const log = Logger('en', 'CueSheet')

export const filterOnlyActive = (cues) => {
	if(!cues || cues.length < 1) {
		return cues
	}

	let out = []
	for(let i = 0; i < cues.length; i++) {
		let q = cues[i]
		if(!q.deleted){
			out.push(q)
		}
	}

	return out
}

/**
 * Returns an array of strings of known names. 
 * @param {*} cues 
 */
export const updateCharacterNamesForSpots = (cues) => {
	return (dispatch, getState) => {
		let cues = getState().cues.cues || []

		let names = {
			'OUT': 'OUT'
		}
		for( let q of cues) {
			if(q.spots) {
				for(let s of q.spots) {
					if(s && !isNull(s.pickup) && s.pickup != '') {
						names[s.pickup] = s.pickup
					}
				}
			}
		}
	
		let out = Object.keys(names)
		out.sort()
		dispatch({
			type: FIND_CHARACTER_NAMES,
			values: out
		})
	}
	
}
/**
 * Takes the values from a cue and tries to provide a decent description even if the cue
 * has bad data.
 * @param {Cue} cue 
 */
export const utilSmartLabelCue = (cue) => {
	const def = cue || {}
	return def.number || def.label || def.description || '-'
}

/**
 * Find the cue index based on the ID
 * @param {*} id the id to lookup
 * @param {*} state the full application state
 * 
 * @returns -1 if not found, else the index.
 */
export const utilFindCueIndex = (id, state) => {
	const cues = state.cues.cues || []
	return cues.findIndex(x => x._id == id)
}

/**
 * Find the first cue that isn't deleted and return it. This skips deleted cues
 * as they probably don't count.
 * @param {String} num 
 * @param {ApplicationState} state 
 */
export const utilFindCueNumber = (num, state) => {
	let x = []
	const cues = (state.cues.cues || []).filter(c => !c.deleted)
	let first = cues.findIndex(c => c.number = num)

	return cues[first]
}

 /**
	* Returns a copy of the cue down into the spot light structure
 	* @param {*} cue
 	*/
export const utilCopyCue = (cue) => {
	if(!cue) {
		return {}
	}

	let spots = null
	if(cue.spots) {
		spots = []
		for(let i = 0; i < cue.spots.length; i++) {
			spots[i] = {
				...cue.spots[i], //clone
			}
		}
	}

	let notes = null
	if(cue.notes) {
		notes = []
		for(let i = 0; i < cue.notes.length; i++) {
			notes[i] = {
				...cue.notes[i], //clone
			}
		}
	}

	return {
		...cue,
		spots,
		notes,
	}
}

export const updateUi = (settings)=>{
	log.info('dispatching UPDATEUI')
	return dispatch => dispatch({
		type: UPDATE_UI,
		settings: settings
	})
}

/**
 * Set the osc tracking on or off. Defaults to false on null.
 * @param {Boolean} on 
 * @returns 
 */
export const cuesTrackOsc = (on) => {
	return dispatch => {
		dispatch( updateUi({trackOsc: !!on}) )
	}
}

/*
Load the cues and fire a callback if needed.

FIXME: this method should be removed, we don't need to load anymore as we're going to store this all in the state.

NOTE 2019-12-07 : We're ignoring the linked list here. We're done with that and just 
using an array 
*/
export const loadCues = (callback)=>{
	return (dispatch, getState) => {
		const state = getState()
		const cues = state.cues.cues || []

		if(callback) {
			callback(cues)
		}

		dispatch({
			type: LOAD_CUES,
			cues: cues
		})
	}
}

//Marked for removal
export const loadCuesOLD = (callback)=>{
	return dispatch => {
		pouch.db()
			.find({
				selector: { 
					type: 'cue',
				}
			}).then(result=>{
				if(result.docs.length <= 1){
					dispatch({
						type: LOAD_CUES,
						cues: result.docs
					});
					return;
				}

				const documents = [];
				const idMap = {};
				const nextMap = {};
				let last = null;
				const docs = result.docs;

				docs.forEach( (q, i) => {
					if(!q.next){
						if(last){
							alert('duplicate last, there is an orphan! High Priority Debug Event at index' + i);
						}
						
						last = q;
					}

					idMap[q._id] = q;
					nextMap[q.next] = q;
				});


				if(!last){
					alert('There was no tail to this list. Attempting to clean (costly)');
					//Use n^2 algorithm here
				} else {
					//Use lookups
					const popBack = (el)=>{
						documents.unshift(el); //Add to front
						const previous = nextMap[el._id];
						if(previous){
							popBack(previous);
						}
					};

					popBack(last);
				}

				if(callback){
					callback(documents);
				}
				
				dispatch({
					type: LOAD_CUES,
					cues: documents
				});
				
			}).catch(error => {
				dispatch(Errors.reactError('Unable to load cues', error));
			});
	};
};

/* Replace a cue with the current cue, we'll use an ID for this */
export const replaceCue = (cue) => {
	return (dispatch, getState) => {
		if(!cue || !cue._id){
			dispatch(Errors.reactError('Cue Replacement Failed', 'The value is null or has no id'));
			console.log(cue)
			console.log('\t^^^DEBUG')
			throw new Error('CueLogic::replaceCue The value is null or has no id')
		}

		dispatch({
			type: REPLACE_CUE,
			cue: utilCopyCue(cue)
		})
	}
}

export const replaceCueOLD = (cue)=>{
	return dispatch => {
		if(!cue || !cue._id){
			dispatch(Errors.reactError('Cue Replacement Failed', 'The value is null or has no id'));
			console.log(cue)
			console.log('\t^^^DEBUG')
			throw new Error('CueLogic::replaceCue The value is null or has no id')
		}

		pouch.db().put(cue).then( response=> {
			if(response.ok){
				dispatch({
					type: REPLACE_CUE,
					cue: Object.assign({}, cue, { _rev : response.rev } )
				})
			} else {
				throw new Error('REVISION CONFLICT DB: "' + response.rev + '" | Cue:"' + cue._rev + '"')
			}

			}).catch( error=>{
					return dispatch(Errors.reactError('Cue Replacement Failed', error));
			})
		//We need to hit the DB here in a moment.

	}
}

/**
 * Merge this cue object into the stack ignoring current revision data
 * by pulling the record from the database.
 * @param {Cue} cue the cue to merge
 * @param {String} undoDescription the description for the undo operation
 * @param {String} redoDescription the description for the redo operation
 * @param {Boolean} allowUndo whether to push the redo/undo option (default true)
 * @returns a `thunk` function using `dispatch`
 */
export const mergeCueData = (cue, undoDescription, redoDescription, allowUndo = true) => {
	return (dispatch, getState) => {
		const state = getState()
		if(!cue || !cue._id){
			dispatch(Errors.reactError('Cue Replacement Failed', 'The value is null or has no id'))
			return 
		}

		const index = utilFindCueIndex(cue._id, state)
		const oldCue = state.cues.cues[index]
		if(index > -1) {

			if(!undoDescription) {
				undoDescription = 'Unknown undo operation for cue:${number}'
			}
		
			if(!redoDescription) {
				redoDescription = 'Unknown redo operation for cue:${number}'
			}

			undoDescription = formatUnicorn(undoDescription, oldCue)
			redoDescription = formatUnicorn(redoDescription, cue)

			let update = {  //merge using the new data
				...oldCue,
				...cue,
				_rev: Date.now().toString(),
			}

			let restore = {
				...oldCue
			}
//NOTE: We're skipping the next flag etc... as we're dumping the linked list...
			const onUndo = () => {
				//Replace with restore
				dispatch( {
					type: REPLACE_CUE,
					cue: {
						...restore
					}
				})
			}
	
			const onRedo = () => {
				//Replace with update
				dispatch( {
					type: REPLACE_CUE,
					cue: {
						...update
					}
				})
			}

			//FIRE REDO TO MAKE THIS HAPPEN
			if(allowUndo) {
				const undoObj = makeUndo(onUndo, onRedo, undoDescription, redoDescription)
				dispatch( pushUndo(CUES_UNDO, undoObj) ) //push to the stack
			}
			onRedo()
			dispatch( updateCharacterNamesForSpots() )
		}
	}
}
/**
 * OLD METHOD, marked for deletion
 * Merge this cue object into the stack ignoring current revision data
 * by pulling the record from the database.
 * @param {Cue} cue the cue to merge
 * @param {String} undoDescription the description for the undo operation
 * @param {String} redoDescription the description for the redo operation
 * @param {Boolean} allowUndo whether to push the redo/undo option (default true)
 * @returns a `thunk` function using `dispatch`
 */
export const mergeCueDataOLD = (cue, undoDescription, redoDescription, allowUndo = true) => {
	return dispatch => {
		if(!cue || !cue._id){
			dispatch(Errors.reactError('Cue Replacement Failed', 'The value is null or has no id'))
			return 
		}

		pouch.db().get(cue._id).then( document => {

			if(!undoDescription) {
				undoDescription = 'Unknown undo operation for cue:${number}'
			}
		
			if(!redoDescription) {
				redoDescription = 'Unknown redo operation for cue:${number}'
			}

			undoDescription = formatUnicorn(undoDescription, document)
			redoDescription = formatUnicorn(redoDescription, cue)

			let update = {  //merge using the new data
				...document,
				...cue 
			}

			let restore = {
				...document
			}

			const onUndo = () => {
				pouch.db().get(cue._id).then( undoDoc => {
					let _doc = {
						...restore
					}

					for(let invalid of MERGE_BLACK_LIST) {
						delete _doc[invalid] //clear invalids
						_doc[invalid] = undoDoc[invalid] //pull from the db if it exists
					}

					pouch.db().put(_doc).then( response => {
						if(response.ok) {
							dispatch({
								type: REPLACE_CUE,
								cue: {
									..._doc,
									_rev: response.rev,
								}
							})	
						}
					}).catch( error => {
						return dispatch(Errors.reactError('Cue Undo Failed (db::put)', error))
					})
				}).catch( error => {
					return dispatch(Errors.reactError('Cue Undo Failed (db::get)', error))
				})
			}
	
			const onRedo = () => {
				pouch.db().get(cue._id).then( redoDoc => {
					let _doc = {
						...update
					}

					for(let invalid of MERGE_BLACK_LIST) {
						delete _doc[invalid] //clear invalids
						_doc[invalid] = redoDoc[invalid] //pull from the db if it exists
					}

					pouch.db().put(_doc).then( response => {
						if(response.ok) {
							dispatch({
								type: REPLACE_CUE,
								cue: {
									..._doc,
									_rev: response.rev,
								}
							})	
						} else {
							return dispatch(Errors.reactError('Cue Redo RESPONSE IS INVALID', response))	
						}
					}).catch( error => {
						return dispatch(Errors.reactError('Cue Redo Merge Failed (db::put)', error))
					})
				}).catch( error => {
					return dispatch(Errors.reactError('Cue Redo Failed (db::get)', error))
				})
			}

			//FIRE REDO TO MAKE THIS HAPPEN
			if(allowUndo) {
				const undoObj = makeUndo(onUndo, onRedo, undoDescription, redoDescription)
				dispatch( pushUndo(CUES_UNDO, undoObj) ) //push to the stack
			}
			onRedo()
			
		//Catch master "GET" request, this should never fail	
		}).catch ( error => {
			return dispatch(Errors.reactError('Cue Merge Failed (Initial Retreival) (db::get)', error))
		})
	}
}

/**
 * Merge this cue object into the stack ignoring all data and only
 * affecting the placement of the cue in the list.
 * @param {*} cue 
 * @returns a `thunk` function using `dispatch`
 */
export const mergeCuePlacement = (cue, callback) => {
	console.log('UNUSED METHOD, we do not track next now')
	return
}

/**
 * OLD METHOD, this is marked for deletion
 * Merge this cue object into the stack ignoring all data and only
 * affecting the placement of the cue in the list.
 * @param {*} cue 
 * @returns a `thunk` function using `dispatch`
 */
export const mergeCuePlacementOLD = (cue, callback) => {
	return dispatch => {
		if(!cue || !cue._id){
			return dispatch(Errors.reactError('mergeCuePlacement', 'The value is null or has no id'))
		}
		cue = {
			...cue
		}
		pouch.db().get(cue._id).then( doc => {
			let _doc = {
				...doc,
				next: cue.next
			}
			
			pouch.db().put(_doc).then( response => {
				if(response.ok) {
					dispatch({
						type: REPLACE_CUE,
						cue: {
							..._doc,
							_rev: response.rev,
						}
					})	

					if(callback) {
						callback()
					}
				} else {
					return dispatch(Errors.reactError('CueLogic::mergeCuePlacement RESPONSE IS INVALID', response))	
				}
			}).catch( error => {
				if( equals(cue, _doc)) {
					//we're the same, no harm, just log it no error
					log.info('CueLogic::mergeCuePlacement [IDENTICAL] Cue Merge Failed (db::put)', error)
				} else {
					console.log('[BASE]', cue)
					console.log('[COPY]', _doc)
					console.log('\t^^^^ cue / doc')
					dispatch(Errors.reactError('CueLogic::mergeCuePlacement Cue Merge Failed (db::put)', error))
					throw error
				}
			})			
		}).catch(error=>{
			return dispatch(Errors.reactError('CueLogic::mergeCuePlacement Cue Replacement Failed', error));
		})
	}
}

export const appendCues = (count, select = false) => {
	const _count = num(count, 0)
	if(_count < 1 || _count > 100) {
		return Errors.reactError(
			'Unable to add Cue', 
			`You must specify at least 1 cue to add and no more than 100, the object 
			passed was "${count}".`
		);
	}

	return (dispatch, getState) => {
		const state = getState()
		const cues = state.cues.cues
		//Recursive call if we're not loaded
		//FIXME -> Delete this loadCues() call, it should be implicit now.
		if(!getState().cues.loaded){ 
			dispatch( loadCues() )
			return Errors.reactError(
				'Unable to add Cue', 
				`You must specify at least 1 cue to add and no more than 100, the object 
				passed was "${count}".`
			);
		}

		let onComplete = null
		if(select) {
			onComplete = () => {
				let el = document.getElementById('select-last-cue')
				if(el) {
					el.click()
				}
			}
		}
		dispatch({
			type: ADD_CUES,
			number: _count,
			onComplete
		})
	}
}
/**
 * Adds a new cue after the specific cue, or as the first cue if specified
 * @param {Cue} cue the cue to add
 * @param {Cue} after the cue to add this cue after
 * @param {Boolean} first is this the first cue?
 */
//FIXME add undo logic, I dont' like the original logic. It should actually remove from the list on undo rather than be marked deleted...
export const addCue = (cue, after, first)=>{
	if(!cue){
		return Errors.reactError(
			'Unable to add Cue', 
			`You must specify a cue to add, the object 
			passed was null or undefined.`);
	}

	cue.type = 'cue'
	return (dispatch, getState) => {
		if(!cue._id){
			cue._id = uuidv1()
			cue._rev = Date.now().toString()
		}
		const state = getState()
		const cues = state.cues.cues
		//Recursive call if we're not loaded
		//FIXME -> Delete this loadCues() call, it should be implicit now.
		if(!getState().cues.loaded){ 
			dispatch( loadCues() )
			dispatch( addCue(cue, after) )
			return
		}

		if(first) {
			dispatch({
				type: ADD_CUE,
				cue: cue,
				hintIndex: -1
			})
			return
		}
		const _after = after || {}
		const afterIndex = after ? utilFindCueIndex(after._id, state) : -1
		if(afterIndex == -1) { //push to end...
			console.log(`CueLogic::addCue Warning, cue ${_after._id} or ${_after.number} not found, pushing to the end of the list. Potential bug...`)
		}
		if(afterIndex < 0) {
			dispatch({
				type: ADD_CUE,
				cue: cue,
				hintIndex: -2, //end
			})
		} else {
			dispatch({
				type: ADD_CUE,
				cue: cue,
				hintIndex: afterIndex,
			})
		}

		return
		//Old logic for reference, stop before this
		if(first){ //push to the front
			hintIndex = -1;
			cueAfter = cues[0];
			if(cueAfter){
				cue.next = cueAfter._id;
			}
		} else if (!after || !after.next){ //Send this to the end
			hintIndex = cues.length - 1;
			cueBefore = cues[cues.length -1];
			cueAfter = null;

			if(cueBefore){
				cueBefore.next = cue._id;
			}

		} else {
			hintIndex = cues.findIndex(q => q._id == after._id);
			if(hintIndex < 0) {
				hintIndex = cues.length - 1;
				cueBefore = cues[hintIndex];
				cueBefore.next = cue._id;
			} else {
				cueBefore = cues[hintIndex];
				cueBefore.next = cue._id;
				cueAfter = cues[hintIndex + 1];
				if(cueAfter){
					cue.next = cueAfter._id;
				}
			}
		}

		pouch.db()
			.put(cue)
			.then(res => {
				if(res.ok){
					cue._id = res.id;
					cue._rev = res.rev;

					if(cueBefore){
						dispatch( replaceCue(cueBefore) );
					}
					if(cueAfter) {
						dispatch( replaceCue(cueAfter) );
					}
					
					const T = TranslatorFromState(getState, 'CueLogic')
					let description = T.get('addCue')
					if(cueBefore) {
						description = T.get('addCueAfter', cueBefore)
					}
					
					const onRedo = () => { //delete
						dispatch( mergeCueData({...cue, deleted: false }, description, description, false) )
					}

					const onUndo = () => { //undelete
						dispatch( mergeCueData({...cue, deleted: true }, description, description, false) )
					}

					dispatch({
						type: ADD_CUE,
						cue: cue,
						hintIndex: hintIndex
					})
					const undo = makeUndo(onUndo, onRedo, description, description)

					dispatch( pushUndo( CUES_UNDO, undo ) )
				} else {
					dispatch(Errors.reactError('CRITICAL ERROR', JSON.stringify(res, null, 2)));
				}
			})
			.catch(err => dispatch(Errors.reactError('Unable to save cue', err)));
	}
}

export const addCueOLD = (cue, after, first)=>{
	if(!cue){
		return Errors.reactError(
			'Unable to add Cue', 
			`You must specify a cue to add, the object 
			passed was null or undefined.`);
	}

	cue.type = 'cue';
	return (dispatch, getState) => {
		if(!cue._id){
			cue._id = Date.now().toString();
		}

		const cues = getState().cues.cues
		//Recursive call if we're not loaded
		if(!getState().cues.loaded){ 
			dispatch( loadCues() )
			dispatch( addCue(cue, after) )
			return
		}

		let hintIndex = -1;
		let cueBefore, cueAfter;

		if(first){ //push to the front
			hintIndex = -1;
			cueAfter = cues[0];
			if(cueAfter){
				cue.next = cueAfter._id;
			}
		} else if (!after || !after.next){ //Send this to the end
			hintIndex = cues.length - 1;
			cueBefore = cues[cues.length -1];
			cueAfter = null;

			if(cueBefore){
				cueBefore.next = cue._id;
			}

		} else {
			hintIndex = cues.findIndex(q => q._id == after._id);
			if(hintIndex < 0) {
				hintIndex = cues.length - 1;
				cueBefore = cues[hintIndex];
				cueBefore.next = cue._id;
			} else {
				cueBefore = cues[hintIndex];
				cueBefore.next = cue._id;
				cueAfter = cues[hintIndex + 1];
				if(cueAfter){
					cue.next = cueAfter._id;
				}
			}
		}

		pouch.db()
			.put(cue)
			.then(res => {
				if(res.ok){
					cue._id = res.id;
					cue._rev = res.rev;

					if(cueBefore){
						dispatch( replaceCue(cueBefore) );
					}
					if(cueAfter) {
						dispatch( replaceCue(cueAfter) );
					}
					
					const T = TranslatorFromState(getState, 'CueLogic')
					let description = T.get('addCue')
					if(cueBefore) {
						description = T.get('addCueAfter', cueBefore)
					}
					
					const onRedo = () => { //delete
						dispatch( mergeCueData({...cue, deleted: false }, description, description, false) )
					}

					const onUndo = () => { //undelete
						dispatch( mergeCueData({...cue, deleted: true }, description, description, false) )
					}

					dispatch({
						type: ADD_CUE,
						cue: cue,
						hintIndex: hintIndex
					})
					const undo = makeUndo(onUndo, onRedo, description, description)

					dispatch( pushUndo( CUES_UNDO, undo ) )
				} else {
					dispatch(Errors.reactError('CRITICAL ERROR', JSON.stringify(res, null, 2)));
				}
			})
			.catch(err => dispatch(Errors.reactError('Unable to save cue', err)));
	}
}

/**
 * Reorder the list of cues placing the cue (after) the cue (before). 
 * @param {*} cue The cue to move
 * @param {*} targetCueID The cue ID to place this after, if it is null this cue will become the first element
 * @param {*} applyUndo apply the undo event (allows reusing this function inside an undo)
 */
export const moveAfterCue = (cue, targetCueID, applyUndo = true) => {
	
	return (dispatch, getState) => {
		const T = TranslatorFromState(getState, 'CueLogic')
		const state = getState()
		const cues = state.cues.cues || []
		const currentIndex = utilFindCueIndex(cue._id, state)
		const restoreCueBeforeSourceCueID = (cues[currentIndex - 1] || {})._id
		const afterIndex = utilFindCueIndex(targetCueID, state)
		const after = cues[afterIndex] || {}

		if(targetCueID == cue._id) {
			console.log('CIRCULAR REFERENCE DETECTED->You can not move a cue after itself...')
			//dispatch( Errors.reactError('CIRCULAR REFERENCE DETECTED', 'You can not move a cue after itself...') )
			return
		}


		//This is the actual method
		const onRedo = () => {
			const state = getState()
			const newTargetIndex = !!targetCueID ? utilFindCueIndex(targetCueID, state) : -1
			
			dispatch({
				type: MOVE_TO,
				cue: cue,
				afterIndex: newTargetIndex,
			})
		}

		const onUndo = () => {
			//restore the original placement
			dispatch( moveAfterCue(cue, restoreCueBeforeSourceCueID, false) )
		}

		if(applyUndo) {
			let description = T.get('moveGeneric')
			description = T.get('moveAfter', utilSmartLabelCue(cue), utilSmartLabelCue(after) )	

			const undo = makeUndo(onUndo, onRedo, description, description)
			dispatch( pushUndo(CUES_UNDO, undo) )
		}

		onRedo()
	}
}

/**
 * MARKED FOR DELETE...
 * Reorder the list of cues placing the cue (after) the cue (before). 
 * @param {*} before The cue to place this cue after <b>CAN BE NULL</b>
 * @param {*} after The cue to place after the cue `before` <b>CAN BE NULL</b>
 * @param {*} applyUndo apply the undo event (allows reusing this function inside an undo)
 */
export const moveAfterOLD = (_before, _after, applyUndo = true) => {
	
	return (dispatch, getState) => {
		const T = TranslatorFromState(getState, 'CueLogic')

		//Check for circular references
		if(_before && _after) {
			if(_before._id == _after._id) {

				//Circular reference checks
				if(_before.next == _after._id || _before._id == _after.next) {
					dispatch( Errors.reactError('CIRCULAR REFERENCE DETECTED', '[NOT IMPLEMENTED] The records have a circular reference (your file is corrupted) the application will attempt to repair this by placing these records at the end of tte list.') )
				} else {
					log.error('Records are the same, aborting function')
					return
				}
			}
		}

		const cues = getState().cues.cues
		const beforeId = _before ? _before._id : null
		const afterId = _after ? _after._id : null

		const iBefore = cues.findIndex(q => q._id === beforeId)
		const iAfter = cues.findIndex(q => q._id === afterId)

		//We might want to do some error checks
		const beforeBefore = cues[iBefore - 1]
		const before = cues[iBefore]
		const beforeAfter = cues[iBefore + 1]

		const afterBefore = cues[iAfter - 1]
		const after = cues[iAfter]
		const afterAfter = cues[iAfter + 1]


		let description = T.get('moveGeneric')
		if(after && before) {
			description = T.get('moveAfter', ifNullThen(after.number, '-'), ifNullThen(before.number, '-'))	
		} else if(after) { //theoretically this doesn't happen...
			description = T.get('moveAfter', ifNullThen(after.number, '-'), T.get('last'))	
		} else if (before) {
			description = T.get('moveAfter', ifNullThen(before.number, '-'), T.get('first'))	
		}

		const onUndo = () => {
			//restore the original placement
			dispatch( moveAfter(afterBefore, after, false) )
		}
		const onRedo = () => {
			const cues = getState().cues.cues

			const iBefore = cues.findIndex(q => q._id === beforeId)
			const iAfter = cues.findIndex(q => q._id === afterId)

			if(!cues) {
				throw new Error('CueLogic::moveAfterYou must have established a state for the swap cues action to fire')
			}

			if(!_before) { //Move to front of list
				const first = cues[0]
				if(!first) {
					log.error('This list has no head', after)
					return 
				}
				const u_afterBefore = {
					...afterBefore
				}

				const u_after = {
					...after
				}

				if(!u_afterBefore || !u_afterBefore._id) {
					log.error('Trying to move item from the front of the list to the front of the list.', u_after, u_afterBefore)
					return 
				}
				
				u_afterBefore.next = u_after.next
				u_after.next = first._id
				dispatch( mergeCuePlacement(u_afterBefore) )
				dispatch( mergeCuePlacement(u_after) )

				dispatch({
					type: MOVE_TO,
					after: u_after,
					beforeIndex: ifNullThen(iBefore, -1),
					afterIndex: iAfter
				})


			} else if (!_after) { //Inversion of move to front of list
				console.log('inversion of before')
				return
			} else { //standard operation

				if(before.next == after._id) {
					log.error('CueLogic:moveAfter::onRedo We are already in sequence, before.next = after._id')
					return
				}
				const u_after = {
					...after
				}

				const u_before = {
					...before
				}

				const u_afterBefore = {
					...afterBefore
				}

				if(afterBefore) {
					u_afterBefore.next = after.next
				}

				u_after.next = before.next
				u_before.next = u_after._id

				if(afterBefore) {
					dispatch( mergeCuePlacement(u_afterBefore) )
				}
				
				dispatch( mergeCuePlacement(u_after) )
				dispatch( mergeCuePlacement(u_before) )

				dispatch({
					type: MOVE_TO,
					after: u_after,
					beforeIndex: iBefore,
					afterIndex: iAfter
				})
			}
		}
		
		if(applyUndo) {
			const undo = makeUndo(onUndo, onRedo, description, description)
			dispatch( pushUndo(CUES_UNDO, undo) )
		}

		onRedo() //execute teh function
	}
}

export const undeleteCue = (cue) => {
	return (dispatch, getState) => {
		const T = TranslatorFromState(getState, 'CueLogic')

		if(!cue || !cue._id){
			return dispatch(Errors.reactError('Cue deletion Failed', 'The value is null or has no id'));
		}

		const labelArgs = {
			number: ifNullThen(cue.number, '???')
		}

		const description = T.get('restoreCue', labelArgs)
		
		const toRestore = Object.assign({}, cue, { deleted: true})

		const onUndo = () => {
			dispatch( mergeCueData({...cue, deleted: true }, description, description, false) )
		}

		const onRedo = () => {
			dispatch( mergeCueData({...cue, deleted: false }, description, description, false) )
		}
		
		const undo = makeUndo(onUndo, onRedo, description, description)
		dispatch( pushUndo(CUES_UNDO, undo) )
		onRedo()
	}
}

export const deleteCue = (cue)=>{
	return (dispatch, getState) => {
		const T = TranslatorFromState(getState, 'CueLogic')
		if(!cue || !cue._id){
			return dispatch(Errors.reactError('Cue deletion Failed', 'The value is null or has no id'));
		}

		const labelArgs = {
			number: utilSmartLabelCue(cue)
		}

		const description = T.get('deleteCue', labelArgs)

		const onUndo = () => {
			dispatch( mergeCueData({...cue, deleted: false }, description, description, false) )
		}

		const onRedo = () => {
			dispatch( mergeCueData({...cue, deleted: true }, description, description, false) )
		}
		
		const undo = makeUndo(onUndo, onRedo, description, description)
		dispatch( pushUndo(CUES_UNDO, undo) )
		onRedo()
	}
}

export const downloadCusTsv = () => {
	return (dispatch, getState) => {
		if(!getState().cues || !getState().cues.cues){
			alert('please load the cues first--this is a hack');
			return;
		}

		const cues = getState().cues.cues;
		let tsv = [];
		tsv.push('Index\tCue\tPage\tLabel\tDescription\tSpot 1\tSpot 2\tSpot 3\tSpot4\tSpot 5\tSpot 6\tSpot 7\tSpot 8');
		tsv.push('\n');

		cues.forEach((q, i)=>{

			if(q.header && q.headerText){
				tsv.push(q.headerText);
				tsv.push('\n');
			}

			tsv.push(i);
			tsv.push('\t');
			tsv.push(q.number);
			tsv.push('\t');
			tsv.push(q.page);
			tsv.push('\t');
			tsv.push(q.label);
			tsv.push('\t');
			tsv.push(q.description);
			tsv.push('\t');
			if(q.spots){
				for(let i = 0; i < 8; i++) {
					if(q.spots[i]) {
						tsv.push(q.spots[i].pickup);
					} 
					
					tsv.push('\t')
				}
			} else {
				tsv.push('\t\t\t\t\t\t\t\t');
			}

			tsv.push('\n');
		});

		const str = 'data:text/plain;charset=utf-8,' + encodeURI( tsv.join('') );
		const a = document.createElement('a');
		a.href = str;
		a.download = 'cues.tsv';
		a.innerHTML = 'download TEMP';

		const container = document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
};

export const downloadCuesUSITT = () => {
	return (dispatch, getState) => {
		if(!getState().cues || !getState().cues.cues){
			alert('please load the cues first--this is a hack');
			return;
		}
/*
Ident 3:0

!EXPORT FROM EXCEL
Cue Cue
Text Page/M#/Beat | Label | Fade 
Up LX

Cue 0.9
Text Blackout Check
Up 4.9

Cue 1
Text House and Warmers
Up 4.9

Cue 2
Text Preset
Up 4.9

Cue 3
Text House to Half
Up 4.9

Cue 6
Text Maestro Bow
Up 4.9
*/
		const cues = getState().cues.cues;
		let tsv = [];
		tsv.push('Ident 3:0');
		tsv.push('');
		tsv.push('!EXPORT FROM Light Assistant');
		tsv.push('!http://lightassistant.com');
		tsv.push('');
		
		cues.forEach((q, i)=>{
			if(q.number){
				tsv.push('');
				tsv.push('Cue ' + q.number);
				let text = '';
				if(q.page && q.label){
					text += q.page + ' | ' + q.label;
				} else if(q.page){
					text += q.page;
				} else if (q.label) {
					text += q.label;
				} else {
					text = '';
				}

				tsv.push('Text ' + text);
				tsv.push('Up 4.9'); //default time
			}
		});

		const str = 'data:text/plain;charset=utf-8,' + encodeURI( tsv.join('\r\n') );
		const a = document.createElement('a');
		a.href = str;
		a.download = 'usitt-ascii.txt';
		a.innerHTML = 'download TEMP';

		const container = document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};
};

export const noteCue = (cue, props)=>{
	return (dispatch, getState) => {
		const state = getState()

		if(isNull(cue.number) || !state.cues.skipMemos) {
			dispatch({
				type: NOTE_CUE,
				cue: cue,
				props: props
			})
		} else {
			const data = {
				cue: cue.number,
			}
			dispatch( dismissNote())
			dispatch( showNoteDialog(data, false, NOTES.get('lighting')) )
		}

	}
}

export const dismissNote = ()=>{
	return dispatch => {
		dispatch({
			type: NOTE_DISMISS
		})
	}
}

export const cueReset = ()=>{
	return dispatch => {
		dispatch({ type: RESET });
	};
};


export const checkSequential = () => {
	return (dispatch, getState) => {
		let state = getState()
		let checkFn = (cueList) => {
			cueList = cueList.filter(q => !q.deleted)
			let last = -1
			let vars = {
				count: cueList.length,
				countText: CUE_SHEET.get('totalCues'),
				sequenceProblems: CUE_SHEET.get('sequenceProblems'),
				sequenceProblemsStart: CUE_SHEET.get('sequenceProblemsNone'),
			}

			//Process for broken sequence
			for(let i = 0; i < cueList.length; i++) {
				const cue = cueList[i]
				let number = Number(cue.number) || -1
				if (cue.number) {
					if (number < last) {
						vars.sequenceProblemsStart = CUE_SHEET.get('sequenceProblemsStart', Object.assign({}, {
							previous: last,
							problem: number
						}, cue) )
						break
					}

					last = number
				}
			}

			let htmlReport = formatUnicorn(
			`<div>
				<h4>{countText}</h4>
				<label>{count}</label>
				<h4>{sequenceProblems}</h4>
				<label>{sequenceProblemsStart}</label>
			</div>
			`, vars)
			dispatch( showDialog ( CUE_SHEET.get('cueSheetStatus'), htmlReport) )
		}

		if (!state.cues.loaded) {
			loadCues(checkFn) //load then check
		} else {
			checkFn(state.cues.cues) //check
		}
	}
}

export const purgeDeleted = () => {
	return (dispatch, getState) => {
		const allCues = getState().cues.cues || []

		const onDelete = () => {
			dispatch({
				type: PURGE_DELETED
			})
		}
		let deleted = allCues.filter(q => q.deleted)
		let deletedText = deleted.map(q => q.number || '?').join(', ')
		let htmlReport = 
			`<div>
				${CUE_SHEET.get('purgeDeletedBody', {
					COUNT: deleted.length,
					CUES: deletedText,
				})}
			</div>
			`
			dispatch( showConfirm(
				CUE_SHEET.get('purgeDeletedTitle'), 
				htmlReport, 
				onDelete, 
				null, 
				CUE_SHEET.get('purgeDeleted'), 
				null
			) )
	}
}

/**
 * Checks to see if the cue list is current and processes it if it is. If it isn't
 * it dispatches the load event and then processes the list when completed.
 * @param {*function(Array<Cues>)} callback to process the cues
 */
export const processCues = (callback) =>{
	return (dispatch, getState) => {
		const state = getState()
		if (state.cues && state.cues.loaded) {
			callback(state.cues.cues || [])
		} else {
			dispatch( loadCues(callback) )
		}
	}
}

export const INITIAL_STATE = {
	cues: [],
	loaded: false,
	autoSave: false,
	deletePrompt: true,
	dragEnabled: true,
	trackOsc: true,
	skipMemos: false,
	characters: [],
};

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {

	case RESET: 
      return INITIAL_STATE

	case ADD_CUE: {
			const cue = action.cue;
			const cues = state.cues.slice();
			const hintIndex = action.hintIndex;
			if(!cue){
				return state;
			}

			if(hintIndex == -2) { //push to the end...
				cues.push(cue)
			} else if (hintIndex == -1) {
				cues.unshift(cue)
			} else {
				cues.splice(hintIndex + 1, 0, cue) //add cue after the index...
			}
			// //Our list is organized node.next, splice is node.before
			//OLD logic, left for reference...
			// let index = hintIndex < 0 ? 0 : hintIndex + 1;
			// cues.splice(index, 0, cue);

			return {
				...state,
				cues: cues,
			}
	}

	case ADD_CUES: {
		const number = action.number
		const cues = state.cues.slice()
		const adds = []
		for(let i = 0; i < number; i++) {
			const newCue = {
				type: 'cue',
				_id: uuidv1(),
				_rev: Date.now().toString(),
			}

			adds[i] = newCue
		}
		if(action.onComplete) {
			setTimeout(action.onComplete, 50)
		}
		return {
			...state,
			cues: cues.concat(adds),
		}
	}
			
  case DELETE_CUE: {
		const { cue } = action;

		if(cue) {
			const index = state.cues.findIndex(x => x._id === cue._id);
			if(index < 0){
				return state; //the cue doesn't exist...
			}

			const cues = state.cues.slice();
			cues.splice(index, 1); 
			return Object.assign({}, state, {cues: cues});
		}
    return state;
	}

	case PURGE_DELETED: {
		if(!state.loaded) {
			console.warn('CUES ARE NOT LOADED, CAN NOT PURGE')
			return state;
		}

		const cues = state.cues.slice()
		let active = cues.filter(q => !q.deleted)
		return {
			...state,
			cues: active.slice()
		}
	}

  case REPLACE_CUE: {
		const cue = action.cue;
		if(cue){
			const index = state.cues.findIndex(x => x._id === cue._id);
			if(index < 0){
				return state;
			}

			const cues = state.cues.slice();
			cues[index] = cue;

			return Object.assign({}, state, {
				cues: cues
			});
		}
    return state;
	}

	case MOVE_TO: {
		const { cue, afterIndex } = action
		const cues = state.cues.slice()
		const fakeState = {
			cues: state,
		}
		const currentIndex = utilFindCueIndex(cue._id, fakeState)

		//If our source is before the target
		if(currentIndex < afterIndex) {
			//add the target
			cues.splice(afterIndex + 1, 0, cue)
			//then remove the source
			cues.splice(currentIndex, 1)
		} else {
			//remove the old value
			cues.splice(currentIndex, 1)
			//add the target
			cues.splice(afterIndex + 1, 0, cue)
		}

		return {
			...state,
			cues: cues,
		}
	}

	case 'OLD_MOVE_TO': { //Fix this to handle nulls correctly
		const { after, beforeIndex, afterIndex } = action
		const cues = state.cues.slice();

		if(beforeIndex < afterIndex){
			cues.splice(afterIndex, 1);
			cues.splice(beforeIndex + 1, 0, after);
		} else {
			cues.splice(beforeIndex + 1, 0, after);
			cues.splice(afterIndex, 1);
		}

		return Object.assign({}, state, { 
			cues: cues 
		});
	}

  case LOAD_CUES: {
		const loaded = {
			cues: action.cues,
			loaded: true
		};
		return Object.assign({}, state, loaded);
	}

	case UPDATE_UI: {
		if(action.settings){
			return Object.assign({}, state, action.settings);
		} else {
			return state;
		}
	}

	case NOTE_CUE: {
		const { cue, props } = action;
		return { 
			...state, 
			note: {
				open: true,
				cue: cue,
			} 
		};
	}

	case NOTE_DISMISS: {
		return {
			...state, 
			note: {
				open: false
			} 
		};
	}

	case FIND_CHARACTER_NAMES: {
		if(action.values) {
			return {
				...state,
				characters: action.values
			}
		}

		return state
	}

	default:
		return state;
	}
};