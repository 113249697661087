/**
 * A simple container file for all the static strings
 * used in the application.
 */
export default {
  //Inter Process Communication Dialogs / Core Exchange
  REDUX_DISPATCH: 'redux-dispatch',
  REDUX_ERROR:  'redux-error',
  REDUX_INFO: 'redux-info',
  REDUX_LOG: 'redux-log',
  REDUX_INFO_DIALOG: 'redux-info-dialog',

  //Electron Test Methods
  ELECTRON_HEARTBEAT_ASYNC: 'async-electron-alive',
  ELECTRON_HEARTBEAT_SYNC: 'electron-alive',
  ELECTRON_SAVE_DATA: 'electron-save',

  //ELECTRON MENU
  ELECTRON_MENU_SAVE: 'electron-menu-save',
  ELECTRON_MENU_SAVE_AS: 'electron-menu-save-as',
  ELECTRON_MENU_LOAD: 'electron-menu-load',
  ELECTRON_MENU_NEW: 'electron-menu-new',

  //OSC Specific Logs
  OSC_MESSAGE: 'osc-message',

  //Eos Specific Method (EOS TCP Thread)
  EOS_HEARTBEAT: 'eos-heartbeat',
  EOS_DISCONNECT: 'eos-disconnect',
  EOS_CONNECT: 'eos-connect',
  EOS_LIVE: 'eos-live',
  EOS_SYNC_CUES: 'eos-sync-cues',
  EOS_LABEL_PATCH: 'eos-label-patch',
  EOS_LISTENER_FLAGS: 'eos-listener-flags',
  EOS_CUE_FIRED: 'eos-cue-fired',
	EOS_CUE_SET_FIRED: 'eos-cue-set-fired',
  EOS_REQUEST_PATCH: 'eos-request-patch',
  EOS_PATCH_CHANGED: 'eos-patch-changed',
  EOS_PATCH_CHANGED_BULK: 'eos-patch-changed-bulk',

  //QLab Connectivity
  QLAB_WRITE_GROUPS: 'qlab-write-groups',

  //Vectorworks Synchronization Actions

  /**
   * Starts the process of the exchange, creating the file if necessary
   */
  ELECTRON_VW_SYNC_START_EXCHANGE: 'ELECTRON_VW_SYNC_START_EXCHANGE',
  /**
   * Dispatches the `syncWithVectorworks` command inside the web container
   */
  ELECTRON_VW_SYNC_PROCESS_DATA: 'ELECTRON_VW_SYNC_PROCESS_DATA',
  /**
   * Causes Electron to overwrite the existing file with the new data
   * automatically (without user confirmation)
   */
  ELECTRON_VW_SYNC_SAVE_DATA: 'ELECTRON_VW_SYNC_SAVE_DATA',

  /**
   * A simple "enum" that specifies how things should merge.
   * This is not implemented fully yet
   */
  SYNC_MODES: {
    CHANGES: 'CHANGES',
    ALL_BY_TIMESTAMP: 'ALL_BY_TIMESTAMP',
    ALL_PREFER_LIGHT_ASSISTANT: 'ALL_PREFER_LIGHT_ASSISTANT',
    ALL_PREFER_VECTORWORKS: 'ALL_PREFER_VECTORWORKS',
  },

  //Cue Sheet
  CUES_PRINT_SIMPLE_PDF: 'CUES_PRINT_SIMPLE_PDF',

  //Notes Module
  NOTES_NEW_NOTE_FROM_ELECTRON: 'NOTES_NEW_NOTE_FROM_ELECTRON',
  //DEBUG and ERROR REPORTING
  ELECTRON_CAPTURE_SCREEN: 'ELECTRON_CAPTURE_SCREEN',
}

