import React from 'react'
import { connect } from 'react-redux'
import {
  Button, 
  Badge,
} from '@material-ui/core'

import { 
  inlineSpan,
} from './../../components/Footer/Footer'

import Colors from './../Colors'
import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'
import ClearIcon from '@material-ui/icons/Clear'
import OpenIcon from '@material-ui/icons/ExpandLess'
import CloseIcon from '@material-ui/icons/ExpandMore'
import translate from './../../shared/language/Translate'

import {
  actionClear,
  actionUndo,
  actionRedo,
} from './../reducers/UndoLogic'
import { DeleteForever } from '@material-ui/icons'

class UndoItem extends React.Component {

  render() {
    const indent = {
      display: 'block',
      flex: 0,
      minWidth: 24,
      maxWidth: 24,
      margin: 0,
      padding: 0,
      color: 'green',
    }
  
    const isFirst = this.props.index === 0
    let bg

    if(this.props.type  === 'undo') {
      if(this.props.index === 0) {
        bg = 'rgba(0,128,0,0.3)'   
      } else {
        bg = this.props.index % 2 == 0 ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.5)'
      }
    } else {
      bg = this.props.index % 2 == 0 ? 'rgba(0,0,128,0.2)' : 'rgba(0,0,128,0.5)'
    }

    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: this.props.type === 'redo' ? window.theme.palette.primary.main : window.theme.palette.text.primary,
          margin: '3px 5px 4px 5px',
          fontSize: '14px',
          backgroundColor: bg,
          padding: 0,
          margin: 0,
        }}
      >
        <span style={indent}>{isFirst ? this.props.type === 'undo' ? '>>' : '' : ''}</span>
        <span dangerouslySetInnerHTML={{
          __html: this.props.html
        }}/>
      </div>
    )
  }
}

class Undo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: true
    }
  }

  collapse(yesNo) {
    this.setState({
      ...this.state, 
      collapsed: yesNo
    })
  }

  render() {

    const T = this.props.strings
    const redoTag = T.get('redoTag')
    const undoTag = T.get('undoTag')
    const stack = (this.props.undo || {})[this.props.module] || {}
    const { dispatch } = this.props
    const undo = stack.undo || []
    const redo = stack.redo || []

    const undoEnabled = undo.length > 0
    const redoEnabled = redo.length > 0

    const undoClass = {
      color: window.theme.palette.text.primary,
      margin: '3px 0 0 0',
      borderBottom: '1px solid red',
      fontSize: '14px',
    }

    const redoClass= {
      ...undoClass,
      color: window.theme.palette.secondary.primary,
    }

    const labelStyle = {
      fontSize: '1.5em',
      fontWeight: 'bold',
      paddingLeft: 10,
      paddingRight: 24,
    }

    const ulStyle = {
      flex: 1,
      overflow: 'scroll',
    }

    const inlineButtonStyle = {
      padding: 0,
      margin: 0,
    }

    const buttonClass = (disabled) => {
      return disabled ? 'footer disabled' : 'footer'
    }
    const collapsed = () => {
      return (
        <span style={inlineSpan}> 
            <button
              title={ T.get('undo') }
              className={ buttonClass(!undoEnabled) }
              style={inlineButtonStyle}
              disabled={!undoEnabled}
              onClick = { () => dispatch( actionUndo(this.props.module) ) }
              size='small'
            >
              <UndoIcon>
              </UndoIcon>
            </button>

            <button 
              title={ T.get('redo') }
              className={ buttonClass(!redoEnabled) }
              style={inlineButtonStyle}
              disabled={!redoEnabled}
              onClick = { () => dispatch( actionRedo(this.props.module) ) }            
              size='small'
            >
              <RedoIcon />
            </button>

            <button 
              title={ T.get('viewHistoryDetail', this.props.module)}
              className={ buttonClass(!redoEnabled && !undoEnabled) }
              disabled={!redoEnabled && !undoEnabled}
              style={inlineButtonStyle}
              onClick = { () => {
                this.collapse(false)
              } }
            >
              <b> { T.get('viewHistory', T.get(this.props.module) ) } </b> 
              <OpenIcon /> 
            </button>
        </span>
      )
    }

    const expanded = () => {
      return (
        <div 
          style={{
            fontSize: '1em',
            borderBottom: '1px solid black',
            margin: 0,
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
          }}
        >  
          {/* HEADER */}
          <div
            style={{
              display: 'block',
              flex: 0,
              backgroundColor: window.theme.palette.common.black,
              color: window.theme.palette.common.white,
              boxShadow: Colors.SHADOW_BLACK,
            }}
          >
            <label style={labelStyle}>
              { T.get('viewHistory', T.get(this.props.module) ) }
            </label>
            <Badge
              badgeContent={undo.length}
              color='secondary'
            >
            </Badge>
            <Button 
              color='secondary'
              disabled={!undoEnabled}
              onClick = { () => dispatch( actionUndo(this.props.module) ) }
            >
              <UndoIcon /> { T.get('undo') }
            </Button>

            <Button 
              color='primary'
              disabled={!redoEnabled}
              onClick = { () => dispatch( actionRedo(this.props.module) )} 
            >
              <RedoIcon /> { T.get('redo') }
            </Button>

            <Badge
              color='primary'
              badgeContent={redo.length}
            >
            </Badge>

            <Button 
              color='secondary'
              onClick = { 
                () => { 
                  dispatch( actionClear(this.props.module) )
                  this.collapse(true)
                } 
              } 
            >
              <DeleteForever /> CLEAR
            </Button>
            <Button
              style={{
                color: window.theme.palette.common.white,
              }}
              onClick = { () => {
                this.collapse(true)
              }}
            >
              <ClearIcon />
            </Button>
          </div>
          
        {/* LIST */}
        <div 
          style={ulStyle}
        >
        {
          redo.map((x,i) => {
            const redoTag = T.get('redoTag')
            const _dangerous = redoTag + x[0].redoDescription

            return (
              <UndoItem 
                key={'r' + i}
                type='redo'
                index={i} 
                html={_dangerous} 
              />
            )
          })
        }
        {
          undo.slice().reverse().map((x,i) => {
            const _dangerous = undoTag + x[0].description

            return (
              <UndoItem 
                type='undo'
                key={'u' + i}
                index={i} 
                html={_dangerous} 
              />
            )
          })
        }
        </div>
      </div>)
    }
    return (
      <React.Fragment>
        {/* FOOTER */}
        { collapsed() }
        { !this.state.collapsed ? 
          <div 
            style={{
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              height: '100%',
              zIndex: 900,
              backgroundColor: window.theme.palette.background.paper,
              //backgroundColor: 'rgba(128, 128, 128, 0.8)',
              padding: 0,
              //boxShadow: '0px 0px 5px 5px rgba(0,0,0, 0.7)',
              border: '1px solid black',
              WebkitTransition: 'width: 2s',
              transition: 'width: 2s',
            }}
          >
            { expanded() }
          </div>
          : null }
      </React.Fragment>
      
    )
    
  }
}
export default connect(s => {
  return {
    undo: s.undo
  }
})( translate('Undo')(Undo) )