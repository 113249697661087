export default {
  open: 'Open',
  close: 'Close',
  delete: 'Delete',
  restore: 'Restore',
  ok: 'OK',
  cancel: 'Cancel',
  reset: 'Reset',
  report: 'Report',
  save: 'Save',
  load: 'Load',
  done: 'Done',
  commitEdits: 'Commit Changes',
  cancelEdits: 'Cancel Changes',
  locked: 'Locked',
  restoreDefaults: 'Restore Defaults',
  settings: 'Settings',
  advanced: 'Advanced',
  options: 'Options',
  remove: 'Remove',
  saveChanges: 'Save Changes',
  utilities: 'Utilities',
  continue: 'Continue',
}