let __currentStore = null
let activeListener = null

const VECTORWORKS = 'vectorworks'
const CUES = 'cues'
const INSTRUMENTS = 'instruments'
const IMAGES = 'images'

/** 
 * THIS IS HALF BAKED AND NOT USED
 * 
 * I was looking at how to get around redux-persist's limits but we're using LocalForage
 * now and it should be fine.
 */
export const applyStorageListener = (store) => {
  
  if(activeListener) {
    activeListener()
  }

  activeListener = store.subscribe(()=>{
    const oldState = __currentStore
  
    __currentStore = store.getState()

    if(oldState !== __currentStore) {
      const old = oldState || {}
      const current = __currentStore || {}
      for(let key of modules) {
        if(old[key] !== current[key])
        switch (key){
          case 'vectorworks': {
            localStorage.setItem( 'la::vectorworks', JSON.stringify(current.vectorworks) )
          }
        }
        //Logger
        if(old[key] !== current[key]) {
          console.log(`[STATE CHANGED] module->${key}`)
        }
      }
    }
  })
}

export const rehydrate = (store) =>{

}