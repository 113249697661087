import { showError, showSnack, showWarning } from './reducers/InterfaceLogic';

class BaseError extends Error {
	constructor(message, obj){
		super(message);
		this.name = this.constructor.name;
		this.message = message;
		if( typeof Error.captureStackTrace === 'function'){
			Error.captureStackTrace(this, this.constructor);
		} else {
			this.stack = (new Error(message)).stack;
		}
	}
}

let baseErrorFunc = (err, msg, code)=>{
		if(!err){
			err = new BaseError(msg);
		}
		return {
			status: false,
			message: msg || err.message || 'Unknown error',
			error : {
				message : msg || err.message || 'Unknown error',
				error: {
					status: code || 500,
					message: err.message,
					stack: err.stack,
					debug : err
				},
				code : code || 500
			}
		};
};

const INFO = 'info';
const ERROR = 'error';
const WARNING = 'warning';
const SUCCESS = 'success';

const react = (level)=>{
	return (title, detail, error)=>{
		console.log(`
------------------------------
React Message: [${level}]
------------------------------ 
${title}
------------------------------
${detail}
`);
		const opts = {
			type: 'error',
			title : title,
			message: detail,
			position: 'br',
			dismissible: true,
			autoDismiss: 9,
			error: error || new BaseError(detail || title)
		};

		if(level === SUCCESS){
			return dispatch => {
				console.log('[SUCCESS]');
				console.log(opts);
				dispatch( showSnack(opts.title) );
			};
		} else if (level === WARNING){
			return dispatch => {
				console.log('[WARNING]');
				console.log(opts);
				dispatch( showWarning (opts.title, opts.message) );
			};
		} else if (level === ERROR) {
			return dispatch => {
				console.log('[ERROR]');
				console.log(opts);
				dispatch( showError(opts.title, opts.message, error) );
			};
		} else { //INFO
			return dispatch => {
				console.log('[INFO]');
				console.log(opts);
				dispatch( showSnack(opts.title) );
			};
		}
	};
};

export default {
	//FIXME this won't really cut it, it needs to log
	'handle' : (err)=>{
		if(!err){
			err = 'Unknown Error';
		}
		const message = err.message || err;
		return react(ERROR)('An error occured', message);
	},
	INFO: INFO,
	ERROR: ERROR,
	WARNING: WARNING,
	SUCCESS: SUCCESS,
	BaseError : BaseError,
	'reactInfo': react(INFO),
	'reactWarning': react(WARNING),
	'reactError': react(ERROR),
	'reactSuccess': react(SUCCESS),
	/*
	 * return an error object with a standard format so that
	 * the application remains consistent.
	 * @param err the err object returned from MongoDB
	 * @param msg the error message you wish to return
	 * @param code the error code (optional) that you wish to assign
	 * by default it returns 500 (these corrispond to http codes).
	 */
	'error' : baseErrorFunc,
		/*
	 * return an error object with a standard format so that
	 * the application remains consistent. This will generate an 
	 * error object so that there is a stack
	 * @param msg the error message you wish to return
	 * @param code the error code (optional) that you wish to assign
	 * by default it returns 500 (these corrispond to http codes).
	 */
	'simple' : (msg, code) =>{
		return baseErrorFunc(null, msg, code);
	}
};