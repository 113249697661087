//import PDFDocument from 'pdfkit'
import { joinStrings } from './../StringUtil'
import { Translator} from './../language/Translate'
import { createPositionList } from '../ReportHelpers'
//import blobStream from 'blob-stream'
/**
 * Print a simplified cue sheet
 * @param {Array of Cue Objects} cues the cues to write
 * @param {Stream} stream the stream to write to
 * @param {Object} _settings an object of settings, if null a default setting is used
 * {
 *   columns: [
 *    {
 *      key: propertyName, //the key to write
 *      width: 96, //number in points
 *      wrap: true | false | default = true
 *    }
 *    , ...
 *   ],
 * }

 * @returns a Promise that resolves in a PDF document
 */
export const reportInstrumentSchedule = (dataSet, stream, _settings, show) => {

  const showTitle = show.currentShow || 'Unknown Show'
  const positions = createPositionList(show)
  const designer = show.designerField || ''
  const assistant = show.assistant || ''
  const producer = show.producer || ''
  const paperworkDate = show.paperworkDate || ''

  const code = _settings ? _settings.language : 'en'
  const lang = Translator(code || 'en', 'Instruments')
  const INSTRUMENT = 'instrument'
  const NOTE = 'note'

  const DEFAULT_FONT = 'Helvetica'
  const DEFAULT_BOLD = 'Helvetica-Bold'
  const DEFAULT_FONT_SIZE = 10

  const HEADER_SIZE = 20
  const HEADER_SUB_SIZE = 12
  const HEADER_TOTAL = HEADER_SIZE + HEADER_SUB_SIZE

  return new Promise( (resolve, reject) => {
    try {
      let doc = new PDFDocument({
        bufferPages: true
      })
      doc.fontSize(12)
      doc.font(DEFAULT_FONT)
      //console.log(stream)
      let output = doc.pipe(stream)
  
      let page = 1
      let cue = 0
      let numRows = 20

      //468 on normal margins
      let defaultSettings = {
        header: 40,
        footer: 20,
        lineSpacing: 2,
        stripeColor: '#f4f4f4',
        headerColor: show.color || '#0D47A1',
        headerTextColor: null,
        bodyTextColor: null,
        show: {
          title: showTitle
        },
        positions: positions,
        columns: [ 
          {
            key: 'channel',
            width: 32,
            fontSize: 10,
            align: 'center',
            formatter: (num) => {
              if(num === undefined || num === null) {
                return ''
              }
              
              return '(' + num + ')'
            },
          }, {
            key: 'address',
            width: 32,
            fontSize: 10,
            align: 'left',
          }, {
            key: 'circuit',
            width: 32,
            fontSize: 10,
            align: 'left',
            formatter: (_, inst) => {
              const name = inst.circuitName || ''
              const num = inst.circuitNumber || ''
              return name + num
            }   
          }, {
            key: '__instTypeAccessories',
            width: 180,
            fontSize: 10,
            align: 'left',  
            formatter: (_, inst) => {
              const type = inst.instrumentType || ''
              const accessories = inst.accessories || ''
              return type + ' ' + accessories
            }   
          }, {
            key: 'wattage',
            width: 56,
            fontSize: 10,
            align: 'left',  
          }, {
            key: 'color',
            width: 64,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
            formatter: (_, inst) => {
              const color = inst.color
              const frost = inst.frost

              return joinStrings(color, frost)
            }
          }, {
            key: 'template',
            width: 64,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
            formatter: (_, inst) => {
              const template = inst.template
              const template2 = inst.template2

              return joinStrings(template, template2)
            }
          }, {
            key: 'purpose',
            width: 80,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
          }, {          
            key: 'unit',
            width: 24,
            fontSize: 10,
            align: 'center'
          }, 
        ],
        labels: {
          channel: '(Ch)',
          address: 'Dim',
          circuit: 'Cir.',
          __instTypeAccessories: 'Instrument Type & Accessories',
          wattage: 'Wattage',
          color: 'Color',
          template: 'Template',
          purpose: 'Purpose',
          unitNumber: '#'
        }
      }

      const settings = Object.assign({}, defaultSettings, _settings)

      const columns = settings.columns
      const margins = { //18 is the minimum standard we can expect a printer to use
        top: 18,
        left: 18,
        right: 18,
        bottom: 18.
      }
      const pageSize = { width: doc.page.width, height: doc.page.height }
      const position = {x: doc.x, y: doc.y}
      const availableSize = { 
        width: pageSize.width - margins.left - margins.right, 
        height: pageSize.height - margins.top - margins.bottom
      }

      const bodySize = {
        width: availableSize.width,
        height: availableSize.height - settings.header - settings.footer,
        start: settings.header || 0
      }

      const lineSpacing = settings.lineSpacing || 2
      //console.log(doc)
      console.log(`margins -> ${JSON.stringify(margins)}`)
      console.log(`size -> ${JSON.stringify(pageSize)}`)
      console.log(`position -> ${JSON.stringify(position)}`)
      console.log(`available -> ${JSON.stringify(availableSize)}`)

      let sections = []
      let section = null

      const newSection = (title) => {
        section = {
          height: HEADER_TOTAL + lineSpacing, //minimum
          header: null,
          title: title,
          rows: [],
        }
        sections.push(section)
      }

      const addRow = (type, data, height) => {
        //Layout guides
        section.rows.push({
          type: type,
          data: data,
          height: height
        })

        section.height += height + lineSpacing
      }

      newSection('showNameTbd')

      /**
       * Warning, this function mutates the document object 
       * and could, potentially, cause drawing failures.
       * 
       * @param {*} text 
       * @param {*} width 
       * @param {*} size 
       * @param {*} font 
       */
      const calcTextSize = (text, width, size, font) => {
        doc.fontSize(size || DEFAULT_FONT_SIZE)
        doc.font(font || DEFAULT_FONT)
        const out = doc.heightOfString(text, { width: width })
        return out
      }

      const defaultFormatter = x =>{ 
        if (x === undefined || x === null) {
          return '-'
        } else {
          return x
        }
      }

      let lastPosition = null
      for (let dataIndex = 0; dataIndex < dataSet.length; dataIndex++) {
        const inst = dataSet[dataIndex]
        let rowHeight = 0 //maximum vertical space for this cue

        //FIRST ELEMENT, this needs a new section by default
        if(dataIndex === 0) {
          lastPosition = inst.position
          section.title = lastPosition //use this cue's title
        } else if (inst.position != lastPosition) { 
        //START NEW SECTION, the above check starts a new section
          lastPosition = inst.position
          newSection(lastPosition)
        }

        //Header Portion
          //Not implemented
          //Images
        let fakeCue = ''
        //Basic Cue Information
        for(let i = 0; i < columns.length; i++) {
          const col = columns[i]
          let formatter = col.formatter || defaultFormatter
          let key = col.key
          let val = formatter(inst[key], inst)
          fakeCue += ' ' + val
          const textHeight = calcTextSize(val, columns[i].width, col.fontSize, col.font)
          rowHeight = Math.max(rowHeight, textHeight)
        }

        fakeCue += ' | height -> ' + rowHeight 
        //FIXME add the other parts as needed
        addRow(INSTRUMENT, inst, rowHeight)
        console.log(fakeCue)
        //Notes portion
          //Not implemented
        //Add pagination logic
        //console.log('Cue -> ' + cues[cue])
      }
      
      console.log(`Sections ${sections.length}`)
      for(let i = 0; i < sections.length; i++) {
        console.log(`\tSection: ${sections[i].title} Rows: ${sections[i].rows.length} Height: ${sections[i].height} Pages: ${sections[i].height / bodySize.height}`)
      }

      //----------------------------------
      // WRITE THE PDF
      //----------------------------------
      const writeSectionHeader = (text, x, y, width) => {
        const headerColor = settings.headerColor || 'red'
        const headerTextColor = settings.headerTextColor || 'white'
        const bodyTextColor = settings.bodyTextColor || 'black'
        doc
          .font(DEFAULT_BOLD)
          .fontSize(14)

        const titleHeight = doc.heightOfString('_Demoj', { 
            width: 400
          })

        const titleOffsetY = (HEADER_SIZE - titleHeight) / 2 + 3
        console.log(`titleOffsetY: ${titleOffsetY}`)

        doc
          .rect(x, y, width, HEADER_SIZE)
          .fill(headerColor)

        doc
          .fillColor(headerTextColor)
          .font(DEFAULT_BOLD)
          .fontSize(14)
          .text(text || 'NO POSITION', x + 5, y + titleOffsetY, {
            width: width,
            ellipsis: true,
            align: 'left',
          })

        //Write subtitles
        doc
          .rect(x + 12, y + HEADER_TOTAL - 2, width - 12, 1)
          .fill(headerColor)

        let dX = 0
        for(let col of columns) {
          doc
          .fillColor(bodyTextColor)
          .font(col.font || DEFAULT_FONT)
          .fontSize(DEFAULT_FONT_SIZE)
          .text( col.name || lang.get(col.key), dX, y + HEADER_SIZE + 1, {
            width: col.width,
            height: HEADER_SUB_SIZE,
            color: col.color || 'black',
            align: col.align || 'left',
          } )

          dX += col.width
        }
      }

      //Write pages
      let dY = 0
      doc.translate(margins.left, margins.top + (settings.header || 0)) //respect the margins
      doc.margins = margins
      doc.save()

      //Write the page number, header, etc... 
      for(let s = 0; s < sections.length; s++) {
        let section = sections[s]
        //Write header

        const measurement = section.height + dY + 20 //section padding
        if(s > 0 && measurement > bodySize.height) {
          dY = 0
          doc.restore()
          doc.addPage(margins)
          doc.translate(margins.left, margins.top + (settings.header || 0))
          doc.margins = margins
          doc.save()
        }

        writeSectionHeader(section.title, 0, dY, bodySize.width, 20)

        //FIXME -> trying to paginations
        dY += HEADER_TOTAL + lineSpacing
        
        //RENDER LOOP, this loop renders rows based on type
        var stripes = 0

        for(let i = 0; i < section.rows.length; i++) {
          let row = section.rows[i]
          
          if (INSTRUMENT == row.type) { 
            stripes++ //color the row
            let dX = 0
            
            const nextHeight = dY + row.height + lineSpacing
            if(nextHeight > bodySize.height) {
              stripes = 0
              console.log(`
              Breaking here for the following
              dY: ${dY}
              bodySize: ${JSON.stringify(bodySize)}
              nextHeight: ${nextHeight}
              `)
              doc.restore()
              doc.addPage()
              doc.translate(margins.left, margins.top + (settings.header || 0)) 
              doc.margins = margins
              doc.save()
              writeSectionHeader(section.title + '(continued...)', 0, 0, bodySize.width)
              dY = 20 + lineSpacing
            }
  
            if(stripes % 2 == 0) {      
              doc
                .rect(0, dY - settings.lineSpacing - 1, bodySize.width, row.height + settings.lineSpacing)
                .fill(settings.stripeColor)
            }
            for(let c = 0; c < columns.length; c++) {
              let col = columns[c]
              let formatter = col.formatter || defaultFormatter
              doc
                .fillColor(col.color || 'black')
                .font(col.font || DEFAULT_FONT)
                .fontSize(col.fontSize || DEFAULT_FONT_SIZE)
                .text( formatter( row.data[col.key], row.data ), dX, dY, {
                  width: col.width,
                  height: row.height,
                  color: col.color || 'black',
                  align: col.align || 'left',
                } )
              
              dX += col.width
            }
  
            //Line after cue data
            if(!row.data.showSpots) {
              const lineY = dY + row.height - 1
              doc
                .moveTo(0, lineY)
                .lineTo(bodySize.width, lineY)
                .stroke('#ddd')
            }
            
            dY += row.height
            dY += lineSpacing
            //console.log(`${row.data.number} | Moving to points [${0}, ${dY}]`)
          } else if (row.type == SPOTS) {
            console.log('spot cue here...')
            // dY = row.height + lineSpacing
            doc
              .moveTo(0, dY)
            for(let i = 0; i < row.data.spots.length; i++) {
              doc
                .fillColor('black')
                .font(DEFAULT_BOLD)
                .fontSize(10)
                .text( `Spot ${i + 1}`, 320 + spotWidth * i, dY, {
                  width: 48,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
              doc
                .fillColor('black')
                .font(DEFAULT_FONT)
                .fontSize(10)
                .text( row.data.spots[i].pickup, 320 + spotWidth * i, dY + 12, {
                  width: spotWidth - 32,
                  height: 20,
                  color: 'black',
                  align: 'left',
                } )
            }

            const lineY = dY + row.height - 1
            doc
              .moveTo(0, lineY)
              .lineTo(bodySize.width, lineY)
              .stroke('#ddd')

            dY += spotHeight
            dY += lineSpacing
          }
        } 
      }

      //Add Page Numbers
      let range = doc.bufferedPageRange()
      doc.restore()
      for(let i = range.start; i < range.count; i++) {
        doc.switchToPage(i)
        doc.translate(0, -settings.header) //This feels weird, but seems to work.
        //Write Header
        doc
          .fillColor('black')
          .font(DEFAULT_BOLD)
          .fontSize(24)
          .text( `Instrument Schedule ${settings.show.title}`, 0, 0, {
            width: bodySize.width,
            height: settings.header,
            color: 'black',
            align: 'left',
          } )

        if (settings.positions && settings.positions.length) {
          for(let p = 0; p < settings.positions.length; p++) {
            doc
            .fillColor('black')
            .font(DEFAULT_FONT)
            .fontSize(DEFAULT_FONT_SIZE)
            .text( 
              settings.positions[p], 
              bodySize.width - 200, p * 15, {
              width: 200,
              height: settings.header || 40,
              color: 'black',
              align: 'right',
            } )
          }
        }
        
        
        //Write Footer
        doc
          .fillColor('black')
          .font(DEFAULT_FONT)
          .fontSize(DEFAULT_FONT_SIZE)
          .text( `Page ${i + 1} of ${range.count}`, bodySize.width - 128, availableSize.height - settings.footer, {
            width: 128,
            height: 20,
            color: 'black',
            align: 'right',
          } )
      }

      doc.flushPages()
      doc.end()
      resolve(output)
    } catch (error) {
      reject(error)
    }
  })
}