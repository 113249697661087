export default {
  download: 'Download',
  editFieldsAndMappings: 'Edit Fields and Mappings',
  //Sync Module
  syncNow: 'Sync Now',
  syncDataExchange: `Sync Vectorworks`,
  outstanding: 'Outstanding Changes',
  moreInfo: 'More Information',
  redownloadXml: 'Download XML',
  tipHelp: 'More information on Vectorworks Data Exchange',
  tipSync: 'Performs a synchronization with Vectorworks',
  outstandingTooltip: `You have {0} changes to instruments that have not been synchronized`,
  syncDataExchangeTitle: 'Vectorworks Data Exchange (Web)',
  syncDataExchangeAppTitle: 'Vectorworks Data Exchange (App)',
  syncDataExchangeHelp: `
<p>
Light Assistant (Web) can use Vectorworks Data Exchange for import/export. To 
begin simply export the entire plot and enable Data Exchange. In the web 
version you must manually handle file upload and downloads due to security 
restrictions. This is error prone, but functional. 

Light Assistant is <em>very error tolerant</em>, so when in doubt just export 
the entire plot from Vectorwork and synchronize again. You can also just pick
up where you left off in Light Assistant with the current XML file. Any changes
you have made will be pushed to Vectorworks.
</p>

<p><em>It is imperitive that you replace the XML file with the file generated by 
Light Assistant or the exchange won't work. </em></p>
`,
syncDataExchangeAppHelp: `
<p>
Light Assistant (App) can use Vectorworks Data Exchange for import/export. To 
begin simply export the entire plot and enable Data Exchange. Once synchronization has
run Light Assistant uses the same file and automatically updates when you click <b>"Sync"</b>.
</p>

<p>
Light Assistant is <em>very error tolerant</em>, so when in doubt just export 
the entire plot from Vectorwork and synchronize again. You can also just pick
up where you left off in Light Assistant with the current XML file. Any changes
you have made will be pushed to Vectorworks.
</p>

<p>
Light Assistant is prefers to synchronize when you are ready to rather than 
synchonizing constantly. This helps eliminate errors and allows you to see changes
as they come in rather than constantly. This does mean you need to manually synchronize
but the application time-stamps all interactions an is generally error-free.
</p>
`,
  syncExplainNew: 'Select New Exchange File',
  syncConfrimNew: 'Select a New File',
  syncExplainNewBody: `
  <p>
  Selecting a new file retargets the exchange operation. This is useful if a CAD 
  file has been renamed or you simply want to synchronize with a new document to merge data. 
  </p>

  <p>
  You will likely want to combine this with an <b>Export All Data on Exit</b> from 
  Vectorworks or follow it with an <b>Export All Light Assistant Data</b> command
  to bring the files together.
  </p>

  <p>
  Alternatively, you can clear your instrument data, force an export from Vectorworks 
  and then retarget the exchange file to bring in new instrument data.
  </p>
`,

  syncExplainExportAll: 'Export All Light Assistant Data (Light Assistant Wins)',
  syncExplainExportAllBody: `
  <p>NOT IMPLEMENTED</p>
`,
  syncExplainSyncWithVectorworks: 'Sync With Vectorworks (Vectorworks Wins)',

  syncExplainClear: 'Clear All Instrument Data',
  syncExplainClearConfirm: 'Delete Instrument Data',
  syncExplainClearBody: `
  <p>
    <i>Clear All Instrument Data</i> removes all instrument data 
    from Light Assistant. This operation can be useful if you want 
    a fresh import from Vectorworks or you are merging from another 
    show.
  </p>
    
  <p>
    The feature is useful if you need to resolve synchronization issues with Vectorworks
    (such as when a backup is restored in Vectorworks after a crash) and you know Vectorworks 
    is up to date. If you believe Light Assistant has the most recent information you should 
    use <b>Export All Light Assistant Data (Light Assistant Wins)</b> before clearing. 
  
  </p>

  <p>
    It is recommended you save your show first.
  </p>
`,
  setupDataExchange: `Setup Data Exchange`,
  start: 'Start Exchange',
  cancel: 'Cancel',

  //Mappings
  setupMappings: 'Setup Mappings',
  justSync: 'Synchronize',
  setupMappingsTitle: 'Setup Mappings Now?',
  setupMappingsDetail: `
  <p><b>Do you want to set up custom mappings now?</b></p>
  <p>
    By default Light Assistant pulls in any available Vectorworks 
    default Spotlight Record data. 
  </p>
  <p>
    <i>
      This means that unless you are using
      custom fields, custom paperwork, or have a custom workflow you probably
      do not need to adjust the mappings on this screen.
    </i>
  </p>
`,

  errorReadingXml: 'Unable to read XML',
  errorReadingXmlDetail: `
  <p>
    The file could not be processed. <b>${0}</b> is not a Data Exchange XML file or is corrupted.
  </p>
`,
  syncChangeMappings: 'Edit Data Exchange Mappings',
  webImportDone: 'Import Completed',
  webImportDoneDetail: `
  <p>
  The data from Vectorworks has been successfully processed.
  </p>
  <p>
  Web security prevents Light Assistant from overwriting the file directly, you 
  need to download and replace the XML file you uploaded. The desktop appliction does
  not have this restriction.
  </p>
  `,
}