import React from 'react'
import PropTypes from 'prop-types'
import Colors from './../Colors'

import { 
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core'
class OscConsole extends React.Component {

  constructor(props){
    super(props)

    this.state = {
      text: '',
      filters: {
        send: true,
        receive: true,
        info: true,
      },
    }
  }
  static propTypes = {
    sendText: PropTypes.string,
    onSend: PropTypes.function,
    enabled: PropTypes.enabled,
    lines: PropTypes.arrayOf(PropTypes.object),
  }

  toggleFilter(filter) {
    const copy = {...this.state.filters}
    copy[filter] = !copy[filter]

    this.setState({
      filters: copy
    })
  }

  render() {

    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <label>Filters</label>
          <FormGroup row>
            {['send', 'receive', 'info'].map( str => {
              return (
                <FormControlLabel 
                  label={str}
                  control={
                    <Checkbox
                      checked={this.state.filters[str]}
                      value={str}
                      onChange={()=>{
                        this.toggleFilter(str)
                      }}
                    />
                  }
                />
              )
            })}
          </FormGroup>
          <input 
            type='text' 
            style={{
              flex: 1,
              color: Colors.ORANGE,
            }}
          />
          <button
            style={{
              marginRight: 16,
            }}
            onClick={e => {
              if(this.props.enabled && this.props.onSend) {
                this.props.onSend('NO STRING YET')
              }
            }}
          > 
            {this.props.sendText || 'Send'} 
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            overflow: 'scroll',
            fontFamily: 'monospace',
            flexDirection: 'column',
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        >
          { this.props.lines ? this.props.lines.map(p => {
            if(!this.state.filters[p.type]) {
              return null
            }

            const text = `[${p.level}] [${p.app}::${p.type}] [${p.time}] ${p.message} ${p.args.join(' ')}`
            let color = Colors.GREEN

            if(p.app == 'eos') {
              if(p.type == 'info') {
                color = Colors.EOS_INFO
              } else if (p.type == 'receive') {
                color = Colors.EOS_RECEIVE
              } else {
                color = Colors.EOS_SEND
              }
            } else if (p.app == 'qlab') {
              if(p.type == 'info') {
                color = Colors.QLAB_INFO
              } else if (p.type == 'receive') {
                color = Colors.QLAB_RECEIVE
              } else {
                color = Colors.QLAB_SEND
              }
            }

            return (
              <label 
                style={{
                  fontFamily: 'monospace',
                  color: color,
                  fontSize: 12,
                  marginLeft: 32,
                  marginTop: 5,
                }}
              >
                {text}
              </label>
            )
          }) : null }

        </div>
      </div>
    )
  }
}

export default OscConsole