import Mappings from './../modules/Mappings'
import FullScreenDialog from './../../components/FullScreenDialog'
import { connect } from 'react-redux'
import Colors from './../Colors'
import PropTypes from 'prop-types'

import React from 'react'
import { 
  Button,
  IconButton,
} from '@material-ui/core'

import IconHelp from '@material-ui/icons/Help'
import IconRestore from '@material-ui/icons/Restore'
import { 
  instrumentMappings,
  general, 
} from './../../shared/language/'

import {
  showConfirm,
  showDialog,
} from './../reducers/InterfaceLogic'

import {
  createOrResetDefaultMappings
} from './../reducers/VectorworksCache'
class MappingsFullScreen extends React.Component {

  render() {
    const { dispatch } = this.props
    return (
      <FullScreenDialog
        title={ instrumentMappings.get('editFieldsAndMappings') }
        open={ this.props.open } 
        closeText={ general.get('done') }
        onClose={()=>{
          if(this.props.onClose) {
            this.props.onClose()
          }
        }}
        topActions={
          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              style={{
                color: Colors.WHITE,
              }}
              onClick={e => {
                dispatch( showConfirm(
                  instrumentMappings.get('resetToDefault'),
                  instrumentMappings.get('resetDefaultMessage'),
                  () => {
                    dispatch( createOrResetDefaultMappings() )
                    //dispatch( /*fire method here */)
                  },
                  null,
                  instrumentMappings.get('resetToDefaultConfirm'),
                ) )
              }}
            >
              <IconRestore />
              { instrumentMappings.get('resetToDefault') }
            </Button>

            <IconButton
              style={{
                color: Colors.WHITE,
              }}
              onClick={ x => {
                dispatch( 
                  showDialog( 
                    instrumentMappings.get('resetToDefault'),
                    instrumentMappings.get('resetDeafultHelp'),
                  )
                )
              }}
            >
              <IconHelp />
            </IconButton>
          </div>
        }
      >
        <Mappings />
      </FullScreenDialog>
    )
    
  }

}

FullScreenDialog.propTypes = {
  open: PropTypes.boolean
}

export default connect( s => {
  return {}
} )(MappingsFullScreen)