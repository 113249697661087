


export const backendTestError = (message) => {
  return dispatch => dispatch({
    type: 'TEST_ERROR',
    message: message || 'No Test Message',
  })

}

export const tryReportError = (_error, hint, onComplete) => {
  const reportIt = (error) => {
    window.REDUX_STORE.dispatch( 
      (dispatch, getState) => {
        let output = {}
        if(window.ERROR_SCREEN_SHOT) {
          //Pull screen shot.
          output.screenShot = (window.ERROR_SCREEN_SHOT || {}).screenShot
        }
        const state = getState()
        output.state = JSON.parse(JSON.stringify(state))//deep copy
        output.stack = error.stack
        output.error = error
        output.version = `Version info: ui:${UI_VERSION} native:${VERSION}, ${new Date(BUILD_DATE).toLocaleDateString()}`
        output.name = error.toString() || 'No Error Name'
        output.message = error.message || 'No Error Message'
        output.string = error.toString()
        output.lineNumber = error.lineNumber || '?'
        output.columnNumber = error.columnNumber || '?'
        output.fileName = error.fileName || '?'
        output.location = `${output.fileName} ${output.lineNumber} ${output.lineNumber}`
        output.hint = hint || ''
        output.simple = `
<div class="error simple">
<h1>${output.name}<h1>
<p>${output.version}</p>
<p>${output.message}</p>
<p style="color: red;">${hint}</p>
<h5>${output.version}</h5>
<code style="color: red; white-space: pre;">
${output.fileName} ${output.lineNumber} ${output.lineNumber}
${output.stack}
</code>
</div>
  `
  //-------START error-template.html
        output.detailed = `
        <html>
        <head>
          <script type="text/javascript">
            const readState = () => {
              let block = document.getElementById('state')
              let data = block.innerText
      
              const a = document.createElement('a');
              const blobData = new Blob([data.trim()], { type: 'text/json' })
              const blobUrl = URL.createObjectURL(blobData)
              a.href = blobUrl;
              a.download = name + 'bug-report.error.la';
              a.innerHTML = 'download TEMP';
      
              const container = document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }  
          </script>
          <style type="text/css">
            body {
              background-color: black;
              color: #fff;
            }
      
            code {
              white-space: pre;
              background: #333;
              padding: 20px;
              border: 1px solid #888;
              font-family: monospace;
              display:block;
            }
      
            img.screen-shot {
              min-width: 400px;
              min-height: 300px;
              max-width: 800px;
              border: 1px solid blue;
            }
      
            .center {
              max-width: 900px;
              margin:0 auto;
            }
          </style>
        </head>
        <body>
          <div class="center">
            <h1>Light Assistant Error Report</h1>
            
            <h2>Name</h2>
            <code id="name">
              ${output.name}
            </code>
            
            <h2>Message</h2>
            <code id="message">
              ${output.message}
            </code>
            
            <h2>Hint</h2>
            <code id="hint">
              ${hint}
            </code>
      
            <h2>Version</h2>
            <code id="version">
              ${output.version}
            </code>
      
            <h2>Location</h2>
            <code id="location">
              ${output.fileName} ${output.lineNumber} ${output.lineNumber}
            </code>
      
            <h2>Stack Trace</h2>
            <code id="stack">
              ${output.stack}
            </code>
        
            <h2>Screen Shot</h2>
            <img src="${output.screenShot}" class="screen-shot"/>
      
            <h2>User State</h2>
            <a onClick="readState();">Download State</a>
            <code id="state">
              ${JSON.stringify(output.state, null, 2)}
            </code>
          </body>
          </div>
         
      </html>
  `
  //-------_END error-template.html
        if(onComplete) {
          onComplete(output)
        } else {
          console.log(output)
        }
      }
    )
  }

  //Process strings...
  if(!(_error instanceof Error)) {
    try {
      throw new Error(''+_error)
    } catch (p) {
      reportIt(p)
    }
  } else {
    reportIt(_error)
  }

}

export const mailError = (error, hint) => {
  return dispatch => {

    const onComplete = (processed) => {
      const copy = {
        ...processed
      }
      delete copy.error
      //FIXME we appear to be limited to 2MB here.
      try {

        let mailer = document.createElement('a')
        const subject = 'Lightassistant | Error Report'
        const body = 
`
Please delete this information if you do not wish me to know it. This is simply so I can respond when it is fixed.
The system is currently does not store information server-side as per our privacy policy.

Error --------------\r\n' 
${copy.simple}
Your Application State

The informaiton in the ERROR REPORT may contain personal information. I value your 
privacy, please send this information if you wish to help resolve the error in a detailed
manner. If you are concerned about your privacy the simple error message above may contain
enough information to trace the error. It contains no data you have entered, only details about where it occurs.

--------------------START ERROR REPORT

--------------------END ERROR REPORT
`          
// 0
        const msg = 'mailto:chapman@danielbchapman.com?subject=' + encodeURI(subject)+ '&body=' + encodeURI(body)
        mailer.href = msg
        mailer.click()
      } catch(err) {
        alert(`unable to generate email: + ${err}`)
        console.log(err)
      }
    }
    dispatch( tryReportError(error, hint, onComplete) )
  }
}
//** Dispatched the error reporter and tries to download a JSON file representing the error */
export const downloadError = (error, hint) => {
  return dispatch => {

    const onComplete = (processed) => {
      const copy = {
        ...processed
      }
      delete copy.error
      //FIXME we appear to be limited to 2MB here.
      try {
        const a = document.createElement('a')
        const blobData = new Blob([copy.detailed], { type: 'text/html' })
        const blobUrl = URL.createObjectURL(blobData)
        a.href = blobUrl;
        a.download = 'lightassistant.error.html'
        a.innerHTML = 'download TEMP'
      
        const container = document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch(err) {
        alert(`unable to save file: + ${err}`)
        console.log(err)
      }
    }
    dispatch( tryReportError(error, hint, onComplete) )
  }
}

export const sendErrorToServer = (processed, onTick, onComplete) => {

}
export const restoreStateFromFile = (json) => {
  const full = JSON.parse(json).state
  if(!full) {
    window.REDUX_STORE.dispatch({
      type: 'BUG_REPLACE_STATE',
      replacement: state,
    })
  }
}

export default {
  tryReportError,
  mailError,
  downloadError,
}