import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import upsert from 'pouchdb-upsert'
import upsertBulk from './patches/pouchdb-upsert-bulk'


console.log('----------------------------------------');
console.log('PouchDB');
console.log('----------------------------------------');
console.log('PouchDB', PouchDB)
console.log('PouchDBFind', PouchDBFind)
console.log('PouchDBUpsert', upsert)
console.log('PouchDBUpsertBulk', upsertBulk)
console.log('----------------------------------------');

PouchDB.plugin(PouchDBFind)
PouchDB.plugin(upsert)
PouchDB.plugin(upsertBulk)

let _db = null;
let _current = 'offline';

const getInstance = ()=>{
	if(!_db){
		if(!_current){
			_current = 'offline';
		}
		_db = new PouchDB(_current);
		//----------------------------------------
		// CREATE INDEXES
		//----------------------------------------

		/*'type' the universal discriminator for this DB*/		
		_db.createIndex({
			fields: ['type']
		}).then(result =>{
			//console.log('type index created');
		}).catch(error =>{
			console.log(error);
		});

		//----------------------------------------
		// CUE INDECIES
		//----------------------------------------
		//Find by Type and Next ID
		_db.createIndex({
			fields: ['type', 'next']
		}).then(result=>{
			//console.log(result);
		}).catch(error=>{
			console.log(error);
		});

		//----------------------------------------
		// SETTINGS INDECIES
		//----------------------------------------
		//Others as needed...
	}
	return _db;
};

export default {
	db : ()=>{
		return getInstance();
	},
	current: ()=>{ 
		return _current; 
	},
	change: (user, database)=>{
		getInstance(); //--load the DB
		return new Promise((resolve, reject)=>{
			if(_db){
				_db.destroy()
					.then(x=>{
						_db = null;
						_current = database;
						resolve(getInstance());
					})
					.catch(err => {
						console.log('[CRITICAL] db not destroyed');
						reject(err);
					});
			}
		});
	}
};