import React from 'react'
import { connect } from 'react-redux'
import { 
  instruments as Strings,
  general,
} from './../../shared/language'
import TextFieldRef from './../../components/TextFieldRef'

import { 
  Button, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import {
  createWorksheet
} from './../reducers/WorksheetsLogic'
class AddWorksheetDialog extends React.Component {

  render(){
    const { isOpen, onClose, onCreateCallback } = this.props
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>
          { Strings.get('createWorksheetTitle')}  
        </DialogTitle>

        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: Strings.get('createWorksheetDescription') }}></div>  

          <TextFieldRef
            autoFocus={true}
            label={Strings.get('worksheetName')}
            helperText={ Strings.get('createWorksheetHelper') }
            ref='createWorksheet'
          />
        </DialogContent>

        <DialogActions>

          <Button
            onClick={e => {
              if(onClose) {
                onClose()
              }
            }}
          >
            { general.get('cancel') }
          </Button>

          <Button
            color='secondary'
            onClick={e => {
              const text = this.refs['createWorksheet']
              const val = text.getValue()
              text.clear()

              this.props.dispatch( createWorksheet(val, onCreateCallback) )
              onClose()
            }}
          >
            { Strings.get('addWorksheet') }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(s=>{
  return {}
})(AddWorksheetDialog)