export const REPORTS_ADD_FILTER = 'REPORTS_ADD_FILTER'
export const REPORTS_UPDATE_FILTER = 'REPORTS_UPDATE_FILTER'
export const REPORTS_DELETE_FILTER = 'REPORTS_DELETE_FILTER'

export const REPORTS_ADD_QUERY = 'REPORTS_ADD_QUERY'
export const REPORTS_UPDATE_QUERY = 'REPORTS_UPDATE_QUERY'
export const REPORTS_DELETE_QUERY = 'REPORTS_DELETE_QUERY'

export const REPORTS_UPDATE_PDF = 'REPORTS_UPDATE_PDF'
export const REPORTS_DELETE_PDF = 'REPORTS_DELETE_PDF'

export const REPORTS_UPDATE_EXCEL = 'REPORTS_UPDATE_EXCEL'
export const REPORTS_DELETE_EXCEL = 'REPORTS_DELETE_EXCEL'

export const FilterConditions = Object.freeze({
  //we can revisit these later
  // LESS_THAN: 'lessThan',
  // GREATER_THAN: 'greaterThan',
  EQUAL_TO: 'equalTo',
  NOT_EQUAL_TO: 'notEqualTo',
  INCLUDES: 'includes',
  EXCLUDES: 'exludes',
})

export const loadReportFromWorksheet = (worksheet, name) => {
  if(!name) {
    name = 'Copy of ' + worksheet.name 
  }
  throw new Error('Not Implemented ReportsLogic::loadReportFromWorksheet')
  const report = {}
  return dispatch => {
    dispatch( createReport(report) )
  }
  
}

export const addUpdatePdfReport = (report, index = -1) => {
  return dispatch => dispatch({
    type: REPORTS_UPDATE_PDF,
    index: index,
    report: report,
  })
}

export const addReportFilter = (name = 'New Filter') => {
  return dispatch => {
    dispatch({
      type: REPORTS_ADD_FILTER,
      filter: {
        name: name,
      }
    })
  }
}

export const deleteReport = (index = -1) => {
  return dispatch => {
    dispatch({
      type: REPORTS_DELETE_PDF,
      index: index
    })
  }
}
const INITIAL_STATE = {
  excel: [],
  filters: [],
  queries: [],
  pdf: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //FILTERS
    case REPORTS_ADD_FILTER: {
      const copy = {
        ...state
      }
      copy.filters = copy.filters.slice()
      copy.filters.push({
        ...action.filter
      })
      return copy
    }

    //QUERY
    case REPORTS_ADD_QUERY: {
      const copy = {
        ...state
      }
      copy.queries = copy.queries.slice()
      copy.queries.push({
        ...action.query
      })
      return copy
    }
    //PDF
    case REPORTS_UPDATE_PDF: {
      const copy = {
        ...state
      }
      const index = action.index

      if(index < 0) {
        copy.pdf = copy.pdf.slice()
        copy.pdf.push({
          ...action.report
        })
        return copy
      } else {
        copy.pdf = copy.pdf.slice()
        copy.pdf[index] = {
          ...action.report
        }
        return copy
      }

    }
    //EXCEL
    case REPORTS_UPDATE_EXCEL: {
      const copy = {
        ...state
      }
      const index = action.index

      if(index < 0) {
        copy.excel = copy.excel.slice()
        copy.excel.push({
          ...action.report
        })
        return copy
      } else {
        copy.excel = copy.excel.slice()
        copy.excel[index] = {
          ...action.report
        }
        return copy
      }
    }

    case REPORTS_DELETE_PDF: {
      if(action.index < 0) {
        return state
      }
      const copy = {
        ...state
      }
      copy.pdf = copy.pdf.slice()
      copy.pdf.splice(action.index, 1)
      return copy
    }

  }

  return state
}