
import React from 'react'
import { connect } from 'react-redux'
import translate from './../../shared/language/Translate'
import {
  isConnected,
} from '../reducers/ElectronLogic'

import { formatUnicorn } from './../../shared/StringUtil'
import {
  Button
} from '@material-ui/core'
import { Block } from '@material-ui/icons'
class FloatingOSCTracker extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    //Base style overrides for absolute positioning etc...
    const { style, oscOn, oscList, oscCue, eosHost, eosOscVersion } = this.props
    const T = this.props.strings

    const live = ()=> {
      return(
      <div
        style={{
          ...style,
        }}
      >
      
        <ul>
          <li>{this.props.oscCue}</li>
          <li>{this.props.oscList}</li>
          <li>{this.props.oscOn}</li>
        </ul>
      </div>
      )
    }

    const onLight = (on) => {
      const base = {
        maxWidth: 12,
        maxHeight: 12,
        minWidth: 12,
        minHeight: 12,
        margin: 4,
        display: 'inline-block',
        verticalAlign: 'middle',
        borderRadius: 99,
        border: '1px solid gray',
      }

      if(on) {
        return (
          <span style={{
            ...base,
            backgroundColor: 'green'
          } }>
          </span>
        )
      } else {
        return (
          <span style={{
            ...base,
            backgroundColor: 'red'
          } }>
          </span>
        )
      }
    }
    const pair = (label, data) => {
      return (
        <React.Fragment>
          { label }&nbsp;<b>{data}</b> &nbsp;&nbsp;
        </React.Fragment>
      )
    }

    const baseStyle = {
      ...style,
      display: 'flex',
      flexDirection: 'row',
      justify: 'flexEnd',
      alignItems: 'center',
      fontFamily: 'monospace',
      fontSize: '1rem',
    }

    const webClient = () => {
      return (
        <div style={ baseStyle }>
          { pair(T.get('offline'), onLight(false) ) }
          { pair(T.get('web'), T.get('requiresApp') ) }
        </div>
      )
    }
    const offline = () => {
      return (
        <div style={ baseStyle }>
          {/* { T.get('eosOscAndVersion', { eosHost, eosOscVersion } ) } */}
          { pair(T.get('offline'), onLight(false) ) }
          {/* { pair(T.get('cue'), `--/----` ) } */}
        </div>
      )
    }

    const online = () => {
      return (
        <div style={ baseStyle }>
          { pair(T.get('online'), onLight(true) ) }
          {/* 
          { T.get('eosOscAndVersion', { eosHost, eosOscVersion } ) }
          */}
          { pair(T.get('cue'), `${oscList}/${oscCue}` ) } 
          <span 
            id='OSC_STATUS_LINE'
            className='osc-status-text'
            style={{
              display: 'inline',
              overflow:' hidden',
              whiteSpace: 'nowrap',
              maxWidth: 200,
              textOverflow: 'ellipsis',
            }}
          >
            {this.props.oscStatus || ''}
          </span>
        </div>
      )
    }

    if(!isConnected()) {
      return webClient()
    }

    return oscOn ? online() : offline()
  }
}

export default connect(s => {
  return {
    //OSC Tracking
    oscCue: s.osc.cue,
    oscList: s.osc.list,
    oscOn: !!s.osc.on,
    eosOscVersion: s.osc.eosOscVersion,
    eosHost: s.osc.eosHost,
    oscStatus: s.osc.status,
  }
})(translate('OSC')(FloatingOSCTracker) )