import { 
  PAPER_US_LETTER_PORTRAIT,
} from './../../../shared/printing/GenericReport'

export default {
  ...PAPER_US_LETTER_PORTRAIT,
  name: 'My New Notes',
  data: 'notes',
  group: [],
  sort: [],
  filters: [],
  headerEmpty: 'N/C',
  header: [
    {
      logo: 'show',
      posX: 0,
      posY: 0,
      width: 300,
      height: 50,//we only use the height
    },
    {
      text: '{show}',
      posX: 0,
      posY: 0,
      width: PAPER_US_LETTER_PORTRAIT.width,
      fontSize: 16,
      font: 'Helvetica-Bold',
      align: 'center',
      wrap: false,

    }, {
      text: 'My New Report',
      posX: 0,
      posY: 18,
      width: PAPER_US_LETTER_PORTRAIT.width,
//          color: '#ffaa00',
      fontSize: 16,
      font: 'Helvetica-Bold',
      align: 'center',
      wrap: false,
    }
  ],
  heading: {
    text: '{position}',
    font: 'Helvetica-Bold',
    fontSize: 14,
    posX: 0,
    posY: 0,
    height: 20, 
  },
  formatting: {
    seperator: 'dash',
    start: 'none',
    end: 'none',
    vertical: 'solid',
    rowLineColor: '#ddd',
    seperatorColor: '#ccc',
  },
  subheading: {
    font: 'Helvetica',
    fontSize: 10,
    fill: 'white',
    color: 'black',
    wrap: 'true',
  },
  people: {
    disable: false,
    align: 'left',
    font: 'Helvetica-Bold',
    posX: 400,
    posY: 0,
  },
  footer: [],
  //IMPORTED
  columns: [ 
    {          
      key: 'position',
      width: 64,
      fontSize: 10,
      align: 'left',
      label: 'Position',
    }, {          
      key: 'unit',
      width: 24,
      fontSize: 10,
      align: 'right',
      label: '#',
    }, {
      key: 'channel',
      width: 32,
      fontSize: 10,
      align: 'center',
      format: '({channel})',
      label: '(Ch.)',
    }, {
      key: 'address',
      width: 32,
      fontSize: 10,
      align: 'left',
      label: 'Adr.',
      formatter: (_, inst) => {
        const u = inst.universe
        const dim = inst.dimmer
        const address = inst.address
        let base =''

        if(u) {
          base = ''+u
          base.trim()
          base += '/'
        }

        if(!address && !dim) {
          return base
        }

        if(address) {
          return base + address
        }

        if(dim) {
          return base + dim
        }
        
        return base + '-'
      },
    }, {
      key: 'circuit',
      width: 32,
      fontSize: 10,
      align: 'left',
      label: 'Cir.',
      formatter: (_, inst) => {
        const name = inst.circuitName || ''
        const num = inst.circuitNumber || ''
        return name + num
      }   
    }, {
      key: '__instTypeAccessories',
      width: 180,
      fontSize: 10,
      align: 'left',  
      format: '{instTypeAccessories}',
      label: 'Instrument Tyoe & Accessories',
    }, {
      key: 'wattage',
      width: 56,
      fontSize: 10,
      align: 'left',
      label: 'Wattage',
    }, {
      key: 'colorTemplate',
      width: 76,
      fontSize: 10,
      font: 'Helvetica',
      align: 'left',
      format: '{colorAndTemplate}',
      label: 'Color & Temp.',
    }, {
      key: 'purpose',
      width: 80,
      fontSize: 10,
      font: 'Helvetica',
      align: 'left',
      label: 'Purpose',
    },
  ],
  columnsOptional: [
    {
      width: 16,
      type: 'blank',
    },
    {
      key: 'purpose',
      format: '  ROUNDED: {purpose}',
      width: 150,
      fontSize: 10,
      font: 'Helvetica',
      align: 'left',
      container: 'roundedRectangle',
    },
    {
      width:16, 
      type: 'blank'
    },
    {
      key: 'purpose',
      format: '  RECTANGLE: {purpose}',
      width: 150,
      fontSize: 10,
      font: 'Helvetica',
      align: 'left',
      container: 'rectangle',
    },
  ],
  labels: {
    position: 'Position',
    unitNumber: '#',
    channel: '(Ch)',
    address: 'Dim',
    circuit: 'Cir.',
    __instTypeAccessories: 'Instrument Type & Accessories',
    wattage: 'Wattage',
    colorTemplate: 'Color & Template',
    purpose: 'Purpose',
  }      
}