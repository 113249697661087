import React from 'react'

const CardSeries = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      { props.children }
    </div>
  )
}
export default CardSeries