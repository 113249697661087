//import PDFDocument from 'pdfkit'
import { joinStrings, formatUnicorn, isEmptyOrWhitespace } from './../StringUtil'
import { num } from './../Utility'
import { Translator} from './../language/Translate'
import { createPositionList } from '../ReportHelpers'
import {
  readAccessories
} from './../vectorworks'

import moment from 'moment'
import { line } from './GenericReport'

export const DEFAULT_FONT = 'Helvetica'
export const DEFAULT_BOLD = 'Helvetica-Bold'

import {
  ReportCommon,
  Align,
  Seperator,
  ReportContainers,
} from './ReportConstants'
/**
 * Creates a generic report from the _settings object. This allows completely custom reports
 * from the user.
 * 
 * @param {Array of Cue Objects} cues the cues to write
 * @param {Stream} stream the stream to write to
 * @param {Object} _settings an object of settings, if null a default setting is used
 * {
 *   columns: [
 *    {
 *      key: propertyName, //the key to write
 *      width: 96, //number in points
 *      wrap: true | false | default = true
 *    }
 *    , ...
 *   ],
 * }
 * All header elements are formatted with the following object {
 * }
 * @returns a Promise that resolves in a PDF document
 */
export const reportGenericMultiline = (dataSet, stream, _settings, show) => {

  const _time = moment()
  const VARIABLES = {
    show: show.currentShow || 'Unknow Show',
    //TIME
    LLLL: _time.format('LLLL'),
    YYYY: _time.format('YYYY'),
    YY: _time.format('YY'),
    Q: _time.format('Q'),
    M: _time.format('M'),
    MM: _time.format('MM'),
    MMM: _time.format('MMM'),
    MMMM: _time.format('MMMM'),
    month: _time.format('month'),
    D: _time.format('D'),
    DD: _time.format('DD'),
    Do: _time.format('Do'),
    DDD: _time.format('DDD'),
    X: _time.format('X'),
    day: _time.format('dddd'),
    dddd: _time.format('dddd'),
    H: _time.format('H'),
    HH: _time.format('HH'),
    h: _time.format('h'),
    hh: _time.format('hh'),
    a: _time.format('a'),
    am: _time.format('a'),
    mm: _time.format('mm'),
    minutes: _time.format('mm'),
    ss: _time.format('ss'),
    seconds: _time.format('ss'),
    ZZ: _time.format('ZZ'),
    date: _time.format('MMMM Do YYYY'),
    time: _time.format('hh:mm:ss a'),
		shortDate: _time.format('YYYY-MM-DD'),
		...show,
  }
  VARIABLES.paperworkDate = formatUnicorn(show.paperworkDate || '{day}, {date} {time}', VARIABLES)
  
  const showTitle = show.currentShow || 'Unknown Show'
  const positions = createPositionList(show)

  const code = _settings ? _settings.language : 'en'
  const lang = Translator(code || 'en', 'Instruments')
  const INSTRUMENT = 'instrument'
  const NOTE = 'note'

  // const DEFAULT_FONT = 'Helvetica'
  // const DEFAULT_BOLD = 'Helvetica-Bold'
  const DEFAULT_FONT_SIZE = 10

	const PAGE_SIZE = [ //default to letter
		(_settings.pageSize || {}).width || 612,
		(_settings.pageSize || {}).height || 792,
	]
  return new Promise( (resolve, reject) => {

    try {
      let doc = new PDFDocument({
				bufferPages: true,
				size: PAGE_SIZE
      })

      //register fonts
      if(window.FONTS) {
        for(let font of Object.keys(window.FONTS)) {
          doc.registerFont(font, window.FONTS[font])
        }
      }

      doc.fontSize(12)
      doc.font(DEFAULT_FONT)
      //console.log(stream)
      let output = doc.pipe(stream)
  
      //468 on normal margins
      // Types -> none || dash || solid
      let defaultSettings = {
        formatting: {
					seperator: 'dash',
					start: 's',
					end: 'none',
          vertical: 'solid',
          rowLineColor: '#ddd',
          seperatorColor: '#ccc',
				},
        header: [
          //Add default header here using show title
        ],
        footer: [
          {
            text: 'Page # {page} of {pages}',
            align: 'center',
            font: DEFAULT_FONT,
            wrap: false,
            posX: 0,
            posY: 20,
          }
        ],
        people: {
        },
        defaultEmpty: '-',
        lineSpacing: 2,
        stripe: true,
        stripeColor: '#f4f4f4',
        headerColor: show.color || '#0D47A1',
        headerTextColor: null,
        bodyTextColor: null,
        show: {
          title: showTitle
        },
        positions: positions,
        subheading: {
          font: DEFAULT_FONT,
          fontSize: 10,
        },
        columns: [ 
          {
            key: 'channel',
            width: 32,
            fontSize: 10,
            align: 'center',
            formatter: (num) => {
              if(num === undefined || num === null || num.length < 1) {
                return ''
              }
              
              return '(' + num + ')'
            },
          }, {
            key: 'address',
            width: 32,
            fontSize: 10,
            align: 'left',
            formatter: (_, inst) => {
              const u = inst.universe
              const dim = inst.dimmer
              const address = inst.address
              let base =''

              if(u) {
                base = ''+u
                base.trim()
                base += '/'
              }

              if(!address && !dim) {
                return base
              }

              if(address) {
                return base + address
              }

              if(dim) {
                return base + dim
              }
              
              return base + '-'
            },
          }, {
            key: 'circuit',
            width: 32,
            fontSize: 10,
            align: 'left',
            formatter: (_, inst) => {
              const name = inst.circuitName || ''
              const num = inst.circuitNumber || ''
              return name + num
            }   
          }, {
            key: '__instTypeAccessories',
            width: 180,
            fontSize: 10,
            align: 'left',  
            formatter: (_, inst) => {
              const type = inst.instrumentType || ''
              const accessories = readAccessories(inst) || ''
              return type + ' ' + accessories
            }   
          }, {
            key: 'wattage',
            width: 56,
            fontSize: 10,
            align: 'left',  
          }, {
            key: 'color',
            width: 64,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
            formatter: (_, inst) => {
              const color = inst.color
              const frost = inst.frost
              const userField1 = inst.userField1
              return joinStrings(color, frost, userField1)
            }
          }, {
            key: 'template',
            width: 64,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
            formatter: (_, inst) => {
              const template = inst.template
              const template2 = inst.template2

              return joinStrings(template, template2)
            }
          }, {
            key: 'purpose',
            width: 80,
            fontSize: 10,
            font: 'Helvetica',
            align: 'left',
          }, {          
            key: 'unit',
            width: 24,
            fontSize: 10,
            align: 'center'
          }, 
        ]
      }
      
      /**
       * Warning, this function mutates the document object 
       * and could, potentially, cause drawing failures.
       * 
       * @param {*} text 
       * @param {*} width 
       * @param {*} size 
       * @param {*} font 
       */
      const calcTextSize = (text, width, size, font) => {
        doc.save()
        const out = doc
          .font(font || DEFAULT_FONT)
          .fontSize(size || DEFAULT_FONT_SIZE)
          .heightOfString(text, { width: width })

        doc.restore()
        return out + 1
      }
      //Process headers from settings


      //Calculated Variables after processing settings
      let _calcHeaderHeight = 10
      let _calcFooterHeight = 20

      const settings = Object.assign({}, defaultSettings, _settings)
      const people = settings.people || {}
      const headings = settings.heading || { text: 'Unlabeled Section' }

      //Assign defaults for bad data
      if(settings.columns) {
        settings.columns.forEach(c => {
          c.width = num(c.width, 64)
        })
      }

      if(settings.columnsOptional) {
        settings.columnsOptional.forEach(c => {
          c.width = num(c.width, 64)
        })
      }

      const pageSize = { 
				width: PAGE_SIZE.width,
				height: PAGE_SIZE.height,
			}

      const position = {x: doc.x, y: doc.y}

      const margins = { //18 is the minimum standard we can expect a printer to use
        top: 18,
        left: 18,
        right: 18,
        bottom: 18.
      }

      const availableSize = { 
        width: PAGE_SIZE[0] - margins.left - margins.right, 
        height: PAGE_SIZE[1] - margins.top - margins.bottom
      }

      //CALCULATE SUB_SIZE
      //WE DON'T SHOW A HEADER IF THERE'S NO GROUPS
      const HEADER_SIZE = !settings.heading ? 0 : num( (settings.heading.height || {}).height, 20) //20 unless specified
      const HEADER_SUB_SIZE = (()=>{
        let result = 12

        if(settings.subheading.wrap === false) { //just do one line...
          const simpleHeight = calcTextSize('ApqRTQZJj', 700, settings.subheading.fontSize, settings.subheading.font)
          return Math.round( Math.max(simpleHeight, 12) )
        }
        //Defaults to wrapping
        for(let col of settings.columns) {
          const rowHeight = calcTextSize(col.label || col.key, col.width, settings.subheading.fontSize, settings.subheading.font)
          result = Math.max(result, rowHeight)
        }

        return Math.round( Math.max(result, 12) )
      })()

      const HEADER_TOTAL = HEADER_SIZE + HEADER_SUB_SIZE //+ settings.lineSpacing
      //Process the header to figure out the size
      for(const element of settings.header) {
        if(element.logo && show.logo) {
          //Process the image height
          const _height = num(element.posY) + num(element.height)
          if(_calcHeaderHeight < _height) {
            _calcHeaderHeight = _height
          }
        } else {
          const text = element
          const _actual = formatUnicorn(text.text, VARIABLES)
          const _width = text.wrap ? text.width : doc.page.width
          let _height = text.posY + calcTextSize(_actual, _width, text.fontSize || 20, text.font || DEFAULT_BOLD)
  
          if(_calcHeaderHeight < _height) {
            _calcHeaderHeight = _height
          }
        }
      }

      for( let text of settings.footer) {
        const _width = text.wrap ? text.width : doc.page.width
        const _actual = formatUnicorn(text.text, VARIABLES)
        let _height = text.posY + calcTextSize(_actual, _width, text.fontSize || 20, text.font || DEFAULT_BOLD)

        if(_calcFooterHeight < _height) {
          _calcFooterHeight = _height
        }
      }
      
      //Process the list of positions on the top of the page
      if(!people.disable) {
        const people = settings.people
        let _fontSize = num(people.fontSize || DEFAULT_FONT_SIZE)
        let _offsetY = num(people.posY || 0) + 5

        for(let p of positions) {
          const _h = calcTextSize(p, 9999, _fontSize, people.font || DEFAULT_FONT)
          _offsetY += _h
        }

        if(_offsetY > _calcHeaderHeight) {
          _calcHeaderHeight = _offsetY
        }
      }

      //Process the footer height

      //#endRegion

      const columns = settings.columns
      const columnsOptional = settings.columnsOptional

      const bodySize = {
        width: availableSize.width,
        height: availableSize.height - _calcHeaderHeight - _calcFooterHeight,
        start: _calcHeaderHeight || 0
      }

      const lineSpacing = settings.lineSpacing || 2
      //console.log(doc)
      // console.log(`margins -> ${JSON.stringify(margins)}`)
      // console.log(`size -> ${JSON.stringify(pageSize)}`)
      // console.log(`position -> ${JSON.stringify(position)}`)
      // console.log(`available -> ${JSON.stringify(availableSize)}`)

      // let sections = []
      // let section = null
      let logicalSections = []
      let logicalSection = null

      const newSection = () => {
        logicalSection = {
          height: _calcHeaderHeight + HEADER_SUB_SIZE + lineSpacing, //minimum
          header: null,
          title: 'pending',
          rows: [],
        }
        logicalSections.push(logicalSection)
      }

      const addRow = (type, data, height, height2 = 0) => {
        //Layout guides
        logicalSection.rows.push({
          type: type,
          data: data,
          height: height,
          height2: height2,
          totalHeight: height + height2,
        })

        logicalSection.height += height + lineSpacing
      }

      newSection('showNameTbd')

      /**
      @param x<Any>: the value of the key for this row
      @param data: the row object
      @param column: the column object (looks for "format")
      */
      const defaultFormatter = (x, data, column) =>{ 
        if(column && column.virtual && column.format) {
          return formatUnicorn(column.format, data)
        }
  
        if ( isEmptyOrWhitespace(x) ) {
          return ''
        } else if (column && column.format) {
          return formatUnicorn(column.format, data)
        } else {
          return formatUnicorn(x, data)
        }
      }

      //FIXME -> This is where we need genric "grouping" logic
      let lastInstrument = null
      let firstInstrument = null
      
      /**
       * Return true if this should start a new section
       * @param {*} _old 
       * @param {*} _new 
       */
      const shouldMakeNewSection = (_old, _new) => { //returns true/false
        if(!settings.group || !settings.group[0]) {
          return false //we assume no sections
        }

        for(let field of settings.group) {
          if(_old[field] != _new[field]) {
            console.log('=================NEW SECTION ' + _new[field] + ' was: ' + _old[field])
            return true
          }
        }
        return false
      }    
      
      const formatSectionTitle = (_section, _first, _last) => {
        if(!_first) {
          _first = {}
        }

        if(!_last) {
          _last = {}
        }

        const _vars = {
          ...VARIABLES,
          ..._first,
        }

        for( let key of Object.keys(_last) ) {
          _vars['last.' + key] = _last[key]
        }

        _section.title = formatUnicorn(headings.text, _vars)
      }

      //CALCULATE ALL THE ROW HEIGHTS HERE.
      for (let dataIndex = 0; dataIndex < dataSet.length; dataIndex++) {
        const inst = dataSet[dataIndex]
        let rowHeight = 0 //maximum vertical space for this cue
        let rowHeight2 = 0

        //FIRST ELEMENT which is formatted using element[0]
        if(dataIndex === 0) {
          firstInstrument = inst

        //START NEW SECTION, the above check starts a new section            
        } else if ( shouldMakeNewSection(lastInstrument, inst) ) {

          formatSectionTitle( logicalSection, firstInstrument, lastInstrument )
          newSection( )
          firstInstrument = inst
          lastInstrument = inst

        //LAST ELEMENT, FORMAT THE SECTION
        } else if (dataIndex >= dataSet.length - 1) {
          formatSectionTitle( logicalSection, firstInstrument, inst )
        }

        lastInstrument = inst
        //Header Portion
          //Not implemented
          //Images
        let fakeCue = ''
        //Basic Cue Information
        for(let i = 0; i < columns.length; i++) {
          const col = columns[i]
          let formatter = col.formatter || defaultFormatter
          let key = col.key
          let val = formatter(inst[key], inst, col)
          fakeCue += ' ' + val
          const textHeight = calcTextSize(val, columns[i].width, col.fontSize, col.font)
          rowHeight = Math.max(rowHeight, textHeight)
        }

        if(columnsOptional) {
          for(let i = 0; i < columnsOptional.length; i++) {
            const col = columnsOptional[i]
            let formatter = col.formatter || defaultFormatter
            let key = col.key
            let val = formatter(inst[key], inst, col)
            fakeCue += ' ' + val
            const textHeight = calcTextSize(val, col.width, col.fontSize, col.font)
            rowHeight2 = Math.max(rowHeight2, textHeight)
          }
        }

//         START HERE, we need to generate a "last" option here if this exceeds the page size
// and start a new section with a "(cont...)" labbel so that it properly formats the titles.
// If we don't do this the "frist last" needs to be recalulated.
//START HERE
//Basically what we need to do here is preprocess the section breaks

        fakeCue += ' | height -> ' + rowHeight + rowHeight2
        //FIXME add the other parts as needed
        addRow(INSTRUMENT, inst, rowHeight, rowHeight2)
        console.log(fakeCue)
        //Notes portion
          //Not implemented
        //Add pagination logic
        //console.log('Cue -> ' + cues[cue])
      }
      
      console.log(`Sections ${logicalSections.length}`)
      for(let i = 0; i < logicalSections.length; i++) {
        console.log(`\tSection: ${logicalSections[i].title} Rows: ${logicalSections[i].rows.length} Height: ${logicalSections[i].height} Pages: ${logicalSections[i].height / bodySize.height}`)
      }

      //----------------------------------
      // PREPROCESS THE PDF HERE (Determine Page Data / Breaks)
      //----------------------------------

      //TRACKING PAGES
      let deltaY = 0
      let pages = []
      let pageData = {
        sections: []
      }
      let subSection = null

      const preprocessNewPage = () => {
        pageData = {
          sections:[]
        }
        pages.push(pageData)
        deltaY = 0
      }

      const preprocessNewSection = (continued) => {
        console.log('------------------------------------------ADDING NEW SECTION')
        if(subSection && continued) {
          subSection.spansMultiplePages = true
        }

        subSection = {
          height: HEADER_TOTAL + lineSpacing,
          first: null,
          last: null,
          continued: false,
          spansMultiplePages: false,
          rows: [],
        }

        pageData.sections.push(subSection)
        deltaY += HEADER_TOTAL + lineSpacing //placeholder for the header
      }

      const preprocessRow = (row) => {
        subSection.rows.push(row)
        const delta = row.totalHeight + lineSpacing
        deltaY += delta
        subSection.height += delta
      }

      //Start with a page
      preprocessNewPage()
    
      for(let s = 0; s < logicalSections.length; s++) {
        const logicalSection = logicalSections[s]
      
//--- Start new sections on a new page if they don't fit
        const measurement = logicalSection.height + deltaY + 20 //section padding

        //If this section spans over a page just start a new page, unless we're the first page
        if(s > 0 && measurement > bodySize.height) { 
          preprocessNewPage()
        } 
        
        preprocessNewSection(false)
//--- End Start new sections on a new page if they don't fit

        for(let i = 0; i < logicalSection.rows.length; i++) {
          let row = logicalSection.rows[i]
          const nextHeight = deltaY + row.totalHeight + lineSpacing

          if(nextHeight > bodySize.height) { //Do we need to wrap?
            preprocessNewPage()
            preprocessNewSection(true)//continued section
          }

          //Loop to keep looking at it
          preprocessRow(row)
        }
      }
      
      console.log('-----------------DEBUG SECTIONS')
      for(let i = 0; i < pages.length; i++) {
        console.log(`PAGE ${i}`)
        for(let s of pages[i].sections) {
          console.log(`\tSECTION ${s.header}`)
          for(let r of s.rows) {
            console.log(`\t\tROW: ${r}`)
          }
        }
        console.log('\n')
      }

      //----------------------------------
      // WRITE THE PDF
      //----------------------------------
      const writeSectionHeader = (text, x, y, width) => {
        const headerColor = settings.headerColor || 'red'
        const headerTextColor = settings.headerTextColor || 'white'
        const bodyTextColor = settings.bodyTextColor || 'black'
        doc
          .font(DEFAULT_BOLD)
          .fontSize(14)

        const titleHeight = doc.heightOfString('_Demoj', { 
            width: 400
          })

        let titleOffsetY = 0

        if(settings.heading) {
          titleOffsetY = (HEADER_SIZE - titleHeight) / 2 + 3
        }
        //console.log(`titleOffsetY: ${titleOffsetY}`)

        if(titleOffsetY > 0) {
          doc
            .rect(x, y, width, HEADER_SIZE)
            .fill(headerColor)

          doc
            .fillColor(headerTextColor)
            .font(DEFAULT_BOLD)
            .fontSize(14)
            .text(text || '-', x + 5, y + titleOffsetY, {
              width: width,
              ellipsis: true,
              lineBreak: !settings.subheading.wrap,
              align: 'left',
            })
        } else {
          //Write subtitles
          doc
            .rect(x + 12, y + HEADER_TOTAL - 2, width - 12, 1)
            .fill(headerColor)
        }

        if(settings.subheading.fill) {
          doc
            .rect(x, y + HEADER_SIZE, bodySize.width, HEADER_SUB_SIZE)
            .fill(settings.subheading.fill)
        }

        let dX = 0

        if(true) { //intentional warning, this is to draw a line
          const lineY = y + HEADER_SIZE + HEADER_SUB_SIZE 
          doc.save()
          doc
            .lineCap('butt')
            .lineWidth(1)
            .moveTo(0, lineY)
            .lineTo(bodySize.width, lineY)
            .stroke(settings.headerColor || 'black')
          doc.restore()
        } 

        for(let c = 0; c < columns.length; c++) {
          let col = columns[c]

          const dY = y + HEADER_SIZE
          const color = settings.subheading && settings.subheading.color || 'black'
          const outerColor = 'black'
          if(c === 0 && settings.formatting && settings.formatting.start === 'solid') {
            //const { x, y, width, height, stroke = 'black', lineWidth = 1, cap = 'butt'} = el
            doc.save()
      
            doc
              .lineCap('butt')
              .lineWidth(1)
              .moveTo(dX, dY)
              .lineTo(dX, dY + HEADER_SUB_SIZE)
              .stroke(outerColor)
          
            doc.restore()
          }

          if(c === columns.length - 1 && settings.formatting.end === 'solid') {
            doc.save()
          
            doc
              .lineCap('butt')
              .lineWidth(1)
              .moveTo(bodySize.width, dY)
              .lineTo(bodySize.width, dY + HEADER_SUB_SIZE)
              .stroke(outerColor)
          
            doc.restore()
          }

          //Dashed
          if(c > 0 && c < columns.length && settings.formatting.seperator === 'dash') {
            doc.save()
            doc
              .lineCap('butt')
              .lineWidth(1)
              .moveTo(dX, dY)
              .lineTo(dX, dY + HEADER_SUB_SIZE)
              .dash(1, 2)
              .stroke(color)
            
            doc.restore()
          }

          if(c > 0 && c < columns.length && settings.formatting.seperator === 'solid') {
            doc.save()
            doc
              .lineCap('butt')
              .lineWidth(1)
              .moveTo(dX, dY)
              .lineTo(dX, dY + HEADER_SUB_SIZE)
              .stroke(color)
            
            doc.restore()
          }

          doc
            .fillColor(settings.subheading.color || 'black',)
            .font(settings.subheading.font || col.font || DEFAULT_BOLD)
            .fontSize(settings.subheading.fontSize || DEFAULT_FONT_SIZE)
            .text( col.label || col.key, dX, y + HEADER_SIZE + settings.lineSpacing, {
              width: col.width,
              height: HEADER_SUB_SIZE,
              align: col.align || 'left',
            } )

          dX += col.width
        }
      }

      //Write pages
      let dY = 0
      doc.translate(margins.left, margins.top + (_calcHeaderHeight || 0)) //respect the margins
      doc.margins = margins
      doc.save()

      if(settings.coverPage) {
        const coverPage = settings.coverPage

        doc
        .fillColor(coverPage.color || 'black')
        .font(coverPage.font || DEFAULT_FONT)
        .fontSize(coverPage.fontSize || DEFAULT_FONT_SIZE)
        .text( coverPage.text, 64, HEADER_SIZE + 20, {
          width: PAGE_SIZE[0] - 64,
          align: coverPage.align || 'left',
        } )

        doc.restore()

        doc.addPage( {
          size: PAGE_SIZE,
          ...margins
        })

        //Create a new page for zero
        doc.translate(margins.left, margins.top + (_calcHeaderHeight || 0))
        doc.margins = margins
        doc.save()
      }

      //Write the page number, header, etc... 
      for(let p = 0; p < pages.length; p++) {
        //Create a new page, we already did the math...
        dY = 0

        if(p > 0) {
          doc.restore()
          doc.addPage( {
						size: PAGE_SIZE,
						...margins
					})
          doc.translate(margins.left, margins.top + (_calcHeaderHeight || 0))
          doc.margins = margins
          doc.save()
        }


        for(let s = 0; s < pages[p].sections.length; s++){

          let section = pages[p].sections[s]  
          const first = (section.rows[0] || {}).data
          const last = (section.rows[ section.rows.length - 1 ] || {}).data

          //Write header
          formatSectionTitle( section, first, last )
          writeSectionHeader( section.title, 0, dY, bodySize.width, 20)
          dY += HEADER_TOTAL + lineSpacing

          let stripes = 0
          
          for(let i = 0; i < section.rows.length; i++) {
            let row = section.rows[i]
            
            if (INSTRUMENT == row.type) { 
              stripes++ //color the row
              const isRowAlt = settings.stripe && stripes % 2 == 0
              let dX = 0
              
              const nextHeight = dY + row.totalHeight + lineSpacing
              if(nextHeight > bodySize.height) {
                console.log('\t\t\t---BODY HEIGHT EXCEEDED:~609')
                // stripes = 0
                // console.log(`
                // Breaking here for the following
                // dY: ${dY}
                // bodySize: ${JSON.stringify(bodySize)}
                // nextHeight: ${nextHeight}
                // `)
                // doc.restore()
                // doc.addPage()
                // doc.translate(margins.left, margins.top + (_calcHeaderHeight || 0)) 
                // doc.margins = margins
                // doc.save()
                // writeSectionHeader(section.title + ' (continued...)', 0, 0, bodySize.width)
                // dY = HEADER_TOTAL + lineSpacing
              }
    
              if(isRowAlt) {      
                doc.save()
                doc
                  .rect(0, dY - settings.lineSpacing, bodySize.width, row.totalHeight + settings.lineSpacing)
                  .fill(settings.stripeColor)
                doc.restore()
              }

              if(settings.formatting.vertical === 'solid') {
                doc.save()
                doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(0, dY + row.totalHeight)
                    .strokeColor(settings.formatting.rowLineColor || 'black')
                    .lineTo(bodySize.width, dY + row.totalHeight)
                    .stroke()
                doc.restore()
              }

              if(settings.formatting.vertical === 'dash') {
                doc.save()
                doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(0, dY + row.totalHeight)
                    .strokeColor(settings.formatting.rowLineColor || 'black')
                    .dash(1,2)
                    .lineTo(bodySize.width, dY + row.totalHeight)
                    .stroke()
                doc.restore()
              }

              for(let c = 0; c < columns.length; c++) {
                const col = columns[c]
                const formatter = col.formatter || defaultFormatter

                if(c === 0 && settings.formatting && settings.formatting.start === 'solid') {
                  //const { x, y, width, height, stroke = 'black', lineWidth = 1, cap = 'butt'} = el
                  doc.save()
            
                  doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(dX, dY - lineSpacing - 1)
                    .lineTo(dX, dY + row.totalHeight)
                    .stroke('black')
                
                  doc.restore()
                }

                if(c === columns.length - 1 && settings.formatting.end === 'solid') {
                  doc.save()
                
                  doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(bodySize.width, dY - lineSpacing - 1)
                    .lineTo(bodySize.width, dY + row.totalHeight)
                    .stroke('black')
                
                  doc.restore()
                }

                if(c > 0 && settings.formatting.seperator === 'dash') {
                  doc.save()
                  doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(dX, dY - lineSpacing - 1)
                    .lineTo(dX, dY + row.totalHeight)
                    .dash(1, 2)
                    .stroke(settings.formatting.seperatorColor || '#bbb')
                  doc.restore()
                }

                if(c > 0 && settings.formatting.seperator === 'solid') {
                  doc.save()
                  doc
                    .lineCap('butt')
                    .lineWidth(0.5)
                    .moveTo(dX, dY - lineSpacing - 1)
                    .lineTo(dX, dY + row.totalHeight)
                    .stroke(settings.formatting.seperatorColor || '#bbb')
                  doc.restore()
                }

                doc
                  .fillColor(col.color || 'black')
                  .font(col.font || DEFAULT_FONT)
                  .fontSize(col.fontSize || DEFAULT_FONT_SIZE)
                  .text( formatter( row.data[col.key], row.data, col ), dX, dY, {
                    width: col.width,
                    //height: row.height,
                    fillColor: col.color || 'black',
                    align: col.align || 'left',
                  } )
                
                dX += col.width
              }

              dY += row.height
              //Start a new row.
              dX = 0
              //LINE 2
              if(columnsOptional) {
                for(let c = 0; c < columnsOptional.length; c++) {

                  const col = columnsOptional[c]
                  const formatter = col.formatter || defaultFormatter
                  const result = formatter( row.data[col.key], row.data, col)
                  const shouldDraw = !isEmptyOrWhitespace(result)

                  if(!shouldDraw) {
                    dX += col.width
                    continue
                  }

                  //Addornments
                  if(col.container) {
                    if(col.container == ReportContainers.ROUNDED_RECTANGLE) {
                      const fillColor = 'white'
                      const borderColor = settings.headerColor || 'black'
                      doc.save()
                      doc
                        .roundedRect(dX + 1, dY - 2, col.width, row.height2, 3)
                        .fillOpacity(0.8)
                        .fillAndStroke(fillColor, borderColor)
                      doc.restore()
                    }

                    if(col.container == ReportContainers.RECTANGLE) {
                      const fillColor = 'white'
                      const borderColor = settings.headerColor || 'black'
                      doc.save()
                      doc
                        .rect(dX + 1, dY - 2, col.width, row.height2)
                        .fillOpacity(0.8)
                        .fillAndStroke(fillColor, borderColor)
                      doc.restore()
                    }
                  }

                  doc
                    .fillColor(col.color || 'black')
                    .font(col.font || DEFAULT_FONT)
                    .fontSize(col.fontSize || DEFAULT_FONT_SIZE)
                    .text( result, dX, dY, {
                      width: col.width,
                      height: row.height2,
                      color: col.color || 'black',
                      align: col.align || 'left',
                    } )
                  
                  dX += col.width
                }

                dY += row.height2
              }

              dY += lineSpacing
              
              //console.log(`${row.data.number} | Moving to points [${0}, ${dY}]`)
            }
          }
        }
      }

      //Add Page Numbers
      let range = doc.bufferedPageRange()
      doc.restore()
      for(let i = range.start; i < range.count; i++) {
        const PAGE_VARIABLES = {
          ...VARIABLES,
          page: i + 1,
          pages: range.count,
        }
        doc.switchToPage(i)
        doc.translate(0, -_calcHeaderHeight) //This feels weird, but seems to work.
        //Write Page Header
        for(let el of settings.header) {
          doc.save()

          const { 
            posX, 
            posY
          } = el

          if(el.text) {
            let text = el
            const _text = formatUnicorn(text.text, PAGE_VARIABLES)

            doc
              .fillColor(text.color || 'black')
              .font(text.font || DEFAULT_BOLD)
              .fontSize(text.fontSize || DEFAULT_FONT_SIZE)
              .text( _text || '', 
                num(text.posX, 0), 
                num(text.posY, 0), 
                {
                  width: text.width || bodySize.width,
                  height: _calcHeaderHeight,
                  color: text.color || 'black',
                  align: text.align || 'left',
                } )
          }

          if(el.image) {
            const {
              width,
              height
            } = el

            const image = el.image
            const base = image.split('base64,')[1]

            try {
              //let buffer = new Buffer(image)
              doc.image(image, posX, posY, {
                //width: imageWidth,
                height: height,
              })        
            } catch (e) {
              doc
              .rect(posX, posY, width, height)
              .fill('red')
            }
          }

          if(el.logo && show.logo) {
            const image = show.logo
            const {
              width,
              height
            } = el

            const base = image.split('base64,')[1]

            try {
              //let buffer = new Buffer(image)
              doc.image(image, posX, posY, {
                //width: imageWidth,
                height: height,
              })        
            } catch (e) {
              doc
              .rect(posX, posY, width, height)
              .fill('red')
            }
          }
          
          doc.restore()
        }
  
        //Write positions

        if (!people.disable && settings.positions && settings.positions.length) {
          doc.translate( 0, num(people.posY, 0) )
          for(let p = 0; p < settings.positions.length; p++) {
            doc
            .fillColor('black')
            .font(DEFAULT_FONT)
            .fontSize(DEFAULT_FONT_SIZE)
            .text( 
              settings.positions[p], 
              bodySize.width - 200, p * 15, {
              width: 200,
              height: _calcHeaderHeight || 40,
              color: 'black',
              align: 'right',
            } )
          }
          doc.restore()
        }
                
        //Write Footer
        for(let text of settings.footer) {
          doc.save()
          doc.translate(0, availableSize.height - _calcFooterHeight)
          //.text( `Page ${i + 1} of ${range.count}`, bodySize.width - 128, availableSize.height - _calcFooterHeight, {
          const { 
            posX, 
            posY
          } = text

          const _text = formatUnicorn(text.text, PAGE_VARIABLES)
          doc
            .fillColor(text.color || 'black')
            .font(text.font || DEFAULT_FONT)
            .fontSize(text.fontSize || DEFAULT_FONT_SIZE)
            .text( _text || '', 
              num(text.posX, 0), 
              num(text.posY, 0), 
              {
                width: text.width || bodySize.width,
                height: _calcHeaderHeight,
                color: text.color || 'black',
                align: text.align || 'left',
              } )

          doc.restore()
        }

        //APPLY WATERMARK
        if(show.watermark) {
          let fontSize = 256
          let size = -1
          let maxSize = availableSize.width * 0.75
          while(fontSize > 24) {
            const single = calcTextSize('Aa', maxSize, fontSize, DEFAULT_BOLD)
            size = calcTextSize(show.watermark, maxSize, fontSize, DEFAULT_BOLD)
            if(size > single) {
              fontSize -= 12
            } else {
              break
            }
          }

          doc.save()

          doc
            .fillColor('black', 0.10)
            .font(DEFAULT_BOLD)
            .fontSize(fontSize)
            .rotate(-40, { origin: [availableSize.width / 2, availableSize.height / 2] })
            .text( show.watermark, 0, (availableSize.height - size ) / 2, {
              width: availableSize.width,
              height: fontSize,
              align: 'center',
            } )
          doc.restore()
        }

        // //FINALLY WRITE THE PAGE NUMBERS (we need to make this a settings)
        // doc
        //   .fillColor('black')
        //   .font(DEFAULT_FONT)
        //   .fontSize(DEFAULT_FONT_SIZE)
        //   .text( `Page ${i + 1} of ${range.count}`, bodySize.width - 128, availableSize.height - _calcFooterHeight, {
        //     width: 128,
        //     height: 20,
        //     color: 'black',
        //     align: 'right',
        //   } )
      }

      doc.flushPages()
      doc.end()
      resolve(output)
    } catch (error) {
      reject(error)
    }
  })
}