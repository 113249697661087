import React from 'react'
import { connect } from 'react-redux'
import translate from './../../shared/language/Translate'
import Errors from './../Errors'
import Colors from './../Colors'
import { 
  mergeCueData,
  deleteCue, 
  undeleteCue,
  addCue, 
  moveAfterCue, 
  noteCue, 
  utilCopyCue,
  NOTE_TYPES, 
  utilSmartLabelCue
} from '../reducers/CueLogic'

import { 
  showDialog, 
  showConfirm, 
  showSnack, 
  showQuickSnack 
} from '../reducers/InterfaceLogic'

import {
  completeNote,
  showNoteDialog,
  utilSmartNoteDescription,
} from '../reducers/NotesLogic'

import { 
  parseFiles, 
  convertFileToImage 
} from './../Utility'

import { 
  ifNullThen, 
  formatUnicorn,
  isEmptyOrWhitespace,
} from './../../shared/StringUtil'

import {
  Button,
  Fab,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core'

import EditableSelect from './../../components/EditableSelect'

import IconLabel from '@material-ui/icons/LabelOutlined'

import IconAccept from '@material-ui/icons/Done'
import IconCancel from '@material-ui/icons/NotInterested'
import IconDelete from '@material-ui/icons/DeleteForever'
import IconUndelete from '@material-ui/icons/Restore'

import IconAddCue from '@material-ui/icons/PlaylistAdd'
import IconAddPhoto from '@material-ui/icons/AddAPhoto'
import {
  SpotlightIcon,
  LabelOff as IconLabelOff,
} from './../Assets'
import IconHideSpots from '@material-ui/icons/NotInterested'
import IconNote from '@material-ui/icons/Flag'
import { noteReset } from '../reducers/NotesLogic'

export const buttonStyle = {
  maxWidth: '48px', 
  maxHeight: '48px',
  minWidth: '48px',
  minHeight: '48px',
}

export const buttonStyleFloat = Object.assign({}, buttonStyle, {
  position: 'absolute', 
  right: '8px',
  bottom: '24px',
})

export const pageStyle = {
  paddingLeft: 0,
  paddingRight: 0
}
/** We bind this to a class so that it can be referenced. */
class TextEditor extends React.Component {
  render() {
    const { ref, ...rest} = this.props

    return (
      <TextField 
        {...rest}
      />
    )
  }
}

class CueRow extends React.Component{
  constructor(props){
    super(props)

    const { cue, dirty, ...rest} = props
    this.state = {
      selected : false,
      autoFlag: false,
      dirty : true,
      count: 0,
      backup: utilCopyCue( cue ),
      cue : utilCopyCue( cue ),
      ...rest
    }

    this.focusRefs = {}
  }

  UNSAFE_componentWillReceiveProps(props) {
    

    if(this.state.selected) {
      const { cue, dirty, ...rest} = props

      const update = Object.assign({}, this.state,  { 
        cue : this.state.cue, //Use the active cue data rather than replacing it...
        dirty: this.state.dirty,
        selected: this.state.selected,
        //Use the new backup value (might be an undo on a note or something
        backup: utilCopyCue( cue ),
        ...rest
      })
      this.setState(update)
    } else {
      const { cue, dirty, ...rest} = props

      const update = Object.assign({}, this.state,  { 
        cue : utilCopyCue( cue ),
        dirty: false,
        backup: utilCopyCue( cue ),
        ...rest
      })
      this.setState(update)
    }

  }

  componentDidUpdate() {
    if(this.state.selected && this.state.focus){
      if(this.editor !== null || this.editor !== undefined){
        //console.log('focusing editor');
        if(this.state.focus){
          const el = this.focusRefs[this.state.focus]
          if(el) {
            //el.focus()
          } else {
            this.editor.focus()
          } 
        }

        this.setState(Object.assign({}, this.state, { 
          focus: false
        }))
      } else {
        console.log('editor is null')
      }
    }
  }

  onFocus(e){  
    e.target.select()
    this.setState(Object.assign({}, this.state, { 
      autoFlag: false
    }))
  }

  select(e, field) {
    this.setState(Object.assign({}, this.state, { 
      selected : true,
      autoFlag: false,
      focus: field
    }))
  }

  deselect(e) {
    this.setState(Object.assign({}, this.state, { 
      selected : false,
      focus: false
    }))
  }

  addNote(note){
    if(!note){
      note = {
        typeGeneral: true      
      }
    }
    const save = ()=>{
      const copy = Object.assign({}, this.state.cue)
      if(!copy.notes){
        copy.notes = []
      }

      copy.notes.push(note)
      this.props.dispatch( mergeCueData(copy, 'Remove a note to cue ({number})', 'Add a note to cue ({number})') )
      this.setState(Object.assign({}, this.state, { 
        autoFlag: false,
        cue: copy
      }))
    }

    this.props.dispatch( noteCue( this.state.cue ) )
  }

  cancelComplete(note, index){
    note.completed = false
    if(note.timer){
      clearTimeout(note.timer)
    }
    delete note.timer
    this.setState( Object.assign({}, this.state) )
  }

  completeNote(note, index){
    const T = this.props.strings

    const fireComplete = () => {
      const cue = Object.assign({}, this.state.cue)
      if(!cue.notes){
        return
      }
      note.completed = true
      cue.notes.splice(index, 1)

      const label = {
        label: utilSmartLabelCue(cue)
      }

      let undo = T.get('noteMarkedCompleted', label)
      let redo = T.get('noteMarkedRestored', label)
      this.props.dispatch( mergeCueData(cue, undo, redo ) )
      //FIXME --add a description here for closing a note and opening a note..

      this.setState(Object.assign({}, this.state, {
        cue: cue,
        dirty: true,
        autoFlag: false
      }))
      return
      //OLD LOGIC, this is some hill billy crap...

      if(!cue.notes){
        return
      }
      const backup = Object.assign({}, cue.note)
      note.completed = true
      
      const timers = (this.state.timers || []).splice()
      note.timer = setTimeout( ()=>{
        cue.notes.splice(index, 1)
        delete note.timer
        console.log('store note for future use')
        console.log(note)
        this.props.dispatch( mergeCueData(cue) )
        this.setState(Object.assign({}, this.state, {
          cue: cue,
          dirty: true,
          autoFlag: false
        }))
  
        this.props.dispatch( showQuickSnack( T.get('noteMarkedCompleted') ) )
      }, 3900)
  
      this.props.dispatch( showSnack( T.get('noteDeleted'), T.get('undo'), ()=>{
        this.cancelComplete(note, index)
      } ) )
  
      this.setState(Object.assign({}, this.state, {
        cue: cue,
        dirty: true,
        autoFlag: false
      }))
    }

    fireComplete()
  }

  toggle(field, value){
    const copy = Object.assign({}, this.state)
    if(value == copy.cue[field]){
      return
    }

    copy.cue[field] = value
    copy.dirty = true
    this.setState(copy)
    this.props.dispatch( mergeCueData(copy.cue) )
  }

  forceUpdate(field, value) {
    const copy = Object.assign({}, this.state)
    if(value == copy.cue[field]){
      return
    }

    copy.cue[field] = value
    copy.dirty = true
    this.setState(copy)
  }

  updateSpotExplicit(newVal, index, field){
    const copy = Object.assign({}, this.state)
    if(!copy.cue.spots){
      copy.cue.spots = []
    }
    
    if(newVal == copy.cue.spots[index][field]){
      return
    }
    copy.cue.spots[index][field] = newVal
    copy.dirty = true
    copy.autoFlag = false
    this.setState(copy)
  }

  updateSpot(e, index, field){
    const copy = Object.assign({}, this.state)
    if(!copy.cue.spots){
      copy.cue.spots = []
    }
    
    if(e.target.value == copy.cue.spots[index][field]){
      return
    }
    copy.cue.spots[index][field] = e.target.value
    copy.dirty = true
    copy.autoFlag = false
    this.setState(copy)
  }

  update(e, field, sub) {
    const copy = Object.assign({}, this.state)
    if(e.target.value == copy.cue[field]){
      return
    }

    copy.cue[field] = e.target.value
    copy.dirty = true 
    copy.autoFlag = false
    this.setState(copy)
  }

  //Do we need to put some checks in here?
  updateMemo(e, index) {
    const copy = Object.assign({}, this.state)
    if(e.target.value == copy.cue.notes[index].description){
      return
    }

    copy.cue.notes[index].description = e.target.value
    copy.dirty = true 
    copy.autoFlag = false
    this.setState(copy)
  }

  save(e) {
    this.setState({
      ...this.state,
      autoFlag: false,
      selected: false,
      dirty: false,
      focus: false,
    })
    this.props.dispatch( mergeCueData(this.state.cue) )
  }

  cancel(e) {
    this.setState(Object.assign({}, this.state, {
      selected: false,
      autoFlag: false,
      cue : utilCopyCue( this.state.backup ),
      dirty: false,
      focus: false
    }))
  }

  undelete(e) {
    const T = this.props.strings
    this.deselect()
    this.props.dispatch(undeleteCue(this.state.cue))
    this.setState(Object.assign({}, this.state, { autoFlag: false}))
  }

  delete(e) {
    const T = this.props.strings
    this.deselect()

    if(this.state.deletePrompt){
      const confirm = ()=>{
        this.props.dispatch(deleteCue(this.state.cue))
        this.setState(Object.assign({}, this.state, { autoFlag: false}))
      }

      const cancel = ()=> {
        //console.log('canceled');
      }

      const labelArgs = {
        number: ifNullThen(this.state.cue.number, '-')
      }
      this.props.dispatch( showConfirm(
        T.get('dialogDeleteTitle', labelArgs),
        T.get('dialogDeleteBody', labelArgs),
        confirm, 
        cancel,
        T.get('delete'),
        T.get('cancel')
      ))
    } else {
      this.props.dispatch(deleteCue(this.state.cue))
      this.setState(Object.assign({}, this.state, { autoFlag: false}))
    }
  }

  addImages(fileObject){
    const results = parseFiles(fileObject)
    console.log(results)
    const cue = Object.assign({}, this.state.cue)
    
    if(!cue.images){
      cue.images = []
    }

    const promises = []

    results.forEach(f => {
      promises.push(
        new Promise((resolve, reject)=>{
          try {
            convertFileToImage(f, (data)=>{
              resolve(data)
            })

          } catch (err) {
            reject(err)
          }
        }) 
      )
    })

    Promise.all(promises)
      .then( values=>{
        values.forEach(v => {
          if(v){
            cue.images.push(v)
          }
        })
        this.props.dispatch( mergeCueData(cue) )
      }).catch(err=>{
        this.props.dispatch( Errors.reactError(this.props.strings.get('errorAddImage'), err) )
      })
  }

  onMouseEnter(e) {
    this.setState(Object.assign({}, this.state, {
      hover: true
    }))
  }

  onMouseLeave(e) {
    this.setState(Object.assign({}, this.state, {
      hover: false
    }))
  }

  onFieldBlur(e){
    if(this.props.autoSave){
      setTimeout(()=>{
        if(this.state.autoFlag){
          this.save(e)
        }
      }, 50)
      this.setState(Object.assign({}, this.state, { autoFlag: true} ))
    }
  }

  keyHandler(e) {
    const key = e.which || e.keyCode
    if(key == 27){
      this.cancel(e)
    } else if (key == 13){
      this.keyEnter(e)
    }
  }
  
  keyEnter(e){
    e.preventDefault()
    e.target.blur()
    this.save(e)
  }

  onDrawAccept(e) {
    if(!this.state.dragEnabled){
      return
    }
    //const newId = e.dataTransfer.getData('application/json');
    //alert('accepting->' + newId);
  }

  onDragStart(e) {
    if(!this.state.dragEnabled){
      return
    }
    console.log(`onDragStart @${this.state.cue.number}`)
    e.dataTransfer.setData('application/json', JSON.stringify(this.state.cue) )
    window.CUES_TEMP = this
  }

  onDragOver(e) {
    if(!this.state.dragEnabled){
      return
    } else {
      if(!this.state.dragHover) {
        this.setState({
          ...this.state,
          dragHover: true,
        })
      }
    }
    e.preventDefault()
  }

  onDragEnter(e) {
    if(!this.state.dragEnabled){
      return
    }
    this.setState(Object.assign({}, this.state, {
      dragHover: true
    }))
  }

  clearDragHover(e, after) {
    this.setState(Object.assign({}, this.state, {
      dragHover: false
    }))
    if(after) {
      after()
    }
  }

  onDrop(e) {
    if(!this.state.dragEnabled){
      return
    }
    e.preventDefault()
    console.log(`dropping event @${this.props.cue.number}`)
    if(e.dataTransfer.types.includes('application/json')){
      
      const droppedCue = JSON.parse( e.dataTransfer.getData('application/json') )

      //this.setState( Object.assign({}, this.state, { dragHover: false }) )
      //We are delaying the moveAfter to clear the hover...
      this.clearDragHover(e, ()=>{
        setTimeout(()=>{
          this.props.dispatch( 
            moveAfterCue(droppedCue, this.state.cue._id)
            //moveAfter (this.state.cue, cue)
          )
        }, 10)
      })


      console.log(`ON DROP -> [${droppedCue.number}] ${this.props.cue.number}`)
    } else if (e.dataTransfer.files.length > 0){
      this.addImages(e.dataTransfer.files)
    }

    console.log('ON DROP -> ')
  }

  renderHeaders() {
    if(this.props.renderHeaders) {
      return this.props.renderHeaders()
    } else {
      return null
    }
    
    
    const T = this.props.strings
    return (
      <div className='row hidden-xs heading'>
        <div className='col-md-1 hidden-xs'>
          {/*controls*/}
          <label></label>
        </div>
        
        <div className='col-md-1 col-xs-2'>
          <label className='cue parens'>
            {T.get('hCue')}
          </label>
        </div>

        <div className='col-md-1 col-xs-3' style={pageStyle}>
          {T.get('hPage')}
        </div>

        <div className='col-md-3 col-xs-7'>
          {T.get('hLabel')}
        </div>

        <div className='col-md-3 col-xs-12'>
          {T.get('hDescription')}
        </div>

        <div className='col-md-3 col-xs-12'>
          {T.get('hActions')}
        </div>
      </div>
    )
  }

  renderNotes(cue, editing) {
    const notes = (cue.notes || []).slice()

    const integratedNotes = this.props.notes
    const cueNotes = this.props.cueNotes[cue.number] || []

    for(let noteId of cueNotes) {
      let note = { ...integratedNotes[noteId] }
      let transform = {
        ...note,
      }

      if(!note.completed) {
        notes.push(transform)
      }
    }

    if(!notes){
      return null
    }

    const cFocus = 'orange'
    const cWork = window.theme.palette.secondary.main
    const cOther = window.theme.la_blue

    const T = this.props.strings
    let work, focus, other

    const labelIt = (n) => {
      const style = (c)=>{
        if(editing){
          if(n.uuid) {
            return { 
              color: window.theme.palette.text.disabled, 
              paddingRight: '10px'
            }
          } else {
            return { color: c, paddingRight: '10px'}  
          }
        } else {
          return { color: c, paddingRight: '10px'}
        }
      }

      if(n[ NOTE_TYPES.CUE] ){
        return <span style={ style(cOther) }>{ T.get('cue') }</span>
      } else if (n[ NOTE_TYPES.FOCUS ]){
        return <span style={ style(cFocus) }>{ T.get('focus') }</span>
      } else if (n[ NOTE_TYPES.GENERAL ]) {
        return <span  style={ style(cOther) }>{ T.get('general') }</span>
      } else if (n[ NOTE_TYPES.PRIVATE ]) {
        return <span  style={ style(cOther) }>{ T.get('private') }</span>
      } else if (n[ NOTE_TYPES.WORK ]) {
        return <span  style={ style(cWork) }>{ T.get('work') }</span>
      } else {
        //DEFAULT --we need to fix this as the notes really should get color coding
        return <span  style={ style(cWork) }>{ T.get('note') }</span>
      }
    }

    notes.forEach(n => {
      if(n[ NOTE_TYPES.CUE] ){
        other = true
      } else if (n[ NOTE_TYPES.FOCUS ]){
        focus = true
      } else if (n[ NOTE_TYPES.GENERAL ]) {
        other = true
      } else if (n[ NOTE_TYPES.PRIVATE ]) {
        other = true
      } else if (n[ NOTE_TYPES.WORK ]) {
        work = true
      }
    })

    const noteClass = (base, n)=>{
      if(editing){
        return base + ' editing'
      }

      if(n[ NOTE_TYPES.CUE] ){
        return base + ' general'
      } else if (n[ NOTE_TYPES.FOCUS ]){
        return base + ' focus'
      } else if (n[ NOTE_TYPES.GENERAL ]) {
        return base + ' general'
      } else if (n[ NOTE_TYPES.PRIVATE ]) {
        return base + ' private'
      } else if (n[ NOTE_TYPES.WORK ]) {
        return base + ' work'
      } else {
        return base + ' unknown error'
      }
    }

    const name  = (type) =>{
      if(type === 'general'){
        return 'row col-md-12 col-xs-12 completed note general'
      } else if(type === 'private') {
        return 'row col-md-12 col-xs-12 note private'
      } else {
        return 'row col-md-12 col-xs-12 note work'
      }
    }

    const flag = (show, color) => {
      if(show){
        if(editing){
          return <IconNote style={{color: 'rgba(0,0,0,.4)'}} />
        } else {
          return <IconNote style={{color: color}} />
        }
      }
      return null
    }

    return (
      <div 
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            width: 96,
          }}
        > 
          { flag(other, cOther) }
          { flag(work, cWork) }
          { flag(focus, cFocus) }
        </div>

        <div 
          style={{
            width: '80%',
            display: 'flex',
            flexDirection: 'column',
          }}
          className='notes'>
          { notes.map((note, index) => {
            if(note.completed || note.deleted){
              return null
            }
            const memoRefId = 'memo-' + index
           
            return (
              <div 
                key={index} 
                className={ noteClass('note', note) }
                style={{
                  display: 'flex',
                  padding: '0px 12px 0px 12px',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    color: editing ? window.theme.palette.text.disabled : window.theme.palette.text.primary,
                  }}
                >  
                  { labelIt(note) }

                  { note.uuid ? 
                    //NOTE
                    <span>
                      { utilSmartNoteDescription(note) }
                    </span>
                    :
                    //MEMO
                    editing ? 
                      <TextEditor 
                        size='small'
                        key={ 'e' + memoRefId }
                        onFocus={e => this.onFocus(e) }
                        autoFocus = { this.state.focus == memoRefId }
                        multiline={ true }
                        onKeyDown={ e => this.keyHandler(e) }
                        value={ cue.notes[index].description }
                        ref={ x => {
                          //console.log(`setting ref to ${key} `)
                          this.focusRefs[memoRefId] = x
                        }}
                        style={{
                          flex: 1
                        }}
                        onChange={ e => {
                          this.updateMemo(e, index)
                        } } 
                      />
                      :
                      <span 
                        onClick={e => {
                          this.select(e, memoRefId)
                        }}
                        className='pointer'
                      >
                        { utilSmartNoteDescription(note) }
                      </span>
                  }

                  {/* 
                  
                  { note.channels  && note.channels.length > 0 ? 
                    note.channels.map(x=> x ? '(' + x + ') ' : null) 
                    : null }
                  { note.description } 

                  */}
                  
                </div>
                <div 
                  className='no-print'
                >
                    { note.uuid && 
                      <Button
                        disabled={editing || false}
                        onClick={e => {
                          if(note.uuid) {
                            this.props.dispatch( 
                              showNoteDialog(note)
                            )
                          }
                        }}
                      >
                        Edit
                      </Button>
                    }
                    
                    <Button
                      color='secondary'
                      disabled={editing || false}
                      
                      onClick={e =>{ 
                        if(note.uuid) {
                          this.props.dispatch( completeNote(note) )
                        } else {
                          this.completeNote(note, index)
                        }
                        
                      } } 
                    >
                      { note.uuid ? T.get('complete') : T.get('dismiss') }
                    </Button>
                </div>
              </div>
            )
          }) }
        </div>

      </div>
    )
  }
  /**
   * Removes an image from the data-structure in the 
   * state but does not broadcast that change (it can be canceled).
   * 
   * If 'save' is truthy then it will broadcast the change.
   */
  removeImage(index, save){
    const cue = Object.assign({}, this.state.cue)

    if(cue.images){
      cue.images = cue.images.slice()
      cue.images.splice(index, 1)
    }
    
    if(save){
      this.props.dispatch( mergeCueData(cue) )
    }
    
    this.setState( Object.assign({}, this.state, { 
      cue: cue,
      dirty: true
    }) )
  }

  renderSimpleSpots(cue, editing, count) {
    if(!cue.showSpots){
      return null
    } 

    if(!count){
      count = 4 //FIXME hard coded, but this allows unlimited spots
    }
    
    if(!cue.spots || cue.spots.length < 1){
      cue.spots = []
      for(let i = 0; i < count; ++i){
        cue.spots.push({number: i+1})
      }
    }

    const T = this.props.strings

    const unique = (index, field) =>{
      return 'spot_' + index + '_' + field
    }

    const labelFor = (spot, field, index) =>{
      const id = unique(index, field)
      return (
        <label      
          className={spot[field] ? '' : 'empty'}     
          onClick={ e=>{ 
            this.select(e, id)
          } }>
          {spot[field] || T.get('spot_' + field)}
        </label>
      )
    }

    const textFor = (spot, field, index) =>{
      const id = unique(index, field)
      //updateSpot(e, index, field)
      return (
        <TextField 
          onFocus={e => this.onFocus(e) }
          multiLine={true}
          onKeyDown={ e=> this.keyHandler(e) }
          value={cue.spots[index][field]}
          hintText={T.get('spot' + field + 'Hint')}
          ref={x => this[id] = x }
          style={{width: '90%' }}
          onChange={ e => this.updateSpot(e, index, field) } 
        />
      )
    }

    return (
      <div style={{ float:'right' }}>
        { cue.spots.map((spot, index) => {
          if(index >= count){
            return null
          }

          return (
            <div key={index}>
              { editing ? 
                  textFor(spot, 'pickup', index)
                : 
                  labelFor(spot, 'pickup', index)
              }
            </div>
          )
        }) }
      </div>
    )
  }

  renderSpots(cue, editing, count, width, options) {

    const { 
      spotLightColors,
      spotLightSizes, 
      spotLightLocations,
      characterNames
    } = this.props

    if(!cue.showSpots){
      return null
    } 

    if(!count){
      count = 4 //hard coded, but this allows unlimited spots
    }
    
    if(!cue.spots || cue.spots.length < 1){
      cue.spots = []
      for(let i = 0; i < count; ++i){
        cue.spots.push( { number: i + 1 } )
      }
    }

    if(cue.spots.length < count) {
      const start = cue.spots.length

      for(let i = 0; i < count; i++) {
        cue.spots.push( { number: i + 1 } )
      }
    }

    const T = this.props.strings

    const unique = (index, field) =>{
      return 'spot_' + index + '_' +field
    }

    const labelFor = (spot, field, index) =>{
      const id = unique(index, field)
      return (
        <label      
          className={spot[field] ? '' : 'empty'}     
          onClick={ e=>{ 
            this.select(e, id)
          } }>
          {spot[field] || T.get('spot_' + field)}
        </label>
      )
    }

    const selectFor = (spot, field, index, options) => {
      const id = unique(index, field)

      return (
        <EditableSelect 
          //onFocus={e => this.onFocus(e) }
          //multiLine={true}
          //onKeyDown={ e=> this.keyHandler(e) }
          items={ options }
          value={ cue.spots[index][field] }
          hintText={T.get('spot' + field + 'Hint')}
          placeholder={ T.get('spot' + field + 'Hint') }
          ref={x => this[id] = x }
          style={{width: '90%' }}
          onKeyDownHandler={e => {
            //Essentially copy the main code...
              const key = e.which || e.keyCode
              if(key == 27){
                this.cancel(e)
              } else if (key == 13){
                e.preventDefault()
                e.target.blur()
                this.save(e)
              }
            }
          }
          onChange={ val => this.updateSpotExplicit(val, index, field) } 
        />
      )
    }

    const textFor = (spot, field, index) =>{
      const id = unique(index, field)
      //updateSpot(e, index, field)
      return (
        <TextEditor 
          onFocus={e => this.onFocus(e) }
          multiLine={true}
          onKeyDown={ e=> this.keyHandler(e) }
          value={cue.spots[index][field]}
          hintText={T.get('spot' + field + 'Hint')}
          placeholder={ T.get('spot' + field + 'Hint') }
          ref={x => this[id] = x }
          style={{width: '90%' }}
          onChange={ e => this.updateSpot(e, index, field) } 
        />
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          lineHeight: '24px',//slim
        }}
      >
        <div style={{flex: 1}}>
          {/* SPACER */}
        </div>

        { cue.spots.map((spot, index) => {
          if(index >= count){
            return null
          }

          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0 24px 0 0',
                width: width,
              }}
            >
              <label className='heading'>
                Spot { index + 1}
                {
                  // T.get('spot', index+1, 'a', 'b') 
                }
              </label>
              
              <div>
                { editing ? 
                    selectFor(spot, 'pickup', index, characterNames)
                  : 
                    labelFor(spot, 'pickup', index)
                }
              </div>

              <div>
                { editing ? 
                    selectFor(spot, 'frame', index, spotLightColors)
                  : 
                    labelFor(spot, 'frame', index)
                }
              </div>

              <div>
                { editing ? 
                    selectFor(spot, 'size', index, spotLightSizes)
                  : 
                    labelFor(spot, 'size', index)
                }
              </div>

              <div>
                { editing ? 
                    selectFor(spot, 'location', index, spotLightLocations)
                  : 
                    labelFor(spot, 'location', index)
                }
              </div>

              {/*<div className='col-md-12 col-xs-12'>
                { editing ? 
                    textFor(spot, 'size', index)
                  : 
                    labelFor(spot, 'size', index)
                }
              </div>

              <div className='col-md-12 col-xs-12'>
                { editing ? 
                    textFor(spot, 'frame', index)
                  : 
                    labelFor(spot, 'frame', index)
                }
              </div>*/}

            </div>
          )
        }) }

        <div 
          style={{
            width: 64
          }}>
          {/* SPACER */}
        </div>
      </div>
    )
  }

  renderImages(cue, editing){

    const imageStyle = {
      width: 400,
      padding: '0 16px 0 16px',
    }

    if(cue.images && cue.images.length > 0){
      return (
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          { cue.images.map( (data, index) => {
              return (
                <div key={index}
                  className='image-tile'
                  style={{
                    ...imageStyle,
                  }}
                  onClick={ e=>{ 
                    if(!editing){
                      this.select(e)
                    }
                  } }>
                  <div 
                    className='background'
                    style={{
                      display: 'block',
                      position: 'relative',
                    }}
                  >
                    { editing ? 
                      <Fab 
                        size="small"
                        style={buttonStyleFloat}
                        color='secondary'
                        onClick={e => {
                          this.removeImage(index, this.state.autoSave)
                        } }
                      >
                        <IconDelete />
                      </Fab>
                      : null 
                    }
                    <img src={data}  />
                  </div>
                </div>
              )
          }) }
      </div>
      )
    } else {
      return null
    }
  }

  render() {
    const T = this.props.strings
    const { dispatch, alt, imageUpload, showDeleted, oscCue } = this.props

    const { 
      selected, 
      dirty, 
      cue, 
      hover, 
      autoFlag, 
      dragHover, 
      autoSave,
      deletePrompt, 
      spotCount,
      spotWidth,
      mappings,
      cueSheet,
      dragEnabled } = this.state

    const spotProps = cueSheet.state.spotProps

    if(!showDeleted && cue.deleted) {
      return null
    }
    let classNames = ['row', 'cue-row']

    //OSC OVERRIDES:
    if( !isEmptyOrWhitespace(oscCue) && oscCue == cue.number) { 
      //.cue-row.osc-active in Standard.js
      classNames.unshift('osc-active') // push to the top of the list...
    }

    if(selected){
      classNames.push('selected')
    } else if (hover){
      classNames.push('hover')
    }

    if(alt){
      classNames.push('alt')
    }

    if(dragHover) {
      classNames.push('dragHover')
    }

    const className = classNames.join(' ')

    const addButtonStyle=Object.assign({}, buttonStyle, {
      //marginLeft: '-60px'
    })

    const rowStyle = { 
      display: 'flex',
      flexDirection: 'column',
      // width: '100%', 
      // height: '48px',
      // 'fontFamily': 'Roboto, sans serif',
      // 'fontSize': '16px',
      // 'lineHeight' : '48px',
      // 'vertical-align': 'middle',
      // 'border-bottom': '1px solid gray'
    }

    if(this.state.autoFlag){
      //rowStyle.background = 'yellow';
    }

    if(this.state.field){
      setTimeout(()=>{
        //console.log('this.state: ' + this.state.field);
        this.page.focus()
      }, 10)
    }

    const imageUploader = 
      <input 
        type='file' 
        accept='images' 
        ref={x=> this.imageUpload = x}
        hidden
        onChange={e => {
          if(e.target.files && e.target.files.length > 0) {
            this.addImages(e.target.files)
          }
        } } />

    const spots = cue.showSpots ? cue.spots || []: []   
    const notes = cue.notes || []
    const rowStyleCurrent = cue.deleted ? { 
      ...rowStyle, 
      backgroundColor: Colors.DELETED_BACKGROUND,
      color: Colors.DELETED_TEXT,
      textDecoration: 'line-through',
    } : rowStyle

    //console.log(`CLASSNAMES-> ${cue.number} [${className}] drag:[${this.state.dragHover}] hover:${this.state.hover}`)
    return (
      selected ? (
        <div 
          className={className}
          style={{
            ...rowStyleCurrent,
            backgroundColor: Colors.SELECTED_BACKGROUND_OVERLAY,
          }}
          ref={x => this.editor = x }
          onMouseEnter={e=> this.onMouseEnter(e) }
          onMouseLeave={ e=> this.onMouseLeave(e) }
          onBlur={ e=> this.onFieldBlur(e) }
          tabIndex={-1}
        >

          {imageUploader}
          
          { cue.header ? 
            <div style={{
              flex: 1,
            }}>
              <div className='scene-top-editing'>
                <TextEditor 
                  autoFocus = { this.state.focus == 'headerText' }
                  onFocus={e => this.onFocus(e) }
                  value={cue.headerText}
                  hintText={T.get('sceneHelp')}
                  ref={x => {
                    this.focusRefs.headerText = x 
                  } }
                  style={{width: '50%' }}
                  onKeyDown={ e=> this.keyHandler(e) }
                  onChange={ e => this.update(e, 'headerText') } 
                />
                <Tooltip title={ T.get('tipRemoveHeader') }>
                  <Button
                    className='no-print'
                    onClick={e =>{ 
                      this.forceUpdate('header', false)
                    } }
                  >
                    <IconLabelOff />
                  </Button>
                </Tooltip>
              </div>
              
              { this.renderImages(cue, true) }
              { this.renderHeaders() }

            </div>
          : 
            this.renderImages(cue, true)
          }

          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              lineHeight: '48px',
            }}
          >
            <div
              style={{
                width: 64
              }}
            >
              {/* ADD CUE */}
            </div>
  
            { mappings.map( (mapping) => {
              const { key, width } = mapping

              return (
                <TextEditor 
                  key={ 'e' + key }
                  onFocus={e => this.onFocus(e) }
                  autoFocus = { this.state.focus == key }
                  multiline={ true }
                  onKeyDown={ e => this.keyHandler(e) }
                  value={ cue[key] || '' }
                  label={ T.get( key ) }
                  ref={ x => {
                    //console.log(`setting ref to ${key} `)
                    this.focusRefs[key] = x
                  }}
                  style={{
                    width: width
                  }}
                  onChange={ e => this.update(e, key) } 
                />
              )
            })}
  
            <div
              style={{
                width: 192, //4 buttons
              }}
            >
              { autoSave ? 
                <React.Fragment>
                  <Button
                    variant='fab'
                    size='small'
                    onClick={e => {
                      this.forceUpdate('header', true)
                    } }
                  >
                    <IconLabel /> 
                  </Button>

                  <Button
                    size='small'
                    color='secondary'
                    onClick={e => this.delete(e) }
                  >
                    <IconDelete />
                  </Button>

                  { autoFlag ? 'saving...' : ''}
                </React.Fragment>
                : 
                <React.Fragment>
                  <Tooltip title={ T.get('tipSave') }>
                    <Button
                      style={buttonStyle}
                      onClick={ e => this.save(e) }
                    >
                      <IconAccept />
                    </Button>
                  </Tooltip>

                  <Tooltip title={ T.get('tipCancel') }>
                    <Button
                      style={buttonStyle}
                      onClick={e => this.cancel(e) }
                    >
                      <IconCancel />
                    </Button>
                  </Tooltip>

                  { cue.deleted ? 
                    <Tooltip title={ T.get('tipUndelete') }>
                      <Button
                        style={buttonStyle}
                        onClick={e => this.undelete(e) }
                      >
                        <IconUndelete />
                      </Button>
                      </Tooltip>
                    :
                    <Tooltip title={ T.get('tipDelete') }>
                      <Button
                        style={buttonStyle}
                        onClick={e => this.delete(e) }
                      >
                        <IconDelete />
                      </Button>
                    </Tooltip>   
                  }            
                </React.Fragment>
              }
            </div>
          </div>

          { this.renderSpots(cue, true, spotCount, spotWidth, cueSheet.spotProps )}
          { this.renderNotes(cue, true) }
        </div>
      ) : (
//-------------------------------------------
// DISPLAY COMPONENT
//-------------------------------------------        
        <div 
          draggable={dragEnabled}
          onDragStart={ e => this.onDragStart(e) }
          onDrop={ e => this.onDrop(e) }
          onMouseEnter={e => {
            // console.log('\t\tMOUSE ENTER')
            this.onMouseEnter(e)
          } }
          onMouseLeave={ e => {
            // console.log('\t\tMOUSE LEAVE')
            this.onMouseLeave(e)
          } }
          onDragOver={ e => {
            // console.log('\t\tDRAG OVER')
            this.onDragOver(e)
          } }
          onDragEnter={ e => {
            // console.log('\t\tDRAG ENTER')
            this.onDragEnter(e)
          } }
          onDragLeave={ e => {
            // console.log('\t\tDRAG LEAVE')
            this.clearDragHover(e)
          } }
          onDragExit={ e => {
            // console.log('\t\tDRAG EXIT')
            this.clearDragHover(e)
          } }        
          className={className}
          style={rowStyleCurrent}
          ref={x => this.editor = x }
          onBlur={ e=> this.onFieldBlur(e) }
          tabIndex={-1}
        >
{/* HEADER COMPONENT */}

            { cue.header ? 
              <div 
                style={{
                  flex: 1,
                }}
              >
                <div className='row'>
                  <div className='scene-top'
                    onClick={ e=>{ 
                      this.select(e, 'headerText')
                    } }
                  >
                    <label>{cue.headerText || T.get('sceneBreak')}</label>
                  </div>
                </div>
              </div>
              : null
            }
          
            { this.renderImages(cue, false) }
            { cue.header ? this.renderHeaders() : null }
{/* MAIN ROW COMPONENT */}
            <div 
              // className={ className }
              style={{
                display: 'flex',
                flexDirection: 'row',
                lineHeight: '48px',
              }}
            >
              {imageUploader}

              <div
                style={{
                  width: 64
                }}
              >
                {/* <Tooltip title={  }> */}
                  <IconButton
                    title={ T.get('tipAddCue') }
                    color='primary'
                    onClick={ () => {
                      dispatch( addCue({}, cue) )
                    }}
                  >
                    <IconAddCue />
                  </IconButton>
                {/* </Tooltip> */}
              </div>
      
              { mappings.map( (mapping, index) => {
                const field = mapping.key
                
                let value = cue[field] || ''
                if(mapping.formatter && value){
                  value = mapping.formatter( value ) 
                } 

                return (
                  <div
                    id={ cue._id + '_' + mapping.key}
                    key={ mapping.key }
                    style={{
                      width: mapping.width,
                      lineHeight: 'initial', 
                      marginTop: '12px'
                    }}
                    onClick={ e => { 
                      this.select(e, field)
                    } }
                  >
                    <label      
                      className={ value ? '' : 'empty'}     
                      style={ mapping.style }
                      onClick={ e => { 
                        this.select(e, field)
                      } }>
                      { value || T.get( field ) }
                    </label>
                  </div>
                )
              })}
      
              <div
                style={{
                  width: 192 //4 buttons
                }}
              >
                
                {/* <Tooltip title={ T.get('tipAddNote') }> */}
                <Button
                    title={ T.get('tipAddNote') }
                    style={addButtonStyle}
                    color='primary'
                    onClick={e => {
                      this.addNote()
                    } }
                  >
                    <IconNote />
                  </Button>              
                {/* </Tooltip> */}

                {/* <Tooltip title={ T.get('tipAddImage') }> */}
                <Button
                    title={ T.get('tipAddImage') }
                    style={addButtonStyle}
                    color='primary'
                    onClick={e=> this.imageUpload.click()}
                  >
                    <IconAddPhoto />
                  </Button>
                {/* </Tooltip> */}

                { cue.header ? 
                  // <Tooltip title={ T.get('tipRemoveHeader') }>
                    <Button
                      title={ T.get('tipRemoveHeader') }
                      style={addButtonStyle}
                      color='primary'
                      onClick={e => {
                        this.forceUpdate('header', false)
                      } }
                    >
                      <IconLabelOff />
                    </Button>
                  // </Tooltip>
                : 
                  // <Tooltip title={ T.get('tipAddHeader') }>
                    <Button
                      title={ T.get('tipAddHeader') }
                      style={addButtonStyle}
                      color='primary'
                      onClick={e => {
                        this.forceUpdate('header', true)
                      } }
                    >
                      <IconLabel />
                    </Button>
                  // </Tooltip>
                }

                { cue.showSpots ? 
                  // <Tooltip title={ T.get('tipHideSpots') }>
                    <Button
                      title={ T.get('tipHideSpots') }
                      style={addButtonStyle}
                      color='primary'
                      onClick={e => {
                        this.toggle('showSpots', false)
                      } }
                    >
                      <IconHideSpots />
                    </Button>
                  //</Tooltip>
                : 
                  //<Tooltip title={ T.get('tipShowSpots') }>
                    <Button
                      title={ T.get('tipShowSpots') }
                      style={addButtonStyle}
                      color='primary'
                      onClick={e => {
                        this.toggle('showSpots', true)
                      } }
                    >
                      <SpotlightIcon />
                    </Button>
                  //</Tooltip>
                }
              </div>
            </div>
            
            { this.renderSpots(cue, false, spotCount, spotWidth, cueSheet.spotProps) }
            { this.renderNotes(cue, false) }
        </div>
        
      )
    )
  }
}

export default (translate('CueSheet')(CueRow))