export default {
  //status
  open: 'Open',
  close: 'Close',
  closed: 'Closed',
  completed: 'Completed',
  deleted: 'Deleted',
  takeNote: 'Take a New Note',

  //Sections
  cueNotes: 'Cue Notes',
  workNotes: 'Work Notes',
  focusNotes: 'Focus Notes',
  notes: 'Notes',

  addCueNoteTitle: 'Add Note - Cue {0}',
  updateCueNoteTitle: 'Update Note - Cue {0}',
  addNoteTitle: 'Add Note',
  updateNoteTitle: 'Update Note',
  newNote: 'New Note',

  //Default Departments
  lighting: 'Lighting',
  assistant: 'Assistant',
  private: 'Private',
  scenery: 'Scenery',
  costumes: 'Costumes',
  sound: 'Sound',
  video: 'Video/Projections',
  management: 'Management',

  //Priorities
  urgent: 'Urgent',
  high: 'High',
  regular: 'Regular',
  low: 'Low',
  minor: 'Minor',

  //Types
  typeGeneral: 'General',
  typeCue: 'Cue Note',
  typeWork: 'Work Note',
  typeFocus: 'Focus Note',
  typePrivate: 'Private Note',

  //Controls
  created: 'Created',
  department: 'Department',
  priority: 'Priority',
  shortDescription: 'Note Text',
  category: 'Category',
  relatedChannels: 'Related Channels',
  relatedCues: 'Related Cues',
  cascadeChanges: 'Cascade Notes',
  discardErrors: 'Suppress Error Messages',
  trackCueListOSC: 'OSC Tracking',
  clear: 'clear',
  addNote: 'Add Note',
  status: 'Status',
  channels: 'Channels',
  channel: 'Channel',
  date: 'Date',
  cue: 'Cue',
  description: 'Description',
  thenBy: 'Then by',
  showDeleted: 'Show Deleted',
  showCompleted: 'Show Completed',
  day: 'Day',
  time: 'Time',

  //Notes Table
  configureSorting: 'Configure Filters and Sorting',
  showingAllCategories: 'Showing all categories',
  showingCategories: 'Showing',
  showingDefaultSort: 'Showing default sort (Status, Priority, Department, Date)',
  showingSort: 'Sort by ',
  filters: 'Filters',
  //NOTES ROW
  status_completed: 'COMPLETE',
  status_deleted: 'DELETED',
  status_undefined: 'OPEN',
  status_open: 'OPEN',

  //CONTROLS
  reopen: 'REOPEN',
  complete: 'COMPLETE',
  save: 'SAVE',
  update: 'UPDATE',
  delete: 'DELETE',
  cancel: 'CANCEL',
  restore: 'RESTORE',

  tipSaveChanges: 'Save',
  tipCancelChanges: 'Cancel Changes',
  tipDeleteNote: 'Delete Note (can be restored)',
  tipRestoreNote: 'Restore this deleted note',
  tipEditFields: 'Opens a dialog to edit this note',
  tipCompleteNote: 'Mark this note as finished (completed)',
  tipReopenNote: 'Mark this note as incomplete',

  tipShowDeleted: 'Show notes that are deleted',
  tipShowCompleted: 'Show notes that are completed',
  tipOSC: 'Toggle OSC On or Off',
  tipSettings: 'Show Notes Settings',
  tipRemoveCompleted: 'Remove all completed notes',
  tipRemoveDeleted: 'Remove all deleted notes',
  privateNoteDetail: `
  <p class="warning">
    Notes that are set to <b><u>private</u></b> will be 
    ignored in the default reports and all publications. 
  </p>
`,
  oscSyncButtonTooltip: 'Use the current cue tracked in OSC as the related cue',
  submitNoteHelp: `Press SHIFT + ENTER when typing to save a note and start a new one.`,
  settingsCleanup: 'Clear Data',
  settingsClearAll: 'Purge All Notes',
  settingsClearCompletedNotes: 'Purge Completed Notes',
  settingsClearDeletedNotes: 'Purge Deleted Notes',
  removeAllDeletedTitle: `Remove all closed notes?`,
  removeAllDeletedBody: `<p>
  This will purge ({deleted}) notes that are have been deleted.
  </p>
  <p>
  <em>This action can not be undone.</em>
  </p>`,
  removeAllCompletedTitle: `Remove all completed notes?`,
  removeAllCompletedBody: `<p>
  This will remove ({completed}) notes that
  are marked completed.
  </p>
  <p>
  <em>This action can not be undone.</em>
  </p>`,
  removeAllNotes: 'Remove All Notes?',
  removeAllNotesBody: `<p>
  This will remove all notes from the file.
  </p>
  <p>
  <em>This action can not be undone.</em>
  </p>`,
  
  //Instrument Page
  warnNotesRequireChannels: 'Channel Required',
  warnNotesRequireChannelsDetail: `
  <p>
  Notes are currently linked to instruments based on their channel. Please provide a channel to use this feature.
  </p>
  <p>
  <i>
    (There is currently a feature planned to link via UUID as well as Channel, 
   but it currently makes more sense to link via channel when working during tech.)
   </i>
  </p>
  `,
}