export default {
  lightassistant: 'Light Assistant',
  home : 'Overview',
  cues : 'Cues',
  instruments : 'Instruments',
  paperwork : 'Paperwork',
  show: 'Show',
  about: 'About',
  notes: 'Notes',
  integration: 'OSC Integration',
  log: 'Log',
  dev: 'Labs',

  //routes
  '/': 'Overview',
  '/cues': 'Cue Sheet',
  '/instruments': 'Instruments',
  '/notes': 'Notes',
  '/paperwork': 'Paperwork',
  '/show': 'Show',
  '/about': 'About',
  '/account': 'Account',
  '/integration': 'OSC',
  '/log': 'Log',
  '/dev': 'Labs',

  //Main Menu
  fileMenu: 'File Menu',
  navigation: 'Navigation',


}