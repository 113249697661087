import React from 'react'
import { connect } from 'react-redux'
import { addMapping } from './../reducers/VectorworksCache'
import { instrumentMappings, general } from './../../shared/language/'
import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

import TextFieldRef from './../../components/TextFieldRef'

class AddMappingDialog extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      open: false,
      text: '',
    }
  }

  open = () => {
    this.setState({
      ...this.state,
      open: true
    })
  }

  close = () => {
    this.setState({
      ...this.state,
      open: false,
      text: '',
    })
  }

  submit() {
    const text = this.text.getValue()
    this.text.clear()
    
    if(text) {
      this.props.dispatch( addMapping(text) )
      this.setState({
        open: false,
        text: '',
      })
    }
  }
  render() {
    const T = instrumentMappings
    const { dispatch } = this.props
    return (
      <Dialog
        open={this.state.open}
        onClose={x => this.close() }
      >
        <DialogTitle>
          { instrumentMappings.get('addMappingTitle') }
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
          <p>
            { instrumentMappings.get('addMappingDescription') }
          </p>
          
          <TextFieldRef 
            ref={ r => this.text = r}
            label={ instrumentMappings.get('newFieldName') }
          />
          </div>
          
          
        </DialogContent>

        <DialogActions>
          <Button 
            onClick={e => this.close()}
          >
            { general.get('cancel') }
          </Button>

          <Button 
            color='secondary'
            onClick={e => {
              this.submit()
            }}
          >
            { T.get('addMapping') }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
//Dispatch
export default AddMappingDialog