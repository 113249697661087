import React from 'react'
import { connect } from 'react-redux'
import { 
  instruments as Strings,
  general,
} from '../../shared/language'

import { 
  Avatar,
  Button, 
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

import {
  SortStatus,
  updateWorksheet
} from '../reducers/WorksheetsLogic'

const chipStyle = {
  margin: '0 8px 8px 0',
}
const Container = (props) => {
  return (
    <div 
      style={{
        minWidth: 400,
        maxWidth: 800,
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
      }}

      {...props}
    />
  )
}

class EditWorksheetSort extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      active: [],
      inactive: [],
      
    }
  }

  componentWillReceiveProps(props) {
    const { worksheet } = this.props
    if(!worksheet){
      return
    }

    const activeGroupings = worksheet.sort.slice()
    const activeMap = {}
    const active = []
    const inactive = []

    for(let g of activeGroupings) {
      activeMap[g] = true
      active.push( {
        key: g.key,
        sort: g.sort || SortStatus.ASCENDING,
      } )
    }

    for(let f of worksheet.fields){
      if( !activeMap[f.property] ) { //skip active
        inactive.push( {
          key: f.property,
          sort: SortStatus.ASCENDING,
        } )
      }
    }

    this.setState({
      active: activeGroupings,
      inactive: inactive,
    })
  }

  _handleAdd(key) {
    const index = this.state.inactive.findIndex( x => x.key == key)
    if(index < 0) {
      return
    }

    const cActive = this.state.active.slice()
    const cInactive = this.state.inactive.slice()

    cActive.push({
      key: key,
      sort: SortStatus.ASCENDING,
    })

    cInactive.splice(index, 1)

    this.setState({
      active: cActive,
      inactive: cInactive,
    })
  }

  _handleRemove(key) {
    const index = this.state.active.findIndex( x => x.key == key)
    if(index < 0) {
      return
    }

    const cActive = this.state.active.slice()
    const cInactive = this.state.inactive

    cInactive.push( {
      key: key,
      sort: SortStatus.ASCENDING,
    })
    cActive.splice(index, 1)

    this.setState({
      active: cActive,
      inactive: cInactive,
    })
  }

  _saveWorksheet() {
    const copy = this.props.worksheet
    const index = this.props.worksheetIndex

    copy.sort = this.state.active.slice()

    this.props.dispatch( updateWorksheet(index, copy) )
    this.props.onClose()
  }

  render(){
    const { isOpen, onClose, worksheetIndex, worksheet } = this.props
    
    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle>
          { Strings.get('editWorksheetSortTitle') }  
        </DialogTitle>

        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: Strings.get('editWorksheetSortDetail') }}></div>  

          <h3>Active</h3>
          <Container>
            { this.state.active.map((a, index)=>{
              return (
                <Chip 
                  style={ chipStyle}
                  key={a.key}
                  color='secondary'
                  onDelete={ ()=>{
                    this._handleRemove( a.key )
                  } }
                  avatar={<Avatar>{index + 1}</Avatar>}
                  label={a.key}
                />
              )
            })}
          </Container>


          <h3>Available</h3>
          <Container>
            { this.state.inactive.map((a, index)=>{
              return (
                <Chip 
                  style={chipStyle}
                  variant='outlined'
                  key={a.key}
                  label={a.key}
                  onClick={ ()=>{
                    this._handleAdd( a.key )
                  }}
                />
              )
            })}
          </Container>

        </DialogContent>

        <DialogActions>

          <Button
            onClick={e => {
              if(onClose) {
                onClose()
              }
            }}
          >
            { general.get('cancel') }
          </Button>

          <Button
            color='secondary'
            onClick={e => {
              this._saveWorksheet()
            }}
          >
            { general.get('saveChanges') }
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default connect(s=>{
  return {}
})(EditWorksheetSort)