import React from 'react'
import { connect } from 'react-redux'
import { positions } from './../../shared/language'
import Standard from './../../components/Layouts/Standard'

import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc"

import {
  Avatar,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,  
} from '@material-ui/core'

import DragHandleIcon from '@material-ui/icons/DragHandle'

import {
  loadInstruments,
  replacePositions,
} from './../reducers/InstrumentLogic'

const DragHandle = SortableHandle( ()=>{
  return (
    <ListItemIcon>
      <DragHandleIcon />
    </ListItemIcon>
  )
} )

const SortableItem = SortableElement( props => {
  return (
    <ListItem ContainerComponent="div">
      <ListItemAvatar>
        <Avatar>
          {props.order + 1}
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={props.name} 
        secondary={`(${props.count}) Units`}
      />
      <ListItemSecondaryAction>
        <DragHandle />
      </ListItemSecondaryAction>
    </ListItem>
  )
})

const SortableListContainer = SortableContainer( props => {
  return (
    <List component="div">
      {props.items.map(({ id, name, count }, index) => (
        <SortableItem 
          key={id} 
          index={index} 
          name={name} 
          order={index}
          count={count}
        />
      ))}
    </List>
  ) 
} )

class PositionsPage extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch( loadInstruments(null) )
  }

  handleIndexSwap(oldIndex, newIndex) {
    const copy = this.props.positions.slice()
    const old = {...copy[oldIndex]}
    copy.splice(oldIndex, 1)
    copy.splice(newIndex, 0, old)

    this.props.dispatch( replacePositions(copy) )
  }

  render() {
    const T = positions

    return (
      <Standard>
        <h1>{T.get('positions')}</h1>
        <Button
          onClick={ e => {
            this.props.dispatch( loadInstruments(null) )
          } }
        >
          UPDATE
        </Button>
        <div
          style={{
            maxWidth: 350
          }}
        >
          <SortableListContainer
            items={this.props.positions}
            onSortEnd={ (dataSet, config)=>{
              const { oldIndex, newIndex } = dataSet
              this.handleIndexSwap(oldIndex, newIndex)
            } }
            useDragHandle={true}
            lockAxis="y"
          />
        </div>
        
        <h3>DEBUG</h3>
        <code>
          { JSON.stringify(this.props.positions, null, 2)}
        </code>

      </Standard>
    )
  }
}

export default connect(s => {
  const positions = s && s.instruments && s.instruments.positions || []
  return {
    positions: positions,
  }
})(PositionsPage)