export default {
  report: 'Report',
  refresh: 'Abort and Reload',
  close: 'Close',
  home: 'Return Home',
  clear: 'Try to Clear Error',
  unknown: 'Unknown Error',
  noTitle: ' ', //intentionally blank
  defaultMessage: ' ', //intentionally blank
  errorThanks: 'Thank you for reporting this error, I am a one-person shop and it makes a huge difference.',
  
  //Error Reporting
  errorReportTitle: 'Report System Error',
  errorReportDetail:`
We're sorry that Light Asssitant encountered an unexpected error. You can report this to our server which
will notify us of the issue or you can cancel this operation. If you chose to report the
error you also have the option to include information that will help reprouce the error. 
You can read our privacy policy for how this data is retained and used.
  `,
  submit: 'Submit',
  privacy: 'I have read and acknowledge how my data will be used.',
  includeOptionalData: 'Include Optional Data',
  saveBackup: 'Save a Backup',
  download: 'Download Error Report',
}