import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Standard from '../../components/Layouts/Standard'
import LoadingCentered from '../../components/LoadingCentered'

//import s from './styles.css'

import translate from '../../shared/language/Translate'
import CueSheet from './CueSheet'
import Undo from './../history/Undo'

import { 
  loadCues, 
  updateCharacterNamesForSpots 
} from '../reducers/CueLogic'
import { buildCueNotesCache } from '../reducers/NotesLogic'



class CuesPage extends React.Component {
  constructor(props){
    super(props)
    const make = (x)=>{
      return {
        _id: x,
        number: x,
        label: 'Random ' + x,
        page: x,
        measure: x % 32,
        beat: x % 8,
      }
    }

    this.state = {
      loaded: false
    }
    //Test data if needed
    // const rows = [];
    // for(let i = 1; i < 250; i++){
    //   rows.push(make(i));
    // }
    // this.state = {
    //   rows: rows 
    // };
  }

  componentDidMount() {
    //Build the notes cache on load
    this.props.dispatch( buildCueNotesCache() )
    this.props.dispatch( updateCharacterNamesForSpots() )
    setTimeout( () => {
      this.setState({
        loaded: true,
      })}, 
    750)
  }

  render() {
    const renderText = (val)=>{
      return <label>{val}</label>
    }
    
    const { dispatch, strings, cues } = this.props
    const T = strings


    if(!this.state.loaded){
      return (
        <Standard>
          <LoadingCentered label={T.get('loading')}/>
        </Standard>
      )
    }
    return (
      <Standard key={this.props.language.current} undo='cues'>
        <CueSheet flag='a'/>
      </Standard>
    )
  }
}

export default connect(s => {
  const mapped = {
    language: s.language,
    cues : s.cues.cues,
    // loaded: s.cues.loaded,
    show: s.show
  }
  return mapped
}) (translate('CueSheet')(CuesPage))