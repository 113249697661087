import React, { useState } from 'react'
import { connect } from 'react-redux'
import Standard from './../../components/Layouts/Standard'
import { 
  electronSave, 
  isConnected 
} from './../reducers/ElectronLogic'

//Color Picker
import 'rc-color-picker/assets/index.css'
import ColorPicker from 'rc-color-picker'

import {
  num
} from './../../shared/Utility'

import {
  dispatchCreatePdfBlob,
} from './../paperwork/PaperworkUtil'

import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormGroup,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  Switch,
  Tab,
  Tabs,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Tooltip,
  FormLabel,
  FormControlLabel,
  Menu,
  colors,
} from '@material-ui/core'

import {
  TreeView,
  TreeItem,
  Autocomplete
} from '@material-ui/lab'

import IconCheck from '@material-ui/icons/Done'
import IconSave from '@material-ui/icons/Save'
import IconCancel from '@material-ui/icons/Block'
import IconDelete from '@material-ui/icons/DeleteForever'
import IconDownload from '@material-ui/icons/CloudDownload'
import IconLeft from '@material-ui/icons/FormatAlignLeft'
import IconCenter from '@material-ui/icons/FormatAlignCenter'
import IconRight from '@material-ui/icons/FormatAlignRight'
import IconImage from '@material-ui/icons/Image'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'

import TextFields from '@material-ui/icons/TextFields'

import InfoOutlined from '@material-ui/icons/InfoOutlined'
import AddBoxOutlined from '@material-ui/icons/AddBoxOutlined'
import IndeterminateCheckBoxOutlined from '@material-ui/icons/IndeterminateCheckBoxOutlined'
import IconStorage from '@material-ui/icons/Storage'
import IconSort from '@material-ui/icons/SortByAlpha'
import IconFilter from '@material-ui/icons/FilterList'
import IconAdd from '@material-ui/icons/Add'
import IconRefresh from '@material-ui/icons/Refresh'

import { 
  withStyles 
} from '@material-ui/core/styles'

import demoInstrumentSchdule from './reports/sample-instrument-schedule'
import demoNotes from './reports/sample-notes'
import demoCues from './reports/sample-cues'

import Languages from './../../shared/language'
import { 
  showDialog, 
  showConfirm 
} from '../reducers/InterfaceLogic'

import {
  addReportFilter,
  addUpdatePdfReport,
  deleteReport,
} from './../reducers/ReportsLogic'

import {
  mergeMissingMappings
} from './../reducers/WorksheetsLogic'

import ChipSet from './ChipSet'

import {
  PAPER_US_LETTER_PORTRAIT
} from './../../shared/printing/GenericReport'

const fonts = [
  'Helvetica',
  'Helvetica-Bold',
  'Helvetica-Oblique',
  'Helvetica-BoldOblique',
  'Courier',
  'Courier-Bold',
  'Courier-Oblique',
  'Courier-BoldOblique',
  'Times-Roman',
  'Times-Bold',
  'Times-Italic',
  'Times-BoldItalic',
]

const containers = [
  'none',
  'roundedRectangle',
  'rectangle',
]
const TAB_SETUP = 0
const TAB_PREVIEW = 1
const TAB_DATA = 2
const TAB_FILTERS = 3
const TAB_EDITOR = 4

const PAGE_HEADER_ARRAY = 'header'
const COLUMN_HEADER_OBJECT = 'heading'
const PEOPLE_FORMAT = 'people'
const DATA_COLUMN_PRIMARY = 'columns'
const DATA_COLUMN_OPTIONAL = 'columnsOptional'
const REPORT_DATA_SOURCE = 'data'
const REPORT_FILTERS = 'data'
const REPORT_FORMATTING = 'formatting'

const PANEL_DATA_SUMMARY = 'dataPanelSummary'
const PANEL_DATA_SOURCE = 'dataPanelSource'
const PANEL_DATA_SORTING = 'dataPanelSorting'
const PANEL_DATA_FILTERS = 'dataPanelFilters'
const PANEL_DATA_PREVIEW = 'dataPanelPreview'

export const DATA_SET_CUES = 'cues'
export const DATA_SET_INSTRUMENTS = 'instruments'
export const DATA_SET_NOTES = 'notes'

const FlashHelp = (props) => {
  return (
    <div 
      className='flash'
      style={{
        margin: 'auto',
        padding: 8,
        borderRadius: 5,
        maxWidth: 350,
      }}
      dangerouslySetInnerHTML={{
        __html: props.text
      }}
    />
  )
}
const QuickPanel = (props) => {
  const [open, setOpen] = useState(props.open)

  return (
    <ExpansionPanel expanded={open} onChange={_ => setOpen(!open) }>
      {props.children}
    </ExpansionPanel>
  )
}
const QuickPanelSummary = (props) => {
  const noPad = {
    padding: 0,
    margin: 0,
    flex: 1,

  }

  const subheading = {
    flex:0,
    whiteSpace: 'nowrap'
  }
  return (
      <ExpansionPanelSummary 
        expandIcon={<ExpandMore />}
      >
        { props.title && <h3 style={noPad}>{props.title}</h3>}
        { props.subheading && <div style={subheading}>{props.subheading}</div> }

        { props.children }
        <h3 
          style={{
            padding: 0,
            margin: 0,
          }}
        >
          { props.children}
        </h3>
      </ExpansionPanelSummary>
  )
}

const QuickPanelDetails = (props) => {
  return (
    <ExpansionPanelDetails>
      <div style={{display: 'block'}}>
        {props.children}
      </div>
    </ExpansionPanelDetails>
  )
}

const PropertyText = (props) => {
  const style = {
    marginTop: 8,
    ...props.style,
  }
  return (
    <TextField 
      InputLabelProps={{
        shrink: true,
      }}
      style={style}
      variant='filled' 
      size='small'
      {...props} 
    />
  )
}

const PropertyNumber = (props) => {
  return (
    <PropertyText type='number' {...props} />
  )
}


const TopLevelTreeItem = withStyles( theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  label: {
    fontWeight: 'bold',
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
  },
}))( props => {
  return (
    <TreeItem 
        style={{
          fontWeight: 'bold',
        }}
      {...props} 
    />
  )
})

const TextTreeItem = withStyles( theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  label: {
    color: 'blue',
    fontWeight: 'bold',
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
  },
}))( props => {
  return (
    <TreeItem 
        style={{
          fontWeight: 'bold',
        }}
      {...props} 
    />
  )
})

const PropertyTreeItem = withStyles( theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  label: {
    color: 'purple',
    fontWeight: 'bold',
  },
  group: {
    marginLeft: 12,
    paddingLeft: 12,
  },
}))( props => {
  return (
    <TreeItem 
        style={{
          fontWeight: 'bold',
        }}
      {...props} 
    />
  )
})

const TextHeader = (props) => {
  const { text } = props
  return (
    <div
      style={{
        backgroundColor: window.theme.la_blue,
        color: window.theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 16,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        margin: '1px 1px 0px 1px',
      }}
    >
      <span
        style={{
          flex: 1,
        }}
      >
        { text }
      </span>
      
      <span
        style={{
          flex: 0,
          display: 'flex',
          flexDirection: 'row',
          color: window.theme.palette.common.white,
        }}
      >
        { props.children }
      </span>
      
    </div>
  )
}
const UI_TIMER_TIMOUT = 300

const SKELETON_REPORT = {
  ...PAPER_US_LETTER_PORTRAIT,
}
class PdfFrame extends React.Component {
  
  componentDidUpdate() {
    console.log('PDF-FRAME::update')
    if(this.innerFrame) {
      this.innerFrame.src = '/web/viewer.html?file=' + this.props.url  
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.url != this.props.url) {
      return true
    } else {
      return false
    }
  }
  componentDidMount() {
    console.log('PDF-FRAME::MOUNT')
    if(this.innerFrame) {
      this.innerFrame.src = '/web/viewer.html?file=' + this.props.url  
    }
  }

  render() {
      return (<iframe 
          style={{
            flex: 1,
            width: '100%',
            minHeight: '800px'
          }}
          ref={x => this.innerFrame = x} 
        />  
    )
  }
}

class ReportEditor extends React.Component {
  constructor(props) {
    super(props)
    this.copy = null
    this.backup = null

    this.T = Languages.reportEditor
    this.INSTRUMENTS = Languages.instruments
    this.CUES = Languages.cueSheet
    this.NOTES = Languages.notes
    //we need more info here
    this.state = {
      tab: 0,
      report: null,
      reportIndex: -1,
      backup: null,
      mappings: [],
      editing: null,
      editSection: -1,
      editIndex: -1,
      //UI Stuff
      showAll: false,
      expansions: {},
      saved: false,
      reportName: 'unsaved',
      reportToLoad: -1,
      pdfPreview: null,
      dataPreview: null,
    }

    this.cache = {}

    //TIMERS
    this.pdfRefreshTimer = null
  }

  closeEditors() {
    this.setState({
      tab: TAB_SETUP,
      editing: null,
      editSection: -1,
      editIndex: -1,
      report: null,
      reportIndex: -1,
      dataPreview: null,
      pdfPreview: null,
    })
  }

  closeReport() {
    this.props.dispatch(
      showConfirm('Close Report', 'Are you sure you want to close without saving?', ()=>{
        this.closeEditors()
      })
    )
  }

  createNewReport() {
    const base = JSON.parse(JSON.stringify(this.copy))
    base.columns = []
    base.name = 'New Report'
    delete base.InputLabelProps
    this.setState({
      report: base,
      reportIndex: -1,
      dataPreview: null,
      pdfPreview: null,
    })
  }


  runPdfPreview() {
    if(!this.state.report) {
      alert('Report is not loaded!')
      return
    }

    this.props.dispatch( dispatchCreatePdfBlob(this.state.report, (blob) => {
      const url = URL.createObjectURL(blob)

      if(this.pdfFrame){
        this.pdfFrame.src = '/web/viewer.html?file=' + url  
      }
      
      this.setState({
        pdfPreview: url,
      })
    }))
  }


  runDataPreview() {
    if(!this.state.report) {
      alert('Report is not loaded')
    }

    this.setState({
      dataPreview: {test:'data'},
    })
  }
  
  _renderDataPreviewTable() {
    return <div>Not implemented...</div>
  }

  loadPdfReport(index) {
    const report = this.props.pdf[index]
    if(report) {

      const mappings = this.updateDataSourceReturn(report.data)

      const defaults = {
        SKELETON_REPORT,
        ...report
      }
      this.setState({
        report: JSON.parse(JSON.stringify(defaults)),
        reportIndex: index,
        mappings: mappings,
      })
    }

    // this.props.dispatch( 
    //   showConfirm('Report Editor', `
    //   <p>
    //   The report editor is an experimental feature requested by
    //   several users. It does not have a drag and drop user interface
    //   or a preview yet, but those features are on the horizon.
    //   </p>
    //   <p>
    //     Your report is edited by using the outline on the left
    //     and then editing properties on the right or in the main window.
    //     You can then save these reports which end up in PDF or Excel 
    //     repsectively. Any feedback is welcome.
    //   </p>
    //   `, ()=>{
    //   })
    //)
  }

  savePdfReport() {
    this.props.dispatch(
      addUpdatePdfReport(this.state.report, this.state.reportIndex)
    )

    // this.closeEditors()
  }

  downloadPdfReport() {
    const json = JSON.stringify(this.state.report)
    const name = `${this.state.report.name || 'My Report'}.lar`
    if( isConnected() ) {
      electronSave ( json, name)
    } else {
      const a = document.createElement('a')
      const blobData = new Blob([json], { type: 'text/json' })
      const blobUrl = URL.createObjectURL(blobData)
      a.href = blobUrl
      a.download = name
      a.innerHTML = 'download TEMP'

      const container = document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }

  loadSampleInstruments() {
    this.setState({
      report: JSON.parse(JSON.stringify(demoInstrumentSchdule))
    })
  }

  loadSampleNotes() {
    this.setState({
      report: JSON.parse(JSON.stringify(demoNotes))
    })
  }

  loadSampleCues() {
    this.setState({
      report: JSON.parse(JSON.stringify(demoCues))
    })
  }

  cancelColumnEdits() {
    const mode = this.state.editSection
    const copy = { ...this.state.report }
    
    if(mode === DATA_COLUMN_PRIMARY) {
    
      const columns = copy.columns.slice()
      columns[this.state.editIndex] = { ...this.state.editing }
      copy.columns = columns

    } else if (mode === DATA_COLUMN_OPTIONAL) {

      const columnsOptional = copy.columnsOptional.slice()
      columnsOptional[this.state.editIndex] = { ...this.state.editing }
      copy.columns = columnsOptional

    }

    this.setState({
      report: copy,
      editing: null,
      editIndex: -1,
      editSection: -1,
    })

    this._stopEdit()
  }

  toggleShowAll() {
    this.updateDataSource(this.state.report.data, !this.state.showAll)
  }

  switchTab(tab) {
    const current = this.state.tab

    if(tab === current) {
      return
    }

    if(tab === TAB_DATA) {
      this.updateDataSource(this.state.report.data)
    }

    this.setState({
      tab: tab
    })

    if(tab === TAB_PREVIEW) {
      this.updateDataSource(this.state.report.data)
      setTimeout(()=>{
        this.runPdfPreview()
      }, 250)
    }
  }

  _stopEdit() {
    this.setState({
      editIndex: -1,
      editSection: -1,
      editing: null,
    })
  }

  _masterEdit(mode, index) {
    let editing = null
    if(mode === PAGE_HEADER_ARRAY) {
      editing = {
        ...this.state.report.header[index]
      }
    } else if (mode === COLUMN_HEADER_OBJECT) {
      editing = {
        ...this.state.report.heading
      }

      const subheading = this.state.report.subheading || {}
      this.cache.headerColor = this.state.report.headerColor || this.props.show.color || '#0d47a1'
      this.cache.headerText = this.state.report.headerTextColor || '#fff'
      this.cache.subheadingColor = subheading.color || 'black'
      this.cache.subheadingFill = subheading.fill || 'white'
    } else if(mode === DATA_COLUMN_PRIMARY) {
      editing = {
        ...this.state.report.columns[index]
      }
    }
    else if (mode === DATA_COLUMN_OPTIONAL) {
      editing = {
        ...this.state.report.columnsOptional[index]
      }
    } else if (mode === REPORT_FORMATTING) {
      const formatting = this.state.report.formatting || {}
      this.cache.rowLineColor = formatting.rowLineColor || '#ddd'
      this.cache.seperatorColor = formatting.seperatorColor || '#ccc'
      this.cache.stripeColor = this.state.report.stripeColor || '#f4f4f4'
    }

    this.setState({
      editIndex: index,
      editSection: mode || null,
      editing,
    })
  }

  editHeader(index) {
    this._masterEdit(PAGE_HEADER_ARRAY, index)
  }

  editDataHeader() {
    this._masterEdit(COLUMN_HEADER_OBJECT, 0)
  }

  editSecondaryColumn(index) {
    this._masterEdit(DATA_COLUMN_OPTIONAL, index)
  }

  addColumn() {
    if(this.state.report) {
      const newIndex = this.state.report.columns.length
      this._masterEdit(DATA_COLUMN_PRIMARY, newIndex)
    }
  }

  addColumnOptional() {
    if(this.state.report) {
      const newIndex = this.state.report.columnsOptional.length
      this._masterEdit(DATA_COLUMN_OPTIONAL, newIndex)
    }
  }

  deleteColumn(mode, index) {
    if(!this.state.report) {
      return
    }
    let copy = { ...this.state.report }
    if(mode === DATA_COLUMN_PRIMARY) {
      const columns = copy.columns.slice()
      columns.splice(index, 1)
      copy = {
        ...copy,
        columns: columns
      }
    } else if (mode === DATA_COLUMN_OPTIONAL) {
      const columnsOptional = copy.columnsOptional.slice()
      columnsOptional.splice(index, 1)
      copy = {
        ...copy,
        columnsOptional: columnsOptional
      }
    }
    
    this.setState({
      report: copy,
      editIndex:-1,
      editSection: -1,
      editing: null,
    })
  }

  editorTab() {
    if(!this.state.report) {
      return this._noDataTab()
    }
    
    return (
      <div style={{padding: 16}}>
        <code>
          { JSON.stringify(this.state.report, null, 2) }
        </code>
      </div>
    )
  }

  previewTab() {
    if(!this.state.report) {
      return this._noDataTab()
    }

    const frame = (
      <iframe 
          onLoad
          style={{
            flex: 1,
            width: '100%',
            minHeight: '800px'
          }}
          ref={x => this.pdfFrame = x} 
        />  
    )
    return (
      <div style={{padding:16}}>
        <Button
          variant='outlined'
          onClick={ () => {
            this.runPdfPreview()
          }}
        >
          Refresh PDF
          <IconRefresh />
        </Button>

        <div
          style={{
            border: '3px solid orange'
          }}
        >
          <PdfFrame url={this.state.pdfPreview} />
        </div>
      </div>
    )
    if(!this.state.pdfPreview) {
      return (<div style={{padding:16}}>

      </div>)
    }
    
    if(this.state.pdfPreview) {
      
    }
    return (
      <div style={{padding: 16}}>
        PREVIEW Coming Soon
      </div>
    )
  }

  filterTab() {
    
    if(!this.state.report) {
      return this._noDataTab()
    }
    
    const { filters } = this.props
    const addFilter = () => {
      return (
        <Button
          color='primary'
          variant='contained'
          onClick={e => {
            this.props.dispatch(
              addReportFilter(`New Filter ${filters.length + 1}`)
            )
          }}
        >
          Add Filter
        </Button>
      )
    }

    if(!filters || filters.length < 1) {
      return (
        <div style={{padding: 16}}>
          <h4>No filters currently defined</h4>
          { addFilter() }
        </div>
      )
    }

    return (
      <div 
        style={{
          padding: 16,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        { filters.map( (f,i) => {
          return (
            <div>
              <h4>Filter: {f.name} @ {i}</h4>
              <button>actions</button>
            </div>
          )
        } ) }
        <Box>
          { addFilter() }
        </Box>
        
      </div>
    )
  }

  variablesList() {
    const instruments = [
      ['{colorAndTemplate}', 'A combination of color, frost, template1, template2'],
      ['{instTypeAccessories}', 'A combination of Instrument Type and Accessories'],
      ['not really finished', 'Not even remotely finished, please ask for anything...'],
    ]

    return (
      <React.Fragment>
        <p>
          Light Assistant uses a variety of variables to 
          help complex formatting. This is based loosely on ECMAScript 6 
          string templates.
        </p>

        <h4>Instrument Data</h4>
        <table>
          <thead>
            <tr>
              <th>Variable</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            { instruments.map((a, i) => {
              return (
                <tr>
                  <td 
                    style={{
                      fontFamily: 'monospace',
                      whiteSpace: 'pre',
                    }} 
                    className='key'
                  >
                    {a[0]}
                  </td>
                  <td className='description'>{a[1]}</td>
                </tr>
              )
            })}
          </tbody>

        </table>
        <h4>Global</h4>
      </React.Fragment>
    )
  }

  setDataTab(section = 'data', panel = PANEL_DATA_SUMMARY) {
    const expansions = {
      ...this.state.expansions
    }
    expansions[section] = panel
    this.setState({
      expansions: expansions
    })
  }
  _noDataTab() {
    return <div style={{padding: 16}}>{this.T.get('noReportLoaded')}</div>
  }

  dataTab() {

    if(!this.state.report) {
      return this._noDataTab()
    }

    const T = this.T
    const join = (array, def = T.get('none')) => {
      if(!array || array.length < 1) {
        return def
      }

      return array.join(' > ')
    }
    const mono = {
      font: 'monospace',
      whiteSpace: 'pre',
    }

    const report = this.state.report
    const filters = report.filters || []
    const group = report.group || []
    const sort = report.sort || []
    
    const ifEmpty = (cond, comp) => {
      if(!cond || cond.length < 0) {
        return comp
      }
    }
    const renderFilters = () => {
      if(!filters || filters.length < 0) {
        return (<div>No Filters Applied</div>)
      }
    }

    const paperStyle = {
      padding: 16,
    }

    const isCues = this.state.report.data != DATA_SET_CUES

    return (
      <div style={{padding: 16}}>

        <QuickPanel open={true}>
          <QuickPanelSummary
            title={T.get('dataSourceSummary')}
            subheading={ T.get(this.state.report.data) }
          />
          <QuickPanelDetails>
            <div style={{maxWidth: 500}}>
              {this.renderDataSourceProperties()}
              </div>
          </QuickPanelDetails>
        </QuickPanel>

        { isCues && 
        <QuickPanel>
          <QuickPanelSummary 
            title={T.get('groupsAndSorting')}
            subheading={T.get('groupByList', join(group) ) + ' ' + T.get('sortByList', join(sort)) }
          />
          <QuickPanelDetails>
            <div
              style={{
                display: 'block',
              }}
            >
              <h2>{T.get('groupBy')}</h2>
                { this.state.report.data == DATA_SET_INSTRUMENTS  ? 
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginTop: -48,
                  }}
                >
                  <Tooltip
                    title={ T.get('showAllFieldsTip') }
                  >
                    <FormControlLabel 
                      control={
                        <Switch 
                          checked={this.state.showAll}
                          onChange={e => {
                            this.toggleShowAll()
                          }}
                        />
                      }
                      label={T.get('showAllFields')}
                    />
                  </Tooltip>
                </div>
                
                  
                : null } 
                <ChipSet 
                  color='primary'
                  available={ this.state.mappings }
                  selected={ group }
                  onSelect={ x => {
                    this.addGroup(x)
                  }}
                  onDelete={ x => {
                    this.deleteGroup(x)
                  }}
                  toLabel={o => {
                    return o
                  }}
                />

                <h2>{T.get('sortBy')}</h2>
                <ChipSet 
                  color='secondary'
                  available={ this.state.mappings }
                  selected={ sort }
                  onSelect={ x => {
                    this.addSort(x)
                  }}
                  onDelete={ x => {
                    this.deleteSort(x)
                  }}
                  toLabel={o => {
                    return o
                  }}
                />
              </div>
          </QuickPanelDetails>
        </QuickPanel>
        }
        
        { isCues && 
        <QuickPanel>
          <QuickPanelSummary
            title={T.get('filters')}
            subheading={filters.join(' > ')}
          >
          </QuickPanelSummary>
          <QuickPanelDetails>
            Filter controls here
          </QuickPanelDetails>
        </QuickPanel>
        }

        <QuickPanel>
          <QuickPanelSummary
            title={T.get('dataPreview')}
          />
          <QuickPanelDetails>
            Data Preview Based on set...
            <CircularProgress />
          </QuickPanelDetails>
        </QuickPanel>

      </div>
    )
  }

  setupTab() {
    const buttonPad = {
      marginLeft: 8,
    }
    const T = this.T
    let name

    if(this.state.report) {
      name = this.state.report.name || 'No Name'
    }

    name = 'No Name'

    const header = (text) => {
      return (
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>
              <h3>{text}</h3>
            </TableCell>
          </TableRow>
        </TableHead>
      )
    }

    const cell = (any) => {
      return <TableCell>{any}</TableCell>
    }

    const reports = this.props.pdf

    const saveReport = (
      <Button
        variant='outlined'
        onClick={ e => { 
          this.savePdfReport()
        } }
      >
        <IconSave /> Save Report
      </Button>
    )
    const downloadReport = (
      <Button
        style={buttonPad}
        variant='outlined'
        onClick={ e => { 
          this.downloadPdfReport()
        } }
      >
        <IconDownload /> Download Copy
      </Button>
    )

    const closeReport = (
      <Button
        style={buttonPad}
        variant='outlined'
        onClick={ e => { 
          this.closeReport()
        } }
      >
        <IconCancel/> Close Report
      </Button>
    )
    return (
      <div style={{padding: 16}}>
        <QuickPanel open={true}>
          <QuickPanelSummary 
              title='Current Report'
            />
          <ExpansionPanelDetails style={{flexDirection: 'column'}}>
            { this.state.report ? 
              <React.Fragment>
                <Box>
                  <TextField 
                    label={T.get('reportName')}
                    helperText={T.get('reportNameHelp')}
                    value={this.state.report.name}
                    onChange={e => {
                      this.setState({
                        report: {
                          ...this.state.report,
                          name: e.target.value,
                        }
                      })
                    }}
                  />
                </Box>
                
                <Box
                  style={{
                    flex: 1,
                    marginTop: 24,
                  }}
                >
                  { saveReport }
                  { downloadReport }
                  { closeReport }
                </Box>

                
                { name || 'Unnamed'}
              </React.Fragment>
            : 
              <React.Fragment>
                <h2>No Active Report</h2>
              </React.Fragment>
            }
          </ExpansionPanelDetails>
        </QuickPanel>
        <QuickPanel>
          <QuickPanelSummary 
            title='PDF Reports'
          />
          <QuickPanelDetails>
            <h2>Default Reports</h2>
            Not implemented yet...

            <h2>Custom Reports</h2>
            { this.props.pdf.length < 1 && <Paper>No custom reports, please create or load them</Paper>}
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{fontWeight: 'bold'}}>
                  <TableRow>
                    <TableCell>
                      { T.get('reportName') }
                    </TableCell>
                    <TableCell>
                      { T.get('reportData') }
                    </TableCell>
                    <TableCell>
                      { T.get('filters') }
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.pdf.map((r,i) => {
                    return (
                      <TableRow key={'tr-' + i}>
                        <TableCell>{r.name}</TableCell>
                        <TableCell>{r.data}</TableCell>
                        <TableCell>{(r.filters || []).map(f => f.name).join(', ')}</TableCell>
                        <TableCell>
                          <Button
                          variant='outlined'
                          onClick={e => {
                            this.loadPdfReport(i)
                          }}
                        >
                          { T.get('loadReport') }
                        </Button>
                        </TableCell>
                      </TableRow>
                      
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </QuickPanelDetails>
        </QuickPanel>

        <QuickPanel>
          <QuickPanelSummary 
            title='Instrument Worksheets'
          />
          <QuickPanelDetails>
            <Table>
            <TableBody>
            { this.props.worksheets.map( (x, i) => {
              return (
                <TableRow key={'worksheet-'+x.name + i}>
                  { cell(x.name) }
                  { cell(JSON.stringify(x.sort) ) }
                  { cell(JSON.stringify(x.grouping) ) }
                  <TableCell>
                    <button>clone to pdf</button>
                    <button>clone to excel</button>                
                  </TableCell>
                </TableRow>
              )
            })}
            </TableBody>
          </Table>
          </QuickPanelDetails>
        </QuickPanel>      

        <QuickPanel>
          <QuickPanelSummary 
            title='Excel Worksheets'
          />
          <QuickPanelDetails>
            Not implemented yet, but it is coming
          </QuickPanelDetails>  
        </QuickPanel>

        <Box
              style={{
                flexDirection: 'row',
                marginTop: 24,
              }}
            >
              <Button
                style={buttonPad}
                variant='outlined'
                onClick={ e => { 
                  this.createNewReport()
                } }
              >
                Create New Report
              </Button>
              <Button
                variant='outlined'
                style={buttonPad}
                onClick={e => this.loadSampleInstruments()}
              >
                Create Sample Schedule
              </Button>

              <Button
                variant='outlined'
                style={buttonPad}
                onClick={e => this.loadSampleNotes()}
              >
                Create Sample Notes
              </Button>

              <Button
                variant='outlined'
                style={buttonPad}
                onClick={e => this.loadSampleCues()}
              >
                Create Sample Cues
              </Button>
            </Box>
      </div>
    )
  }

  tab(index, children) {
    if(this.state.tab !== index) {
      return null
    }

    return (
      <div 
        style={{
          height: '100%',
          overflowY: 'auto',
          flex: 1,
        }}
      >
        {children}
      </div>
    )
  }

  addGroup(g) {
    const report = this.state.report
    if(!this.state.report) {
      return
    }
    const group = (report.group || []).slice()
    const exists = group.findIndex(f => f == g)
    if(exists > -1) {
      return
    }

    group.push(g)

    this.setState({
      report: {
        ...report,
        group: group,
      }
    })
  }

  deleteGroup(g) {
    const report = this.state.report
    if(!this.state.report) {
      return
    }

    const group = (report.group || []).slice()
    const index = group.findIndex(f => f == g)
    if(index < 0) {
      return
    }

    group.splice(index, 1)
    this.setState({
      report: {
        ...report,
        group: group,
      }
    })
  }

  addSort(s) {
    const report = this.state.report
    if(!this.state.report) {
      return
    }
    const sort = (report.sort || []).slice()
    const exists = sort.findIndex(f => f == s)
    if(exists > -1) {
      return
    }

    sort.push(s)

    this.setState({
      report: {
        ...report,
        sort: sort,
      }
    })
  }

  deleteSort(s) {
    const report = this.state.report
    if(!this.state.report) {
      return
    }

    const sort = (report.sort || []).slice()
    const index = sort.findIndex(f => f == s)
    if(index < 0) {
      return
    }

    sort.splice(index, 1)
    this.setState({
      report: {
        ...report,
        sort: sort,
      }
    })
  }

  refreshPdfOnBlur() {
    if(this.state.tab === TAB_PREVIEW) {
      clearTimeout(this.pdfRefreshTimer)
      this.pdfRefreshTimer = setTimeout(()=>{
        this.runPdfPreview()
      }, UI_TIMER_TIMOUT)
    } else {
      clearTimeout(this.pdfRefreshTimer)
    }
  }
  /**
   * Update a column by merging new values. This 
   * allows for hidden values
  * @param {*} index 
  * @param {*} property 
  * @param {*} newValue 
  */
  updateColumn(mode, index, property, newValue) {
    const report = this.state.report

    if(mode === DATA_COLUMN_PRIMARY) {
      const columns = this.state.report.columns.slice()

      const column = {
        ...columns[index],
      }
      column[property] = newValue
  
      columns[index] = column
      report.columns = columns
      this.setState({
        ...report
      })

      this.refreshPdfOnBlur()
    } else if (mode === DATA_COLUMN_OPTIONAL) {
      const columnsOptional = this.state.report.columnsOptional.slice()


      const column = {
        ...columnsOptional[index],
      }
      column[property] = newValue
  
      columnsOptional[index] = column
      report.columnsOptional = columnsOptional
      this.setState({
        ...report
      })

      this.refreshPdfOnBlur()
    }
  }

  updateHeading(prop, val) {
    const report = this.state.report
    if(!report) {
      return
    }

    const heading = {...report.heading}
    heading[prop] = val
    this.setState({
      report: {
        ...report,
        heading: heading,
      }
    })

    this.refreshPdfOnBlur()
  }

  updateRootProp(prop, val) {
    const report = this.state.report
    if(!report) {
      return
    }

    const copy = {...report}
    copy[prop] = val
    this.setState({
      report: copy
    })

    this.refreshPdfOnBlur()
  }

  updateDeep(obj, prop, val) {
    const report = this.state.report
    if(!report) {
      return
    }

    const copy = {...report}

    const merge = {...report[obj]}
    merge[prop] = val
    copy[obj] = merge

    this.setState({
      report: copy
    })

    this.refreshPdfOnBlur()
  }

  resetReportColors() {
    const report = this.state.report
    if(!report) {
      return
    }

    const copy = {...report}
    delete copy.headerText
    delete copy.headerColor
    delete copy.subheading.color
    delete copy.subheading.fill
    
    this.cache.headerColor = this.state.report.headerColor || this.props.show.color || '#0d47a1'
    this.cache.headerText = this.state.report.headerText || '#fff'
    this.cache.subheadingColor = this.state.report.subheading.color || 'black'
    this.cache.subheadingFill = this.state.report.subheading.fill || 'white'
    this.setState({
      report: copy
    })

    this.refreshPdfOnBlur()
  }

  resetReportStripeColors() {
    const report = this.state.report
    if(!report) {
      return
    }

    const copy = {...report}
    delete copy.stripeColor
    delete copy.formatting.rowLineColor
    delete copy.formatting.seperatorColor

    const formatting = this.state.report.formatting || {}
    this.cache.rowLineColor = formatting.rowLineColor || '#ddd'
    this.cache.seperatorColor = formatting.seperatorColor || '#ccc'
    this.cache.stripeColor = copy.stripeColor || '#f4f4f4'

    this.setState({
      report: copy
    })

    this.refreshPdfOnBlur()
  }

  updatePageHeader(index, prop, val) {
    const report = this.state.report
    if(!report) {
      return
    }

    const header = (this.state.report.header || []).slice()
    
    const el = header[index] || {}
    el[prop] = val
    header[index] = el

    this.setState({
      report: {
        ...report,
        header: header,
      }
    })

    this.refreshPdfOnBlur()
  }

  updateDataSourceReturn(source, override = null) {
    const mappings = []
    const showAll = override === true ? true : override === false ? false : !!this.state.showAll

    if(source === DATA_SET_CUES) {
      // mappings.push('no')
      // mappings.push('notes')
      // mappings.push('yet')
    } else if (source === DATA_SET_INSTRUMENTS) {
      
      const output = mergeMissingMappings(this.props.vectorworks, null, showAll).fields
      for(const obj of output) {
        if(!obj.disabled) {
          mappings.push(obj.property)
        }
        
      }
    } else if (source === DATA_SET_NOTES) {
      mappings.push('department')
      mappings.push('status')
      mappings.push('category')
      mappings.push('priority')
      mappings.push('date')
      mappings.push('description')
    }

    mappings.sort()
    return mappings
  }
  /**
   * Update the report to use the new data source
   * @param {*} source 
   */
  updateDataSource(source, override = null) {
    const mappings = this.updateDataSourceReturn(source, override)
    const showAll = override === true ? true : override === false ? false : !!this.state.showAll

    this.setState({
      report: {
        ...this.state.report,
        data: source,
      },
      showAll: showAll,
      mappings: mappings
    })
  }

  renderDataSourceProperties() {
    const source = this.state.report || {}
    const T = this.T
    const data = source.data
    const selected = (s => s === data ? 'contained' : 'outlined')
    const color = (s => s === data ? 'primary' : 'default')
    const textDescription = source.data ? source.data + 'Description' : 'noDataSourceDescription'
    const renderButton = (key) => {
      return (
        <Button
          color={color(key)}
          onClick={ () => this.updateDataSource(key)}
          variant={selected(key)}
        >
          { T.get(key) }
        </Button>
      )
    }
    return (
      <React.Fragment>
        { renderButton(DATA_SET_CUES) }
        { renderButton(DATA_SET_INSTRUMENTS) }
        { renderButton(DATA_SET_NOTES) }

        <div dangerouslySetInnerHTML={{__html: T.get(textDescription)}}></div>
      </React.Fragment>
    )
  }

  editColumnHeaderProperties() {
    const T = this.T

    const heading = {
      text: '',
      font: 'Helvetica-Bold',
      fontSize: 14,
      posX: 0,
      posY: 0,
      height: 20,
      ...this.state.report.heading,
    }

    const inputStyle = {
      // zoom: 0.9,
      marginTop: 8,
      // marginLeft: 16,
      // marginRight: 16,
    }
    const subheading = { 
      font: 'Helvetica-Bold',
      fontSize: 10,
      fill: '#ffffff',
      color: '#000000',
      wrap: true,
      ...this.state.report.subheading 
    }
    return (
      <React.Fragment>
        <PropertyText 
          label={ T.get('text') }
          helperText={ T.get('textHint')}
          style={inputStyle}
          value={ heading.text }
          onChange={e => {
            const text = e.target.value
            this.updateHeading('text', text)
          }}
        />

        <TextField
          select
          style={inputStyle}
          label={T.get('font')}
          helperText={T.get('fontHint')}
          variant='filled'
          label={T.get('font')}
          value={heading.font || fonts[0]}
          helperText={T.get('fontHint')}
          onChange={e => {
            const update = e.target.value
            this.updateHeading('font', update)
          }}
        >
          { fonts.map((f,i)=>{
            return (
              <MenuItem value={f}>
                {f}
              </MenuItem>
            )
          })}
        </TextField>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PropertyNumber
            label={ T.get('height') }
            helperText={ T.get('heightHint')}
            style={{
              ...inputStyle,
              width: 120,
            }}
            value={ heading.height }
            onChange={e => {
              const n = e.target.value
              this.updateHeading('height', num(n))
            }}
          />

          <PropertyNumber 
            label={ T.get('fontSize') }
            helperText={ T.get('fontSizeHint')}
            style={{
              ...inputStyle,
              width: 120,
              marginLeft: 12,
            }}
            value={ heading.fontSize }
            onChange={e => {
              const n = e.target.value
              this.updateHeading('fontSize', num(n))
            }}
          />
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PropertyNumber
            label={ T.get('posX') }
            helperText={ T.get('posXHint')}
            style={{
              ...inputStyle,
              width: 120,
            }}
            value={ heading.posX }
            onChange={e => {
              const n = e.target.value
              this.updateHeading('posX', num(n))
            }}
          />

          <PropertyNumber
            label={ T.get('posY') }
            helperText={ T.get('posYHint')}
            style={{
              ...inputStyle,
              width: 120,
              marginLeft: 12,
            }}
            value={ heading.posY }
            onChange={e => {
              const n = e.target.value
              this.updateHeading('posY', num(n))
            }}
          />
        </Box>

        <h3>Heading Colors</h3>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ColorPicker
            animation="slide-up"
            color={this.cache.headerText}
            onChange={colors => {
              this.cache.headerText = colors.color
            }}
            onClose={_ => {
              this.updateRootProp('headerTextColor', this.cache.headerText)
            }}
          />

          <ColorPicker
            animation="slide-up"
            color={this.cache.headerColor}
            onChange={colors => {
              this.cache.headerColor = colors.color
            }}
            onClose={_ => {
              this.updateRootProp('headerColor', this.cache.headerColor)
            }}
          />

        </Box>
        
        <h3>Subheading</h3>
        <TextField
          select
          style={inputStyle}
          label={T.get('font')}
          helperText={T.get('fontHint')}
          variant='filled'
          label={T.get('font')}
          value={subheading.font || fonts[0]}
          helperText={T.get('fontHint')}
          onChange={e => {
            const update = e.target.value
            this.updateDeep('subheading', 'font', update)
          }}
        >
          { fonts.map((f,i)=>{
            return (
              <MenuItem value={f}>
                {f}
              </MenuItem>
            )
          })}
        </TextField>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PropertyNumber 
            label={ T.get('fontSize') }
            helperText={ T.get('fontSizeHint')}
            style={{
              ...inputStyle,
              width: 120,
              marginLeft: 12,
            }}
            value={ subheading.fontSize}
            onChange={e => {
              const n = e.target.value
              this.updateDeep('subheading', 'fontSize', num(n))
            }}
          />

          <FormControl
            style={{
              ...inputStyle,
              marginLeft: 12,
              width: 90,
            }}
          >
            <FormControlLabel 
              control={
                <Checkbox 
                  checked={!!subheading.wrap}
                  onChange={e => {
                    let val = !!e.target.checked
                    this.updateDeep('subheading', 'wrap', !!val)
                  }}
                />
              }
              label={T.get('wrap')}
            />
            <FormHelperText>
            {T.get('wrapHint')}
            </FormHelperText>
          </FormControl>
          { subheading.wrap }
          
        </Box>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ColorPicker
            animation="slide-up"
            color={this.cache.subheaderColor}
            onChange={colors => {
              this.cache.subheaderColor = colors.color
            }}
            onClose={_ => {
              this.updateDeep('subheading', 'color', this.cache.subheaderColor)
            }}
          />

          <ColorPicker
            animation="slide-up"
            color={this.cache.subheadingFill}
            onChange={colors => {
              this.cache.subheadingFill = colors.color
            }}
            onClose={_ => {
              this.updateDeep('subheading', 'fill', this.cache.subheadingFill)
            }}
          />
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* updateRootProp */}

          Background {this.cache.headerColor || '#0D47A1'}
          Text {this.cache.headerText || '#ffffff'}
        </Box>
        <div>
          <div
            style={{
              backgroundColor: this.cache.headerColor || '#0D47A1',
              color: this.state.report.headerCacheText || '#ffffff',
              padding: 8,
              fontWeight: 'bold',
            }}
          >
            Demo Header Look And Feel
          </div>
        </div>
        <Button 
          variant='outlined'
          onClick={e => {
            this.resetReportColors()
          }}
        >
          Reset Default Colors
        </Button>
      </React.Fragment>
    )
  }

  renderPageHeaderFieldProperties(index) {
    const element = {...this.state.report.header[index]}
    const textNode = { 
      text: '',
      posX: 0,
      posY: 0,
      width: 468,
      fontSize: 16,
      font: 'Helvetica-Bold',
      align: 'center',
      wrap: false,
      ...element
     }

    const T = this.T

    if(!textNode) {
      return <h3>No data for column {index}</h3>
    }

    const inputStyle = {
      // zoom: 0.9,
      marginTop: 8,
      // marginLeft: 16,
      // marginRight: 16,
    }

    const cardTitleProps = {
      //marginTop:0,
      padding: 0,
      paddingLeft: 12,
      paddingTop: 6,
      margin: 0,
      marginBottom: 4,
    }

    const align = (val) => {
      if(textNode.align === val) {
        return 'primary'
      } else {
        return 'default'
      }
    }

    const handleCheckbox = (prop, event) => {
      const checked = !!event.target.checked
      this.updatePageHeader(index, prop, checked)
    }

    const change = (e, key) => {
      this.updatePageHeader(index, key, e.target.value)
    }

    const changeNum = (e, key) => {
      this.updatePageHeader(index, key, num(e.target.value) )
    }

    if(!element.logo) {
      return (
        <React.Fragment>
          <PropertyText 
            label={ T.get('text') }
            helperText={ T.get('textHint')}
            style={inputStyle}
            value={ textNode.text }
            onChange={ (e) => change(e, 'text')}
          />
  
          <TextField
            select
            style={inputStyle}
            label={T.get('font')}
            helperText={T.get('fontHint')}
            variant='filled'
            label={T.get('font')}
            value={textNode.font || fonts[0]}
            helperText={T.get('fontHint')}
            onChange={e => {
              const update = e.target.value
              this.updatePageHeader(index, 'font', update)
            }}
          >
            { fonts.map((f,i)=>{
              return (
                <MenuItem value={f}>
                  {f}
                </MenuItem>
              )
            })}
          </TextField>
  
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <PropertyNumber
              label={ T.get('width') }
              helperText={ T.get('widthHint')}
              style={{
                ...inputStyle,
                width: 120,
              }}
              value={ textNode.width }
              onChange={ (e) => changeNum(e, 'width')}
            />
  
            <PropertyNumber 
              label={ T.get('fontSize') }
              helperText={ T.get('fontSizeHint')}
              style={{
                ...inputStyle,
                width: 120,
                marginLeft: 12,
              }}
              value={ textNode.fontSize }
              onChange={ (e) => changeNum(e, 'fontSize')}
            />
  
            <FormControl
              style={{
                ...inputStyle,
                marginLeft: 12,
              }}
            >
              <FormGroup>
                <ButtonGroup 
                  style={{
                    border: `1px solid ${window.theme.palette.divider}`
                  }}
                >
                  <IconButton 
                    color={ align('left') }
                    onClick={e => {
                      this.updatePageHeader(index, 'align', 'left')
                    }}
                  >
                    <IconLeft />
                  </IconButton>
      
                  <IconButton 
                    color={ align('center') }
                    onClick={e => {
                      this.updatePageHeader(index, 'align', 'center')
                    }}
                  >
                    <IconCenter />
                  </IconButton>
      
                  <IconButton 
                    color={ align('right') }
                    onClick={e => {
                      this.updatePageHeader(index, 'align', 'right')
                    }}
                  >
                    <IconRight />
                  </IconButton>
                </ButtonGroup>
              </FormGroup>
              <FormHelperText>{T.get('alignHint')}</FormHelperText>
            </FormControl>
  
          </Box>
  
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
  
            <PropertyNumber
              label={ T.get('posX') }
              helperText={ T.get('posXHint')}
              style={{
                ...inputStyle,
                width: 120,
              }}
              value={ textNode.posX }
              onChange={ (e) => changeNum(e, 'posX')}
            />
  
            <PropertyNumber
              label={ T.get('posY') }
              helperText={ T.get('posYHint')}
              style={{
                ...inputStyle,
                width: 120,
                marginLeft: 12,
              }}
              value={ textNode.posY }
              onChange={ (e) => changeNum(e, 'posY')}
            />
          </Box>
  
          <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <FormControl
                style={inputStyle}
              >
                <FormControlLabel 
                  control={
                    <Checkbox 
                      onChange={e => {
                        handleCheckbox('wrap', e)
                      }}
                    />
                  }
                  label={T.get('wrap')}
                />
                <FormHelperText>
                {T.get('wrapHint')}
                </FormHelperText>
              </FormControl>
            </Box>
        </React.Fragment>
      )
    } else {
      const imgStyle = {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 200,
      }
      return (
        <React.Fragment>
          <h3>Show Logo</h3>
          { this.props.show.logo ? 
            <img style={imgStyle} src={this.props.show.logo} />
            :
            <img style={imgStyle}  src='img/icon.png' />
          }
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <PropertyNumber
              disabled
              label={ T.get('width') }
              helperText={ T.get('widthHint')}
              style={{
                ...inputStyle,
                width: 120,
              }}
              value={ textNode.width }
              onChange={ (e) => changeNum(e, 'width')}
            />
  
            <PropertyNumber 
              label={ T.get('height') }
              helperText={ T.get('heightHint')}
              style={{
                ...inputStyle,
                width: 120,
                marginLeft: 12,
              }}
              value={ textNode.height }
              onChange={ (e) => changeNum(e, 'height')}
            />
  
          </Box>
  
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
  
            <PropertyNumber
              label={ T.get('posX') }
              helperText={ T.get('posXHint')}
              style={{
                ...inputStyle,
                width: 120,
              }}
              value={ textNode.posX }
              onChange={ (e) => changeNum(e, 'posX')}
            />
  
            <PropertyNumber
              label={ T.get('posY') }
              helperText={ T.get('posYHint')}
              style={{
                ...inputStyle,
                width: 120,
                marginLeft: 12,
              }}
              value={ textNode.posY }
              onChange={ (e) => changeNum(e, 'posY')}
            />
          </Box>

          <p 
            style={{
              maxWidth: 300,
            }}
          >
            Note: images are finicky and currently Light Assistant resizes
            the <b><tt>height</tt></b> of the image only to fit it to the page. This
            is to preserve vertical space regardless of imagery. In the future more
            options will be released, but for the moment the aspect ratio of artwork
            is preserved and you simply pick a height.
          </p>
        </React.Fragment>
      )
    }
  }
  renderEditFormat() {
    const T = this.T
    const inputStyle = {
      // zoom: 0.9,
      marginTop: 8,
      // marginLeft: 16,
      // marginRight: 16,
    }
    const formatting = {
      seperator: 'dash',
      start: 'solid',
      end: 'solid',
      vertical: 'solid',
      rowLineColor: '#ddd',
      seperatorColor: '#ccc',
      ...this.state.report.formatting
    }

    const lineType = (key) => {
      const _translate = 'format' + key
      return (
        <TextField
        select
          style={inputStyle}
          label={T.get(_translate)}
          helperText={T.get(_translate + 'Hint')}
          variant='filled'
          value={formatting[key] || 'none'}
          onChange={e => {
            const update = e.target.value
            this.updateDeep('formatting', key, update)
          }}
        >
          <MenuItem value='none'>
            { T.get('lineNone') }
          </MenuItem>
          <MenuItem value='solid'>
            { T.get('lineSolid') }
          </MenuItem>
          <MenuItem value='dash'>
            { T.get('lineDash') }
          </MenuItem>
        </TextField>
      )
    }
    let stripes = !!this.state.report.stripe
    
    if(this.state.report.stripe === null || this.state.report.stripe === undefined) {
      stripes = true
    }

    return (
      <React.Fragment>
        <h3>{T.get('stripeColor')}</h3>
        <ColorPicker
          animation="slide-up"
          color={this.cache.stripeColor}
          onChange={colors => {
            this.cache.stripeColor = colors.color
          }}
          onClose={_ => {
            this.updateRootProp('stripeColor', this.cache.stripeColor)
          }}
        />
        <FormControl
          style={inputStyle}
        >
          <FormControlLabel 
            control={
              <Checkbox 
                checked={stripes}
                onChange={e => {
                  let bool = !!e.target.checked
                  this.updateRootProp('stripe', bool)
                }}
              />
            }
            label={T.get('useStripes')}
          />
          <FormHelperText>
          {T.get('useStripesHint')}
          </FormHelperText>
        </FormControl>
        <h3>{T.get('rowSeperator')}</h3>
        { lineType('vertical') }
        <ColorPicker
          animation="slide-up"
          color={this.cache.rowLineColor}
          onChange={colors => {
            this.cache.rowLineColor = colors.color
          }}
          onClose={_ => {
            this.updateDeep('formatting', 'rowLineColor', this.cache.rowLineColor)
          }}
        />
        
        <h3>{T.get('verticalSeperator')}</h3>
        { lineType('seperator') }
        <ColorPicker
          animation="slide-up"
          color={this.cache.seperatorColor}
          onChange={colors => {
            this.cache.seperatorColor = colors.color

          }}
          onClose={_ => {
            this.updateDeep('formatting', 'seperatorColor', this.cache.seperatorColor)
          }}
        />


        <h3>{T.get('seperatorBorder')}</h3>
        { lineType('start') }
        { lineType('end') }
        <p>{T.get('borderHelp')}</p>

        <Button
          variant='outlined'
          onClick={e => {
            this.resetReportStripeColors()
          }}
        >
          {T.get('resetLineColors')}
        </Button>
      </React.Fragment>
    )
  }

  renderColumnFieldProperties(mode, index) {
    let array = null

    if(mode === DATA_COLUMN_OPTIONAL) {
      array = this.state.report.columnsOptional
    } else if (mode === DATA_COLUMN_PRIMARY) {
      array = this.state.report.columns
    }

    if(!array) {
      return <h1>{`INVALID SELECTION ${mode}:${index}`}</h1>
    }
    
    //We add defaults for each field
    const column = { 
      key: '',
      label: '',
      width: 64,
      fontSize: 10,
      format: '',
      align: 'left',
      wrap: true,
      virtual: false,
      ...array[index]
     }
    const T = this.T

    if(!column) {
      return <h3>No data for column {index}</h3>
    }

    const inputStyle = {
      // zoom: 0.9,
      marginTop: 8,
      // marginLeft: 16,
      // marginRight: 16,
    }

    const cardTitleProps = {
      //marginTop:0,
      padding: 0,
      paddingLeft: 12,
      paddingTop: 6,
      margin: 0,
      marginBottom: 4,
    }

    const align = (val) => {
      if(column.align === val) {
        return 'primary'
      } else {
        return 'default'
      }
    }

    const handleCheckbox = (prop, event) => {
      const checked = !!event.target.checked
      this.updateColumn(mode, index, prop, checked)
    }

    const blur = (e, key) => {
      console.log(`blur ${key}=>${e.target.value}`)
      let input = ('' + e.target.value).trim()
      this.updateColumn(mode, index, key, input)
    }

    const change = (e, key) => {
      console.log(`${key}=>${e.target.value}`)
      this.updateColumn(mode, index, key, e.target.value)
    }

    const changeNum = (e, key) => {
      this.updateColumn(mode, index, key, num(e.target.value))
    }

    let defaults = [column.key]
    Object.values(this.state.mappings).forEach(v => defaults.push(v))

    defaults.sort()
    return (
      <React.Fragment>
        <Autocomplete 
          freeSolo
          selectOnFocus
          filterSelectedOptions
          id='key-auto'
          options = { defaults.map(s => s) }
          value={ column.key }
          renderInput={ params => (
            <PropertyText 
              {...params}
              label={ T.get('key') }
              helperText={ T.get('keyHint')}
              style={inputStyle}
              value={ column.key }
              onBlur={ e => blur(e, 'key') }
              onChange={ (e) => change(e, 'key')}
            />
          )}
        />

        { mode === DATA_COLUMN_PRIMARY && 
          <PropertyText 
            label={ T.get('label') }
            helperText={ T.get('labelHint')}
            style={inputStyle}
            value={ column.label }
            onChange={ (e) => {
              let input = ('' + e.target.value).trim()
              this.updateColumn(mode, index, 'label', input)
            }}
          />
        }

        <TextField
          select
          style={inputStyle}
          label={T.get('font')}
          helperText={T.get('fontHint')}
          variant='filled'
          value={column.font || fonts[0]}
          onChange={e => {
            const update = e.target.value
            this.updateColumn(mode, index, 'font', update)
          }}
        >
          { fonts.map((f,i)=>{
            return (
              <MenuItem value={f} key={'font-' + i}>
                {f}
              </MenuItem>
            )
          })}
        </TextField>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PropertyNumber
            label={ T.get('width') }
            helperText={ T.get('widthHint')}
            style={{
              ...inputStyle,
              width: 120,
            }}
            value={ column.width }
            onChange={ (e) => changeNum(e, 'width')}
          />

          <PropertyNumber 
            label={ T.get('fontSize') }
            helperText={ T.get('fontSizeHint')}
            style={{
              ...inputStyle,
              width: 120,
              marginLeft: 12,
            }}
            value={ column.fontSize }
            onChange={ (e) => changeNum(e, 'fontSize')}
          />

          <FormControl
            style={{
              ...inputStyle,
              marginLeft: 12,
            }}
          >
            <FormGroup>
              <ButtonGroup 
                style={{
                  border: `1px solid ${window.theme.palette.divider}`
                }}
              >
                <IconButton 
                  color={ align('left') }
                  onClick={e => {
                    this.updateColumn(mode, index, 'align', 'left')
                  }}
                >
                  <IconLeft />
                </IconButton>
    
                <IconButton 
                  color={ align('center') }
                  onClick={e => {
                    this.updateColumn(mode, index, 'align', 'center')
                  }}
                >
                  <IconCenter />
                </IconButton>
    
                <IconButton 
                  color={ align('right') }
                  onClick={e => {
                    this.updateColumn(mode, index, 'align', 'right')
                  }}
                >
                  <IconRight />
                </IconButton>
              </ButtonGroup>
            </FormGroup>
            <FormHelperText>{T.get('alignHint')}</FormHelperText>
          </FormControl>

        </Box>
        
        { mode === DATA_COLUMN_OPTIONAL && 
          <TextField
            select
            style={inputStyle}
            label={T.get('container')}
            helperText={T.get('containerHint')}
            variant='filled'
            value={column.container || containers[0]}
            onChange={e => {
              const update = e.target.value
              this.updateColumn(mode, index, 'container', update)
            }}
          >
            { containers.map((c,i)=>{
              return (
                <MenuItem value={c} key={'cont-' + i}>
                  { T.soft(c) }
                </MenuItem>
              )
            })}
          </TextField>
        }

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: `1px solid ${window.theme.palette.divider}`,
            borderRadius: 5,
            margin: '8px -4px 8px -4px',
            padding: '0 4px 8px 4px'
          }}
        >
          <h4 style={cardTitleProps}>
              Advanced Settings
          </h4>
          
          <PropertyText 
            label={ T.get('format') }
            helperText={ T.get('formatHint')}
            style={inputStyle}
            value={ column.format }
            onChange={ (e) => {
              let input = ('' + e.target.value).trim()
              this.updateColumn(mode, index, 'format', input)
            }}
          />

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FormControl
              style={inputStyle}
            >
              <FormControlLabel 
                control={
                  <Checkbox 
                    checked={!!column.virtual}
                    onChange={e => {
                      handleCheckbox('virtual', e)
                    }}
                  />
                }
                label={T.get('virtual')}
              />
              <FormHelperText>
              {T.get('virtualHint')}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box 
            style={{
              display: 'flex',
              marginTop: 12,
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={e => {
                this.props.dispatch( 
                  showDialog(T.get('advancedHelp'), T.get('advancedHelpContent')) 
                )
              }}
            >
              { T.get('advancedHelp') }
            </Button>
          </Box>
          
        </div>

        { mode === DATA_COLUMN_OPTIONAL ? 
          <FlashHelp text={ T.get('columnOptionalHelp' )} />
        : null}
      </React.Fragment>
    )
  }

  renderProperties() {
    const T = this.T
    const inputStyle = {
      zoom: 0.9,
      marginTop: 8,
      marginLeft: 16,
      marginRight: 16,
    }

    const { editSection, editIndex } = this.state
    let titleText = 'None'
    let actions = []
    let renderControls = null

    //Editor Switch
    if(editSection === DATA_COLUMN_PRIMARY || editSection === DATA_COLUMN_OPTIONAL) {
      const save = (
        <Tooltip title={T.get('saveButtonHelp')}>
          <Button
            key={'save'}
            style={{
              color: window.theme.palette.common.white,
            }}
            onClick={()=>{
              this._stopEdit()
            }}
          >
            <IconCheck />
          </Button>
        </Tooltip>
      )

      const cancel = (
        <Tooltip title={T.get('cancelButtonHelp')}>
          <Button
            key={'cancel'}
            style={{
              color: window.theme.palette.common.white,
            }}
            onClick={()=>{
              this.cancelColumnEdits()
            }}
          >
            <IconCancel />
          </Button>
        </Tooltip>
      )

      const remove = (
        <Tooltip title={T.get('deleteButtonHelp')}>
          <Button
            key={'delete'}
            style={{
              color: window.theme.palette.common.white,
            }}
            onClick={e => {
              this.props.dispatch(
                showConfirm(
                  T.get('deleteTitle'),
                  T.get('deleteConfirm', editIndex),
                  () => {
                    this.deleteColumn(editSection, editIndex)
                  }
                )
              )
            }}
          >
            <IconDelete />
          </Button>
        </Tooltip>
      )
      titleText = T.get('editingColumn', editIndex)
      actions = [save, cancel, remove]

      renderControls = this.renderColumnFieldProperties(editSection, editIndex)

    } else if (editSection === PAGE_HEADER_ARRAY) {
      titleText = T.get('editingHeader', editIndex)
      actions = []
      renderControls = this.renderPageHeaderFieldProperties(editIndex)

    } else if( editSection === COLUMN_HEADER_OBJECT) {
      titleText = T.get('editingDataHeader')
      renderControls = this.editColumnHeaderProperties()

    } else if ( editSection === REPORT_DATA_SOURCE) {
      titleText = T.get('editingDataSource')
      renderControls = this.renderDataSourceProperties()

    } else if ( editSection === REPORT_FILTERS) {
      titleText = T.get('editingDataFilters')
      renderControls = <h3>Nope</h3>
    } else if (editSection === REPORT_FORMATTING) {
      titleText = T.get('titleFormat')
      renderControls = this.renderEditFormat()
    }
    //IF COLUMN PROPERTY
    if(  editSection ) {
        return (
          <React.Fragment>
            <TextHeader 
              text={ titleText }
            >
              { actions.map( (x,i) => {
                return x
              })}
            </TextHeader>
            
            <Box
              width={1}
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
                height: '100%',
                zoom: 0.9,
              }}
            >
              { renderControls }
            </Box>
          </React.Fragment>
        )
    }

    //PAGE HEADER
    if(this.state.editSection === COLUMN_HEADER_OBJECT ) {
      return (
        <React.Fragment>
          <TextHeader 
            text={T.get('emptyEditingText')}
          ></TextHeader>
          <tt>
            We need to implement the header object... sorry
          </tt>
        </React.Fragment>
      )
      
    }

    //DEFAULT EMPTY
    const debug = {
      editSection: this.state.editSection,
      editIndex: this.state.editIndex,
      editing: this.state.editing,
    }
    return (
      <React.Fragment>
        <TextHeader 
          text={T.get('emptyEditingText')}
        ></TextHeader>
        <tt>
          {JSON.stringify(debug, null, 2)}
        </tt>
      </React.Fragment>
    )
  }

  renderTree() {
    const T = this.T
    let report = this.state.report

    if(!report) {
      return <TreeView />
    }

    const pageHeader = report.header || []
    const heading = report.heading || {}
    const columns = report.columns || []
    const columnsOptional = report.columnsOptional || []
    const EMPTY = '[Empty]'



    return (
      <TreeView
        defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
        defaultExpandIcon={<AddBoxOutlined />}
        defaultEndIcon={<TextFields />}
      >
        <TopLevelTreeItem
          nodeId='report-name' 
          label={this.state.report.name || 'New Report'}
        >
          <TreeItem 
            nodeId='main-styles'
            label='Formatting'
            onClick={ e => {
              this._masterEdit(REPORT_FORMATTING)
            }}
          />
        </TopLevelTreeItem>

        <TopLevelTreeItem
          nodeId='data' 
          label='Report Data'
        >
          <TreeItem 
            nodeId='report-query' 
            endIcon={<IconStorage />}
            label={T.get(report.data || 'none') }
            onClick={ e => { 
              this.switchTab( TAB_DATA )
            } }
          />
          
          <TreeItem 
            nodeId='report-sorting' 
            endIcon={<IconSort />}
            label={ T.get('groupsAndSorting')}
            onClick={ e => { 
              this._masterEdit(REPORT_DATA_SOURCE, 0)
            } }
          />

          <TreeItem 
            nodeId='report-filters' 
            endIcon={<IconFilter />}
            label={ T.get('filters') }
            onClick={ e => { 
              this._masterEdit(REPORT_DATA_SOURCE, 0)
            } }
          />
        </TopLevelTreeItem>

        <TopLevelTreeItem nodeId='page' label='Page Header'>
          { pageHeader.map( (el, index) => {
            if(el.logo) {
              return (
                <TreeItem 
                  key={index}
                  nodeId={'page-' + index}
                  endIcon={<IconImage />}
                  label='LOGO'
                  onClick={ e => this.editHeader(index) }
                />
              )
            } else {
              return (
                <TreeItem 
                  key={index}
                  nodeId={'page-' + index}
                  label={el.text || EMPTY}
                  onClick={ e => this.editHeader(index) }
                />
              )
            }
            
          }) }
        </TopLevelTreeItem>

        <TopLevelTreeItem nodeId='heading' label='Data Header'>
          <TreeItem 
            nodeId={'heading-object'} 
            label={heading.text || EMPTY}
            onClick={e => this.editDataHeader() }
          />
        </TopLevelTreeItem>

        <TopLevelTreeItem nodeId='column' label='Columns'>
          { columns.map((x,i) => {
            return (
              <TreeItem 
                key={i+x.key}
                nodeId={'column-'+i} 
                label={ x.format || x.key || EMPTY } 
                onClick={e => {
                  this._masterEdit(DATA_COLUMN_PRIMARY, i)
                }}
              />
            )
          })}
          <TreeItem 
            nodeId='column-add'
            endIcon={<IconAdd />}
            label={ T.get('addColumn') }
            onClick={ e => {
              this.addColumn()
            }}
          />
        </TopLevelTreeItem>

        <TopLevelTreeItem nodeId='columnOptioonal' label='Optional Columns'>
          { columnsOptional.map((x,i) => {
            return (
              <TreeItem 
                key={i+x.key}
                nodeId={'columnOptional-'+i} 
                label={ x.format || x.key || EMPTY } 
                onClick={e => {
                  this._masterEdit(DATA_COLUMN_OPTIONAL, i)
                }}
              />
            )
          })}
          <TreeItem 
            nodeId='column-add-opt'
            endIcon={<IconAdd />}
            label={ T.get('addColumnOptional') }
            onClick={ e => {
              this.addColumnOptional()
            }}
          />
        </TopLevelTreeItem>

        <TopLevelTreeItem nodeId='footer' label='Footer'>
          <TreeItem 
            nodeId='footer-1'
            endIcon={null}
            label='Not Implemented (yet)'
          />
        </TopLevelTreeItem>

      </TreeView>
    )
  }

  render() {
    const reportLoaded = this.state.report
    
    return (
      <Standard
        style={{
          overflowY: 'hidden',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Paper
            style={{
              minWidth: 380,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            {/* <div 
              style={{
                border: `1px solid ${window.theme.palette.common.black}`,
                boxShadow: window.theme.boxShadow,
                flex: 1,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',

              }}
            >
              { this.properties() }
            </div> */}

            <div 
              style={{
                border: `1px solid ${window.theme.palette.common.black}`,
                boxShadow: window.theme.boxShadow,
                flex: 3,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto',
              }}
            >
              <TextHeader 
                text='Report Outline'
              >
                  {/* Add controls here */}
              </TextHeader>

              <div
                style={{
                  overflowY: 'auto',
                  padding: 12,
                }}
              >
                { this.renderTree() }
              </div>
            </div>            
          </Paper> 

          <div 
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                width: '100%',
                backgroundColor: window.theme.paperAlt,
                display: 'flex',
                alignItems: 'baseline'
              }}
            >
              { !this.state.report ?
                  this.T.get('noReportLoaded')
                :
                <React.Fragment>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: 1,
                      alignItems: 'baseline',
                    }}
                  >
                    <h3 
                      style={{
                        display: 'inline-block',
                        padding: 12,
                      }}
                    >
                      { this.state.report.name || 'Unnamed Report'}
                    </h3>
                    <div 
                      style={{
                        flexGrow: 1,
                      }}>
                    </div>

                    <Tooltip title='Save Report'>
                      <Button
                        variant='outlined'
                        onClick={ e => { 
                          this.savePdfReport()
                        } }
                      >
                        <IconSave />
                      </Button>
                    </Tooltip>

                    <Tooltip title='Close Report'>
                      <Button
                        variant='outlined'
                        onClick={ e => { 
                          this.closeReport()
                        } }
                      >
                        <IconCancel />
                      </Button>
                    </Tooltip>

                    <Tooltip title='Delete Report'>
                      <Button
                        color='secondary'
                        variant='outlined'
                        onClick={ e => { 
                          this.props.dispatch(
                            showConfirm('Delete Report', 'Are you sure you want to delete this report?', ()=>{
                              this.props.dispatch( deleteReport(this.state.reportIndex) )
                              this.closeEditors()
                            })
                          )
                        } }
                      >
                        <IconDelete />
                      </Button>
                    </Tooltip>
                    
                  </Box>
                </React.Fragment>
              }

            </div>
            <Tabs 
              value={this.state.tab} 
              onChange={(e, i) => {
                this.switchTab(i)
              }}
            >
              <Tab label="Setup"></Tab>
              <Tab label="Preview" disabled={!reportLoaded} ></Tab>
              <Tab label="Data" disabled={!reportLoaded} ></Tab>
              <Tab label="Filters" disabled={!reportLoaded} ></Tab>
              <Tab label="Editor" disabled={!reportLoaded} ></Tab>
            </Tabs>
            { this.tab(0, this.setupTab() ) }
            { this.tab(1, this.previewTab() ) }
            { this.tab(2, this.dataTab() ) }
            { this.tab(3, this.filterTab() ) }
            { this.tab(4, this.editorTab() ) }
          </div>

          <Paper
            style={{
              minWidth: 380,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            { this.renderProperties() }
          </Paper>
        </div>

      </Standard>
    )
  }
}

export default connect(s => {
  const reports = s.reports || {}
  const { pdf, excel, filters, queries } = reports
  return {
    mappings: s.vectorworks.syncMappings || {},
    vectorworks: s.vectorworks || {},
    worksheets: s.worksheets.worksheets || [],
    pdf: pdf || [],
    excel: excel || [],
    filters: filters || [],
    queries: queries || [],
    show: s.show.show || {}
  }
})(ReportEditor)