import React from 'react'
import PropTypes from 'prop-types'
import Colors from './../Colors'

import IconDropDown from '@material-ui/icons/ArrowDropDown'
import IconDropUp from '@material-ui/icons/ArrowDropUp'
import IconSort from '@material-ui/icons/Sort'

export const SortStatus = {
  NONE: 'none',
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

class SortIcon extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      hover: false,
      color: this.props.color || Colors.CONTROL_INACTIVE
    }
  }

  render() {
    const { status, style, color, hover, onClick, enabled } = this.props
    let iconStyle = this.props.iconStyle || {}

    let _iconStyle = {
      ...iconStyle,
      color: this.state.color,
    }

    let icon = null

    if(status == SortStatus.ASCENDING ) {
      icon = 
        <IconDropUp 
          style={_iconStyle}
        />
    } else if (status == SortStatus.DESCENDING) {
      icon = 
        <IconDropUp 
          style={_iconStyle}
        />
    } else { //NONE
      icon = 
        <IconSort 
          style={_iconStyle}
        />
    }
    
    return (
      <div
        style={{
          cursor: 'pointer',
          ...style,
          color: this.state.color,
        }}
        onMouseEnter={ e => {
          this.setState({
            ...this.state,
            color: hover || Colors.MATERIAL_HIGHLIGHT
          })
        }}
        onMouseLeave={ e => {
          this.setState({
            ...this.state,
            color: color || Colors.CONTROL_INACTIVE
          })
        }}

        onClick={ e => {
          if(this.props.onClick) {
            this.props.onClick()
          }
        }}
      >
        { icon } 
      </div>
    )
  }
}

SortIcon.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,

  color: PropTypes.string,
  hover: PropTypes.string,
  iconStyle: PropTypes.object,
}

export default SortIcon