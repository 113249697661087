export default {
  reports: 'Custom Reports',
  reportsHint: 'Choose a report from this list to make changes',
  reportName: 'Report Name',
  reportData: 'Data Set',
  reportNameHelp: 'Does not need to be unique',
  loadReport: 'Load Report',
  noReportLoaded: 'No Report Loaded',
  saveButtonHelp: 'Close this editor',
  cancelButtonHelp: 'Revert changes and close editor',
  deleteButtonHelp: 'Delete this column',
  deleteTitle: 'Delete Column',
  deleteConfirm: 'Are you sure you want to delete column {0}',
  addColumn: 'Add Column',
  addColumnOptional: 'Add Optional Column',
  columnOptionalHelp: `
  <p>
    <em>Optional Columns</em> are only printed when there is a valid value 
    for one of the fields. These are designed for conditional details and
    are useful for <em>notes</em>, <em>accessories</em>, or other important
    information that should stand out, but doesn't need to take up space.
  </p>

  <p><em>Optional Columns</em> do not generate labels.</p>
  `,
  key: 'Field Key',
  keyHint: 'What data to use and how to label this column',
  text: 'Field Text',
  textHint: 'What should this display (variables are available)',
  editingColumn: 'Editing Column {0}',
  label: 'Field Label',
  labelHint: 'The column label, ex: "#" for Unit Number',
  font: 'Field Font',
  fontHint:'The font for this field, Bold and Italic are selected here',
  fontHelp:`
  <p>
    Light Assistant uses Open Fonts which are not always True Type. This means you 
    need to select a Bold font for bold output and the same with Oblique or Italic.
  </p>
  `,
  fontSize: 'Font Size',
  fontSizeHint: 'Size in points',
  format: 'Format',
  formatHint: 'Using {variable} notation you can create complex formatting',
  virtual: 'Virtual Column',
  virtualHint:'The column always uses the format rather then checking to see if the field has a value',
  align: 'Alignment',
  alignHint: 'Text alignment',
  textNode: 'Text columns represent a piece of data in a row. You can modify the properties below to customize the look of your report.',
  width: 'Width',
  widthHint: 'Width in points',
  dataSource: 'Data Source',
  advanced: 'Advanced Settings',
  advancedHelp: 'Advanced Help',
  //DATA TAB
  none: 'None',
  sortBy: 'Sort By',
  groupBy: 'Group By',
  dataSourceSummary: 'Data Source',
  dataSourceLabel: 'Data Source: {0}',
  groupsAndSorting: 'Grouping and Sorting',
  groupAndSortLabel: 'Group: {0} Sort: {1}',
  groupByList: 'Group by: {0}',
  sortByList: 'then Sort by: {0}',
  filters: 'Filters',
  filterLabel: 'Filter: {0}',
  dataPreview: 'Data Preview',
  showAllFields: 'Show All Fields',
  showAllFieldsTip: 'The instrument module has a large number of hidden fields that are rarely used. Show them?',
  advancedHelpContent: `
  <p>
  The advanced settings for using variable or otherwise formatting your
  data with adornments. Templates are defined by: '{variableName}'. A complete
  list of variables can be found in the documentation but the ones you will
  probably most commonly use are listed below:
  </p>

  <ul>
    <li>{show}</li> Show Name the name from the show module
    <li>{people}</li> A list of designers from the show module
    <li>Not really implemented, please contact support</li>
  </ul>
  `,
  cues: 'Cues',
  instruments: 'Instruments',
  notes: 'Notes',
  cuesDescription: `
  <p>
    The Cue data set is naturally sorted by its place in the array. Additional sorting is 
    disabled, however you can use filters to elminiate data.
  </p>`,
  notesDescription: `
  <p>
    Notes returns all data from the notes module. It is strongly recommended that you 
    assign a sort as well as filtering to this report as it can contain information from
    all modules in Light Assistant.
  </p>`,
  instrumentsDescription: `
  <p>
    The instrument data source has sevearl layers of pre and post-processing to ensure 
    that acessories and related data are attached to the correct record.
  </p>
  <p>
    The natural sort for lighting instruments is undefined and you probably want
    to assign some order and/or filtering for this to be useful.
  </p>
  `,
  noDataSourceDescription: `
  <p>
    This report has no data source, please select one from 
    the list. Without it a blank page will be printed.
  </p>`,

  //--------FORMATTING
  lineNone: 'No Line',
  lineSolid: 'Solid Line',
  lineDash: 'Dashed Line',
  rowAlternateColor: 'Row Alternate Color (Stripes)',
  formatseperator: 'Column Seperator Type',
  formatseperatorHint: 'The line drawn between columns',
  formatvertical: 'Row Seperator Type',
  formatverticalHint: 'The line drawn between rows',
  rowSeperator: 'Row Seperators',
  verticalSeperator: 'Column Seperators',
  seperatorBorder: 'Outside Border Settings',
  formatstart: 'Row Start Border',
  formatstartHint: 'The line before the data',
  formatend: 'Row End Border',
  formatendHint: 'The line after the data',
  borderHelp:`
  The start and end variables are used to create a border on the report, this
  is needed for clarity in some layouts, but the suggested setting is none.
  `,
  resetLineColors: 'Reset Line Colors',
  useStripes: 'Use Stripes',
  useStripesHint: 'Use alternating row colors',
  wrap: 'Wrap',
  wrapHint: 'Text overflows to a new line',
  container: 'Container',
  containerHint: 'An outline for this field, useful for optional columns.',
  rectangle: 'Rectangle',
  roundedRectangle: 'Rounded Rectangle',
}