import React from 'react'
import PropTypes from 'prop-types'

import {
  Avatar,
  Chip,
} from '@material-ui/core'

class ChipSet extends React.Component {

  render() {
    const { color, onSelect, onDelete, toLabel } = this.props
    let { available, selected } = this.props

    if(!available || available.length < 0) {
      available = []
    }

    if(!selected || selected.length < 0) {
      selected = []
    }
    let currentlyAvailable = []
    let currentlySelected = []

    for(const a of available) {
      currentlyAvailable.push( a )
    }
    for(const s of selected) {
      let index = currentlyAvailable.findIndex(x => x == s)
      if(index > -1) {
        currentlyAvailable.splice(index, 1)  
      }
      currentlySelected.push( s )
    }

    return (
      <React.Fragment>
        <h3>Active</h3>
        { selected.map( (s,i) => {
          return (
            <Chip
              key={i + s }
              avatar={<Avatar>{i+1}</Avatar>}
              label={ toLabel(s) }
              clickable
              color={ color || 'default' }
              onDelete={e => {
                if(onDelete) {
                  onDelete(s)
                }
              }}
            />
          )
        })}
        <hr />
        { currentlyAvailable.map( (s,i) => {
          return (
            <Chip
              key={i + s }
              avatar={<Avatar>{i+1}</Avatar>}
              label={ toLabel(s) }
              clickable
              onClick={e => {
                if(onSelect) {
                  onSelect(s)
                }
              }}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

ChipSet.propTypes = {
  available: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  toLabel: PropTypes.func.isRequired,
}

export default ChipSet