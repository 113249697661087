import Utility from './../Utility'
import { LOG_CLEAR_ITEM } from './LogLogic';

export const UNDO = 'UNDO_UNDO'
export const REDO = 'UNDO_REDO'
export const SET_NEW_STACK = 'UNDO_NEW_STACK'
export const PUSH_UNDO_ARRAY = 'UNDO_PUSH_UNDO_ARRAY'
export const CLEAR = 'UNDO_CLEAR'
export const CLEAR_ALL = 'UNDO_CLEAR_ALL'

const INITIAL_STATE = {
}
/**
 * Diff the objects a/b returning a list of keys that have been modified. If no modifications
 * are present then it returns an empty array.
 * @param {*} a Object a
 * @param {*} b Object b
 * @param {*} keys a set of keys to use for a diff
 * 
 * @returns a list of keys that are changed
 */
export const diffKeys = (a, b, keys, restoreFn)  => {
  
}

/**
 * Creates and Undo/Redo object to be pushed to the stack. 
 * @param {*} onUndo a function that restores this object with an argument taking either current or previous (undo/redo)
 * @param {*} onRedo @ function that replaces the state before undo
 * @param {Function<Record, Record, Array<String>} descriptionFn a function that shows the changes between state one and state two, this is used in the redo or undo
 * 
 * @return {Object} an object formatted for the undo/redo stack
 */
export const makeUndo = (onUndo, onRedo, undoDescription, redoDescription) => {
  return {
    undo: onUndo,
    redo: onRedo,
    description: undoDescription, 
    redoDescription: redoDescription,
  }
}

/**
 * 
 * Push a set of undos created with the `makeUndo` function.
 * @param {String} stack the name of the undo stack
 * @param {Array<Undo>} undoArray a set of undo operations that consist of a single "undo" logically to the user.
 */
export const pushUndoSet = (stack, undoArray) => {
  return dispatch => {
    dispatch({
      stack: stack,
      type: PUSH_UNDO_ARRAY,
      undoArray: undoArray,
    })
  }
}

/**
 * Push a single undo operation created with the `makeUndo` function.
 * @param {String} stack the name of the undo stack
 * @param {Undo} undo a single "undo" logically to the user.
 */
export const pushUndo = (stack, undo) => {
  return pushUndoSet( stack, [ undo ])
}

/**
 * Fire an undo action.
 * @param {String} key the name of the stack to undo
 */
export const actionUndo = (key) => {
  return (dispatch, getState) => {
    if(!key) {
      return
    }
    const state = getState().undo
    const stack = state[key] || { undo: [], redo: [] }

    const undo = (stack.undo || []).slice()
    const redo = (stack.redo || []).slice()
    
    const actions = undo.pop()
    if(actions && actions.length) {

      let actionPerformed = false
      for(let a of actions) {//execute all actions in the list
        if(a) {
          actionPerformed = true
          a.undo()
        }
      }

      if(actionPerformed) {
        redo.push(actions)
      }
    }

    const output = {
      undo: undo,
      redo: redo,
    }

    dispatch({
      stack: output,
      key: key,
      type: SET_NEW_STACK
    })
  }
}

/**
 * Frie a redo action.
 * @param {String} key the name of the stack to redo
 */
export const actionRedo = (key) => {
  return (dispatch, getState) => {
    if(!key) {
      return
    }
    const state = getState().undo
    const stack = state[key] || { undo: [], redo: [] }

    const undo = (stack.undo || []).slice()
    const redo = (stack.redo || []).slice()
    
    const actions = redo.pop()
    if(actions && actions.length) {

      let actionPerformed = false
      for(let a of actions) {//execute all actions in the list
        if(a) {
          actionPerformed = true
          a.redo()
        }
      }

      if(actionPerformed) {
        undo.push(actions)
      }
    }

    const output = {
      undo: undo,
      redo: redo,
    }

    dispatch({
      stack: output,
      key: key,
      type: SET_NEW_STACK
    })
  }
}

/**
 * Clear the undo stack
 * @param {String} stack the name of the stack to clear
 */
export const actionClear = (stack) => {
  return dispatch => {
    dispatch({
      stack: stack,
      type: CLEAR
    })
  }
}

export const actionClearAll = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ALL
    })
  }
}

export default (state = INITIAL_STATE, action)=>{
  switch(action.type) {
    
    case CLEAR: {
      return INITIAL_STATE
    }

    case CLEAR_ALL: {
      return INITIAL_STATE
    }

    case SET_NEW_STACK: {
      const { key, stack } = action
      if(!key || !stack) {
        return state
      }
      const update = {
        ...stack
      }
      const out = {
        ...state
      }

      out[key] = {
        ...stack
      }
      return out
    }

    case PUSH_UNDO_ARRAY: {
      const key = action.stack

      if(!key) {
        return key
      }

      const stack = state[key] || { undo: [], redo: [] }
      const undo = (stack.undo || []).slice()
      undo.push(action.undoArray)

      const output = {
        ...state
      }

      output[key] = {
        undo: undo,
        redo: [],
      }
      
      return output
    }

    case UNDO: {
      if(!action.stack) {
        return state
      }
      const stack = state[action.stack] || { undo: [], redo: [] }

      const undo = (stack.undo || []).slice()
      const redo = (stack.redo || []).slice()
      
      const actions = undo.pop()
      if(actions && actions.length) {

        let actionPerformed = false
        for(let a of actions) {//execute all actions in the list
          if(a) {
            actionPerformed = true
            a.undo()
          }
        }

        if(actionPerformed) {
          redo.push(actions)
        }
      }

      const output = {
        ...state
      }
      output[action.stack] = {
        undo: undo,
        redo: redo,
      }
      return output
    }

   case REDO: {
      if(!action.stack) {
        return state
      }
      const stack = state[action.stack] || { undo: [], redo: [] }

      const undo = (stack.undo || []).slice()
      const redo = (stack.redo || []).slice()
      
      const actions = redo.pop()
      if(actions && actions.length) {

        let actionPerformed = false
        for(let a of actions) {//execute all actions in the list
          if(a) {
            actionPerformed = true
            a.redo()
          }
        }

        if(actionPerformed) {
          undo.push(actions)
        }
      }

      const output = {
        ...state
      }
      output[action.stack] = {
        undo: undo,
        redo: redo,
      }
      return output
    }
  }

  return state
}