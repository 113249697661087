const CREATE_DEFAULT_WORKSHEETS = 'INST_CREATE_DEFAULT_WORKSHEETS'
const UPDATE_WORKSHEET = 'INST_UPDATE_WORKSHEET'
const REMOVE_WORKSHEET = 'INST_REMOVE_WORKSHEET'
const SELECT_WORKSHEET = 'INST_SELECT_WORKSHEET'
const ADD_WORKSHEET = 'INST_ADD_WORKSHEET'

import { 
  broadwaySortFieldsFn,
  broadwaySort,
  chainSorts,
} from '../../shared/Utility'

import { 
  LA_FIELDS,
  UNLOAD,
  backendInstrumentsDirectly,
} from './InstrumentLogic'

import { 
  instrumentLogic as Strings 
} from './../../shared/language'

import Errors from './../Errors'
import { 
  DEFAULT_VECTORWORKS_EXPORT_FIELD_LIST, 
  USER_WORKSHEET_IGNORES,
} from '../../shared/vectorworks'

//FIXME -> REPLACE THIS WITH REAL LOGIC
const BAD_DEFAULT_FIELDS = [
  {
    property: LA_FIELDS.CHANNEL,
    width: 100,
  }, {
    property: LA_FIELDS.UNIVERSE,
    width: 75,    
  },  {
    property: LA_FIELDS.ADDRESS,
    width: 75,            
  }, {
    property: LA_FIELDS.CIRCUIT_NAME,
    width: 50,
  },  {
    property: LA_FIELDS.CIRCUIT_NUMBER,
    width: 50,
  },  {    
    property: LA_FIELDS.POSITION,
    width: 100,
  }, {
    property: LA_FIELDS.UNIT,
    width: 50,
  }, {
    property: LA_FIELDS.DEVICE_TYPE,
    width: 100,      
    disabled: true,
  }, {
    property: LA_FIELDS.INSTRUMENT_TYPE,
    width: 150,
    editor: 'select'
  },  {
    property: LA_FIELDS.FRAME_SIZE,
    width: 75,      
  },  {
    property: LA_FIELDS.COLOR,
    width: 100,
  },  {
    property: LA_FIELDS.TEMPLATE,
    width: 100,
  },  {
    property: LA_FIELDS.PURPOSE,
    width: 100,
  }
]

export const SortStatus = {
  NONE: null,
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

const DEVICE_TYPE_RANKS = {
  'Light': 1,
  'Moving Light': 2,
  'Accessory': 3,
  'Static Accessory': 4,
  'Device': 5,
  'Practical': 6,
  'SFX' : 7,
  'Power': 8,
  'Other': 9,
}
export const _defaultWorksheetSort = (a, b) => {
  if(!a && !b) {
    return 0
  }

  if(a == b) {
    return 0
  }

  if(!a) {
    return 1
  }

  if(!b) {
    return -1
  }

  const aD = DEVICE_TYPE_RANKS[a.deviceType] || 99
  const bD = DEVICE_TYPE_RANKS[b.deviceType] || 99

  if(aD == bD) {
    return 0
  } else {
    return aD < bD ? -1 : 1
  }
}
/**
 * Reads the groupings, sorts, and overrides into a single array
 * so that it can be sorted.
 * 
 * @param {Worksheet} worksheet, the worksheet to process
 * @returns an array of sorts with an override flag for any that are overrides.
 */
export const combineSorts = (worksheet) => {
  if(!worksheet) {
    return []
  }
  const output = []

  const grouping = worksheet.grouping || []
  const sort = worksheet.sort || []
  const override = worksheet.override || []

  for(let x of grouping) {
    if(x.key) { //legacy or null skip
      output.push({
        key: x.key,
        sort: x.sort || SortStatus.ASCENDING,
        override: false,
      })
    }
  }

  for(let x of sort) {
    if(x.key) { //legacy or null skip
      output.push({
        key: x.key,
        sort: x.sort || SortStatus.ASCENDING,
        override: false,
      })
    }
  }

  for(let o of override) {
    if(o.key) { 
      const exists = output.findIndex(x => o.key == x.key)
      if(exists < 0) { //add it
        output.push({
          key: o.key,
          sort: o.sort || SortStatus.NONE,
          override: true,
        })
      } else {
        output.splice(exists, { //override it
          key: o.key,
          sort: o.sort || SortStatus.NONE,
          override: true,
        })
      }
    }
  }

  return output
}
/**
 * Merges the missing mapping fields into the worksheet 
 * (alphabetically) and disables them.
 * @param {syncMappings} state the Redux state
 * @param {Worksheet} worksheet the worksheet to update
 */
export const mergeMissingMappings = (vectorworks, worksheet, showAllFields = false) => {
  const mappings = vectorworks.syncMappings || {}

  if(!mappings) {
    return worksheet
  }

  if(!worksheet) {
    worksheet = {
      fields: []
    }
  }

  if(!worksheet.fields) {
    worksheet.fields = []
  }
  const exists = {}
  const fields = worksheet.fields
  for(let f of fields) {
    exists[f.property] = true
  }
  
  let keys = Object.keys(mappings)

  for(const k of USER_WORKSHEET_IGNORES) {
    exists[k] = true //mark them as existing
  }

  let reverseLookup = {}
  let keyMap = {}
  if(!showAllFields) {
    for(let m of Object.keys(mappings) ) {
      reverseLookup[ mappings[m].key ] = m
    }

    const vwKeys = Object.keys(vectorworks.ExportFieldList || {})
    for(let v of vwKeys) {
      const lookup = reverseLookup[v]
      if(lookup) {
        keyMap[ lookup ] = true
      }
    }

    for(const k of USER_WORKSHEET_IGNORES) {
      delete keyMap[k]
    }
    keys = Object.keys(keyMap)
  }

  keys.sort()

  for(let m of keys ) {
    if(!exists[m]) {
      const map = mappings[m]

      fields.push({
        property: m,
        width: 75,
        hidden: true,
        disabled: map.disabled,
      })
    }
  }

  if(!showAllFields) {
    const newFields = []
    for(let i = 0; i < fields.length; i++) {
      const field = fields[i]
      //If we are hidden, but not in the mappings
      if(!field.hidden) {
        newFields.push( field )
      } else if(keyMap[field.property]) {
        newFields.push( field )
      } //else skip it
    }

    return {
      ...worksheet,
      fields: newFields,
    }
  }

  return {
    ...worksheet,
    fields: fields,
  }
}

export const createDefaultWorksheets = (merge = false) => {
  return dispatch => dispatch({
    type: CREATE_DEFAULT_WORKSHEETS,
    merge: merge,
  })
}

export const updateWorksheet = (index, worksheet) => {

  return dispatch => dispatch( {
    type: UPDATE_WORKSHEET,
    worksheet: worksheet,
    index: index
  } )

}

/**
 * Moves the property up or down in the list of columns
 * @param {*} index, the property index 
 * @param {*} worksheet 
 * @param {*} up (default true)
 */
export const moveProperty = (index, up, worksheet)=> {
  if(index == 0 && up) {
    return worksheet
  }

  const fields = worksheet.fields.slice()
  let field = fields[index]

  if( up ) {
    const before = fields[index - 1]
    if(before) {
      fields[index] = before
      fields[index - 1] = field
    }
  } else {
    const after = fields[index + 1]
    if(after) {
      fields[index] = after
      fields[index + 1] = field
    }
  }

  return {
    ...worksheet,
    fields: fields,
  }
}

/**
 * 
 * @param {String} propertyName 
 * @param {Boolean} bool 
 * @param {Worksheet} worksheet 
 */
export const toggleProperty = (propertyName, bool, worksheet) => {
  
  const fields = worksheet.fields.slice()
  const index = fields.findIndex(f => f.property == propertyName)
  
  if(index < 0) {
    return worksheet
  }

  fields[index] = {
    ...fields[index],
    hidden: bool,
  }

  return {
    ...worksheet,
    fields: fields
  }
}

export const selectWorksheet = (index, onComplete) => {
  return (dispatch, getState) => {
    const state = getState()

    const worksheets = state.worksheets.worksheets || []
    const pending = worksheets[index]

    if(pending) {
      const instruments = state.instruments.instruments.slice() //copy the instruments
    
      //Start the loading ui..
      dispatch({
        type: UNLOAD
      })

      //FIXME -> Implement custom sort logic based on fields, accessories, etc...
      const sortOrder = combineSorts( pending ) 
      const sortFns = []

      for(let sort of sortOrder) {
        sortFns.push( 
          (a,b) => {
            if(sort.sort === SortStatus.NONE){//we could theoretically override to "none"
              return 0
            }

            let out = broadwaySort(a[sort.key], b[sort.key])
            //DEFAULT
            if(sort.sort === SortStatus.ASCENDING) {
              return out
            } else { //INVERTED
              return out * -1
            }
          } 
        )
      }

      //Final default tie-breaker
      sortFns.push( _defaultWorksheetSort )
      const newInstruments = instruments.sort( chainSorts(...sortFns) )
      //OLD METHOD
      // const grouping = pending.grouping || []
      // const sort = pending.sort || ['uid'] //DEFAULT
      // const fields = []
      // for(let x of grouping) {
      //   fields.push(x)
      // }

      // for(let x of sort){
      //   fields.push(x)
      // }

      // const newInstruments = instruments.sort( broadwaySortFieldsFn(...fields) )

      //WARNING THIS MUTATES THE DATA NOW...
      dispatch( 
        backendInstrumentsDirectly( newInstruments ) 
      )

      dispatch({
        type: SELECT_WORKSHEET,
        index: index,
        onComplete: onComplete
      })
    } //FIXME else silently fail and annoy users, this shouldn't happen
  }
}

/**
 * Overrides a sort with the new value, if that value is null 
 * it removes it from the overrides but leaves it intact in 
 * the worksheet.
 * 
 * This will dispatch a new sorted instruments array if changed
 * @param {String} index, the worksheet index
 * @param {Worksheet} worksheet, the worksheet to override
 * @param {String} property, the field we want to use
 * @param {SortStatus} sort, the SortStatus key
 */

export const overrideSort = (index, worksheet, property, sort) => {
  return (dispatch, getState) => {
    if(index < 0 || !worksheet || !property) {
      return
    }

    const state = getState()
    const grouping = worksheet.grouping || []
    const sort = worksheet.sort || []
    const override = worksheet.sort || []
    
    const isGrouping = worksheet.grouping
    const isSort = worksheet.sort 


  }
}

export const clearOverride = (index, worksheet, property, sort) => {
  return dispatch => dispatch({

  })
}

/**
 *
 * Create a new worksheet with a unique name
 * @param {*} name the name of the worksheet
 * @param {*} onComplete 
 */
export const createWorksheet = (name, onComplete) => {
  
  if(!name) {
    return //ignore it
  }

  const trim = name.trim()
  if(!trim){
    return
  }

  return (dispatch, getState) => {
    const state = getState()
    const currentWorksheets = (state.worksheets.worksheets || []).slice()
    for(let w of currentWorksheets) {
      if(w.name == trim){
        dispatch( Errors.reactWarning( Strings.get('errorWorksheetExistsTitle', trim), Strings.get('errorWorksheetExistsParagraph', trim)) )
        return//abort
      }
    }

    dispatch( {
      type: ADD_WORKSHEET,
      name: name,
      onComplete: onComplete,
    })
  }
}

/**
 * Remove the worksheet with the following name
 * @param {String} name 
 */
export const removeWorksheet = (name) => {
  if(!name){
    return
  }
  return dispatch => dispatch({
    type: REMOVE_WORKSHEET,
    name: name.trim(),
  })
}

const INITIAL_STATE = {
  worksheets: null,
  worksheet: null,
}

const makeSortKey = (key) => {
  return {
    key: key,
    sort: SortStatus.ASCENDING,//asc, but not set
  }
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {

    //WORKSHEETS
    case CREATE_DEFAULT_WORKSHEETS: {
      const { merge } = action

      
      let worksheets = []

      worksheets.push({
        name: 'Instrument Schedule',
        grouping: [
          makeSortKey(LA_FIELDS.POSITION)
        ],
        sort: [
          makeSortKey(LA_FIELDS.UNIT)
        ],
        fields: [
          {
            property: LA_FIELDS.POSITION,
            width: 100,
          }, {
            property: LA_FIELDS.UNIT,
            width: 50,
          }, {
            property: LA_FIELDS.DEVICE_TYPE,
            width: 100,      
            disabled: true,
          }, {
            property: LA_FIELDS.INSTRUMENT_TYPE,
            width: 150,
            editor: 'select'
          },  {
            property: LA_FIELDS.FRAME_SIZE,
            width: 75,      
          }, {
            property: LA_FIELDS.CHANNEL,
            width: 100,
          }, {
            property: LA_FIELDS.UNIVERSE,
            width: 100,
          },  {
            property: LA_FIELDS.ADDRESS,
            width: 100,
          },  {
            property: LA_FIELDS.CIRCUIT_NAME,
            width: 50,
          },  {
            property: LA_FIELDS.CIRCUIT_NUMBER,
            width: 50,
          },  {
            property: LA_FIELDS.COLOR,
            width: 100,
          },  {
            property: LA_FIELDS.TEMPLATE,
            width: 100,
          },  {
            property: LA_FIELDS.PURPOSE,
            width: 100,
          }
        ]
      })

      worksheets.push({
        name: 'Channel Hookup',
        grouping: [
        ],
        sort: [
          makeSortKey(LA_FIELDS.CHANNEL),
          makeSortKey(LA_FIELDS.ADDRESS),
        ],
        fields: BAD_DEFAULT_FIELDS.slice()
      })

      worksheets.push({
        name: 'Address Hookup',
        grouping: [
        ],
        sort: [
          makeSortKey(LA_FIELDS.ADDRESS),
          makeSortKey(LA_FIELDS.CHANNEL),
        ],
        fields: BAD_DEFAULT_FIELDS.slice()
      })

      worksheets.push({
        name: 'Color Schedule',
        grouping: [
          makeSortKey(LA_FIELDS.COLOR),
          makeSortKey(LA_FIELDS.FRAME_SIZE),
        ],
        sort: [
          makeSortKey(LA_FIELDS.POSITION),
          makeSortKey(LA_FIELDS.UNIT),
        ],
        fields: BAD_DEFAULT_FIELDS.slice()
      })

      worksheets.push({
        name: 'Unit Schedule',
        grouping: [
          makeSortKey(LA_FIELDS.INSTRUMENT_TYPE),
        ],
        sort: [
          makeSortKey(LA_FIELDS.POSITION),
          makeSortKey(LA_FIELDS.UNIT),
        ],
        fields: BAD_DEFAULT_FIELDS.slice()
      })

      if(merge) { //FIXME internationalize this
        const copy = (state.worksheets || []).slice()
        
        for(const w of copy) {
          switch(w.name) {
            case 'Instrument Schedule': {
              continue
            }

            case 'Channel Hookup': {
              continue
            }

            case 'Address Hookup': {
              continue
            }

            case 'Color Schedule': {
              continue
            }
            case 'Unit Schedule': {
              continue
            }

            default:
              worksheets.push(w)
          }
        }
        
      }
      return {
        ...state,
        worksheets: worksheets,
        worksheet: 0,
      }
    }

    case SELECT_WORKSHEET: {
      //We need to sort here
      const { index, instruments,  } = action
      if(action.onComplete) {
        setTimeout( ()=>{
          action.onComplete()
        }, 25)
      }
      return {
        ...state,
        worksheet: index,
        instruments: instruments,
      }
    }

    case ADD_WORKSHEET: {
      const { name } = action

      const copy = state.worksheets.slice()
      copy.push({
          name: name,
          grouping: [
          ],
          sort: [
            makeSortKey(LA_FIELDS.CHANNEL),
          ],
          fields: [ //we should probably pull this from the active state
            {
              property: LA_FIELDS.POSITION,
              width: 100,
            }, {
              property: LA_FIELDS.UNIT,
              width: 50,
            }, {
              property: LA_FIELDS.DEVICE_TYPE,
              width: 100,      
              locked: true,
            }, {
              property: LA_FIELDS.INSTRUMENT_TYPE,
              width: 150,
              editor: 'select'
            },  {
              property: LA_FIELDS.FRAME_SIZE,
              width: 75,      
            }, {
              property: LA_FIELDS.CHANNEL,
              width: 100,
            }, {
              property: LA_FIELDS.UNIVERSE,
              width: 100,
            },  {
              property: LA_FIELDS.ADDRESS,
              width: 100,
            },  {
              property: LA_FIELDS.CIRCUIT_NAME,
              width: 50,
            },  {
              property: LA_FIELDS.CIRCUIT_NUMBER,
              width: 50,
            },  {
              property: LA_FIELDS.COLOR,
              width: 100,
            },  {
              property: LA_FIELDS.TEMPLATE,
              width: 100,
            },  {
              property: LA_FIELDS.PURPOSE,
              width: 100,
            }
          ]
      })

      
      if(action.onComplete) { //fire a delayed action
        setTimeout( action.onComplete( copy.length - 1 ), 150)
      }

      return {
        ...state,
        worksheets: copy,
      }
    }

    case REMOVE_WORKSHEET: {
      const { name } = action

      if(!name) {
        return state
      }

      const worksheets = (state.worksheets || []).slice()
      const index = worksheets.findIndex(w => w.name == name )
      
      if(index < 0) {
        return state
      }

      worksheets.splice(index, 1)

      return {
        ...state,
        worksheets: worksheets,
      }
    }

    case UPDATE_WORKSHEET: {
      const { worksheet, index } = action
      const test = state.worksheets[ index ]
      if(!test) {
        return state//we don't have an index here
      }

      const copy = state.worksheets.slice()
      copy[index] = worksheet
      return {
        ...state,
        worksheets: copy
      }
    }

    default:
      return state
  }
}