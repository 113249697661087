import React from 'react'
import { connect } from 'react-redux'

import translate from './../../shared/language/Translate'
import Link from '../Link'
import FloatingOSCTracker from './../../src/integration/FloatingOSCTracker'

import Undo from './../../src/history/Undo'

import UndoIcon from '@material-ui/icons/Undo'
import RedoIcon from '@material-ui/icons/Redo'

export const inlineSpan = {
    marginLeft: 10,
    marginRight: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
}

class Footer extends React.Component {

  render(){
    const T = this.props.strings
    const linkStyle = {
      paddingLeft: '10px',
      marginLeft: 'auto',
    }


    const colorOnline = window.theme.oscOnline
    const colorOffline = window.theme.la_blue//window.theme.palette.background.default
    const color = this.props.oscOn ? colorOnline : colorOffline

    return(
      <footer 
        className='no-print'
        style={{
          backgroundColor: color,
          minHeight: '24px',
          maxHeight: '24px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          fontSize: '0.8em',
        }}
      >         
        {/* <Link to="/not-found" style={linkStyle}>404</Link>
        <Link to="/error" style={linkStyle}>500</Link> */}
        {/* LEFT SIDE DATA SIDE INFO */}
        <span style={{ paddingRight: 10,}}>
          { this.props.currentShow }
        </span>
        <FloatingOSCTracker 
          style={{
            marginRight: 'auto',
          }} 
        />

        {/* RIGHT SIDE INFO */}
        <Link to="/privacy" style={linkStyle}> { T.get('privacy') }</Link>
        <span 
          style={inlineSpan}
        >
          { T.get('copyright') }
        </span>

{/* THIS IS A TEST INLINE FOR THE UNDO MODULE */ }
{/*
        <span style={inlineSpan}> 
          <b>Cues Module</b> 
          999  
          <UndoIcon />
          <RedoIcon />
          998  
        </span> 
*/}

        { this.props.undo ? <Undo module={this.props.undo} /> : null } 
      </footer>      
    )
  }
}

export default connect(s => {
  return {
    //Paths and show variables
    path: s.routing && s.routing.pathname || '/',
    currentShow: s.show && s.show.show && s.show.show.currentShow || '*unsaved',

    //OSC Tracking
    trackOsc: s.cues.trackOsc,
    oscCue: s.osc.cue,
    oscList: s.osc.list,
    oscOn: !!s.osc.on,
    oscStatus: s.osc.status,
  }
})( translate('Footer')(Footer) )
