import React from 'react'
import translate from '../../shared/language/Translate'
import { notes, notes as Strings } from './../../shared/language'
import { connect } from 'react-redux'
import Colors from './../Colors'
import AsHtml from './../../components/AsHtml'
import {
  addNote,
  buildAllCaches,
  buildCueNotesCache,
  buildInstrumentNotesCacheByChannel,
  completeNote,
  deleteNote,
  dismissNoteDialog,
  getNoteDefaultsForDepartment,
  mergeNote,
  openNote,
  restoreNote,
  storeLastNoteState,
} from './../reducers/NotesLogic'

import {
  getCurrentOscCue, updateStatus
} from './../reducers/OscLogic'

import { 
  detectShiftEnter
} from './../Utility'

import {
  isEmptyOrWhitespace
} from './../../shared/StringUtil'

import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip, 
  Typography,
} from '@material-ui/core'

import IconDelete from '@material-ui/icons/DeleteForever'
import IconSave from '@material-ui/icons/Save'
import IconComplete from '@material-ui/icons/Check'
import IconSync from '@material-ui/icons/Sync'
import IconExpandMore from '@material-ui/icons/ExpandMore'

const cardStyle = {
  display: 'block',
  padding: 20,
  marginBottom: 20,
  borderRadius: 3,
}

class TextFieldRef extends React.Component {
  render(){
    return <TextField {...this.props} />
  }
}

class OscButtonClass extends React.Component {
  render() {
    if(!this.props.on) {
      return null
    }

    return (
      <Tooltip title={Strings.get('oscSyncButtonTooltip')}>
        <Button
          {...this.props}
        >
          <IconSync /> OSC
        </Button>
      </Tooltip>
      
    )
  }
}

const OscButton = connect(s => {
  const on = s && s.osc && s.osc.on 
  return {
    on: on,
  }
})(OscButtonClass)

class NoteDialog extends React.Component {
  constructor(props) {
    super(props)
    const T = props.strings

    //Borrowed from the notes page...
    this.departments = [
      T.get('private'),
      T.get('assistant'),
      T.get('lighting'),
      T.get('video'),
      T.get('scenery'),
      T.get('costumes'),
      T.get('sound'),
      T.get('management'),
    ]

    this.priorities = [
      'urgent',
      'high',
      'regular',
      'low',
      'minor',
    ]

    this.categories = [
      'typeGeneral',
      'typeCue',
      'typeFocus',
      'typeWork',
      'typePrivate',
    ]

    this.sorts = [
      T.get('status'),
      T.get('category'),
      T.get('department'),
      T.get('priority'),
      T.get('channels'),
      T.get('cue'),
      T.get('date'),
    ]

    this.focusField = null
    const categories = this.departments.slice()
    categories.unshift( 'cue' )

    //Not copied from the notes page..., this is the component will receive props call
    const activeCue = props.noteDialogData.cue || null
    const cues = activeCue ? [ activeCue ] : []

    this.state = {
      //Constructor
      priority: 'regular',
      department: props.strings.get('lighting'),

      //Always
      addNote: {},
      channels:[],
      channelText: '',
      cues: cues,
      cueText: '',
      category: 'typeCue',
      cascade: true,
      description: '',
      discardErrors: false,
      uuid: null,
    }
  }

  componentWillReceiveProps(props) {
    const newNote = props.noteDialogData

    const activeCue =  props.noteDialogData.cue || null
    console.log(`[DEBUG NOTE PROPS] ${JSON.stringify(props, null, 2)}`)
    let cues = activeCue ? [ activeCue ] : []
    if(newNote.uuid) {
      cues = (newNote.relatedCues || []).slice()
    } 
    const newState = {
      //Constructor
      priority: this.state.priority || 'regular',
      department: this.state.department || props.strings.get('lighting'),

      //Always
      addNote: {},
      channels: (newNote.relatedChannels || []).slice(),
      channelText: '',
      cues: cues,
      cueText: '',
      category: this.state.category || 'typeCue',
      cascade: true,
      description: '',
      discardErrors: false,
      ...newNote, //override anything if needed.

      //Explicitly only use incoming UUIDs
      uuid: newNote.uuid || null,
    }

    
    let composite = {
      ...this.state,
      ...newState
    }
    //delete composite['uuid']
    console.log(`[DEBUG NOTE]->${JSON.stringify(composite, null, 2)}`)
    this.setState(composite)
  }

  _guessCategory(state) {
    if(!state) {
      state = this.state
    }
    if(state.channels.length > 0) {
      return 'typeWork'
    }

    if(state.cues.length > 0) {
      return 'typeCue'
    }

    if(state.department == notes.get('lightinng') ) {
      return 'typeCue'
    } else if (state.department == notes.get('assistant')) {
      return 'typeCue'
    }

    return 'typeGeneral'
  }

  storeDepartment(department){
    const updates = {
      ...this.state,
    }
    if(this.department != department) {
      let category = this._guessCategory()
      updates.category = category
    }

    updates.department = department

    this.setState(updates)
  }


  resetNote() {
    let resetState = {
      ...this.state,
      channels: [],
      channelText: '',
      cues: [],
      cueText: '',
      description: '',
      priority: 'regular',
      category: this.state.type || 'typeCue',
      uuid: null,
    }

    this.setState(resetState)

    if(this.focusField && this.focusField.focus) {
      this.focusField.focus()
    }
  }

  trySaveNote() {
    const note = {
      description: this.state.description,
      department: this.state.department,
      priority: this.state.priority,
      category: this.state.category,
      relatedChannels: this.state.channels || [],
      relatedCues: this.state.cues || [],
      date: new Date(),
      uuid: this.state.uuid,
      status: this.state.status,
    }

    setTimeout( () => this.resetNote(), 100 )
    if(this.state.uuid) {
      this.props.dispatch( mergeNote(note) ) 
    } else {
      this.props.dispatch( addNote(note) )
    }

    this.props.dispatch( storeLastNoteState(note) )

    //Dispatch the cue notes to update the cue sheet as needed.
    // --this really doesn't take very long and we don't
    // run this 24-7
    this.props.dispatch( buildAllCaches() )
  }

  tryDeleteNote() {
    //FIXME do a snack here or something to prevent deleting.
    this.props.dispatch( deleteNote(this.props.noteDialogData) )
    this.props.dispatch( buildAllCaches() )
  }

  tryMarkCompleted() {
    this.props.dispatch( completeNote(this.props.noteDialogData) )
    this.props.dispatch( buildAllCaches() )
  }

  tryReopenNote() {
    this.props.dispatch( openNote(this.props.noteDialogData) )
    this.props.dispatch( buildAllCaches() )
  }

  tryRestoreNote() {
    this.props.dispatch( restoreNote(this.props.noteDialogData) )
    this.props.dispatch( buildAllCaches() )
  }

  renderHelpText() {
    return (<div>
      <AsHtml html={Strings.get('submitNoteHelp')}/>
    </div>)
  }

  renderCompleteButton() {
    const note = this.props.noteDialogData || {}
    if(note.uuid && note.status == 'open') {
      return (
        <Button
          color='secondary'
          onClick={()=>{
            this.tryMarkCompleted()
            this.props.dispatch( dismissNoteDialog () )
          }}
        >
          {Strings.get('complete')}
        </Button>
      )
    }

    return null
    
  }

  renderOscButton() {
    return (
      <OscButton
        color='secondary'
        onClick={e => {
          const updateCue = (x) => {
            if(x) {
              const cues = [ x ]

              this.setState({
                ...this.state,
                cueText: '',
                cues: cues,
                category: 'typeCue'
              })
            }
          }

          this.props.dispatch( (_d, getState) => {
            const s = getState()
            const current = getCurrentOscCue(s, true)//force cue #
            updateCue(current)
          })
        }}
      >
        <IconSync /> OSC
      </OscButton>
    )
    
  }

  renderTitleText() {
    const { noteDialogData } = this.props
    const T = Strings
    if( !isEmptyOrWhitespace(noteDialogData.cue) ) {
      if(noteDialogData.uuid) {
        return T.get('updateCueNoteTitle', noteDialogData.cue)
      } else {
        return T.get('addCueNoteTitle', noteDialogData.cue)
      }
    } else {
      if(noteDialogData.uuid) {
        return T.get('updateNoteTitle') 
      } else {
        return T.get('addNoteTitle')
      }
    }
  }

  renderEditorControls() {
    const { dispatch } = this.props
    return (
      <React.Fragment>
        <Button
          onClick={e => {
            this.resetNote()
            dispatch( dismissNoteDialog () )
          }}
        >
          { Strings.get('cancel') }
        </Button>

        { this.renderCompleteButton() }

        <Button
          color='secondary'
          onClick={e => {
            this.trySaveNote()
            
            dispatch( dismissNoteDialog () )
          }}
        >
          { this.state.uuid ? Strings.get('update') : Strings.get('save') }
        </Button>

        { this.renderOscButton() }

{/* DEBUG INFO */}
        {/* <br /> */}
        <div style={{
          overflow: 'scroll'
        }}>

        <code>
            <b>STATE</b>
          { JSON.stringify(this.state, null, 2) }
          </code>

          <code style={{color: 'orange'}}>
            <b>PROPS</b>
          { JSON.stringify(this.props, null, 2) }
          </code>
        </div>
        
      </React.Fragment>
    )
  }
  //Copied from the Notes index.js
  renderEditor() { 
    const T = this.props.strings
    const textStyle = {
      flex: 1,
      marginRight: '4px'
    }

    const setChannel = () => {
      if(this.state.channelText) {
        const copy = {...this.state}
        copy.channels.push(this.state.channelText)
        copy.channelText = ''
        copy.category = this._guessCategory(copy)
        this.setState(copy)
      }
    }

    const removeChannel = (ch) => {
      const channels = this.state.channels.slice()
      const cut = channels.indexOf(ch)
      if(cut > -1) {
        channels.splice(cut, 1)
      }

      const copy = {
        ...this.state,
        channels: channels,
      }

      copy.category = this._guessCategory(copy)
      this.setState(copy)
    }

    const setCue = () => {
      if(this.state.cueText) {
        const copy = {...this.state}
        copy.cues.push(this.state.cueText)
        copy.cueText = ''
        copy.category = 'typeCue'
        this.setState(copy)
      }
    }

    const removeCue = (ch) => {
      const cues = this.state.cues.slice()
      const cut = cues.indexOf(ch)
      if(cut > -1) {
        cues.splice(cut, 1)
      }

      const copy = {
        ...this.state,
        cues: cues,
      }
      copy.category = this._guessCategory(copy)
      this.setState(copy)
    }

    const paddedButton = {
      paddingLeft: 16,
    }

    const expandRelated = (this.state.cues.length + this.state.channels.length) > 0

    const expansionStyle = {
      maxHeight: 48,
      minHeight: 36,
      //borderBottom: `1px solid ${window.theme.palette.primary.dark}`,
    }
    return (
      <div style={{
        display: 'block',
        flex: 0,
      }}>
        <div
          style={{
            // ...cardStyle,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary 
              style={expansionStyle}
              expandIcon={<IconExpandMore />}
            >
              <b>Details</b>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div              
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
              {/* What is this?  */}
              </div>

              <div style={{display:'flex'}}>
                <TextFieldRef
                  select 
                  style={textStyle}
                  label={T.get('department')}
                  onChange={ event => {
                    this.storeDepartment(event.target.value)
                    // this.setState({
                    //   ...this.state,
                    //   department: event.target.value,
                    // })
                  } }
                  value={this.state.department}
                >
                  { this.departments.map( d => {
                    return (
                      <MenuItem value={d} key={d}>
                        { d }
                      </MenuItem>
                    )
                  })}

                </TextFieldRef>

                <TextFieldRef 
                  select
                  style={textStyle}
                  label={T.get('priority')}
                  onChange={ event => {
                    this.setState({
                      ...this.state,
                      priority: event.target.value,
                    })
                  } }
                  value={this.state.priority}
                >
                  { this.priorities.map( d => {
                    return (
                      <MenuItem value={d} key={d}>
                        { T.get(d) }
                      </MenuItem>
                    )
                  })}

                </TextFieldRef>

                <TextFieldRef 
                  select
                  style={textStyle}
                  label={T.get('category')}
                  onChange={ event => {
                    this.setState({
                      ...this.state,
                      category: event.target.value,
                    })
                  } }
                  value={this.state.category}
                >
                  { this.categories.map( d => {
                    return (
                      <MenuItem value={d} key={d}>
                        { T.get(d) }
                      </MenuItem>
                    )
                  })}

                </TextFieldRef>
                
              </div>
              
              <div style={{display:'flex'}}>
                <TextFieldRef 
                  autoFocus
                  style={textStyle}
                  multiline
                  label={T.get('shortDescription')}
                  helperText={Strings.get('submitNoteHelp')}
                  value={this.state.description}
                  ref={ (x)=>{ this.focusField = x} }//callback for focus
                  onKeyUp={ (e) => {
                    if( detectShiftEnter(e) ) {
                      setTimeout(()=>{this.trySaveNote()}, 50) //delay
                      e.preventDefault()
                    }
                  } }
                  onChange={ (e) => {
                    if( detectShiftEnter(e) ) {
                      e.preventDefault()
                      return
                    }
                    this.setState({
                      ...this.state,
                      description: e.target.value,
                    })
                  } }
                />
              </div>

              
            </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel defaultExpanded={expandRelated}>
            <ExpansionPanelSummary 
              style={expansionStyle}
              expandIcon={<IconExpandMore />}
            >
              <b>Relations</b> 
              <span style={{
                fontFamily: 'monospaced',
                fontSize: '0.8em',
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}>
                { this.state.cues.map(q => 'Cue ' + q).join(', ') } 
                &nbsp;
                { this.state.channels.map(c => 'Ch. ' + c).join(', ') } 
              </span>

            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{display:'flex'}}>
                <TextFieldRef
                  style={textStyle}
                  label={ T.get('relatedChannels') }
                  onChange={ (e) => {
                    const val = e.target.value
                    this.setState({
                      ...this.state,
                      channelText: val,
                    })
                  } }
                  onBlur={ (e) => {
                    setChannel()
                  }}
                  onKeyUp= { (e) => {
                    if(e.charCode === 13 || e.keyCode === 13) {
                      setChannel()
                    }
                  }}
                  value={this.state.channelText}
                />

                <TextFieldRef
                  style={textStyle}
                  label={ T.get('relatedCues') }
                  onChange={ (e) => {
                    const val = e.target.value
                    this.setState({
                      ...this.state,
                      cueText: val,
                    })
                  } }
                  onBlur={ (e) => {
                    setCue()
                  }}
                  onKeyUp= { (e) => {
                    if(e.charCode === 13 || e.keyCode === 13) {
                      setCue()
                    }
                  }}
                  value={this.state.cueText}
                />          
              </div>

              <Paper 
                style={{
                  display: 'flex',
                  marginBotton: 8,
                  marginTop: 8,
                  padding: 8,
                }}
              >
                { this.state.cues.map( (c,i) => {
                  return (
                    <Chip 
                      style={{
                        backgroundColor: Colors.LA_LIGHT_BLUE,
                        color: window.theme.palette.common.white,
                      }}
                      key={c}
                      label={'Cue ' + c}
                      onDelete={ () => { removeCue(c) }}
                    />
                  )
                })}

                { this.state.channels.map( (c,i) => {
                  return (
                    <Chip 
                      key={c}
                      label={'Ch. ' + c}
                      onDelete={ () => { removeChannel(c) }}
                    />
                  )
                })}
              </Paper> 
            </ExpansionPanelDetails>
          </ExpansionPanel>

            
      
          <ExpansionPanel>
            <ExpansionPanelSummary
              style={expansionStyle}
              expandIcon={<IconExpandMore />}
            >
              <b>Advanced Relations</b>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Field</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Controls</TableCell>
                  </TableRow>
                  <TableBody>
                    <TableRow>
                      Placeholder
                    </TableRow>
                  </TableBody>
                </TableHead>
              </Table>
              <h3>Add Property</h3>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    )
    
  }

  renderDrawer() {
    const T = this.props.strings
    const { dispatch, noteDialogOpen, noteDialogData, noteDialogMerge, } = this.props
    return (
      <Drawer
        open={noteDialogOpen || false}
        anchor='right'
        onClose={e => {
          dispatch( dismissNoteDialog() )
        }}
      >
        <Paper
          style={{
            padding: 8,
            backgroundColor: window.theme.palette.background.default,
            flex: 1,
          }}
        >
          <h1>
            { this.renderTitleText() }
          </h1>

          { this.renderEditor() }

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            { this.renderEditorControls() }
          </div>
        </Paper>
        {/* DEBUG */}
        {/* <tt>->{JSON.stringify(this.props.noteDialogData, null, 2)}</tt> */}
      </Drawer>
    )
  }

  renderDialog() {
    const T = this.props.strings
    const { dispatch, noteDialogOpen, noteDialogData, noteDialogMerge, } = this.props

    return (
      <Dialog
        open={noteDialogOpen || false}
        modal={true}
        autoScrollBodyContent={true}
        onRequestClose={e=>{
          dispatch( dismissNoteDialog() )
        }}
      > 
        <DialogTitle>
          { this.renderTitleText() }
        </DialogTitle>

        <DialogContent>
          <Paper
            style={{
              padding: 8,
              backgroundColor: window.theme.palette.background.default,
              flex: 1,
            }}
          >
            { this.renderEditor() }
          </Paper>          

        </DialogContent>

        <DialogActions>
          { this.renderEditorControls() }
        </DialogActions>


      </Dialog>
    )
  }

  render() {
    if(this.props.noteDialogMode) {
      return this.renderDialog()
    } else {
      return this.renderDrawer()
    }
  }
}

export default translate('Notes')( 
  connect(s => {
    return {
      noteDialogOpen: !!s.notes.noteDialogOpen, //cast to boolean
      noteDialogData: s.notes.noteDialogData || {},
      noteDialogMode: s.notes.noteDialogMode || false,
    }
  })(NoteDialog)
)