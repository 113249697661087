/**
 * returns an array of position objects which are title/person
 * @param {Show Object} show 
 */
export const createPositionList = (show) => {
  let positions = []

  const safe = (str, def) => {
    if(!str) {
      return def || ''
    }

    return str
  }

  const position = (title, person, defaultTitle) => {
    if(!show[person]) {
      return
    }
    positions.push( safe(show[title], defaultTitle) + ': ' + safe(show[person] || '') )
  } 

  position('designerTitle', 'designer', 'Lighting by')
  position('assistant1Title', 'assistant1', '')
  position('assistant2Title', 'assistant2', '')
  position('assistant3Title', 'assistant3', '')

  return positions
}