import React from 'react'
import { connect } from 'react-redux'
import translate from './../../shared/language/Translate'
import { addEvent, clearLog, refreshLog } from './../reducers/LogLogic'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DEBUG, ERROR, WARN, INFO } from './../../shared/WebLog'
import Standard from './../../components/Layouts/Standard'

class LogPage extends React.Component {
  render() {
    const T = this.props.strings
    const { events, loaded, dispatch } = this.props

    if(!loaded) {
      setTimeout(()=>{
        dispatch(refreshLog())
      }, 500)
      return (
        <Standard key={this.props.language.current}>
          <h1></h1>
          <CircularProgress size={60} thickness={7} />
        </Standard>
      )
    }

    const stringForLevel = (level) => {
      if (level >= 3) {
        return T.get('ERROR' )
      } else if (level >= 2) {
        return T.get('WARN' )
      } else if (level >= 1) {
        return T.get('INFO' )
      } else {
        return T.get('DEBUG')
      }
    }

    const classForLevel = (level) => {
      switch (level) {
        case 3:
          return 'log-error'  
        case 2:
          return 'log-warn'
        case 1: 
          return 'log-info'
        default:
          return 'log-debug'
      }
    }

    const renderTable = () => {
      return (
        <table className='log-table'>
          <thead>
            <tr>
              <th className='time'>{T.get('time')}</th>
              <th className='level'>{T.get('level')}</th>
              <th className='module'>{T.get('module')}</th>
              <th className='message'>{T.get('message')}</th>
            </tr>
          </thead>
          <tbody>
            { events.map((log, index) => {
              return (
                <tr key={index} className= { classForLevel(log.level) }>
                  <td className='time'>{ log.time }</td>
                  <td className='level'>{ stringForLevel(log.level) }</td>
                  <td className='module'>{ log.module }</td>
                  <td className='message'>{ log.message }</td>
                </tr>
              )
            })
            }
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th>{ T.get('totalMessages', events.length) }</th>
            </tr>
          </tfoot>
        </table>
      )
    }

    return (
      <Standard key={this.props.language.current}>
        <h1>{T.get('log')}</h1>
        Loaded ? {loaded ? 'yes' : 'no' }
        <button
            onClick = { e => {
              const message = ` This is a longer message so we can test something valid that might show up in the logs plus a random:${Math.random()}.`;

              dispatch( 
                addEvent(Math.floor( (Math.random() * 5) - 1 ), 'TEST', message ) 
              )
            }}
          >
            test-add-random
        </button>

        <button
            onClick = { e => {
              dispatch( clearLog() )
            }}
          >
            { T.get('clearLog')}
        </button>

          { renderTable() }

          { events.length > 0 ? null : <div>{T.get('logNothingHere')}</div> }
      </Standard>
    )
  }
}

export default connect(s => {
  return {
    events: s.log.events,
    loaded: s.log.loaded,
    language: s.language
  }
})(translate('Log')(LogPage))