import React from 'react'
import Colors from './../Colors'

class InstrumentRow extends React.PureComponent {
  constructor(props) {
    super(props)
    this.textEditor = null
    this.state = {
      selected: {},
      backup: '',
      current: '',
      debounce: false,
      hover: false,
    }
  }

  componentDidUpdate() {
    if(!this.props || this.state.debounce){
      return
    }


    setTimeout(()=>{  
      if(this.props.editingRow == this.props.index) {
        console.log(`We are the selected row ${this.props.index}`)

        if(this.textEditor) {
          this.textEditor.focus()
          this.textEditor.setSelectionRange(0,999)
          console.log(`\t\tselecting`)
        }

        this.setState({
          ...this.state,
          debounce: true,
        })
      }
    }, 50)
  }

  _hover(bool) {
    this.setState({
      ...this.state,
      hover: !!bool
    })
  }

  _handleCellOnClick(e, column) {
    const shift = e.shiftKey
    const ctrl = e.ctrlKey

    const selected = { ...this.state.selected }
    selected[column.key] = true

    this.setState({
      ...this.state,
      selected: selected,
      debounce: false,
    })

    this.props.onSelect(this.props.index, column.key, shift, ctrl)
  }

  _handleTextChange(e) {
    this.setState({
      ...this.state,
      current: e.target._handleKeyPress,
    })
  }

  _cancelEdit() {
    this.setState({
      ...this.state,
      current: this.state.backup,
    })

    this.props.list.deselect()
  }

  _renderTextArea(column, list) {
    const theme = window.theme
    const palette = theme.palette

    return (
      <textarea 
        ref={x => this.textEditor = x}
        style={{
          resize: 'none',
          flex: 1,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          border: `2px solid ${theme.editorBorder}`,
          background: theme.editor,
          color: palette.text.primary,
          outline: 'none',
        }}
        value={ list.state.editValue }
        // onKeyPress={(e)=>{
        //   this._handleKeyPress(e)
        // }}
        onChange={(e)=>{
          list.updateEdit( e.target.value )
        }}
        onBlur={ e => {
          list.commitEdit()
        } }
        onKeyDown = { e => {
          console.log(`::textarea::keyUp ${e.keyCode} ${e.which}` )
          if ( e.keyCode == 13 || e.which == 13) {
            e.preventDefault()
            list.commitEdit()
          }

          if ( e.keyCode == 27 || e.which == 27) {
            list.deselect()
          }
        } }
      />
    )
    
  }

  render() {
    const {
      data, 
      columns, 
      index, 
      onSelect,

      selectKey,
      editingRow,
      selected,
      list, //reference to the sheet

      //Old methods
      selectStart,
      selectEnd,
    } = this.props

    //=================== IF DELETED FUNCTIONALITY
    if(!data || data.deleted) {
      return null
    }

    const PRIMARY = window.theme.palette.primary.main
    const SECONDARY = window.theme.palette.secondary.main
    //console.log('InstrumentRow::rendering [' + index + ']')
    let selectedColumn = null
    let color = Colors.TABLE_CLEAR

    const theme = window.theme
    const palette = theme.palette

    if(selectKey) {
      if(selectStart > -1 && selectEnd > -1) {
      } else if (selectStart > -1) {
        if (selectEnd > -1) {
          if(selectStart >= index && selectEnd >= index) {
            console.log(`\t\t -> selected ${selectStart}-${selectEnd}:${index}`)
            selectedColumn = selectKey
          }
        } else if (selectStart == index) {
            console.log(`\t\t -> selected ${selectStart}:${index}`)
            selectedColumn = selectKey
        }
      }
    } else {
      selectedColumn = '__none__'
    }

    let bgFunc = (key) => {

      if(selected && key == selectKey) {
        if (editingRow == index) {
          return theme.editor
        } else {
          return theme.highlight
        }
      }
      
      return color
    }
    
    const editorForColumn = (column) => {
      return this._renderTextArea(column)
    }

    const borderBottom = `1px solid ${PRIMARY}`
    const borderLeft = `1px solid ${window.theme.tableBorder}`
    const boxShadow = `2px 2px 5px ${window.theme.la_light_blue_alpha_25}`
    const borderClear = `1px solid ${Colors.CLEAR}`
    const borderHover = `1px solid ${window.theme.la_light_blue}`

    let _baseStyle = {
      ...this.props.style,
      display: 'flex',
      backgroundColor: index % 2 === 1 ? Colors.TABLE_CLEAR : Colors.TABLE_ROW_ALT,
      borderRight: borderClear,
      borderTop: borderClear,
      borderBottom: borderBottom,
      borderLeft: borderLeft,
      boxShadow: 'none',
      color: theme.palette.text.primary,
    }

    if(this.state.hover) {
      _baseStyle.borderBottom = borderHover
      _baseStyle.borderRight = borderHover
      _baseStyle.borderTop = borderHover
      _baseStyle.boxShadow = boxShadow
    } 

    _baseStyle.borderLeft = `1px solid ${Colors.TABLE_BORDER}`

    return (
      <div 
        className='row-hover'
        style={_baseStyle}
        onMouseEnter = { e => this._hover(true) }
        onMouseLeave = { e => this._hover(false) }
      >
        <div
          style={{
            width: '30px',
            minWidth: '30px',
            display: 'flex',
            color: this.state.hover ? SECONDARY : PRIMARY,
            backgroundColor: Colors.BACKGROUND,
            borderRight: selected ? `3px solid ${SECONDARY}`: `1px solid ${window.theme.tableDashed}`,
            padding: '2px', 
            fontWeight: 'bold',
          }}
        >
          <label
            style={ selected ? {
              flex: 1,
              textAlign: 'center',
              color: SECONDARY,
            } : {
              flex: 1,
              textAlign: 'center',
            } }
          >
            { index }
          </label>
        </div>
        <div
          style={{
            width: '25px',
            //marginLeft: '-25px',
            color: 'red',
            position: 'absolute',
            right: '10px',
          }}
        >
          { index }
        </div>
        { columns.map((column, colIndex) => {            
          return (
            <div 
              key={column.key + colIndex} 
              style={{
                width: column.width,
                minWidth: column.width,
                backgroundColor: bgFunc(column.key), 
                borderRight: `1px dotted ${window.theme.tableDashed}`,
                textOverflow: 'ellipsis',
                //overflow: 'hidden',
                display: 'flex',
              }}
              onClick={(e)=>{
                this._handleCellOnClick(e, column)
              } }
            >
              { editingRow == index && selected && column.key == selectKey  && !column.locked ? 
                
                this._renderTextArea( column, list )
                :
                <label 
                  style={{
                    flex: 1,
                    padding: '2px',
                    color: column.locked ? theme.palette.text.disabled : theme.palette.text.primary,
                    fontStyle: column.locked ? 'italic' : 'inherit',
                  }}
                >
                  {data[column.key]}
                </label>
              }
            </div>
          )
        })}
      </div>
    )
  }
}

export default InstrumentRow